// not using this file anymore

import React, { Component } from 'react'
import Button from 'react-bootstrap/Button';
import { faUserPlus, faCapsules, faDna, faHouseUser, faRunning, faAppleAlt, faWeight, faBed, faBrain} from '@fortawesome/free-solid-svg-icons'

import {stopTimer} from "../../redux/actions.js";
import PatientInfoStrip from '../../components/PatientInfoStrip'
import GlobalStyles from '../styles/global.module.scss'
import * as Metadata from '../../metadata.js'

import {connect} from "react-redux";
import Styles from './styles/Profile.module.scss'
import Constants from '../../values.js'
import Select from "react-select";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Config from "../../config";
import {addPatient} from "../../redux/actions";
import moment from "moment";
const axios = require('axios');

const BMI_RANGES = [
  [0, 18.4],
  [18.5, 24.9],
  [25, 29.9],
  [30, 100]
]


let windowHeight = window.innerHeight

const PATIENT_DATA = [
  {
    label: 'Conditions',
    logo: faUserPlus,
    1: [
        {label: 'Conditions', values: ["Asthma", "Hypertension"]},
        {label: 'Undiagnosed', values: ["Auto Immune"]}
      ],
    2: [
      {label: 'Allergies', values: ["Dust", "Skin"]},
      {label: 'Food Sensitivities', values: ["Nuts", "Alcohol"]}
    ]
  },
  {
    label: 'Medications',
    logo: faCapsules,
    1: [
      {label: 'Medications', values: []}
    ],
    2: [
      {label: 'Vitamins / Supplements', values: []}
    ]
  },
  {
    label: 'Genetics',
    logo: faDna,
    1: [
      {label: 'Family History', values: ["Father: Arthritis", "Mother:"]}
    ],
    2: [
      {label: 'Genetics', values: []}
    ]
  },
  {
    label: 'Social Determinants',
    logo: faHouseUser,
    1: [
      {label: 'Social Determinants', values: ["Employment Status: ", "Occupation: ", "Education level: ", "Marital Status: ", "Number in household: " ]},
    ],
    2: [
      {label: 'Needs Assessment', values: ["Housing: ", "Food: ", "Transportation: ", "Money for bills: ", "Clothing: "]}
    ],
    3: [
      {label: 'Neighborhood Characteristics', values: ["Low Income: ", "Food Desert: ", "Air Quality: ", "Access to Parks: ", "Public Transportation: ", "School Quality: ", "Crime Index:"]}
    ]
  },
  {
    label: 'Lifestyle',
    logo: faRunning,
    values: ["Nutrition", "Weight", "Sleep", "Physical", "Emotional"]
  }
]

class Profile extends Component {
  constructor() {
    super()
    this.state = {
      modalIsOpen: false,
      cohortModalOpen: false,
      contentModalOpen: false,
      contentValues: [],
      cohortValues: [],
      patientCore: null,
      loading: true,
      rareDisease: null
    }
    this.trackScrolling = this.trackScrolling.bind(this)
    this.isBottom = this.isBottom.bind(this)
    this.getRareDisease = this.getRareDisease.bind(this)
  }

  componentDidMount() {
    if(!this.props.patient) {
      this.props.history.push('/patient/list')
      return
    }

    document.addEventListener('scroll', this.trackScrolling);
  }

  static getDerivedStateFromProps(props, state) {
    if(props.patientCore && props.conditions)
      return {loading: false}

    return null
  }

  isBottom(el) {
    if(!el)
      return
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }

  trackScrolling() {
    const wrappedElement = document.getElementById('bottom');
    if (this.isBottom(wrappedElement)) {
      this.props.history.push('/patient/progress')
      document.removeEventListener('scroll', this.trackScrolling);
    }
  };

  getRareDisease(id) {
    let {token} = this.props
    let self = this
    let url = Config.BACKEND_URL + 'rare-diseases/' + id
    axios({
      method: 'get',
      headers:{Authorization: 'JWT '+ token},
      url: url,
    }).then(({ data }) => {
      if(data )
        self.setState({rareDisease: data})
    }).catch(err => {
      console.log('error when getting rare disease info', err)
    })
  }

  calculateBMIValues(height, weight, gender, age) {
    if(!height || !weight || !gender || !age)
      return {
        bmi: 0,
        bfp: 0
      }
    let bmi = (703 * weight/Math.pow(height, 2))
    let bfp = null
    switch(gender) {
      case 'Male':
        bfp = (1.2 * bmi) + (0.23 * age) - 16.2
        break
      case 'Female':
        bfp = (1.2 * bmi) + (0.23 * age) - 5.4
    }

    return {
      bmi: bmi,
      bfp: bfp
    }
  }

  getFrequencyCount(value) {
    switch(value.toLowerCase()) {
      case 'occasional':
      case 'low':
        return '1-2'
        break
      case 'moderate':
        return '3-6'
        break
      case 'consistent':
      case 'high':
        return '7+'
      default:
        return '0'
    }
  }

  calculateRelationshipScore(relationships) {
    let totalRelationshipPoints = 0
    let totalRelations = 0
    Object.values(relationships).forEach(score => {
      if(typeof score === 'number') {
        totalRelationshipPoints += score
        totalRelations += 1
      } else {
        console.log('ignoring the relatiohsjp')
      }
    })
    let score = totalRelations > 0 ? (totalRelationshipPoints/totalRelations)*10 : 'N/A'
    return score
  }

  renderConditions(label) {
    let conditions = this.props.conditions
    let conditionsElement = conditions.map((x,index) => <p key={index.toString()} style={{textAlign: 'left', padding: '2px 10px'}}>{x.symptom}</p>)
    return (
      <div key={label} style={{width: '100%', paddingLeft: 10, paddingRight: 10}}>
        <h5 style={{color: Constants.primaryTheme, textAlign: 'center'}}>{label}</h5>
        <div style={{height: '90%', overflowY: 'scroll'}}>
          {conditionsElement}
        </div>
      </div>
    )

  }

  renderUndiagnosedConditions(label) {
    let {rareDisease} = this.state
    let {patientCore} = this.props
    let autoImmune = patientCore && patientCore.pre_existing ? patientCore.pre_existing.auto_immune : {}
    if(autoImmune.user_entry && autoImmune.user_entry.length > 0 && !rareDisease) {
      //if the user has eneterd auto immune ID and rare disease name is not available, get it form backedn
      this.getRareDisease(autoImmune.user_entry)
    }
    let userChoice
    switch(autoImmune.user_choice) {
      case true:
        userChoice = 'Yes'
        break
      case false:
        userChoice = 'No'
        break
      default:
        userChoice = 'N/A'
    }

    return (
      <div key={label} style={{width: '100%', paddingLeft: 10, paddingRight: 10}}>
        <h5 style={{color: Constants.primaryTheme, textAlign: 'center'}}>{label}</h5>
        <div style={{width: '100%', overflowY: 'scroll'}}>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <p style={{fontWeight: 'bold', width: '50%', overflowWrap: 'break-word', fontSize: 14}}>Undiagnosed Condition</p>
            <p style={{marginLeft: 20}}>{userChoice}</p>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20}}>
            <p style={{fontWeight: 'bold', width: '50%', overflowWrap: 'break-word', fontSize: 14}}>Suspected Condition</p>
            <p style={{marginLeft: 20}}>{rareDisease && rareDisease.name}</p>
          </div>
        </div>
      </div>
    )
  }

  renderAllergies(label) {
    let {patientCore} = this.props
    let allergies = patientCore && patientCore.allergies ? patientCore.allergies : []
    let allergiesElement = allergies.map((x,i) => <p key={i.toString()} style={{textAlign: 'left', padding: '2px 10px'}}>{x}</p> )
    return (
      <div key={label} style={{width: '100%', paddingLeft: 10, paddingRight: 10}}>
        <h5 style={{color: Constants.primaryTheme, textAlign: 'center'}}>{label}</h5>
        <div style={{height: '90%', overflowY: 'scroll'}}>
          {allergiesElement}
        </div>
      </div>
    )
  }

  renderFoods(label) {
    let {patientCore} = this.props
    let foods = patientCore && patientCore.food_sensitivities ? patientCore.food_sensitivities.foods : []
    let foodsElement = foods.map((x,i) => <p key={i.toString()} style={{textAlign: 'left', padding: '2px 10px'}}>{x}</p> )
    return (
      <div key={label} style={{width: '100%', paddingLeft: 10, paddingRight: 10}}>
        <h5 style={{color: Constants.primaryTheme, textAlign: 'center'}}>{label}</h5>
        <div style={{height: '90%', overflowY: 'scroll'}}>
          {foodsElement}
        </div>
      </div>
    )
  }

  getFamilyHistoryFormated(relations) {
    if(!relations)
      return null
    let toReturn = [];
    relations.forEach(each => {
      let conditions = []
      if(each.conditions)
        conditions = each.conditions.map((x, index) => <span key={index.toString()} style={{width: '80%'}}>{x}{index === each.conditions.length-1 ? '':', '}</span>)
      toReturn.push(
        <div key={each.relationship} style={{display: 'flex', flexDirection: 'row', padding: '2px 10px'}}>
          <p style={{fontWeight: 'bold'}}>{each.relationship}:</p>
          <p style={{marginLeft: 5, overflowWrap: 'break-word'}}>{conditions}</p>
        </div>
      )
    })

    return toReturn
  }

  renderFamily(label) {
    let familyScreening = this.props.patientCore.screening_family
    let entries = []
    if(familyScreening) {
      entries = this.getFamilyHistoryFormated(familyScreening.relations)
    }
    return (
      <div key={label} style={{width: '100%', paddingLeft: 10, paddingRight: 10}}>
        <h5 style={{color: Constants.primaryTheme, textAlign: 'center'}}>{label}</h5>
        <div style={{height: '90%', overflowY: 'scroll'}}>
          {entries}
        </div>
      </div>
    )
  }

  renderSocial(label) {
    let socialScreening = this.props.patientCore.screening_social
    let maritalStatus = this.props.patientCore.relationships ? this.props.patientCore.relationships.marital_status : ''
    return (
      <div key={label} style={{width: '100%', paddingLeft: 10, paddingRight: 10}}>
        <h5 style={{color: Constants.primaryTheme, textAlign: 'center'}}>{label}</h5>
        {
          socialScreening &&
          <div style={{height: '90%', overflowY: 'scroll'}}>
            <div style={{display: 'flex', flexDirection: 'row', padding: '2px 10px'}}>
              <p style={{fontWeight: 'bold'}}>Employment Status:</p>
              <p style={{marginLeft: 5, overflowWrap: 'break-word'}}>{socialScreening.employment}</p>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', padding: '2px 10px'}}>
              <p style={{fontWeight: 'bold'}}>Occupation:</p>
              <p style={{marginLeft: 5, overflowWrap: 'break-word'}}>{socialScreening.occupation}</p>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', padding: '2px 10px'}}>
              <p style={{fontWeight: 'bold'}}>Education Level:</p>
              <p style={{marginLeft: 5, overflowWrap: 'break-word'}}>{socialScreening.education}</p>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', padding: '2px 10px'}}>
              <p style={{fontWeight: 'bold'}}>Marital Status:</p>
              <p style={{marginLeft: 5, overflowWrap: 'break-word'}}>{maritalStatus}</p>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', padding: '2px 10px'}}>
              <p style={{fontWeight: 'bold'}}>Number in household:</p>
              <p style={{marginLeft: 5, overflowWrap: 'break-word'}}>{socialScreening.household}</p>
            </div>
          </div>
        }
      </div>
    )
  }

  renderNeighborhood(label) {
    return (
      <div key={label} style={{width: '100%', paddingLeft: 10, paddingRight: 10}}>
        <h5 style={{color: Constants.primaryTheme, textAlign: 'center'}}>{label}</h5>

        <div style={{height: '90%', overflowY: 'scroll'}}>
          <div style={{display: 'flex', flexDirection: 'row', padding: '2px 10px'}}>
            <p style={{fontWeight: 'bold'}}>Low Income: </p>
            <p style={{marginLeft: 5}}>No</p>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', padding: '2px 10px'}}>
            <p style={{fontWeight: 'bold'}}>Food Desert: </p>
            <p style={{marginLeft: 5}}>No</p>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', padding: '2px 10px'}}>
            <p style={{fontWeight: 'bold'}}>Air Quality: </p>
            <p style={{marginLeft: 5}}>50</p>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', padding: '2px 10px'}}>
            <p style={{fontWeight: 'bold'}}>Access to Parks: </p>
            <p style={{marginLeft: 5}}>Yes</p>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', padding: '2px 10px'}}>
            <p style={{fontWeight: 'bold'}}>Public Transportation: </p>
            <p style={{marginLeft: 5}}>Yes</p>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', padding: '2px 10px'}}>
            <p style={{fontWeight: 'bold'}}>School Quality: </p>
            <p style={{marginLeft: 5}}>9 / 10</p>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', padding: '2px 10px'}}>
            <p style={{fontWeight: 'bold'}}>Crime Rate: </p>
            <p style={{marginLeft: 5}}>345 crimes / 10,000 people</p>
          </div>

        </div>
      </div>
    )
  }

  renderNeeds(label) {
    let socialScreening = this.props.patientCore.screening_social || {}
    let access = socialScreening.access || []
    return (
      <div key={label} style={{width: '100%', paddingLeft: 10, paddingRight: 10}}>
        <h5 style={{color: Constants.primaryTheme, textAlign: 'center'}}>{label}</h5>

          {
            socialScreening &&
            <div style={{height: '90%', overflowY: 'scroll'}}>
              <div style={{display: 'flex', flexDirection: 'row', padding: '2px 10px'}}>
                <p style={{fontWeight: 'bold'}}>Housing :</p>
                <p style={{marginLeft: 5}}>{access.includes('Housing') ? 'Yes' : 'No'}</p>
              </div>
              <div style={{display: 'flex', flexDirection: 'row', padding: '2px 10px'}}>
                <p style={{fontWeight: 'bold'}}>Food :</p>
                <p style={{marginLeft: 5}}>{access.includes('Food') ? 'Yes' : 'No'}</p>
              </div>
              <div style={{display: 'flex', flexDirection: 'row', padding: '2px 10px'}}>
                <p style={{fontWeight: 'bold'}}>Transportation :</p>
                <p style={{marginLeft: 5}}>{access.includes('Transportation') ? 'Yes' : 'No'}</p>
              </div>
              <div style={{display: 'flex', flexDirection: 'row', padding: '2px 10px'}}>
                <p style={{fontWeight: 'bold'}}>Money for Bills :</p>
                <p style={{marginLeft: 5}}>{access.includes('Money to pay bills') ? 'Yes' : 'No'}</p>
              </div>
              <div style={{display: 'flex', flexDirection: 'row', padding: '2px 10px'}}>
                <p style={{fontWeight: 'bold'}}>Clothing :</p>
                <p style={{marginLeft: 5}}>{access.includes('Clothing') ? 'Yes' : 'No'}</p>
              </div>
              <div style={{display: 'flex', flexDirection: 'row', padding: '2px 10px'}}>
                <p style={{fontWeight: 'bold'}}>Medical Care :</p>
                <p style={{marginLeft: 5}}>{access.includes('Medical Care') ? 'Yes' : 'No'}</p>
              </div>

            </div>
          }

      </div>
    )
  }

  renderGenetics(label) {
    let {patientCore} = this.props
    let data = patientCore && patientCore.genetics ? patientCore.genetics : []
    let links = data.map((x,i) => <li key={i.toString()} style={{overflowWrap: 'break-word'}}><a href={x} target={'_blank'} rel="noopener noreferrer">Report</a></li>)
    return (
      <div key={label} style={{width: '100%', paddingLeft: 10, paddingRight: 10}}>
        <h5 style={{color: Constants.primaryTheme, textAlign: 'center'}}>{label}</h5>
        <div style={{height: '90%', overflowY: 'scroll'}}>
          <ul>
            {links}
          </ul>
        </div>
      </div>
    )

  }

  renderVitamins(label) {
    let medicationsObject = this.props.medications
    let activeVitamins = null
    let inactiveVitamins = null
    if(medicationsObject) {
      activeVitamins = medicationsObject.active_vitamins.map((x,i) => (
          <div key={i.toString()} style={{display: 'flex', flexDirection: 'row', padding: '2px 10px'}}>
            <p style={{fontWeight: 'bold', width: '40%', textAlign: 'center'}}>{x.name}</p>
            <p style={{width: '30%', textAlign: 'center'}}>{x.dose.quantity} {x.dose.unit}</p>
            <p style={{width: '30%', textAlign: 'center'}}>{x.frequency.recurring} {x.frequency.quantity}</p>
          </div>
        )
      )

      inactiveVitamins = medicationsObject.inactive_vitamins.map((x,i) => (
          <div key={i.toString()} style={{display: 'flex', flexDirection: 'row', padding: '2px 10px'}}>
            <p style={{fontWeight: 'bold', width: '40%', textAlign: 'center'}}>{x.name}</p>
            <p style={{width: '60%', textAlign: 'center'}}>ended {moment(x.end).format("MMM Do YYYY")}</p>
          </div>
        )
      )
    }

    return (
      <div key={label} style={{width: '100%', paddingLeft: 10, paddingRight: 10}}>
        <h5 style={{color: Constants.primaryTheme, textAlign: 'center'}}>{label}</h5>
        <div style={{height: '90%', overflowY: 'scroll'}}>
          <p style={{fontWeight: 'bold', textAlign: 'center'}}>Active</p>
          {activeVitamins}
          <p style={{fontWeight: 'bold', textAlign: 'center'}}>Inactive</p>
          {inactiveVitamins}
        </div>
      </div>
    )
  }

  renderMedications(label) {
    let medicationsObject = this.props.medications
    let medications = null
    let inactiveMedications = null
    if(medicationsObject) {
      medications = medicationsObject.active_medications.map((x,i) => (
        <div key={i.toString()} style={{display: 'flex', flexDirection: 'row', padding: '2px 10px'}}>
          <p style={{fontWeight: 'bold', width: '40%', textAlign: 'center'}}>{x.name}</p>
          <p style={{width: '30%', textAlign: 'center'}}>{x.dose.quantity} {x.dose.unit}</p>
          <p style={{width: '30%', textAlign: 'center'}}>{x.frequency.recurring} {x.frequency.quantity}</p>
        </div>
        )
      )

      inactiveMedications = medicationsObject.inactive_medications.map((x,i) => (
          <div key={i.toString()} style={{display: 'flex', flexDirection: 'row', padding: '2px 10px'}}>
            <p style={{fontWeight: 'bold', width: '40%', textAlign: 'center'}}>{x.name}</p>
            <p style={{width: '60%', textAlign: 'center'}}>ended {moment(x.end).format("MMM Do YYYY")}</p>
          </div>
        )
      )
    }
    return (
      <div key={label} style={{width: '100%', paddingLeft: 10, paddingRight: 10}}>
        <h5 style={{color: Constants.primaryTheme, textAlign: 'center'}}>{label}</h5>
        <div style={{height: '90%', overflowY: 'scroll'}}>
          <p style={{fontWeight: 'bold', textAlign: 'center'}}>Active</p>
          {medications}
          <p style={{fontWeight: 'bold', textAlign: 'center'}}>Inactive</p>
          {inactiveMedications}
        </div>
      </div>
    )
  }

  renderFieldEntries(data) {
    let toReturn = []
    data.forEach(entry => {
      if(entry.label === 'Conditions') {
        toReturn.push(this.renderConditions(entry.label))
      } else if(entry.label === 'Undiagnosed'){
        toReturn.push(this.renderUndiagnosedConditions(entry.label))
      } else if(entry.label === 'Allergies'){
        toReturn.push(this.renderAllergies(entry.label))
      } else if(entry.label === 'Food Sensitivities'){
        toReturn.push(this.renderFoods(entry.label))
      } else if(entry.label === 'Family History'){
        toReturn.push(this.renderFamily(entry.label))
      } else if(entry.label === 'Social Determinants'){
        toReturn.push(this.renderSocial(entry.label))
      } else if(entry.label === 'Needs Assessment'){
        toReturn.push(this.renderNeeds(entry.label))
      } else if(entry.label === 'Neighborhood Characteristics'){
        toReturn.push(this.renderNeighborhood(entry.label))
      } else if(entry.label === 'Medications'){
        toReturn.push(this.renderMedications(entry.label))
      } else if(entry.label === 'Genetics'){
        toReturn.push(this.renderGenetics(entry.label))
      } else if(entry.label === 'Vitamins / Supplements') {
        toReturn.push(this.renderVitamins(entry.label))
      }
      else {
        let entries = entry.values.map(x => <p style={{textAlign: 'left', padding: '2px 10px'}}>{x}</p>)
        toReturn.push(
          <div style={{width: '100%', paddingLeft: 10, paddingRight: 10}}>
            <h5 style={{color: Constants.primaryTheme, textAlign: 'center'}}>{entry.label}</h5>
            <div style={{height: '90%', overflowY: 'scroll'}}>
              {entries}
            </div>
          </div>
        )
      }

    })

    return toReturn
  }

  renderNutritionInfo() {
    let data = this.props.patientCore
    let progressData = this.props.patientCalculatedProgress
    if(!data)
      return

    let diet, cigarette, drugs
    if(data.nutrition && data.nutrition.diet)
      diet = data.nutrition.diet.map((x,index) => <span key={index.toString()} >{x}{index === data.nutrition.diet.length - 1 ? '':', '}</span>)

    if(data.lifestyle_habits && data.lifestyle_habits.cigarette)
      cigarette = data.lifestyle_habits.cigarette === 'NA' ? 'N/A' : data.lifestyle_habits.cigarette + ' cigarettes / day'

    if(data.lifestyle_habits && data.lifestyle_habits.drugs)
      drugs = data.lifestyle_habits.drugs === 'NA' ? 'N/A' : data.lifestyle_habits.drugs + ' times / week'

    return (
      <div key={'nutrition'} className={Styles.lifestyleEntry}>
        <div className={Styles.columnIcon}>
          <FontAwesomeIcon icon={faAppleAlt} style={{color: 'white', fontSize: 20}}/>
          <p className={Styles.rowIconText2}>Nutrition</p>
        </div>

        <div className={Styles.lifestyleText}>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <p style={{fontWeight: 'bold', marginRight: 10}}>Diet scre: {progressData ? <span style={{fontWeight: 'normal'}}>{progressData.healthyEatingPercentage}% of time healthy eating this past month</span> : ""}</p>
          </div>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <p style={{fontWeight: 'bold', marginRight: 10}}>Diet: </p>
            <p style={{wordBreak: 'break-all'}}>{diet}</p>
          </div>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <p style={{fontWeight: 'bold', marginRight: 10}}>Preference: </p>
            <p style={{wordBreak: 'break-all'}}>{data.nutrition && data.nutrition.cooking_choice}</p>
          </div>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <p style={{fontWeight: 'bold', marginRight: 10}}>Smoking: </p>
            <p style={{wordBreak: 'break-all'}}>{cigarette}</p>
          </div>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <p style={{fontWeight: 'bold', marginRight: 10}}>Drinking: </p>
            <p style={{wordBreak: 'break-all'}}>{data.lifestyle_habits && this.getFrequencyCount(data.lifestyle_habits.alcohol)} drinks / week</p>
          </div>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <p style={{fontWeight: 'bold', marginRight: 10}}>Coffee: </p>
            <p style={{wordBreak: 'break-all'}}>{data.lifestyle_habits && this.getFrequencyCount(data.lifestyle_habits.coffee)} cups / day</p>
          </div>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <p style={{fontWeight: 'bold', marginRight: 10}}>Drugs: </p>
            <p style={{wordBreak: 'break-all'}}>{drugs}</p>
          </div>
        </div>
      </div>
    )
  }

  renderWeightInfo() {
    let data = this.props.patientCore
    if(!data)
      return
    let bmiValues = this.calculateBMIValues(data.height, data.weight_update || data.weight, data.gender, moment().diff(moment(data.dob), 'years'))
    let bmiRemark
    if(bmiValues.bmi >= BMI_RANGES[0][0] && bmiValues.bmi <= BMI_RANGES[0][1])
      bmiRemark = 'Underweight'
    else if (bmiValues.bmi >= BMI_RANGES[1][0] && bmiValues.bmi <= BMI_RANGES[1][1])
      bmiRemark = 'Normal/Healthy'
    else if (bmiValues.bmi >= BMI_RANGES[2][0] && bmiValues.bmi <= BMI_RANGES[2][1])
      bmiRemark = 'Overweight'
    else
      bmiRemark = 'Obese'

    let updates = this.props.patientProgress ? this.props.patientProgress.updates : [{waist: 'N/A'}]
    let {patientProgress} = this.props
    let waist
    //either get if from the updates or from the wellness screening
    if(patientProgress && patientProgress.updates && patientProgress.updates.length > 0) {
      waist = updates[0].waist
    } else if(data.body_composition && data.body_composition.waist) {
      waist = data.body_composition.waist
    }
    return (
      <div key={'weight'} className={Styles.lifestyleEntry}>
        <div className={Styles.columnIcon}>
          <FontAwesomeIcon icon={faWeight} style={{color: 'white', fontSize: 20}}/>
          <p className={Styles.rowIconText2}>Weight</p>
        </div>
        <div className={Styles.lifestyleText}>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <p style={{fontWeight: 'bold', marginRight: 10}}>BMI: </p>
            <p style={{wordBreak: 'break-all'}}>{bmiValues.bmi.toFixed(1)}<span style={{marginLeft: 4}}>({bmiRemark})</span></p>
          </div>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <p style={{fontWeight: 'bold', marginRight: 10}}>Body Fat: </p>
            <p style={{wordBreak: 'break-all'}}>{bmiValues.bfp.toFixed(1)} %</p>
          </div>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <p style={{fontWeight: 'bold', marginRight: 10}}>Waist: </p>
            <p style={{wordBreak: 'break-all'}}>{waist} {waist ? 'inches':''}</p>
          </div>
        </div>
      </div>
    )
  }

  renderSleepInfo() {
    let data = this.props.patientCore
    if(!data || !data.sleep || !data.energy)
      return

    let vitalsData = this.props.patientProgress ? this.props.patientProgress.vitals : []
    let totalDays = 0
    let totalSleep = 0
    vitalsData.forEach(each => {
      if(each.sleep) {
        totalSleep += Number(each.sleep)
        totalDays += 1
      }
    })

    let avg = totalDays > 0 ? (totalSleep/totalDays).toFixed(1) : 'N/A'

    return (
      <div key={'sleep'} className={Styles.lifestyleEntry}>
        <div className={Styles.columnIcon}>
          <FontAwesomeIcon icon={faBed} style={{color: 'white', fontSize: 20}}/>
          <p className={Styles.rowIconText2}>Sleep</p>
        </div>
        <div className={Styles.lifestyleText}>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <p style={{fontWeight: 'bold', marginRight: 10}}>Type: </p>
            <p style={{wordBreak: 'break-all'}}>{data.sleep.habits}</p>
          </div>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <p style={{fontWeight: 'bold', marginRight: 10}}>Average: </p>
            <p style={{wordBreak: 'break-all'}}>{avg}hrs past month</p>
          </div>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <p style={{fontWeight: 'bold', marginRight: 10}}>Sleep goal: </p>
            <p style={{wordBreak: 'break-all'}}>{data.sleep.goal}hrs</p>
          </div>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <p style={{fontWeight: 'bold', marginRight: 10}}>Highest energy during these times: <span style={{fontWeight: 'normal'}}>{data.energy.timings}</span></p>
          </div>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <p style={{fontWeight: 'bold', marginRight: 10}}>Trouble sleeping at night: <span style={{fontWeight: 'normal'}}>{data.sleep.statement === 'Have trouble falling asleep' ? 'Yes' : 'No'}</span></p>
          </div>
        </div>
      </div>
    )
  }

  renderPhysicalInfo() {
    let data = this.props.patientCore
    if(!data || !data.exercise_style || !data.exercises)
      return

    let activities = data.exercises.map((x, index) => <span key={index.toString()} >{x}{index === data.exercises.length-1 ? '':', '}</span>)

    return (
      <div key={'physical'} className={Styles.lifestyleEntry}>
        <div className={Styles.columnIcon}>
          <FontAwesomeIcon icon={faRunning} style={{color: 'white', fontSize: 20}}/>
          <p className={Styles.rowIconText2}>Physical</p>
        </div>
        <div className={Styles.lifestyleText}>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <p style={{fontWeight: 'bold', marginRight: 10}}>Physically able to exercise: <span style={{fontWeight: 'normal'}}>{data.exercise_style.ability}</span></p>
          </div>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <p style={{fontWeight: 'bold', marginRight: 10}}>Activity level: </p>
            <p style={{wordBreak: 'break-all'}}>{data.exercise_style.style}</p>
          </div>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <p style={{fontWeight: 'bold', marginRight: 10}}>Preferred activities: <span style={{fontWeight: 'normal'}}>{activities}</span></p>
          </div>
        </div>
      </div>
    )
  }

  renderEmotionalInfo() {
    let data = this.props.patientWellnessScreening
    if(!data || !data.stress || !data.relationships)
      return
    let stress_about = data.stress.about.map((x, index) => <span key={index.toString()} >{x}{index === data.stress.about.length-1 ? '':', '}</span>)
    let stress_events = data.stress.events.map((x, index) => <span key={index.toString()}>{x}{index === data.stress.events.length-1 ? '':', '}</span>)
    let stress_management = data.stress.management.map((x, index) => <span key={index.toString()}>{x}{index === data.stress.management.length-1 ? '':', '}</span>)
    return (
      <div key={'emotional'} className={Styles.lifestyleEntry}>
        <div className={Styles.columnIcon}>
          <FontAwesomeIcon icon={faBrain} style={{color: 'white', fontSize: 20}}/>
          <p className={Styles.rowIconText2}>Emotional</p>
        </div>
        <div className={Styles.lifestyleText}>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <p style={{fontSize: 12}}>Test taken on {moment(data.created_at).format('MMM Do YYYY')}</p>
            <p style={{fontWeight: 'bold'}}>Relationship score: <span style={{fontWeight: 'normal'}}>{this.calculateRelationshipScore(data.relationships.quality)}%</span></p>
          </div>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <p style={{fontWeight: 'bold'}}>Key stressors: <span style={{fontWeight: 'normal'}}>{stress_about}</span></p>
          </div>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <p style={{fontWeight: 'bold'}}>Stress relievers: <span style={{fontWeight: 'normal'}}>{stress_management}</span></p>
          </div>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <p style={{fontWeight: 'bold'}}>Past stressful events: <span style={{fontWeight: 'normal'}}>{stress_events}</span></p>
          </div>
        </div>
      </div>
    )
  }

  renderLifestyleRow(data) {
    let toReturn = []
    data.values.forEach(each => {
      switch(each) {
        case 'Nutrition':
          toReturn.push(this.renderNutritionInfo())
          break
        case 'Weight':
          toReturn.push(this.renderWeightInfo())
          break
        case 'Sleep':
          toReturn.push(this.renderSleepInfo())
          break
        case 'Physical':
          toReturn.push(this.renderPhysicalInfo())
          break
        case 'Emotional':
          toReturn.push(this.renderEmotionalInfo())
          break
        default:
      }
    })

    return toReturn
  }

  renderRow(rowData) {
    return (
      <div key={rowData.label} className={Styles.sectionDivider} style={rowData.label === 'Goals' ? {borderBottomWidth: 0} : {}}>
        <div className={Styles.rowIcon}>
          <FontAwesomeIcon icon={rowData.logo} style={{color: 'white', fontSize: rowData.label === 'Lifestyle' ? 40 : 30}}/>
          <p className={Styles.rowIconText}>{rowData.label}</p>
        </div>

        {
          rowData.label === 'Lifestyle' ?
            <div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', flexWrap: 'wrap', paddingTop: 10}}>
              {this.renderLifestyleRow(rowData)}
            </div>
            :
            <div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', flexWrap: 'wrap'}}>
              <div className={Styles.rowColumnEntry}>
                {this.renderFieldEntries(rowData["1"])}
              </div>

              <div className={Styles.rowColumnEntry}>
                {this.renderFieldEntries(rowData["2"])}
              </div>
              {
                rowData.label === 'Social Determinants'
                &&
                <div className={Styles.rowColumnEntry} style={{marginTop: 40}}>
                  {this.renderFieldEntries(rowData["3"])}
                </div>
              }
            </div>
        }

      </div>
    )
  }

  renderPatientDetails() {
    let toReturn = []
    PATIENT_DATA.forEach(each => {
      toReturn.push(this.renderRow(each))
    })

    toReturn.push(
      <div key={'bottom'} id={'bottom'} style={{visibility: 'hidden', marginTop: 400}}>
        asdasd
      </div>
    )

    return toReturn
  }

  render() {
    return (
      <div className={GlobalStyles.container}>
        {
          this.state.loading ?
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100%', height: 200}}>
              <div className={GlobalStyles.loader} />
            </div>
            :
            <div>
              <PatientInfoStrip
                medications={this.props.medications}
                conditions={this.props.conditions}
                patient={this.props.patient}
                patientCore={this.props.patientCore}
                color={this.props.color}
                cohorts={this.props.mongoUser ? this.props.mongoUser.cohorts : []}
              />

              {this.renderPatientDetails()}
            </div>
        }

      </div>
    )
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    patient: state.patientReducer.patient,
    patientCore: state.patientReducer.patientCore,
    patientProgress: state.patientReducer.patientProgress,
    medications: state.patientReducer.medications,
    conditions: state.patientReducer.conditions,
    goals: state.patientReducer.goals,
    token: state.authReducer.token,
    color: state.patientReducer.color,
    patientWellnessScreening: state.patientReducer.patientWellnessScreening,
    patientCalculatedProgress: state.patientReducer.patientCalculatedProgress,
    mongoUser: state.userReducer.mongoUser
  }
}

const mapDispatchToProps = { stopTimer }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile)

