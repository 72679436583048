const values = {
  // BACKEND_URL: 'http://localhost:9000/',
  // WEB_HOST: 'http://localhost:3008/',
  // CHANGE_BACKEND_URL: 'https://c1ad-54-200-111-12.ngrok.io/',
  // CHANGE_BACKEND_URL: 'http://localhost:9000/',
  
  // BACKEND_URL: 'https://18ab-54-200-111-12.ngrok.io/',
  // WEB_HOST: 'https://b491-124-123-105-157.ngrok.io/',
  
  // dev config
  // ENV: 'DEV',
  // PRACTICE_ID: '633a908fe917d01d1cf7834a',
  // CHANGE_BACKEND_URL:
  //   'https://change-dev.ailahealth.net/',
  // BACKEND_URL: 'https://838nndzp3h.execute-api.us-west-2.amazonaws.com/dev/',
  // WEB_HOST: 'https://portal-dev.ailahealth.com/',
  // CHAT_ENDPOINT: 'https://chat-test.ailahealth.net',
  // STRIPE_PUBLISHABLE_API_KEY:
  //   'pk_test_51IJ0JbL8GbHIX9Uz9XT0EWjSTRAqtDULVU24F7XZduBQTwYCV1gItRKayWubcMwBsTIWVmEqMUX0UVFyeu4qEJr800iOFzeoe5',
  // STRIPE_PRICE_ID_1MONTH: 'price_1ILkhOL8GbHIX9Uzt5uhVDl7',
  // STRIPE_AILA_CLINIC_1MONTH: 'price_1JkxTdL8GbHIX9UzfrEg98CD',
  // STRIPE_AILA_CLINIC_3MONTH: 'price_1JkxlUL8GbHIX9UzSPQBkS4f',
  // STRIPE_CCFM_LONG_COVID_3MONTH: 'price_1JkytcL8GbHIX9UztYWtonon',
  // // APPOINTMENT_BOOKING_PROVIDER_ID: 'SpPHAgsw7QUoIECxDOW93AvqKHI2',
  // APPOINTMENT_BOOKING_PROVIDER_ID: 'zpYEj2ht5TNWlTTHgoj4GxvrTEz2',
  // APPOINTMENT_BOOKING_DOCTOR_ID: 'SpPHAgsw7QUoIECxDOW93AvqKHI2',
  // STRIPE_MEMBERSHIP_MONTHLY: 'price_1JyfitL8GbHIX9UzfrZoj5Lo',
  // STRIPE_MEMBERSHIP_ANNUALLY: 'price_1LMppnL8GbHIX9UzPNwtMYRY',
  // STRIPE_PREMIUM_MONTHLY: 'price_1JkxTdL8GbHIX9UzfrEg98CD',
  // STRIPE_PREMIUM_ANNUALLY: 'price_1LMpsbL8GbHIX9Uz3UVCZhGU',

  // // APPOINTMENT_BOOKING_PROVIDER_ID: '9xYpevvHIhXqGjbZ6NDkLIHpAiA3',
  // STRIPE_AILA_APPOINTMENT_BASIC: 'price_1JyfnCL8GbHIX9Uz20Q30Vzm',
  // STRIPE_AILA_APPOINTMENT_MEMBERSHIP: 'price_1JyfnwL8GbHIX9UzgXhuOsbh',
  // AILA_CLINIC_15: 75,
  // AILA_CLINIC_30: 150,
  // AILA_CLINIC_45: 225,
  // AILA_CLINIC_60: 300,
  // AILA_COACH_30: 50,
  // AILA_COACH_60: 100,
  // CHANGE_USER_ID: 'p_ailahealthclc',
  // CHANGE_PWD: 'Practice00!',
  // CHANGE_ORG_ID: '3004405780',
  // CHANGE_IFRAME_URL: 'https://cli-cert.changehealthcare.com/servlet/DxLogin',


  // test config
  ENV: 'TEST',
  BACKEND_URL: 'https://tvo70q0ndd.execute-api.us-west-2.amazonaws.com/test/',
  WEB_HOST: 'https://portal-test.ailahealth.com/',

  // prod config
  // ENV: 'PROD',
  // BACKEND_URL: 'https://8nvbgo1q4l.execute-api.us-west-2.amazonaws.com/prod/',
  // WEB_HOST: 'https://portal.ailahealth.com/',
  CHANGE_BACKEND_URL:'https://change-prod.ailahealth.net/',
  CHAT_ENDPOINT: 'https://chat.ailahealth.net',
  STRIPE_PUBLISHABLE_API_KEY:
    'pk_live_51IJ0JbL8GbHIX9UzKuUz2AHHG32EeX3DBy33yjGnSUiQTo4X4iN15KVUsiwEv3eeE0Rt72oYfAAQQ4AeCIAaKENK00wL9tEk2i',
  STRIPE_AILA_CLINIC_1MONTH: 'price_1KTWQIL8GbHIX9UzLQIHAAWM',
  STRIPE_AILA_CLINIC_3MONTH: 'price_1KTWRaL8GbHIX9UzqD03kWb9',
  STRIPE_CCFM_LONG_COVID_3MONTH: 'price_1KTWN8L8GbHIX9UznIB40muJ',
  STRIPE_MEMBERSHIP_MONTHLY: 'price_1MjoOXL8GbHIX9Uzeyyw6O2X',
  STRIPE_MEMBERSHIP_ANNUALLY: 'price_1MjoPXL8GbHIX9Uz43HALBTz',
  STRIPE_PREMIUM_MONTHLY: 'price_1LMpsbL8GbHIX9UzKUO1hwW3',
  STRIPE_PREMIUM_ANNUALLY: 'price_1LMpsbL8GbHIX9Uz3UVCZhGU',
  APPOINTMENT_BOOKING_PROVIDER_ID: '3OK2DTvHHIeUiTrtpVAYMo2kf4m1',
  APPOINTMENT_BOOKING_DOCTOR_ID: 'dWk3nt6DJ9QKw1zZsdYgzKXF7Fn2',
  STRIPE_AILA_APPOINTMENT_BASIC: 'price_1KTWMGL8GbHIX9Uz3TXO4sgX',
  STRIPE_AILA_APPOINTMENT_MEMBERSHIP: 'price_1KTWIRL8GbHIX9UzZ5uwuWCb',
  AILA_CLINIC_15: 75,
  AILA_CLINIC_30: 150,
  AILA_CLINIC_45: 225,
  AILA_CLINIC_60: 300,
  AILA_COACH_30: 50,
  AILA_COACH_60: 100,
  PRACTICE_ID: '635bfdb25234070ea0376e99',
  CHANGE_ORG_ID: '4015227894',
  CHANGE_USER_ID: 'ahcsclc_all',
  CHANGE_PWD: 'ozsxm905',
  CHANGE_IFRAME_URL: 'https://clinician.changehealthcare.com/servlet/DxLogin',
  
  BRANCH_DEEPLINK_KEY: 'key_live_njXe3nlkO40NgTsI2X4dubgeBDaMfdGB',
  VAPID_KEY:
    'BC8lL1_oxEcza_mewWYLb7nSqtbmjn_InWgJo9-TZs-Q9LcbyCZm3pZP4UxuvlxDcIIaiAB0zVTg_RDOqz34DJU',
  SDOH_HOST: 'https://sdoh.ailahealth.net/',
  // NYLAS_CLIENT_ID: '4f9p9dp7qdw7l5tzxq2qhyisn', //test app
  NYLAS_CLIENT_ID: 'blbkbv5m7rsjo8z1423fxcltx', // production app
  GOOGLE_API_KEY: 'AIzaSyAlmct1SHhXm2OgLlUfShrHZjNFj3fvDNM',
  APP_STORE_ID: '1525982613',
  APP_BUNDLE_ID: 'AilaHealth.Aila-Health',
  FIREBASE_API_KEY: 'AIzaSyCFbraNxCkmEcppXB8gTTgsRv8FkU2qff4',
  NYLAS_API_ENDPOINT: 'https://api.nylas.com/',
  ALGOLIA_APP_ID: 'PP1P2QW79F',
  ALGOLIA_API_KEY: '743073a3068d1c7475f5e49d5772e815',
  GOOGLE_ADS_ID: 'AW-607596551',
  
  //LINKS TO BE USED IN SIGNUP PAGE 
  TOS_LINK: 'https://www.ailahealth.com/termsofuse',
  PRIVACY_POLICY_LINK: 'https://www.ailahealth.com/privacypolicy',
  TELEHEALTH_POLICY: 'https://www.ailahealth.com/telehealthconsent',
  DOWNLOAD_LINK: 'https://www.ailahealth.com/ailahealthapp',

}
export default values