import React, { Component, Children } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import Modal from 'react-modal'
import GlobalStyles from '../../pages/styles/global.module.scss'
import { connect } from 'react-redux'
import Config from '../../config'
import values from '../../values'
import Styles from './MyCalendar.module.scss'
import Button from 'react-bootstrap/Button'

import { subscriptionPlans } from '../../metadata'

const axios = require('axios')

const localizer = momentLocalizer(moment)

const SLOT_AVAILABLE_MODAL_STYLES = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '40%',
    height: '80%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
  },
}

class MyCalendar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalIsOpen: false,
      modalOption: {},
      modalSelectOption: {},
      fileCount: 1,
      numPages: null,
      pageNumber: 1,
      title: '',
      image_url: '',
      content_url: '',
      video_url: '',
      edu_content: [],
      finalMap: [],
      loading: false,
      selectedContent: [],
      selectedValue: '',
      medications: [],
      listEducation: [],
      availability: {},
      appointmentData: [],
      markedAvailableDates: {},
      showSlotAvailableModal: false,
      selectedDate: '',
      availDurationArr: [],
      selectedDuration: '',
      selectedTime: '',
      appointmentMonthsMap: {},
      patientDetails: '',
      googleEvents: [],
    }

    this.getAppointmentList = this.getAppointmentList.bind(this)
    this.formatAvailability = this.formatAvailability.bind(this)
    this.splitAvailTiming = this.splitAvailTiming.bind(this)
    this.getBookedAppointments = this.getBookedAppointments.bind(this)
    this.calculateAvailableDates = this.calculateAvailableDates.bind(this)
    this.onConfirmTimeClick = this.onConfirmTimeClick.bind(this)
    this.getUserDetails = this.getUserDetails.bind(this)
    this.getEvents = this.getEvents.bind(this)
  }

  componentDidMount() {
    if (this.props?.doctorDetails?.google_calendar_id) {
      this.getEvents()
    } else {
      this.formatAvailability()
    }
    this.getAppointmentList()
    this.getUserDetails()
  }

  //GET GOOGLE CALENDAR EVENTS
  async getEvents() {
    //EVENTS FROM GOOGLE CALENDAR
    const CALENDAR_ID = this.props?.doctorDetails?.google_calendar_id
    const API_KEY = Config.GOOGLE_API_KEY
    let url = `https://www.googleapis.com/calendar/v3/calendars/${CALENDAR_ID}/events?key=${API_KEY}`
    axios({
      method: 'get',
      url,
      params: {
        maxResults: 2500,
        showDeleted: true,
        timeMin: moment().toISOString(),
        timeMax: moment().add(3, 'M').toISOString(),
      },
    })
      .then(({ data }) => {
        this.setState({ googleEvents: data?.items || [] })
        console.log('google cal events received')
        this.formatAvailability()
      })
      .catch((err) => {
        console.log('error when getting GOOGLE CAL EVENTS', err)
        this.formatAvailability()
      })
  }

  //CREATE EVENT IN GOOGLE CALENDAR AFTER APPOINTMENT SCHEDULED
  createEvent(event, access_token, event_id) {
    var gapi = window.gapi
    const CALENDAR_ID = this.props?.doctorDetails?.google_calendar_id
    gapi.load('client:auth2', () => {
      console.log('loading client')

      gapi.client.load('calendar', 'v3', () => {
        console.log('google calendar signed in')
        try {
          gapi.auth.setToken({
            access_token: access_token?.access_token,
          })
          var request = gapi.client.calendar.events.insert({
            calendarId: CALENDAR_ID,
            resource: event,
          })

          request.execute((event) => {
            console.log('EVENT CREATED:::', event)
            let config = {
              method: 'put',
              url: `${Config.BACKEND_URL}appointment`,
              headers: {
                Authorization: `JWT ${this.props.token}`,
                'Content-Type': 'application/json',
              },
              data: { google_event_id: event?.id, event_id: event_id },
            }
            axios(config)
              .then(({ data }) => {
                console.log('google event id saved in backend')
              })
              .catch((err) => {
                console.log('error when saving google event id', err)
              })
          })
        } catch (error) {
          console.log('Event creation on google calendar failed', error)
        }
      })
    })
  }

  //CREATE EVENT IN GOOGLE CALENDAR AFTER APPOINTMENT SCHEDULED
  updateEvent(event, access_token, event_id, google_event_id) {
    var gapi = window.gapi
    const CALENDAR_ID = this.props?.doctorDetails?.google_calendar_id
    gapi.load('client:auth2', () => {
      console.log('loading client')

      gapi.client.load('calendar', 'v3', () => {
        console.log('google calendar signed in', event)
        try {
          gapi.auth.setToken({
            access_token: access_token?.access_token,
          })
          var request = gapi.client.calendar.events.update({
            calendarId: CALENDAR_ID,
            eventId: google_event_id,
            resource: event,
          })

          request.execute((event) => {
            console.log('EVENT UPDATED:::', event)
          })
        } catch (error) {
          console.log('Event creation on google calendar failed', error)
        }
      })
    })
  }

  componentDidUpdate(prevProps) {
    if (
      this.props?.doctorDetails?.firebase_id !==
      prevProps?.doctorDetails?.firebase_id
    ) {
      this.formatAvailability()
    }
  }

  async getUserDetails() {
    const url = `${Config.BACKEND_URL}doctor/patients`
    try {
      const response = await axios({
        method: 'get',
        headers: {
          x_patient_id: this.props.patientId,
          Authorization: `JWT ${this.props.token}`,
        },
        url,
      })
      // console.log('user id', response)
      const user = response.data
      this.setState({ patientDetails: user })
      const { stripe } = user
      // console.log('got user data', user)
    } catch (err) {
      console.log('error when getting user details', err)
    }
  }

  getAppointmentList = () => {
    var config = {
      method: 'get',
      url: `${Config.BACKEND_URL}patient/appointments`,
      headers: {
        Authorization: `JWT ${this.props.token}`,
        x_patient_id: this.props.patientId,
      },
    }

    axios(config)
      .then(({ data }) => {
        if (data) {
          let appointments = data || []
          let appointmentMonthsMap = {}
          // let appointmentMonthsMap = {
          //   'Jan2021': {
          //     healthCoachAppointmentsMonth: [],
          //     nonHealthCoachAppointmentsMonth = []
          //   },
          // }
          appointments.forEach((appointment) => {
            let monthName = moment(appointment.start).format('MMMYYYY')
            if (appointmentMonthsMap?.[monthName] === undefined) {
              appointmentMonthsMap[monthName] = {
                healthCoachAppointments: 0,
                nonHealthCoachAppointments: 0,
              }
            }
            if (appointment?.user_type?.toLowerCase() !== 'health coach') {
              ++appointmentMonthsMap[monthName]['nonHealthCoachAppointments']
            } else if (
              appointment?.user_type?.toLowerCase() === 'health coach'
            ) {
              ++appointmentMonthsMap[monthName]['healthCoachAppointments']
            }
          })
          this.setState({ appointmentMonthsMap: appointmentMonthsMap })
          // console.log('AAPPT MAP::', appointmentMonthsMap)
          // setAppointmentsMonthsMap(appointmentMonthsMap)
        }
      })
      .catch(function (error) {
        console.log('error when getting patient appointments', error)
      })
  }

  // formatting the available dates set up by providers to divide into 30 minutes chunks
  formatAvailability = () => {
    const availability = this.props.doctorDetails.availability
    this.setState({ loading: true })
    let availList = {}
    if (availability) {
      Object.keys(availability).forEach((avail) => {
        // avail = Mon, Tue, Wed ...
        availList[avail] = []
        if (availability?.[avail]?.length) {
          // In day object get the time from the array
          // availability[avail] &&
          //   availability[avail].forEach((time) => {
          //     let availTimeStart = moment(time?.start)
          //     let availTimeEnd = moment(time?.end)
          //     // splitting the block of time into 30 minutes chunks
          //     while (availTimeStart.diff(availTimeEnd) < 0) {
          //       availList[avail].push(moment(availTimeStart).format('HH:mm'))
          //       availTimeStart.add(30, 'minutes')
          //     }
          //   })
          availList[avail] = this.splitAvailTiming(
            availability[avail],
            availList[avail],
          )
          availList[avail] = availList[avail].sort(
            (a, b) => a.availFrom - b.availFrom,
          )
        }
      })
    }
    // console.log('FORMATED AVAIL: ', availList)
    this.setState({ loading: false, availableTimeList: availList })
    this.getBookedAppointments()
  }

  // splitting epoch start and end time to [hh:mm] array
  splitAvailTiming = (availTimeEpoch, availTimeArray) => {
    availTimeEpoch &&
      availTimeEpoch.length &&
      availTimeEpoch.forEach((time) => {
        let availTimeStart = moment(time?.start)
        let availTimeEnd = moment(time?.end)
        // splitting the block of time into 30 minutes chunks
        while (availTimeStart.diff(availTimeEnd) < 0) {
          availTimeArray.push({
            availFrom: moment(availTimeStart).format('HH:mm'),
            availTill: moment(availTimeStart)
              .add(15, 'minutes')
              .format('HH:mm'),
            availTillDuration: availTimeEnd.diff(availTimeStart, 'minutes'),
          })
          availTimeStart.add(15, 'minutes')
        }
      })
    return availTimeArray
  }

  getBookedAppointments = () => {
    this.setState({ loading: true })
    let config = {
      method: 'get',
      url: `${Config.BACKEND_URL}providers/appointments`,
      headers: {
        Authorization: `JWT ${this.props.token}`,
        x_firebase_id: this.props.patientId,
        x_doctor_id: this.props.doctorDetails.firebase_id,
      },
      params: {
        startDate: moment().valueOf(),
      },
    }
    axios(config)
      .then(({ data }) => {
        this.setState({ loading: false, appointmentData: data })
        this.calculateAvailableDates()
      })
      .catch((err) => {
        console.log('error while fetching appointment data: ', err)
        this.setState({ loading: false })
        this.calculateAvailableDates()
      })
  }

  // calculating the available time for each day for the next 2 months

  calculateAvailableDates = () => {
    let availableDates = {}
    let currentDate = moment()
    let lastDate = moment().add(2, 'M')
    const availableTimeList = this.state.availableTimeList
    const appointmentData = this.state.appointmentData
    const availability_override =
      this.props.doctorDetails?.availability_override || {}
    // color code 2 months for available status
    while (currentDate.diff(lastDate) <= 0) {
      let tempAvaiList =
        availableTimeList?.[moment(currentDate).format('ddd')] || []
      // checking for overriding dates using epoch time and override the default time
      let overrideDate = Object.keys(availability_override)?.find((time) =>
        currentDate.isSame(moment.unix(time / 1000), 'day'),
      )
      if (overrideDate?.length) {
        console.log(availability_override[overrideDate])
        let tempTimeArr = []
        tempAvaiList = this.splitAvailTiming(
          availability_override[overrideDate],
          tempTimeArr,
        )
      }
      let currentDayBookedAppointments =
        appointmentData?.filter((date) => {
          return moment(date.start).isSame(moment(currentDate), 'date')
        }) || []

      let googleBookedAppointments =
        this.state.googleEvents?.filter((data) => {
          let temp = moment
            .utc(data?.start?.dateTime)
            .format('YYYY-MM-DD HH:mm:ss')
          return (
            'date' in data?.start ||
            moment(temp).isSame(moment(currentDate), 'date')
          )
        }) || []

      // present time should be less than the available time
      if (moment().isSame(moment(currentDate), 'day') && tempAvaiList.length) {
        tempAvaiList = tempAvaiList.filter(
          (time) => time.availFrom > moment().format('HH:mm'),
        )
      }

      // if the available slot is not available for that day
      if (tempAvaiList?.length === 0) {
        availableDates[moment(currentDate).format('YYYY-MM-DD')] = {
          disabled: true,
          disableTouchEvent: true,
        }
        currentDate.add(1, 'days')
        continue
      }

      // if there is no pre-booked appointments and there is slots available
      if (tempAvaiList.length !== 0 && appointmentData.length === 0) {
        availableDates[moment(currentDate).format('YYYY-MM-DD')] = {
          startingDay: true,
          color: 'red',
          endingDay: true,
          textColor: 'white',
          availSlot: tempAvaiList,
        }
        currentDate.add(1, 'days')
        continue
      }

      // to block the pre-booked appointment time slots from the provider available time slot
      if (currentDayBookedAppointments?.length) {
        currentDayBookedAppointments.forEach((appointmentTime) => {
          // removing the pre-booked appointment time slot
          tempAvaiList = tempAvaiList.filter(
            (item) =>
              !(
                moment(appointmentTime.start).format('HH:mm') <=
                  item.availFrom &&
                moment(appointmentTime.end).format('HH:mm') >= item.availTill
              ),
          )
        })
      }

      // to block the pre-booked appointment time slots from the provider available time slot
      if (googleBookedAppointments?.length) {
        googleBookedAppointments.forEach((appointmentTime) => {
          if ('date' in appointmentTime?.start) {
            let start = moment
              .utc(appointmentTime?.start?.date)
              .format('YYYY-MM-DD HH:mm:ss')
            let end = moment
              .utc(appointmentTime?.end?.date)
              .format('YYYY-MM-DD HH:mm:ss')
            while (moment(end).isSameOrAfter(moment(start), 'date')) {
              if (moment(start).isSame(moment(currentDate), 'date')) {
                tempAvaiList = this.splitAvailTiming([], [])
              }
              start = moment.utc(start).add(1, 'days').format()
            }
            return
          }
          tempAvaiList = tempAvaiList.filter(
            (item) =>
              !(
                moment(appointmentTime?.start?.dateTime).format('HH:mm') <=
                  item.availFrom &&
                moment(appointmentTime?.end?.dateTime).format('HH:mm') >=
                  item.availTill
              ),
          )
        })
      }

      if (tempAvaiList.length) {
        availableDates[moment(currentDate).format('YYYY-MM-DD')] = {
          startingDay: true,
          color: 'green',
          endingDay: true,
          textColor: 'white',
          availSlot: tempAvaiList,
        }
      } else {
        availableDates[moment(currentDate).format('YYYY-MM-DD')] = {
          disabled: true,
          disableTouchEvent: true,
        }
      }

      currentDate.add(1, 'days')
      // else {
      //   currentDate.add(1, 'days')
      //   availableDates[(moment(currentDate).format('YYYY-MM-DD'))] = {disabled: true, disableTouchEvent: true}
      // }
    }
    this.setState({ markedAvailableDates: availableDates })
  }

  checkStripeDetails = () => {
    let { patientDetails } = this.state
    const user = patientDetails
    if (user?.stripe && user?.stripe?.customer_id) {
      console.log('stripe customer id present')
      return false
    } else {
      console.log('error : no stripe customer id ')
      return true
    }
  }

  onSelectSlot(slot) {
    // let showCardModal = this.checkStripeDetails()
    // if (!this.props?.fromWebFlow && showCardModal) {
    //   this.props.parentCallback({
    //     success: false,
    //     // config: config,
    //   })
    //   this.setState({
    //     selectedDate: '',
    //     selectedTime: '',
    //     showSlotAvailableModal: false,
    //   })

    //   return
    // }

    const AVAILABLE_DATES = Object.keys(this.state.markedAvailableDates)
    const markedDates = this.state.markedAvailableDates

    const selectedDate = moment(slot.start).format('YYYY-MM-DD')

    AVAILABLE_DATES.includes(selectedDate) &&
    !markedDates[selectedDate].disabled
      ? this.setState({
          selectedDate: selectedDate,
          showSlotAvailableModal: true,
        })
      : this.setState({
          selectedDate: '',
          showSlotAvailableModal: false,
        })
  }

  onModalCloseRequested() {
    this.setState({
      showSlotAvailableModal: false,
      selectedTime: '',
      selectedDuration: '',
    })
  }

  onConfirmTimeClick = () => {
    let { doctorDetails, patientData, fromProvider } = this.props
    let {
      appointmentMonthsMap,
      selectedTime,
      patientDetails,
      selectedDuration,
    } = this.state

    // console.log('TIME SELECTED:::', selectedTime, typeof selectedTime, this.state.selectedDuration)
    // console.log(doctorDetails)
    // console.log(patientDetails)
    if (this.props?.axiosConfig) {
      let config = this.props?.axiosConfig
      // console.log('CONFIG::', config)
      config.data.start = moment(selectedTime).valueOf()
      config.data.end = moment(selectedTime)
        .add(selectedDuration, 'minutes')
        .valueOf()
      config.data.reason = config?.data?.reason?.length
        ? config?.data?.reason
        : 'Appointment From Web'

      let monthName = moment(config?.data?.start).format('MMMYYYY') // Jan2021, Feb2021, ...
      let docType =
        doctorDetails?.user_type?.toLowerCase() !== 'health coach'
          ? 'nonHealthCoachAppointments'
          : 'healthCoachAppointments'
      let appointmentCount
      if (doctorDetails?.user_type?.toLowerCase() !== 'health coach')
        appointmentCount = appointmentMonthsMap?.[monthName]?.[docType] || 0
      else appointmentCount = appointmentMonthsMap?.[monthName]?.[docType] || 0 // if there is no appointments booked for that month
      //  = appointmentMonthsMap?.[monthName]?.[docType] || 0
      console.log(appointmentCount) // if there is no appointments booked for that month
      let isPaid = false
      let isInsuranceSet =
        patientDetails?.insurance?.insurance_company ||
        patientDetails?.insurance?.[0]?.insurance_company
          ? true
          : false
      const userPremiumPlan =
        (patientDetails?.coaching?.active &&
          !patientDetails?.coaching?.hasOwnProperty('plan')) ||
        (patientDetails?.coaching?.active &&
          patientDetails?.coaching?.plan === subscriptionPlans.PREMIUM)
      if (doctorDetails?.user_type?.toLowerCase() !== 'health coach') {
        if (userPremiumPlan && appointmentCount === 0) {
          // if there is no health coach appointment for the month and coaching plan is premium set paid to true
          isPaid = true
          config.data.free_appointment = true
        }
      } else if (
        doctorDetails?.user_type?.toLowerCase() !== 'wellness coach' &&
        (userPremiumPlan ||
          patientDetails?.coaching?.plan === subscriptionPlans.MEMBERSHIP) &&
          appointmentCount === 0
          ) {
            // for health coach
            isPaid = true
            // if there is no health coach appointment for the month and if the coaching plan is premium or membership set paid to true
            config.data.free_appointment = true
          }
      if (isInsuranceSet) {
        isPaid = true // if the insurance is set then treat the appointment as paid
        config.data.insurance_claimed = false // insurance not yet claimed, claim after appointment is over
      }
      // config.data.appointment_payment_paid = isPaid
      config.data.appointment_payment_paid = isPaid

      // to calculate the fee and send it to mobile app if the visit is not free
      if (
        doctorDetails?.user_type?.toLowerCase() !== 'health coach' &&
        doctorDetails?.user_type?.toLowerCase() !== 'wellness coach' &&
        !config.data.free_appointment
      ) {
        // clinical visit fee
        switch (selectedDuration) {
          case 15:
            config.data.visit_fee = Config.AILA_CLINIC_15
            break
          case 30:
            config.data.visit_fee = Config.AILA_CLINIC_30
            break
          case 45:
            config.data.visit_fee = Config.AILA_CLINIC_45
            break
          case 60:
            config.data.visit_fee = Config.AILA_CLINIC_60
            break

          default:
            config.data.visit_fee = Config.AILA_CLINIC_30
            break
        }
      } else if (!config.data.free_appointment) {
        // wellness and health coach fee
        switch (selectedDuration) {
          case 30:
            config.data.visit_fee = Config.AILA_COACH_30
            break
          case 60:
            config.data.visit_fee = Config.AILA_COACH_60
            break

          default:
            config.data.visit_fee = Config.AILA_COACH_30
            break
        }
      }
      // console.log(config)

      // if (this.props?.fromWebFlow) {
      //   this.props.parentCallback({
      //     success: true,
      //     config: config,
      //   })
      //   this.setState({
      //     selectedDate: '',
      //     selectedTime: '',
      //     showSlotAvailableModal: false,
      //   })
      // }

      var google_event = {
        summary: 'Aila Health Visit',
        description: 'https://portal.ailahealth.com/',
        start: {
          dateTime: moment(selectedTime).format(),
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        end: {
          dateTime: moment(selectedTime)
            .add(selectedDuration, 'minutes')
            .format(),
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        attendees: [
          // { email: 'fathima@ailahealth.com' },
          // {'email': 'sbrin@example.com'}
        ],
      }

      axios(config)
        .then(({ data }) => {
          // console.log('successfull:', data)
          this.props.parentCallback({
            success: true,
            // event_id: data?.event_id,
            event_data: config.data,
            event_doctor_data: doctorDetails,
          })
          if (
            data?.access_token &&
            this.props?.doctorDetails?.google_calendar_id
          ) {
            config.data?.google_event_id
              ? this.updateEvent(
                  google_event,
                  data?.access_token,
                  data?.event_id,
                  config.data?.google_event_id,
                )
              : this.createEvent(
                  google_event,
                  data?.access_token,
                  data?.event_id,
                )
          }
          this.setState({
            selectedDate: '',
            selectedTime: '',
            showSlotAvailableModal: false,
          })
        })
        .catch((err) => {
          console.log('Error when booking appointment: ', err)
          if (err?.response?.status === 409) {
            this.getBookedAppointments()
            window.alert('Error while scheduling', 'Please select other time')
          } else {
            this.props.parentCallback({ success: false })
            this.setState({
              selectedDate: '',
              selectedTime: '',
              showSlotAvailableModal: false,
            })
          }
        })
    }
  }

  renderVisitDurationContent() {
    let durationArr = [
      { lable: '30 minutes', value: 30 },
      { lable: '60 minutes', value: 60 },
    ]
    // show 15 and 45 with 30 and 60 for clinicians only
    let docType = this.props.doctorDetails?.user_type
    // console.log('TESTING:', this.props.doctorDetails)
    if (
      docType?.toLowerCase() !== 'health coach' &&
      docType?.toLowerCase() !== 'wellness coach'
    ) {
      durationArr.splice(0, 0, { lable: '15 minutes', value: 15 })
      durationArr.splice(2, 0, { lable: '45 minutes', value: 45 })
    }
    let selectedDate = this.state.selectedDate

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          paddingTop: 20,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() => this.onModalCloseRequested()}
        >
          X
        </p>
        <h4
          style={{
            color: values.primaryThemeDark,
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          Schedule your appointment
        </h4>

        <h5 style={{ color: values.darkGray, textAlign: 'center' }}>
          {moment(selectedDate, 'YYYY-MM-DD').format('dddd')},{' '}
          {moment(selectedDate, 'YYYY-MM-DD').format('MMMM Do, YYYY')}
        </h5>
        <h6 style={{ color: values.darkGray, textAlign: 'center' }}>
          {new Date()
            .toLocaleDateString(undefined, {
              day: '2-digit',
              timeZoneName: 'long',
            })
            .substring(4)}
        </h6>
        <h4
          style={{
            color: values.primaryTheme,
            textAlign: 'center',
            borderBottom: '1px solid #DEDEDE',
            width: '100%',
          }}
        >
          Select the Duration
        </h4>

        {durationArr.map((duration, index) => {
          return (
            <div
              style={{
                width: '50%',
                margin: '10px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                onClick={() => {
                  this.setState({ selectedDuration: duration.value })
                }}
                className={Styles.button2}
                style={{
                  margin: '1vh',
                  width: 200,
                  color: '#20A892',
                  fontWeight: 'bold',
                }}
                variant="primary"
              >
                {duration.lable}
              </Button>
            </div>
          )
        })}
        {durationArr.length === 0 && (
          <div
            style={{
              width: '50%',
              margin: '10px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            No slots available for today
          </div>
        )}
      </div>
    )
  }

  renderSlotAvailableContent() {
    let { selectedDuration, markedAvailableDates, selectedDate } = this.state
    let timeArr =
      markedAvailableDates[selectedDate] &&
      markedAvailableDates[selectedDate].availSlot
        ? markedAvailableDates[selectedDate].availSlot
        : []
    timeArr = timeArr.filter(
      (time) => time.availTillDuration >= selectedDuration,
    )
    let currentDayBookedAppointments =
      this.state.appointmentData?.filter((date) => {
        return moment(date.start).isSame(moment(selectedDate), 'date')
      }) || []

    let googleBookedAppointments =
      this.state.googleEvents?.filter((data) => {
        // let currentDate = moment.utc().format('YYYY-MM-DD HH:mm:ss')
        let temp = moment
          .utc(data?.start?.dateTime)
          .format('YYYY-MM-DD HH:mm:ss')
        return moment(temp).isSame(moment(selectedDate), 'date')
      }) || []

    if (currentDayBookedAppointments?.length) {
      currentDayBookedAppointments.forEach((appointmentTime) => {
        // removing the pre-booked appointment time slot according to selected duration
        timeArr = timeArr.filter((item) => {
          let visitEndTime = moment(item.availFrom, 'HH:mm')
            .add(selectedDuration, 'minutes')
            .format('HH:mm')
          // console.log('TIME: ', item.availFrom, visitEndTime, moment(appointmentTime.start).format('HH:mm'))
          return !(
            (item.availFrom > moment(appointmentTime.start).format('HH:mm') &&
              visitEndTime <= moment(appointmentTime.end).format('HH:mm')) ||
            (item.availFrom < moment(appointmentTime.start).format('HH:mm') &&
              visitEndTime >= moment(appointmentTime.end).format('HH:mm')) ||
            (item.availFrom > moment(appointmentTime.start).format('HH:mm') &&
              item.availFrom < moment(appointmentTime.end).format('HH:mm')) ||
            (visitEndTime > moment(appointmentTime.start).format('HH:mm') &&
              visitEndTime < moment(appointmentTime.end).format('HH:mm'))
          )
        })
      })
    }

    if (googleBookedAppointments?.length) {
      googleBookedAppointments.forEach((appointmentTime) => {
        // removing the pre-booked appointment time slot according to selected duration
        timeArr = timeArr.filter((item) => {
          let visitEndTime = moment(item.availFrom, 'HH:mm')
            .add(selectedDuration, 'minutes')
            .format('HH:mm')
          return !(
            (item.availFrom >
              moment(appointmentTime?.start?.dateTime).format('HH:mm') &&
              visitEndTime <=
                moment(appointmentTime?.end?.dateTime).format('HH:mm')) ||
            (item.availFrom <
              moment(appointmentTime?.start?.dateTime).format('HH:mm') &&
              visitEndTime >=
                moment(appointmentTime?.end?.dateTime).format('HH:mm')) ||
            (item.availFrom >
              moment(appointmentTime?.start?.dateTime).format('HH:mm') &&
              item.availFrom <
                moment(appointmentTime?.end?.dateTime).format('HH:mm')) ||
            (visitEndTime >
              moment(appointmentTime?.start?.dateTime).format('HH:mm') &&
              visitEndTime <
                moment(appointmentTime?.end?.dateTime).format('HH:mm'))
          )
        })
      })
    }

    // console.log('curr: ', currentDayBookedAppointments, timeArr)

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          paddingTop: 20,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          className={Styles.backModalBtn}
          onClick={() =>
            this.setState({ selectedDuration: '', selectedTime: '' })
          }
        >
          {'<'}
        </p>
        <p
          className={Styles.closeModalBtn}
          onClick={() => this.onModalCloseRequested()}
        >
          X
        </p>
        <h4
          style={{
            color: values.primaryThemeDark,
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          Schedule your appointment
        </h4>

        <h5 style={{ color: values.darkGray, textAlign: 'center' }}>
          {moment(selectedDate, 'YYYY-MM-DD').format('dddd')},{' '}
          {moment(selectedDate, 'YYYY-MM-DD').format('MMMM Do, YYYY')}
        </h5>
        <h6 style={{ color: values.darkGray, textAlign: 'center' }}>
          {new Date()
            .toLocaleDateString(undefined, {
              day: '2-digit',
              timeZoneName: 'long',
            })
            .substring(4)}
        </h6>
        <h4
          style={{
            color: values.primaryTheme,
            textAlign: 'center',
            borderBottom: '1px solid #DEDEDE',
            width: '100%',
          }}
        >
          Select a Time
        </h4>

        {timeArr.map((time, index) => {
          let displayTime = moment(
            selectedDate + ' ' + time.availFrom,
            'YYYY-MM-DD HH:mm',
          )
          return (
            <div
              style={{
                width: '50%',
                margin: '10px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                onClick={() => {
                  this.setState({ selectedTime: displayTime.valueOf() })
                }}
                className={Styles.button2}
                style={{
                  margin: '1vh',
                  width: 200,
                  color: '#20A892',
                  fontWeight: 'bold',
                }}
                variant="primary"
              >
                {displayTime.format('hh:mm A')}
              </Button>

              {this.state.selectedTime?.length !== 0 &&
                this.state.selectedTime === displayTime.valueOf() && (
                  <Button
                    onClick={() => {
                      this.onConfirmTimeClick(this.state.selectedTime)
                    }}
                    className={GlobalStyles.button}
                    style={{ width: 200, margin: '1vh' }}
                    variant="primary"
                  >
                    Confirm Time
                  </Button>
                )}
            </div>
          )
        })}
        {timeArr.length === 0 && (
          <div
            style={{
              width: '50%',
              margin: '10px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            No slots available for today
          </div>
        )}
      </div>
    )
  }

  render() {
    const CURRENT_DATE = moment().toDate()
    const AVAILABLE_DATES = Object.keys(this.state.markedAvailableDates)
    const markedDates = this.state.markedAvailableDates

    // example implementation of a wrapper
    const ColoredDateCellWrapper = ({ children, value }) =>
      React.cloneElement(Children.only(children), {
        style: {
          ...children.style,
          backgroundColor:
            AVAILABLE_DATES.includes(moment(value).format('YYYY-MM-DD')) &&
            !markedDates[moment(value).format('YYYY-MM-DD')].disabled
              ? values.primaryThemeFadedDeep
              : values.contentBackground,
          cursor:
            AVAILABLE_DATES.includes(moment(value).format('YYYY-MM-DD')) &&
            !markedDates[moment(value).format('YYYY-MM-DD')].disableTouchEvent
              ? 'pointer'
              : 'not-allowed',
        },
      })
    if (this.state.loading) {
      return (
        <div className={GlobalStyles.container} style={{ height: '100vh' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              height: '100%',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        </div>
      )
    }
    return (
      <div
        style={{
          flex: 1,
          visibility: this.state.showSlotAvailableModal ? 'hidden' : 'visible',
        }}
      >
        <Calendar
          selectable
          views={['month']}
          onSelectEvent={(event) => console.log(event)}
          onSelectSlot={this.onSelectSlot.bind(this)}
          localizer={localizer}
          defaultDate={new Date()}
          events={[]}
          startAccessor="start"
          endAccessor="end"
          components={{
            // you have to pass your custom wrapper here
            // so that it actually gets used
            dateCellWrapper: ColoredDateCellWrapper,
          }}
          className={Styles.calendarStyle}
          // style={{
          //   height: '60vh',
          //   width: '60vw',
          //   display: 'flex',
          //   justifyContent: 'center',
          //   margin: 'auto',
          // }}
        />

        <Modal
          ariaHideApp={true}
          onRequestClose={() => this.onModalCloseRequested()}
          isOpen={this.state.showSlotAvailableModal}
          // style={SLOT_AVAILABLE_MODAL_STYLES}
          className={Styles.modalContent}
          // style={paymentModalStyles}

          style={{
            overlay: {
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.44)',
            },
          }}
          contentLabel="Slot Modal"
        >
          {this.state.selectedDuration
            ? this.renderSlotAvailableContent()
            : this.renderVisitDurationContent()}
        </Modal>
      </div>
    )
  }
}

export default MyCalendar
