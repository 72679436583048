import React, {useState, useEffect} from 'react'
import Styles from "./Musculoskeletal.module.scss";
import {
	Legend,
	Line,
	LineChart,
	Tooltip,
	XAxis,
	YAxis,
	ReferenceLine,
	ReferenceArea,
	AreaChart,
	Label,
	CartesianGrid,
	Area
} from "recharts";
import Select from "react-select";
import axios from 'axios';
import moment from 'moment';
import Constants from "../../values";
import Config from "../../config";


const getScoreValue = (data, name) => {
  // console.log('TENDERNESS VALUES: ', data[name])
  return data[name]
}

const renderChart = (data, graphWidth, chartRange, scoreName, lineName, yAxisName, domainRange, referenceLine, statusFunc, extraToolTip) =>{
  let newChartData = data.filter((data) => moment(data.created_at).isSame(new Date(), chartRange.value))
  // console.log('inside renderChart: ', newChartData)

  if(newChartData.length === 0) {
    return (
      <div style={{display:'flex', justifyContent: 'center', textAlign: 'center', height: 350, alignItems: 'center'}}>
       <p>
         No Data Present
       </p>
      </div>
    )
  }
  
	return(
    <LineChart width={graphWidth} height={350} data={newChartData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
      <Line 
        dot={false} 
        name={lineName}  
        type="monotone" 
        dataKey={scoreName}
        stroke={Constants.graphLineOne} 
        />

      {referenceLine && referenceLine(scoreName)}

      <XAxis dataKey="created_at" tickFormatter={(tickItem) => {
        return moment(tickItem).format('D-MMM')
      }}/>
      <YAxis
        label={{ value: yAxisName, angle: -90, position: 'insideLeft', offset: 10}}
        domain={domainRange || [0,'auto']}
      />
      <Legend verticalAlign="top" height={36}/>
      <Tooltip content={({ active, payload, label }) => {
        let date = moment(label).format('D-MMM')
        return (
          <div className={Styles.painChartTooltip} style={{width: 240}}>
            <p>{date}</p>
            
            <div style={{width: '100%', padding: '0px 5px'}}>
              <p style={{fontWeight: 'bold', textAlign: 'left'}}>{payload[0]?.name}: <span style={{fontWeight: 'normal', marginLeft: 5}}>{payload && payload.length > 0 && Math.round((payload[0]?.value + Number.EPSILON) * 100) / 100}</span></p>
              {extraToolTip && extraToolTip(payload)}
              {statusFunc && <p style={{fontWeight: 'bold', textAlign: 'left'}}>Status: <span style={{fontWeight: 'normal', marginLeft: 5}}>{statusFunc(payload && payload.length>0 && payload[0].payload)}</span></p>}
            </div>
                
          </div>
        )
      }}/>
    </LineChart>
	)
}


export default function GeneralChart({token, patientId, chartWidth, questionName, scoreName, lineName, yAxisName, chartName, graphData, domainRange, status, referenceLine, statusFunc, extraToolTip}) {
  // console.log('TESTING: ', token, patientId, chartWidth, questionName, scoreName, lineName, yAxisName, chartName, graphData, domainRange)
  const [chartData, setChartData] = useState([])
  const [chartRange, setChartRange] = useState({label: 'Year', value: 'year'})
  // const [jointName, setJointName] = useState('')
  const [loading, setLoading] = useState(false)
  const graphWidth = (window.innerWidth) / 2;


  const getData = () => {
    const url = Config.BACKEND_URL + 'screenings/' + questionName
    axios({
      method: 'get',
      url,
      headers: { Authorization: `JWT ${token}`, x_firebase_id: patientId, complete: true },
    })
      .then(({ data }) => {
        setChartData(data)
        setLoading(false)
      })
      .catch((err) => {
        console.log('error when getting screening', err)
      })
  }

  useEffect(() => {
    if (graphData?.length) {
      setChartData(graphData)
    } else {
      getData()
    }
  }, [])


  return (
    <div className="row" style={{alignSelf: 'flex-start', paddingLeft: 30, marginTop: 50, width: (chartWidth || 'auto')}}>
      <div className="col">
      <div className={Styles.chartClassOld} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <h5 style={{width: '100%', textAlign: 'center', color: Constants.primaryTheme}}>{chartName}</h5>
        <div className={Styles.chartSelectReproductive}>
				<div style={{width: '100%', height: 30}}>
					<Select
						isSearchable={false}
						className={Styles.selectStyle}
						classNamePrefix={Styles.selectStyleInnerGraph}
						autoBlur={true}
						options={[
							{label: 'Year', value: 'year'},
							{label: 'Month', value: 'month'},
							{label: 'Week', value: 'week'}
						]}
						value={chartRange}
						onChange={(val) => {
							setChartRange(val)
						}}
						theme={(theme) => ({
							...theme,
							colors: {
								...theme.colors,
								primary25: '#20A89233',
								primary: '#20A892',
							},
						})}
					/>
				</div>
			</div>
        {renderChart(chartData, graphWidth, chartRange, scoreName, lineName, yAxisName, domainRange, referenceLine, statusFunc, extraToolTip)}
        {status && <p style={{width: '100%', textAlign: 'center'}}>Status: {status}</p>}
      </div>
      </div>
    </div>
  )
}


{/* <GeneralChart
  token={token}
  patientId={patient?.patient_id}
  chartWidth={'100%'}
  questionName={'physical'}
  scoreName={'haqScore'}
  lineName={'HAQ-II Score'}
  yAxisName={'Score'}
  chartName={'Physical Function Score'}
  graphData={screeningData.physical}
  domainRange={[0, 20]}
/>  */}