import React, { useState, useEffect } from 'react'
import moment from 'moment'
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify'
import GlobalStyles from '../../../styles/global.module.scss'
import Styles from './styles/Orders.module.scss'
import Config from '../../../../config'
import Button from 'react-bootstrap/Button'
import Modal from 'react-modal'
import metadata from '../../../../metadata'
import firebase from '../../../../services/firebase.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { connect, useSelector, useDispatch } from 'react-redux'
import { fetchPatientCoreDate } from '../../../../redux/actions'
import Table from '../../../../components/Table'
import { rpmOrdersColumns } from './RpmOrdersColumns'
import { referralsColumns } from '../../../Orders/Columns/referralsColumns'
// import { usaCities } from '../../../../cityMetaData'
import Handlebars from 'handlebars'
import htmlModule1 from '../../../../helpers/PdfTemplates/ProviderReferral.txt'
import htmlModule2 from '../../../../helpers/PdfTemplates/ProviderReferralInfusion.txt'
import htmlModule3 from '../../../../helpers/PdfTemplates/ProviderReferralImaging.txt'
import Axios from 'axios'
const axios = require('axios')

const customChangeModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 10,
  },
  content: {
    zIndex: 10,
    width: '95%',
    height: '90%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const customPharmacyModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 10,
  },
  content: {
    zIndex: 10,
    width: '50%',
    height: '75%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const customDeviceModalStyle = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 10,
  },
  content: {
    zIndex: 10,
    width: '50%',
    height: '60%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const ORDER_STATUSES = {
  C: 'Corrected',
  E: 'Entered',
  F: 'Final Reported',
  I: 'Inactive',
  NA: 'Results Received',
  P: 'Partial Reported',
  R: 'Ready to Transmit',
  T: 'Transmitted',
  TX: 'Transmission Error',
  X: 'Error',
}

const Orders = ({ patient, token, patientList }) => {
  const [loading, setLoading] = useState(false)
  const [showRx, setShowRx] = useState(true)
  const [showInboxModal, setShowInboxModal] = useState(false)
  const [rxInboxUrl, setRxInboxUrl] = useState('')
  const [prescriptions, setPrescriptions] = useState([])
  const [labOrdersFromBackend, setLabOrdersFromBackend] = useState(null)
  const [rpmOrders, setRpmOrders] = useState([])
  const [referrals, setReferrals] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [pdfData, setPdfData] = useState('')
  const [clinicalReportsFromChange, setClinicalReportsFromChange] =
    useState(null)
  const [clinicalReportsFromAila, setClinicalReportsFromAila] = useState(null)
  const [labOrdersFromChange, setLabOrdersFromChange] = useState(null)
  const [modalRequisition, setModalRequisition] = useState(null)
  const [showRequisitionModal, setShowRequisitionModal] = useState(false)
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [tabIndex, setTabIndex] = useState(1)
  const [showReportModal, setShowReportModal] = useState(false)
  const [encodedReportString, setEncodedReportString] = useState('')
  const [labResultPdf, setLabResultPdf] = useState('')
  const [showLabResultModal, setShowLabResultModal] = useState(false)
  const [showPatientAssignModal, setShowPatientAssignModal] = useState(false)
  const [patientsList, setPatientsList] = useState([])
  const [
    selectedPatientForAssigningClinicalReport,
    setSelectedPatientForAssigningClinicalReport,
  ] = useState(null)
  const [patientLabOrders, setPatientLabOrders] = useState(null)
  const [modalClinicalReport, setModalClinicalReport] = useState(null)
  const [modalLoading, setModalLoading] = useState(false)
  const [pharmacyModalOpen, setPharmacyModalOpen] = useState(false)
  const [pharmacyList, setPharmacyList] = useState([])
  const [pharmacySearchString, setPharmacySearchString] = useState('')
  const [selectedCity, setSelectedCity] = useState('')
  //RPM DEVICE IDS UPDATED BY ADMIN
  const [deviceIds, setDeviceIds] = useState([])
  const [deviceId, setDeviceId] = useState('')
  const [showDeviceIdModal, setShowDeviceIdModal] = useState(false)
  const [updatingOrder, setUpdatingOrder] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    console.log('the patient is', patient)
    if (patient && patient.change && patient.change.person) {
      // change user exists
      getPrescriptions()
    } else {
      console.log('No change user ID present')
      toast.info('No information available')
    }

    preparePatientList()
  }, [])

  const preparePatientList = () => {
    const list = []
    if (!patientList) return

    Object.values(patientList).forEach((colorCodedPatients) => {
      if (colorCodedPatients.length > 0) {
        colorCodedPatients.forEach((patient) => {
          list.push({
            label: `${patient.firstName} ${patient.lastName}`,
            value: patient.uid,
          })
        })
      }
    })

    setPatientsList(list)
  }

  const getRmpOrders = () => {
    setLoading(true)
    const url = `${Config.BACKEND_URL}rpm/order?patient=${patient?.firebase_id}`
    axios({
      method: 'get',
      url,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: patient?.firebase_id,
      },
    })
      .then(({ data }) => {
        console.log('RPM : ', data)
        setLoading(false)
        // let temp =
        //   data &&
        //   data?.filter((item) => {
        //     if (item.patient_id === patient?.firebase_id) return item
        //   })
        setRpmOrders(data)
      })
      .catch((err) => {
        console.log('error when getting lab orders', err)
        toast.error('Something went wrong')
      })
  }

  const getAllReferrals = () => {
    setLoading(true)
    let config = {
      method: 'get',
      url: Config.BACKEND_URL + `referrals`,
      headers: {
        Authorization: `JWT ${token}`,
        patient_id: patient?.firebase_id,
      },
    }

    axios(config)
      .then(({ data }) => {
        // console.log('Got all referrals', data)
        setLoading(false)
        setReferrals(data)
      })
      .catch((err) => {
        console.log('Error getting all referrals', err)
        toast.error('Could get all referral. Please try Again')
      })
  }

  const updateRpmOrder = () => {
    setLoading(true)
    const url = `${Config.BACKEND_URL}rpm/order`
    let data = {
      device_ids: deviceIds,
      object_id: updatingOrder?._id,
      patient_id: patient?.firebase_id,
    }
    console.log(data)
    axios({
      method: 'put',
      url,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: patient?.firebase_id,
      },
      data: data,
    })
      .then(({ data }) => {
        console.log('RPM updated: ', data)
        setLoading(false)
        getRmpOrders()
      })
      .catch((err) => {
        console.log('error when getting lab orders', err)
        toast.error('Something went wrong')
      })
  }

  const getLabOrders = () => {
    setLoading(true)
    const url = `${Config.BACKEND_URL}patients/labs/orders`
    axios({
      method: 'get',
      url,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: patient?.firebase_id,
      },
    })
      .then(({ data }) => {
        console.log('data', data)
        setLoading(false)
        setLabOrdersFromBackend(data)
      })
      .catch((err) => {
        console.log('error when getting lab orders', err)
        toast.error('Something went wrong')
      })
  }

  const getLabOrdersFromChange = () => {
    const url = `${Config.CHANGE_BACKEND_URL}change/patients/orders`
    axios({
      method: 'get',
      url,
      headers: {
        Authorization: `JWT ${token}`,
        person: patient?.change?.person,
      },
    })
      .then(({ data }) => {
        console.log('change data', data)
        if (data?.RESULT?.OBJECT) {
          let orders = []
          let content = data.RESULT.OBJECT
          let ordersMap = {}
          if (Array.isArray(content)) {
            content.forEach((x) => getLabsInfo(x, ordersMap))
          } else {
            getLabsInfo(content, ordersMap)
          }
          setLabOrdersFromChange(ordersMap)
        } else {
          toast.error('Could not get lab orders from change')
        }
      })
      .catch((err) => {
        console.log('error when getting lab orders', err)
        toast.error('Something went wrong')
      })
  }

  const getPrescriptions = () => {
    setLoading(true)
    const url = `${Config.CHANGE_BACKEND_URL}change/patients/prescriptions`
    axios({
      method: 'get',
      url,
      headers: {
        Authorization: `JWT ${token}`,
        person: patient?.change?.person,
      },
    })
      .then(({ data }) => {
        if (data?.RESULT?.OBJECT) {
          let prescriptions = []
          let content = data.RESULT.OBJECT
          if (Array.isArray(content)) {
            content.forEach((x) => {
              prescriptions.push(getPrescriptionInfo(x))
            })
          } else {
            prescriptions.push(getPrescriptionInfo(content))
          }
          setPrescriptions(prescriptions)
        } else {
          toast.error('No prescriptions found for the patient')
        }
        setLoading(false)
      })
      .catch((err) => {
        console.log('error when getting patient prescriptions', err)
        setLoading(false)
        toast.error('Could not fetch the data')
      })
  }

  const onLabRequistionRequested = (order) => {
    let ordersFromChange = labOrdersFromChange || {}
    let changeOrder = ordersFromChange[order.order_id]
    console.log('lab orders from change')
    if (
      changeOrder &&
      changeOrder.status &&
      (changeOrder.status === 'F' ||
        changeOrder.status === 'T' ||
        changeOrder.status === 'NA' ||
        changeOrder.status === 'P')
    ) {
      //requisition is available
      setLoading(true)
      const url = `${Config.CHANGE_BACKEND_URL}change/orders/pdf`
      axios({
        method: 'get',
        url,
        headers: {
          Authorization: `JWT ${token}`,
          'order-id': order.order_id,
        },
      })
        .then(({ data }) => {
          setLoading(false)
          console.log('requisition data', data)
          if (data?.RESULT?.OBJECT) {
            let content = data.RESULT.OBJECT
            let body = content.body
            // let temp = `data:base64${body}`
            setModalRequisition(body)
            setShowRequisitionModal(true)
          } else {
            toast.error('Could not get requisition for order')
          }
        })
        .catch((err) => {
          console.log('error when getting lab orders', err)
          toast.error('Something went wrong')
        })
    } else {
      toast.error('Requisition not available')
    }
  }

  const onShowInboxClicked = () => {
    let url = `${Config.CHANGE_IFRAME_URL}?userid=${`ah_aapple`}&PW=${
      Config.CHANGE_PWD
    }&hdnBusiness=${
      Config.CHANGE_ORG_ID
    }&apiLogin=true&target=jsp/lab/person/PatientLookup.jsp&searchaccountId=${
      patient.uid
    }&actionCommand=Search&FromOrder=false&FromRx=false&loadPatient=true&link=false`
    setRxInboxUrl(url)
    setShowInboxModal(true)
  }

  const getPrescriptionInfo = (content) => {
    return {
      drugName: content['drug_name'],
      providerName: content['prescriber_name'],
      creationDate: content['creation_date'],
      transmissionDate:
        typeof content['transmission_date'] === 'string'
          ? content['transmission_date']
          : '',
      status: content['transmittal_status'],
    }
  }

  const getLabsInfo = (content, map) => {
    map[content.order] = {
      status: content.order_status,
      providerName: `${content.ref_cg_fname} ${content.ref_cg_lname}`,
    }
  }

  const onTabSelected = (index) => {
    if (loading) return

    if (index === 2) {
      getLabOrders()
      getLabOrdersFromChange()
    }
    if (index === 3) {
      getRmpOrders()
    }
    if (index === 4) {
      getAllReferrals()
    }

    setTabIndex(index)
  }

  const onPharmacySearch = async () => {
    setModalLoading(true)
    if (pharmacySearchString.length === 0) {
      toast.error('Please enter pharmacy name or zipcode')
      setModalLoading(false)
      return
    }

    let config = {
      method: 'get',
      url: `${Config.CHANGE_BACKEND_URL}change/pharmacies/search/zip`,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: patient?.firebase_id,
      },
    }
    // if entered only zip code (ie, string contains only digits)
    if (/^\d+$/.test(pharmacySearchString)) {
      config.url = `${Config.CHANGE_BACKEND_URL}change/pharmacies/search/zip`
      config.headers.zip = pharmacySearchString
    } else {
      if (selectedCity.length === 0) {
        toast.error('Please enter city name')
        setModalLoading(false)
        return
      }
      config.url = `${Config.CHANGE_BACKEND_URL}change/pharmacies/search/name`
      config.headers.name = pharmacySearchString
      config.headers.city = selectedCity
    }

    axios(config)
      .then(({ data }) => {
        console.log('got results', data)
        if (data) {
          setPharmacyList(data || [])
          setModalLoading(false)
        }
      })
      .catch(function (error) {
        setModalLoading(false)
        setPharmacyList([])
        toast.error(
          'Error occured while fetching the pharmacy details, please contact support',
        )
        console.log('error when searching pharmacy', error)
      })
  }

  const onPharmacySelected = (pharmacyObj) => {
    let data = { 'change.pharmacy': pharmacyObj }
    let config = {
      method: 'put',
      url: `${Config.BACKEND_URL}users`,
      headers: {
        Authorization: 'JWT ' + token,
        x_firebase_id: patient?.firebase_id,
      },
      data: data,
    }

    axios(config)
      .then(() => {
        console.log('saved pharmacy information in the backend')
        toast.success('Patient pharmacy data has be saved')
        setPharmacyList([])
        setPharmacySearchString('')
        setPharmacyModalOpen(false)
        dispatch(fetchPatientCoreDate(patient.uid, token))
      })
      .catch((error) => {
        setLoading(false)
        console.log('error when saving info to backend', error)
        toast.error('Error while saving pharmacy info')
      })
  }

  const renderPharmacyList = () => {
    if (
      !pharmacySearchString ||
      pharmacySearchString.length === 0 ||
      pharmacyList.length === 0
    ) {
      return
    }

    if (
      !pharmacyList ||
      (pharmacyList.length !== 0 && pharmacyList[0] === null)
    ) {
      return (
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <p>no results</p>
        </div>
      )
    }

    let elements = pharmacyList.map((pharmacy) => (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          borderBottom: '1px solid #d0d0d0',
          padding: '8px 2px',
          cursor: 'pointer',
        }}
        onClick={() => onPharmacySelected(pharmacy)}
      >
        <p style={{ width: '50%', textAlign: 'center' }}>
          {pharmacy?.name?.[1]}
        </p>
        <p style={{ width: '50%', textAlign: 'center' }}>
          {pharmacy?.address_1}, {pharmacy?.city}
        </p>
      </div>
    ))

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          overflowY: 'scroll',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            borderBottom: '1px solid #d0d0d0',
            padding: 10,
          }}
        >
          <p
            style={{
              width: '50%',
              textAlign: 'center',
              fontWeight: 'bold',
              color: 'gray',
            }}
          >
            Name
          </p>
          <p
            style={{
              width: '50%',
              textAlign: 'center',
              fontWeight: 'bold',
              color: 'gray',
            }}
          >
            Address
          </p>
        </div>

        {elements}
      </div>
    )
  }

  const renderPharmacySearchModal = () => {
    return (
      <Modal
        ariaHideApp={false}
        isOpen={pharmacyModalOpen}
        onRequestClose={() => setPharmacyModalOpen(false)}
        style={customPharmacyModalStyles}
        contentLabel="Modal"
      >
        {modalLoading ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              minHeight: '100%',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        ) : (
          <div
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: 10,
            }}
          >
            <p
              className={Styles.closeModalBtn}
              onClick={() => setPharmacyModalOpen(false)}
            >
              X
            </p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                padding: '0px 10px',
                width: '80%',
                justifyContent: 'center',
              }}
            >
              <input
                placeholder={'Enter pharmacy or zipcode'}
                className={Styles.labTextInput}
                type="textInput"
                value={pharmacySearchString}
                onChange={(e) => setPharmacySearchString(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    onPharmacySearch()
                  }
                }}
              />
              <div style={{ width: '50%', marginLeft: 10 }}>
                <input
                  placeholder={'Enter city name'}
                  className={Styles.labTextInput}
                  type="textInput"
                  value={selectedCity}
                  onChange={(e) => setSelectedCity(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      onPharmacySearch()
                    }
                  }}
                />
                {/* <Select
                    placeholder="Select city"
                    inputId="state"
                    options={usaCities.map(data => ( {value: data.city, label: data.city} ))}
                    isMulti={false}
                    isSearchable
                    autoBlur
                    onChange={(val) => {
                      setSelectedCity(val)
                      console.log('city: ', val)
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  /> */}
              </div>
              <Button
                onClick={onPharmacySearch}
                className={GlobalStyles.button}
                style={{ width: '10%', marginLeft: 5, height: 40 }}
                variant="primary"
              >
                Search
              </Button>
            </div>

            {renderPharmacyList()}
          </div>
        )}
      </Modal>
    )
  }

  const renderRxContent = () => {
    let tableHeader = (
      <div className={Styles.row}>
        <p className={Styles.headerText} style={{ width: '25%' }}>
          Drug Name
        </p>
        <p className={Styles.headerText} style={{ width: '25%' }}>
          Prescriber Name
        </p>
        <p className={Styles.headerText} style={{ width: '15%' }}>
          Creation Date
        </p>
        <p className={Styles.headerText} style={{ width: '15%' }}>
          Transmission Date
        </p>
        <p className={Styles.headerText} style={{ width: '20%' }}>
          Transmission Status
        </p>
      </div>
    )

    let tableRows = prescriptions.map((x) => {
      return (
        <div className={Styles.row}>
          <p className={Styles.entryText} style={{ width: '25%' }}>
            {x.drugName}
          </p>
          <p className={Styles.entryText} style={{ width: '25%' }}>
            {x.providerName}
          </p>
          <p className={Styles.entryText} style={{ width: '15%' }}>
            {x.creationDate}
          </p>
          <p className={Styles.entryText} style={{ width: '15%' }}>
            {x.transmissionDate}
          </p>
          <p className={Styles.entryText} style={{ width: '20%' }}>
            {x.status}
          </p>
        </div>
      )
    })

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          marginTop: 20,
        }}
      >
        <Modal
          ariaHideApp={false}
          isOpen={showInboxModal}
          onRequestClose={() => setShowInboxModal(false)}
          style={customChangeModalStyles}
          contentLabel="Modal"
        >
          <iframe
            src={rxInboxUrl}
            title="erx"
            style={{ height: '100%', width: '100%' }}
          />
        </Modal>

        <Button
          onClick={onShowInboxClicked}
          className={GlobalStyles.button}
          style={{ width: 200, margin: 20 }}
          variant="primary"
        >
          RX Inbox
        </Button>

        <div
          style={{
            minWidth: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {tableHeader}
          {tableRows}
        </div>
      </div>
    )
  }

  const getLabStatusText = (x) => {
    if (!labOrdersFromChange || !x.order_id) return 'order not placed'

    let ordersFromChange = labOrdersFromChange || {}
    let changeOrder = ordersFromChange[x.order_id]
    return ORDER_STATUSES[changeOrder?.status]
  }

  const onLabReportRequested = (lab) => {
    console.log('lab', lab)
    setLoading(true)
    const url = `${Config.BACKEND_URL}labs/reports/orderOrReport`
    axios({
      method: 'post',
      url,
      headers: {
        Authorization: `JWT ${token}`,
        lab_order_id: lab._id,
      },
    })
      .then(({ data }) => {
        console.log('clinical document data', data)
        setLoading(false)
        if (data) {
          let result = data.result
          result.forEach((x) => {
            if (x.mime_type === 'pdf') {
              setLabResultPdf(x.body_text)
              setShowLabResultModal(true)
            }
          })
        } else {
          toast.error('Could not retrieve the results')
          toast.error('Contact support')
        }
      })
      .catch((err) => {
        console.log('error when getting lab orders', err)
        toast.error('Something went wrong')
      })
  }

  const updateDeviceId = (obj) => {
    console.log(obj)
    setShowDeviceIdModal(true)
    setDeviceIds(obj.device_ids || [])
    setUpdatingOrder(obj)
  }
  const renderRpmContent = () => {
    const a = null
    console.log('RPM INSIDE DISPLAY:', rpmOrders)

    return (
      <div className={GlobalStyles.contentWrapper}>
        <Table columns={rpmOrdersColumns(updateDeviceId)} data={rpmOrders} />

        <Modal
          ariaHideApp={false}
          isOpen={showDeviceIdModal}
          onRequestClose={() => setShowDeviceIdModal(false)}
          style={customDeviceModalStyle}
          contentLabel="Modal"
        >
          <div className={Styles.addManualRow}>
            <p className={Styles.addManualQuestion}>Device IDs </p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '70%',
              }}
            >
              <input
                placeholder={'Enter device ID'}
                className={Styles.labTextInput}
                style={{
                  width: '90%',
                  marginBottom: '0px',
                }}
                type="textInput"
                value={deviceId}
                onChange={(e) => setDeviceId(e.target.value)}
              />
              <FontAwesomeIcon
                className={Styles.plusButton}
                onClick={() => {
                  if (deviceId.length) {
                    let tempNote = deviceIds
                    tempNote.unshift(deviceId)
                    setDeviceIds(tempNote)
                    setDeviceId('')
                  }
                }}
                icon={faPlusCircle}
              />
            </div>
          </div>
          {deviceIds?.map((device, index) => (
            <div className={Styles.addManualRow}>
              <p className={Styles.addManualQuestion}>
                {index + 1}. {device}
              </p>
            </div>
          ))}
          <Button
            onClick={() => updateRpmOrder()}
            className={GlobalStyles.button}
            style={{ width: 200, margin: 20 }}
            variant="primary"
          >
            Save
          </Button>
        </Modal>
      </div>
    )
  }

  const onAdminSubmitPressed = (referral_display) => {
    let data = referral_display

    if (data?.referral_type === 'physician') {
      Axios(htmlModule1).then((res) => {
        let templateHtml = Handlebars.compile(res.data.toString())
        let bodyHtml = templateHtml(data)

        setPdfData(bodyHtml)
        setShowModal(true)
      })
    } else if (data?.referral_type === 'medical') {
      Axios(htmlModule3).then((res) => {
        let templateHtml = Handlebars.compile(res.data.toString())
        let bodyHtml = templateHtml(data)

        setPdfData(bodyHtml)
        setShowModal(true)
      })
    } else if (data?.referral_type === 'priorAuth') {
      Axios(htmlModule3).then((res) => {
        let templateHtml = Handlebars.compile(res.data.toString())
        let bodyHtml = templateHtml(data)

        setPdfData(bodyHtml)
        setShowModal(true)
      })
    } else if (data?.referral_type === 'imaging') {
      Axios(htmlModule3).then((res) => {
        let templateHtml = Handlebars.compile(res.data.toString())
        let bodyHtml = templateHtml(data)
        console.log('show referral')
        setPdfData(bodyHtml)
        setShowModal(true)
      })
    } else if (data?.referral_type === 'speciality') {
      Axios(htmlModule2).then((res) => {
        let templateHtml = Handlebars.compile(res.data.toString())
        let bodyHtml = templateHtml(data)
        setPdfData(bodyHtml)
        setShowModal(true)
      })
    }
  }

  const renderReferralsContent = () => {
    const a = null
    console.log('RPM INSIDE DISPLAY:', rpmOrders)

    return (
      <div className={GlobalStyles.contentWrapper}>
        <Table
          columns={referralsColumns(onAdminSubmitPressed)}
          data={referrals}
        />

        {showModal && (
          <Modal
            ariaHideApp={false}
            isOpen={showModal}
            onRequestClose={() => setShowModal(false)}
            style={customChangeModalStyles}
            contentLabel="Modal"
          >
            <h3>Referral Document</h3>
            <Button
              onClick={() => {
                let postData = {
                  htmlContent: pdfData,
                }
                let url = Config.CHANGE_BACKEND_URL + `pdf/generate`
                //API REQUEST TO GET PDF BASE64 RIGHT FORMAT FROM BACKEND - PUPPETEER
                axios({
                  method: 'post',
                  headers: {
                    Authorization: 'JWT ' + token,
                    x_firebase_id: patient?.firebase_id,
                  },
                  url: url,
                  data: postData,
                })
                  .then(({ data }) => {
                    // Insert a link that allows the user to download the PDF file
                    var link = document.createElement('a')
                    link.innerHTML = 'Download PDF file'
                    link.download = `Patient Referral ${patient?.firstName.charAt(
                      0,
                    )}${patient?.lastName.charAt(0)} ${moment().format(
                      'MM/DD/YYYY',
                    )}.pdf`
                    link.href =
                      'data:application/octet-stream;base64,' + data.result
                    // document.body.appendChild(link);
                    link.click()
                    console.log('downloaded')
                    toast.success('pdf downloaded')
                  })
                  .catch((err) => {
                    console.log(
                      'error when getting saving provider goals data',
                      err,
                    )
                    toast.error('Could not download Notes, please try again')
                  })
              }}
              className={GlobalStyles.button}
              style={{
                width: 100,
                position: 'absolute',
                right: 120,
                top: 10,
              }}
              variant="primary"
            >
              Download
            </Button>
            {/* <Button
              onClick={() => onSendPressed()}
              className={GlobalStyles.button}
              style={{
                width: 100,
                position: 'absolute',
                right: 5,
                top: 10,
              }}
              variant="primary"
            >
              Submit
            </Button> */}
            <iframe
              srcDoc={pdfData}
              title="Provider Referral"
              style={{ height: '100%', width: '100%' }}
            />
          </Modal>
        )}
      </div>
    )
  }

  const renderLabsContent = () => {
    const a = null
    let tableHeader = (
      <div className={Styles.row}>
        <p className={Styles.headerText} style={{ width: '20%' }}>
          Name
        </p>
        <p className={Styles.headerText} style={{ width: '20%' }}>
          Location
        </p>
        <p className={Styles.headerText} style={{ width: '10%' }}>
          Payment Status
        </p>
        <p className={Styles.headerText} style={{ width: '10%' }}>
          Paid By
        </p>
        <p className={Styles.headerText} style={{ width: '15%' }}>
          Created Date
        </p>
        <p className={Styles.headerText} style={{ width: '15%' }}>
          Collection Date
        </p>
        <p className={Styles.headerText} style={{ width: '10%' }}>
          Status
        </p>
      </div>
    )

    let tableRows =
      labOrdersFromBackend &&
      labOrdersFromBackend.map((x) => {
        let tests = x.tests || []
        let testElements = tests.map((test) => <li>{test.name}</li>)

        return (
          <div className={Styles.row}>
            <ul
              className={Styles.entryText}
              style={{ width: '20%', textAlign: 'left' }}
            >
              {testElements}
            </ul>
            <p className={Styles.entryText} style={{ width: '20%' }}>
              {x.lab?.label}
            </p>
            <p
              onClick={() => x.order_id && onLabRequistionRequested(x)}
              className={Styles.entryText}
              style={{
                width: '10%',
                color: x.order_id ? 'blue' : 'black',
                textDecoration: x.order_id ? 'underline' : 'none',
                cursor: x.order_id ? 'pointer' : 'auto',
              }}
            >
              {x.paid ? 'Paid' : 'Unpaid'}
            </p>
            <p className={Styles.entryText} style={{ width: '10%' }}>
              {x.payment_type}
            </p>
            <p className={Styles.entryText} style={{ width: '15%' }}>
              {moment(x.created_at).format('MM/DD/YYYY')}
            </p>
            <p className={Styles.entryText} style={{ width: '15%' }}>
              {moment(x.collection_date_time).format('MM/DD/YYYY h:mmA')}
            </p>
            <p
              className={Styles.entryText}
              onClick={() => x.result && onLabReportRequested(x)}
              style={{
                width: '10%',
                cursor: x.result ? 'pointer' : 'auto',
                color: x.result ? 'blue' : 'black',
                textDecoration: x.result ? 'underline' : 'none',
              }}
            >
              {getLabStatusText(x)}
            </p>
          </div>
        )
      })

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          marginTop: 20,
        }}
      >
        {tableHeader}
        {labOrdersFromBackend && labOrdersFromBackend.length !== 0 && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              marginTop: 20,
            }}
          >
            {tableRows}
          </div>
        )}

        <Modal
          ariaHideApp={false}
          isOpen={showRequisitionModal}
          onRequestClose={() => setShowRequisitionModal(false)}
          style={customChangeModalStyles}
          contentLabel="Modal"
        >
          <iframe
            style={{ height: '100%', width: '100%' }}
            src={`data:application/pdf;base64,${modalRequisition}`}
          />
        </Modal>

        <Modal
          ariaHideApp={false}
          isOpen={showLabResultModal}
          onRequestClose={() => setShowLabResultModal(false)}
          style={customChangeModalStyles}
          contentLabel="Modal"
        >
          <iframe
            style={{ height: '100%', width: '100%' }}
            src={`data:application/pdf;base64,${labResultPdf}`}
          />
        </Modal>
      </div>
    )
  }

  function onDocumentLoadSuccess({ numPages }) {
    console.log('on pdf loaded')
    setNumPages(numPages)
  }

  const renderPharmaName = () => {
    const patientPharmacy = patient?.change?.pharmacy
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          marginLeft: 30,
        }}
      >
        {renderPharmacySearchModal()}
        <p className={Styles.headerText}>
          Prefered Pharmacy:{' '}
          {patientPharmacy
            ? patientPharmacy?.name?.[1] +
              `, ${patientPharmacy?.address_1}` +
              `, ${patientPharmacy?.city}`
            : 'N/A'}
        </p>
        <Button
          onClick={() => setPharmacyModalOpen(true)}
          className={GlobalStyles.button}
          style={{ width: 200, margin: 20 }}
          variant="primary"
        >
          Search
        </Button>
      </div>
    )
  }

  const renderContent = () => (
    <div
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {patient?.change?.person && renderPharmaName()}
      <Tabs onTabSelected={onTabSelected} activeTab={showRx ? 1 : 2} />

      {loading ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minWidth: '100%',
            height: '50vh',
            justifyContent: 'center',
          }}
        >
          <div className={GlobalStyles.loader} />
        </div>
      ) : (
        renderTabContent()
      )}
    </div>
  )

  const renderTabContent = () => {
    switch (tabIndex) {
      case 1:
        return renderRxContent()
      case 2:
        return renderLabsContent()
      case 3:
        return renderRpmContent()
      case 4:
        return renderReferralsContent()
      default:
        return renderRxContent()
    }
  }

  return <div>{renderContent()}</div>
}

const Tabs = (props) => {
  const [activeTab, setActiveTab] = useState(1)

  useEffect(() => {
    setActiveTab(props.activeTab)
  }, [props.activeTab])

  const onTabSelected = (index) => {
    setActiveTab(index)
    props.onTabSelected(index)
  }

  return (
    <div
      style={{
        flexDirection: 'row',
        display: 'flex',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'gray',
        width: 300,
        height: 40,
        marginTop: 10,
      }}
    >
      <div
        onClick={() => onTabSelected(1)}
        className={Styles.tabItem}
        style={{
          backgroundColor: activeTab === 1 ? '#DCDCDC' : '',
          width: 220,
        }}
      >
        <p>RX</p>
      </div>

      <div
        onClick={() => onTabSelected(2)}
        className={Styles.tabItem}
        style={{
          backgroundColor: activeTab === 2 ? '#DCDCDC' : '',
          width: 220,
        }}
      >
        <p>Labs</p>
      </div>
      <div
        onClick={() => onTabSelected(3)}
        className={Styles.tabItem}
        style={{
          backgroundColor: activeTab === 3 ? '#DCDCDC' : '',
          width: 220,
        }}
      >
        <p>RPM</p>
      </div>
      <div
        onClick={() => onTabSelected(4)}
        className={Styles.tabItem}
        style={{
          backgroundColor: activeTab === 4 ? '#DCDCDC' : '',
          width: 220,
        }}
      >
        <p>Referrals</p>
      </div>

      {/*<div*/}
      {/*  onClick={() => onTabSelected(3)}*/}
      {/*  className={Styles.tabItem}*/}
      {/*  style={{*/}
      {/*    backgroundColor: activeTab === 3 ? '#DCDCDC' : '',*/}
      {/*    width: 300,*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <p>Clinical Reports</p>*/}
      {/*</div>*/}
    </div>
  )
}

export default Orders
