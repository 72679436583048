import React, { Component } from 'react'
import GlobalStyles from '../styles/global.module.scss'
import { toast, ToastContainer } from 'react-toastify'
import {
  faPhone,
  faBolt,
  faAppleAlt,
  faBrain,
  faMoon,
  faFireAlt,
  faCheckCircle
} from '@fortawesome/free-solid-svg-icons'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import Loader from 'react-loader-spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'react-bootstrap/Button'
import { loadStripe } from '@stripe/stripe-js'
import Config from '../../config'
import Styles from './styles/SubscriptionNew.module.scss'
import Constants from '../../values'
import AilaLogo from '../../assets/images/aila_logo.png'
import CommImage from "../../assets/images/comm_1.png";
const queryString = require('query-string')
const moment = require('moment')
const axios = require('axios')

const TOS_LINK = 'https://www.portal.ailahealth.com/ccfm/tos'
const TEXT_COLOR = '#7E7E7E'

const CCFM_STATES = {
  az: true,
  md: true,
  nj: true,
  ny: true,
  or: true,
  va: true,
  vt: true,
  wa: true,
  mn: true,
}

const AILA_STATES = {
  co: true,
}

const CCFM_VALUE_PROPS = [
  'Unlimited Messaging',
  'Dedicated Health Coach',
  'Personalized Nutrition Plans',
  'Mental Health Support',
  'Educational Content',
  'Mindfulness Program',
  'Long COVID Community Circles',
  'Includes 60 minute initial clinical evaluation and 30 minute follow up'
]

const AILA_VALUE_PROPS = [
  'Unlimited Messaging',
  'Dedicated Health Coach',
  'Personalized Nutrition Plans',
  'Mental Health Support',
  'Educational Content',
  'Mindfulness Program',
  'Patient Community Circles',
  'Includes 1 clinical visit per month'
]

export default class SubscriptionNew extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      token: null,
      patientId: null,
      patientDetails: null,
      patientSubscribed: false,
      subscriptionActive: false,
      subscriptionCancelledAtEndOfPeriod: false,
      patientEmail: null,
      state: null,
      tosChecked: false
    }
    this.createCheckoutSession = this.createCheckoutSession.bind(this)
    this.getAuthToken = this.getAuthToken.bind(this)
    this.getUserDetails = this.getUserDetails.bind(this)
    this.getCustomerPortalUrl = this.getCustomerPortalUrl.bind(this)
    this.onPlanClicked = this.onPlanClicked.bind(this)
  }

  componentDidMount() {
    const parsed = queryString.parse(this.props.location.search)
    console.log('parsed', parsed)
    const patientId = parsed.patient_id
    const patientEmail = parsed.email
    const { state } = parsed
    if (!patientId || !patientEmail) {
      console.log('missing info')
      toast.error('Missing information ')
    } else {
      this.getAuthToken(patientId, patientEmail)
      this.setState({ state })
    }
  }

  async getAuthToken(patientId, patientEmail) {
    const url = `${Config.BACKEND_URL}authorization`
    try {
      const response = await axios({
        method: 'get',
        headers: { x_firebase_id: patientId },
        url,
      })
      const token = response.data && response.data.token
      this.setState({ token, patientId, patientEmail })
      this.getUserDetails(patientId, token)
    } catch (err) {
      console.log('error when auth token', err)
      toast.error('Error when getting authorization from backend')
    }
  }

  async getUserDetails(patientId, token) {
    const url = `${Config.BACKEND_URL}users`
    try {
      const response = await axios({
        method: 'get',
        headers: { x_firebase_id: patientId, Authorization: `JWT ${token}` },
        url,
      })
      console.log('user id', response)
      const user = response.data
      const { stripe } = user
      console.log('got user data', user)
      if (user && stripe && stripe.customer_id && stripe.checkout_id) {
        // patient has subscribed at least once in Aila's history
        const today = Math.floor(Date.now() / 1000)
        console.log('today is', new Date(today * 1000))
        if (today >= stripe.period_start && today <= stripe.period_end) {
          console.log('subscription active')
          // active subscription
          this.setState({ subscriptionActive: true })

          // if user has cancelled subscription at the end of this period, subscription flag will be false even though dates are active
          if (!stripe.subscription) {
            this.setState({ subscriptionCancelledAtEndOfPeriod: true })
          }
        }
        this.setState({ patientSubscribed: true, patientDetails: user })
      }
      this.setState({ loading: false })
    } catch (err) {
      console.log('error when getting user details', err)
      toast.error('Something went wrong')
    }
  }

  async getCustomerPortalUrl() {
    this.setState({ loading: true })
    const { patientId, token } = this.state
    const url = `${Config.BACKEND_URL}stripe/customer-portal`
    try {
      const response = await axios({
        method: 'get',
        headers: { x_patient_id: patientId, Authorization: `JWT ${token}` },
        url,
      })
      if (response.data && response.data.url) {
        this.setState({ loading: false })
        window.location.href = response.data.url
      }
    } catch (err) {
      console.log('error when getting customer portal url ', err)
      toast.error('Something went wrong')
    }
  }

  onPlanClicked(priceId) {
    let {state, tosChecked} = this.state
    if(CCFM_STATES[state?.toLowerCase()] && !tosChecked) {
      //if they are in ccfm states and haven't accepted the terms
      window.alert('Please read and accept terms of agreement')
      return
    }
    this.createCheckoutSession(priceId, true)
  }

  async createCheckoutSession(priceId, couponEnabled) {
    const { token, patientId, patientEmail } = this.state
    const url = `${Config.BACKEND_URL}stripe/sessions`
    const stripePublishableKey = Config.STRIPE_PUBLISHABLE_API_KEY
    console.log('requesting sessionf for price id', priceId, couponEnabled)
    this.setState({ loading: true })
    try {
      const stripe = await loadStripe(stripePublishableKey)
      const response = await axios({
        method: 'get',
        headers: {
          Authorization: `JWT ${token}`,
          stripe_price_id: priceId,
          x_patient_id: patientId,
          email: patientEmail,
          couponEnabled: true,
        },
        url,
      })

      const { data } = response
      this.setState({ loading: false })
      stripe
        .redirectToCheckout({
          sessionId: data.sessionId,
        })
        .then(() => {
          console.log('stripe success')
        })
        .catch((err) => {
          console.log('error when creating stripe session', err)
        })
    } catch (err) {
      console.log('error when creating session', err)
      this.setState({ loading: false })
    }
  }

  renderValueProps() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '6px 0px',
          borderRadius: 4,
          border: '1px solid gray',
          width: '96%',
        }}
      >
        <p
          style={{
            fontWeight: 'bold',
            fontSize: 18,
            width: '100%',
            textAlign: 'center',
            color: Constants.primaryTheme,
          }}
        >
          $99 / month
        </p>
        <p style={{ textAlign: 'center', color: '#7e7e7e' }}>or</p>
        <p
          style={{
            fontWeight: 'bold',
            fontSize: 18,
            width: '100%',
            textAlign: 'center',
            color: Constants.primaryTheme,
          }}
        >
          $250 / 3 months
        </p>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            flexWrap: 'wrap',
            justifyContent: 'center',
            marginTop: 10,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '45%',
              margin: '6px 2px',
              alignItems: 'center',
            }}
          >
            <div className={Styles.propIconWrapper}>
              <FontAwesomeIcon
                onClick={() =>
                  this.setState({ modalIsOpen: true, modalGoals: true })
                }
                icon={faBolt}
                className={Styles.propIcon}
              />
            </div>

            <p
              style={{
                width: '100%',
                textAlign: 'left',
                fontSize: 13,
                color: TEXT_COLOR,
              }}
            >
              Exercise Recommendations
            </p>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '45%',
              margin: '6px 2px',
              alignItems: 'center',
            }}
          >
            <div className={Styles.propIconWrapper}>
              <FontAwesomeIcon
                onClick={() =>
                  this.setState({ modalIsOpen: true, modalGoals: true })
                }
                icon={faAppleAlt}
                className={Styles.propIcon}
              />
            </div>

            <p
              style={{
                width: '100%',
                textAlign: 'left',
                fontSize: 13,
                color: TEXT_COLOR,
              }}
            >
              Nutrition and Meal Planning
            </p>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '45%',
              margin: '6px 2px',
              alignItems: 'center',
            }}
          >
            <div className={Styles.propIconWrapper}>
              <FontAwesomeIcon
                onClick={() =>
                  this.setState({ modalIsOpen: true, modalGoals: true })
                }
                icon={faBrain}
                className={Styles.propIcon}
              />
            </div>

            <p
              style={{
                width: '100%',
                textAlign: 'left',
                fontSize: 13,
                color: TEXT_COLOR,
              }}
            >
              Mental Well-Being and Mindfulness Training
            </p>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '45%',
              margin: '6px 2px',
              alignItems: 'center',
            }}
          >
            <div className={Styles.propIconWrapper}>
              <FontAwesomeIcon
                onClick={() =>
                  this.setState({ modalIsOpen: true, modalGoals: true })
                }
                icon={faMoon}
                className={Styles.propIcon}
              />
            </div>

            <p
              style={{
                width: '100%',
                textAlign: 'left',
                fontSize: 13,
                color: TEXT_COLOR,
              }}
            >
              Sleep Optimization Training
            </p>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '45%',
              margin: '6px 2px',
              alignItems: 'center',
            }}
          >
            <div className={Styles.propIconWrapper}>
              <FontAwesomeIcon
                onClick={() =>
                  this.setState({ modalIsOpen: true, modalGoals: true })
                }
                icon={faFireAlt}
                className={Styles.propIcon}
              />
            </div>

            <p
              style={{
                width: '100%',
                textAlign: 'left',
                fontSize: 13,
                color: TEXT_COLOR,
              }}
            >
              Condition and Flare Management
            </p>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '45%',
              margin: '6px 2px',
              alignItems: 'center',
            }}
          >
            <div className={Styles.propIconWrapper}>
              <FontAwesomeIcon
                onClick={() =>
                  this.setState({ modalIsOpen: true, modalGoals: true })
                }
                icon={faPhone}
                className={Styles.propIcon}
              />
            </div>
            <p
              style={{
                width: '100%',
                textAlign: 'left',
                fontSize: 13,
                color: TEXT_COLOR,
              }}
            >
              1 Virtual Visit and Unlimited Texting
            </p>
          </div>
        </div>
      </div>
    )
  }

  renderNotAvailableContent() {
    let windowWidth = window.innerWidth
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 40
        }}
      >
        <h5
          style={{
            marginTop: 10,
            color: Constants.primaryThemeDark,
            marginBottom: 20,
            fontSize: 22,
            fontWeight: 'bold'
          }}
        >
          Aila Health Membership
        </h5>

        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <div className={Styles.commImageWrapper}>
            <img
              src={CommImage}
              style={
                windowWidth > 600
                  ? { height: 175, width: 175 }
                  : { height: 125, width: 125 }
              }
            />
          </div>
        </div>

        <p
          style={{
            fontSize: 18,
            color: Constants.primaryThemeDark,
            width: '75%',
            textAlign: 'center',
            marginTop: 20
          }}
        >
          {`We aren't providing clinical care in your state yet but you can still
          use the app to track your symptoms and connect with other patients in
          your community!`}
        </p>

        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.ailahealth.com/ailahealthapp"
        >
          <button
            onClick={null}
            className={GlobalStyles.button}
            style={{
              width: 200,
              marginTop: 40,
              borderRadius: 15,
              borderWidth: 0,
              height: 40,
              fontWeight: 'bold',
            }}
          >
            <p style={{ color: 'white' }}>Download Now</p>
          </button>
        </a>
      </div>
    )
  }

  renderValuePropsBoxAila = (valueProps, isOneMonth, showPopular) => {

    let elements = []
    elements = valueProps && valueProps.map(x => (
      <div style={{display: 'flex', flexDirection: 'row', marginTop: 4,alignItems: 'center'}}>
        <FontAwesomeIcon
          icon={faCheckCircle}
          className={Styles.propIcon}
          style={{marginRight: 10}}
        />
        <p style={{color: Constants.primaryThemeDark}}>{x}</p>
      </div>
    ))

    return (
      <div
        onClick={() => {
          if(isOneMonth)
            this.onPlanClicked(Config.STRIPE_AILA_CLINIC_1MONTH)
          else
            this.onPlanClicked(Config.STRIPE_AILA_CLINIC_3MONTH)
        }}
        className={Styles.valuePropsBoxAila}
        style={{
          marginRight: isOneMonth ? 0 : 20,
          marginLeft: isOneMonth ? 20 : 0
        }}
      >
        <h5
          style={{
            color: Constants.primaryThemeDark,
            marginBottom: 10,
            fontSize: 19,
            fontWeight: 'bold',
            textDecoration: 'underline',
          }}
        >
          {isOneMonth ? '1 Month Membership' : '3 Month Membership'}
        </h5>

        <h5
          style={{
            color: 'gray',
            marginBottom: 20,
            fontSize: 19,
            fontWeight: 'bold',
          }}
        >
          {isOneMonth ? '$150' : '$375'}
        </h5>

        <div style={{display: 'flex', flexDirection: 'column', width: '100%', padding: '0px 10px'}}>
          {elements}
        </div>

        <p style={{
          color: 'gray',
          fontSize: 14,
          marginTop: 20
        }}>
          * Labs and Rx billed
        </p>

        <p style={{
          color: 'gray',
          fontSize: 14,
        }}>
          * Additional processing charges are applicable at checkout
        </p>


        <div style={
          {backgroundColor: 'orange',
            width: 140,
            borderRadius: 4,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 10,
            visibility: showPopular ? 'visible' : 'hidden'
          }}>
          <p style={{color: 'white'}}>Most Popular</p>
        </div>



      </div>
    )
  }


  renderValuePropsBoxCCFM = (valueProps) => {
    let elements = []
    elements = valueProps && valueProps.map(x => (
      <div style={{display: 'flex', flexDirection: 'row', marginTop: 4,alignItems: 'center'}}>
        <FontAwesomeIcon
          icon={faCheckCircle}
          className={Styles.propIcon}
          style={{marginRight: 10}}
        />
        <p style={{color: Constants.primaryThemeDark}}>{x}</p>
      </div>
    ))

    return (
      <div
        onClick={() => {
          this.onPlanClicked(Config.STRIPE_CCFM_LONG_COVID_3MONTH)
        }}
        className={Styles.valuePropsBoxCCFM}
      >
        <h5
          style={{
            color: Constants.primaryThemeDark,
            marginBottom: 10,
            fontSize: 19,
            fontWeight: 'bold',
            textDecoration: 'underline',
          }}
        >
          Long COVID Program
        </h5>

        <h5
          style={{
            color: 'gray',
            marginBottom: 20,
            fontSize: 19,
            fontWeight: 'bold',
          }}
        >
          $375 for 3 months
        </h5>

        <div style={{display: 'flex', flexDirection: 'column', width: '100%', padding: '0px 10px'}}>
          {elements}
        </div>

        <p style={{
          color: 'gray',
          fontSize: 14,
          marginTop: 20
        }}>
          * Additional processing charges are applicable at checkout
        </p>

      </div>
    )
  }


  renderCCFMContent() {
    const start = moment()
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 40
        }}
      >
        <h5
          style={{
            marginTop: 10,
            color: Constants.primaryThemeDark,
            fontWeight: 'bold',
            marginBottom: 20,
            fontSize: 19,
          }}
        >
          Select your plan
        </h5>

        {this.renderValuePropsBoxCCFM(CCFM_VALUE_PROPS)}

        <p style={{fontSize: 14, color: TEXT_COLOR}}>* Your subscription will start on {start.format('Do MMM YYYY')}</p>

        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20, width: '100%', justifyContent: 'center'}}>
          <input
            type="checkbox"
            style={{marginRight: 10}}
            onChange={(checked) => this.setState({tosChecked: !this.state.tosChecked})}
            checked={this.state.tosChecked}/>
          <p style={{color: Constants.primaryThemeDark}}>We partner with California Center for Functional Medicine(CCFM) to provide care in your state. Please read and accept the <a href={TOS_LINK} target="_blank" rel="noopener noreferrer">terms of agreement.</a></p>
        </div>

      </div>
    )
  }

  renderAilaContent() {
    const start = moment()
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 40
        }}
      >
        <h5
          style={{
            marginTop: 10,
            color: Constants.primaryThemeDark,
            fontWeight: 'bold',
            marginBottom: 20,
            fontSize: 19,
          }}
        >
          Select your plan
        </h5>

        <div className={Styles.valuePropsWrapper}>
          {this.renderValuePropsBoxAila(AILA_VALUE_PROPS, false, true)}
          {this.renderValuePropsBoxAila(AILA_VALUE_PROPS, true, false)}
        </div>

        <p style={{fontSize: 14, color: TEXT_COLOR}}>* Your subscription will start on {start.format('Do MMM YYYY')}</p>

      </div>
    )
  }

  renderExistingSubscriptionContent() {
    const {
      subscriptionActive,
      subscriptionCancelledAtEndOfPeriod,
      patientDetails,
    } = this.state
    const stripe = patientDetails.stripe || {}

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 40
        }}
      >
        <h5 style={{ marginTop: 20, color: Constants.primaryThemeDark, marginBottom: 20, fontSize: 22,
          fontWeight: 'bold' }}>
          Aila Health Membership
        </h5>
        {subscriptionActive ? (
          subscriptionCancelledAtEndOfPeriod ? (
            <div
              style={{
                width: '90%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <p style={{ textAlign: 'center', fontSize: 18 }}>
                You have cancelled your subscription
              </p>
              <p style={{ textAlign: 'center', fontSize: 14 }}>
                Enjoy your benefits until your subscription ends on{' '}
                {stripe.period_end &&
                moment(stripe.period_end * 1000).format('Do MMMM YYYY')}
                .{' '}
              </p>
            </div>
          ) : (
            <div
              style={{
                width: '90%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <p
                style={{ textAlign: 'center', fontSize: 18, color: TEXT_COLOR }}
              >
                Your subscription is active
              </p>
              <p
                style={{ textAlign: 'center', fontSize: 14, color: TEXT_COLOR }}
              >
                Next billing on:{' '}
                {stripe.period_end &&
                moment(stripe.period_end * 1000).format('Do MMMM YYYY')}
              </p>
            </div>
          )
        ) : (
          <div
            style={{
              width: '90%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <p style={{ textAlign: 'center', fontSize: 18, color: TEXT_COLOR }}>
              Your subscription is not active
            </p>
            <p style={{ textAlign: 'center', color: TEXT_COLOR }}>
              It ended on{' '}
              {stripe.period_end &&
              moment(stripe.period_end * 1000).format('Do MMMM YYYY')}
            </p>
          </div>
        )}

        <button
          onClick={this.getCustomerPortalUrl}
          className={GlobalStyles.button}
          style={{
            width: 200,
            marginTop: 40,
            borderRadius: 15,
            borderWidth: 0,
            height: 40,
            fontWeight: 'bold',
          }}
        >
          <p style={{ color: 'white' }}>Manage Subscription</p>
        </button>
      </div>
    )
  }

  renderContent() {
    const { patientSubscribed, state } = this.state
    if (patientSubscribed) {
      return this.renderExistingSubscriptionContent()
    }

    if (!state || state.length === 0) return this.renderNotAvailableContent()

    if(CCFM_STATES[state?.toLowerCase()])
      return this.renderCCFMContent()
    else if(AILA_STATES[state?.toLowerCase()])
      return this.renderAilaContent()

    return this.renderNotAvailableContent()

    // return this.renderNewSubscriptionContent()
  }

  render() {
    return (
      <div
        className={GlobalStyles.container}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: 10,
          backgroundColor: 'white',
        }}
      >

        {this.state.loading ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              marginTop: 40,
            }}
          >
            <Loader
              type="Oval"
              color={Constants.primaryTheme}
              height={40}
              width={40}
            />
          </div>
        ) : (
          this.renderContent()
        )}
      </div>
    )
  }
}
