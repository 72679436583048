import React, { Component, useEffect, useState } from 'react'
import {
  Legend,
  Line,
  LineChart,
  ReferenceArea,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import moment from 'moment'
import Styles from './Behaviour.module.scss'
import Constants from '../../../values'
export const Behavioral = (props) => {
    function getChart(chartData, lines, domain, depressionOrAnxiety) {
      const graphWidth = window.innerWidth / 3.1
      return (
        <LineChart
          width={graphWidth}
          height={350}
          data={chartData}
          margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
        >
          <Legend verticalAlign="top" />
          {lines}
          <XAxis
            dataKey="created_at"
            tickFormatter={(tickItem) => moment(tickItem).format('D-MMM')}
          />
          <YAxis domain={domain} />
          {!depressionOrAnxiety ? (
            <Tooltip />
          ) : (
            <Tooltip
              content={({ active, payload, label }) => {
                const date = moment(label).format('D-MMM')
                let depressionScoreAndRemark
                let anxietyScoreAndRemark
                if (depressionOrAnxiety === 'depression')
                  depressionScoreAndRemark =
                    (payload &&
                      payload.length > 0 &&
                      getDepressionScoreAndRemark(payload[0].payload)) ||
                    {}
                else
                  anxietyScoreAndRemark =
                    (payload &&
                      payload.length > 0 &&
                      getAnxietyScoreAndRemark(payload[0].payload)) ||
                    {}
                return (
                  <div
                    className={Styles.chartTooltip}
                    style={{ height: 120, width: 300 }}
                  >
                    {depressionOrAnxiety === 'depression' ? (
                      <div style={{ width: '100%', padding: '0px 5px' }}>
                        <p>{depressionScoreAndRemark.date}</p>
                        <p
                          style={{
                            textAlign: 'left',
                            fontWeight: 'bold',
                            width: '100%',
                          }}
                        >
                          Depression(PHQ-9):{' '}
                          <span style={{ fontWeight: 'normal', marginLeft: 5 }}>
                            {depressionScoreAndRemark.score}
                          </span>
                        </p>
                        <p
                          style={{
                            textAlign: 'left',
                            fontWeight: 'bold',
                            width: '100%',
                          }}
                        >
                          Status:{' '}
                          <span style={{ fontWeight: 'normal', marginLeft: 5 }}>
                            {depressionScoreAndRemark.remark}
                          </span>
                        </p>
                      </div>
                    ) : (
                      <div style={{ width: '100%', padding: '0px 5px' }}>
                        <p>{anxietyScoreAndRemark.date}</p>
                        <p
                          style={{
                            textAlign: 'left',
                            fontWeight: 'bold',
                            width: '100%',
                          }}
                        >
                          Anxiety(GAD-7):{' '}
                          <span style={{ fontWeight: 'normal', marginLeft: 5 }}>
                            {anxietyScoreAndRemark.score}
                          </span>
                        </p>
                        <p
                          style={{
                            textAlign: 'left',
                            fontWeight: 'bold',
                            width: '100%',
                          }}
                        >
                          Status:{' '}
                          <span style={{ fontWeight: 'normal', marginLeft: 5 }}>
                            {anxietyScoreAndRemark.remark}
                          </span>
                        </p>
                      </div>
                    )}
                  </div>
                )
              }}
            />
          )}
        </LineChart>
      )
    }
    
    function renderDepressionChart() {
      const lines = []
      const data = props.behavioralData ? props.behavioralData.depression : []
      lines.push(
        <Line
          dot={false}
          name="Depression Score"
          type="monotone"
          dataKey="phq9_score"
          stroke={Constants.graphLineOne}
        />,
      )
      lines.push(
        <ReferenceArea
          y1={0}
          y2={4}
          fill={Constants.graphReferenceColors[0]}
          fillOpacity={0.1}
        />,
      )
      lines.push(
        <ReferenceArea
          y1={4}
          y2={9}
          fill={Constants.graphReferenceColors[1]}
          fillOpacity={0.1}
        />,
      )
      lines.push(
        <ReferenceArea
          y1={9}
          y2={14}
          fill={Constants.graphReferenceColors[2]}
          fillOpacity={0.1}
        />,
      )
      lines.push(
        <ReferenceArea
          y1={14}
          y2={19}
          fill={Constants.graphReferenceColors[3]}
          fillOpacity={0.1}
        />,
      )
      lines.push(
        <ReferenceArea
          y1={19}
          fill={Constants.graphReferenceColors[4]}
          fillOpacity={0.1}
        />,
      )
      return getChart(data, lines, [0, 27], 'depression')
    }
  
    function renderAnxietyChart() {
      const lines = []
      const data = props.behavioralData ? props.behavioralData.anxiety : []
      lines.push(
        <Line
          dot={false}
          name="Anxiety Score"
          type="monotone"
          dataKey="anxietyScore"
          stroke={Constants.graphLineOne}
        />,
      )
      lines.push(
        <ReferenceArea
          y1={0}
          y2={4}
          fill={Constants.graphReferenceColors[0]}
          fillOpacity={0.1}
        />,
      )
      lines.push(
        <ReferenceArea
          y1={4}
          y2={9}
          fill={Constants.graphReferenceColors[1]}
          fillOpacity={0.1}
        />,
      )
      lines.push(
        <ReferenceArea
          y1={9}
          y2={14}
          fill={Constants.graphReferenceColors[2]}
          fillOpacity={0.1}
        />,
      )
      lines.push(
        <ReferenceArea
          y1={14}
          fill={Constants.graphReferenceColors[4]}
          fillOpacity={0.1}
        />,
      )
      return getChart(data, lines, [0, 24], 'anxiety')
    }
  
    function getAnxietyScoreAndRemark(screening) {
      if (!screening || Object.keys(screening).length === 0)
        return { score: 'N/A', remark: '-', date: '-' }
  
      let toReturn
      const date = moment(screening.created_at).format('MMM DD YYYY')
      if (screening.anxietyScore <= 4)
        toReturn = {
          score: screening.anxietyScore,
          remark: 'No Anxiety',
          date,
        }
      else if (screening.anxietyScore >= 5 && screening.anxietyScore <= 9)
        toReturn = {
          score: screening.anxietyScore,
          remark: 'Mild Anxiety',
          date,
        }
      else if (screening.anxietyScore >= 10 && screening.anxietyScore <= 14)
        toReturn = {
          score: screening.anxietyScore,
          remark: 'Moderate Anxiety',
          date,
        }
      else if (screening.anxietyScore >= 15)
        toReturn = {
          score: screening.anxietyScore,
          remark: 'Severe Anxiety',
          date,
        }
      else toReturn = { score: 'N/A', remark: '-', date: '-' }
  
      return toReturn
    }
  
    function getDepressionScoreAndRemark(screening) {
      if (!screening || Object.keys(screening).length === 0)
        return { score: 'N/A', remark: '-', date: '-' }
  
      let toReturn = {}
      const date = moment(screening.created_at).format('MMM DD YYYY')
      if (screening.phq9_score <= 4)
        toReturn = {
          score: screening.phq9_score,
          remark: 'No Depression',
          date,
        }
      else if (screening.phq9_score >= 5 && screening.phq9_score <= 9)
        toReturn = {
          score: screening.phq9_score,
          remark: 'Mild Depression',
          date,
        }
      else if (screening.phq9_score >= 10 && screening.phq9_score <= 14)
        toReturn = {
          score: screening.phq9_score,
          remark: 'Moderate Depression',
          date,
        }
      else if (screening.phq9_score >= 15 && screening.phq9_score <= 19)
        toReturn = {
          score: screening.phq9_score,
          remark: 'Moderately Severe Depression',
          date,
        }
      else if (screening.phq9_score >= 20 && screening.phq9_score <= 27)
        toReturn = {
          score: screening.phq9_score,
          remark: 'Severe Depression',
          date,
        }
      else toReturn = { score: 'N/A', remark: '-', date: '-' }
  
      if (screening.suicidal > 0)
        toReturn = {
          score: screening.phq9_score,
          remark: 'Severe Depression/Suicidal',
          date,
        }
  
      return toReturn
    }
  
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          padding: '10px 40px',
        }}
      >
        <div className={Styles.row} style={{ marginTop: 10 }}>
          <div style={{ width: '50%' }}>
            <div
              className={Styles.chartWrapper}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <p
                style={{
                  fontWeight: 'bold',
                  width: '100%',
                  textAlign: 'center',
                  fontSize: 17,
                  color: Constants.primaryTheme,
                }}
              >
                Depression (PHQ-9)
              </p>
              {renderDepressionChart()}
            </div>
          </div>
  
          <div style={{ width: '50%' }}>
            <div
              className={Styles.chartWrapper}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <p
                style={{
                  fontWeight: 'bold',
                  width: '100%',
                  textAlign: 'center',
                  fontSize: 17,
                  color: Constants.primaryTheme,
                }}
              >
                Anxiety (GAD-7)
              </p>
              {renderAnxietyChart()}
            </div>
          </div>
        </div>
      </div>
    )
  }