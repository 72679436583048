import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SidebarHeader,
  SubMenu,
  SidebarFooter,
} from 'react-pro-sidebar'
import {
  FaHome,
  FaHandHoldingHeart,
  FaUser,
  FaUserMd,
  FaGripLines,
  FaRocketchat,
  FaUserInjured,
  FaBookMedical,
  FaUserLock,
  FaUsers,
  FaRegFile,
  FaCalendar,
  FaChartLine,
  FaDna,
  FaPlusCircle,
  FaBell,
  FaClipboardList,
  FaFax,
  FaUserFriends,
  FaMoneyCheck,
  FaHeartbeat,
  FaListAlt,
  FaHospital,
  FaTasks,
} from 'react-icons/fa'
import { connect } from 'react-redux'
import Config from '../config'
import Styles from './styles/styles.module.scss'
import firebase from '../services/firebase.js'
import GlobalStyles from '../pages/styles/global.module.scss'
import {
  userLoggedIn,
  addPatientLP,
  updateSideNavBar,
  addFilledData,
  getUnreadMessages,
} from '../redux/actions'

const SIDEBAR_STYLE = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: 20,
  padding: '10px 2px',
  letterSpacing: '1px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}

class SideNav extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collapsed: false,
      render: false,
      path: '/',
    }
  }

  componentDidMount() {
    this.props.getUnreadMessages(
      this.props.adminId,
      this.props.token,
      this.props.mongoUser,
    )
    this.props.onNavCollapse(false)
  }

  static getDerivedStateFromProps(props, state) {
    let path = window.location.pathname
    if (!props.userType && path === '/login') path = '/patient/profile'
    if (props.loggedIn && path === '/login' && props.userType)
      path = '/provider/home'

    return {
      path,
      render: path !== window.location.pathname ? !state.render : state.render,
    }
  }

  onPatientListClicked() {
    this.props.getUnreadMessages(
      this.props.adminId,
      this.props.token,
      this.props.mongoUser,
    )
    this.props.navigation.push('/patient/list')
    this.props.updateSideNavBar(!this.props.updateRender)
  }

  onPatientNavLinkClicked(lp) {
    this.props.addPatientLP(lp)
    // let render = this.state.render
    // this.setState({render: !render})
  }

  onNavCollapsePressed() {
    const current = this.state.collapsed
    this.props.onNavCollapse(!current)
    this.setState({ collapsed: !current })
  }

  renderCCFMSidebar() {
    const { path } = this.state
    const { userType } = this.props
    let surveyElement = <p></p>
    if (userType === 'ccfm-patient') {
      surveyElement = (
        <MenuItem
          icon={
            <FaRegFile
              size={25}
              color={path === '/wellness/survey' ? 'black' : 'white'}
            />
          }
        >
          <NavLink
            onClick={() => this.setState({ render: !this.state.render })}
            activeStyle={{ color: 'black' }}
            to="/wellness/survey"
          >
            Survey
          </NavLink>
        </MenuItem>
      )
    }
    return (
      <ProSidebar
        collapsed={this.state.collapsed}
        toggled={false}
        onToggle={() => console.log('toggled')}
      >
        <SidebarHeader>
          <div
            onClick={this.onNavCollapsePressed.bind(this)}
            style={SIDEBAR_STYLE}
          >
            {this.state.collapsed ? (
              <div>
                <FaGripLines size={25} color="white" />
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <p style={{ textOverflow: 'ellipsis', marginRight: 10 }}>
                  AILA HEALTH
                </p>
                <FaGripLines size={25} color="white" />
              </div>
            )}
          </div>
        </SidebarHeader>

        <SidebarContent>
          <Menu>
            <MenuItem
              icon={
                <FaUsers
                  size={25}
                  color={path === '/wellness/dashboard' ? 'black' : 'white'}
                />
              }
            >
              <NavLink
                onClick={() => this.setState({ render: !this.state.render })}
                activeStyle={{ color: 'black' }}
                to="/wellness/dashboard"
              >
                Wellness Dashboard
              </NavLink>
            </MenuItem>

            {surveyElement}
          </Menu>
        </SidebarContent>

        {!this.state.collapsed && (
          <SidebarFooter style={{ textAlign: 'center' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingBottom: 30,
              }}
            >
              <a
                style={{ height: 30 }}
                href=""
                target="_blank"
                rel="noopener noreferrer"
              >
                <p
                  style={{
                    color: 'white',
                    textDecoration: 'underline',
                    fontSize: 14,
                  }}
                >
                  Privacy policy
                </p>
              </a>
              <a
                style={{ height: 30 }}
                href=""
                target="_blank"
                rel="noopener noreferrer"
              >
                <p
                  style={{
                    color: 'white',
                    textDecoration: 'underline',
                    fontSize: 14,
                  }}
                >
                  Terms of Use
                </p>
              </a>

              <Link to="/support">
                <Button
                  onClick={() =>
                    this.props.updateSideNavBar(!this.props.updateRender)
                  }
                  style={{
                    marginTop: 20,
                    width: 200,
                    backgroundColor: '#ffffff44',
                    borderColor: '#00000000',
                  }}
                  variant="primary"
                  size="xs"
                >
                  Contact Support
                </Button>
              </Link>

              <Link to="/login">
                <Button
                  onClick={() => firebase.auth().signOut()}
                  style={{
                    marginTop: 20,
                    width: 200,
                    backgroundColor: '#ffffff44',
                    borderColor: '#00000000',
                  }}
                  variant="primary"
                  size="xs"
                >
                  Sign Out
                </Button>
              </Link>
            </div>
          </SidebarFooter>
        )}
      </ProSidebar>
    )
  }

  renderPatientSidebar() {
    const { path } = this.state
    console.log('patient ', path)
    return (
      <ProSidebar
        collapsed={this.state.collapsed}
        toggled={false}
        onToggle={() => console.log('toggled')}
      >
        <SidebarHeader>
          <div
            onClick={this.onNavCollapsePressed.bind(this)}
            style={SIDEBAR_STYLE}
          >
            {this.state.collapsed ? (
              <div>
                <FaGripLines size={25} color="white" />
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <p style={{ textOverflow: 'ellipsis', marginRight: 10 }}>
                  AILA HEALTH
                </p>
                <FaGripLines size={25} color="white" />
              </div>
            )}
          </div>
        </SidebarHeader>
        <SidebarContent>
          <Menu>
            <MenuItem
              onClick={() =>
                this.props.updateSideNavBar(!this.props.updateRender)
              }
              icon={
                <FaHome
                  size={25}
                  color={path === '/patient/home' ? 'black' : 'white'}
                />
              }
            >
              <NavLink
                activeStyle={this.props.patient ? { color: 'black' } : {}}
                to={this.props.patient ? '/patient/home' : '/login'}
                exact
              >
                Home
              </NavLink>
            </MenuItem>
            <MenuItem
              onClick={() =>
                this.props.updateSideNavBar(!this.props.updateRender)
              }
              icon={
                <FaHandHoldingHeart
                  size={25}
                  color={path === '/patient/mycare' ? 'black' : 'white'}
                />
              }
            >
              <NavLink
                activeStyle={this.props.patient ? { color: 'black' } : {}}
                to={this.props.patient ? '/patient/mycare' : '/login'}
              >
                My Care
              </NavLink>
            </MenuItem>
            {/* <MenuItem
              onClick={() =>
                this.props.updateSideNavBar(!this.props.updateRender)
              }
              icon={
                <FaUser
                  size={25}
                  color={path === '/patient/profile' ? 'black' : 'white'}
                />
              }
            >
              <NavLink
                activeStyle={this.props.patient ? { color: 'black' } : {}}
                to={this.props.patient ? '/patient/profile' : '/login'}
              >
                Profile
              </NavLink>
            </MenuItem> */}

            <MenuItem
              onClick={() =>
                this.props.updateSideNavBar(!this.props.updateRender)
              }
              icon={
                <FaCalendar
                  size={25}
                  color={path === '/patient/appointments' ? 'black' : 'white'}
                />
              }
            >
              <NavLink
                // onClick={() => this.setState({ render: !this.state.render })}
                activeStyle={{ color: 'black' }}
                to="/patient/appointments"
              >
                Appointments
              </NavLink>
            </MenuItem>

            <MenuItem
              onClick={() =>
                this.props.updateSideNavBar(!this.props.updateRender)
              }
              icon={
                <FaUserFriends
                  size={25}
                  color={path === '/patient/community' ? 'black' : 'white'}
                />
              }
            >
              <NavLink
                activeStyle={this.props.patient ? { color: 'black' } : {}}
                to={this.props.patient ? '/patient/community' : '/login'}
              >
                Communities
              </NavLink>
            </MenuItem>

            <MenuItem
              onClick={() =>
                this.props.updateSideNavBar(!this.props.updateRender)
              }
              icon={
                <FaRocketchat
                  size={25}
                  color={path === '/patient/messages' ? 'black' : 'white'}
                />
              }
            >
              <NavLink
                // onClick={() => this.setState({ render: !this.state.render })}
                activeStyle={{ color: 'black' }}
                to={this.props.patient ? '/patient/messages' : '/login'}
                // to="/patient/messages"
              >
                Messages
              </NavLink>
            </MenuItem>

            <MenuItem
              onClick={() =>
                this.props.updateSideNavBar(!this.props.updateRender)
              }
              icon={
                <FaUser
                  size={25}
                  color={path === '/patient/account' ? 'black' : 'white'}
                />
              }
            >
              <NavLink
                // onClick={() => this.setState({ render: !this.state.render })}
                activeStyle={{ color: 'black' }}
                to="/patient/account"
                exact
              >
                My Account
              </NavLink>
            </MenuItem>

            <MenuItem
              onClick={() =>
                this.props.updateSideNavBar(!this.props.updateRender)
              }
              icon={
                <FaHeartbeat
                  size={25}
                  color={path === '/patient/subscription' ? 'black' : 'white'}
                />
              }
            >
              <NavLink
                // onClick={() => this.setState({ render: !this.state.render })}
                activeStyle={{ color: 'black' }}
                to="/patient/subscription"
              >
                Subscriptions
              </NavLink>
            </MenuItem>

            <MenuItem
              // TODO: Get this checked by pratik/ Fathima
              onClick={() =>
                this.props.updateSideNavBar(!this.props.updateRender)
              }
              icon={
                <FaMoneyCheck
                  size={25}
                  color={path === '/patient/mycards' ? 'black' : 'white'}
                />
              }
            >
              <NavLink
                // onClick={() => this.setState({ render: !this.state.render })}
                activeStyle={{ color: 'black' }}
                to="/patient/mycards"
              >
                My Cards
              </NavLink>
            </MenuItem>


            {/* <MenuItem
              onClick={() =>
                this.props.updateSideNavBar(!this.props.updateRender)
              }
              icon={
                <FaChartLine
                  size={25}
                  color={path === '/patient/progress' ? 'black' : 'white'}
                />
              }
            >
              <NavLink
                activeStyle={this.props.patient ? { color: 'black' } : {}}
                to={this.props.patient ? '/patient/progress' : '/login'}
              >
                Progress
              </NavLink>
            </MenuItem>

            <MenuItem
              onClick={() =>
                this.props.updateSideNavBar(!this.props.updateRender)
              }
              icon={
                <FaDna
                  size={25}
                  color={path === '/patient/body-systems' ? 'black' : 'white'}
                />
              }
            >
              <NavLink
                activeStyle={this.props.patient ? { color: 'black' } : {}}
                to={this.props.patient ? '/patient/body-systems' : '/login'}
              >
                Body Systems
              </NavLink>
            </MenuItem>

            <MenuItem
              onClick={() =>
                this.props.updateSideNavBar(!this.props.updateRender)
              }
              icon={
                <FaPlusCircle
                  size={25}
                  color={path === '/patient/careplan' ? 'black' : 'white'}
                />
              }
            >
              <NavLink
                activeStyle={this.props.patient ? { color: 'black' } : {}}
                to={{
                  pathname: this.props.patient ? '/patient/careplan' : '/login',
                  state: 'care-plan',
                  key: 2,
                }}
              >
                Care Plan
              </NavLink>
            </MenuItem> */}
          </Menu>
        </SidebarContent>
        {!this.state.collapsed && (
          <SidebarFooter style={{ textAlign: 'center' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingBottom: 30,
              }}
            >
              {/* <a
                style={{ height: 30 }}
                href={Config.PRIVACY_POLICY_LINK}
                target="_blank"
                rel="noopener noreferrer"
              >
                <p
                  style={{
                    color: 'white',
                    textDecoration: 'underline',
                    fontSize: 14,
                  }}
                >
                  HIPAA Notice, Privacy Policy
                </p>
              </a>
              <a
                style={{ height: 30 }}
                href={Config.TOS_LINK}
                target="_blank"
                rel="noopener noreferrer"
              >
                <p
                  style={{
                    color: 'white',
                    textDecoration: 'underline',
                    fontSize: 14,
                  }}
                >
                  Terms of Service
                </p>
              </a> */}
              <a
                style={{ height: 30 }}
                href="https://www.ailahealth.com/policies"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p
                  style={{
                    color: 'white',
                    textDecoration: 'underline',
                    fontSize: 14,
                  }}
                >
                  Policies
                </p>
              </a>

              <Link
                // to="/support"
                to={{
                  pathname: '/support',
                  patient: 'Patient',
                }}
              >
                <Button
                  onClick={() =>
                    this.props.updateSideNavBar(!this.props.updateRender)
                  }
                  style={{
                    marginTop: 20,
                    width: 200,
                    backgroundColor: '#ffffff44',
                    borderColor: '#00000000',
                  }}
                  variant="primary"
                  size="xs"
                >
                  Contact Technical Support
                </Button>
              </Link>

              <Link to="/login">
                <Button
                  onClick={() => firebase.auth().signOut()}
                  style={{
                    marginTop: 20,
                    width: 200,
                    backgroundColor: '#ffffff44',
                    borderColor: '#00000000',
                  }}
                  variant="primary"
                  size="xs"
                >
                  Sign Out
                </Button>
              </Link>
            </div>
          </SidebarFooter>
        )}
      </ProSidebar>
    )
  }

  renderCompleteSidebar() {
    const { path } = this.state
    const color = path.includes('/patient') ? 'black' : 'white'
    const { userType, patient, unreadNotifications, mongoUser } = this.props
    let wellnessDashboard = <p></p>
    let wellnessDashboardDemo = <p></p>
    let messages = <p></p>
    let content = <p></p>
    let appointments = <p></p>
    let notifications = <p></p>
    let faxes = <p></p>
    let orders = <p></p>
    let patientRoster = <p></p>
    let providerRoster = <p></p>
    let clinicalSupport = <p></p>
    const popDashboard = <p></p>
    if (userType && userType.includes('ccfm')) {
      // show wellness dashboard only for ccfm people
      wellnessDashboard = (
        <MenuItem
          icon={
            <FaUsers
              size={25}
              color={path === '/wellness/dashboard' ? 'black' : 'white'}
            />
          }
        >
          <NavLink
            onClick={() => this.setState({ render: !this.state.render })}
            activeStyle={{ color: 'black' }}
            to="/wellness/dashboard"
          >
            Wellness Dashboard
          </NavLink>
        </MenuItem>
      )
    } else {
      wellnessDashboard = (
        <div>
          <MenuItem
            icon={
              <FaUsers
                size={25}
                color={path === '/population/dashboard' ? 'black' : 'white'}
              />
            }
          >
            <NavLink
              onClick={() => {
                this.setState({ render: !this.state.render })
                this.props.addFilledData(null)
              }}
              activeStyle={{ color: 'black' }}
              to="/population/dashboard"
            >
              Population Dashboard
            </NavLink>
          </MenuItem>
        </div>
      )

      if (Config.ENV === 'dev') {
        wellnessDashboardDemo = (
          <div>
            <MenuItem
              icon={
                <FaUsers
                  size={25}
                  color={path === '/population/dashboard-1' ? 'black' : 'white'}
                />
              }
            >
              <NavLink
                onClick={() => {
                  this.setState({ render: !this.state.render })
                  this.props.addFilledData(null)
                }}
                activeStyle={{ color: 'black' }}
                to="/population/dashboard-1"
              >
                Pop Dashboard Demo
              </NavLink>
            </MenuItem>
          </div>
        )
      }

      messages = (
        <MenuItem
          icon={
            <FaRocketchat
              size={25}
              color={path === '/messages' ? 'black' : 'white'}
            />
          }
        >
          <NavLink
            onClick={() => this.setState({ render: !this.state.render })}
            activeStyle={{ color: 'black' }}
            to="/messages"
          >
            Messages
          </NavLink>
        </MenuItem>
      )

      appointments = (
        <MenuItem
          icon={
            <FaCalendar
              size={25}
              color={path === '/appointments' ? 'black' : 'white'}
            />
          }
        >
          <NavLink
            onClick={() => this.setState({ render: !this.state.render })}
            activeStyle={{ color: 'black' }}
            to="/appointments"
          >
            Appointments
          </NavLink>
        </MenuItem>
      )

      content = (
        <MenuItem
          icon={
            <FaBookMedical
              size={25}
              color={path === '/content/library' ? 'black' : 'white'}
            />
          }
        >
          <NavLink
            onClick={() => this.setState({ render: !this.state.render })}
            activeStyle={{ color: 'black' }}
            to="/content/library"
          >
            Content Library
          </NavLink>
        </MenuItem>
      )

      faxes = (
        <MenuItem
          icon={
            <FaFax size={25} color={path === '/faxes' ? 'black' : 'white'} />
          }
        >
          <NavLink
            onClick={() => this.setState({ render: !this.state.render })}
            activeStyle={{ color: 'black' }}
            to="/faxes"
          >
            Faxes
          </NavLink>
        </MenuItem>
      )

      notifications = (
        <MenuItem
          icon={
            <FaBell
              size={25}
              color={path === '/notifications' ? 'black' : 'white'}
            />
          }
        >
          <NavLink
            onClick={() => {
              this.props.getUnreadMessages(
                this.props.adminId,
                this.props.token,
                this.props.mongoUser,
              )
              console.log('UNREAD NOT FROM REDUCER: ', unreadNotifications)
              this.setState({
                render: !this.state.render,
              })
            }}
            activeStyle={{ color: 'black' }}
            to="/notifications"
          >
            Notifications
          </NavLink>
          {unreadNotifications !== null && (
            <div
              style={{
                width: 15,
                height: 15,
                borderRadius: 10,
                backgroundColor: 'red',
                position: 'absolute',
                right: 215,
                top: 8,
              }}
            />
          )}
        </MenuItem>
      )

      orders = (
        <MenuItem
          icon={
            <FaClipboardList
              size={25}
              color={path === '/orders' ? 'black' : 'white'}
            />
          }
        >
          <NavLink
            onClick={() => this.setState({ render: !this.state.render })}
            activeStyle={{ color: 'black' }}
            to="/orders"
          >
            Orders
          </NavLink>
        </MenuItem>
      )

      patientRoster = (
        <MenuItem
          icon={
            <FaListAlt
              size={25}
              color={path === '/roster-patient' ? 'black' : 'white'}
            />
          }
        >
          <NavLink
            onClick={() => this.setState({ render: !this.state.render })}
            activeStyle={{ color: 'black' }}
            to="/roster-patient"
          >
            Patient Roster
          </NavLink>
        </MenuItem>
      )

      providerRoster = (
        <MenuItem
          icon={
            <FaHospital
              size={25}
              color={path === '/roster-provider' ? 'black' : 'white'}
            />
          }
        >
          <NavLink
            onClick={() => this.setState({ render: !this.state.render })}
            activeStyle={{ color: 'black' }}
            to="/roster-provider"
          >
            Provider Roster
          </NavLink>
        </MenuItem>
      )

      clinicalSupport = (
        <MenuItem
          icon={
            <FaTasks
              size={25}
              color={path === '/clinical-support' ? 'black' : 'white'}
            />
          }
        >
          <NavLink
            onClick={() => this.setState({ render: !this.state.render })}
            activeStyle={{ color: 'black' }}
            to="/clinical-support"
          >
            Support
          </NavLink>
        </MenuItem>
      )

    }
    return (
      <ProSidebar
        collapsed={this.state.collapsed}
        toggled={false}
        onToggle={() => console.log('toggled')}
      >
        <SidebarHeader>
          <div
            onClick={this.onNavCollapsePressed.bind(this)}
            style={SIDEBAR_STYLE}
          >
            {this.state.collapsed ? (
              <div>
                <FaGripLines size={25} color="white" />
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <p style={{ textOverflow: 'ellipsis', marginRight: 10 }}>
                  AILA HEALTH
                </p>
                <FaGripLines size={25} color="white" />
              </div>
            )}
          </div>
        </SidebarHeader>

        <SidebarContent>
          <Menu>
            {/* <MenuItem */}
            {/*  icon={<FaHome size={25} color={path.includes('home') ? 'black' : 'white'}/>}> */}
            {/*  <NavLink  onClick={() => this.setState({render: !this.state.render})} activeStyle={{color: 'black'}} to="/home"> */}
            {/*    Home */}
            {/*  </NavLink> */}
            {/* </MenuItem> */}
            <MenuItem
             icon={<FaHome size={25} color={path.includes('home') ? 'black' : 'white'}/>}>
             <NavLink  onClick={() => this.setState({render: !this.state.render})} activeStyle={{color: 'black'}} to="/provider/home" exact>
               Home
             </NavLink>
            </MenuItem>
            {/* <MenuItem
              onClick={() =>
                this.props.updateSideNavBar(!this.props.updateRender)
              }
              icon={
                <FaHome
                  size={25}
                  color={path === '/patient/home' ? 'black' : 'white'}
                />
              }
            >
              <NavLink
                activeStyle={this.props.patient ? { color: 'black' } : {}}
                to={this.props.patient ? '/patient/home' : '/login'}
              >
                Home
              </NavLink>
            </MenuItem> */}
            <SubMenu
              // suffix={<span className={Styles.badgeYellow}>3</span>}
              open={!!this.props.patient}
              onOpenChange={this.onPatientListClicked.bind(this)}
              title="My Patients"
              className={
                path.includes('/patient')
                  ? Styles.subMenuItemBlack
                  : Styles.subMenuItemWhite
              }
              icon={
                <FaUserInjured
                  size={25}
                  color={path.includes('/patient') ? 'black' : 'white'}
                />
              }
            >
              {this.props.patient && (
                <div>
                  <MenuItem>
                    <NavLink
                      onClick={() => this.onPatientNavLinkClicked('profile')}
                      activeStyle={this.props.patient ? { color: 'black' } : {}}
                      to={
                        this.props.patient
                          ? '/patient/profile'
                          : '/patient/list'
                      }
                    >
                      Profile
                    </NavLink>
                  </MenuItem>
                  <MenuItem>
                    <NavLink
                      onClick={() => this.onPatientNavLinkClicked('progress')}
                      activeStyle={this.props.patient ? { color: 'black' } : {}}
                      to={
                        this.props.patient
                          ? '/patient/progress'
                          : '/patient/list'
                      }
                    >
                      Progress
                    </NavLink>
                  </MenuItem>
                  <MenuItem>
                    <NavLink
                      onClick={() => this.onPatientNavLinkClicked('profile')}
                      activeStyle={this.props.patient ? { color: 'black' } : {}}
                      to={
                        this.props.patient
                          ? '/patient/body-systems'
                          : '/patient/list'
                      }
                    >
                      Body Systems
                    </NavLink>
                  </MenuItem>
                  <MenuItem>
                    <NavLink
                      to={{
                        pathname: this.props.patient
                          ? '/patient/careplan'
                          : '/patient/list',
                        state: 'care-plan',
                        key: 2,
                      }}
                      onClick={() => {
                        this.onPatientNavLinkClicked('careplan')
                      }}
                      activeStyle={this.props.patient ? { color: 'black' } : {}}
                    >
                      Care Plan
                    </NavLink>
                  </MenuItem>
                </div>
              )}
            </SubMenu>

            {/* { (mongoUser?.user_type?.toLowerCase() === 'administrator') ? patientRoster : <></>} */}
            {/* { (mongoUser?.user_type?.toLowerCase() === 'administrator') ? providerRoster : <></>} */}
            {orders}
            {messages}
            {appointments}
            {faxes}

            <MenuItem
              icon={
                <FaUserFriends
                  size={25}
                  color={path === '/patient/community' ? 'black' : 'white'}
                />
              }
            >
              <NavLink
                onClick={() => this.setState({ render: !this.state.render })}
                activeStyle={{ color: 'black' }}
                to="/patient/community"
              >
                Patient Communities
              </NavLink>
            </MenuItem>

            {content}
            {wellnessDashboard}
            {wellnessDashboardDemo}

            <SubMenu
              // suffix={<span className={Styles.badgeYellow}>3</span>}
              // open={!!this.props.patient}
              // onOpenChange={this.onPatientListClicked.bind(this)}
              onClick={() => this.setState({ render: !this.state.render })}
              title={
                <NavLink
                  onClick={() => this.setState({ render: !this.state.render })}
                  activeStyle={{ color: 'black' }}
                  to="/account"            
                >
                  My Account
                </NavLink>
              }
              className={
                path.includes('/account')
                  ? Styles.subMenuItemBlack
                  : Styles.subMenuItemWhite
              }
              icon={
                <FaUserMd
                  size={25}
                  color={path.includes('/account') ? 'black' : 'white'}
                />
              }
            >
              <MenuItem
              // icon={
              //   <FaUserMd
              //     size={25}
              //     color={path === '/account' ? 'black' : 'white'}
              //   />
              // }
              >
                <NavLink
                  onClick={() => this.setState({ render: !this.state.render })}
                  activeStyle={{ color: 'black' }}
                  to="/account"
                  exact
                >
                  Account
                </NavLink>
              </MenuItem>

              <MenuItem>
                <NavLink
                  // onClick={() => this.onPatientNavLinkClicked('profile')}
                  activeStyle={{ color: 'black' }}
                  to={
                    this.props.mongoUser ? '/account/credentialing' : '/account'
                  }
                >
                  Credentialing
                </NavLink>
              </MenuItem>

              <MenuItem>
                <NavLink
                  // onClick={() => this.onPatientNavLinkClicked('profile')}
                  activeStyle={{ color: 'black' }}
                  to={this.props.mongoUser ? '/account/invoices' : '/account'}
                >
                  Invoices
                </NavLink>
              </MenuItem>

              <MenuItem>
                <NavLink
                  // onClick={() => this.onPatientNavLinkClicked('profile')}
                  activeStyle={{ color: 'black' }}
                  to={this.props.mongoUser ? '/account/tutorials' : '/account'}
                >
                  Training
                </NavLink>
              </MenuItem>
            </SubMenu>

            {/* <MenuItem
              icon={
                <FaUserMd
                  size={25}
                  color={path === '/account' ? 'black' : 'white'}
                />
              }
            >
              <NavLink
                onClick={() => this.setState({ render: !this.state.render })}
                activeStyle={{ color: 'black' }}
                to="/account"
              >
                My Account
              </NavLink>
            </MenuItem> */}

            <MenuItem
              icon={
                <FaUserLock
                  size={25}
                  color={path === '/admin' ? 'black' : 'white'}
                />
              }
            >
              <NavLink
                onClick={() => this.setState({ render: !this.state.render })}
                activeStyle={{ color: 'black' }}
                to="/admin"
                exact
              >
                Admin Portal
              </NavLink>
            </MenuItem>
            
            { (mongoUser?.user_type?.toLowerCase() === 'administrator') ? clinicalSupport : <></>}
            {notifications}
          </Menu>
        </SidebarContent>

        {!this.state.collapsed && (
          <SidebarFooter style={{ textAlign: 'center' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingBottom: 30,
              }}
            >
              <a
                style={{ height: 30 }}
                href=""
                target="_blank"
                rel="noopener noreferrer"
              >
                <p
                  style={{
                    color: 'white',
                    textDecoration: 'underline',
                    fontSize: 14,
                  }}
                >
                  Privacy policy
                </p>
              </a>
              <a
                style={{ height: 30 }}
                href=""
                target="_blank"
                rel="noopener noreferrer"
              >
                <p
                  style={{
                    color: 'white',
                    textDecoration: 'underline',
                    fontSize: 14,
                  }}
                >
                  Terms of Use
                </p>
              </a>

              <Link to="/support">
                <Button
                  onClick={() =>
                    this.props.updateSideNavBar(!this.props.updateRender)
                  }
                  style={{
                    marginTop: 20,
                    width: 200,
                    backgroundColor: '#ffffff44',
                    borderColor: '#00000000',
                  }}
                  variant="primary"
                  size="xs"
                >
                  Contact Support
                </Button>
              </Link>

              <Link to="/login">
                <Button
                  onClick={() => firebase.auth().signOut()}
                  style={{
                    marginTop: 20,
                    width: 200,
                    backgroundColor: '#ffffff44',
                    borderColor: '#00000000',
                  }}
                  variant="primary"
                  size="xs"
                >
                  Sign Out
                </Button>
              </Link>
            </div>
          </SidebarFooter>
        )}
      </ProSidebar>
    )
  }

  render() {
    const { userType } = this.props
    if (userType) {
      // one of the doctor or team member or  CCFM related accounts
      if (userType === 'ccfm-patient' || userType === 'ccfm-client')
        return this.renderCCFMSidebar()
      return this.renderCompleteSidebar()
    }
    return this.renderPatientSidebar()
  }
}

const mapStateToProps = (state /* , ownProps */) => ({
  loggedIn: state.userReducer.loggedIn,
  patient: state.patientReducer.patient,
  updateRender: state.userReducer.render,
  userType: state.userReducer.userType,
  navigation: state.genericReducer.navigation,
  unreadNotifications: state.userReducer.unreadNotifications,
  adminId: state.userReducer.adminId,
  token: state.authReducer.token,
  mongoUser: state.userReducer.mongoUser,
})

const mapDispatchToProps = {
  userLoggedIn,
  addPatientLP,
  updateSideNavBar,
  addFilledData,
  getUnreadMessages,
}

export default connect(mapStateToProps, mapDispatchToProps)(SideNav)
