import React, { useState, useEffect } from 'react'
import moment from 'moment'
import Select from 'react-select'
import GlobalStyles from '../../../styles/global.module.scss'
import Styles from './styles/Screeners.module.scss'
import Config from '../../../../config'
import BSModal from "react-bootstrap/Modal";
import Modal from 'react-modal'
import Constants from '../../../../values'
import QuestionMetaData from '../../../../questionMetadata'
import Helper from '../../../../helpers/Global'
import { compareDocumentPosition } from 'domutils'
const axios = require('axios')

const questionModalStyle = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 10,
  },
  content: {
    zIndex: 10,
    width: 1000,
    height: '90vh',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const SCREENERS = [
  { label: 'Wellness', value: 'Wellness', path: 'screenings/wellness' },
  {
    label: 'Physical Function(HAQ-2)',
    value: 'Physical Function(HAQ-2)',
    path: 'screenings/physical',
  },
  {
    label: 'Anxiety(GAD-7)',
    value: 'Anxiety(GAD-7)',
    path: 'screenings/anxiety',
  },
  {
    label: 'Mental Health(PHQ-9)',
    value: 'Mental Health(PHQ-9)',
    path: 'screenings/mental',
  },
  { label: 'Burnout', value: 'Burnout', path: 'screenings/burnout' },
  {
    label: 'Promis Cognitive Function Short Form 4a',
    value: 'Promis Cognitive Function Short Form 4a',
    path: 'screenings/cognitive-promis',
  },
  {
    label: 'Promis Dyspnea Severity Short Form 4a',
    value: 'Promis Dyspnea Severity Short Form 4a',
    path: 'screenings/dyspnea-promis',
  },
  {
    label: 'Promis Pain Intensity 1a',
    value: 'Promis Pain Intensity 1a',
    path: 'screenings/pain-promis',
  },
  {
    label: 'Promis Fatigue Short Form 8a',
    value: 'Promis Fatigue Short Form 8a',
    path: 'screenings/fatigue-promis',
  },
  {
    label: "Crohn's(Harvey Bradshaw)",
    value: "Crohn's(Harvey Bradshaw)",
    path: 'screenings/gastro',
  },
  {
    label: 'Colitis(P-SCCAI)',
    value: 'Colitis(P-SCCAI)',
    path: 'screenings/colitis',
  },
  { label: 'Joints', value: 'Joints', path: 'screenings/joints' },
  {
    label: 'Muscle Pain(Widespread Pain)',
    value: 'Muscle Pain(Widespread Pain)',
    path: 'screenings/pain',
  },
  {
    label: 'Activities of Daily Living',
    value: 'Activities of Daily Living',
    path: 'screenings/activities',
  },
  {
    label: 'Global HRA (COPD Risk + Diabetes Risk + ASCVD Risk)',
    value: 'Global HRA (COPD Risk + Diabetes Risk + ASCVD Risk)',
    path: 'screenings/hra',
  },
  {
    label: 'Fibromyalgia',
    value: 'Fibromyalgia',
    path: 'screenings/fibromyalgia',
  },
  { label: 'Fatigue-FM', value: 'Fatigue-FM', path: 'screenings/fatigue' },
  { label: 'CFS/ME', value: 'CFS/ME', path: 'screenings/cfs-me' },
  {
    label: 'Ankylosing Spondilitis(BASDAI)',
    value: 'Ankylosing Spondilitis(BASDAI)',
    path: 'screenings/basdai',
  },
  { label: 'Fertility', value: 'Fertility', path: 'screenings/fertility' },
  {
    label: 'Menstrual Cycle',
    value: 'Menstrual Cycle',
    path: 'screenings/menstrual',
  },
  {
    label: 'CCFM',
    value: 'CCFM',
    path: 'screenings/ccfm',
  },
  {
    label: 'ACE',
    value: 'ACE',
    path: 'screenings/ace',
  },
  {
    label: 'Sleep Apnea (STOP - BANG)',
    value: 'Sleep Apnea (STOP - BANG)',
    path: 'screenings/sleepapnea',
  },
  {
    label: 'Global Health',
    value: 'Global Health',
    path: 'screenings/globalhealth',
  },
  {
    label: 'HRQoL (sf-36)',
    value: 'HRQoL (sf-36)',
    path: 'screenings/hrqol',
  },
  {
    label: 'Long Covid',
    value: 'Long Covid',
    path: 'longcovid/questionnaire',
  },
]

const Screeners = ({ patientId, token }) => {
  const [loading, setLoading] = useState(false)
  const [selectedScreeners, setSelectedScreeners] = useState([])
  const [wellnessScreening, setWellnessScreening] = useState(null)
  const [anxietyScreening, setAnxietyScreening] = useState(null)
  const [mentalHealthScreening, setMentalHealthScreening] = useState(null)
  const [crohnsScreening, setCrohnsScreening] = useState(null)
  const [colitisScreening, setColitisScreening] = useState(null)
  const [jointsScreening, setJointsScreening] = useState(null)
  const [musclePainScreening, setMusclePainScreening] = useState(null)
  const [activitesScreening, setActivitesScreening] = useState(null)
  const [hraScreening, setHraScreening] = useState(null)
  const [fatiguePromisScreening, setFatiguePromisScreening] = useState(null)
  const [fatigueFMScreening, setFatigueFMScreening] = useState(null)
  const [cfsMeScreening, setCfsMeScreening] = useState(null)
  const [physicalScreening, setPhysicalScreening] = useState(null)
  const [fmScreening, setFMScreening] = useState(null)
  const [fertilityScreening, setFertilityScreening] = useState(null)
  const [menstrualScreening, setMenstrualScreening] = useState(null)
  const [burnoutScreening, setBurnoutScreening] = useState(null)
  const [basdaiScreening, setBasdaiScreening] = useState(null)
  const [cognitiveScreening, setCognitiveScreening] = useState(null)
  const [dyspneaScreening, setDyspneaScreening] = useState(null)
  const [promisPainScreening, setPromisPainScreening] = useState(null)
  const [ccfmScreening, setCcfmScreening] = useState(null)
  const [aceScreening, setAceScreening] = useState(null)
  const [sleepapneaScreening, setSleepapneaScreening] = useState(null)
  const [globalhealthScreening, setGlobalhealthScreening] = useState(null)
  const [hrqolScreening, setHrqolScreening] = useState(null)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [currentQuestion, setCurrentQuestion] = useState({
    label: null,
    path: null,
  })
  const [questionDate, setQuestionDate] = useState('')
  const [answerData, setAnswerData] = useState(null)
  const [lcData, setLcData] = useState(null)

  const hideModal = () => {
    setModalIsOpen(false)
  }
  const openModal = () => {
    setModalIsOpen(true)
  }

  useEffect(() => {
    getAllScreeners()
  }, [])

  const getAllScreeners = () => {
    setLoading(true)
    SCREENERS.forEach((screener) => {
      getScreening(screener.path)
    })
  }

  const getScreening = (path) => {
    const url = Config.BACKEND_URL + path
    axios({
      method: 'get',
      url,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: patientId,
        complete: true,
      },
    })
      .then(({ data }) => {
        setData(path, data)
        setLoading(false)
      })
      .catch((err) => {
        console.log('error when getting screening', err)
      })
  }

  const setData = (path, data) => {
    let tempDefaultQuestions = selectedScreeners
    switch (path) {
      case 'screenings/wellness':
        setWellnessScreening(data)
        if (data.length)
          tempDefaultQuestions.push({
            label: 'Wellness',
            value: 'Wellness',
            path: 'screenings/wellness',
          })
        break

      case 'screenings/anxiety':
        setAnxietyScreening(data)
        if (data.length)
          tempDefaultQuestions.push({
            label: 'Anxiety(GAD-7)',
            value: 'Anxiety(GAD-7)',
            path: 'screenings/anxiety',
          })
        break

      case 'screenings/mental':
        setMentalHealthScreening(data)
        if (data.length)
          tempDefaultQuestions.push({
            label: 'Mental Health(PHQ-9)',
            value: 'Mental Health(PHQ-9)',
            path: 'screenings/mental',
          })
        break

      case 'screenings/gastro':
        setCrohnsScreening(data)
        if (data.length)
          tempDefaultQuestions.push({
            label: "Crohn's(Harvey Bradshaw)",
            value: "Crohn's(Harvey Bradshaw)",
            path: 'screenings/gastro',
          })
        break

      case 'screenings/colitis':
        setColitisScreening(data)
        if (data.length)
          tempDefaultQuestions.push({
            label: 'Colitis(P-SCCAI)',
            value: 'Colitis(P-SCCAI)',
            path: 'screenings/colitis',
          })
        break

      case 'screenings/joints':
        setJointsScreening(data)
        if (data.length)
          tempDefaultQuestions.push({
            label: 'Joints',
            value: 'Joints',
            path: 'screenings/joints',
          })
        break

      case 'screenings/pain':
        setMusclePainScreening(data)
        if (data.length)
          tempDefaultQuestions.push({
            label: 'Muscle Pain(Widespread Pain)',
            value: 'Muscle Pain(Widespread Pain)',
            path: 'screenings/pain',
          })
        break

      case 'screenings/activities':
        setActivitesScreening(data)
        if (data.length)
          tempDefaultQuestions.push({
            label: 'Activities of Daily Living',
            value: 'Activities of Daily Living',
            path: 'screenings/activities',
          })
        break

      case 'screenings/hra':
        setHraScreening(data)
        if (data.length)
          tempDefaultQuestions.push({
            label: 'Global HRA (COPD Risk + Diabetes Risk + ASCVD Risk)',
            value: 'Global HRA (COPD Risk + Diabetes Risk + ASCVD Risk)',
            path: 'screenings/hra',
          })
        break

      case 'screenings/fatigue-promis':
        setFatiguePromisScreening(data)
        if (data.length)
          tempDefaultQuestions.push({
            label: 'Promis Fatigue Short Form 8a',
            value: 'Promis Fatigue Short Form 8a',
            path: 'screenings/fatigue-promis',
          })
        break

      case 'screenings/cfs-me':
        setCfsMeScreening(data)
        if (data.length)
          tempDefaultQuestions.push({
            label: 'CFS/ME',
            value: 'CFS/ME',
            path: 'screenings/cfs-me',
          })
        break

      case 'screenings/fatigue':
        setFatigueFMScreening(data)
        if (data.length)
          tempDefaultQuestions.push({
            label: 'Fatigue-FM',
            value: 'Fatigue-FM',
            path: 'screenings/fatigue',
          })
        break

      case 'screenings/physical':
        setPhysicalScreening(data)
        if (data.length)
          tempDefaultQuestions.push({
            label: 'Physical Function(HAQ-2)',
            value: 'Physical Function(HAQ-2)',
            path: 'screenings/physical',
          })
        break

      case 'screenings/fibromyalgia':
        setFMScreening(data)
        if (data.length)
          tempDefaultQuestions.push({
            label: 'Fibromyalgia',
            value: 'Fibromyalgia',
            path: 'screenings/fibromyalgia',
          })
        break

      case 'screenings/fertility':
        setFertilityScreening(data)
        if (data.length)
          tempDefaultQuestions.push({
            label: 'Fertility',
            value: 'Fertility',
            path: 'screenings/fertility',
          })
        break

      case 'screenings/menstrual':
        setMenstrualScreening(data)
        if (data.length)
          tempDefaultQuestions.push({
            label: 'Menstrual Cycle',
            value: 'Menstrual Cycle',
            path: 'screenings/menstrual',
          })
        break

      case 'screenings/burnout':
        setBurnoutScreening(data)
        if (data.length)
          tempDefaultQuestions.push({
            label: 'Burnout',
            value: 'Burnout',
            path: 'screenings/burnout',
          })
        break

      case 'screenings/basdai':
        setBasdaiScreening(data)
        if (data.length)
          tempDefaultQuestions.push({
            label: 'Ankylosing Spondilitis(BASDAI)',
            value: 'Ankylosing Spondilitis(BASDAI)',
            path: 'screenings/basdai',
          })
        break

      case 'screenings/cognitive-promis':
        setCognitiveScreening(data)
        if (data.length)
          tempDefaultQuestions.push({
            label: 'Promis Cognitive Function Short Form 4a',
            value: 'Promis Cognitive Function Short Form 4a',
            path: 'screenings/cognitive-promis',
          })
        break

      case 'screenings/dyspnea-promis':
        setDyspneaScreening(data)
        if (data.length)
          tempDefaultQuestions.push({
            label: 'Promis Dyspnea Severity Short Form 4a',
            value: 'Promis Dyspnea Severity Short Form 4a',
            path: 'screenings/dyspnea-promis',
          })
        break

      case 'screenings/pain-promis':
        setPromisPainScreening(data)
        if (data.length)
          tempDefaultQuestions.push({
            label: 'Promis Pain Intensity 1a',
            value: 'Promis Pain Intensity 1a',
            path: 'screenings/pain-promis',
          })
        break

      case 'screenings/ccfm':
        setCcfmScreening(data)
        if (data.length)
          tempDefaultQuestions.push({
            label: 'CCFM',
            value: 'CCFM',
            path: 'screenings/ccfm',
          })
        break

      case 'screenings/ace':
        setAceScreening(data)
        if (data.length)
          tempDefaultQuestions.push({
            label: 'ACE',
            value: 'ACE',
            path: 'screenings/ace',
          })
        break

      case 'screenings/sleepapnea':
        setSleepapneaScreening(data)
        if (data.length)
          tempDefaultQuestions.push({
            label: 'Sleep Apnea (STOP - BANG)',
            value: 'Sleep Apnea (STOP - BANG)',
            path: 'screenings/sleepapnea',
          })
        break

      case 'screenings/globalhealth':
        setGlobalhealthScreening(data)
        if (data.length)
          tempDefaultQuestions.push({
            label: 'Global Health',
            value: 'Global Health',
            path: 'screenings/globalhealth',
          })
        break

      case 'screenings/hrqol':
        setHrqolScreening(data)
        if (data.length)
          tempDefaultQuestions.push({
            label: 'HRQoL (sf-36)',
            value: 'HRQoL (sf-36)',
            path: 'screenings/hrqol',
          })
        break

      case 'longcovid/questionnaire':
        setLcData(data)
        if (data.length)
          tempDefaultQuestions.push({
            label: 'Long Covid',
            value: 'Long Covid',
            path: 'longcovid/questionnaire',
          })
        break

      default:
    }
    setSelectedScreeners(tempDefaultQuestions)
  }

  const getScreeningData = (path) => {
    switch (path) {
      case 'screenings/wellness':
        return wellnessScreening
      case 'screenings/anxiety':
        return anxietyScreening
      case 'screenings/mental':
        return mentalHealthScreening
      case 'screenings/gastro':
        return crohnsScreening
      case 'screenings/colitis':
        return colitisScreening
      case 'screenings/joints':
        return jointsScreening
      case 'screenings/pain':
        return musclePainScreening
      case 'screenings/activities':
        return activitesScreening
      case 'screenings/hra':
        return hraScreening
      case 'screenings/fatigue-promis':
        return fatiguePromisScreening
      case 'screenings/cfs-me':
        return cfsMeScreening
      case 'screenings/fatigue':
        return fatigueFMScreening
      case 'screenings/physical':
        return physicalScreening
      case 'screenings/fibromyalgia':
        return fmScreening
      case 'screenings/fertility':
        return fertilityScreening
      case 'screenings/menstrual':
        return menstrualScreening
      case 'screenings/burnout':
        return burnoutScreening
      case 'screenings/basdai':
        return basdaiScreening
      case 'screenings/cognitive-promis':
        return cognitiveScreening
      case 'screenings/dyspnea-promis':
        return dyspneaScreening
      case 'screenings/pain-promis':
        return promisPainScreening
      case 'screenings/ccfm':
        return ccfmScreening
      case 'screenings/ace':
        return aceScreening
      case 'screenings/sleepapnea':
        return sleepapneaScreening
      case 'screenings/globalhealth':
        return globalhealthScreening
      case 'screenings/hrqol':
        return hrqolScreening
      case 'longcovid/questionnaire':
        return lcData
      default:
        return null
    }
  }

  const selectQuestionDate = (val, allAnswer) => {
    setQuestionDate(val)
    let answer = allAnswer.find((answer) =>
      moment(answer.created_at).isSame(moment(val.value)),
    )
    console.log('Selected date answer: ', answer, allAnswer, val)
    setAnswerData(answer)
  }

  const renderQuestionModal = () => {
    const allAnswer = getScreeningData(currentQuestion?.path)
    // setAnswerData(getScreeningData(currentQuestion?.path))
    let dates = allAnswer?.map((data) => ({
      label: moment(data?.created_at).format('MMM Do YYYY'),
      value: data?.created_at,
    }))
    // console.log('current: ', allAnswer)
    return (
      <BSModal
        show={modalIsOpen}
        onHide={hideModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 15
          }}
        >
          <p className={Styles.closeModalBtn} onClick={hideModal}>
            X
          </p>
          <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
            {currentQuestion?.label}
          </h4>

          <div style={{ width: '40%', alignSelf: 'start' }}>
            <div className={Styles.row}>
              <p className={Styles.rowLabel}>Date:</p>
              <Select
                options={dates}
                className={'w-50'}
                defaultValue={{
                  label: moment(allAnswer?.[0]?.created_at).format(
                    'MMM Do YYYY',
                  ),
                  value: allAnswer?.[0]?.created_at,
                }}
                autoBlur={true}
                onChange={(val) => {
                  selectQuestionDate(val, allAnswer)
                }}
              />
            </div>
          </div>

          <div className={Styles.questionContainer}>
            {renderOptionValues()}
            <div className={Styles.inviteRow}>
              <p className={Styles.inviteListHeader} style={{ width: '10%' }}>
                No.
              </p>
              <p className={Styles.inviteListHeader} style={{ width: '60%' }}>
                Questions
              </p>
              <p className={Styles.inviteListHeader} style={{ width: '20%' }}>
                Answers
              </p>
            </div>
            {renderQuestions()}
            {/* <p style={{ textAlign: 'start' }}>
              {answerData?.result?.message &&
                `Result: ${answerData?.result?.message}`}
            </p> */}
          </div>
        </div>
      </BSModal>
    )
  }

  const getJointMusclePainAnswers = (data) => {
    // console.log('getJointMusclePainAnswers: ', data, currentQuestion?.path, data?.tenderness)
    if (currentQuestion?.path === 'screenings/joints') {
      return `Tenderness: ${data?.tenderness}/10, Swollen: ${data?.swollen}/10`
      // return (
      //   <>
      //     {<p>Tenderness: {data?.tenderness}</p>}
      //     {<p>Swollen: {data?.swollen}</p>}
      //   </>
      // )
    } else if (currentQuestion?.path === 'screenings/pain') {
      let sentence = data?.type?.join(', ') ? `, ${data?.type?.join(', ')}` : ''
      return `Pain Level: ${data?.value}/10 ${sentence}`
      // return (
      //   <>
      //     {<p>Pain Level: {data?.value}</p>}
      //     {<p>Pain Type: {data?.type?.join(', ')}</p>}
      //   </>
      // )
    } else {
      return data.toString()
    }
  }

  const getOptions = () => {
    switch (currentQuestion.path) {
      case 'screenings/anxiety':
        return [
          '0 - Not at all',
          '1 - Several Days',
          '2 - More than half the days',
          '3 - Nearly every day',
        ]

      case 'screenings/mental':
        return [
          '0 - Not at all',
          '1 - Several Days',
          '2 - More than half the days',
          '3 - Nearly every day',
        ]

      case 'screenings/burnout':
        return [
          '4 - Very Often',
          '3 - Often',
          '2 - Sometimes',
          '1 - Rarely',
          '0 - Not at all',
        ]

      case 'screenings/fatigue-promis':
        return [
          '0 - Not at all',
          '1 - A little bit',
          '2 - Somewhat',
          '3 - Quite a bit',
          '4 - Very much',
        ]

      case 'screenings/cognitive-promis':
        return [
          '0 - Never',
          '1 - Rarely (once)',
          '2 - Sometimes (2-3 times)',
          '3 - Often (About once/day)',
          '4 - Very Often (Several times/day)',
        ]

      case 'screenings/ccfm':
        return ['0 - least', '10 - most']

      case 'screenings/joints':
        return ['0 - least', '10 - most']

      case 'screenings/pain':
        return ['0 - least', '10 - most']

      case 'screenings/dyspnea-promis':
        return [
          '-1 - I did not do this in the past 7 days',
          '0 - No Shortness of breath',
          '3 - Severely short of breath',
          '2 - Moderately Short of breath',
          '1 - Mildly short of breath',
        ]

      case 'screenings/ace':
        return ['0 - No', '1 - Yes']

      case 'screenings/sleepapnea':
        return ['0 - No', '1 - Yes']

      case 'screenings/fibromyalgia':
        return [
          '0 - No problem',
          '1 - Slight or mild problems; generally mild or intermittent',
          '2 - Moderate; considerable problems; often present and/or at a moderate level',
          '3 - Severe; pervasive, continuous, life-disturbing problems',
        ]

      case 'screenings/fatigue':
        return [
          '4 - Very much',
          '3 - Quite a bit',
          '2 - Somewhat',
          '1 - A little bit',
          '0 - Not at all',
        ]

      case 'screenings/basdai':
        return ['0-Not at all', '10-Very much']

      case 'screenings/physical':
        return [
          '3 - Unable to do',
          '2 - With much difficulty',
          '1 - With some difficulty ',
          '0 - Without any difficulty',
        ]

      case 'screenings/pain-promis':
        return [
          '0 - Not at all',
          '1 - A little bit',
          '2 - Somewhat',
          '3 - Quite a bit',
          '4 - Very much',
        ]

      case 'screenings/globalhealth':
        return [
          '1 - Poor',
          '2 - Fair',
          '3 - Good',
          '4 - Very Good',
          '5 - Excellent',
        ]

      default:
        return []
    }
  }

  const getCfsOptions = (option) => {
    switch (option) {
      case 1:
        return 'Yes to both'

      case 2:
        return 'I experience cognitive impairment'

      case 3:
        return 'My symptoms get worse when standing or sitting upright'

      case 4:
        return 'Neither'

      default:
        return ''
    }
  }

  const getLongCovidTime = (key, score, answerData) => {
    let month = 'covidHistory.duration_months'
      .split('.')
      .reduce((o, i) => o?.[i], answerData)
    let weeks = 'covidHistory.duration_weeks'
      .split('.')
      .reduce((o, i) => o?.[i], answerData)
    let days = 'covidHistory.duration_days'
      .split('.')
      .reduce((o, i) => o?.[i], answerData)
    return `${weeks || 0} weeks ${days || 0} days`
  }

  const getAddedSymptoms = (key, score, answerData) => {
    let data = key.split('.').reduce((o, i) => o?.[i], answerData)
    let result = data?.map((ele) => ele.name)
    return result
  }

  const getHrqolOptions = (key, score) => {
    let options1 = ['Excellent', 'Very Good', 'Good', 'Fair', 'Poor']
    let options2 = [
      'Much better',
      'Somewhat better',
      'About the same',
      'Somewhat worse',
      'Much worse',
    ]
    let options3 = [
      'Yes, limited a lot',
      'Yes, limited a little',
      'No, not limited at all',
    ]
    let options4 = ['Yes', 'No']
    let options5 = [
      'Not at all',
      'Slightly',
      'Moderately',
      'Quite a bit',
      'Extremely',
    ]
    let options6 = [
      'None',
      'Very mild',
      'Mild',
      'Moderate',
      'Severe',
      'Very Severe',
    ]
    let options7 = [
      'All of the time',
      'Most of the time',
      'A good bit of the time',
      'Some of the time',
      'A little of the time',
      'None of the time',
    ]
    let options8 = [
      'All of the time',
      'Most of the time',
      'Some of the time',
      'A little of the time',
      'None of the time',
    ]
    let options9 = [
      'Definitely true',
      'Mostly true',
      'Don’t know',
      'Mostly false',
      'Definitely false',
    ]

    let answerSet1 = [
      'vigorus_activities',
      'moderate_activities',
      'lifting',
      'several_flights',
      'one_flights',
      'bending',
      'walking_mile',
      'walking_several',
      'walking_one',
      'dressing',
    ]
    let answerSet2 = [
      'physical_cut_work',
      'physical_accomplish_less',
      'physical_limit_work',
      'physical_difficult_perform',
      'emotional_cut_work',
      'emotional_accomplish_less',
      'emotional_difficult_perform',
    ]
    let answerSet3 = ['interfere_social', 'interfere_work']
    let answerSet4 = [
      'full_pep',
      'nervous_person',
      'no_cheer',
      'felt_calm',
      'have_energy',
      'down_hearted',
      'worn_out',
      'happy_person',
      'feel_tired',
    ]
    let answerSet5 = [
      'easy_sick',
      'health_anybody',
      'health_worse',
      'health_excellent',
    ]

    if (key === 'general_health') {
      return `${options1[score - 1]}`
    } else if (key === 'compare_health') {
      return `${options2[score - 1]}`
    } else if (answerSet1.includes(key)) {
      return `${options3[score - 1]}`
    } else if (answerSet2.includes(key)) {
      return `${options4[score - 1]}`
    } else if (answerSet3.includes(key)) {
      return `${options5[score - 1]}`
    } else if (key === 'bodily_pain') {
      return `${options6[score - 1]}`
    } else if (answerSet4.includes(key)) {
      return `${options7[score - 1]}`
    } else if (key === 'time_interfere_social') {
      return `${options8[score - 1]}`
    } else if (answerSet5.includes(key)) {
      return `${options9[score - 1]}`
    } else if (key === 'physical_functioning_score') {
      return `${score}/3`
    } else if (
      key === 'role_limitations_physical_score' ||
      key === 'role_limitations_emotional_score'
    ) {
      return `${score}/2`
    } else if (
      key === 'energy_fatigue_score' ||
      key === 'emotional_wellbeing_score'
    ) {
      return `${score}/6`
    } else if (
      key === 'social_functioning_score' ||
      key === 'general_health_sf36_score'
    ) {
      return `${score}/5`
    } else if (key === 'pain_sf36_score') {
      return `${score}/5.5`
    } else {
      return score
    }
  }

  const getScoreValues = (key, score, answerData) => {
    let path = currentQuestion?.path
    if ('screenings/mental' === path) {
      if (answerData?.suicidal > 0 && key === 'phq9_score') {
        return 'Severe Depression'
      } else if (key === 'phq9_score') {
        return `${score}, ${Helper.calculatePhq9(score)}`
      } else {
        return `${score}`
      }
    } else if ('screenings/anxiety' === path && key === 'anxietyScore') {
      return `${score}, ${Helper.calculateAnxiety(score)}`
    } else if (
      'screenings/gastro' === path &&
      key === 'harvey_bradshaw_index'
    ) {
      return `${score}, ${Helper.calculateGastro(score)}`
    } else if ('screenings/colitis' === path && key === 'colitisScore') {
      return `${score}, ${Helper.calculateColitis(score)}`
    } else if ('screenings/physical' === path && key === 'haqScore') {
      return `${Number(score).toFixed(2)}, ${Helper.calculateHaq(score)}`
    } else if ('screenings/cfs-me' === path) {
      if (key === 'symptoms') {
        let option = score.length && score.split(',').map(Number)
        let ans = ''
        option.forEach(
          (opt, index) =>
            (ans += (index === 0 ? '' : ', ') + getCfsOptions(opt)),
        )
        return ans
      } else {
        switch (score) {
          case '1':
            return 'Yes'
          case '2':
            return 'No'
          case '3':
            return 'Not Sure'
          default:
            return score
        }
      }
    } else if ('screenings/menstrual' === path && key === 'last_period') {
      return moment(score).format('MMM Do YYYY')
    } else if ('screenings/hra' === path) {
      if (key === 'copd.quit_smoking') {
        return moment(score).format('MMM Do YYYY')
      } else if (score === 'true' || score === 'false') {
        if (score === 'true') return 'Yes'
        else return 'No'
      } else {
        return score
      }
    } else if ('screenings/sleepapnea' === path || 'screenings/ace' === path) {
      if (key === 'score') {
        return `${score}, ${Helper.calculateSleepApnea(score)}`
      } else {
        return score === '1' ? 'Yes' : 'No'
      }
    } else if ('screenings/ace' === path && key !== 'score') {
      return score === '1' ? 'Yes' : 'No'
    } else if ('screenings/globalhealth' === path) {
      if (key === 'physical_activities') {
        let options = [
          '1 - Not at all',
          '2 - A little',
          '3 - Moderately',
          '4 - Mostly',
          '5 - Completely',
        ]
        return options[score - 1]
      } else if (key === 'emotional_problems') {
        let options = [
          '1 - Always',
          '2 - Often',
          '3 -Sometimes',
          '4 - Rarely',
          '5 - Never',
        ]
        return options[score - 1]
      } else if (key === 'fatigue_rate') {
        let options = [
          '1 - Very Severe',
          '2 - Severe',
          '3 - Moderate',
          '4 - Mild',
          '5 - None',
        ]
        return options[score - 1]
      } else {
        return score
      }
    } else if ('screenings/hrqol' === path) {
      return getHrqolOptions(key, score)
    } else if (
      'longcovid/questionnaire' === path &&
      (key === 'cardioVascular.added_symptoms' ||
        key === 'respiRatory.added_symptoms' ||
        key === 'stomachDigestive.added_symptoms' ||
        key === 'endoCrine.added_symptoms' ||
        key === 'jointPain.added_symptoms' ||
        key === 'mentalHealth.added_symptoms' ||
        key === 'generalHealth.added_symptoms')
    ) {
      return getAddedSymptoms(key, score, answerData)
    } else if (
      'longcovid/questionnaire' === path &&
      key === 'covidHistory.duration_months'
    ) {
      return getLongCovidTime(key, score, answerData)
    } else if (
      'longcovid/questionnaire' === path &&
      key === 'covidHistory.symptoms_start'
    ) {
      return moment(score).format('MMM Do YYYY')
    } else {
      return `${score}`
    }
  }

  const getAnswer = (key, answer) => {
    let data = key.split('.').reduce((o, i) => o?.[i], answer)

    if (Array.isArray(answer?.[key])) {
      // let ans = answer?.[key]?.length ? answer?.[key]?.join() : ''
      return answer?.[key]?.join(', ')
    } else if (
      answer?.[key] ||
      answer?.[key] === 0 ||
      answer?.[key] === false
    ) {
      if (typeof answer?.[key] === 'object' && answer?.[key] !== null) {
        // return JSON.stringify(answer?.[key])
        return getJointMusclePainAnswers(answer?.[key])
      } else {
        return answer?.[key].toString()
      }
    } else if (data || data === 0 || data === false) {
      if (Array.isArray(data)) {
        return data?.join(', ')
      } else {
        return data.toString()
      }
    } else {
      return ''
    }
  }

  const renderQuestions = () => {
    if (!answerData) {
      return [
        <div className={GlobalStyles.container} style={{ height: '100vh' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              height: '100%',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        </div>,
      ]
    }
    let toReturn = []
    console.log('answer Data', answerData)
    answerData &&
      Object.entries(QuestionMetaData?.[currentQuestion?.path]).forEach(
        ([key, value], index) => {
          toReturn.push(
            <div
              key={index.toString()}
              className={Styles.inviteRow}
              style={{ backgroundColor: index % 2 === 0 ? '#e8e8e8' : '' }}
            >
              <p className={Styles.inviteListEntry} style={{ width: '10%' }}>
                {index + 1}.
              </p>
              <p
                className={Styles.inviteListEntry}
                style={{ width: '60%', textAlign: 'start' }}
              >
                {value}
              </p>
              <p className={Styles.inviteListEntry} style={{ width: '20%' }}>
                {getScoreValues(key, getAnswer(key, answerData), answerData)}
              </p>
            </div>,
          )
        },
      )
    return toReturn
  }

  const onClickModal = (data, screener) => {
    data?.length && openModal()
    data?.length &&
      setCurrentQuestion({ label: screener.label, path: screener.path })
    setAnswerData(data?.[0])
  }

  const renderOptionValues = () => {
    // console.log('getOptions: ', getOptions())
    return (
      <div
        style={{
          display: 'flex',
          alignSelf: 'flex-start',
          flexDirection: 'column',
          marginRight: 40,
          marginLeft: 40,
        }}
      >
        {getOptions()?.map((options) => {
          return <p>{options}</p>
        })}
      </div>
    )
    // return (
    //   <p style={{marginTop: 20}}>{getOptions()?.join(', ')}</p>
    // )
  }

  const renderTableRows = () => {
    const rows =
      selectedScreeners &&
      selectedScreeners.map((screener) => {
        const data = getScreeningData(screener.path)
        return (
          <div className={Styles.tableRow}>
            <p
              style={{
                width: '40%',
                textAlign: 'center',
              }}
            >
              {screener.label}
            </p>
            <p
              style={
                data?.length
                  ? {
                      width: '30%',
                      textAlign: 'center',
                      color: 'blue',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }
                  : {
                      width: '30%',
                      textAlign: 'center',
                    }
              }
              onClick={() => {
                onClickModal(data, screener)
              }}
            >
              {data?.length
                ? moment(data?.[0]?.created_at).format('MMM Do YYYY')
                : '-'}
            </p>
            {/* <p
              style={{
                width: '30%',
                textAlign: 'center',
                color: 'blue',
                textDecoration: 'underline',
              }}
            >
              Send
            </p> */}
          </div>
        )
      })

    return rows
  }

  const renderTableHeader = () => (
    <div className={Styles.tableRow}>
      <p
        style={{
          fontSize: 18,
          width: '40%',
          textAlign: 'center',
          fontWeight: 'bold',
          color: 'gray',
        }}
      >
        Screener Name
      </p>
      <p
        style={{
          fontSize: 18,
          width: '30%',
          textAlign: 'center',
          fontWeight: 'bold',
          color: 'gray',
        }}
      >
        Last Screening Date
      </p>
      {/* <p
        style={{
          fontSize: 18,
          width: '30%',
          textAlign: 'center',
          fontWeight: 'bold',
          color: 'gray',
        }}
      >
        Send Reminder
      </p> */}
    </div>
  )
  const renderTable = () => {
    if (!selectedScreeners || !selectedScreeners.length) {
      return null
    }

    return (
      <div className={Styles.tableWrapper}>
        {renderTableHeader()}
        {renderTableRows()}
      </div>
    )
  }

  const renderContent = () => (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          margin: 20,
        }}
      >
        <p style={{ marginRight: 20, fontWeight: 'bold' }}>
          Select Questionnaires
        </p>
        <div style={{ width: '90%' }}>
          <Select
            options={SCREENERS}
            value={selectedScreeners}
            isMulti
            isSearchable
            autoBlur
            onChange={setSelectedScreeners}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        </div>
      </div>
      {renderTable()}
      {renderQuestionModal()}
    </div>
  )

  return (
    <div style={{width: "100%"}}>
      {loading ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minWidth: '100%',
            height: '50vh',
            justifyContent: 'center',
          }}
        >
          <div className={GlobalStyles.loader} />
        </div>
      ) : (
        renderContent()
      )}
    </div>
  )
}

export default Screeners
