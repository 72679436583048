import React from 'react'
import Select from 'react-select'
import Styles from './styles/Columns.module.scss'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import Constants from '../../../values'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ContentEditable from 'react-contenteditable'

export const claimsColumns = ({
  providersList,
  patientList,
  generatePdf,
  generateNotesPdf,
  navigatetoPatientProfile,
  onPatientNavLinkClicked,
  saveAppointmentData,
  supervisor,
  openNotesEditModal,
  onInsuranceEditClicked,
  renderEditable,
  handleInputChange,
  getStripeChargeUrl,
  getStripeRefund,
  openStripeRefundModal,
  openStripeChargeModal
}) => {
  return [
    {
      Header: 'Patient/ Client Name',
      // accessor: 'patientName',
      accessor: (obj) => {
        let patientData = obj
        return patientData?.firstName + patientData?.lastName
      },
      Cell: ({ row }) => {
        let patientData = row?.original
        return (
          <Link
            onClick={() => {
              navigatetoPatientProfile(row?.original?.uid)
              onPatientNavLinkClicked('profile')
            }}
            to={{
              pathname: '/patient/profile',
              state: 'info',
              key: 2,
            }}
            className={Styles.link}
          >
            {patientData?.firstName || ''} {patientData?.lastName || ''}
          </Link>
        )
      },
      width: 300,
      sort: true,
      style: {
        position: 'sticky',
        left: '0px',
        zIndex: 10,
        backgroundColor: '#F3F3F3',
      },
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Last Date of Service',
      accessor: (obj) =>
        obj?.start
          ? moment(obj?.start).format('ll')
          : moment(obj.start).format('ll'),
      width: 200,
      sort: true,
      // style: {
      //   position: 'sticky',
      //   left: 300,
      //   zIndex: 10,
      //   backgroundColor: '#F3F3F3',
      // },
    },
    {
      id: 'Claim Type',
      Header: 'Claim Type',
      accessor: (obj) => {
        return obj?.timeSpent?.method_of_visit
          ? obj?.timeSpent?.method_of_visit
          : 'Telehealth - Video Visit'
      },

      width: 300,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%', fontWeight: 'normal' }}>
              <Select
                inputId="claimType"
                placeholder="Select"
                options={[
                  {
                    label: 'Telehealth - Video Visit',
                    value: 'Telehealth - Video Visit',
                  },
                  {
                    label: 'Telehealth - Phone Call',
                    value: 'Telehealth - Phone Call',
                  },
                  {
                    label: 'Telehealth - E-Consult',
                    value: 'Telehealth - E-Consult',
                  },
                  { label: 'Others', value: 'Others' },
                ]}
                value={
                  filterValue
                    ? { label: filterValue, value: filterValue }
                    : null
                }
                isSearchable
                autoBlur
                onChange={(val) => {
                  setFilter(val.label)
                  // filterByPatientType(val)
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </span>
        )
      },
    },
    {
      id: 'Cash Pay/ Insurance Pay',
      Header: 'Cash Pay/ Insurance Pay',
      accessor: (obj) => {
        let insurance = Array.isArray(obj?.insurances)
          ? obj?.insurances?.find((ins) => ins.preference === 1) || null
          : obj?.insurances || null
        return `${
          insurance?.insurance_type?.cashpay ? 'Cash Pay' : 'Insurance Pay'
        }`
      },
      width: 150,
      sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%', fontWeight: 'normal' }}>
              <Select
                inputId="claimType"
                placeholder="Select"
                options={[
                  { label: 'Cash Pay', value: 'Cash Pay' },
                  { label: 'Insurance Pay', value: 'Insurance Pay' },
                ]}
                value={
                  filterValue
                    ? { label: filterValue, value: filterValue }
                    : null
                }
                isSearchable
                autoBlur
                onChange={(val) => {
                  setFilter(val.label)
                  // filterByPatientType(val)
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Health Plan',
      accessor: (obj) => {
        let insurance = Array.isArray(obj?.insurances)
          ? obj?.insurances?.find((ins) => ins.preference === 1) || null
          : obj?.insurances || null
        return `${insurance?.insurance_company?.insurance_name || ''}`
      },
      Cell: ({ row }) => {
        let patientData = row?.original
        let insurance = Array.isArray(patientData?.insurances)
          ? patientData?.insurances?.find((ins) => ins.preference === 1) || null
          : patientData?.insurances || null
        return (
          <p
            onClick={() => {
              onInsuranceEditClicked(
                true,
                patientData?.insurances,
                patientData?.uid,
              )
            }}
            className={Styles.link}
          >
            {insurance?.insurance_company?.insurance_name || ''}
          </p>
        )
      },
      width: 250,
      sort: true,
    },
    {
      Header: 'Provider Name',
      accessor: (obj) => {
        let providerData = obj?.providerData || null
        return `${providerData.first_name} ${providerData.last_name}`
      },
      width: 300,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
      sort: true,
    },

    {
      Header: 'Submission Date',
      accessor: (obj) =>
        obj?.claim?.date_of_submission
          ? moment(obj?.claim?.date_of_submission).format('ll')
          : '',
      width: 200,
      sort: true,
    },
    {
      Header: 'Days from Submission Date',
      accessor: (obj) =>
        obj?.claim?.date_of_submission
          ? moment(obj?.claim?.date_of_submission).diff(moment(), 'days')
          : '-',
      width: 200,
      sort: true,
    },
    {
      Header: 'Cheque Number',
      accessor: 'chequeNumber',
      width: 200,
      // sort: true,
    },
    {
      Header: 'Status',
      accessor: (obj) => obj?.claim?.status,
      width: 200,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%', fontWeight: 'normal' }}>
              <Select
                inputId="Statustype"
                placeholder="Select"
                options={[
                  { label: 'Created', value: 'Created' },
                  { label: 'Sent', value: 'Sent' },
                  { label: 'Deductible Applies', value: 'Deductible Applies' },
                  {
                    label: 'Needs Investigation',
                    value: 'Needs Investigation',
                  },
                  { label: 'Partial Payment', value: 'Partial Payment' },
                  { label: 'Fully Paid', value: 'Fully Paid' },
                  { label: 'Denied', value: 'Denied' },
                ]}
                value={
                  filterValue
                    ? { label: filterValue, value: filterValue }
                    : null
                }
                isSearchable
                autoBlur
                onChange={(val) => {
                  setFilter(val.value)
                  // filterByPatientType(val)
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Billed Amount',
      accessor: (obj) => (obj?.billed_amount ? obj?.billed_amount : '-'),
      Cell: ({ row }) => {
        let billed_amount = row?.original?.billed_amount 
        ? '$' + row?.original?.billed_amount?.replace(/\D/g, "") 
        : '-'
        return (
          <ContentEditable
            html={
              billed_amount
            }
            onChange={(event) => handleInputChange(row, 'billed_amount', event)}
          />
        )
      },
      width: 200,
      sort: true,
    },
    {
      Header: 'Insurance Paid Amount',
      accessor: (obj) =>
        obj?.insurance_amount ? obj?.insurance_amount : '-',
      Cell: ({ row }) => {
        let insurance_amount = row?.original?.insurance_amount
          ? '$' + row?.original?.insurance_amount?.replace(/\D/g, "")
          : '-'
        return (
          <ContentEditable
            html={
              insurance_amount
            }
            onChange={(event) =>
              handleInputChange(row, 'insurance_amount', event)
            }
          />
        )
      },
      width: 200,
      sort: true,
    },
    {
      Header: 'Projected Collections',
      accessor: (obj) =>
        !obj?.appointment_payment_paid && obj?.visit_fee
          ? '' + obj?.visit_fee
          : obj?.free_appointment ? 'Included' : '-',
      Cell: ({ row }) => {
        return (
          <ContentEditable
            html={
              row?.original?.visit_fee ? '$' + row?.original?.visit_fee : row?.original?.free_appointment ? 'Included' : '-'
            }
            onChange={(event) => handleInputChange(row, 'visit_fee', event)}
          />
        )
      },
      width: 200,
      sort: true,
    },
    {
      Header: 'Patient Paid Amount(Stripe - fees included)',
      Cell: ({ row }) => {
        let invoice = row?.original?.invoice?.[0]
        return (
          <div
            // className={Styles.link}
          >
            {invoice?.amount_paid ? '$' + parseFloat(invoice?.amount_paid/100).toFixed(2) : '-'}
          </div>
        )
      },
      width: 200,
      sort: true,
    },
    {
      Header: 'Invoice ID',
      accessor: 'invoiceId',
      Cell: ({ row }) => {
        let invoice = row?.original?.invoice?.[0]
        return (
          <div
            onClick={() => {
              console.log('view invoice')
              invoice?.id && getStripeChargeUrl(invoice.charge)
            }}
            // to={{
            //   pathname: '/patient/profile',
            //   state: 'info',
            //   key: 2,
            // }}
            className={Styles.link}
          >
            {invoice?.id || 'N/A'}
          </div>
        )
      },
      width: 150,
      sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
      // obj.firstName && obj.lastName ? obj.firstName + ' ' + obj.lastName : '',
    },
    {
      Header: 'Percent Collected Overall - (Insurance + Patient Paid/Billed)',
      accessor: (obj) => {
        let invoice = obj?.invoice?.[0] || 0
        let invoice_amount = parseFloat(invoice?.amount_paid/100).toFixed(2)
        if(Number.isNaN(invoice_amount) || invoice_amount === 'NaN')
          invoice_amount = 0
        // console.log((obj?.insurance_amount?.replace(/\D/g, "")), (obj?.billed_amount|| obj?.visit_fee.replace(/\D/g, "")))
        return (
          obj?.insurance_amount && obj?.visit_fee && obj?.billed_amount
            ? (
                ((Number(obj?.insurance_amount?.replace(/\D/g, "") || 0) + Number(invoice_amount)).toFixed(2) / (obj?.billed_amount?.replace(/\D/g, "") || obj?.visit_fee.toString()?.replace(/\D/g, ""))).toFixed(2)
              ) * 100 + '%'
            : '-'
        )
      },
      width: 200,
      // sort: true,
    },
    {
      Header: 'Adjustment Amount',
      accessor: (obj) =>
        obj?.adjustment_amount ? obj?.adjustment_amount : '-',
      Cell: ({ row }) => {
        let adjustment_amount = row?.original?.adjustment_amount
        ? '$' + row?.original?.adjustment_amount?.replace(/\D/g, "")
        : '-'
        return (
          <ContentEditable
            html={
              adjustment_amount
            }
            onChange={(event) =>
              handleInputChange(row, 'adjustment_amount', event)
            }
          />
        )
      },
      width: 200,
      sort: true,
    },
    {
      Header: 'Allowed Amount',
      accessor: (obj) =>
        obj?.allowed_amount ? obj?.allowed_amount : '-',
      Cell: ({ row }) => {
        let allowed_amount = row?.original?.allowed_amount
        ? '$' + row?.original?.allowed_amount?.replace(/\D/g, "")
        : '-'
        return (
          <ContentEditable
            html={
              allowed_amount
            }
            onChange={(event) =>
              handleInputChange(row, 'allowed_amount', event)
            }
          />
        )
      },
      width: 200,
      sort: true,
    },
    {
      Header: 'Patient Amount Due (Allowed Amt - Insurance Paid Amt)',
      accessor: (obj) =>
        obj?.coinsurance_amount ? obj?.coinsurance_amount : '-',
      Cell: ({ row }) => {
        let due_amount = (
          (row?.original?.allowed_amount?.replace(/\D/g, "")|| 0) - (row?.original?.insurance_amount?.replace(/\D/g, "") || 0)
        )
        let coinsurance_amount = row?.original?.coinsurance_amount?.replace(/\D/g, "")
          ? '$' + row?.original?.coinsurance_amount?.replace(/\D/g, "")
          : ((due_amount > 0) ? '$' + due_amount.toString() : '-')

        return (
          <ContentEditable
            html={
              coinsurance_amount
            }
            onChange={(event) =>
              handleInputChange(row, 'coinsurance_amount', event)
            }
          />
        )
      },
      width: 200,
      sort: true,
    },
    {
      Header: 'Charge',
      accessor: 'charge',
      Cell: ({ row }) => {
        let patientData = row?.original?.patientData
        let data = row?.original
        let duration = moment(data.end).diff(moment(data.start), 'minutes')
        let isSpecialist = data?.providerData?.user_type !== 'Health Coach' && data?.providerData?.user_type !== 'Wellness Coach'
        return (
          <div
            onClick={() => {
              let due_amount = (
                (row?.original?.allowed_amount?.replace(/\D/g, "")|| 0) - (row?.original?.insurance_amount?.replace(/\D/g, "") || 0)
              )
              let patient_due = row?.original?.coinsurance_amount?.replace(/\D/g, "")
                ? '$' + row?.original?.coinsurance_amount?.replace(/\D/g, "")
                : ((due_amount > 0) ? '$' + due_amount.toString() : '-')

              if(patientData?.stripe?.customer_id) {
                openStripeChargeModal(
                  patientData?.stripe?.customer_id, 
                  isSpecialist,
                  data?.event_id,
                  duration,
                  data.doctor_id,
                  row?.original?.allowed_amount,
                  patient_due,
                  patientData?.firebase_id,
                  data?.start
                )
              } else {
                window.alert('Customer has not saved their CC details, please contact support')
              }
            }}
            // to={{
            //   pathname: '/patient/profile',
            //   state: 'info',
            //   key: 2,
            // }}
            className={Styles.link}
          >
            Charge
          </div>
        )
      },
      width: 150,
    },
    {
      Header: 'ICD-10',
      accessor: (obj) => {
        let icd_10_data = ''
        let tempArray = obj?.careplan?.careplan || []
        tempArray.forEach((icd, index) => {
          icd_10_data = icd_10_data.concat(
            icd?.icd_10?.icd10_code ? `${icd?.icd_10?.icd10_code}` : '',
            index < tempArray?.length - 1 && icd?.icd_10?.icd10_code
              ? `, `
              : '',
          )
        })
        return icd_10_data
      },
      width: 300,
      // sort: true,
    },
    {
      Header: 'Notes',
      accessor: (obj) => (obj?.careplan ? 'Notes' : 'Incomplete Notes'),
      Cell: ({ row }) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <p
              onClick={() => {
                console.log('view note', row.original)
                let careplanNote = row?.original?.careplan
                careplanNote.doctor = row.original?.providerData
                row?.original?.careplan &&
                  openNotesEditModal(careplanNote, row?.original)
              }}
              className={Styles.link}
              style={{
                textDecoration: 'underline',
                color: (!row?.original?.careplan || 
                  !(row?.original?.careplan?.note_status === 'completed' && row?.original?.careplan?.careplan?.some(icd => icd?.icd_10?.icd10_code) ) 
                ) && 'red',
              }}
            >
              {
              row?.original?.careplan 
                ? ((row?.original?.careplan?.note_status === 'completed' && row?.original?.careplan?.careplan?.some(icd => icd?.icd_10?.icd10_code) ) 
                    ? 'Notes'
                    : 'Incomplete'
                  ) 
                : 'No Notes Found'
              }
            </p>
            {row?.original?.careplan && (
              <FontAwesomeIcon
                onClick={() => {
                  console.log('view note', row.original)
                  row?.original?.careplan &&
                    generateNotesPdf(row?.original?.careplan, row?.original)
                }}
                icon={faFilePdf}
                style={{
                  color: Constants.primaryTheme,
                  fontSize: 20,
                  marginLeft: 10,
                  cursor: 'pointer',
                }}
              />
            )}
          </div>
        )
      },
      width: 200,
    },
    {
      Header: 'Medical Director Reviewed',
      accessor: (obj) => {
        return obj.reviewed || false
      },
      Cell: ({ row }) => {
        let reviewed = row?.original?.reviewed || false
        return (
          <div
            style={{
              margin: 'auto',
              display: 'flex',
              justifyContent: 'center',
              flex: 'auto',
            }}
          >
            <label style={{ color: '#20A892' }} class={Styles.checkContainer}>
              <input
                type="checkbox"
                checked={reviewed}
                disabled={!supervisor}
                onClick={() => {
                  // console.log('reviewed', obj)
                  saveAppointmentData(row?.original, {
                    updateReviewed: true,
                    reviewed: !reviewed,
                  })
                }}
              />
              <span class={Styles.checkmark}></span>
            </label>
          </div>
        )
      },
      width: 200,
    },

    {
      Header: 'Superbill',
      // accessor: 'Superbill',
      Cell: ({ row }) => {
        return (
          <p
            onClick={() => {
              console.log('view superbill', row.original)
              generatePdf(row.original)
            }}
            className={Styles.link}
            style={{ textDecoration: 'underline' }}
          >
            View
          </p>
        )
      },
      width: 150,
    },
    {
      Header: 'Refund',
      accessor: 'refund',
      Cell: ({ row }) => {
        let invoice = row?.original?.invoice?.[0]
        return (
          <div
            onClick={() => {
              if(!invoice?.refunded && invoice?.charge) {
                openStripeRefundModal(
                  invoice.charge, 
                  invoice?.transfer_data?.destination ? true : false,
                  parseFloat(invoice?.amount_paid/100).toFixed(2)
                )
              }
            }}
            // to={{
            //   pathname: '/patient/profile',
            //   state: 'info',
            //   key: 2,
            // }}
            className={(invoice?.refunded || !invoice?.charge) ? Styles.disabledCursor : Styles.link}
          >
            {invoice?.refunded ? 'Refunded $' + parseFloat(invoice?.refunds?.data?.[0]?.amount/100).toFixed(2) : invoice?.charge ? 'Refund' : 'N/A'}
          </div>
        )
      },
      width: 150,
    },
  ]
}
