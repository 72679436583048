import React, { Component, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import { geocodeByAddress } from 'react-google-places-autocomplete'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlusCircle,
  faStar,
  faCheckCircle,
  faExclamationCircle,
  faTrash,
  faEdit,
  faSearch,
  faArrowDown,
  faArrowUp,
  faSmileBeam,
} from '@fortawesome/free-solid-svg-icons'
import { ToastContainer, toast } from 'react-toastify'
import Modal from 'react-modal'
import Select from 'react-select'
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button'
import { loadStripe } from '@stripe/stripe-js'
import {
  addMongoUser,
  stopTimer,
  toggleSidebar,
  addFirebaseUser,
  fetchPatientCoreDate,
  addPatient,
} from '../../../redux/actions'
import firebase from '../../../services/firebase.js'
import PlaceHolderProfileImage from '../../../assets/images/placeholder.png'
import metadata, * as Metadata from '../../../metadata.js'

import GlobalStyles from '../../styles/global.module.scss'
import Header from '../../../components/Header'
import Constants from '../../../values.js'
import Styles from './styles/PatientAccount.module.scss'
import Config from '../../../config'
import { testModeAPI } from 'react-ga'

const moment = require('moment')
const axios = require('axios')
const windowHeight = window.innerHeight
const windowWidth = window.innerWidth

const medicModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '40%',
    height: '60%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
  },
}

const customModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '60%',
    height: '80%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
  },
}

const customPharmacyModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 10,
  },
  content: {
    zIndex: 10,
    width: '50%',
    height: '75%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const paymentModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '75%',
    height: '90%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 10,
    zIndex: 2,
  },
}
const TEXT_COLOR = '#7E7E7E'

const PatientInsurance = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const twilioToken = useSelector((state) => state.genericReducer?.twilioToken)
  const token = useSelector((state) => state.authReducer.token)
  //   const openNotesModal = useSelector(
  //     (state) => state.genericReducer?.openNotesModal,
  //   )
  const appointmentData = useSelector(
    (state) => state.appointmentReducer?.appointmentData,
  )

  const patient = useSelector((state) => state.patientReducer?.patient)
  const patientCore = useSelector((state) => state.patientReducer?.patientCore)

  const frontImageRef = React.createRef()
  const backImageRef = React.createRef()

  const frontImageRef1 = React.createRef()
  const backImageRef1 = React.createRef()

  const [loading, setLoading] = useState(false)
  const [drlistData, setDrListData] = useState([])
  const [twilloTokenLocal, setTwilloTokenLocal] = useState('')
  const [confirmModal, setConfirmModal] = useState(false)
  const [confirmCustomModal, setConfirmCustomModal] = useState(false)
  const [selectedDrName, setSelectedDrName] = useState('')
  const [accessToken, setAccessToken] = useState('')
  const [showWeb, setShowWeb] = useState(false)
  const [webViewUrl, setWebViewUrl] = useState('')
  const [doctorDetails, setDoctorDetails] = useState({})
  const [customModalDetails, setCustomModalDetails] = useState('')
  const [showScheduleModal, setShowScheduleModal] = useState(false)
  const [showDoctorModal, setShowDoctorModal] = useState(false)
  const [docDetails, setDocDetails] = useState({})
  const [userLocation, setUserLocation] = useState('')

  //INSURANCE IMAGE DATA
  //PRIMARY INSURANCE
  const [insuranceBack, setInsuranceBack] = useState(false)
  const [backLocation, setBackLocation] = useState('')
  const [backImage, setBackImage] = useState(null)
  const [backUploaded, setBackUploaded] = useState(false)
  const [frontLocation, setFrontLocation] = useState('')
  const [frontImage, setFrontImage] = useState(null)
  const [frontUploaded, setFrontUploaded] = useState(false)
  const [insuranceFront, setInsuranceFront] = useState(false)

  //SECONDARY INSURANCE
  const [insuranceBack1, setInsuranceBack1] = useState(false)
  const [backLocation1, setBackLocation1] = useState('')
  const [backImage1, setBackImage1] = useState(null)
  const [backUploaded1, setBackUploaded1] = useState(false)
  const [frontLocation1, setFrontLocation1] = useState('')
  const [frontImage1, setFrontImage1] = useState(null)
  const [frontUploaded1, setFrontUploaded1] = useState(false)
  const [insuranceFront1, setInsuranceFront1] = useState(false)

  //INSURANCE DATA PRIMARY SECONDARY
  const [showSearchList, setShowSearchList] = useState(false)
  const [activeInsurance, setActiveInsurance] = useState(1)
  const [primaryInsurance, setPrimaryInsurance] = useState(null)
  const [secondaryInsurance, setSecondaryInsurance] = useState(null)
  const [showSecondaryInsurance, setShowSecondaryInsurance] = useState(false)

  //SEARCH DATA
  const [searchName, setSearchName] = useState('')
  const [searchCity, setSearchCity] = useState('')
  const [searchAddress, setSearchAddress] = useState('')
  const [searchState, setSearchState] = useState('')
  const [searchList, setSearchList] = useState([])

  //ADD INSURANCE COMPANY NOT FOUND
  const [addInsurance, setAddInsurance] = useState(false)
  const [newInsurance, setNewInsurance] = useState(null)

  //MEDICARE AND MEDICAID DATA
  const [medicare, setMedicare] = useState(false)
  const [medicaid, setMedicaid] = useState(false)
  const [hmo, setHMO] = useState(false)
  const [mediAdv, setMediAdv] = useState(false)
  const [workersComp, setWorkersComp] = useState(false)
  const [referrals, setReferrals] = useState(null)
  const [showMedicModal, setShowMedicModal] = useState(false)

  //PHARMACY DATA
  const [modalLoading, setModalLoading] = useState(false)
  const [pharmacyModalOpen, setPharmacyModalOpen] = useState(false)
  const [pharmacyList, setPharmacyList] = useState([])
  const [pharmacySearchString, setPharmacySearchString] = useState('')
  const [selectedCity, setSelectedCity] = useState('')
  const [pharmacyType, setPharmacyType] = useState(true)

  //TERMS CHECKED
  const [termsChecked, setTermsChecked] = useState({
    messages: true,
    policy: true,
    medical_release: false,
    payments: false,
  })

  useEffect(() => {
    setLoading(true)

    const patientData = { ...patient, ...patientCore }
    setMedicaid(patientData?.medicaid)
    setMedicare(patientData?.medicare)
    setHMO(patientData?.insurance_type?.hmo)
    setMediAdv(patientData?.insurance_type?.medi_adv)
    setWorkersComp(patientData?.insurance_type?.workers_comp)
    setTermsChecked(
      patientData.termsChecked || {
        messages: true,
        policy: true,
        medical_release: false,
        payments: false,
      },
    )
    setReferrals(
      patientData?.referrals || {
        required: false,
        received: false,
      },
    )

    let primary_insurance = {
      _id: props?.primaryInsurance?._id || null,
      subscriber_id: props?.primaryInsurance?.subscriber_id || '', // it is the policy number/member ID; change UI to show Member ID
      group_number: props?.primaryInsurance?.group_number || '',
      plan_type: props?.primaryInsurance?.plan_type || '',
      member_name: props?.primaryInsurance?.member_name || '',
      front_image_url: props?.primaryInsurance?.front_image_url || null,
      back_image_url: props?.primaryInsurance?.back_image_url || null,
      insurance_company: {
        insurance_isp:
          props?.primaryInsurance?.insurance_company?.insurance_isp || '', // returned if selected insurance from the search result
        insurance_address_1:
          props?.primaryInsurance?.insurance_company?.insurance_address_1 || '', // enter only if it is not present in the search list
        insurance_city:
          props?.primaryInsurance?.insurance_company?.insurance_city || '',
        insurance_state:
          props?.primaryInsurance?.insurance_company?.insurance_state || '',
        insurance_zip:
          props?.primaryInsurance?.insurance_company?.insurance_zip || '',
        insurance_name:
          props?.primaryInsurance?.insurance_company?.insurance_name || '',
      },
      insured: {
        insured_address_1:
          props?.primaryInsurance?.insured?.insured_address_1 || '',
        insured_city: props?.primaryInsurance?.insured?.insured_city || '',
        insured_state: props?.primaryInsurance?.insured?.insured_state || null,
        insured_zip: props?.primaryInsurance?.insured?.insured_zip || '',
        insured_first_name:
          props?.primaryInsurance?.insured?.insured_first_name || '',
        insured_last_name:
          props?.primaryInsurance?.insured?.insured_last_name || '',
        insured_sex: props?.primaryInsurance?.insured?.insured_sex || null,
        insured_relationship:
          props?.primaryInsurance?.insured?.insured_relationship || null,
        insured_home_phone:
          props?.primaryInsurance?.insured?.insured_home_phone || '',
      },
      preference: 1,
      medicaid: patientData?.medicaid || false,
      medicare: patientData?.medicare || false,
    }

    let secondary_insurance = {
      _id: props?.secondaryInsurance?._id || null,
      subscriber_id: props?.secondaryInsurance?.subscriber_id || '', // it is the policy number/member ID; change UI to show Member ID
      group_number: props?.secondaryInsurance?.group_number || '',
      plan_type: props?.secondaryInsurance?.plan_type || '',
      member_name: props?.secondaryInsurance?.member_name || '',
      front_image_url: props?.secondaryInsurance?.front_image_url || null,
      back_image_url: props?.secondaryInsurance?.back_image_url || null,
      insurance_company: {
        insurance_isp:
          props?.secondaryInsurance?.insurance_company?.insurance_isp || '', // returned if selected insurance from the search result
        insurance_address_1:
          props?.secondaryInsurance?.insurance_company?.insurance_address_1 ||
          '', // enter only if it is not present in the search list
        insurance_city:
          props?.secondaryInsurance?.insurance_company?.insurance_city || '',
        insurance_state:
          props?.secondaryInsurance?.insurance_company?.insurance_state || '',
        insurance_zip:
          props?.secondaryInsurance?.insurance_company?.insurance_zip || '',
        insurance_name:
          props?.secondaryInsurance?.insurance_company?.insurance_name || '',
      },
      insured: {
        insured_address_1:
          props?.secondaryInsurance?.insured?.insured_address_1 || '',
        insured_city: props?.secondaryInsurance?.insured?.insured_city || '',
        insured_state:
          props?.secondaryInsurance?.insured?.insured_state || null,
        insured_zip: props?.secondaryInsurance?.insured?.insured_zip || '',
        insured_first_name:
          props?.secondaryInsurance?.insured?.insured_first_name || '',
        insured_last_name:
          props?.secondaryInsurance?.insured?.insured_last_name || '',
        insured_sex: props?.secondaryInsurance?.insured?.insured_sex || null,
        insured_relationship:
          props?.secondaryInsurance?.insured?.insured_relationship || null,
        insured_home_phone:
          props?.secondaryInsurance?.insured?.insured_home_phone || '',
      },
      preference: 2,
    }

    let new_insurance = {
      insurance_isp: '', // returned if selected insurance from the search result
      insurance_address_1: '', // enter only if it is not present in the search list
      insurance_city: '',
      insurance_state: '',
      insurance_zip: '',
      insurance_name: '',
    }

    props?.secondaryInsurance?._id && setShowSecondaryInsurance(true)

    // setPrimaryInsurance(primary_insurance)
    // setSecondaryInsurance(secondary_insurance)

    getInsuranceData()
    setNewInsurance(new_insurance)
    setLoading(false)
  }, [])

  const getInsuranceData = () => {
    setLoading(true)
    var config = {
      method: 'get',
      url: `${Config.BACKEND_URL}patients/insurances`,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: props?.patientId ? props?.patientId : patient?.uid,
      },
    }

    axios(config)
      .then(({ data }) => {
        console.log('got insurance data')
        if (data) {
          const patientData = { ...patient, ...patientCore }
          setMedicaid(patientData?.medicaid)
          setMedicare(patientData?.medicare)
          setReferrals(
            patientData?.referrals || {
              required: false,
              received: false,
            },
          )

          let primary_insurance = Array.isArray(data)
            ? data?.find((ins) => ins.preference === 1) || null
            : data || null

          let secondary_insurance = Array.isArray(data)
            ? data?.find((ins) => ins.preference === 2)
            : {}

          let pmIns = {
            _id: primary_insurance?._id || null,
            subscriber_id: primary_insurance?.subscriber_id || '', // it is the policy number/member ID; change UI to show Member ID
            group_number: primary_insurance?.group_number || '',
            auth_number: primary_insurance?.auth_number || '',
            plan_type: primary_insurance?.plan_type || '',
            member_name: primary_insurance?.member_name || '',
            front_image_url: primary_insurance?.front_image_url || null,
            back_image_url: primary_insurance?.back_image_url || null,
            insurance_company: {
              insurance_isp:
                primary_insurance?.insurance_company?.insurance_isp || '', // returned if selected insurance from the search result
              insurance_address_1:
                primary_insurance?.insurance_company?.insurance_address_1 || '', // enter only if it is not present in the search list
              insurance_city:
                primary_insurance?.insurance_company?.insurance_city || '',
              insurance_state:
                primary_insurance?.insurance_company?.insurance_state || '',
              insurance_zip:
                primary_insurance?.insurance_company?.insurance_zip || '',
              insurance_name:
                primary_insurance?.insurance_company?.insurance_name || '',
            },
            insured: {
              insured_address_1:
                primary_insurance?.insured?.insured_address_1 || '',
              insured_city: primary_insurance?.insured?.insured_city || '',
              insured_state: primary_insurance?.insured?.insured_state || null,
              insured_zip: primary_insurance?.insured?.insured_zip || '',
              insured_first_name:
                primary_insurance?.insured?.insured_first_name || '',
              insured_last_name:
                primary_insurance?.insured?.insured_last_name || '',
              insured_sex: primary_insurance?.insured?.insured_sex || null,
              insured_relationship:
                primary_insurance?.insured?.insured_relationship || null,
              insured_home_phone:
                primary_insurance?.insured?.insured_home_phone || '',
            },
            preference: 1,
            medicaid: patientData?.medicaid || false,
            medicare: patientData?.medicare || false,
            medi_adv: patientData?.medi_adv || false,
            hmo: patientData?.hmo || false,
            workers_comp: patientData?.workers_comp || false,
            benefits: {
              category: primary_insurance?.benefits?.category || '',
              copay: primary_insurance?.benefits?.copay || '',
              coinsurance: primary_insurance?.benefits?.coinsurance || '',
              deductible_calendar:
                primary_insurance?.benefits?.deductible_calendar || '',
              deductible_date:
                primary_insurance?.benefits?.deductible_date || '',

              telemedicine: primary_insurance?.benefits?.telemedicine || false,
              pbm: primary_insurance?.benefits?.pbm || '',
              state: primary_insurance?.benefits?.state || null,
              rxbin: primary_insurance?.benefits?.rxbin || '',
              rxpcn: primary_insurance?.benefits?.rxpcn || '',
              rxgroup: primary_insurance?.benefits?.rxgroup || '',
            },
          }

          let scIns = {
            _id: secondary_insurance?._id || null,
            subscriber_id: secondary_insurance?.subscriber_id || '', // it is the policy number/member ID; change UI to show Member ID
            group_number: secondary_insurance?.group_number || '',
            plan_type: secondary_insurance?.plan_type || '',
            member_name: secondary_insurance?.member_name || '',
            front_image_url: secondary_insurance?.front_image_url || null,
            back_image_url: secondary_insurance?.back_image_url || null,
            insurance_company: {
              insurance_isp:
                secondary_insurance?.insurance_company?.insurance_isp || '', // returned if selected insurance from the search result
              insurance_address_1:
                secondary_insurance?.insurance_company?.insurance_address_1 ||
                '', // enter only if it is not present in the search list
              insurance_city:
                secondary_insurance?.insurance_company?.insurance_city || '',
              insurance_state:
                secondary_insurance?.insurance_company?.insurance_state || '',
              insurance_zip:
                secondary_insurance?.insurance_company?.insurance_zip || '',
              insurance_name:
                secondary_insurance?.insurance_company?.insurance_name || '',
            },
            insured: {
              insured_address_1:
                secondary_insurance?.insured?.insured_address_1 || '',
              insured_city: secondary_insurance?.insured?.insured_city || '',
              insured_state:
                secondary_insurance?.insured?.insured_state || null,
              insured_zip: secondary_insurance?.insured?.insured_zip || '',
              insured_first_name:
                secondary_insurance?.insured?.insured_first_name || '',
              insured_last_name:
                secondary_insurance?.insured?.insured_last_name || '',
              insured_sex: secondary_insurance?.insured?.insured_sex || null,
              insured_relationship:
                secondary_insurance?.insured?.insured_relationship || null,
              insured_home_phone:
                secondary_insurance?.insured?.insured_home_phone || '',
            },
            preference: 2,
          }

          secondaryInsurance?._id && setShowSecondaryInsurance(true)
          setPrimaryInsurance(pmIns)
          setSecondaryInsurance(scIns)
        }
        setLoading(false)
      })
      .catch((err) => {
        console.log('error when getting insurance information', err)
      })
  }

  const updateUserInsurance = (insurance, insuranceData) => {
    let userInsurance =
      insurance === 1 && insuranceData
        ? insuranceData
        : insurance === 1
        ? { ...primaryInsurance }
        : insurance === 2 && insuranceData
        ? insuranceData
        : { ...secondaryInsurance }

    insurance === 1 && insuranceData && setPrimaryInsurance(insuranceData)
    insurance === 2 && insuranceData && setSecondaryInsurance(insuranceData)

    if (!userInsurance._id) {
      //need to send firebase ID the first time
      userInsurance.firebase_id = props?.patientId ? props?.patientId : patient?.uid
    } else {
      delete userInsurance.firebase_id
    }
    console.log('ID: ', userInsurance)
    let method = userInsurance._id ? 'put' : 'post'
    let url = `${Config.BACKEND_URL}patients/insurances${
      userInsurance._id ? `/${userInsurance._id}` : ''
    }`

    const mongoData = {
      medicaid: medicaid || false,
      medicare: medicare || false,
      insurance_type: {
        hmo: hmo,
        medi_adv: mediAdv,
        workers_comp: workersComp,
      },
      medicare: medicare || false,
      referrals: {
        received: referrals?.received || false,
        required: referrals?.required || false,
      },
    }

    if (!props?.fromProvider && (medicaid || medicare)) {
      setShowMedicModal(true)
    }

    const mongo_url = `${Config.BACKEND_URL}users`

    axios({
      method: 'put',
      url: mongo_url,
      headers: { Authorization: `JWT ${token}`, x_firebase_id: props?.patientId ? props?.patientId : patient?.uid },
      data: mongoData,
    })
      .then(({ data }) => {
        console.log('successfully added user details to mongo backend')
        // self.props.addMongoUser(user)
        // toast.success('Successfully updated profile')

        dispatch(
          fetchPatientCoreDate(
            props?.patientId ? props?.patientId : patient?.uid,
            token,
          ),
        )

        // if (self.state.imageUploaded) toast.success('Updated profile picture')
        // self.setState({ loading: false, saving: false })

        // , imageUploaded: false })
      })
      .catch((err) => {
        console.log('error when adding doctor data to backend', err)
      })

    // console.log('Insurance update data::', userInsurance, url)

    delete userInsurance._id

    var config = {
      method: method,
      url: url,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: props?.patientId ? props?.patientId : patient?.uid,
      },
      data: userInsurance,
    }
    // console.log(config)

    axios(config)
      .then(function (response) {
        console.log('saved insurance information in the backend')
        // getPatientInsurance(insurance)
        setLoading(false)
        // toast.success('Successfully updated insurance')
        toast.success(
          insurance === 1
            ? `Primary Insurance updated successfully`
            : `Secondary Insurance updated successfully`,
        )
        dispatch(
          fetchPatientCoreDate(
            props?.patientId ? props?.patientId : patient?.uid,
            token,
          ),
        )
        getInsuranceData()
        // props.parentCallback(true)
      })
      .catch(function (error) {
        console.log('error when saving info to backend', error)
        // toast.warning('Could not update Insurance')
      })
  }

  const onSearch = async () => {
    if (searchName.length === 0 || !searchState) {
      console.log('Insurance : No NAME and STATE search')
      toast.warning('Please specify Name and State of Insurance')
      return
    }
    // setLoading(true)
    try {
      let url = Config.CHANGE_BACKEND_URL
      var config = {
        method: 'get',
        url: `${url}change/search-insurance?city=${searchCity}&address_1=${searchAddress}&name=${searchName}&state=${searchState?.abbreviation}`,
        headers: {
          Authorization: `JWT ${token}`,
          x_firebase_id: props?.patientId ? props?.patientId : patient?.uid,
        },
      }

      axios(config)
        .then(({ data }) => {
          if (data?.RESULT?.hasOwnProperty('OBJECT')) {
            Array.isArray(data?.RESULT?.OBJECT)
              ? setSearchList(data.RESULT.OBJECT)
              : setSearchList([data.RESULT.OBJECT])
          } else if (data?.hasOwnProperty('ERROR')) {
            toast.warning(
              'The search criteria you entered found too many matching records. Make your search criteria more specific.',
            )
          } else {
            setAddInsurance(true)
          }
        })
        .catch(function (error) {
          // setLoading(false)
          // setPharmacyList([])
          toast.error('Could not fetch Insurances, please try again later')
          console.log('error - search insurance', error)
        })
    } catch (error) {
      console.log('error - search insurance', error)
      toast.error('Could not fetch Insurances, please try again later')
    }
  }

  const getAddressDetails = (patientData) => {
    let { addressComponents, zipcode } = patientData
    let state = null
    let address_1 = null
    let city = null
    let zip = null
    if (addressComponents && addressComponents.length > 0) {
      addressComponents.forEach((addressComponent) => {
        let types = addressComponent.types || []
        if (types.includes('street_number'))
          address_1 = addressComponent.long_name
        if (types.includes('route'))
          address_1 = address_1
            ? address_1 + addressComponent.long_name
            : addressComponent.long_name

        if (types.includes('administrative_area_level_1'))
          state = addressComponent.short_name
        if (types.includes('locality')) city = addressComponent.long_name
        if (types.includes('postal_code')) {
          zip = addressComponent.long_name
        } else {
          zip = zipcode
        }
      })
    }

    return { address_1, city, state, zip }
  }

  const populateSelfData = (insurance) => {
    const patientData = { ...patient, ...patientCore }

    switch (insurance) {
      case 1: {
        let pinsurance = { ...primaryInsurance }
        pinsurance.insured.insured_first_name = patientData?.firstName
        pinsurance.insured.insured_last_name = patientData?.lastName
        pinsurance.insured.insured_sex = patientData?.gender
        pinsurance.insured.insured_home_phone = patientData?.phoneNumber
        //ADDRESS DETAILS
        let address = getAddressDetails(patientData)
        pinsurance.insured.insured_address_1 = address.address_1
        pinsurance.insured.insured_city = address.city
        pinsurance.insured.insured_state = address.state
        pinsurance.insured.insured_zip = address.zip
        setPrimaryInsurance(pinsurance)
        break
      }

      case 2: {
        let pinsurance = { ...secondaryInsurance }
        pinsurance.insured.insured_first_name = patientData?.firstName
        pinsurance.insured.insured_last_name = patientData?.lastName
        pinsurance.insured.insured_sex = patientData?.gender
        pinsurance.insured.insured_home_phone = patientData?.phoneNumber
        //ADDRESS DETAILS
        let address = getAddressDetails(patientData)
        pinsurance.insured.insured_address_1 = address.address_1
        pinsurance.insured.insured_city = address.city
        pinsurance.insured.insured_state = address.state
        pinsurance.insured.insured_zip = address.zip
        setSecondaryInsurance(pinsurance)
        break
      }

      default:
        break
    }
  }

  const onPharmacySearch = async () => {
    setModalLoading(true)
    if (pharmacySearchString.length === 0) {
      toast.error('Please enter pharmacy name or zipcode')
      setModalLoading(false)
      return
    }

    let config = {
      method: 'get',
      url: `${Config.CHANGE_BACKEND_URL}change/pharmacies/search/zip`,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: props?.patientId ? props?.patientId : patient?.uid,
      },
    }
    // if entered only zip code (ie, string contains only digits)
    if (/^\d+$/.test(pharmacySearchString)) {
      config.url = `${Config.CHANGE_BACKEND_URL}change/pharmacies/search/zip`
      config.headers.zip = pharmacySearchString
    } else {
      if (selectedCity.length === 0) {
        toast.error('Please enter city name')
        setModalLoading(false)
        return
      }
      config.url = `${Config.CHANGE_BACKEND_URL}change/pharmacies/search/name`
      config.headers.name = pharmacySearchString
      config.headers.city = selectedCity
    }

    axios(config)
      .then(({ data }) => {
        console.log('got results', data)
        if (data) {
          setPharmacyList(data || [])
          setModalLoading(false)
        }
      })
      .catch(function (error) {
        setModalLoading(false)
        setPharmacyList([])
        toast.error(
          'Error occured while fetching the pharmacy details, please contact support',
        )
        console.log('error when searching pharmacy', error)
      })
  }

  const onPharmacySelected = (pharmacyObj) => {
    let data = pharmacyType
      ? { 'change.pharmacy': pharmacyObj }
      : { 'change.specialty_pharmacy': pharmacyObj }
    let config = {
      method: 'put',
      url: `${Config.BACKEND_URL}users`,
      headers: {
        Authorization: 'JWT ' + token,
        x_firebase_id: props?.patientId ? props?.patientId : patient?.uid,
      },
      data: data,
    }

    axios(config)
      .then(() => {
        console.log('saved pharmacy information in the backend')
        toast.success('Patient pharmacy data has be saved')
        setPharmacyList([])
        setPharmacySearchString('')
        setPharmacyModalOpen(false)
        dispatch(fetchPatientCoreDate(props?.patientId ? props?.patientId : patient?.uid, token))
        getInsuranceData()
      })
      .catch((error) => {
        setLoading(false)
        console.log('error when saving info to backend', error)
        toast.error('Error while saving pharmacy info')
      })
  }

  const renderPharmacyList = () => {
    if (
      !pharmacySearchString ||
      pharmacySearchString.length === 0 ||
      pharmacyList.length === 0
    ) {
      return
    }

    if (
      !pharmacyList ||
      (pharmacyList.length !== 0 && pharmacyList[0] === null)
    ) {
      return (
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <p>no results</p>
        </div>
      )
    }

    let elements = pharmacyList.map((pharmacy) => (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          borderBottom: '1px solid #d0d0d0',
          padding: '8px 2px',
          cursor: 'pointer',
        }}
        onClick={() => onPharmacySelected(pharmacy)}
      >
        <p style={{ width: '50%', textAlign: 'center' }}>
          {pharmacy?.name?.[1]}
        </p>
        <p style={{ width: '50%', textAlign: 'center' }}>
          {pharmacy?.address_1}, {pharmacy?.city}
        </p>
      </div>
    ))

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          overflowY: 'scroll',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            borderBottom: '1px solid #d0d0d0',
            padding: 10,
          }}
        >
          <p
            style={{
              width: '50%',
              textAlign: 'center',
              fontWeight: 'bold',
              color: 'gray',
            }}
          >
            Name
          </p>
          <p
            style={{
              width: '50%',
              textAlign: 'center',
              fontWeight: 'bold',
              color: 'gray',
            }}
          >
            Address
          </p>
        </div>

        {elements}
      </div>
    )
  }

  const renderPharmacySearchModal = () => {
    return (
      <Modal
        ariaHideApp={false}
        isOpen={pharmacyModalOpen}
        onRequestClose={() => setPharmacyModalOpen(false)}
        style={customModalStyles}
        contentLabel="Modal"
      >
        {modalLoading ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              minHeight: '100%',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        ) : (
          <div
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: 10,
            }}
          >
            <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
              Search Pharmacy
            </h4>
            <p
              className={Styles.closeModalBtn}
              onClick={() => setPharmacyModalOpen(false)}
            >
              X
            </p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                padding: '0px 10px',
                width: '80%',
                justifyContent: 'space-evenly',
              }}
            >
              <input
                placeholder={'Enter pharmacy or zipcode'}
                className={Styles.textInput}
                style={{ width: '40%' }}
                type="textInput"
                value={pharmacySearchString}
                onChange={(e) => setPharmacySearchString(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    onPharmacySearch()
                  }
                }}
              />
              <input
                placeholder={'Enter city name'}
                className={Styles.textInput}
                style={{ width: '40%' }}
                type="textInput"
                value={selectedCity}
                onChange={(e) => setSelectedCity(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    onPharmacySearch()
                  }
                }}
              />
              <Button
                onClick={onPharmacySearch}
                className={GlobalStyles.button}
                style={{ marginLeft: 5, height: 40 }}
                variant="primary"
              >
                Search
              </Button>
              {/* <div style={{ width: '50%', marginLeft: 10 }}>          
         
              </div> */}
            </div>

            {renderPharmacyList()}
          </div>
        )}
      </Modal>
    )
  }
  const renderPharmaName = (preferred) => {
    const patientData = { ...patient, ...patientCore }
    const patientPharmacy = preferred
      ? patientData?.change?.pharmacy
      : patientData?.change?.specialty_pharmacy
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          // padding: '1% 5%',
        }}
      >
        {renderPharmacySearchModal()}
        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Name:</p>
          <div
            style={{
              width: '50%',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <input
              placeholder=""
              className={Styles.textInput}
              style={{ width: 'auto' }}
              type="text"
              disabled={true}
              value={
                patientPharmacy ? patientPharmacy?.name?.[1] : 'Search Pharmacy'
              }
            />
            <FontAwesomeIcon
              onClick={() => {
                setPharmacyType(preferred)
                setPharmacyModalOpen(true)
                console.log('search pharmacy')
              }}
              icon={faSearch}
              style={{
                color: Constants.primaryTheme,
                fontSize: 20,
                marginLeft: 10,
                cursor: 'pointer',
              }}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Address</p>
          <div style={{ width: '50%' }}>
            <input
              placeholder="Address"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              disabled={true}
              value={patientPharmacy ? patientPharmacy?.address_1 : ''}
            />
          </div>
        </div>
        <div className={Styles.row}>
          <p className={Styles.rowLabel}>State</p>
          <div style={{ width: '50%' }}>
            <input
              placeholder="State"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              disabled={true}
              value={patientPharmacy ? patientPharmacy?.state : ''}
            />
          </div>
        </div>
        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Zip</p>
          <div style={{ width: '50%' }}>
            <input
              placeholder="Zip"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              disabled={true}
              value={patientPharmacy ? patientPharmacy?.zip : ''}
            />
          </div>
        </div>
        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Phone</p>
          <div style={{ width: '50%' }}>
            <input
              placeholder="Phone"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              disabled={true}
              value={patientPharmacy ? patientPharmacy?.phone : ''}
            />
          </div>
        </div>
        {/* <div className={Styles.row}>
          <p className={Styles.rowLabel}>Fax</p>
          <div style={{ width: '50%' }}>
            <input
              placeholder="Fax"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              disabled={true}
              value={
                patientPharmacy
                  ? patientPharmacy?.phone
                  : // +
                    //   `, ${patientPharmacy?.address_1}` +
                    //   `, ${patientPharmacy?.city}`
                    ''
              }
            />
          </div>
        </div> */}
      </div>
    )
  }

  const renderLoader = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '100%',
        height: 100, // do not change this
      }}
    >
      <div className={GlobalStyles.loader} />
    </div>
  )

  const getRandomString = (len) => {
    var result = []
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    var charactersLength = characters.length
    for (let i = 0; i < len; i++) {
      result.push(
        characters.charAt(Math.floor(Math.random() * charactersLength)),
      )
    }
    return result.join('')
  }

  const saveImageToFirebase = (insurance) => {
    if (frontUploaded && backUploaded) {
      let pinsurance = { ...primaryInsurance }
      const file = frontImage

      const storageRef = firebase.storage().ref()
      const fileExtension = frontImage.name.split('.').pop()

      const uploadTask = storageRef
        .child(
          `user/insurances/${props?.patientId ? props?.patientId : patient?.uid}/${
            frontUploaded
              ? `front-${getRandomString(10)}`
              : `back-${getRandomString(10)}`
          }.${fileExtension}`,
        )
        .put(file)
      uploadTask.on(
        'state_changed',
        function (snapshot) {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log(`${progress}%`)
        },
        function (err) {
          // Handle unsuccessful uploads
          console.log('error when uploading image', err)
          // self.saveUserToBackend(user)
        },
        function () {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            // let pinsurance = { ...primaryInsurance }
            pinsurance.front_image_url = downloadURL
            setPrimaryInsurance(pinsurance)
            // console.log('PRIMARY FRONT:', downloadURL)

            const file = backImage
            const storageRef = firebase.storage().ref()
            const fileExtension = backImage.name.split('.').pop()

            const uploadTask = storageRef
              .child(
                `user/insurances/${props?.patientId ? props?.patientId : patient?.uid}/${
                  frontUploaded
                    ? `front-${getRandomString(10)}`
                    : `back-${getRandomString(10)}`
                }.${fileExtension}`,
              )
              .put(file)
            uploadTask.on(
              'state_changed',
              function (snapshot) {
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                console.log(`${progress}%`)
              },
              function (err) {
                // Handle unsuccessful uploads
                console.log('error when uploading image', err)
                // self.saveUserToBackend(user)
              },
              function () {
                uploadTask.snapshot.ref
                  .getDownloadURL()
                  .then(function (downloadURL) {
                    // let pinsurance = { ...primaryInsurance }
                    pinsurance.back_image_url = downloadURL
                    setPrimaryInsurance(pinsurance)
                    updateUserInsurance(1, pinsurance)
                    // console.log('PRIMARY BACK:', downloadURL)
                  })
              },
            )
            // updateUserInsurance(1, pinsurance)
          })
        },
      )
    } else if (frontUploaded) {
      const file = frontImage

      const storageRef = firebase.storage().ref()
      const fileExtension = frontImage.name.split('.').pop()

      const uploadTask = storageRef
        .child(
          `user/insurances/${props?.patientId ? props?.patientId : patient?.uid}/${
            frontUploaded
              ? `front-${getRandomString(10)}`
              : `back-${getRandomString(10)}`
          }.${fileExtension}`,
        )
        .put(file)
      uploadTask.on(
        'state_changed',
        function (snapshot) {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log(`${progress}%`)
        },
        function (err) {
          // Handle unsuccessful uploads
          console.log('error when uploading image', err)
          // self.saveUserToBackend(user)
        },
        function () {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            let pinsurance = { ...primaryInsurance }
            pinsurance.front_image_url = downloadURL
            setPrimaryInsurance(pinsurance)
            updateUserInsurance(1, pinsurance)
          })
        },
      )
    } else if (backUploaded) {
      const file = backImage
      const storageRef = firebase.storage().ref()
      const fileExtension = backImage.name.split('.').pop()

      const uploadTask = storageRef
        .child(
          `user/insurances/${props?.patientId ? props?.patientId : patient?.uid}/${
            frontUploaded
              ? `front-${getRandomString(10)}`
              : `back-${getRandomString(10)}`
          }.${fileExtension}`,
        )
        .put(file)
      uploadTask.on(
        'state_changed',
        function (snapshot) {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log(`${progress}%`)
        },
        function (err) {
          // Handle unsuccessful uploads
          console.log('error when uploading image', err)
          // self.saveUserToBackend(user)
        },
        function () {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            let pinsurance = { ...primaryInsurance }
            pinsurance.back_image_url = downloadURL
            setPrimaryInsurance(pinsurance)
            updateUserInsurance(1, pinsurance)
          })
        },
      )
    }

    //SECONDARY IMAGE

    if (frontUploaded1 && backUploaded1) {
      let pinsurance = { ...secondaryInsurance }

      const file = frontImage1

      const storageRef = firebase.storage().ref()
      const fileExtension = frontImage1.name.split('.').pop()

      const uploadTask = storageRef
        .child(
          `user/insurances/${props?.patientId ? props?.patientId : patient?.uid}/${
            frontUploaded1
              ? `front-${getRandomString(10)}`
              : `back-${getRandomString(10)}`
          }.${fileExtension}`,
        )
        .put(file)
      uploadTask.on(
        'state_changed',
        function (snapshot) {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log(`${progress}%`)
        },
        function (err) {
          // Handle unsuccessful uploads
          console.log('error when uploading image', err)
          // self.saveUserToBackend(user)
        },
        function () {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            // let pinsurance = { ...secondaryInsurance }
            pinsurance.front_image_url = downloadURL
            setSecondaryInsurance(pinsurance)
            // console.log('SECONDARY FRONT:', downloadURL)

            const file = backImage1
            const storageRef = firebase.storage().ref()
            const fileExtension = backImage1.name.split('.').pop()

            const uploadTask = storageRef
              .child(
                `user/insurances/${props?.patientId ? props?.patientId : patient?.uid}/${
                  frontUploaded1
                    ? `front-${getRandomString(10)}`
                    : `back-${getRandomString(10)}`
                }.${fileExtension}`,
              )
              .put(file)
            uploadTask.on(
              'state_changed',
              function (snapshot) {
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                console.log(`${progress}%`)
              },
              function (err) {
                // Handle unsuccessful uploads
                console.log('error when uploading image', err)
                // self.saveUserToBackend(user)
              },
              function () {
                uploadTask.snapshot.ref
                  .getDownloadURL()
                  .then(function (downloadURL) {
                    // let pinsurance = { ...secondaryInsurance }
                    pinsurance.back_image_url = downloadURL
                    setSecondaryInsurance(pinsurance)
                    updateUserInsurance(2, pinsurance)
                    // console.log('SECONDARY BACK:', downloadURL)
                  })
              },
            )
            // updateUserInsurance(2, pinsurance)
          })
        },
      )
    } else if (frontUploaded1) {
      const file = frontImage1

      const storageRef = firebase.storage().ref()
      const fileExtension = frontImage1.name.split('.').pop()

      const uploadTask = storageRef
        .child(
          `user/insurances/${props?.patientId ? props?.patientId : patient?.uid}/${
            frontUploaded1
              ? `front-${getRandomString(10)}`
              : `back-${getRandomString(10)}`
          }.${fileExtension}`,
        )
        .put(file)
      uploadTask.on(
        'state_changed',
        function (snapshot) {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log(`${progress}%`)
        },
        function (err) {
          // Handle unsuccessful uploads
          console.log('error when uploading image', err)
          // self.saveUserToBackend(user)
        },
        function () {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            let pinsurance = { ...secondaryInsurance }
            pinsurance.front_image_url = downloadURL
            setSecondaryInsurance(pinsurance)
            updateUserInsurance(2, pinsurance)
            // console.log('SECONDARY FRONT:', downloadURL)
          })
        },
      )
    } else if (backUploaded1) {
      const file = backImage1
      const storageRef = firebase.storage().ref()
      const fileExtension = backImage1.name.split('.').pop()

      const uploadTask = storageRef
        .child(
          `user/insurances/${props?.patientId ? props?.patientId : patient?.uid}/${
            frontUploaded1
              ? `front-${getRandomString(10)}`
              : `back-${getRandomString(10)}`
          }.${fileExtension}`,
        )
        .put(file)
      uploadTask.on(
        'state_changed',
        function (snapshot) {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log(`${progress}%`)
        },
        function (err) {
          // Handle unsuccessful uploads
          console.log('error when uploading image', err)
          // self.saveUserToBackend(user)
        },
        function () {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            let pinsurance = { ...secondaryInsurance }
            pinsurance.back_image_url = downloadURL
            setSecondaryInsurance(pinsurance)
            updateUserInsurance(2, pinsurance)
            // console.log('SECONDARY BACK:', downloadURL)
          })
        },
      )
    }
  }

  const onImageChange = (e, insuranceFront, insuranceBack, insurance) => {
    const file = e.target.files[0] || false

    if (insurance === 1) {
      if (
        file &&
        window.confirm(`Are you sure you want to upload ${file.name}?`)
      ) {
        // this.setState({imageUploaded: true, imageLocation: })

        const reader = new FileReader()

        reader.onload = function (e) {
          const image = e.target.result

          if (insuranceFront) {
            console.log('confirmed upload - insurance front')

            setFrontLocation(image)
            setFrontUploaded(true)
            setFrontImage(file)
            setInsuranceFront(false)
          } else if (insuranceBack) {
            console.log('confirmed upload - insurance back')

            setBackLocation(image)
            setBackUploaded(true)
            setBackImage(file)
            setInsuranceBack(false)
          }
        }

        reader.readAsDataURL(file) // convert to base64 string
      } else {
        console.log('ignored')
      }
    } else if (insurance === 2) {
      if (
        file &&
        window.confirm(`Are you sure you want to upload ${file.name}?`)
      ) {
        // this.setState({imageUploaded: true, imageLocation: })

        const reader = new FileReader()

        reader.onload = function (e) {
          const image = e.target.result

          if (insuranceFront) {
            console.log('confirmed upload - insurance front')

            setFrontLocation1(image)
            setFrontUploaded1(true)
            setFrontImage1(file)
            setInsuranceFront1(false)
          } else if (insuranceBack) {
            console.log('confirmed upload - insurance back')

            setBackLocation1(image)
            setBackUploaded1(true)
            setBackImage1(file)
            setInsuranceBack1(false)
          }
        }

        reader.readAsDataURL(file) // convert to base64 string
      } else {
        console.log('ignored')
      }
    }
  }

  const savePressed = () => {
    if (
      !primaryInsurance?.insurance_company?.insurance_name.length &&
      !props?.fromProvider
    ) {
      toast.warning('Please select Primary Insurance')
      return
    }
    if (!primaryInsurance?.subscriber_id && !props?.fromProvider) {
      toast.warning('Please enter Primary Insurance Policy Number')
      return
    }
    if (
      (!primaryInsurance?.insured?.insured_first_name.length ||
        !primaryInsurance?.insured?.insured_last_name.length ||
        !primaryInsurance?.insured?.insured_sex ||
        !primaryInsurance?.insured?.insured_home_phone ||
        !primaryInsurance?.insured?.insured_address_1 ||
        !primaryInsurance?.insured?.insured_city ||
        !primaryInsurance?.insured?.insured_state ||
        !primaryInsurance?.insured?.insured_zip) &&
      !props?.fromProvider
    ) {
      toast.warning('Please fill Primary Insured Details ')
      return
    }

    if (
      showSecondaryInsurance &&
      !secondaryInsurance?.insurance_company?.insurance_name.length &&
      !props?.fromProvider
    ) {
      toast.warning('Please select Secondary Insurance')
      return
    }
    if (
      showSecondaryInsurance &&
      !secondaryInsurance?.subscriber_id &&
      !props?.fromProvider
    ) {
      toast.warning('Please enter Secondary Insurance Policy Number')
      return
    }
    if (
      showSecondaryInsurance &&
      !props?.fromProvider &&
      (!secondaryInsurance?.insured?.insured_first_name.length ||
        !secondaryInsurance?.insured?.insured_last_name.length ||
        !secondaryInsurance?.insured?.insured_sex ||
        !secondaryInsurance?.insured?.insured_home_phone ||
        !secondaryInsurance?.insured?.insured_address_1 ||
        !secondaryInsurance?.insured?.insured_city ||
        !secondaryInsurance?.insured?.insured_state ||
        !secondaryInsurance?.insured?.insured_zip)
    ) {
      toast.warning('Please fill Secondary Insured Details ')
      return
    }

    setLoading(true)

    const firebaseData = {
      termsChecked: termsChecked,
    }

    updateUserDataOnFirebase(props?.patientId ? props?.patientId : patient?.uid, firebaseData)

    if (frontUploaded || backUploaded || frontUploaded1 || backUploaded1) {
      saveImageToFirebase()
    } else {
      updateUserInsurance(1)
      updateUserInsurance(2)
    }
  }

  const updateUserDataOnFirebase = (uid, data) => {
    console.log('success firebase')
    firebase
      .firestore()
      .collection('users')
      .doc(uid)
      .update(data)
      .then((doc) => {
        console.log('success')
        firebase
          .firestore()
          .collection('users')
          .doc(uid)
          .get()
          .then((doc) => {
            if (doc && doc.data()) {
              const data = doc.data()
              console.log('user firebase saved :: ', data)
              dispatch(addFirebaseUser(data))
              dispatch(addPatient(data, 'gray'))
            }
          })
        // getFirebaseUser(uid)
      })
      .catch((err) => {
        console.log('error - saving firebase ', err)
      })
  }

  const renderMedicModal = () => {
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <h4
          style={{
            color: Constants.primaryThemeDark,
            textAlign: 'center',
            marginBottom: '5%',
          }}
        >
          Medicare/ Medicaid Notice
        </h4>

        <p
          style={{
            fontSize: '14',
            marginTop: '4vh',
            color: Constants.primaryTheme,
            textAlign: 'center',
            fontFamily: 'Avenir',
            wordWrap: 'break-word',
            position: 'relative',
          }}
        >
          {`We currently do not accept Medicaid. 
        However, Aila coaching services will still be available for the subscription. 
        If you would like Aila’s Coaching services, please click next to sign up`}
        </p>

        <div
          style={{
            width: '50%',
            margin: '20px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={() => {
              dispatch(toggleSidebar(false))
              setShowMedicModal(false)
              history.push('/patient/subscription')
            }}
            className={GlobalStyles.button}
            // disabled={this.state.modalLoading}
            style={{ width: 200 }}
            variant="primary"
          >
            Next
          </Button>
        </div>
      </div>
    )
  }

  const renderSearchList = () => {
    const renderAddInsurance = () => {
      let pinsurance =
        activeInsurance === 1
          ? { ...primaryInsurance }
          : { ...secondaryInsurance }

      const onAddPressed = () => {
        setAddInsurance(false)
        setShowSearchList(false)

        setActiveInsurance(1)
        setSearchName('')
        setSearchCity('')
        setSearchAddress('')
        setSearchList([])
        // activeInsurance === 1
        //   ? setPrimaryInsurance(pinsurance)
        //   : setSecondaryInsurance(pinsurance)
      }
      return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ width: '50%', padding: '20px 20px' }}>
            <div className={Styles.row}>
              <p className={Styles.rowLabel}>Name</p>
              <div
                style={{ width: '50%', display: 'flex', flexDirection: 'row' }}
              >
                <input
                  placeholder="Insurance Name"
                  className={Styles.textInput}
                  style={{ width: '100%' }}
                  type="text"
                  value={pinsurance?.insurance_company?.insurance_name}
                  onChange={(e) => {
                    pinsurance.insurance_company.insurance_name = e.target.value
                    activeInsurance === 1
                      ? setPrimaryInsurance(pinsurance)
                      : setSecondaryInsurance(pinsurance)
                  }}
                />
              </div>
            </div>

            <div className={Styles.row}>
              <p className={Styles.rowLabel}>Address</p>
              <div style={{ width: '50%' }}>
                <input
                  placeholder="Enter Address"
                  className={Styles.textInput}
                  style={{ width: '100%' }}
                  type="text"
                  value={pinsurance?.insurance_company?.insurance_address_1}
                  onChange={(e) => {
                    pinsurance.insurance_company.insurance_address_1 =
                      e.target.value
                    activeInsurance === 1
                      ? setPrimaryInsurance(pinsurance)
                      : setSecondaryInsurance(pinsurance)
                  }}
                />
              </div>
            </div>

            <div className={Styles.row}>
              <p className={Styles.rowLabel}>City</p>
              <div style={{ width: '50%' }}>
                <input
                  placeholder="Enter Insured City"
                  className={Styles.textInput}
                  style={{ width: '100%' }}
                  type="text"
                  value={pinsurance?.insurance_company?.insurance_city}
                  onChange={(e) => {
                    pinsurance.insurance_company.insurance_city = e.target.value
                    activeInsurance === 1
                      ? setPrimaryInsurance(pinsurance)
                      : setSecondaryInsurance(pinsurance)
                  }}
                />
              </div>
            </div>

            <div className={Styles.row}>
              <p className={Styles.rowLabel}>Zip</p>
              <div style={{ width: '50%' }}>
                <input
                  placeholder="Enter Insured Zip"
                  className={Styles.textInput}
                  style={{ width: '100%' }}
                  type="text"
                  value={pinsurance?.insurance_company?.insurance_zip}
                  onChange={(e) => {
                    pinsurance.insurance_company.insurance_zip = e.target.value
                    activeInsurance === 1
                      ? setPrimaryInsurance(pinsurance)
                      : setSecondaryInsurance(pinsurance)
                  }}
                />
              </div>
            </div>

            <div className={Styles.row}>
              <p className={Styles.rowLabel}>State</p>
              <div style={{ width: '50%' }}>
                <Select
                  placeholder="Select State"
                  inputId="newinsurancestate"
                  options={metadata.stateResidence}
                  value={
                    pinsurance?.insurance_company?.insurance_state &&
                    !pinsurance?.insurance_company?.insurance_state.label
                      ? {
                          label: pinsurance?.insurance_company?.insurance_state,
                          value: pinsurance?.insurance_company?.insurance_state,
                        }
                      : pinsurance?.insurance_company?.insurance_state
                  }
                  isMulti={false}
                  isSearchable
                  autoBlur
                  onChange={(val) => {
                    pinsurance.insurance_company.insurance_state =
                      val.abbreviation
                    activeInsurance === 1
                      ? setPrimaryInsurance(pinsurance)
                      : setSecondaryInsurance(pinsurance)
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#20A89233',
                      primary: '#20A892',
                    },
                  })}
                />
              </div>
            </div>
            <Button
              onClick={() => onAddPressed()}
              className={GlobalStyles.button}
              style={{ width: 200, margin: '5% auto' }}
              variant="primary"
            >
              Add Insurance
            </Button>
          </div>
        </div>
      )
    }
    const renderList = (insuranceData, index) => {
      return (
        <div
          key={index}
          // className={Styles.contentRow}
          className={Styles.contentBackground}
          style={{
            flexDirection: 'column',
            alignItems: 'flex-start',
            margin: 10,
            backgroundColor: Constants.contentBackground,
            borderRadius: Constants.borderRadius,
            padding: '1% 2%',
          }}
        >
          <div
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start',
                margin: 10,
              }}
            >
              <h6 style={{ color: Constants.primaryThemeDark }}>
                {insuranceData?.name[1]}
                {/* {'\n '}
                  Visit with {insuranceData?.first_name}{' '}
                  {insuranceData?.last_name} */}
              </h6>
              <h6 style={{ color: Constants.primaryTheme }}>
                {` ${insuranceData?.address_1 || ''} ${
                  insuranceData?.city || ''
                }`}
              </h6>
              <h6 style={{ color: Constants.primaryTheme }}>
                {` ${insuranceData?.state || ''} ${insuranceData?.zip || ''} `}
              </h6>
            </div>

            <Button
              className={GlobalStyles.button}
              //   disabled={loading}
              onClick={() => {
                let pinsurance =
                  activeInsurance === 1
                    ? { ...primaryInsurance }
                    : { ...secondaryInsurance }
                pinsurance.insurance_company.insurance_name =
                  insuranceData?.name[1]
                pinsurance.insurance_company.insurance_isp = insuranceData?.isp
                pinsurance.insurance_company.insurance_address_1 =
                  insuranceData?.address_1
                pinsurance.insurance_company.insurance_city =
                  insuranceData?.city
                pinsurance.insurance_company.insurance_state =
                  insuranceData?.state
                pinsurance.insurance_company.insurance_zip = insuranceData?.zip
                activeInsurance === 1
                  ? setPrimaryInsurance(pinsurance)
                  : setSecondaryInsurance(pinsurance)
                setActiveInsurance(1)
                setShowSearchList(false)

                setSearchName('')
                setSearchCity('')
                setSearchAddress('')
                setSearchList([])
              }}
              style={{
                justifyContent: 'right',
                width: '10vw',
              }}
              variant="primary"
            >
              Select
            </Button>
          </div>
        </div>
      )
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: '1% 5%',
        }}
      >
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Search Insurance
        </h4>
        <div className={Styles.row} style={{ margin: '2% 0%' }}>
          <p className={Styles.rowLabel}>Name</p>
          <div style={{ width: '50%', marginRight: '2%' }}>
            <input
              placeholder="Search Name"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={searchName}
              onChange={(e) => {
                setSearchName(e.target.value)
              }}
            />
          </div>
          {/* <p className={Styles.rowLabel}>Address</p>
          <div style={{ width: '50%', marginRight: '2%' }}>
            <input
              placeholder="Search Address"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={searchAddress}
              onChange={(e) => {
                setSearchAddress(e.target.value)
              }}
            />
          </div> */}
          <p className={Styles.rowLabel}>City</p>
          <div style={{ width: '50%', marginRight: '2%' }}>
            <input
              placeholder="Search City"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={searchCity}
              onChange={(e) => {
                setSearchCity(e.target.value)
              }}
            />
          </div>

          <p className={Styles.rowLabel}>State</p>
          <div style={{ width: '50%', marginRight: '2%' }}>
            <Select
              placeholder="Select State"
              inputId="primarystate"
              options={metadata.stateResidence}
              value={searchState}
              isMulti={false}
              isSearchable
              autoBlur
              onChange={(val) => {
                setSearchState(val)
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>

          <FontAwesomeIcon
            onClick={() => {
              //   setActiveInsurance(1)
              console.log(
                'SEARCH PARAMS:',
                searchAddress,
                searchName,
                searchCity,
              )
              onSearch()
              //   setShowSearchList(true)
            }}
            icon={faSearch}
            style={{
              color: Constants.primaryTheme,
              fontSize: 20,
              marginLeft: 10,
              cursor: 'pointer',
            }}
          />
        </div>

        <div style={{ height: '65vh', overflowY: 'scroll' }}>
          {searchList.length !== 0 ? (
            searchList.map((insurance, index) => renderList(insurance, index))
          ) : addInsurance ? (
            renderAddInsurance()
          ) : (
            <p style={{ textAlign: 'center', marginTop: 50 }}>
              No Searches found <br />
              Please search by city, name and address
            </p>
          )}
        </div>
      </div>
    )
  }

  const renderContent = () => {
    //PRIMARY INSURANCE IMAGES
    let frontImage
    if (frontUploaded) frontImage = frontLocation
    else frontImage = primaryInsurance?.front_image_url || null

    let backImage
    if (backUploaded) backImage = backLocation
    else backImage = primaryInsurance?.back_image_url || null

    //SECONDARY IMAGES
    let frontImage1
    if (frontUploaded1) frontImage1 = frontLocation1
    else frontImage1 = secondaryInsurance?.front_image_url || null

    let backImage1
    if (backUploaded1) backImage1 = backLocation1
    else backImage1 = secondaryInsurance?.back_image_url || null

    let disabledPrimary = props?.fromLCFlow
      ? false
      : primaryInsurance?.insured?.insured_relationship === 'Self'
      ? true
      : false
    let disabledSecondary = props?.fromLCFlow
      ? false
      : secondaryInsurance?.insured?.insured_relationship === 'Self'
      ? true
      : false

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: '1% 5%',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ width: '50%', padding: '20px 20px' }}>
            {/*PRIMARY INSURANCE */}
            <p
              className={Styles.rowLabel}
              style={{ fontSize: 'large', fontStyle: 'bold', width: '100%' }}
            >
              Primary Insurance
            </p>

            {/* <div className={Styles.row}>
              <p className={Styles.rowLabel}>Code</p>
              <div style={{ width: '50%' }}>
                <input
                  placeholder="Search for Insurance"
                  className={Styles.textInput}
                  style={{ width: 'auto' }}
                  type="text"
                  disabled={!primaryInsurance?.insurance_company ? true : false}
                  value={primaryInsurance?.insurance_company?.insurance_code}
                  onChange={(e) => {
                    let pinsurance = { ...primaryInsurance }
                    pinsurance.insurance_company.insurance_code = e.target.value
                    setPrimaryInsurance(pinsurance)
                  }}
                />              
              </div>
            </div> */}

            <div className={Styles.row}>
              <p className={Styles.rowLabel}>Name</p>
              <div
                style={{ width: '50%', display: 'flex', flexDirection: 'row' }}
              >
                <input
                  placeholder=""
                  className={Styles.textInput}
                  style={{ width: '100%' }}
                  type="text"
                  disabled={true}
                  value={primaryInsurance?.insurance_company?.insurance_name}
                  // onChange={(e) => {
                  //   let pinsurance = { ...primaryInsurance }
                  //   pinsurance.plan_type = e.target.value
                  //   setPrimaryInsurance(pinsurance)
                  // }}
                />
                <FontAwesomeIcon
                  onClick={() => {
                    setActiveInsurance(1)
                    setShowSearchList(true)

                    setAddInsurance(false)
                    setSearchName('')
                    setSearchCity('')
                    setSearchAddress('')
                    setSearchList([])
                  }}
                  icon={faSearch}
                  style={{
                    color: Constants.primaryTheme,
                    fontSize: 20,
                    marginLeft: 10,
                    cursor: 'pointer',
                  }}
                />
              </div>
            </div>

            <div className={Styles.row}>
              <p className={Styles.rowLabel}>Insurance Plan Type</p>
              <div style={{ width: '50%' }}>
                <input
                  placeholder="Enter Insurance Plan Type"
                  className={Styles.textInput}
                  style={{ width: '100%' }}
                  type="text"
                  value={primaryInsurance?.plan_type}
                  onChange={(e) => {
                    let pinsurance = { ...primaryInsurance }
                    pinsurance.plan_type = e.target.value
                    setPrimaryInsurance(pinsurance)
                  }}
                />
              </div>
            </div>

            <div className={Styles.row}>
              <p className={Styles.rowLabel}>Member Name</p>
              <div style={{ width: '50%' }}>
                <input
                  placeholder="Enter Member Name"
                  className={Styles.textInput}
                  style={{ width: '100%' }}
                  type="text"
                  value={primaryInsurance?.member_name}
                  onChange={(e) => {
                    let pinsurance = { ...primaryInsurance }
                    pinsurance.member_name = e.target.value
                    setPrimaryInsurance(pinsurance)
                  }}
                />
              </div>
            </div>

            <div className={Styles.row}>
              <p className={Styles.rowLabel}>Policy Number</p>
              <div style={{ width: '50%' }}>
                <input
                  placeholder="Search for Insurance"
                  className={Styles.textInput}
                  style={{ width: '100%' }}
                  type="text"
                  value={primaryInsurance?.subscriber_id}
                  onChange={(e) => {
                    let pinsurance = { ...primaryInsurance }
                    pinsurance.subscriber_id = e.target.value
                    setPrimaryInsurance(pinsurance)
                  }}
                />
              </div>
            </div>

            <div className={Styles.row}>
              <p className={Styles.rowLabel}>Group Number</p>
              <div style={{ width: '50%' }}>
                <input
                  placeholder="Enter Group Number"
                  className={Styles.textInput}
                  style={{ width: '100%' }}
                  type="text"
                  value={primaryInsurance?.group_number}
                  onChange={(e) => {
                    let pinsurance = { ...primaryInsurance }
                    pinsurance.group_number = e.target.value
                    setPrimaryInsurance(pinsurance)
                  }}
                />
              </div>
            </div>

            <div className={Styles.row}>
              <p className={Styles.rowLabel}>Insurance Card Image</p>
              <div
                style={{ width: '50%', display: 'flex', flexDirection: 'row' }}
              >
                <div
                  style={{
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  {frontImage && (
                    <img
                      src={frontImage}
                      style={{
                        height: '100%',
                        width: '90%',
                        objectFit: 'cover',
                      }}
                      alt="No Image"
                    />
                  )}

                  <input
                    onChange={(e) => {
                      setInsuranceFront(true)

                      console.log('upload FRONT clicked')

                      onImageChange(e, true, false, 1)
                    }}
                    type="file"
                    id="file"
                    ref={frontImageRef}
                    accept="image/*"
                    style={{ display: 'none' }}
                    multiple={false}
                  />
                  <p
                    onClick={() => frontImageRef.current.click()}
                    style={{
                      textDecoration: 'underline',
                      color: 'gray',
                      cursor: 'pointer',
                    }}
                  >
                    Upload Front
                  </p>
                </div>
                <div
                  style={{
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  {backImage && (
                    <img
                      src={backImage}
                      style={{
                        height: '100%',
                        width: '90%',
                        objectFit: 'cover',
                      }}
                      alt="No Image"
                    />
                  )}

                  <input
                    onChange={(e) => {
                      setInsuranceBack(true)

                      console.log('upload BACK clicked')

                      onImageChange(e, false, true, 1)
                    }}
                    type="file"
                    id="file"
                    ref={backImageRef}
                    accept="image/*"
                    style={{ display: 'none' }}
                    multiple={false}
                  />
                  <p
                    onClick={() => {
                      backImageRef.current.click()
                    }}
                    style={{
                      textDecoration: 'underline',
                      color: 'gray',
                      cursor: 'pointer',
                    }}
                  >
                    Upload Back
                  </p>
                </div>
              </div>
            </div>

            <div className={Styles.row}>
              <p className={Styles.rowLabel}>Authorization #</p>
              <div style={{ width: '50%' }}>
                <input
                  placeholder="Enter Auth Number"
                  className={Styles.textInput}
                  style={{ width: '100%' }}
                  type="text"
                  // disabled={disabledPrimary}
                  value={primaryInsurance?.auth_number}
                  onChange={(e) => {
                    let pinsurance = { ...primaryInsurance }
                    pinsurance.auth_number = e.target.value
                    setPrimaryInsurance(pinsurance)
                  }}
                />
              </div>
            </div>

            <div className={Styles.row}>
              <label style={{ color: '#20A892' }} class={Styles.checkContainer}>
                I have HMO
                <input
                  type="checkbox"
                  checked={hmo}
                  onClick={() => {
                    // console.log('clocked')
                    let pinsurance = { ...primaryInsurance }
                    pinsurance.hmo = !pinsurance.hmo
                    setPrimaryInsurance(pinsurance)
                    setHMO(pinsurance.hmo)
                  }}
                />
                <span class={Styles.checkmark}></span>
              </label>
            </div>
            <div className={Styles.row}>
              <label style={{ color: '#20A892' }} class={Styles.checkContainer}>
                I have Medicare Advantage
                <input
                  type="checkbox"
                  checked={mediAdv}
                  onClick={() => {
                    // console.log('clocked')
                    let pinsurance = { ...primaryInsurance }
                    pinsurance.medi_adv = !pinsurance.medi_adv
                    setPrimaryInsurance(pinsurance)
                    setMediAdv(pinsurance.medi_adv)
                  }}
                />
                <span class={Styles.checkmark}></span>
              </label>
            </div>
            <div className={Styles.row}>
              <label style={{ color: '#20A892' }} class={Styles.checkContainer}>
                I have Worker's Comp
                <input
                  type="checkbox"
                  checked={workersComp}
                  onClick={() => {
                    // console.log('clocked')
                    let pinsurance = { ...primaryInsurance }
                    pinsurance.workers_comp = !pinsurance.workers_comp
                    setPrimaryInsurance(pinsurance)
                    setWorkersComp(pinsurance.workers_comp)
                  }}
                />
                <span class={Styles.checkmark}></span>
              </label>
            </div>
            <div className={Styles.row}>
              <label style={{ color: '#20A892' }} class={Styles.checkContainer}>
                I have Medicare
                <input
                  type="checkbox"
                  checked={medicare}
                  onClick={() => {
                    // console.log('clocked')
                    let pinsurance = { ...primaryInsurance }
                    pinsurance.medicare = !pinsurance.medicare
                    setPrimaryInsurance(pinsurance)
                    setMedicare(!medicare)
                  }}
                />
                <span class={Styles.checkmark}></span>
              </label>
            </div>
            <div className={Styles.row}>
              <label style={{ color: '#20A892' }} class={Styles.checkContainer}>
                I have Medicaid
                <input
                  type="checkbox"
                  checked={medicaid}
                  onClick={() => {
                    // console.log('clocked')
                    let pinsurance = { ...primaryInsurance }
                    pinsurance.medicaid = !pinsurance.medicaid
                    setPrimaryInsurance(pinsurance)
                    setMedicaid(!medicaid)
                  }}
                />
                <span class={Styles.checkmark}></span>
              </label>
            </div>
          </div>

          <div style={{ width: '50%', padding: '20px 20px' }}>
            {/*INSURANCE */}
            <p
              className={Styles.rowLabel}
              style={{ fontSize: 'large', fontStyle: 'bold', width: '100%' }}
            >
              Insured
            </p>

            <div className={Styles.row}>
              <p className={Styles.rowLabel}>Relationship</p>
              <div style={{ width: '50%' }}>
                <Select
                  inputId="primaryrelationship"
                  options={metadata.RELATIONSHIP_OPTIONS}
                  value={
                    primaryInsurance?.insured?.insured_relationship &&
                    !primaryInsurance?.insured?.insured_relationship.label
                      ? {
                          label:
                            primaryInsurance?.insured?.insured_relationship,
                          value:
                            primaryInsurance?.insured?.insured_relationship,
                        }
                      : primaryInsurance?.insured?.insured_relationship
                  }
                  autoBlur
                  onChange={(val) => {
                    let pinsurance = { ...primaryInsurance }
                    pinsurance.insured.insured_relationship = val.label
                    setPrimaryInsurance(pinsurance)
                    if (val.label === 'Self') {
                      populateSelfData(1)
                    } else {
                      let pinsurance = { ...primaryInsurance }
                      pinsurance.insured.insured_first_name = ''
                      pinsurance.insured.insured_last_name = ''
                      pinsurance.insured.insured_address_1 = ''
                      pinsurance.insured.insured_city = ''
                      pinsurance.insured.insured_state = null
                      pinsurance.insured.insured_zip = ''
                      pinsurance.insured.insured_sex = null
                      pinsurance.insured.insured_home_phone = ''
                      setPrimaryInsurance(pinsurance)
                    }
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#20A89233',
                      primary: '#20A892',
                    },
                  })}
                />

                {/* <input
                  placeholder="Enter Insurance Provider"
                  className={Styles.textInput}
                  style={{ width: '100%' }}
                  type="text"
                  value={primaryInsurance?.insurance_provider}
                  onChange={(e) => {
                  let pinsurance ={...primaryInsurance}
                    pinsurance.insurance_provider = e.target.value
                    setPrimaryInsurance(pinsurance)
                  }}
                /> */}
                {/* 
              <FontAwesomeIcon
                onClick={() => {
                  this.props.toggleSidebar(true)
                  this.setState({ showInsuranceModal: true })
                }}
                icon={faEdit}
                style={{
                  color: Constants.primaryTheme,
                  fontSize: 20,
                  marginLeft: 10,
                  cursor: 'pointer',
                }}
              /> */}
              </div>
            </div>

            <div className={Styles.row}>
              <p className={Styles.rowLabel}>First Name</p>
              <div style={{ width: '20%', marginRight: '2%' }}>
                <input
                  placeholder="First Name"
                  className={Styles.textInput}
                  style={{ width: '100%' }}
                  type="text"
                  disabled={disabledPrimary}
                  value={primaryInsurance?.insured?.insured_first_name}
                  onChange={(e) => {
                    let pinsurance = { ...primaryInsurance }
                    pinsurance.insured.insured_first_name = e.target.value
                    setPrimaryInsurance(pinsurance)
                  }}
                />
              </div>
              <p className={Styles.rowLabel}>Last Name</p>
              <div style={{ width: '20%' }}>
                <input
                  placeholder="Last Name"
                  className={Styles.textInput}
                  style={{ width: '100%' }}
                  type="text"
                  disabled={disabledPrimary}
                  value={primaryInsurance?.insured?.insured_last_name}
                  onChange={(e) => {
                    let pinsurance = { ...primaryInsurance }
                    pinsurance.insured.insured_last_name = e.target.value
                    setPrimaryInsurance(pinsurance)
                  }}
                />
              </div>
            </div>

            <div className={Styles.row}>
              <p className={Styles.rowLabel}>Gender</p>
              <div style={{ width: '50%' }}>
                <Select
                  placeholder="Select Gender"
                  inputId="primarygender"
                  options={metadata.GENDER_OPTIONS}
                  isDisabled={disabledPrimary}
                  value={
                    primaryInsurance?.insured?.insured_sex &&
                    !primaryInsurance?.insured?.insured_sex.label
                      ? {
                          label: primaryInsurance?.insured?.insured_sex,
                          value: primaryInsurance?.insured?.insured_sex,
                        }
                      : primaryInsurance?.insured?.insured_sex
                  }
                  autoBlur
                  onChange={(val) => {
                    let pinsurance = { ...primaryInsurance }
                    pinsurance.insured.insured_sex = val.label
                    setPrimaryInsurance(pinsurance)
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#20A89233',
                      primary: '#20A892',
                    },
                  })}
                />
              </div>
            </div>
            <div className={Styles.row}>
              <p className={Styles.rowLabel}>Phone</p>
              <div style={{ width: '50%' }}>
                <input
                  placeholder="Enter Insured Phone"
                  className={Styles.textInput}
                  style={{ width: '100%' }}
                  type="text"
                  disabled={disabledPrimary}
                  value={primaryInsurance?.insured?.insured_home_phone}
                  onChange={(e) => {
                    let pinsurance = { ...primaryInsurance }
                    pinsurance.insured.insured_home_phone = e.target.value
                    setPrimaryInsurance(pinsurance)
                  }}
                />
              </div>
            </div>

            <div className={Styles.row}>
              <p className={Styles.rowLabel}>Address</p>
              <div style={{ width: '50%' }}>
                <input
                  placeholder="Enter Insured Address"
                  className={Styles.textInput}
                  style={{ width: '100%' }}
                  type="text"
                  disabled={disabledPrimary}
                  value={primaryInsurance?.insured?.insured_address_1}
                  onChange={(e) => {
                    let pinsurance = { ...primaryInsurance }
                    pinsurance.insured.insured_address_1 = e.target.value
                    setPrimaryInsurance(pinsurance)
                  }}
                />
              </div>
            </div>

            <div className={Styles.row}>
              <p className={Styles.rowLabel}>City</p>
              <div style={{ width: '50%' }}>
                <input
                  placeholder="Enter Insured City"
                  className={Styles.textInput}
                  style={{ width: '100%' }}
                  type="text"
                  disabled={disabledPrimary}
                  value={primaryInsurance?.insured?.insured_city}
                  onChange={(e) => {
                    let pinsurance = { ...primaryInsurance }
                    pinsurance.insured.insured_city = e.target.value
                    setPrimaryInsurance(pinsurance)
                  }}
                />
              </div>
            </div>

            <div className={Styles.row}>
              <p className={Styles.rowLabel}>Zip</p>
              <div style={{ width: '50%' }}>
                <input
                  placeholder="Enter Insured Zip"
                  className={Styles.textInput}
                  style={{ width: '100%' }}
                  type="text"
                  disabled={disabledPrimary}
                  value={primaryInsurance?.insured?.insured_zip}
                  onChange={(e) => {
                    let pinsurance = { ...primaryInsurance }
                    pinsurance.insured.insured_zip = e.target.value
                    setPrimaryInsurance(pinsurance)
                  }}
                />
              </div>
            </div>

            <div className={Styles.row}>
              <p className={Styles.rowLabel}>State</p>
              <div style={{ width: '50%' }}>
                <Select
                  placeholder="Select State"
                  inputId="primarystate"
                  isDisabled={disabledPrimary}
                  options={metadata.stateResidence}
                  value={
                    primaryInsurance?.insured?.insured_state &&
                    !primaryInsurance?.insured?.insured_state.label
                      ? {
                          label: primaryInsurance?.insured?.insured_state,
                          value: primaryInsurance?.insured?.insured_state,
                        }
                      : primaryInsurance?.insured?.insured_state
                  }
                  isMulti={false}
                  isSearchable
                  autoBlur
                  onChange={(val) => {
                    let pinsurance = { ...primaryInsurance }
                    pinsurance.insured.insured_state = val.abbreviation
                    setPrimaryInsurance(pinsurance)
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#20A89233',
                      primary: '#20A892',
                    },
                  })}
                />
              </div>
            </div>
          </div>
        </div>

        <div style={{ width: '100%', padding: '20px 20px' }}>
          <div className={Styles.row}>
            <label style={{ color: '#20A892' }} class={Styles.checkContainer}>
              I authorize the release of any medical or other information
              necessary to process claims for my medical visits. I also request
              payment of government benefits either to myself or to the party
              who accepts assignment.
              <input
                type="checkbox"
                checked={termsChecked.medical_release}
                onClick={() => {
                  let temp = { ...termsChecked }
                  temp.medical_release = !temp.medical_release
                  setTermsChecked(temp)
                }}
              />
              <span class={Styles.checkmark}></span>
            </label>
          </div>
          <div className={Styles.row}>
            <label style={{ color: '#20A892' }} class={Styles.checkContainer}>
              I authorize payment of medical benefits to the undersigned
              physician or supplier for services rendered and described on the
              claim being submitted to the insurance carrier.
              <input
                type="checkbox"
                checked={termsChecked.payments}
                onClick={() => {
                  let temp = { ...termsChecked }
                  temp.payments = !temp.payments
                  setTermsChecked(temp)
                }}
              />
              <span class={Styles.checkmark}></span>
            </label>
          </div>
        </div>

        {props?.fromProvider && (
          <>
            <div className={Styles.row}>
              <p
                style={{
                  borderBottom: '1px solid #d0d0d0',
                  width: '100%',
                  margin: 'auto',
                }}
              ></p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ width: '50%', padding: '20px 20px' }}>
                <p
                  className={Styles.rowLabel}
                  style={{
                    fontSize: 'large',
                    fontStyle: 'bold',
                    width: '100%',
                  }}
                >
                  Benefits
                </p>

                {/*COPAY DEDUCTIBLE INTAKE */}

                <div className={Styles.row}>
                  <p className={Styles.rowLabel}>Category</p>

                  <div style={{ width: '50%' }}>
                    <Select
                      placeholder="Select "
                      inputId="benefit-category"
                      options={[
                        { label: 'Specialist', value: 'Specialist' },
                        { label: 'PPO', value: 'PPO' },
                      ]}
                      value={
                        primaryInsurance?.benefits?.category
                          ? {
                              label: primaryInsurance?.benefits?.category,
                              value: primaryInsurance?.benefits?.category,
                            }
                          : null
                      }
                      autoBlur
                      onChange={(val) => {
                        let pinsurance = { ...primaryInsurance }
                        pinsurance.benefits.category = val.label
                        setPrimaryInsurance(pinsurance)
                      }}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: '#20A89233',
                          primary: '#20A892',
                        },
                      })}
                    />
                  </div>
                </div>
                <div className={Styles.row}>
                  <label
                    style={{ color: '#20A892' }}
                    class={Styles.checkContainer}
                  >
                    Telemedicine covered
                    <input
                      type="checkbox"
                      checked={primaryInsurance?.benefits?.telemedicine}
                      onClick={() => {
                        let pinsurance = { ...primaryInsurance }
                        pinsurance.benefits.telemedicine =
                          !pinsurance.benefits.telemedicine
                        setPrimaryInsurance(pinsurance)
                      }}
                    />
                    <span class={Styles.checkmark}></span>
                  </label>
                </div>

                <div className={Styles.row}>
                  <p className={Styles.rowLabel}>Copay ($)</p>
                  <div style={{ width: '50%' }}>
                    <input
                      placeholder="$"
                      className={Styles.textInput}
                      style={{ width: '100%' }}
                      type="text"
                      // disabled={disabledPrimary}
                      value={primaryInsurance?.benefits?.copay}
                      onChange={(e) => {
                        let pinsurance = { ...primaryInsurance }
                        pinsurance.benefits.copay = e.target.value
                        setPrimaryInsurance(pinsurance)
                      }}
                    />
                  </div>
                </div>
                <div className={Styles.row}>
                  <p className={Styles.rowLabel}>Coinsurance (%)</p>
                  <div style={{ width: '50%' }}>
                    <input
                      placeholder="%"
                      className={Styles.textInput}
                      style={{ width: '100%' }}
                      type="text"
                      // disabled={disabledPrimary}
                      value={primaryInsurance?.benefits?.coinsurance}
                      onChange={(e) => {
                        let pinsurance = { ...primaryInsurance }
                        pinsurance.benefits.coinsurance = e.target.value
                        setPrimaryInsurance(pinsurance)
                      }}
                    />
                  </div>
                </div>
                <div className={Styles.row}>
                  <p className={Styles.rowLabel}>
                    Deductible (Calendar Year $)
                  </p>
                  <div style={{ width: '50%' }}>
                    <input
                      placeholder="$"
                      className={Styles.textInput}
                      style={{ width: '100%' }}
                      type="text"
                      // disabled={disabledPrimary}
                      value={primaryInsurance?.benefits?.deductible_calendar}
                      onChange={(e) => {
                        let pinsurance = { ...primaryInsurance }
                        pinsurance.benefits.deductible_calendar = e.target.value
                        setPrimaryInsurance(pinsurance)
                      }}
                    />
                  </div>
                </div>
                <div className={Styles.row}>
                  <p className={Styles.rowLabel}>Deductible (Year to Date $)</p>
                  <div style={{ width: '50%' }}>
                    <input
                      placeholder="$"
                      className={Styles.textInput}
                      style={{ width: '100%' }}
                      type="text"
                      // disabled={disabledPrimary}
                      value={primaryInsurance?.benefits?.deductible_date}
                      onChange={(e) => {
                        let pinsurance = { ...primaryInsurance }
                        pinsurance.benefits.deductible_date = e.target.value
                        setPrimaryInsurance(pinsurance)
                      }}
                    />
                  </div>
                </div>
                <p
                  className={Styles.rowLabel}
                  style={{
                    fontSize: 'large',
                    fontStyle: 'bold',
                    width: '100%',
                    marginTop: '20px',
                  }}
                >
                  Referrals
                </p>
                <div className={Styles.row}>
                  <label
                    style={{ color: '#20A892' }}
                    class={Styles.checkContainer}
                  >
                    Referral required?
                    <input
                      type="checkbox"
                      checked={referrals?.required}
                      onClick={() => {
                        // console.log('clocked')
                        let referrals_temp = { ...referrals }
                        referrals_temp.required = !referrals_temp.required
                        setReferrals(referrals_temp)
                      }}
                    />
                    <span class={Styles.checkmark}></span>
                  </label>
                </div>
                <div className={Styles.row}>
                  <label
                    style={{ color: '#20A892' }}
                    class={Styles.checkContainer}
                  >
                    Referral received?
                    <input
                      type="checkbox"
                      checked={referrals?.received}
                      onClick={() => {
                        // console.log('clocked')
                        let referrals_temp = { ...referrals }
                        referrals_temp.received = !referrals_temp.received
                        setReferrals(referrals_temp)
                      }}
                    />
                    <span class={Styles.checkmark}></span>
                  </label>
                </div>
              </div>
              <div style={{ width: '50%', padding: '20px 20px' }}>
                <p
                  className={Styles.rowLabel}
                  style={{
                    fontSize: 'large',
                    fontStyle: 'bold',
                    width: '100%',
                  }}
                >
                  PBM
                </p>
                <div className={Styles.row}>
                  <p className={Styles.rowLabel}>PBM</p>
                  <div style={{ width: '50%' }}>
                    <input
                      placeholder="Enter PBM"
                      className={Styles.textInput}
                      style={{ width: '100%' }}
                      type="text"
                      value={primaryInsurance?.benefits?.pbm}
                      onChange={(e) => {
                        let pinsurance = { ...primaryInsurance }
                        pinsurance.benefits.pbm = e.target.value
                        setPrimaryInsurance(pinsurance)
                      }}
                    />
                  </div>
                </div>

                <div className={Styles.row}>
                  <p className={Styles.rowLabel}>Patient Insurance State</p>
                  <div style={{ width: '50%' }}>
                    <Select
                      placeholder="Select State"
                      inputId="benefitstate"
                      options={metadata.stateResidence}
                      value={
                        primaryInsurance?.benefits?.state
                          ? {
                              label: primaryInsurance?.benefits?.state,
                              value: primaryInsurance?.benefits?.state,
                            }
                          : null
                      }
                      isMulti={false}
                      isSearchable
                      autoBlur
                      onChange={(val) => {
                        let pinsurance = { ...primaryInsurance }
                        pinsurance.benefits.state = val.abbreviation
                        setPrimaryInsurance(pinsurance)
                      }}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: '#20A89233',
                          primary: '#20A892',
                        },
                      })}
                    />
                  </div>
                </div>
                <div className={Styles.row}>
                  <p className={Styles.rowLabel}>RxBIN Number</p>
                  <div style={{ width: '50%' }}>
                    <input
                      placeholder="Enter RxBIN"
                      className={Styles.textInput}
                      style={{ width: '100%' }}
                      type="text"
                      value={primaryInsurance?.benefits?.rxbin}
                      onChange={(e) => {
                        let pinsurance = { ...primaryInsurance }
                        pinsurance.benefits.rxbin = e.target.value
                        setPrimaryInsurance(pinsurance)
                      }}
                    />
                  </div>
                </div>
                <div className={Styles.row}>
                  <p className={Styles.rowLabel}>RxPCN Number</p>
                  <div style={{ width: '50%' }}>
                    <input
                      placeholder="Enter RxPCN"
                      className={Styles.textInput}
                      style={{ width: '100%' }}
                      type="text"
                      value={primaryInsurance?.benefits?.rxpcn}
                      onChange={(e) => {
                        let pinsurance = { ...primaryInsurance }
                        pinsurance.benefits.rxpcn = e.target.value
                        setPrimaryInsurance(pinsurance)
                      }}
                    />
                  </div>
                </div>
                <div className={Styles.row}>
                  <p className={Styles.rowLabel}>RX Group</p>
                  <div style={{ width: '50%' }}>
                    <input
                      placeholder="Enter RX Group"
                      className={Styles.textInput}
                      style={{ width: '100%' }}
                      type="text"
                      value={primaryInsurance?.benefits?.rxgroup}
                      onChange={(e) => {
                        let pinsurance = { ...primaryInsurance }
                        pinsurance.benefits.rxgroup = e.target.value
                        setPrimaryInsurance(pinsurance)
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* PHARMACY */}
            <div className={Styles.row}>
              <p
                style={{
                  borderBottom: '1px solid #d0d0d0',
                  width: '100%',
                  margin: 'auto',
                }}
              ></p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ width: '70%', padding: '20px 20px' }}>
                <p
                  className={Styles.rowLabel}
                  style={{
                    fontSize: 'large',
                    fontStyle: 'bold',
                    width: '100%',
                  }}
                >
                  Preferred/Local Pharmacy
                </p>
                {renderPharmaName(true)}
              </div>
              <div style={{ width: '70%', padding: '20px 20px' }}>
                <p
                  className={Styles.rowLabel}
                  style={{
                    fontSize: 'large',
                    fontStyle: 'bold',
                    width: '100%',
                  }}
                >
                  Specialty Pharmacy
                </p>
                {renderPharmaName(false)}
              </div>
            </div>
          </>
        )}

        <div className={Styles.row}>
          <p
            style={{
              borderBottom: '1px solid #d0d0d0',
              width: '100%',
              margin: 'auto',
            }}
          ></p>
        </div>
        <div style={{ width: '50%', padding: '20px 20px' }}>
          {/*PRIMARY INSURANCE */}
          <p
            className={Styles.rowLabel}
            style={{ fontSize: 'large', fontStyle: 'bold', width: '100%' }}
          >
            Do you have Secondary Insurance ?
          </p>
          <div style={{ width: '50%' }}>
            <Select
              placeholder="Select Gender"
              inputId="primarygender"
              options={[
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ]}
              value={
                showSecondaryInsurance
                  ? { label: 'Yes', value: 'Yes' }
                  : { label: 'No', value: 'No' }
              }
              autoBlur
              onChange={(val) => {
                if (val.label === 'Yes') setShowSecondaryInsurance(true)
                if (val.label === 'No') setShowSecondaryInsurance(false)
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
        </div>

        {showSecondaryInsurance && (
          <>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ width: '50%', padding: '20px 20px' }}>
                {/*PRIMARY INSURANCE */}
                <p
                  className={Styles.rowLabel}
                  style={{
                    fontSize: 'large',
                    fontStyle: 'bold',
                    width: '100%',
                  }}
                >
                  Secondary Insurance
                </p>
                {/* <div className={Styles.row}>
                  <p className={Styles.rowLabel}>Code</p>
                  <div style={{ width: '50%' }}>
                    <input
                      placeholder="Search for Insurance"
                      className={Styles.textInput}
                      style={{ width: 'auto' }}
                      type="text"
                      disabled={true}
                      value={secondaryInsurance?.insurance_provider}
                      onChange={(e) => {
                        let pinsurance = { ...secondaryInsurance }
                        pinsurance.insurance_provider = e.target.value
                        setSecondaryInsurance(pinsurance)
                      }}
                    />                     
                  </div>
                </div> */}

                <div className={Styles.row}>
                  <p className={Styles.rowLabel}>Name</p>
                  <div
                    style={{
                      width: '50%',
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <input
                      placeholder=""
                      className={Styles.textInput}
                      style={{ width: 'auto' }}
                      type="text"
                      disabled={true}
                      value={
                        secondaryInsurance?.insurance_company?.insurance_name
                      }
                      // onChange={(e) => {
                      //   let pinsurance = { ...secondaryInsurance }
                      //   pinsurance.plan_type = e.target.value
                      //   setSecondaryInsurance(pinsurance)
                      // }}
                    />
                    <FontAwesomeIcon
                      onClick={() => {
                        setActiveInsurance(2)
                        setShowSearchList(true)

                        setAddInsurance(false)
                        setSearchName('')
                        setSearchCity('')
                        setSearchAddress('')
                        setSearchList([])
                      }}
                      icon={faSearch}
                      style={{
                        color: Constants.primaryTheme,
                        fontSize: 20,
                        marginLeft: 10,
                        cursor: 'pointer',
                      }}
                    />
                  </div>
                </div>

                <div className={Styles.row}>
                  <p className={Styles.rowLabel}>Insurance Plan Type</p>
                  <div style={{ width: '50%' }}>
                    <input
                      placeholder="Enter Insurance Plan Type"
                      className={Styles.textInput}
                      style={{ width: '100%' }}
                      type="text"
                      value={secondaryInsurance?.plan_type}
                      onChange={(e) => {
                        let pinsurance = { ...secondaryInsurance }
                        pinsurance.plan_type = e.target.value
                        setSecondaryInsurance(pinsurance)
                      }}
                    />
                  </div>
                </div>

                <div className={Styles.row}>
                  <p className={Styles.rowLabel}>Member Name</p>
                  <div style={{ width: '50%' }}>
                    <input
                      placeholder="Enter Member Name"
                      className={Styles.textInput}
                      style={{ width: '100%' }}
                      type="text"
                      value={secondaryInsurance?.member_name}
                      onChange={(e) => {
                        let pinsurance = { ...secondaryInsurance }
                        pinsurance.member_name = e.target.value
                        setSecondaryInsurance(pinsurance)
                      }}
                    />
                  </div>
                </div>

                <div className={Styles.row}>
                  <p className={Styles.rowLabel}>Policy Number</p>
                  <div style={{ width: '50%' }}>
                    <input
                      placeholder="Enter Policy Number"
                      className={Styles.textInput}
                      style={{ width: '100%' }}
                      type="text"
                      value={secondaryInsurance?.subscriber_id}
                      onChange={(e) => {
                        let pinsurance = { ...secondaryInsurance }
                        pinsurance.subscriber_id = e.target.value
                        setSecondaryInsurance(pinsurance)
                      }}
                    />
                  </div>
                </div>

                <div className={Styles.row}>
                  <p className={Styles.rowLabel}>Group Number</p>
                  <div style={{ width: '50%' }}>
                    <input
                      placeholder="Enter Group Number"
                      className={Styles.textInput}
                      style={{ width: '100%' }}
                      type="text"
                      value={secondaryInsurance?.group_number}
                      onChange={(e) => {
                        let pinsurance = { ...secondaryInsurance }
                        pinsurance.group_number = e.target.value
                        setSecondaryInsurance(pinsurance)
                      }}
                    />
                  </div>
                </div>

                <div className={Styles.row}>
                  <p className={Styles.rowLabel}>Insurance Card Image</p>
                  <div
                    style={{
                      width: '50%',
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <div
                      style={{
                        width: '50%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      {frontImage1 && (
                        <img
                          src={frontImage1}
                          style={{
                            height: '100%',
                            width: '90%',
                            objectFit: 'cover',
                          }}
                          alt="No Image"
                        />
                      )}

                      <input
                        onChange={(e) => {
                          setInsuranceFront1(true)

                          console.log('upload FRONT clicked')

                          onImageChange(e, true, false, 2)
                        }}
                        type="file"
                        id="file"
                        ref={frontImageRef1}
                        accept="image/*"
                        style={{ display: 'none' }}
                        multiple={false}
                      />
                      <p
                        onClick={() => frontImageRef1.current.click()}
                        style={{
                          textDecoration: 'underline',
                          color: 'gray',
                          cursor: 'pointer',
                        }}
                      >
                        Upload Front
                      </p>
                    </div>
                    <div
                      style={{
                        width: '50%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      {backImage1 && (
                        <img
                          src={backImage1}
                          style={{
                            height: '100%',
                            width: '90%',
                            objectFit: 'cover',
                          }}
                          alt="No Image"
                        />
                      )}

                      <input
                        onChange={(e) => {
                          setInsuranceBack1(true)

                          console.log('upload BACK clicked')

                          onImageChange(e, false, true, 2)
                        }}
                        type="file"
                        id="file"
                        ref={backImageRef1}
                        accept="image/*"
                        style={{ display: 'none' }}
                        multiple={false}
                      />
                      <p
                        onClick={() => {
                          backImageRef1.current.click()
                        }}
                        style={{
                          textDecoration: 'underline',
                          color: 'gray',
                          cursor: 'pointer',
                        }}
                      >
                        Upload Back
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ width: '50%', padding: '20px 20px' }}>
                {/*INSURANCE */}
                <p
                  className={Styles.rowLabel}
                  style={{
                    fontSize: 'large',
                    fontStyle: 'bold',
                    width: '100%',
                  }}
                >
                  Insured
                </p>

                <div className={Styles.row}>
                  <p className={Styles.rowLabel}>Relationship</p>
                  <div style={{ width: '50%' }}>
                    <Select
                      inputId="secondaryrelationship"
                      options={metadata.RELATIONSHIP_OPTIONS}
                      value={
                        secondaryInsurance?.insured?.insured_relationship &&
                        !secondaryInsurance?.insured?.insured_relationship.label
                          ? {
                              label:
                                secondaryInsurance?.insured
                                  ?.insured_relationship,
                              value:
                                secondaryInsurance?.insured
                                  ?.insured_relationship,
                            }
                          : secondaryInsurance?.insured?.insured_relationship
                      }
                      autoBlur
                      onChange={(val) => {
                        let pinsurance = { ...secondaryInsurance }
                        pinsurance.insured.insured_relationship = val.label
                        setSecondaryInsurance(pinsurance)
                        if (val.label === 'Self') {
                          populateSelfData(2)
                        } else {
                          let pinsurance = { ...secondaryInsurance }
                          pinsurance.insured.insured_first_name = ''
                          pinsurance.insured.insured_last_name = ''
                          pinsurance.insured.insured_address_1 = ''
                          pinsurance.insured.insured_city = ''
                          pinsurance.insured.insured_state = null
                          pinsurance.insured.insured_zip = ''
                          pinsurance.insured.insured_sex = null
                          pinsurance.insured.insured_home_phone = ''
                          setSecondaryInsurance(pinsurance)
                        }
                      }}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: '#20A89233',
                          primary: '#20A892',
                        },
                      })}
                    />

                    {/* <input
                    placeholder="Enter Insurance Provider"
                    className={Styles.textInput}
                    style={{ width: '100%' }}
                    type="text"
                    value={secondaryInsurance?.insurance_provider}
                    onChange={(e) => {
                    let pinsurance = {...secondaryInsurance}
                      pinsurance.insurance_provider = e.target.value
                      setSecondaryInsurance(pinsurance)
                    }}
                  /> */}
                    {/* 
                <FontAwesomeIcon
                  onClick={() => {
                    this.props.toggleSidebar(true)
                    this.setState({ showInsuranceModal: true })
                  }}
                  icon={faEdit}
                  style={{
                    color: Constants.primaryTheme,
                    fontSize: 20,
                    marginLeft: 10,
                    cursor: 'pointer',
                  }}
                /> */}
                  </div>
                </div>

                <div className={Styles.row}>
                  <p className={Styles.rowLabel}>First Name</p>
                  <div style={{ width: '20%', marginRight: '2%' }}>
                    <input
                      placeholder="First Name"
                      className={Styles.textInput}
                      style={{ width: '100%' }}
                      type="text"
                      disabled={disabledSecondary}
                      value={secondaryInsurance?.insured?.insured_first_name}
                      onChange={(e) => {
                        let pinsurance = { ...secondaryInsurance }
                        pinsurance.insured.insured_first_name = e.target.value
                        setSecondaryInsurance(pinsurance)
                      }}
                    />
                  </div>
                  <p className={Styles.rowLabel}>Last Name</p>
                  <div style={{ width: '20%' }}>
                    <input
                      placeholder="Last Name"
                      className={Styles.textInput}
                      style={{ width: '100%' }}
                      type="text"
                      disabled={disabledSecondary}
                      value={secondaryInsurance?.insured?.insured_last_name}
                      onChange={(e) => {
                        let pinsurance = { ...secondaryInsurance }
                        pinsurance.insured.insured_last_name = e.target.value
                        setSecondaryInsurance(pinsurance)
                      }}
                    />
                  </div>
                </div>

                <div className={Styles.row}>
                  <p className={Styles.rowLabel}>Gender</p>
                  <div style={{ width: '50%' }}>
                    <Select
                      placeholder="Select Gender"
                      inputId="secondarygender"
                      isDisabled={disabledSecondary}
                      options={metadata.GENDER_OPTIONS}
                      value={
                        secondaryInsurance?.insured?.insured_sex &&
                        !secondaryInsurance?.insured?.insured_sex.label
                          ? {
                              label: secondaryInsurance?.insured?.insured_sex,
                              value: secondaryInsurance?.insured?.insured_sex,
                            }
                          : secondaryInsurance?.insured?.insured_sex
                      }
                      autoBlur
                      onChange={(val) => {
                        let pinsurance = { ...secondaryInsurance }
                        pinsurance.insured.insured_sex = val.label
                        setSecondaryInsurance(pinsurance)
                      }}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: '#20A89233',
                          primary: '#20A892',
                        },
                      })}
                    />
                  </div>
                </div>
                <div className={Styles.row}>
                  <p className={Styles.rowLabel}>Phone</p>
                  <div style={{ width: '50%' }}>
                    <input
                      placeholder="Enter Insured Phone"
                      className={Styles.textInput}
                      style={{ width: '100%' }}
                      type="text"
                      disabled={disabledSecondary}
                      value={secondaryInsurance?.insured?.insured_home_phone}
                      onChange={(e) => {
                        let pinsurance = { ...secondaryInsurance }
                        pinsurance.insured.insured_home_phone = e.target.value
                        setSecondaryInsurance(pinsurance)
                      }}
                    />
                  </div>
                </div>

                <div className={Styles.row}>
                  <p className={Styles.rowLabel}>Address</p>
                  <div style={{ width: '50%' }}>
                    <input
                      placeholder="Enter Insured Address"
                      className={Styles.textInput}
                      style={{ width: '100%' }}
                      type="text"
                      disabled={disabledSecondary}
                      value={secondaryInsurance?.insured?.insured_address_1}
                      onChange={(e) => {
                        let pinsurance = { ...secondaryInsurance }
                        pinsurance.insured.insured_address_1 = e.target.value
                        setSecondaryInsurance(pinsurance)
                      }}
                    />
                  </div>
                </div>

                <div className={Styles.row}>
                  <p className={Styles.rowLabel}>City</p>
                  <div style={{ width: '50%' }}>
                    <input
                      placeholder="Enter Insured City"
                      className={Styles.textInput}
                      style={{ width: '100%' }}
                      type="text"
                      disabled={disabledSecondary}
                      value={secondaryInsurance?.insured?.insured_city}
                      onChange={(e) => {
                        let pinsurance = { ...secondaryInsurance }
                        pinsurance.insured.insured_city = e.target.value
                        setSecondaryInsurance(pinsurance)
                      }}
                    />
                  </div>
                </div>

                <div className={Styles.row}>
                  <p className={Styles.rowLabel}>Zip</p>
                  <div style={{ width: '50%' }}>
                    <input
                      placeholder="Enter Insured Zip"
                      className={Styles.textInput}
                      style={{ width: '100%' }}
                      type="text"
                      disabled={disabledSecondary}
                      value={secondaryInsurance?.insured?.insured_zip}
                      onChange={(e) => {
                        let pinsurance = { ...secondaryInsurance }
                        pinsurance.insured.insured_zip = e.target.value
                        setSecondaryInsurance(pinsurance)
                      }}
                    />
                  </div>
                </div>

                <div className={Styles.row}>
                  <p className={Styles.rowLabel}>State</p>
                  <div style={{ width: '50%' }}>
                    <Select
                      placeholder="Select State"
                      inputId="secondarystate"
                      isDisabled={disabledSecondary}
                      options={metadata.stateResidence}
                      value={
                        secondaryInsurance?.insured?.insured_state &&
                        !secondaryInsurance?.insured?.insured_state.label
                          ? {
                              label: secondaryInsurance?.insured?.insured_state,
                              value: secondaryInsurance?.insured?.insured_state,
                            }
                          : secondaryInsurance?.insured?.insured_state
                      }
                      isMulti={false}
                      isSearchable
                      autoBlur
                      onChange={(val) => {
                        let pinsurance = { ...secondaryInsurance }
                        pinsurance.insured.insured_state = val.abbreviation
                        setSecondaryInsurance(pinsurance)
                      }}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: '#20A89233',
                          primary: '#20A892',
                        },
                      })}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {/* <p
          onClick={() => {
            console.log('Show Secondary')
            setShowSecondaryInsurance(!showSecondaryInsurance)
          }}
          style={{
            textDecoration: 'underline',
            color: Constants.primaryTheme,
            cursor: 'pointer',
            marginLeft: 'auto',
          }}
        >
          {showSecondaryInsurance ? `` : `Secondary Insurance`}
          <FontAwesomeIcon
            icon={showSecondaryInsurance ? faArrowUp : faArrowDown}
            style={{
              color: Constants.primaryTheme,
              fontSize: 20,
              marginLeft: 10,
              cursor: 'pointer',
            }}
          />
        </p> */}
        {showMedicModal && (
          <Modal
            ariaHideApp={false}
            isOpen={showMedicModal}
            onRequestClose={() => {
              setShowMedicModal(false)
              // setShowSearchList(false)
              // setAddInsurance(false)
              // setActiveInsurance(1)
              // setSearchName('')
              // setSearchCity('')
              // setSearchAddress('')
              // setSearchList([])
            }}
            style={medicModalStyles}
            contentLabel="Modal"
          >
            {renderMedicModal()}
          </Modal>
        )}

        {showSearchList && (
          <Modal
            ariaHideApp={false}
            isOpen={showSearchList}
            onRequestClose={() => {
              setShowSearchList(false)
              setAddInsurance(false)
              setActiveInsurance(1)
              setSearchName('')
              setSearchCity('')
              setSearchAddress('')
              setSearchList([])
            }}
            style={customModalStyles}
            contentLabel="Modal"
          >
            {renderSearchList()}
          </Modal>
        )}
        <Button
          onClick={() => savePressed()}
          className={GlobalStyles.button}
          style={{ width: 200, margin: '5% auto' }}
          variant="primary"
        >
          Save
        </Button>
      </div>
    )
  }

  if (loading) return renderLoader()
  return renderContent()
}

export default PatientInsurance
