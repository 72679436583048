import React, { Component } from 'react'
import GlobalStyles from '../styles/global.module.scss'
import Styles from './styles/NotesModal.module.scss'
import Constants from '../../values.js'
import Config from "../../config";
import {ToastContainer, toast} from "react-toastify";
import moment from "moment";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import AppointmentNotes from './AppointmentNotes';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits, RefinementList, InfiniteHits, connectStateResults, connectSearchBox, connectCurrentRefinements  } from 'react-instantsearch-dom';
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Draggable from 'react-draggable';
import BSModal from "react-bootstrap/Modal";
import ModalDialog from 'react-bootstrap/ModalDialog';
import Select from "react-select";
const axios = require('axios');

const searchClient = algoliasearch(
  Config.ALGOLIA_APP_ID,
  Config.ALGOLIA_API_KEY,
);

const condnClient = {
  search(requests) {
    if (requests[0].params.query === "") {
      return [];
    }
    return searchClient.search(requests);
  },
};

const eventFire = (el, etype) => {
  if (el.fireEvent) {
    el.fireEvent('on' + etype);
  } else {
    var evObj = document.createEvent('Events');
    evObj.initEvent(etype, true, false);
    el.dispatchEvent(evObj);
  }
}

const ClearQuery = ({refine}) => {
  const onClick = ev => {
    refine('');
    ev.stopPropagation();
  };
  return <div onClick={onClick} style={{display: 'none'}}><ConnectedClearAllRefinements/></div>;
};

const ClearAllRefinements = ({refine, items}) => {
  const onClick = () => refine(items);
  return <button id='icdclear' onClick={onClick}>ClearAll</button>;
};

const ClearQueryAndRefinements = connectSearchBox(ClearQuery);
const ConnectedClearAllRefinements = connectCurrentRefinements(ClearAllRefinements);

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

class NotesModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      modalOpen: this.props?.modalIsOpen ? this.props?.modalIsOpen : false,
    }
  }


  componentDidMount() {
    if (!this.props.loggedIn) {
      this.props.history.push('/login')
      return
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props?.modalIsOpen !== prevProps?.modalIsOpen) {
      this.setState({ modalOpen: this.props?.modalIsOpen });
    }
  }


  render() {

    if(this.state.loading) {
      return (
        <div className={GlobalStyles.container} style={{overflow: 'hidden', maxHeight: '100vh'}}>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100%', height: '80vh'}}>
            <div className={GlobalStyles.loader} />
          </div>
        </div>
      )
    }

    return (
      <BSModal
        dialogAs={DraggableModalDialog}
        show={this.state.modalOpen}
        onHide={() => this.props.hideNotesModal()}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName={Styles.notesModalContainer}
        // style={{zIndex: 1000}}
      >
        <BSModal.Header
          closeButton
          style={{
            cursor: 'pointer',
          }}
        >
          <BSModal.Title
            style={{
              cursor: 'pointer',
              textAlign: 'center',
              width: '100%'
            }}
          >
            Add Assessment
          </BSModal.Title>
        </BSModal.Header>
        <BSModal.Body>
        <div style={{height: "70vh", width: '100%', position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', overflowY: 'scroll', overflow: 'auto'}}>
          <AppointmentNotes
            patientId={this.props.patientId}
            selectedPatient={this.props.selectedPatient}
            hideNotes={()=> this.props.hideNotesModal()}
            getUserCarePlan={()=> this.props.getUserCarePlan()}
            navigateFrom={'notesModal'}
            comingFrom={this.props?.comingFrom || 'notes_modal'}
            carePlanNotesData={this.props?.carePlanNotesData}
          />
        </div>
        </BSModal.Body>
        {/* <BSModal.Footer >
          <Button
            onClick={() => noteSubmit(isHealthCoach)}
            className={GlobalStyles.button}
            // style={{width: 140, position: 'absolute', bottom: 10}}
            variant="primary"
          >
            Save
          </Button>
        </BSModal.Footer> */}
      </BSModal>
    )
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedIn: state.userReducer.loggedIn,
    token: state.authReducer.token,
    docFirebaseAuthUser: state.userReducer.firebaseAuthUser,
    mongoUser: state.userReducer.mongoUser,
    adminId: state.userReducer.adminId,
    patientList: state.patientReducer.patientList,
    doctorPatientData: state.appointmentReducer.patientData,
    appointmentData: state.appointmentReducer.appointmentData,
    patient: state.patientReducer.patient
  }
}

const mapDispatchToProps = {  }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotesModal)

