import React, { Component } from 'react'
import Styles from './styles/AilaWebQuestions.module.scss'
import GlobalStyles from '../styles/global.module.scss'
import FancyField from 'react-fancy-field'
import values from '../../values'
import Button from 'react-bootstrap/Button'
import AilaLogo from '../../assets/images/aila_logo.png'
import Form from 'react-bootstrap/Form'
import Modal from 'react-modal'
import Progress_bar from '../../components/ProgressBar'
import algoliasearch from 'algoliasearch/lite'
import Config from '../../config'
import Select from 'react-select'
import * as Metadata from '../../metadata'
import firebase from '../../services/firebase'
import {
  addFirebaseUser,
  addFirebaseAuthUser,
  addPatient,
} from '../../redux/actions'
import { connect } from 'react-redux'
import OnboardingFooter from '../../components/OnboardingFooter'

const axios = require('axios')

const CCFM_STATES = {
  az: true,
  md: true,
  nj: true,
  ny: true,
  or: true,
  va: true,
  vt: true,
  wa: true,
  mn: true,
}

const AILA_STATES = {
  co: true,
  ca: true,
  fl: true,
  tx: true,
}

const customModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: 600,
    height: '40vh',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const questionSet = [
  'What state are you located in?',
  'I am seeking support with...',
]

const specialDocQuestion = 'Please specify which condition'

const answerSet = [
  Metadata.stateResidence,
  [
    { name: 'Autoimmune', isChecked: false },
    { name: 'Long Covid', isChecked: false },
    { name: 'Rheumatoid Arthritis', isChecked: false },
    { name: 'General Health', isChecked: false },
    { name: `Women's Health`, isChecked: false },
    { name: 'Urgent Care Issue', isChecked: false },
    { name: 'Mental Health', isChecked: false },
    { name: 'Health Coaching', isChecked: false },
    { name: 'Nutrition', isChecked: false },
    { name: 'General Health PCP', isChecked: false },
    { name: 'Specialist Visit', isChecked: false },
    { name: 'Primary Care Visit', isChecked: false },
    { name: 'Pharmacist Consult', isChecked: false },
  ],
]

class AilaOnboardingQuestions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedQuestion: 0,
      progressPercent: 0,
      patientAnswers: [
        {
          qn: 'What state are you located in?',
          value: '',
        },
        {
          qn: 'I am seeking support with...',
          value: answerSet[1],
        },
      ],
      patient_condition: '',
      fName: '',
      lName: '',
      successorUrl: '',
      error: false,
      errorString: '',
      showCoachingStatesModal: false,
    }
    this.renderMainContent = this.renderMainContent.bind(this)
    this.onNextPressed = this.onNextPressed.bind(this)
    this.onSubmitPressed = this.onSubmitPressed.bind(this)
    this.storeUsertoMongo = this.storeUsertoMongo.bind(this)
    this.saveDetailsToFirebase = this.saveDetailsToFirebase.bind(this)
    this.onPreviousPressed = this.onPreviousPressed.bind(this)
    this.getAnswerOptions = this.getAnswerOptions.bind(this)
    this.handleMultipleOptions = this.handleMultipleOptions.bind(this)
    this.handleRadioInput = this.handleRadioInput.bind(this)
    this.handleSelectInput = this.handleSelectInput.bind(this)
  }

  componentDidMount() {
    if (!this.props.patient) {
      this.props.history.push('/signup/patient')
      return
    }
  }

  onPreviousPressed() {
    if (this.state.selectedQuestion <= 0) this.setState({ selectedQuestion: 0 })
    else {
      this.setState({ selectedQuestion: this.state.selectedQuestion - 1 })
    }
  }

  onNextPressed() {
    if (this.state.selectedQuestion >= 1) this.setState({ selectedQuestion: 1 })
    else {
      this.setState({
        selectedQuestion: this.state.selectedQuestion + 1,
      })
    }
  }

  storeUsertoMongo = (firebaseID, token, toSave) => {
    let data = {
      firebase_id: firebaseID,
      programs: toSave?.programs,
    }
    let config = {
      method: 'post',
      headers: { Authorization: 'JWT ' + token, x_firebase_id: firebaseID },
      url: `${Config.BACKEND_URL}users`,
      data: data,
    }
    axios(config)
      .then((resp) => {
        console.log('saved mongo data')
      })
      .catch((err) => {
        console.log('error when saving data', err)
      })
  }

  saveDetailsToFirebase = (toSave) => {
    let { patient, token } = this.props
    let ccfmDeeplink = localStorage.getItem('ccfm')
    let dacPilotDeeplink = localStorage.getItem('dac')
    let raPilotDeeplink = localStorage.getItem('rapilot')

    let toSaveObject = {
      email: this.props?.location?.state?.email_id.trim(),
      firstName: this.state.fName.trim(),
      lastName: this.state.lName.trim(),
      uid: patient.uid,
      lastSignedIn: new Date(),
      platform: 'web',
      signup_platform: 'web',
    }

    if (ccfmDeeplink === 'yes') {
      toSaveObject.ccfm = true
      toSaveObject.userType = 'ccfm-patient'
    }

    if (dacPilotDeeplink === 'yes') {
      toSaveObject.dac = true
      toSaveObject.userType = 'dac-patient'
    }

    if (raPilotDeeplink === 'yes') {
      toSaveObject.rapilot = true
      toSaveObject.userType = 'ra-pilot-patient'
    }

    firebase
      .firestore()
      .collection('users')
      .doc(patient.uid)
      .get()
      .then(async (doc) => {
        if (doc.exists) {
          //user data is already present in firestore, do not save again
          firebase
            .firestore()
            .collection('users')
            .doc(patient.uid)
            .update(toSaveObject)
            .then(() => {
              console.log('updated user last signed in ')
              this.props.addFirebaseUser(toSaveObject)
              this.props.addPatient(toSaveObject, 'gray')
              this.storeUsertoMongo(patient.uid, token, toSave)
            })
            .catch((err) =>
              console.log('error when updating user last signed in', err),
            )
          // return
        } else {
          firebase
            .firestore()
            .collection('users')
            .doc(patient.uid)
            .set(toSaveObject)
            .then(() => {
              console.log('Saved user to firestore')
              this.props.addFirebaseUser(toSaveObject)
              this.props.addPatient(toSaveObject, 'gray')
              this.storeUsertoMongo(patient.uid, token, toSave)
            })
            .catch((err) => {
              console.log(
                'somethign went wrong when saving user to firestore',
                err,
              )
            })
        }
      })
  }

  onSubmitPressed() {
    let { patientAnswers, patient_condition, fName, lName } = this.state
    let temp1 = []
    let temp2 = []
    let temp3 = []

    let stateResidence = patientAnswers[0].value.abbreviation

    if (!patientAnswers[0].value || patientAnswers[0].value.length === 0) {
      this.setState({ error: true })
      this.setState({ errorString: 'Please enter your state of residence ' })
      console.log('STATE RESIDENCE ERROR')
      return
    }

    if (!fName || fName.length === 0) {
      this.setState({ error: true })
      this.setState({ errorString: 'Please enter your first name ' })
      console.log('FNAME ERROR')
      return
    }
    if (!lName || lName.length === 0) {
      this.setState({ error: true })
      this.setState({ errorString: 'Please enter your last name' })
      console.log('LNAME ERROR')
      return
    }

    patientAnswers[1].value.forEach((val) => {
      if (val.isChecked === true) temp1.push(val.name)
    })

    let data = {
      patient_id: this.props?.location?.state?.patient_id,
      programs: temp1,
      state_residence: patientAnswers[0].value,
    }
    console.log('FINAL ANSWER: ', data)

    this.saveDetailsToFirebase(data)

    // let url = Config.BACKEND_URL + 'web/questionnaires'
    // axios({
    //   method: 'post',
    //   url: url,
    //   data: data,
    // })
    //   .then(() => {
    //     console.log('saved questionnaire')
    //   })
    //   .catch((err) => {
    //     console.log('error when posting patient web quess', err)
    //   })

    // if (
    //   stateResidence === 'CO' ||
    //   (patientAnswers[7].value === 'Yes' &&
    //     (stateResidence === 'AZ' ||
    //       stateResidence === 'NJ' ||
    //       stateResidence === 'NY' ||
    //       stateResidence === 'OR' ||
    //       stateResidence === 'VA' ||
    //       stateResidence === 'VT' ||
    //       stateResidence === 'WA' ||
    //       stateResidence === 'MN'))
    // ) {
    if (!AILA_STATES[stateResidence?.toLowerCase()]) {
      this.setState({ showCoachingStatesModal: true })
    } else {
      this.props.history.push('/wix/aila/careteam', {
        stateResidence: stateResidence,
      })
    }
    // } else {
    //   this.props.history.push('/wix/aila-download', {
    //     patient_id: this.props?.location?.state?.patient_id,
    //     email_id: this.props?.location?.state?.email_id,
    //     patient_type: false,
    //   })
    // }

    // this.setState({
    //   selectedQuestion: 0,
    //   progressPercent: 0,
    //   patientAnswers: [
    //     {
    //       qn: 'What state are you located in?',
    //       value: '',
    //     },
    //     {
    //       qn: 'What are you interested in?',
    //       value: answerSet[1],
    //     },
    //   ],
    // })
  }

  handleSelectInput(val) {
    let tempQ = [...this.state.patientAnswers]
    tempQ[this.state.selectedQuestion].value = val
    this.setState({ patientAnswers: tempQ })
  }

  handleRadioInput(val) {
    let tempQ = [...this.state.patientAnswers]
    tempQ[this.state.selectedQuestion].value = val
    this.setState({ patientAnswers: tempQ })
  }

  handleMultipleOptions(value) {
    let tempq = this.state.patientAnswers[this.state.selectedQuestion].value
    tempq.forEach((item) => {
      if (item.name === value) item.isChecked = !item.isChecked
    })

    let temp = [...this.state.patientAnswers]
    temp[this.state.selectedQuestion].value = tempq

    this.setState({ patientAnswers: temp })
  }

  getAnswerOptions() {
    if (this.state.selectedQuestion === 0) {
      return (
        <div
          style={{
            margin: '2vh',
            // textAlign: 'left',
            fontSize: '3vh',
            // height: '40vh',
          }}
        >
          <Select
            placeholder="Select State of residence"
            inputId="state"
            options={Metadata.stateResidence}
            isMulti={false}
            isSearchable
            autoBlur
            onChange={(val) => {
              this.handleSelectInput(val)
              // console.log('STATE: ', val)
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        </div>
      )
    } else if (this.state.selectedQuestion === 1)
      return (
        <Form.Group
          controlId={questionSet[this.state.selectedQuestion]}
          style={{ margin: '2vh', textAlign: 'left', fontSize: '3vh' }}
        >
          {this.state.patientAnswers[this.state.selectedQuestion].value.map(
            (type) => {
              return (
                <>
                  <label class={Styles.checkContainer}>
                    {type.name}
                    <input
                      type="checkbox"
                      checked={type.isChecked}
                      onClick={() => {
                        // console.log('clocked')
                        this.handleMultipleOptions(type.name)
                      }}
                    />
                    <span class={Styles.checkmark}></span>
                  </label>
                </>
              )
            },
          )}
        </Form.Group>
      )
  }
  renderCustomModal() {
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          style={{
            fontSize: '20px',
            // marginTop: '4vh',
            margin: 'auto',
            color: values.primaryThemeDark,
            textAlign: 'center',
            fontFamily: 'Avenir',
            wordWrap: 'break-word',
            position: 'relative',
          }}
        >
          We don't currently have medical appointments available in{' '}
          {this.state.patientAnswers[0].value.label}. Our health coaches can see
          patients anywhere. If you would like to book with our health coach,
          please select an appointment with them.
        </p>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            // marginTop: '4vh',
            margin: 'auto',
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          <div
            style={{
              width: '50%',
              margin: '20px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              onClick={() => {
                this.props.history.push('/wix/aila/careteam', {
                  stateResidence:
                    this.state.patientAnswers[0].value.abbreviation,
                })
              }}
              className={GlobalStyles.button}
              disabled={this.state.modalLoading}
              style={{ width: 200 }}
              variant="primary"
            >
              Book Here
            </Button>
          </div>

          <div
            style={{
              width: '50%',
              margin: '20px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              onClick={() => {
                this.props.history.push('/wix/aila-download', {
                  patient_type: false,
                })
              }}
              className={GlobalStyles.button}
              disabled={this.state.modalLoading}
              style={{ width: 200, backgroundColor: 'gray' }}
              variant="primary"
            >
              No Thanks
            </Button>
          </div>
        </div>
      </div>
    )
  }

  renderMainContent = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          minHeight: '50vh',
          justifyContent: 'center',
          textAlign: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            width: '70%',
            alignSelf: 'center',
            marginTop: '4vh',
            justifyContent: 'center',
            overflow: 'hidden',
          }}
        >
          <p
            style={{
              fontSize: '4vh',
              margin: 'auto',
              color: values.primaryThemeDark,
              textAlign: this.state.selectedQuestion === 0 ? 'center' : 'left',
              fontWeight: 'bold',
              fontFamily: 'Avenir',
              wordWrap: 'break-word',
            }}
          >
            {questionSet[this.state.selectedQuestion]}
          </p>
          {/* )} */}
          {this.getAnswerOptions()}

          {this.state.selectedQuestion === 0 && (
            <div style={{ margin: '50px auto' }}>
              <p
                style={{
                  fontSize: '4vh',
                  margin: '10px auto',
                  color: values.primaryThemeDark,
                  textAlign: 'center',
                  fontWeight: 'bold',
                  fontFamily: 'Avenir',
                  wordWrap: 'break-word',
                }}
              >
                What is your name?
              </p>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '50%',
                  margin: '10px auto',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{ width: '50%', margin: '20px', textAlign: 'left' }}
                >
                  <FancyField
                    value={this.state.fName}
                    label="First Name"
                    name="fnameInput"
                    onChange={(val) => this.setState({ fName: val })}
                    placeholder="First Name"
                  />
                </div>

                <div
                  style={{ width: '50%', margin: '20px', textAlign: 'left' }}
                >
                  <FancyField
                    value={this.state.lName}
                    label="Last Name"
                    name="lnameInput"
                    onChange={(val) => this.setState({ lName: val })}
                    placeholder="Last Name"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
  render() {
    return (
      <div
        className={GlobalStyles.container}
        style={{
          overflow: 'hidden',
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'left',
            alignSelf: 'left',
            marginTop: '1vh',
          }}
        >
          <img
            alt="Aila Health"
            src={AilaLogo}
            style={{
              width: '15vh',
              height: '15vh',
              alignSelf: 'flex-start',
            }}
          />
        </div>

        {this.renderMainContent()}

        <Modal
          ariaHideApp={false}
          isOpen={this.state.showCoachingStatesModal}
          onRequestClose={() =>
            this.setState({ showCoachingStatesModal: false })
          }
          // style={customModalStyles}
          className={Styles.modal1Content}
          // style={paymentModalStyles}

          style={{
            overlay: {
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.44)',
            },
          }}
          contentLabel="Modal"
        >
          {this.renderCustomModal()}
        </Modal>

        <p
          style={{
            marginTop: 20,
            fontSize: 15,
            color: 'red',
            visibility: this.state.error ? 'visible' : 'hidden',
            textAlign: 'center',
          }}
        >
          {this.state.errorString}
        </p>

        <div
          style={{
            flex: 1,
            alignSelf: 'self-end',
            marginRight: '5vh',
          }}
        >
          <Button
            onClick={() => this.onPreviousPressed()}
            className={Styles.button2}
            style={{
              margin: '1vh',
              width: 200,
              color: '#20A892',
              fontWeight: 'bold',
              display: this.state.selectedQuestion === 0 ? 'none' : 'inline',
            }}
            variant="primary"
          >
            {'Previous'}
          </Button>

          <Button
            onClick={() => {
              this.state.selectedQuestion === questionSet.length - 1
                ? this.onSubmitPressed()
                : this.onNextPressed()
            }}
            className={GlobalStyles.button}
            style={{
              fontWeight: 'bold',
              margin: '1vh',
              width: 200,
            }}
            variant="primary"
          >
            {this.state.selectedQuestion === questionSet.length - 1
              ? 'Next'
              : 'Next'}
          </Button>
        </div>
        <OnboardingFooter />
      </div>
    )
  }
}

const mapStateToProps = (state /* , ownProps */) => {
  return {
    patient: state.patientReducer.patient,
    token: state.authReducer.token,
    firebaseAuthUser: state.userReducer.firebaseAuthUser,
  }
}

const mapDispatchToProps = {
  addFirebaseUser,
  addFirebaseAuthUser,
  addPatient,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AilaOnboardingQuestions)
