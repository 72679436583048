import React, {useState, useEffect}from 'react'
import Modal from "react-modal";
import Styles from "./styles/styles.module.scss";
import Constants from "../values";
import {Hits, InstantSearch, RefinementList, SearchBox} from "react-instantsearch-dom";
import Button from "react-bootstrap/Button";
import GlobalStyles from "../pages/styles/global.module.scss";
import algoliasearch from "algoliasearch/lite";
import Config from "../config";
const searchClient = algoliasearch(
	Config.ALGOLIA_APP_ID,
	Config.ALGOLIA_API_KEY,
);

const MODAL_STYLES = {
	overlay: {
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: 'rgba(0, 0, 0, 0.44)'
	},
	content : {
		width: '50%',
		height: '90%',
		padding: 0,
		top                   : '50%',
		left                  : '50%',
		right                 : 'auto',
		bottom                : 'auto',
		marginRight           : '-50%',
		transform             : 'translate(-50%, -50%)',
		zIndex: 2
	}
};

const LoincSearchModal = (props) => {
	const [selectedLabs, setSelectedLabs] = useState([])
	useEffect(() => {
		setSelectedLabs(props?.selectedLabs || [])
	}, [props])

	const onLabAdded = (entry) => {
		let temp = [...selectedLabs]
		let present = false
		temp.forEach(each => {
			if(each.LOINC_NUM === entry.LOINC_NUM)
				present = true
		})

		if(!present) {
			temp.push(entry)
			setSelectedLabs(temp)
		}
	}

	const onLabDeleted = (index) => {
		let temp = [...selectedLabs]
		temp.splice(index,1)
		setSelectedLabs(temp)
	}

	return (
		<Modal
			ariaHideApp={false}
			isOpen={props.showModal}
			// onAfterOpen={afterOpenModal}
			onRequestClose={props.hideModal}
			style={MODAL_STYLES}
			contentLabel="Modal"
		>
			<div className={Styles.searchModalContentWrapper}>
				<p className={GlobalStyles.closeModalBtn} onClick={props.hideModal}>X</p>
				<h4 style={{color: Constants.primaryTheme, textAlign: 'center'}}>Search Biomarkers</h4>


				<div className={Styles.selectedLabsWrapper}>
					{selectedLabs && selectedLabs.map((x,i) => {
						return (
							<div key={i.toString()} className={Styles.selectedLabEntry}>
								<p>{x.SHORTNAME}</p>
								<p
									onClick={() => onLabDeleted(i)}
									className={Styles.selectedLabEntryDelete}>X</p>
							</div>
						)
					})}
				</div>


				<div className={Styles.searchElementsWrapper}>
					<InstantSearch searchClient={searchClient} indexName={'LOINC_CODES'}>
						<div style={{position: 'absolute', width: '75%'}}>
							<SearchBox />
							<div style={{overflowY: 'scroll', height: 300, backgroundColor: '#F1F1F1'}}>
								<RefinementList limit={10} attribute={'LOINC_CODES'}/>
								<Hits hitComponent={(props) => Hit(props, onLabAdded)} />
							</div>
						</div>
						{/* <CustomStateResults/> */}
					</InstantSearch>
				</div>


				<Button
					onClick={() => props.onLabsSelected(selectedLabs)}
					className={GlobalStyles.button}
					style={{width: 140, position: 'absolute', bottom: 10}}
					variant="primary"
				>
					Add
				</Button>

			</div>
		</Modal>
	)
}

const Hit = (props, onLabAdded) => {
	let {hit} = props

	const handleClick = () => {
		onLabAdded(hit)
	}

	return (
		<>
			<div onClick={(e) => handleClick(e)} className={Styles.searchResultWrapper}>
				<div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
					<p style={{fontWeight: 'bold', width: '22%'}}>Short Name</p>
					<p style={{marginRight: 8}}>:</p>
					<p style={{width: '75%'}}>{hit.SHORTNAME}</p>
				</div>

				<div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
					<p style={{fontWeight: 'bold', width: '22%'}}>Long Name</p>
					<p style={{marginRight: 8}}>:</p>
					<p style={{width: '75%'}}>{hit.LONG_COMMON_NAME}</p>
				</div>

			</div>
			<hr/>
		</>
	)
};

export default LoincSearchModal;
