module.exports = {
  primaryTheme: '#20A892',
  primaryThemeFaded: '#20A89288',
  primaryThemeMedium: '#20A89266',
  primaryThemeFadedDeep: '#20A89244',
  primaryThemeDark: '#066D6F',
  contentBackground: '#F3F3F3',
  lightGray: '#c8c8c8',
  darkGray: '#808080',
  borderRadius: 20,
  graphLineOne: '#282828',
  graphLineTwo: '#D3D3D3',
  graphLineThree: '#0B93D9',
  graphReferenceColors: ['green', '#FFCE03', '#FD9A01', '#FD6104', '#FF2C05', '#d12100'],
  headerSize: 24
}
