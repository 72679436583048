import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import FancyField from 'react-fancy-field'
import { Link, useHistory } from 'react-router-dom'
import Styles from './styles/AilaWebSignup.module.scss'
import GlobalStyles from '../../styles/global.module.scss'
import values from '../../../values'
import Constants from '../../../values'
import AilaLogo from '../../../assets/images/aila_logo.png'
import Config from '../../../config'
import GoogleLogo from '../../../assets/images/google.png'
import AppleLogo from '../../../assets/images/apple.png'
import ReactGA from 'react-ga'

import { useSelector, useDispatch } from 'react-redux'
import {
  addAuthToken,
  addFirebaseUser,
  addFirebaseAuthUser,
  addPatient,
  fromWebFlow,
} from '../../../redux/actions'

import {
  firebase,
  auth,
  googleProvider,
  appleProvider,
  analytics,
} from '../../../services/firebase'
import TagManager from 'react-gtm-module'
import OnboardingFooter from '../../../components/OnboardingFooter'
const axios = require('axios')

export default function AilaWebSignup(props) {
  const [email, setEmail] = useState('')
  const [fname, setFname] = useState('')
  const [lname, setLname] = useState('')
  const [phone, setPhone] = useState()
  const [pwd, setPwd] = useState('')
  const [confirmpwd, setConfirmpwd] = useState('')
  const [token, setToken] = useState('')
  const [currentUser, setCurrentUser] = useState('')
  const [termsChecked, setTermsChecked] = useState({
    messages: false,
    policy: false,
    medical_release: false,
    payments: false,
  })
  const [terms2Checked, setTerms2Checked] = useState(false)
  const [error, setError] = useState(false)
  const [errorString, setErrorString] = useState('')
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()

  // ReactGA.initialize(Config.GOOGLE_ADS_ID);
  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname + window.location.search, 'AW-607596551', 'aila-web-signup');
  // }, [])
  useEffect(() => {
    console.log(
      'WEB QUESTIONNAIRE FROM:',
      props?.location?.state?.questionnaire_data,
    )
    const tagManagerArgs = {
      gtmId: Config.GOOGLE_ADS_ID,
      dataLayer: {
        env: process.env.ENV,
        pageCategory: 'aila-web-signup',
      },
    }
    TagManager.initialize(tagManagerArgs)
  }, [])

  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  // Token from backend
  const getAuthToken = async (currentUser) => {
    if (!currentUser) {
      console.log('Invalid user')
      return { error: true }
    }

    const authUrl = `${Config.BACKEND_URL}authorization`
    try {
      const response = await axios({
        method: 'get',
        url: authUrl,
        headers: { x_firebase_id: currentUser.uid },
      })
      if (response.status === 200) {
        setToken(response.data.token)
        dispatch(addAuthToken(response.data.token))

        return { error: false, tokenVal: response.data.token }
      }
      console.log('error', response)
      return { error: true }
    } catch (err) {
      console.log('error when getting auth token', err)
      return { error: true, errorString: 'error when getting auth token' }
    }
  }

  const saveDetailsToFirebase = (user_uid, google_id, oauthType) => {
    let ccfmDeeplink = localStorage.getItem('ccfm')
    let dacPilotDeeplink = localStorage.getItem('dac')
    let raPilotDeeplink = localStorage.getItem('rapilot')
    let marketingTitle = localStorage.getItem('marketing_title') || null
    const toSaveObject = {
      email: google_id ? google_id : email.trim(),
      uid: user_uid,
      lastSignedIn: new Date(),
      platform: 'web',
      signup_platform: 'web',
      phoneNumber: phone || '',
    }

    if (marketingTitle) {
      toSaveObject.marketingTitle = marketingTitle
    }

    if (oauthType) {
      toSaveObject.oauthType = oauthType
    }

    if (ccfmDeeplink === 'yes') {
      toSaveObject.ccfm = true
      toSaveObject.userType = 'ccfm-patient'
    }

    if (dacPilotDeeplink === 'yes') {
      toSaveObject.dac = true
      toSaveObject.userType = 'dac-patient'
    }

    if (raPilotDeeplink === 'yes') {
      toSaveObject.rapilot = true
      toSaveObject.userType = 'ra-pilot-patient'
    }

    firebase
      .firestore()
      .collection('users')
      .doc(user_uid)
      .set(toSaveObject)
      .then((data) => {
        console.log('saved-firebase', data)
        analytics.logEvent('Web_Signup')
        firebase
          .firestore()
          .collection('users')
          .doc(user_uid)
          .get()
          .then(async (doc) => {
            if (doc && doc.data()) {
              const data = doc.data()
              console.log('user', data)
              dispatch(addPatient(data, 'gray'))
              dispatch(addFirebaseUser(data))
              dispatch(addFirebaseAuthUser(user_uid))
              dispatch(fromWebFlow(true))

              if (props?.location?.state?.questionnaire_data) {
                let url = Config.BACKEND_URL + 'web/questionnaires'
                axios({
                  method: 'post',
                  url: url,
                  data: {
                    ...props?.location?.state?.questionnaire_data,
                    patient_id: user_uid,
                  },
                })
                  .then(() => {
                    console.log('saved questionnaire')
                  })
                  .catch((err) => {
                    console.log('error when posting patient web quess', err)
                    history.push('/wix/aila-web-questions', {
                      patient_id: user_uid,
                      email_id: data.email,
                      token: token,
                    })
                  })
              }

              setLoading(true)

              history.push('/wix/aila-web-questions', {
                patient_id: user_uid,
                email_id: data.email,
                token: token,
              })
              return
            }
          })
      })
      .catch((err) => {
        console.log('somethign went wrong when saving user to firestore', err)
      })
  }
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  // Sign in with google
  const signinwithApple = async () => {
    auth
      .signInWithPopup(appleProvider)
      .then((result) => {
        // The signed-in user info.
        const user = result.user

        console.log(user)

        setCurrentUser(user.uid)

        // console.log('google user logged in', user.uid)
        firebase
          .firestore()
          .collection('users')
          .doc(user.uid)
          .get()
          .then(async (doc) => {
            if (doc.exists) {
              setError(true)
              setErrorString(
                'The email address is already in use by another account.',
              )
              // console.log('doc already exists, so updating')
              //user data is already present in firestore, do not save again
              firebase
                .firestore()
                .collection('users')
                .doc(user.uid)
                .update({
                  lastSignedIn: new Date(),
                })
                .then(() => {
                  console.log('updated user last signed in ')
                })
                .catch(() =>
                  console.log('error when updating user last signed in'),
                )
              return
            } else {
              try {
                const tokenResp = await getAuthToken(user)
                if (tokenResp.error === true) {
                  setError(true)
                  setErrorString('Token Error')
                } else {
                  console.log('Success token auth: ', tokenResp)
                  setLoading(true)
                  saveDetailsToFirebase(user.uid, user.email, 'apple')
                }
              } catch (err) {
                setError(true)
                setErrorString(err.message)
                console.log(err)
                onFinishFailed(err.message)
              }
            }
          })

        // ...
      })
      .catch((error) => {
        console.log(error)
        // Handle Errors here.
        const errorCode = error.code
        const errorMessage = error.message
        // The email of the user's account used.
        const email = error.email
        // The credential that was used.
        // const credential = OAuthProvider.credentialFromError(error)

        // ...
      })
  }

  // Sign in with google
  const signin = async () => {
    googleProvider.setCustomParameters({
      prompt: 'select_account',
    })
    auth
      .signInWithPopup(googleProvider)
      .then((result) => {
        // console.log('USER: ', result)
        // console.log('TOKEN: ', result.credential.idToken)
        // console.log('EMAIL: ', result.user.email)
        let user = result.user
        setCurrentUser(user.uid)

        // console.log('google user logged in', user.uid)
        firebase
          .firestore()
          .collection('users')
          .doc(user.uid)
          .get()
          .then(async (doc) => {
            if (doc.exists) {
              setError(true)
              setErrorString(
                'The email address is already in use by another account.',
              )
              // console.log('doc already exists, so updating')
              //user data is already present in firestore, do not save again
              firebase
                .firestore()
                .collection('users')
                .doc(user.uid)
                .update({
                  lastSignedIn: new Date(),
                })
                .then(() => {
                  console.log('updated user last signed in ')
                })
                .catch(() =>
                  console.log('error when updating user last signed in'),
                )
              return
            } else {
              try {
                const tokenResp = await getAuthToken(user)
                if (tokenResp.error === true) {
                  setError(true)
                  setErrorString('Token Error')
                } else {
                  console.log('Success token auth: ', tokenResp)
                  setLoading(true)
                  saveDetailsToFirebase(user.uid, user.email, 'google')
                }
              } catch (err) {
                setError(true)
                setErrorString(err.message)
                console.log(err)
                onFinishFailed(err.message)
              }
            }
          })
      })
      .catch((error) => {
        console.log(error)
        // Handle Errors here.
        const errorCode = error.code
        const errorMessage = error.message
        // The email of the user's account used.
        const email = error.email
        // The credential that was used.
        // const credential = OAuthProvider.credentialFromError(error)

        // ...
      })
  }

  // Firebase Login
  async function signup(email, password) {
    const user = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
    let error = false
    if (user.user.uid) {
      setCurrentUser(user.user.uid)
      // console.log('Logged in PATIENT')
    } else {
      error = true
      console.log('ERROR LOGGING IN')
    }
    return { userData: user, errorLogin: error }
  }

  const onSignupPressed = async () => {
    if (!email || email.length === 0 || !validateEmail(email)) {
      setError(true)
      setErrorString('Please enter a valid email address')
      console.log('EMAIL ERROR')
      return
    }

    // if (!fname || fname.length === 0) {
    //   setError(true)
    //   setErrorString('Please enter your first name')
    //   console.log('FNAME ERROR')
    //   return
    // }
    // if (!lname || lname.length === 0) {
    //   setError(true)
    //   setErrorString('Please enter your last name')
    //   console.log('LNAME ERROR')
    //   return
    // }

    if (!pwd || pwd.length < 6) {
      setError(true)
      setErrorString('The password must be at least 6 characters')
      console.log('PWD ERROR')
      return
    }

    if (!confirmpwd || (confirmpwd.length < 6 && confirmpwd === pwd)) {
      setError(true)
      setErrorString('The confirm password must be same as your password')
      console.log('CONFIRMPWD ERROR')
      return
    }

    if (!phone || !phone.length) {
      setError(true)
      setErrorString('Please enter a valid phone number')
      console.log('PHONE ERROR')
      return
    }

    if (Object.values(termsChecked).every((value) => value === true)) {
      console.log('all true')
    } else {
      setError(true)
      setErrorString('Please read and accept the terms of use ')
      console.log('TERMS OF USE ERROR')
      return
    }

    try {
      const { userData, errorLogin } = await signup(email, pwd)
      if (!errorLogin) {
        const tokenResp = await getAuthToken(userData.user)
        if (tokenResp.error === true) {
          setError(true)
          setErrorString('Token Error')
        } else {
          // console.log('Success token auth: ', tokenResp)
          setLoading(true)
          saveDetailsToFirebase(userData.user.uid)
        }
      } else {
        setError(true)
        setErrorString('You Do Not Have Access!!')
      }
    } catch (err) {
      setError(true)
      setErrorString(err.message)
      console.log(err)
      onFinishFailed(err.message)
    }
  }

  const renderLoader = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '100%',
        height: '100vh',
      }}
    >
      <div className={GlobalStyles.loader} />
    </div>
  )

  if (loading) return renderLoader()

  return (
    <div
      className={GlobalStyles.container}
      style={{
        overflow: 'hidden',
        margin: '0 auto',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div className={Styles.container}>
        <img
          alt="Aila Health"
          src={AilaLogo}
          style={{
            width: '15vh',
            height: '15vh',
            alignSelf: 'flex-start',
            marginRight: '-10vh',
          }}
        />

        <div className={Styles.subContainer}>
          <div
            style={{
              width: '100%',
              alignSelf: 'center',
              marginTop: '5vh',
              marginBottom: '2vh',
            }}
          >
            <h2 className={Styles.heading}>We're glad you're here</h2>
            <br />
            <p
              className={Styles.heading}
              style={{
                fontSize: 'large',
                fontWeight: 'normal',
              }}
            >
              Let's see if Aila Health is right for you.
              <br />
              Create a free account to learn more.
            </p>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              width: '90%',
            }}
          >
            <Button
              className={Styles.button}
              onClick={() => signinwithApple()}
              style={{
                shadowColor: '#000',
                backgroundColor: '#fff',
                shadowOffset: { width: 0, height: 2 },
                shadowOpacity: 0.5,
                shadowRadius: 2,
                color: '#000000',
                width: '50%',
                borderColor: '#20A892',
              }}
              variant="primary"
            >
              <img alt="Apple" src={AppleLogo} className={Styles.logo} />
              Sign up with Apple
            </Button>

            <Button
              className={Styles.button}
              onClick={() => signin()}
              style={{
                shadowColor: '#000',
                backgroundColor: '#fff',
                shadowOffset: { width: 0, height: 2 },
                shadowOpacity: 0.5,
                shadowRadius: 2,
                color: '#000000',
                width: '50%',
                borderColor: '#20A892',
              }}
              variant="primary"
            >
              <img alt="Google" src={GoogleLogo} className={Styles.logo} />
              Sign up with Google
            </Button>
          </div>

          <div className={Styles.mainField} style={{ color: '#A6A6A6' }}>
            <p className={Styles.borderLine} />
            or
            <p className={Styles.borderLine} />
          </div>

          <div className={Styles.mainField}>
            <div style={{ width: '50%', margin: '20px' }}>
              <FancyField
                value={email}
                label="Email"
                required={false}
                name="emailInput"
                //   autoFocus
                onChange={(val) => setEmail(val)}
                placeholder="Enter your email... "
              />
            </div>

            <div style={{ width: '50%', margin: '20px' }}>
              <FancyField
                value={phone}
                label="Phone Number"
                required={false}
                name="phoneInput"
                type={Number}
                onChange={(val) => setPhone(val)}
                placeholder="Enter your phone number... "
              />
            </div>
          </div>

          <div className={Styles.mainField} style={{ marginTop: '4vh' }}>
            <div style={{ width: '50%', margin: '20px' }}>
              <FancyField
                value={pwd}
                label="Password"
                required={false}
                type="password"
                name="pwdInput"
                onChange={(val) => setPwd(val)}
                placeholder="Create a secure password... "
              />
            </div>

            <div style={{ width: '50%', margin: '20px' }}>
              <FancyField
                value={confirmpwd}
                label="Confirm Password"
                required={false}
                type="password"
                name="pwdInput"
                onChange={(val) => setConfirmpwd(val)}
                placeholder="Confirm your password... "
              />
            </div>
          </div>

          <div
            className={Styles.mainField}
            style={{ flexDirection: 'column', textAlign: 'justify' }}
          >
            {/* <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                // marginTop: '8vh',
                justifyContent: 'flex-start',
                marginRight: 'auto',
              }}
            >
              <p
                style={{
                  color: Constants.darkGray,
                  width: '100%',
                  overflowWrap: 'break-word',
                  display: 'block',
                }}
              >
                By checking the box below, you agree to this statement:
              </p>      
            </div> */}

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                // marginTop: '2vh',
                justifyContent: 'flex-start',
              }}
            >
              <label className={Styles.checkContainer}>
                <p
                  style={{
                    color: Constants.darkGray,
                    width: '100%',
                    overflowWrap: 'break-word',
                    display: 'block',
                  }}
                >
                  I authorize the release of any medical or other information
                  necessary to process claims for my medical visits. I also
                  request payment of government benefits either to myself or to
                  the party who accepts assignment. I authorize payment of
                  medical benefits to the undersigned physician or supplier for
                  services rendered and described on the claim being submitted
                  to the insurance carrier.
                </p>
                <input
                  type="checkbox"
                  checked={termsChecked.medical_release}
                  onClick={() => {
                    let temp = { ...termsChecked }
                    temp.medical_release = !temp.medical_release
                    temp.payments = !temp.payments
                    setTermsChecked(temp)
                  }}
                />
                <span className={Styles.checkmark}></span>
              </label>
            </div>

            {/* <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                // marginTop: '8vh',
                justifyContent: 'flex-start',
              }}
            >
              <label className={Styles.checkContainer}>
                <p
                  style={{
                    color: Constants.darkGray,
                    width: '100%',
                    overflowWrap: 'break-word',
                    display: 'block',
                  }}
                >
                  I authorize payment of medical benefits to the undersigned
                  physician or supplier for services rendered and described on
                  the claim being submitted to the insurance carrier.
                </p>
                <input
                  type="checkbox"
                  checked={termsChecked.payments}
                  onClick={() => {
                    let temp = { ...termsChecked }
                    temp.payments = !temp.payments
                    setTermsChecked(temp)
                  }}
                />
                <span className={Styles.checkmark}></span>
              </label>
            </div> */}
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                // marginTop: '2vh',
                justifyContent: 'flex-start',
              }}
            >
              <label className={Styles.checkContainer}>
                <p
                  style={{
                    color: Constants.darkGray,
                    width: '100%',
                    overflowWrap: 'break-word',
                    display: 'block',
                  }}
                >
                  I consent to emails and SMS (which may be automated) regarding
                  my treatment plan, as well as updates regarding Aila Health’s
                  products and services (message and data rates may apply).
                </p>
                <input
                  type="checkbox"
                  checked={termsChecked.messages}
                  onClick={() => {
                    let temp = { ...termsChecked }
                    temp.messages = !temp.messages
                    setTermsChecked(temp)
                  }}
                />
                <span className={Styles.checkmark}></span>
              </label>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                // marginTop: '4vh',
                // marginLeft: '20vh',
                justifyContent: 'flex-start',
              }}
            >
              <label className={Styles.checkContainer}>
                <p
                  style={{
                    color: Constants.darkGray,
                    wordWrap: 'break-word',
                    width: '100%',
                  }}
                >
                  By clicking get started, I have read and consent to the terms
                  of Aila Health's{' '}
                  <a
                    href={Config.PRIVACY_POLICY_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecorationLine: 'underline' }}
                  >
                    HIPAA Notice, Privacy Policy
                  </a>{' '}
                  ,{' '}
                  <a
                    href={Config.TOS_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecorationLine: 'underline' }}
                  >
                    Terms of Service
                  </a>{' '}
                  and{' '}
                  <a
                    href={Config.TELEHEALTH_POLICY}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecorationLine: 'underline' }}
                  >
                    Telehealth Consent
                  </a>{' '}
                </p>
                <input
                  type="checkbox"
                  checked={termsChecked.policy}
                  onClick={() => {
                    let temp = { ...termsChecked }
                    temp.policy = !temp.policy
                    setTermsChecked(temp)
                  }}
                />
                <span className={Styles.checkmark}></span>
              </label>
            </div>
          </div>

          <p
            style={{
              marginTop: 20,
              fontSize: 12,
              color: 'red',
              visibility: error ? 'visible' : 'hidden',
              textAlign: 'center',
            }}
          >
            {errorString}
          </p>

          <Button
            onClick={() => onSignupPressed()}
            className={GlobalStyles.button}
            style={{
              marginTop: '2vh',
              width: '15vw',
              height: '6vh',
              alignSelf: 'center',
              fontSize: 'larger',
            }}
            variant="primary"
          >
            Get Started
          </Button>

          <Link
            // onClick={(e) => {
            //   e.preventDefault()
            //   window.location.href = Config.DOWNLOAD_LINK
            // }}
            to={{
              pathname: `/login`,
            }}
          >
            <p
              style={{
                fontSize: '18px',
                marginTop: 30,
                color: values.primaryThemeDark,
                textDecoration: 'underline',
                cursor: 'pointer',
                textAlign: 'center',
              }}
            >
              I already have an account
            </p>
          </Link>
          <OnboardingFooter />
        </div>
      </div>
    </div>
  )
}
