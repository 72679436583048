import React, { Component, useEffect } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import moment from 'moment'
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend } from 'recharts'
import Styles from './styles/Progress.module.scss'
import Helpers from '../../../helpers/Global.js'
import GlobalStyles from '../../styles/global.module.scss'
import { addPatient, updatePatientData } from '../../../redux/actions'
import Config from '../../../config'
import Constants from '../../../values'
const axios = require('axios')

const PATIENT_OUTCOMES = [
  { label: 'Physical Activity', value: 'physical' },
  { label: 'Healthy Eating', value: 'eating' },
  { label: 'Stress', value: 'stress' },
  { label: 'Mood', value: 'mood' },
  { label: 'Inflammation', value: 'inflammation' },
  { label: 'Energy Level', value: 'energy_level' },
  { label: 'Air Pressure', value: 'air_pressure' },
]

class SymptomsProgress extends Component {
  constructor(props) {
    super(props)
    this.state = {
      timeline: { label: 'Complete History', value: 'complete' },
      progressData: null,
      loading: true,
      graphOptions: null,
      selectedGraphOptions: null,
      symptomLoading: false,
      notesMap: {},
      checked: false,
    }
    this.getSymptomProgressData = this.getSymptomProgressData.bind(this)
    this.getNotesData = this.getNotesData.bind(this)
    this.prepareNotesData = this.prepareNotesData.bind(this)
  }

  componentDidMount() {
    console.log(this.props.patient)
    this.getNotesData('complete')
    this.getPreferences()
  }

  getPreferences() {
    if (!this.props.patient) {
      this.props.history.push('/patient/list')
      return
    }
    const { patient } = this.props
    const { symptom_progress_preferences } = patient
    if (symptom_progress_preferences && symptom_progress_preferences.checked) {
      this.setState({
        selectedGraphOptions: symptom_progress_preferences.symptomOptions || [],
        timeline: symptom_progress_preferences.timeline || {
          label: 'Complete History',
          value: 'complete',
        },
        checked: symptom_progress_preferences.checked,
      })
      const timeline = symptom_progress_preferences.timeline
        ? symptom_progress_preferences.timeline.value
        : 'complete'
      // this.getSymptomProgressData(timeline)
      this.getSymptomProgressData(timeline)
      // this.getSymptomProgressData(timeline)
    } else {
      this.setState({
        selectedGraphOptions: null,
        checked: false,
        timeline: { label: 'Complete History', value: 'complete' },
      })
      this.getSymptomProgressData('complete')
    }
  }

  getNotesData(timeline) {
    // get data
    const { patient, token } = this.props
    const url = `${Config.BACKEND_URL}patients/notes?timeline=${timeline}`
    axios({
      method: 'get',
      headers: { Authorization: `JWT ${token}`, x_firebase_id: patient.uid },
      url,
    })
      .then(({ data }) => {
        this.prepareNotesData(data)
      })
      .catch((err) => {
        console.log('error when getting patient symptoms progress data', err)
      })
  }

  getSymptomProgressData(timeline) {
    this.setState({ symptomLoading: true })
    // get data
    const { patient, token } = this.props
    const url = `${Config.BACKEND_URL}user/progress?timeline=${timeline}`
    axios({
      method: 'get',
      headers: { Authorization: `JWT ${token}`, x_firebase_id: patient.uid },
      url,
    })
      .then(({ data }) => {
        if (data && data.progress) data.progress.reverse()
        console.log('got symptom data', data)
        this.setState({ symptomLoading: false })
        // self.setState({symptomProgressData: data, loading: false})
        this.prepareProgressData(data)
      })
      .catch((err) => {
        console.log('error when getting patient symptoms progress data', err)
      })
  }

  prepareNotesData(data) {
    if (data) {
      const map = {}
      data.forEach((note) => {
        const date = moment(note.created_at).format('MM/DD/YYYY')
        map[date] = note
      })
      this.setState({ notesMap: map })
    }
  }

  prepareProgressData(data) {
    if (!data) return
    const { symptoms } = data
    const symptomOptions = []
    symptoms.forEach((symptom) => {
      symptomOptions.push({ label: symptom.symptom, value: symptom.node })
    })

    const graphOptions = symptomOptions.concat(PATIENT_OUTCOMES)
    const { progress } = data
    progress.forEach((each) => {
      const symptomLevels = each.symptom_levels || []
      symptomLevels.forEach((symptomLevel) => {
        each[symptomLevel._id] = symptomLevel.level
      })
    })

    this.setState({
      progressData: data.progress,
      graphOptions,
      loading: false,
    })
  }

  onChecked(e) {
    const { checked } = e.target
    this.setState({ checked })

    const { docFirebaseAuthUser, patient, token } = this.props
    let tempPatient = patient

    if (checked) {
      this.props.updatePatientData(
        {
          doctor_id: docFirebaseAuthUser.uid,
          patient_id: patient.uid,
          symptom_progress_preferences: {
            symptomOptions: this.state.selectedGraphOptions,
            timeline: this.state.timeline,
            checked: true,
          },
        },
        token,
      )
      tempPatient.symptom_progress_preferences.symptomOptions =
        this.state.selectedGraphOptions
      tempPatient.symptom_progress_preferences.timeline = this.state.timeline
      addPatient(tempPatient)
    } else {
      this.props.updatePatientData(
        {
          doctor_id: docFirebaseAuthUser.uid,
          patient_id: patient.uid,
          symptom_progress_preferences: {
            checked: false,
          },
        },
        token,
      )
    }
  }

  onSelectEntryChanged(stateOptionKey, val) {
    this.setState({ selectedGraphOptions: val })
    if (this.state.checked) {
      const { docFirebaseAuthUser, patient, token } = this.props
      let tempPatient = patient
      const key = `symptom_progress_preferences.${stateOptionKey}`
      this.props.updatePatientData(
        {
          doctor_id: docFirebaseAuthUser.uid,
          patient_id: patient.uid,
          [key]: val,
        },
        token,
      )
      tempPatient.symptom_progress_preferences.symptomOptions = val
      addPatient(tempPatient)
    }
  }

  onTimelineChanged(val) {
    this.setState({ timeline: val })
    const { timeline } = this.state

    if (timeline && timeline.value !== val.value) {
      // timeline changed
      const { patient, token } = this.props
      this.setState({ loading: true })
      this.getSymptomProgressData(val.value)
      this.getNotesData(val.value)
    }
    if (this.state.checked) {
      let { docFirebaseAuthUser, patient, token } = this.props
      this.props.updatePatientData(
        {
          doctor_id: docFirebaseAuthUser.uid,
          patient_id: patient.uid,
          'symptom_progress_preferences.timeline': val,
        },
        token,
      )
    }

    // this.getSymptomProgressData(val.value)
  }

  renderChartWithTimeline() {
    const graphWidth = window.innerWidth / 1.4 - 100
    const { selectedGraphOptions, progressData } = this.state
    const lines =
      selectedGraphOptions &&
      selectedGraphOptions.map((x) => (
        <Line
          dot={selectedGraphOptions.length === 1}
          name={x.label}
          type="monotone"
          dataKey={x.value}
          stroke={Helpers.random_hex_color_code()}
          strokeWidth={2.5}
        />
      ))

    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          marginTop: 30,
          paddingLeft: 40,
        }}
      >
        <div style={{ width: '60%', marginBottom: 40 }}>
          <Select
            isMulti
            placeholder="Select ..."
            options={this.state.graphOptions}
            value={this.state.selectedGraphOptions}
            onChange={(val) => this.onSelectEntryChanged('symptomOptions', val)}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        </div>

        <div
          style={{
            margin: '0 auto',
            display: 'flex',
            width: graphWidth + 40,
            flexDirection: 'column',
            alignItems: 'center',
            boxShadow: '5px 5px 5px 5px grey',
            padding: '20px 0px',
          }}
        >
          {lines && lines.length > 0 ? (
            <LineChart
              width={graphWidth}
              height={400}
              data={progressData}
              strokeWidth={2.5}
              margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
            >
              <Legend verticalAlign="top" />
              {lines}
              <XAxis
                label={{
                  value: 'Timeline',
                  position: 'insideBottom',
                  offset: 0,
                }}
                dataKey="created_at"
                tickFormatter={(tickItem) => moment(tickItem).format('D-MMM')}
              />
              <YAxis
                label={{
                  value: 'Severity',
                  angle: -90,
                  position: 'insideLeft',
                }}
                domain={[0, 10]}
              />
              <Tooltip
                content={<CustomTooltip notesData={this.state.notesMap} />}
              />
            </LineChart>
          ) : (
            <div
              style={{
                height: 400,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <p>Select options from dropdown to view the chart</p>
            </div>
          )}
        </div>
      </div>
    )
  }

  render() {
    return (
      <div
        className={GlobalStyles.container}
        style={{ backgroundColor: 'white' }}
      >
        {this.state.loading || this.state.symptomLoading ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              height: 400,
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        ) : (
          <div style={{ width: '100%', marginTop: 10 }}>
            <h4
              style={{
                color: Constants.primaryTheme,
                width: '100%',
                textAlign: 'center',
              }}
            >
              {this.props.fromPatientProfile
                ? `Symptoms Progress`
                : `Symptoms Progress & Patient Reported Outcomes`}
            </h4>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                paddingLeft: 40,
                alignItems: 'center',
              }}
            >
              <p style={{ fontWeight: 'bold', color: 'gray', marginRight: 20 }}>
                Timeline
              </p>
              <div style={{ width: 300 }}>
                <Select
                  placeholder="Select timeline..."
                  options={[
                    { label: 'Weekly', value: 'weekly' },
                    { label: 'Monthly', value: 'monthly' },
                    { label: 'Semi Annual', value: 'semiAnnual' },
                    { label: 'Complete History', value: 'complete' },
                  ]}
                  value={this.state.timeline}
                  onChange={(val) => this.onTimelineChanged(val)}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#20A89233',
                      primary: '#20A892',
                    },
                  })}
                />
              </div>
            </div>

            {!this.props.fromPatientProfile && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  paddingLeft: 40,
                  marginTop: 10,
                }}
              >
                <input
                  type="checkbox"
                  style={{ marginRight: 10 }}
                  onChange={this.onChecked.bind(this)}
                  checked={this.state.checked}
                />
                <p>Save this search</p>
              </div>
            )}

            {this.renderChartWithTimeline()}
          </div>
        )}
      </div>
    )
  }
}

const CustomTooltip = ({ active, payload, notesData }) => {
  const renderRow = (entry, index) => {
    return (
      <div>
        <div key={index.toString()} className={Styles.symptomChartToolTipRow}>
          <p style={{ color: entry.stroke, fontWeight: 'bold' }}>
            {entry.name} :
          </p>
          <p style={{ color: entry.stroke, fontWeight: 'bold', marginLeft: 4 }}>
            {entry.payload[entry.dataKey]}
          </p>
        </div>
      </div>
    )
  }

  const renderNotesAndImage = (note) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div className={Styles.symptomChartToolTipRow}>
          <p style={{ fontWeight: 'bold' }}>Notes :</p>
          <p style={{ marginLeft: 4 }}>{note.text}</p>
        </div>

        {note.image_url && (
          <img
            src={note.image_url}
            className={Styles.symptomChartToolTipImage}
          />
        )}
      </div>
    )
  }

  return (
    <div className={Styles.symptomChartToolTipWrapper}>
      {active && payload && payload.length > 0 && (
        <div>
          <p style={{ fontWeight: 'bold', marginBottom: 4 }}>
            {moment(payload[0].payload?.created_at, 'MM/DD/YYYY').format(
              'MMM Do YYYY',
            )}
          </p>
          {payload.map((entry, index) => renderRow(entry, index))}
          {notesData &&
            notesData[payload[0].payload?.created_at] &&
            renderNotesAndImage(notesData[payload[0].payload?.created_at])}
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state, ownProps /* , ownProps */) => ({
  patient: state.patientReducer.patient || ownProps?.selectedPatient,
  patientCore: state.patientReducer.patientCore,
  color: state.patientReducer.color,
  token: state.authReducer.token,
  docFirebaseAuthUser: state.userReducer.firebaseAuthUser,
})

const mapDispatchToProps = { addPatient, updatePatientData }

export default connect(mapStateToProps, mapDispatchToProps)(SymptomsProgress)
