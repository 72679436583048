module.exports = {
  contentList: [
    {
      label: 'Nutrition',
      options: [
        { label: 'Our Planet Earth', value: 'Healthy Eating', patients: 3 },
        { label: 'The Mind-Body Connection', value: 'Paleo Diet', patients: 2 },
        {
          label: 'Anti-inflammatory Diet',
          value: 'Anti-inflammatory Diet',
          patients: 0,
        },
      ],
    },
    {
      label: 'LifeStyle Health',
      options: [
        { label: 'Stress Management', value: 'Stress Management', patients: 3 },
        { label: 'Weight Management', value: 'Weight Management', patients: 1 },
        { label: 'Better Sleep', value: 'Better Sleep', patients: 3 },
        {
          label: 'Physical Health and Exercise',
          value: 'Physical Health and Exercise',
        },
        {
          label: 'Happiness & Mental Well-being',
          value: 'Happiness & Mental Well-being',
        },
      ],
    },
    {
      label: 'Condition Prevention Education',
      options: [
        { label: 'Obesity Prevention', value: 'Obesity Prevention' },
        { label: 'Cancer', value: 'Cancer' },
        { label: 'Diabetes', value: 'Diabetes' },
        { label: 'Stroke', value: 'Stroke' },
        { label: 'Heart Disease', value: 'Heart Disease' },
        { label: 'Inflammation', value: 'Inflammation' },
        { label: 'Depression', value: 'Depression' },
      ],
    },
    {
      label: 'Social Services',
      options: [
        { label: 'Housing', value: 'Housing' },
        { label: 'Transportation', value: 'Transportation' },
        { label: 'Food Security', value: 'Food Security' },
        { label: 'Relationships', value: 'Relationships' },
        { label: 'Financial', value: 'Financial' },
        { label: 'Violence', value: 'Violence' },
        { label: 'Addiction', value: 'Addiction' },
      ],
    },
  ],
  userTypes: [
    { label: 'Physician (MD/DO)', value: 'Physician' },
    { label: 'Behavioral Health (PsyD, LCSW)', value: 'Behavioral Health' },
    { label: "Physician's Assistant", value: "Physician's Assistant" },
    { label: 'Care Coordinator', value: 'Care Coordinator' },
    { label: 'Nurse Practitioner', value: 'Nurse Practitioner' },
    { label: 'Pharmacist', value: 'Pharmacist' },
    { label: 'Therapist', value: 'Therapist' },
    { label: 'Social Worker', value: 'Social Worker' },
    { label: 'Health Coach', value: 'Health Coach' },
    { label: 'Wellness Coach', value: 'Wellness Coach' },
    { label: 'Administrator', value: 'Administrator' },
    { label: 'Billing', value: 'Billing' },
    {label: 'Monitor', value: 'Monitor'},
  ],
  taskTypes: [
    { label: 'Admin', value: 'Admin' },
    { label: 'Refill Medication', value: 'Refill Medication' },
    { label: 'RPM', value: 'RPM' },
    { label: 'Request/ROI', value: 'Request/ROI' },
    { label: 'Need Referral', value: 'Need Referral' },
    { label: 'Need Results', value: 'Need Results' },
    { label: 'Pharmacy Issue', value: 'Pharmacy Issue' },
    { label: 'Need Appointment', value: 'Need Appointment' },
    { label: 'Insurance Issue', value: 'Insurance Issue' },
    { label: 'Admin Request', value: 'Admin Request' },
    { label: 'Insurance Issue', value: 'Insurance Issue' },
    { label: 'Prior Authorization', value: 'Prior Authorization' },
    { label: 'Records', value: 'Records' },
    { label: 'Other', value: 'Other' },
  ],
  visitLengths: [
    { label: '15-19 Minutes', value: '15-19 Minutes' },
    { label: '30-44 Minutes', value: '30-44 Minutes' },
    { label: '45-59 Minutes', value: '45-59 Minutes' },
  ],
  healthPlanStatus: [
    {
      label: 'Submitted Letter of Interest',
      value: 'Submitted Letter of Interest',
    },
    {
      label: 'Application Submitted',
      value: 'Application Submitted',
    },
    { label: 'Payer Responded', value: 'Payer Responded' },
    {
      label: 'Need More Information',
      value: 'Need More Information',
    },
    { label: 'Contract Received', value: 'Contract Received' },
    { label: 'Contract Signed', value: 'Contract Signed' },
    {
      label: 'Contract Countersigned',
      value: 'Contract Countersigned',
    },
    { label: 'In-Network', value: 'In-Network' },
    { label: 'Rejected', value: 'Rejected' },
    { label: 'Cancelled', value: 'Cancelled' },
  ],
  stateResidence: [
    {
      label: 'Alabama',
      value: 'Alabama',
      abbreviation: 'AL',
    },
    {
      label: 'Alaska',
      value: 'Alaska',
      abbreviation: 'AK',
    },
    {
      label: 'American Samoa',
      value: 'American Samoa',
      abbreviation: 'AS',
    },
    {
      label: 'Arizona',
      value: 'Arizona',
      abbreviation: 'AZ',
    },
    {
      label: 'Arkansas',
      value: 'Arkansas',
      abbreviation: 'AR',
    },
    {
      label: 'California',
      value: 'California',
      abbreviation: 'CA',
    },
    {
      label: 'Colorado',
      value: 'Colorado',
      abbreviation: 'CO',
    },
    {
      label: 'Connecticut',
      value: 'Connecticut',
      abbreviation: 'CT',
    },
    {
      label: 'Delaware',
      value: 'Delaware',
      abbreviation: 'DE',
    },
    {
      label: 'District Of Columbia',
      value: 'District Of Columbia',
      abbreviation: 'DC',
    },
    {
      label: 'Federated States Of Micronesia',
      value: 'Federated States Of Micronesia',
      abbreviation: 'FM',
    },
    {
      label: 'Florida',
      value: 'Florida',
      abbreviation: 'FL',
    },
    {
      label: 'Georgia',
      value: 'Georgia',
      abbreviation: 'GA',
    },
    {
      label: 'Guam',
      value: 'Guam',
      abbreviation: 'GU',
    },
    {
      label: 'Hawaii',
      value: 'Hawaii',
      abbreviation: 'HI',
    },
    {
      label: 'Idaho',
      value: 'Idaho',
      abbreviation: 'ID',
    },
    {
      label: 'Illinois',
      value: 'Illinois',
      abbreviation: 'IL',
    },
    {
      label: 'Indiana',
      value: 'Indiana',
      abbreviation: 'IN',
    },
    {
      label: 'Iowa',
      value: 'Iowa',
      abbreviation: 'IA',
    },
    {
      label: 'Kansas',
      value: 'Kansas',
      abbreviation: 'KS',
    },
    {
      label: 'Kentucky',
      value: 'Kentucky',
      abbreviation: 'KY',
    },
    {
      label: 'Louisiana',
      value: 'Louisiana',
      abbreviation: 'LA',
    },
    {
      label: 'Maine',
      value: 'Maine',
      abbreviation: 'ME',
    },
    {
      label: 'Marshall Islands',
      value: 'Marshall Islands',
      abbreviation: 'MH',
    },
    {
      label: 'Maryland',
      value: 'Maryland',
      abbreviation: 'MD',
    },
    {
      label: 'Massachusetts',
      value: 'Massachusetts',
      abbreviation: 'MA',
    },
    {
      label: 'Michigan',
      value: 'Michigan',
      abbreviation: 'MI',
    },
    {
      label: 'Minnesota',
      value: 'Minnesota',
      abbreviation: 'MN',
    },
    {
      label: 'Mississippi',
      value: 'Mississippi',
      abbreviation: 'MS',
    },
    {
      label: 'Missouri',
      value: 'Missouri',
      abbreviation: 'MO',
    },
    {
      label: 'Montana',
      value: 'Montana',
      abbreviation: 'MT',
    },
    {
      label: 'Nebraska',
      value: 'Nebraska',
      abbreviation: 'NE',
    },
    {
      label: 'Nevada',
      value: 'Nevada',
      abbreviation: 'NV',
    },
    {
      label: 'New Hampshire',
      value: 'New Hampshire',
      abbreviation: 'NH',
    },
    {
      label: 'New Jersey',
      value: 'New Jersey',
      abbreviation: 'NJ',
    },
    {
      label: 'New Mexico',
      value: 'New Mexico',
      abbreviation: 'NM',
    },
    {
      label: 'New York',
      value: 'New York',
      abbreviation: 'NY',
    },
    {
      label: 'North Carolina',
      value: 'North Carolina',
      abbreviation: 'NC',
    },
    {
      label: 'North Dakota',
      value: 'North Dakota',
      abbreviation: 'ND',
    },
    {
      label: 'Northern Mariana Islands',
      value: 'Northern Mariana Islands',
      abbreviation: 'MP',
    },
    {
      label: 'Ohio',
      value: 'Ohio',
      abbreviation: 'OH',
    },
    {
      label: 'Oklahoma',
      value: 'Oklahoma',
      abbreviation: 'OK',
    },
    {
      label: 'Oregon',
      value: 'Oregon',
      abbreviation: 'OR',
    },
    {
      label: 'Palau',
      value: 'Palau',
      abbreviation: 'PW',
    },
    {
      label: 'Pennsylvania',
      value: 'Pennsylvania',
      abbreviation: 'PA',
    },
    {
      label: 'Puerto Rico',
      value: 'Puerto Rico',
      abbreviation: 'PR',
    },
    {
      label: 'Rhode Island',
      value: 'Rhode Island',
      abbreviation: 'RI',
    },
    {
      label: 'South Carolina',
      value: 'South Carolina',
      abbreviation: 'SC',
    },
    {
      label: 'South Dakota',
      value: 'South Dakota',
      abbreviation: 'SD',
    },
    {
      label: 'Tennessee',
      value: 'Tennessee',
      abbreviation: 'TN',
    },
    {
      label: 'Texas',
      value: 'Texas',
      abbreviation: 'TX',
    },
    {
      label: 'Utah',
      value: 'Utah',
      abbreviation: 'UT',
    },
    {
      label: 'Vermont',
      value: 'Vermont',
      abbreviation: 'VT',
    },
    {
      label: 'Virgin Islands',
      value: 'Virgin Islands',
      abbreviation: 'VI',
    },
    {
      label: 'Virginia',
      value: 'Virginia',
      abbreviation: 'VA',
    },
    {
      label: 'Washington',
      value: 'Washington',
      abbreviation: 'WA',
    },
    {
      label: 'West Virginia',
      value: 'West Virginia',
      abbreviation: 'WV',
    },
    {
      label: 'Wisconsin',
      value: 'Wisconsin',
      abbreviation: 'WI',
    },
    {
      label: 'Wyoming',
      value: 'Wyoming',
      abbreviation: 'WY',
    },
  ],

  degreeOptions: [
    { label: 'ABA : Anesthesia Assistant', value: 'Anesthesia Assistant ' },
    {
      label: 'ABA : Applied Behavioral Analyst',
      value: 'Applied Behavioral Analyst',
    },
    { label: 'ACU : Acupuncturist', value: 'Acupuncturist' },
    { label: 'ADC : Alcohol/Drug Counselor', value: 'Alcohol/Drug Counselor' },
    {
      label: 'APN : Advanced Practice Nurse',
      value: 'Advanced Practice Nurse',
    },
    { label: 'AT : Athletic Trainers', value: 'Athletic Trainers' },
    { label: 'AUD : Audiologist', value: 'Audiologist' },
    { label: 'BT : Biofeedback Technician', value: 'Biofeedback Technician' },
    {
      label: 'CNS : Clinical Nurse Specialist',
      value: 'Clinical Nurse Specialist',
    },
    { label: 'CP : Clinical Psychologist', value: 'Clinical Psychologist' },
    {
      label: 'CRNA : Certified Registered Nurse Anesthetist',
      value: 'Certified Registered Nurse Anesthetist',
    },
    {
      label: 'CSP : Christian Science Practitioner',
      value: 'Christian Science Practitioner',
    },
    { label: 'CSW : Clinical Social Worker', value: 'Clinical Social Worker' },
    {
      label: 'DC : Doctor of Chiropractic (DC)',
      value: 'Doctor of Chiropractic (DC)',
    },
    {
      label: 'DDS : Doctor of Dental Surgery',
      value: 'Doctor of Dental Surgery',
    },
    {
      label: 'DMD : Doctor of Dental Medicine',
      value: 'Doctor of Dental Medicine',
    },
    { label: 'DO : Osteopathic Doctor (DO)', value: 'Osteopathic Doctor' },
    {
      label: 'DPM : Doctor of Podiatric Medicine',
      value: 'Doctor of Podiatric Medicine',
    },
    { label: 'DT : Dietician', value: 'Dietician' },
    { label: 'GC : Genetic Counselor', value: 'Genetic Counselor' },
    { label: 'HOS : Hospitalist', value: 'Hospitalist' },
    { label: 'LN : Nutritionist', value: 'Nutritionist' },
    {
      label: 'LPN : Licensed Practical Nurse',
      value: 'Licensed Practical Nurse',
    },
    { label: 'MD : Medical Doctor', value: 'Medical Doctor' },
    {
      label: 'MFT : Marriage/Family Therapist',
      value: 'Marriage/Family Therapist',
    },
    { label: 'MT : Massage Therapist', value: 'Massage Therapist' },
    { label: 'MW : Midwife', value: 'Midwife' },
    { label: 'ND : Naturopath', value: 'Naturopath' },
    { label: 'NEU : Neuropsychologist', value: 'Neuropsychologist' },
    { label: 'NMW : Nurse Midwife', value: 'Nurse Midwife' },
    { label: 'NP : Nurse Practitioner', value: 'Nurse Practitioner' },
    { label: 'OD : Optometrist', value: 'Optometrist' },
    { label: 'OPT : Optician', value: 'Optician' },
    { label: 'OT : Occupational Therapist', value: 'Occupational Therapist' },
    { label: 'PA : Physician Assistant', value: 'Physician Assistant' },
    { label: 'PC : Professional Counselor', value: 'Professional Counselor' },
    { label: 'PHA : Pharmacist', value: 'Pharmacist' },
    { label: 'PT : Physical Therapist', value: 'Physical Therapist' },
    { label: 'RN : Registered Nurse', value: 'Registered Nurse' },
    {
      label: 'RNFA : Registered Nurse First Assistant',
      value: 'Registered Nurse First Assistant',
    },
    { label: 'RT : Respiratory Therapist', value: 'Respiratory Therapist' },
    { label: 'SA : Surgical Assistant', value: 'Surgical Assistant' },
    { label: 'SLP : Speech Pathologist', value: 'Speech Pathologist' },
  ],

  questionnaires: [
    // {label: 'Daily Update', screenName: 'track'},
    { label: 'Long Covid', screenName: 'covidHistory1' },
    { label: 'Wellness', screenName: 'wellnessScreener' },
    { label: 'Social Health', screenName: 'socialHistory' },
    { label: 'Family History', screenName: 'familyHistory' },
    { label: 'Anxiety (GAD-7)', screenName: 'anxiety' },
    { label: 'Mental Health (PHQ-9)', screenName: 'mentalHealth' },
    { label: "Crohn's Progress (Harvey Bradshaw)", screenName: 'crohn' },
    { label: 'Colitis Progress (P-SCCAI)', screenName: 'colitis' },
    { label: 'Burnout', screenName: 'burnout' },
    { label: 'Physical Function (HAQ-2)', screenName: 'haq' },
    { label: 'Pain', screenName: 'pain' },
    { label: 'Fatigue', screenName: 'fatigue' },
    { label: 'Fatigue-FM', screenName: 'fatigueFM' },
    { label: 'Cognitive Function', screenName: 'cognitiveFunction' },
    // {label: 'Lupus', screenName: 'lupus'},
    { label: 'Ankylosing Spondylitis (BASDAI)', screenName: 'basdai' },
    { label: 'Fibromyalgia - Fatigue', screenName: 'fmscreen' },
    {
      label: 'HRA (COPD Risk + Diabetes Risk + ASCVD Risk)',
      screenName: 'hra',
    },
    { label: 'Chronic Fatigue Screen', screenName: 'cfs' },
    { label: 'Fertility', screenName: 'fertility' }, // have to show only if the user is women >50
    { label: 'Menstrual Cycle', screenName: 'menstrualCycle' }, // have to show only if the user is women >50
    { label: 'Activities of Daily Living', screenName: 'activities' },
    { label: 'Muscle Pain (Widespread Pain)', screenName: 'musclePain' },
    { label: 'Joint Pain', screenName: 'jointPain' },
    { label: 'Dyspnea', screenName: 'dyspnea' },
    { label: 'ACE', screenName: 'ace' },
    { label: 'Global Health', screenName: 'globalhealth' },
    { label: 'Sleep Apnea (STOP-BANG)', screenName: 'sleepApnea' },
    { label: 'HRQoL (sf-36)', screenName: 'hrqol1' },
    { label: 'Patient Experience (pre)', screenName: 'prePilot' },
    { label: 'Patient Experience (post)', screenName: 'postPilot' },
    { label: 'Symptom Feedback', screenName: 'symptomFeedback' },
    { label: 'Long Covid', screenName: 'covidHistory1' },
  ],
  patientExperiences: [
    { label: 'Patient Experience (pre)', screenName: 'prePilot' },
    { label: 'Patient Experience (post)', screenName: 'postPilot' },
    { label: 'Symptom Feedback', screenName: 'symptomFeedback' },
  ],
  medicationForms: [
    {
      code: '7946007',
      label: 'Drug suspension',
    },
    {
      code: '11190007',
      label: 'Drug stick',
    },
    {
      code: '17519006',
      label: 'Lotion',
    },
    {
      code: '30843009',
      label: 'Drug aerosol foam',
    },
    {
      code: '36875001',
      label: 'Drug patch',
    },
    {
      code: '37937005',
      label: 'Drug paste',
    },
    {
      code: '46992007',
      label: 'Pill',
    },
    {
      code: '48582000',
      label: 'Caplet',
    },
    {
      code: '52262001',
      label: 'Drug aerosol',
    },
    {
      code: '63316001',
      label: 'Liniment',
    },
    {
      code: '64241004',
      label: 'Drug pledget',
    },
    {
      code: '66076007',
      label: 'Chewable tablet',
    },
    {
      code: '77899000',
      label: 'Drug solution',
    },
    {
      code: '85581007',
      label: 'Drug powder',
    },
    {
      code: '385018001',
      label: 'Oral drops',
    },
    {
      code: '385019009',
      label: 'Oral drops solution',
    },
    {
      code: '385020003',
      label: 'Oral drops suspension',
    },
    {
      code: '385021004',
      label: 'Oral drops emulsion',
    },
    {
      code: '385022006',
      label: 'Oral liquid',
    },
    {
      code: '385023001',
      label: 'Oral solution',
    },
    {
      code: '385024007',
      label: 'Oral suspension',
    },
    {
      code: '385025008',
      label: 'Oral emulsion',
    },
    {
      code: '385026009',
      label: 'Powder for oral solution',
    },
    {
      code: '385027000',
      label: 'Powder for oral suspension',
    },
    {
      code: '385028005',
      label: 'Granules for oral solution',
    },
    {
      code: '385029002',
      label: 'Granules for oral suspension',
    },
    {
      code: '385032004',
      label: 'Syrup',
    },
    {
      code: '385033009',
      label: 'Powder for syrup',
    },
    {
      code: '385034003',
      label: 'Granules for syrup',
    },
    {
      code: '385035002',
      label: 'Soluble tablet',
    },
    {
      code: '385036001',
      label: 'Dispersible tablet',
    },
    {
      code: '385038000',
      label: 'Oral gel',
    },
    {
      code: '385039008',
      label: 'Oral paste',
    },
    {
      code: '385041009',
      label: 'Oral powder',
    },
    {
      code: '385042002',
      label: 'Effervescent powder',
    },
    {
      code: '385043007',
      label: 'Granules',
    },
    {
      code: '385044001',
      label: 'Effervescent granules',
    },
    {
      code: '385045000',
      label: 'Gastro-resistant granules',
    },
    {
      code: '385046004',
      label: 'Prolonged-release granules',
    },
    {
      code: '385047008',
      label: 'Modified-release granules',
    },
    {
      code: '385048003',
      label: 'Cachet',
    },
    {
      code: '385049006',
      label: 'Capsule',
    },
    {
      code: '385050006',
      label: 'Hard capsule',
    },
    {
      code: '385051005',
      label: 'Soft capsule',
    },
    {
      code: '385052003',
      label: 'Gastro-resistant capsule',
    },
    {
      code: '385053008',
      label: 'Prolonged-release capsule',
    },
    {
      code: '385054002',
      label: 'Modified-release capsule',
    },
    {
      code: '385055001',
      label: 'Tablet',
    },
    {
      code: '385057009',
      label: 'Film-coated tablet',
    },
    {
      code: '385058004',
      label: 'Effervescent tablet',
    },
    {
      code: '385059007',
      label: 'Gastro-resistant tablet',
    },
    {
      code: '385060002',
      label: 'Prolonged-release tablet',
    },
    {
      code: '385061003',
      label: 'Modified-release tablet',
    },
    {
      code: '385062005',
      label: 'Oral lyophilisate',
    },
    {
      code: '385063000',
      label: 'Oral gum',
    },
    {
      code: '385064006',
      label: 'Pillule',
    },
    {
      code: '385069001',
      label: 'Oromucosal liquid',
    },
    {
      code: '385070000',
      label: 'Oromucosal solution',
    },
    {
      code: '385071001',
      label: 'Oromucosal suspension',
    },
    {
      code: '385073003',
      label: 'Oromucosal spray',
    },
    {
      code: '385074009',
      label: 'Sublingual spray',
    },
    {
      code: '385077002',
      label: 'Gingival solution',
    },
    {
      code: '385078007',
      label: 'Oromucosal gel',
    },
    {
      code: '385079004',
      label: 'Oromucosal paste',
    },
    {
      code: '385080001',
      label: 'Medicated chewing-gum',
    },
    {
      code: '385081002',
      label: 'Gingival gel',
    },
    {
      code: '385082009',
      label: 'Gingival paste',
    },
    {
      code: '385083004',
      label: 'Oromucosal capsule',
    },
    {
      code: '385084005',
      label: 'Sublingual tablet',
    },
    {
      code: '385085006',
      label: 'Buccal tablet',
    },
    {
      code: '385086007',
      label: 'Muco-adhesive buccal tablet',
    },
    {
      code: '385087003',
      label: 'Lozenge',
    },
    {
      code: '385088008',
      label: 'Dental gel',
    },
    {
      code: '385089000',
      label: 'Dental stick',
    },
    {
      code: '385090009',
      label: 'Dental insert',
    },
    {
      code: '385091008',
      label: 'Dental powder',
    },
    {
      code: '385092001',
      label: 'Dental liquid',
    },
    {
      code: '385094000',
      label: 'Dental solution',
    },
    {
      code: '385095004',
      label: 'Dental suspension',
    },
    {
      code: '385096003',
      label: 'Dental emulsion',
    },
    {
      code: '385098002',
      label: 'Bath additive',
    },
    {
      code: '385099005',
      label: 'Cream',
    },
    {
      code: '385100002',
      label: 'Gel',
    },
    {
      code: '385101003',
      label: 'Ointment',
    },
    {
      code: '385102005',
      label: 'Cutaneous paste',
    },
    {
      code: '385103000',
      label: 'Cutaneous foam',
    },
    {
      code: '385104006',
      label: 'Shampoo',
    },
    {
      code: '385105007',
      label: 'Cutaneous spray',
    },
    {
      code: '385106008',
      label: 'Cutaneous solution spray',
    },
    {
      code: '385107004',
      label: 'Cutaneous liquid',
    },
    {
      code: '385108009',
      label: 'Cutaneous solution',
    },
    {
      code: '385110006',
      label: 'Cutaneous suspension',
    },
    {
      code: '385111005',
      label: 'Cutaneous emulsion',
    },
    {
      code: '385112003',
      label: 'Cutaneous powder',
    },
    {
      code: '385113008',
      label: 'Solution for iontophoresis',
    },
    {
      code: '385114002',
      label: 'Transdermal patch',
    },
    {
      code: '385115001',
      label: 'Collodion',
    },
    {
      code: '385116000',
      label: 'Medicated nail laquer',
    },
    {
      code: '385117009',
      label: 'Poultice',
    },
    {
      code: '385118004',
      label: 'Cutaneous stick',
    },
    {
      code: '385119007',
      label: 'Cutaneous sponge',
    },
    {
      code: '385121002',
      label: 'Eye cream',
    },
    {
      code: '385122009',
      label: 'Eye gel',
    },
    {
      code: '385123004',
      label: 'Eye ointment',
    },
    {
      code: '385124005',
      label: 'Eye drops',
    },
    {
      code: '385125006',
      label: 'Eye drops solution',
    },
    {
      code: '385128008',
      label: 'Prolonged-release eye drops',
    },
    {
      code: '385130005',
      label: 'Eye lotion',
    },
    {
      code: '385132002',
      label: 'Ophthalmic insert',
    },
    {
      code: '385133007',
      label: 'Ear cream',
    },
    {
      code: '385134001',
      label: 'Ear gel',
    },
    {
      code: '385135000',
      label: 'Ear ointment',
    },
    {
      code: '385136004',
      label: 'Ear drops',
    },
    {
      code: '385137008',
      label: 'Ear drops solution',
    },
    {
      code: '385138003',
      label: 'Ear drops emulsion',
    },
    {
      code: '385139006',
      label: 'Ear powder',
    },
    {
      code: '385140008',
      label: 'Ear spray',
    },
    {
      code: '385141007',
      label: 'Ear spray solution',
    },
    {
      code: '385142000',
      label: 'Ear spray suspension',
    },
    {
      code: '385143005',
      label: 'Ear spray emulsion',
    },
    {
      code: '385147006',
      label: 'Ear tampon',
    },
    {
      code: '385148001',
      label: 'Ear stick',
    },
    {
      code: '385149009',
      label: 'Nasal cream',
    },
    {
      code: '385150009',
      label: 'Nasal gel',
    },
    {
      code: '385151008',
      label: 'Nasal ointment',
    },
    {
      code: '385152001',
      label: 'Nasal drops',
    },
    {
      code: '385153006',
      label: 'Nasal drops solution',
    },
    {
      code: '385154000',
      label: 'Nasal drops suspension',
    },
    {
      code: '385155004',
      label: 'Nasal drops emulsion',
    },
    {
      code: '385156003',
      label: 'Nasal powder',
    },
    {
      code: '385157007',
      label: 'Nasal spray',
    },
    {
      code: '385158002',
      label: 'Nasal spray solution',
    },
    {
      code: '385159005',
      label: 'Nasal spray suspension',
    },
    {
      code: '385160000',
      label: 'Nasal spray emulsion',
    },
    {
      code: '385162008',
      label: 'Nasal stick',
    },
    {
      code: '385163003',
      label: 'Eye/ear/nose drops',
    },
    {
      code: '385164009',
      label: 'Eye/ear/nose ointment',
    },
    {
      code: '385165005',
      label: 'Vaginal cream',
    },
    {
      code: '385166006',
      label: 'Vaginal gel',
    },
    {
      code: '385167002',
      label: 'Vaginal ointment',
    },
    {
      code: '385168007',
      label: 'Vaginal foam',
    },
    {
      code: '385169004',
      label: 'Vaginal liquid',
    },
    {
      code: '385170003',
      label: 'Vaginal solution',
    },
    {
      code: '385171004',
      label: 'Vaginal suspension',
    },
    {
      code: '385172006',
      label: 'Vaginal emulsion',
    },
    {
      code: '385173001',
      label: 'Tablet for vaginal solution',
    },
    {
      code: '385174007',
      label: 'Pessary',
    },
    {
      code: '385175008',
      label: 'Vaginal capsule',
    },
    {
      code: '385176009',
      label: 'Hard vaginal capsule',
    },
    {
      code: '385177000',
      label: 'Soft vaginal capsule',
    },
    {
      code: '385178005',
      label: 'Vaginal tablet',
    },
    {
      code: '385179002',
      label: 'Effervescent vaginal tablet',
    },
    {
      code: '385180004',
      label: 'Medicated vaginal tampon',
    },
    {
      code: '385182007',
      label: 'Rectal cream',
    },
    {
      code: '385183002',
      label: 'Rectal gel',
    },
    {
      code: '385184008',
      label: 'Rectal ointment',
    },
    {
      code: '385185009',
      label: 'Rectal foam',
    },
    {
      code: '385186005',
      label: 'Enema',
    },
    {
      code: '385187001',
      label: 'Rectal solution',
    },
    {
      code: '385188006',
      label: 'Rectal suspension',
    },
    {
      code: '385189003',
      label: 'Rectal emulsion',
    },
    {
      code: '385191006',
      label: 'Powder for rectal suspension',
    },
    {
      code: '385192004',
      label: 'Tablet for rectal solution',
    },
    {
      code: '385193009',
      label: 'Tablet for rectal suspension',
    },
    {
      code: '385194003',
      label: 'Suppository',
    },
    {
      code: '385195002',
      label: 'Rectal capsule',
    },
    {
      code: '385196001',
      label: 'Rectal tampon',
    },
    {
      code: '385197005',
      label: 'Nebulizer liquid',
    },
    {
      code: '385198000',
      label: 'Nebulizer solution',
    },
    {
      code: '385199008',
      label: 'Nebulizer suspension',
    },
    {
      code: '385200006',
      label: 'Powder for nebulizer suspension',
    },
    {
      code: '385201005',
      label: 'Powder for nebulizer solution',
    },
    {
      code: '385202003',
      label: 'Nebulizer emulsion',
    },
    {
      code: '385203008',
      label: 'Pressurised inhalation',
    },
    {
      code: '385204002',
      label: 'Pressurised inhalation solution',
    },
    {
      code: '385205001',
      label: 'Pressurised inhalation suspension',
    },
    {
      code: '385206000',
      label: 'Pressurised inhalation emulsion',
    },
    {
      code: '385207009',
      label: 'Inhalation powder',
    },
    {
      code: '385208004',
      label: 'Hard capsule inhalation powder',
    },
    {
      code: '385210002',
      label: 'Inhalation vapor',
    },
    {
      code: '385211003',
      label: 'Inhalation vapor powder',
    },
    {
      code: '385212005',
      label: 'Inhalation vapor capsule',
    },
    {
      code: '385213000',
      label: 'Inhalation vapor solution',
    },
    {
      code: '385214006',
      label: 'Inhalation vapor tablet',
    },
    {
      code: '385215007',
      label: 'Inhalation vapor ointment',
    },
    {
      code: '385216008',
      label: 'Inhalation vapor liquid',
    },
    {
      code: '385217004',
      label: 'Inhalation gas',
    },
    {
      code: '385219001',
      label: 'Injection solution',
    },
    {
      code: '385220007',
      label: 'Injection suspension',
    },
    {
      code: '385221006',
      label: 'Injection emulsion',
    },
    {
      code: '385222004',
      label: 'Injection powder',
    },
    {
      code: '385223009',
      label: 'Powder for injection solution',
    },
    {
      code: '385224003',
      label: 'Powder for injection suspension',
    },
    {
      code: '385229008',
      label: 'Infusion solution',
    },
    {
      code: '385230003',
      label: 'Infusion powder',
    },
    {
      code: '385231004',
      label: 'Powder for infusion solution',
    },
    {
      code: '385236009',
      label: 'Implantation tablet',
    },
    {
      code: '385237000',
      label: 'Implantation chain',
    },
    {
      code: '385242008',
      label: 'Intravesical solution',
    },
    {
      code: '385245005',
      label: 'Urethral gel',
    },
    {
      code: '385246006',
      label: 'Urethral stick',
    },
    {
      code: '385247002',
      label: 'Endotracheopulmonary instillation solution',
    },
    {
      code: '385248007',
      label: 'Powder for endotracheopulmonary instillation solution',
    },
    {
      code: '385250004',
      label: 'Endotracheopulmonary instillation suspension',
    },
    {
      code: '385251000',
      label: 'Endocervical gel',
    },
    {
      code: '385257001',
      label: 'Gastroenteral liquid',
    },
    {
      code: '385258006',
      label: 'Gastroenteral solution',
    },
    {
      code: '385259003',
      label: 'Gastroenteral suspension',
    },
    {
      code: '385260008',
      label: 'Gastroenteral emulsion',
    },
    {
      code: '385261007',
      label: 'Wound stick',
    },
    {
      code: '385262000',
      label: 'Organ preservation solution',
    },
    {
      code: '385278003',
      label: 'Cutaneous powder spray',
    },
    {
      code: '385279006',
      label: 'Cutaneous suspension spray',
    },
    {
      code: '385286003',
      label: 'Implant dosage form',
    },
    {
      code: '414951009',
      label: 'Oral elixir',
    },
    {
      code: '420243009',
      label: 'Tampon dose form',
    },
    {
      code: '420253005',
      label: 'Oil injection',
    },
    {
      code: '420275007',
      label: 'Semi-solid dose form',
    },
    {
      code: '420283001',
      label: 'Nasal emulsion',
    },
    {
      code: '420292003',
      label: 'Rectal powder',
    },
    {
      code: '420293008',
      label: 'Coated pellets capsule',
    },
    {
      code: '420305009',
      label: 'Powder for oral liquid',
    },
    {
      code: '420317006',
      label: 'Inhaler',
    },
    {
      code: '420378007',
      label: 'Extended-release film coated tablet',
    },
    {
      code: '420385006',
      label: 'Extended-release insert',
    },
    {
      code: '420386007',
      label: 'Emulsion',
    },
    {
      code: '420407000',
      label: 'Inhalation aerosol solution',
    },
    {
      code: '420430006',
      label:
        'Lyophilized powder for injectable extended release liposomal suspension',
    },
    {
      code: '420450005',
      label: 'Pressurized nebulizer suspension',
    },
    {
      code: '420460001',
      label: 'Film',
    },
    {
      code: '420509004',
      label: 'Oral granules',
    },
    {
      code: '420536002',
      label: 'Vaginal powder',
    },
    {
      code: '420540006',
      label: 'Cutaneous oil',
    },
    {
      code: '420610000',
      label: 'Nasal aerosol',
    },
    {
      code: '420627008',
      label: 'Extended-release tablet',
    },
    {
      code: '420631002',
      label: 'Vaginal insert',
    },
    {
      code: '420634005',
      label: 'Microspheres for injectable suspension',
    },
    {
      code: '420636007',
      label: 'Eye/ear drops',
    },
    {
      code: '420641004',
      label: 'Solution for inhalation',
    },
    {
      code: '420656008',
      label: 'Lyophilized powder for injectable liposomal suspension',
    },
    {
      code: '420692007',
      label: 'Oral capsule',
    },
    {
      code: '420699003',
      label: 'Liquid dose form',
    },
    {
      code: '420705007',
      label: 'Inhalation aerosol suspension',
    },
    {
      code: '420736004',
      label: 'Eye suspension',
    },
    {
      code: '420757007',
      label: 'Delayed-release granules',
    },
    {
      code: '420761001',
      label: 'Urethral suppository',
    },
    {
      code: '420767002',
      label: 'Delayed-release pellets capsule',
    },
    {
      code: '420768007',
      label: 'Pellet',
    },
    {
      code: '420802004',
      label: 'Extended-release liquid',
    },
    {
      code: '420828001',
      label: 'Powder for reconstitution for drug product',
    },
    {
      code: '420847003',
      label: 'Metered dose aerosol',
    },
    {
      code: '420873008',
      label: 'Extended release injectable suspension',
    },
    {
      code: '420887008',
      label: 'Intrathecal suspension',
    },
    {
      code: '420891003',
      label: 'Ear drops suspension',
    },
    {
      code: '420901005',
      label: 'Ear emulsion',
    },
    {
      code: '420927005',
      label: 'Metered powder',
    },
    {
      code: '420929008',
      label: 'Rectal suppository',
    },
    {
      code: '420955009',
      label: 'Powder for solution',
    },
    {
      code: '420956005',
      label: 'Ultramicronized tablet',
    },
    {
      code: '420992009',
      label: 'Implantable pellet',
    },
    {
      code: '421026006',
      label: 'Oral tablet',
    },
    {
      code: '421027002',
      label: 'Delayed-release capsule',
    },
    {
      code: '421034000',
      label: 'Extended-release suppository',
    },
    {
      code: '421043009',
      label: 'Extended-release film',
    },
    {
      code: '421051007',
      label: 'Sonicated injectable suspension',
    },
    {
      code: '421056002',
      label: 'Emulsion for inhalation',
    },
    {
      code: '421079001',
      label: 'Pastille',
    },
    {
      code: '421080003',
      label: 'Powder for suspension',
    },
    {
      code: '421104008',
      label: 'Aerosol powder',
    },
    {
      code: '421131006',
      label: 'Gaseous dose form',
    },
    {
      code: '421155001',
      label: 'Extended-release enteric coated tablet',
    },
    {
      code: '421166008',
      label: 'Foam',
    },
    {
      code: '421195004',
      label: 'Vaginal suppository',
    },
    {
      code: '421221008',
      label: 'Suspension for inhalation',
    },
    {
      code: '421271006',
      label: 'Extended-release bead implant',
    },
    {
      code: '421288004',
      label: 'Sponge',
    },
    {
      code: '421300005',
      label: 'Extended-release film coated capsule',
    },
    {
      code: '421316007',
      label: 'Powder for eye drops',
    },
    {
      code: '421338009',
      label: 'Extended-release coated capsule',
    },
    {
      code: '421340004',
      label: 'Powder spray',
    },
    {
      code: '421343002',
      label: 'Powder for cutaneous solution',
    },
    {
      code: '421347001',
      label: 'Cutaneous aerosol',
    },
    {
      code: '421366001',
      label: 'Tablet for oral suspension',
    },
    {
      code: '421374000',
      label: 'Delayed-release tablet',
    },
    {
      code: '421378002',
      label: 'Solid dose form',
    },
    {
      code: '421382000',
      label: 'Ear suspension',
    },
    {
      code: '421410002',
      label: 'Intravenous solution',
    },
    {
      code: '421425002',
      label: 'Powder for inhalation solution',
    },
    {
      code: '421427005',
      label: 'Eye/ear ointment',
    },
    {
      code: '421428000',
      label: 'Injectable oil suspension',
    },
    {
      code: '421446006',
      label: 'Extended-release granules for suspension',
    },
    {
      code: '421504000',
      label: 'Cone',
    },
    {
      code: '421522002',
      label: 'Liposomal injectable suspension',
    },
    {
      code: '421532009',
      label: 'Insert',
    },
    {
      code: '421535006',
      label: 'Delayed-release particles tablet',
    },
    {
      code: '421575003',
      label: 'Powder for ear drops',
    },
    {
      code: '421606006',
      label: 'Aerosol spray',
    },
    {
      code: '421607002',
      label: 'Powder for nasal drops',
    },
    {
      code: '421618002',
      label: 'Extended-release capsule',
    },
    {
      code: '421620004',
      label: 'Sustained-release buccal tablet',
    },
    {
      code: '421628006',
      label: 'Cutaneous cream',
    },
    {
      code: '421637006',
      label: 'Lyophilized powder for injectable solution',
    },
    {
      code: '421669002',
      label: 'Metered gel',
    },
    {
      code: '421701006',
      label: 'Tablet for oral solution',
    },
    {
      code: '421713001',
      label: 'Ear solution',
    },
    {
      code: '421716009',
      label: 'Transdermal drug delivery system',
    },
    {
      code: '421720008',
      label: 'Spray dose form',
    },
    {
      code: '421721007',
      label: 'Coated particles tablet',
    },
    {
      code: '421752008',
      label: 'Extended-release enteric coated capsule',
    },
    {
      code: '421759004',
      label: 'Metered dose aerosol inhaler',
    },
    {
      code: '421765004',
      label: 'Intraperitoneal solution',
    },
    {
      code: '421857007',
      label: 'Powder for ophthalmic solution',
    },
    {
      code: '421873001',
      label: 'Oral cream',
    },
    {
      code: '421890007',
      label: 'Oil',
    },
    {
      code: '421932003',
      label: 'Multilayer tablet',
    },
    {
      code: '421937009',
      label: 'Medicated toothpaste',
    },
    {
      code: '421943006',
      label: 'Lyophilized powder for injectable suspension',
    },
    {
      code: '421949005',
      label: 'Cutaneous gel',
    },
    {
      code: '421987002',
      label: 'Intraocular solution',
    },
    {
      code: '421999009',
      label: 'Extended-release coated pellets',
    },
    {
      code: '422054001',
      label: 'Metered dose powder inhaler',
    },
    {
      code: '422059006',
      label: 'Metered dose inhaler',
    },
    {
      code: '422060001',
      label: 'Ophthalmic solution',
    },
    {
      code: '422068008',
      label: 'Eye drops suspension',
    },
    {
      code: '422080000',
      label: 'Nasal suspension',
    },
    {
      code: '422085005',
      label: 'Powder for ophthalmic suspension',
    },
    {
      code: '422151007',
      label: 'Breath activated powder inhaler',
    },
    {
      code: '422186009',
      label: 'Tincture',
    },
    {
      code: '422197009',
      label: 'Breath activated inhaler',
    },
    {
      code: '422199007',
      label: 'Dental cone',
    },
    {
      code: '422201009',
      label: 'Tablet for cutaneous solution',
    },
    {
      code: '422202002',
      label: 'Colloidal suspension for injection',
    },
    {
      code: '422259002',
      label: 'Spirit',
    },
    {
      code: '422264003',
      label: 'Powder for injectable extended release suspension',
    },
    {
      code: '422301006',
      label: 'Modified-release pessary',
    },
    {
      code: '422336005',
      label: 'Nasal solution',
    },
    {
      code: '422353003',
      label: 'Extended-release suspension',
    },
    {
      code: '424179000',
      label: 'Aerosol generator',
    },
    {
      code: '424552006',
      label: 'Vaginal sponge',
    },
    {
      code: '425753008',
      label: 'Topical ointment',
    },
    {
      code: '425965000',
      label: 'Rectal spray',
    },
    {
      code: '426210003',
      label: 'Gum',
    },
    {
      code: '426684005',
      label: 'Drops dose form',
    },
    {
      code: '426823003',
      label: 'Vaginal spray',
    },
    {
      code: '426969004',
      label: 'Metered spray',
    },
    {
      code: '427129005',
      label: 'Coated capsule',
    },
    {
      code: '427564005',
      label: 'Pressurized spray',
    },
    {
      code: '427609008',
      label: 'Modified release drops dose form',
    },
    {
      code: '429885007',
      label: 'Bar',
    },
    {
      code: '443424002',
      label: 'Buccal film',
    },
    {
      code: '447050008',
      label: 'Orodispersible film',
    },
    {
      code: '447079001',
      label: 'Orodispersible tablet',
    },
  ],
  clinicalFindings: [
    {
      Code: '109006',
      Display: 'Anxiety disorder of childhood OR adolescence',
    },
    {
      Code: '122003',
      Display: 'Choroidal hemorrhage',
    },
    {
      Code: '127009',
      Display: 'Spontaneous abortion with laceration of cervix',
    },
    {
      Code: '129007',
      Display: 'Homoiothermia',
    },
    {
      Code: '134006',
      Display: 'Decreased hair growth',
    },
    {
      Code: '140004',
      Display: 'Chronic pharyngitis',
    },
    {
      Code: '144008',
      Display: 'Normal peripheral vision',
    },
    {
      Code: '147001',
      Display:
        'Superficial foreign body of scrotum without major open wound but with infection',
    },
    {
      Code: '150003',
      Display: 'Abnormal bladder continence',
    },
    {
      Code: '151004',
      Display: 'Meningitis due to gonococcus',
    },
    {
      Code: '162004',
      Display: 'Severe manic bipolar I disorder without psychotic features',
    },
    {
      Code: '165002',
      Display: 'Accident-prone',
    },
    {
      Code: '168000',
      Display: 'Typhlolithiasis',
    },
    {
      Code: '171008',
      Display:
        'Injury of ascending right colon without open wound into abdominal cavity',
    },
    {
      Code: '172001',
      Display: 'Endometritis following molar AND/OR ectopic pregnancy',
    },
    {
      Code: '175004',
      Display: 'Supraorbital neuralgia',
    },
    {
      Code: '177007',
      Display: 'Poisoning by sawfly larvae',
    },
    {
      Code: '179005',
      Display: 'Apraxia of dressing',
    },
    {
      Code: '181007',
      Display: 'Hemorrhagic bronchopneumonia',
    },
    {
      Code: '183005',
      Display: 'Autoimmune pancytopenia',
    },
    {
      Code: '184004',
      Display: 'Withdrawal arrhythmia',
    },
    {
      Code: '188001',
      Display: 'Intercostal artery injury',
    },
    {
      Code: '192008',
      Display: 'Congenital syphilitic hepatomegaly',
    },
    {
      Code: '193003',
      Display: 'Benign hypertensive renal disease',
    },
    {
      Code: '195005',
      Display: 'Illegal abortion with endometritis',
    },
    {
      Code: '198007',
      Display: 'Disease due to Filoviridae',
    },
    {
      Code: '199004',
      Display: 'Decreased lactation',
    },
    {
      Code: '208008',
      Display: 'Neurocutaneous melanosis sequence',
    },
    {
      Code: '216004',
      Display: 'Delusion of persecution',
    },
    {
      Code: '219006',
      Display: 'Alcohol user',
    },
    {
      Code: '222008',
      Display: 'Acute epiglottitis with obstruction',
    },
    {
      Code: '223003',
      Display: 'Tumor of body of uterus affecting pregnancy',
    },
    {
      Code: '228007',
      Display: 'Lucio phenomenon',
    },
    {
      Code: '241006',
      Display: 'Motor simple partial status',
    },
    {
      Code: '242004',
      Display: 'Noninfectious jejunitis',
    },
    {
      Code: '253005',
      Display: 'Sycosis',
    },
    {
      Code: '257006',
      Display: 'Acne rosacea, erythematous telangiectatic type',
    },
    {
      Code: '258001',
      Display: 'Pseudoknuckle pad',
    },
    {
      Code: '264008',
      Display: 'Blind hypertensive eye',
    },
    {
      Code: '276008',
      Display: 'Oxytocin poisoning',
    },
    {
      Code: '279001',
      Display: 'Senile myocarditis',
    },
    {
      Code: '281004',
      Display: 'Alcoholic dementia',
    },
    {
      Code: '282006',
      Display: 'Acute myocardial infarction of basal-lateral wall',
    },
    {
      Code: '290006',
      Display: 'Melnick-Fraser syndrome',
    },
    {
      Code: '292003',
      Display: 'EEG finding',
    },
    {
      Code: '297009',
      Display: 'Acute myringitis',
    },
    {
      Code: '299007',
      Display: 'Paraffinoma of skin',
    },
    {
      Code: '303002',
      Display: 'Apoplectic pancreatitis',
    },
    {
      Code: '308006',
      Display: 'Pearly penile papules',
    },
    {
      Code: '310008',
      Display: 'Penile boil',
    },
    {
      Code: '313005',
      Display: 'Déjà vu',
    },
    {
      Code: '317006',
      Display: 'Reactive hypoglycemia',
    },
    {
      Code: '320003',
      Display: 'Cervical dilatation, 1cm',
    },
    {
      Code: '324007',
      Display: 'Plaster ulcer',
    },
    {
      Code: '330007',
      Display: 'Occipital headache',
    },
    {
      Code: '335002',
      Display: 'Pylorospasm',
    },
    {
      Code: '341009',
      Display: 'ABO incompatibility reaction',
    },
    {
      Code: '349006',
      Display: 'Absent tendon reflex',
    },
    {
      Code: '355001',
      Display: 'Hemorrhagic shock',
    },
    {
      Code: '357009',
      Display: 'Closed fracture trapezoid',
    },
    {
      Code: '358004',
      Display: 'Smallpox vaccine poisoning',
    },
    {
      Code: '359007',
      Display: 'Kernicterus due to isoimmunization',
    },
    {
      Code: '360002',
      Display: 'Acute radiation disease',
    },
    {
      Code: '364006',
      Display: 'Acute left-sided heart failure',
    },
    {
      Code: '366008',
      Display: 'Hidromeiosis',
    },
    {
      Code: '368009',
      Display: 'Heart valve disorder',
    },
    {
      Code: '369001',
      Display: 'Normal jugular venous pressure',
    },
    {
      Code: '378007',
      Display: 'Morquio syndrome',
    },
    {
      Code: '382009',
      Display: 'Legal history finding relating to child',
    },
    {
      Code: '383004',
      Display: 'Finding of passive range of hip extension',
    },
    {
      Code: '385006',
      Display: 'Secondary peripheral neuropathy',
    },
    {
      Code: '387003',
      Display: 'Melanuria',
    },
    {
      Code: '398002',
      Display: 'Left axis deviation greater than -90 degrees by EKG',
    },
    {
      Code: '407000',
      Display: 'Congenital hepatomegaly',
    },
    {
      Code: '408005',
      Display: 'Tooth chattering',
    },
    {
      Code: '409002',
      Display: 'Food allergy diet',
    },
    {
      Code: '426008',
      Display: 'Superficial injury of ankle without infection',
    },
    {
      Code: '431005',
      Display: 'Hypertrophy of scrotum',
    },
    {
      Code: '437009',
      Display: 'Abnormal composition of urine',
    },
    {
      Code: '440009',
      Display: 'Persistent hyperphenylalaninemia',
    },
    {
      Code: '442001',
      Display: 'Secondary hypopituitarism',
    },
    {
      Code: '443006',
      Display: 'Cystocele affecting pregnancy',
    },
    {
      Code: '447007',
      Display: 'Coach in sports activity accident',
    },
    {
      Code: '450005',
      Display: 'Ulcerative stomatitis',
    },
    {
      Code: '452002',
      Display: 'Blister of groin without infection',
    },
    {
      Code: '460001',
      Display: 'Squamous metaplasia of prostate gland',
    },
    {
      Code: '467003',
      Display: 'Old laceration of pelvic floor muscle',
    },
    {
      Code: '470004',
      Display: 'Vitreous touch syndrome',
    },
    {
      Code: '479003',
      Display:
        "Graves' disease with pretibial myxedema AND with thyrotoxic crisis",
    },
    {
      Code: '486006',
      Display: 'Acute vascular insufficiency',
    },
    {
      Code: '488007',
      Display: 'Fibroid myocarditis',
    },
    {
      Code: '490008',
      Display: 'Upper respiratory tract hypersensitivity reaction',
    },
    {
      Code: '496002',
      Display: 'Closed traumatic dislocation of third cervical vertebra',
    },
    {
      Code: '504009',
      Display: 'Androgen-dependent hirsutism',
    },
    {
      Code: '517007',
      Display: 'Foreign body in hypopharynx',
    },
    {
      Code: '518002',
      Display: 'Multiple aggregation',
    },
    {
      Code: '520004',
      Display: 'Congenital bent nose',
    },
    {
      Code: '527001',
      Display: "Spontaneous fetal evolution, Roederer's method",
    },
    {
      Code: '536002',
      Display: 'Glissonian cirrhosis',
    },
    {
      Code: '539009',
      Display: 'Conjunctival argyrosis',
    },
    {
      Code: '547009',
      Display: 'Hypersecretion of calcitonin',
    },
    {
      Code: '548004',
      Display: '13p partial trisomy syndrome',
    },
    {
      Code: '554003',
      Display: '2p partial trisomy syndrome',
    },
    {
      Code: '555002',
      Display: 'Dicentra species poisoning',
    },
    {
      Code: '563001',
      Display: 'Nystagmus',
    },
    {
      Code: '568005',
      Display: 'Habit disorder',
    },
    {
      Code: '586008',
      Display: 'Contact dermatitis due to primrose',
    },
    {
      Code: '590005',
      Display: 'Congenital aneurysm of anterior communicating artery',
    },
    {
      Code: '596004',
      Display: 'Premenstrual dysphoric disorder',
    },
    {
      Code: '599006',
      Display: 'Persistent pneumothorax',
    },
    {
      Code: '600009',
      Display: 'Pyromania',
    },
    {
      Code: '602001',
      Display: 'Ross river fever',
    },
    {
      Code: '607007',
      Display: 'Decreased vital capacity',
    },
    {
      Code: '610000',
      Display: 'Spastic aphonia',
    },
    {
      Code: '613003',
      Display: 'FRAXA - Fragile X syndrome',
    },
    {
      Code: '615005',
      Display:
        'Obstruction due to foreign body accidentally left in operative wound AND/OR body cavity during a procedure',
    },
    {
      Code: '616006',
      Display: 'Sensorimotor disorder of eyelid',
    },
    {
      Code: '626004',
      Display: 'Hypercortisolism due to nonpituitary tumor',
    },
    {
      Code: '631002',
      Display: 'Transfusion reaction due to minor incompatibility',
    },
    {
      Code: '634005',
      Display: 'Saddle boil',
    },
    {
      Code: '640003',
      Display: 'Injury of pneumogastric nerve',
    },
    {
      Code: '643001',
      Display: 'Hypertrophy of lip',
    },
    {
      Code: '646009',
      Display: 'Idiopathic cyst of anterior chamber',
    },
    {
      Code: '649002',
      Display: 'Open fracture of distal end of ulna',
    },
    {
      Code: '651003',
      Display: 'Root work',
    },
    {
      Code: '652005',
      Display: 'Gangrenous tonsillitis',
    },
    {
      Code: '655007',
      Display:
        'Abnormal fetal heart beat noted before labor in liveborn infant',
    },
    {
      Code: '658009',
      Display: 'Injury of colon without open wound into abdominal cavity',
    },
    {
      Code: '663008',
      Display: 'Pulmonary embolism following molar AND/OR ectopic pregnancy',
    },
    {
      Code: '664002',
      Display: 'Delayed ovulation',
    },
    {
      Code: '666000',
      Display: 'Poisoning by antivaricose drug AND/OR sclerosing agent',
    },
    {
      Code: '675003',
      Display: 'Torsion of intestine',
    },
    {
      Code: '682004',
      Display: 'Thrombosis complicating pregnancy AND/OR puerperium',
    },
    {
      Code: '685002',
      Display: 'Acquired telangiectasia of small AND/OR large intestines',
    },
    {
      Code: '701003',
      Display: 'Adult osteochondritis of spine',
    },
    {
      Code: '703000',
      Display: 'Congenital adhesion of tongue',
    },
    {
      Code: '714002',
      Display: 'Abrasion AND/OR friction burn of toe with infection',
    },
    {
      Code: '715001',
      Display: 'Nontraumatic rupture of urethra',
    },
    {
      Code: '718004',
      Display: 'Acute bronchiolitis with obstruction',
    },
    {
      Code: '733007',
      Display:
        'Superficial foreign body of groin without major open wound but with infection',
    },
    {
      Code: '734001',
      Display: 'Opocephalus',
    },
    {
      Code: '736004',
      Display: 'Abscess of hip',
    },
    {
      Code: '750009',
      Display: 'Schistosoma mansoni infection',
    },
    {
      Code: '755004',
      Display: 'Postgastrectomy phytobezoar',
    },
    {
      Code: '756003',
      Display: 'Chronic rheumatic myopericarditis',
    },
    {
      Code: '758002',
      Display: 'Cyst of uterus',
    },
    {
      Code: '775008',
      Display: 'Open wound of head with complication',
    },
    {
      Code: '776009',
      Display: 'Partial arterial retinal occlusion',
    },
    {
      Code: '781000',
      Display: 'Cestrum diurnum poisoning',
    },
    {
      Code: '786005',
      Display: 'Clinical stage I B',
    },
    {
      Code: '787001',
      Display: 'Rheumatic mitral stenosis with regurgitation',
    },
    {
      Code: '788006',
      Display: 'Disease-related diet',
    },
    {
      Code: '792004',
      Display: 'CJD - Creutzfeldt-Jakob disease',
    },
    {
      Code: '799008',
      Display: 'Sigmoid colon ulcer',
    },
    {
      Code: '801006',
      Display: 'Insect bite, nonvenomous, of foot, infected',
    },
    {
      Code: '805002',
      Display: 'Pneumoconiosis due to silica',
    },
    {
      Code: '811004',
      Display: 'Flail motion',
    },
    {
      Code: '813001',
      Display: 'Ankle instability',
    },
    {
      Code: '815008',
      Display: 'Episcleritis',
    },
    {
      Code: '816009',
      Display: 'Genetic recombination',
    },
    {
      Code: '818005',
      Display: 'Third degree burn of multiple sites of lower limb',
    },
    {
      Code: '825003',
      Display: 'Superficial injury of axilla with infection',
    },
    {
      Code: '827006',
      Display:
        'Late congenital syphilis, latent (+ sero., - C.S.F., 2 years OR more)',
    },
    {
      Code: '832007',
      Display: 'Moderate major depression',
    },
    {
      Code: '834008',
      Display: 'Chair-seated facing coital position',
    },
    {
      Code: '841002',
      Display: 'Congenital absence of skull bone',
    },
    {
      Code: '842009',
      Display: 'Consanguinity',
    },
    {
      Code: '843004',
      Display: 'Poliomyelomalacia',
    },
    {
      Code: '844005',
      Display: 'Finding relating to behavior',
    },
    {
      Code: '845006',
      Display: 'Inferior mesenteric artery injury',
    },
    {
      Code: '849000',
      Display: 'Total cataract',
    },
    {
      Code: '857002',
      Display: 'Erythema simplex',
    },
    {
      Code: '862001',
      Display: 'Anemia due to chlorate',
    },
    {
      Code: '865004',
      Display: 'Hyperalimentation formula for ileus',
    },
    {
      Code: '871005',
      Display: 'Contracted pelvis',
    },
    {
      Code: '874002',
      Display: 'Therapeutic diuresis',
    },
    {
      Code: '875001',
      Display: 'Chalcosis of eye',
    },
    {
      Code: '888003',
      Display: 'Foetal or neonatal effect of maternal blood loss',
    },
    {
      Code: '890002',
      Display: 'Deep third degree burn of elbow',
    },
    {
      Code: '899001',
      Display: 'Axis I diagnosis',
    },
    {
      Code: '903008',
      Display: 'Chorioretinal infarction',
    },
    {
      Code: '904002',
      Display: "Pinard's sign",
    },
    {
      Code: '908004',
      Display: 'Superficial injury of interscapular region without infection',
    },
    {
      Code: '919001',
      Display: 'Pseudohomosexual state',
    },
    {
      Code: '928000',
      Display: 'Musculoskeletal disorder',
    },
    {
      Code: '931004',
      Display: 'Gestation period, 9 weeks',
    },
    {
      Code: '932006',
      Display: 'Flat affect',
    },
    {
      Code: '934007',
      Display: 'Thalassemia intermedia',
    },
    {
      Code: '943003',
      Display: 'Congenital retinal aneurysm',
    },
    {
      Code: '954008',
      Display: 'Renon-Delille syndrome',
    },
    {
      Code: '961007',
      Display: 'Erythema nodosum, acute form',
    },
    {
      Code: '962000',
      Display: 'Disability evaluation, disability 6%',
    },
    {
      Code: '964004',
      Display: 'Open wound of pharynx without complication',
    },
    {
      Code: '965003',
      Display: 'Toxic amblyopia',
    },
    {
      Code: '975000',
      Display: 'Anorectal agenesis',
    },
    {
      Code: '978003',
      Display: 'Chronic infantile eczema',
    },
    {
      Code: '981008',
      Display: 'Hemorrhagic proctitis',
    },
    {
      Code: '984000',
      Display: 'Perirectal cellulitis',
    },
    {
      Code: '987007',
      Display: 'Cellulitis of temple region',
    },
    {
      Code: '991002',
      Display: 'Wide QRS complex',
    },
    {
      Code: '998008',
      Display: "Chagas' disease with heart involvement",
    },
    {
      Code: '1003002',
      Display: 'Religious discrimination',
    },
    {
      Code: '1020003',
      Display: 'Disease due to Nairovirus',
    },
    {
      Code: '1023001',
      Display: 'Apneic',
    },
    {
      Code: '1027000',
      Display: 'Biliary esophagitis',
    },
    {
      Code: '1031006',
      Display: 'Open wound of trachea without complication',
    },
    {
      Code: '1033009',
      Display: 'Thoracic arthritis',
    },
    {
      Code: '1034003',
      Display: 'Mesenteric-portal fistula',
    },
    {
      Code: '1038000',
      Display: 'Disacchariduria',
    },
    {
      Code: '1045000',
      Display: 'Colonospasm',
    },
    {
      Code: '1046004',
      Display: 'Ureteritis glandularis',
    },
    {
      Code: '1051005',
      Display: 'Hyperplasia of islet alpha cells with gastrin excess',
    },
    {
      Code: '1055001',
      Display: 'Stenosis of precerebral artery',
    },
    {
      Code: '1059007',
      Display: 'Opisthorchiasis',
    },
    {
      Code: '1070000',
      Display: 'Facial myokymia',
    },
    {
      Code: '1073003',
      Display: 'Xeroderma pigmentosum group B',
    },
    {
      Code: '1074009',
      Display: 'Glucocorticoid-responsive primary hyperaldosteronism',
    },
    {
      Code: '1077002',
      Display: 'Septal infarction by EKG',
    },
    {
      Code: '1079004',
      Display: 'Macular retinal cyst',
    },
    {
      Code: '1085006',
      Display: 'Vulval candidiasis',
    },
    {
      Code: '1089000',
      Display: 'Congenital sepsis',
    },
    {
      Code: '1102005',
      Display: 'Intraerythrocytic parasitosis by Nuttallia',
    },
    {
      Code: '1107004',
      Display:
        'Early latent syphilis, positive serology, negative cerebrospinal fluid, with relapse after treatment',
    },
    {
      Code: '1108009',
      Display: 'Female pattern alopecia',
    },
    {
      Code: '1111005',
      Display: 'Normal sebaceous gland activity',
    },
    {
      Code: '1112003',
      Display: 'Degenerative disorder of eyelid',
    },
    {
      Code: '1116000',
      Display: 'Chronic aggressive type B viral hepatitis',
    },
    {
      Code: '1124005',
      Display: 'Postpartum period, 6 days',
    },
    {
      Code: '1125006',
      Display: 'Septicemia during labor',
    },
    {
      Code: '1126007',
      Display: 'Knee locking',
    },
    {
      Code: '1131009',
      Display: 'Congenital valvular insufficiency',
    },
    {
      Code: '1134001',
      Display: 'Muehrcke lines',
    },
    {
      Code: '1135000',
      Display: 'Solar retinitis',
    },
    {
      Code: '1139006',
      Display: 'Confrontation (visual) test',
    },
    {
      Code: '1140008',
      Display: 'Thermal hypesthesia',
    },
    {
      Code: '1141007',
      Display: 'Circumoral paresthesia',
    },
    {
      Code: '1145003',
      Display: 'DSD - Developmental speech disorder',
    },
    {
      Code: '1150009',
      Display: 'Congenital microcheilia',
    },
    {
      Code: '1151008',
      Display: 'Constricted visual field',
    },
    {
      Code: '1152001',
      Display: 'Skin reaction negative',
    },
    {
      Code: '1155004',
      Display: 'Myocardial hypertrophy, determined by electrocardiogram',
    },
    {
      Code: '1156003',
      Display: 'Cavitary prostatitis',
    },
    {
      Code: '1168007',
      Display: 'Allotype',
    },
    {
      Code: '1184008',
      Display: 'Glasgow coma scale, 10',
    },
    {
      Code: '1192004',
      Display: 'Familial amyloid neuropathy, Finnish type',
    },
    {
      Code: '1194003',
      Display: 'Disease condition determination, well controlled',
    },
    {
      Code: '1196001',
      Display:
        'Chronic bipolar II disorder, most recent episode major depressive',
    },
    {
      Code: '1197005',
      Display: 'Carbuncle of heel',
    },
    {
      Code: '1201005',
      Display: 'Benign essential hypertension',
    },
    {
      Code: '1203008',
      Display:
        'Deep third degree burn of forehead AND/OR cheek with loss of body part',
    },
    {
      Code: '1207009',
      Display: 'Optic disc glaucomatous atrophy',
    },
    {
      Code: '1208004',
      Display: 'Gastroptosis',
    },
    {
      Code: '1212005',
      Display: 'Juvenile dermatomyositis',
    },
    {
      Code: '1214006',
      Display: 'Infection by Strongyloides',
    },
    {
      Code: '1230003',
      Display: 'No diagnosis on Axis 1',
    },
    {
      Code: '1232006',
      Display: 'Congenital articular rigidity with myopathy',
    },
    {
      Code: '1239002',
      Display: 'Congenital anteversion of femoral neck',
    },
    {
      Code: '1240000',
      Display: 'Lying prone',
    },
    {
      Code: '1259003',
      Display: 'Schistosis',
    },
    {
      Code: '1261007',
      Display: 'Multiple fractures of ribs',
    },
    {
      Code: '1264004',
      Display:
        'Injury of descending left colon without open wound into abdominal cavity',
    },
    {
      Code: '1271009',
      Display: 'Knuckle pads, leuconychia and deafness',
    },
    {
      Code: '1280009',
      Display: 'Isologous chimera',
    },
    {
      Code: '1282001',
      Display: 'Laryngeal perichondritis',
    },
    {
      Code: '1283006',
      Display:
        'Visual acuity less than .02 (1/60, count fingers 1 meter) or visual field less than 5 degrees, but better than 5.',
    },
    {
      Code: '1284000',
      Display: 'Abnormal jaw closure',
    },
    {
      Code: '1286003',
      Display: 'Vitamin K deficiency coagulation disorder',
    },
    {
      Code: '1287007',
      Display: 'Congenital absence of bile duct',
    },
    {
      Code: '1297003',
      Display: 'Infection by Cladosporium carrionii',
    },
    {
      Code: '1308001',
      Display: 'Complication of reimplant',
    },
    {
      Code: '1310004',
      Display: 'Impaired glucose tolerance associated with genetic syndrome',
    },
    {
      Code: '1317001',
      Display: 'Injury of ovary without open wound into abdominal cavity',
    },
    {
      Code: '1318006',
      Display: 'Post-translational genetic protein processing',
    },
    {
      Code: '1323006',
      Display: 'Kanamycin poisoning',
    },
    {
      Code: '1332008',
      Display: 'Conjugated visual deviation',
    },
    {
      Code: '1335005',
      Display: 'Peyronies disease',
    },
    {
      Code: '1343000',
      Display: 'DTA - Deep transverse arrest',
    },
    {
      Code: '1345007',
      Display: 'Hang nail',
    },
    {
      Code: '1351002',
      Display: 'Iliac artery injury',
    },
    {
      Code: '1356007',
      Display: 'Calculus of common duct with obstruction',
    },
    {
      Code: '1361009',
      Display: 'Leucocoria',
    },
    {
      Code: '1363007',
      Display:
        'Fetal or neonatal effect of chronic maternal respiratory disease',
    },
    {
      Code: '1367008',
      Display: 'Injury of superior mesenteric artery',
    },
    {
      Code: '1370007',
      Display: 'Open fracture of metacarpal bone(s)',
    },
    {
      Code: '1372004',
      Display: 'Unicornate uterus',
    },
    {
      Code: '1376001',
      Display: 'Obsessive compulsive personality disorder',
    },
    {
      Code: '1378000',
      Display: 'Supination-eversion injury of ankle',
    },
    {
      Code: '1380006',
      Display:
        'Agoraphobia without history of panic disorder with limited symptom attacks',
    },
    {
      Code: '1383008',
      Display: 'Hallucinogen induced mood disorder',
    },
    {
      Code: '1384002',
      Display: 'Diffuse cholesteatosis of middle ear',
    },
    {
      Code: '1386000',
      Display: 'Intracranial hemorrhage',
    },
    {
      Code: '1387009',
      Display: 'Solanum nigrum poisoning',
    },
    {
      Code: '1388004',
      Display: 'Metabolic alkalosis',
    },
    {
      Code: '1393001',
      Display: 'Lenz-Majewski dysplasia',
    },
    {
      Code: '1395008',
      Display: 'Complication of ultrasound therapy',
    },
    {
      Code: '1402001',
      Display: 'Frightened',
    },
    {
      Code: '1412008',
      Display: 'Anterior subcapsular polar cataract',
    },
    {
      Code: '1415005',
      Display: 'Inflammation of lymphatics',
    },
    {
      Code: '1418007',
      Display: 'Hypoplastic chondrodystrophy',
    },
    {
      Code: '1419004',
      Display: 'Injury of prostate without open wound into abdominal cavity',
    },
    {
      Code: '1426004',
      Display: 'Necrotizing glomerulonephritis',
    },
    {
      Code: '1427008',
      Display: 'Intraspinal abscess',
    },
    {
      Code: '1430001',
      Display:
        'Intracranial hemorrhage following injury without open intracranial wound AND with prolonged loss of consciousness (more than 24 hours) without return to pre-existing level',
    },
    {
      Code: '1447000',
      Display: 'Icthyoparasitism',
    },
    {
      Code: '1469007',
      Display: 'Miscarriage with urinary tract infection',
    },
    {
      Code: '1474004',
      Display:
        'Hypertensive heart AND renal disease complicating AND/OR reason for care during childbirth',
    },
    {
      Code: '1475003',
      Display: 'Herpes labialis',
    },
    {
      Code: '1478001',
      Display: 'Obliteration of lymphatic vessel',
    },
    {
      Code: '1479009',
      Display: '20q partial trisomy syndrome',
    },
    {
      Code: '1482004',
      Display: 'Chalazion',
    },
    {
      Code: '1486001',
      Display: 'Orbital congestion',
    },
    {
      Code: '1488000',
      Display: 'PONV - Postoperative nausea and vomiting',
    },
    {
      Code: '1489008',
      Display: 'External hordeolum',
    },
    {
      Code: '1492007',
      Display: 'Congenital anomaly of large intestine',
    },
    {
      Code: '1493002',
      Display: 'Acute endophthalmitis',
    },
    {
      Code: '1499003',
      Display: 'Bipolar I disorder, single manic episode with postpartum onset',
    },
    {
      Code: '1512006',
      Display: 'Congenital stricture of bile duct',
    },
    {
      Code: '1515008',
      Display: 'Gorham disease',
    },
    {
      Code: '1518005',
      Display: 'Splenitis',
    },
    {
      Code: '1519002',
      Display: 'Congenital phlebectasia',
    },
    {
      Code: '1521007',
      Display: 'Blister of buttock without infection',
    },
    {
      Code: '1523005',
      Display: 'Clinical stage IV B',
    },
    {
      Code: '1525003',
      Display: 'Blister of foot without infection',
    },
    {
      Code: '1531000',
      Display: 'Nitrofuran derivative poisoning',
    },
    {
      Code: '1532007',
      Display: 'Viral pharyngitis',
    },
    {
      Code: '1534008',
      Display: 'Palsy of conjugate gaze',
    },
    {
      Code: '1538006',
      Display:
        'Central nervous system malformation in foetus affecting obstetrical care',
    },
    {
      Code: '1539003',
      Display: 'Nodular tendinous disease of finger',
    },
    {
      Code: '1542009',
      Display: 'Omphalocele with obstruction',
    },
    {
      Code: '1544005',
      Display: 'Open dislocation of knee',
    },
    {
      Code: '1551001',
      Display: 'Osteomyelitis of femur',
    },
    {
      Code: '1556006',
      Display: 'Clark melanoma level 4',
    },
    {
      Code: '1563006',
      Display: 'Protein S deficiency',
    },
    {
      Code: '1567007',
      Display:
        'Chronic gastric ulcer without hemorrhage, without perforation AND without obstruction',
    },
    {
      Code: '1588003',
      Display: 'Heterosexual precocious puberty',
    },
    {
      Code: '1592005',
      Display: 'Failed attempted termination of pregnancy with uremia',
    },
    {
      Code: '1593000',
      Display: 'Infantile hemiplegia',
    },
    {
      Code: '1606009',
      Display: 'Infection caused by Macracanthorhynchus hirudinaceus',
    },
    {
      Code: '1608005',
      Display: 'Increased capillary permeability',
    },
    {
      Code: '1639007',
      Display:
        'Abnormality of organs AND/OR soft tissues of pelvis affecting pregnancy',
    },
    {
      Code: '1647007',
      Display: 'Primaquine poisoning',
    },
    {
      Code: '1648002',
      Display: 'Lymphocytic pseudotumor of lung',
    },
    {
      Code: '1654001',
      Display: 'Steroid-induced glaucoma',
    },
    {
      Code: '1657008',
      Display: 'Toxic effect of phosdrin',
    },
    {
      Code: '1658003',
      Display: 'Closed fracture clavicle, lateral end',
    },
    {
      Code: '1663004',
      Display: 'Tumor grade G2',
    },
    {
      Code: '1667003',
      Display: 'Early fontanel closure',
    },
    {
      Code: '1670004',
      Display: 'Cerebral hemiparesis',
    },
    {
      Code: '1671000',
      Display: 'Sago spleen',
    },
    {
      Code: '1674008',
      Display: "Meesman's epithelial corneal dystrophy",
    },
    {
      Code: '1679003',
      Display: 'Arthritis associated with another disorder',
    },
    {
      Code: '1682008',
      Display: 'Transitory amino acid metabolic disorder',
    },
    {
      Code: '1685005',
      Display: 'Rat-bite fever',
    },
    {
      Code: '1686006',
      Display: 'Sedative, hypnotic AND/OR anxiolytic-induced anxiety disorder',
    },
    {
      Code: '1694004',
      Display: 'Accessory lobe of lung',
    },
    {
      Code: '1698001',
      Display: 'Ulcer of bile duct',
    },
    {
      Code: '1703007',
      Display: 'Increased leg circumference',
    },
    {
      Code: '1705000',
      Display: 'Closed fracture of base of neck of femur',
    },
    {
      Code: '1708003',
      Display: 'Open dislocation of clavicle',
    },
    {
      Code: '1714005',
      Display: 'Photokeratitis',
    },
    {
      Code: '1717003',
      Display: 'Guttate hypomelanosis',
    },
    {
      Code: '1723008',
      Display: 'Urethral stricture due to schistosomiasis',
    },
    {
      Code: '1724002',
      Display: 'Infection caused by Crenosoma',
    },
    {
      Code: '1734006',
      Display: 'Fracture of vertebral column with spinal cord injury',
    },
    {
      Code: '1735007',
      Display: 'Thrill',
    },
    {
      Code: '1739001',
      Display: 'Occipital fracture',
    },
    {
      Code: '1742007',
      Display: 'Female hypererotism',
    },
    {
      Code: '1744008',
      Display: 'Connation of teeth',
    },
    {
      Code: '1748006',
      Display: 'Thrombophlebitis of deep femoral vein',
    },
    {
      Code: '1755008',
      Display: 'Healed coronary',
    },
    {
      Code: '1761006',
      Display: 'Biliary cirrhosis',
    },
    {
      Code: '1763009',
      Display: 'Stromal keratitis',
    },
    {
      Code: '1767005',
      Display: 'Fisher syndrome',
    },
    {
      Code: '1769008',
      Display: 'Thoracodidymus',
    },
    {
      Code: '1771008',
      Display: 'Insulin biosynthesis defect',
    },
    {
      Code: '1776003',
      Display: 'RTA - Renal tubular acidosis',
    },
    {
      Code: '1777007',
      Display: 'Increased molecular dissociation',
    },
    {
      Code: '1778002',
      Display: 'Malocclusion due to abnormal swallowing',
    },
    {
      Code: '1779005',
      Display: 'OFD II - Orofacial-digital syndrome II',
    },
    {
      Code: '1794009',
      Display: 'Idiopathic corneal edema',
    },
    {
      Code: '1816003',
      Display:
        'Panic disorder with agoraphobia, severe agoraphobic avoidance AND mild panic attacks',
    },
    {
      Code: '1821000',
      Display: 'Chemoreceptor apnea',
    },
    {
      Code: '1822007',
      Display: 'Impaired glucose tolerance associated with pancreatic disease',
    },
    {
      Code: '1824008',
      Display: 'Allergic gastritis',
    },
    {
      Code: '1826005',
      Display: 'Granuloma of lip',
    },
    {
      Code: '1828006',
      Display: 'Infestation caused by Gasterophilus hemorrhoidalis',
    },
    {
      Code: '1829003',
      Display: 'Microcephalus',
    },
    {
      Code: '1833005',
      Display: 'Phacoanaphylactic endophthalmitis',
    },
    {
      Code: '1835003',
      Display: 'Necrosis of pancreas',
    },
    {
      Code: '1837006',
      Display: 'Orciprenaline poisoning',
    },
    {
      Code: '1845001',
      Display: 'Paraparesis',
    },
    {
      Code: '1847009',
      Display: 'Endophthalmitis',
    },
    {
      Code: '1848004',
      Display: 'Poisoning caused by gaseous anesthetic',
    },
    {
      Code: '1852004',
      Display: 'Traumatic injury of sixth cranial nerve',
    },
    {
      Code: '1855002',
      Display: 'Developmental academic disorder',
    },
    {
      Code: '1856001',
      Display: 'Accessory nose',
    },
    {
      Code: '1857005',
      Display: 'Congenital rubella syndrome',
    },
    {
      Code: '1858000',
      Display: 'Infection caused by Stilesia globipunctata',
    },
    {
      Code: '1860003',
      Display: 'Fluid volume disorder',
    },
    {
      Code: '1865008',
      Display: 'Impaired intestinal protein absorption',
    },
    {
      Code: '1869002',
      Display: 'Rupture of iris sphincter',
    },
    {
      Code: '1881003',
      Display: 'Increased nutritional requirement',
    },
    {
      Code: '1892002',
      Display: 'Star figure at the macula',
    },
    {
      Code: '1896004',
      Display: 'Ectopic breast tissue',
    },
    {
      Code: '1897008',
      Display: 'Amsinckia species poisoning',
    },
    {
      Code: '1899006',
      Display: 'Autosomal hereditary disorder',
    },
    {
      Code: '1903004',
      Display: 'Infestation caused by Psorergates ovis',
    },
    {
      Code: '1908008',
      Display: 'von Willebrand disease, type IIC',
    },
    {
      Code: '1909000',
      Display:
        'Impairment level: better eye: severe impairment: lesser eye: near-total impairment',
    },
    {
      Code: '1922008',
      Display: 'Congenital absence of urethra',
    },
    {
      Code: '1926006',
      Display: 'Osteopetrosis',
    },
    {
      Code: '1938002',
      Display:
        'Emotional AND/OR mental disease in mother complicating pregnancy, childbirth AND/OR puerperium',
    },
    {
      Code: '1939005',
      Display: 'Abnormal vascular flow',
    },
    {
      Code: '1943009',
      Display: 'Left-right confusion',
    },
    {
      Code: '1953005',
      Display: 'Congenital deficiency of pigment of skin',
    },
    {
      Code: '1954004',
      Display: 'Dilated cardiomyopathy secondary to toxic reaction',
    },
    {
      Code: '1955003',
      Display: 'Preauricular pit',
    },
    {
      Code: '1959009',
      Display: 'Encephalartos species poisoning',
    },
    {
      Code: '1961000',
      Display: 'Chronic polyarticular juvenile rheumatoid arthritis',
    },
    {
      Code: '1963002',
      Display: 'PNH - Paroxysmal nocturnal hemoglobinuria',
    },
    {
      Code: '1965009',
      Display: 'Normal skin pH',
    },
    {
      Code: '1967001',
      Display: 'Congenital absence of forearm only',
    },
    {
      Code: '1973000',
      Display:
        'Sedative, hypnotic AND/OR anxiolytic-induced psychotic disorder with delusions',
    },
    {
      Code: '1977004',
      Display: 'Oxymetholone poisoning',
    },
    {
      Code: '1979001',
      Display: 'Focal choroiditis',
    },
    {
      Code: '1980003',
      Display: 'Seromucinous otitis media',
    },
    {
      Code: '1981004',
      Display: 'Urhidrosis',
    },
    {
      Code: '1988005',
      Display:
        'Late effect of injury to nerve roots, spinal plexus AND/OR other nerves of trunk',
    },
    {
      Code: '1989002',
      Display: 'Burn of vagina AND/OR uterus',
    },
    {
      Code: '2004005',
      Display: 'Normotensive',
    },
    {
      Code: '2012002',
      Display: 'Fracture of lunate',
    },
    {
      Code: '2024009',
      Display: 'Dilated cardiomyopathy secondary to metazoal myocarditis',
    },
    {
      Code: '2028007',
      Display: 'Erythema induratum',
    },
    {
      Code: '2032001',
      Display: 'Cerebral edema',
    },
    {
      Code: '2036003',
      Display: 'Acquired factor VII deficiency disease',
    },
    {
      Code: '2040007',
      Display: 'Neurogenic thoracic outlet syndrome',
    },
    {
      Code: '2041006',
      Display: 'Eunuchoid gigantism',
    },
    {
      Code: '2043009',
      Display: 'Alcoholic gastritis',
    },
    {
      Code: '2053005',
      Display:
        'Late effect of injury to blood vessels of thorax, abdomen AND/OR pelvis',
    },
    {
      Code: '2055003',
      Display: 'Recurrent erosion syndrome',
    },
    {
      Code: '2058001',
      Display: 'Bilateral loss of labyrinthine reactivity',
    },
    {
      Code: '2061000',
      Display: 'Conductive hearing loss of combined sites',
    },
    {
      Code: '2065009',
      Display: 'Autosomal dominant optic atrophy',
    },
    {
      Code: '2066005',
      Display:
        'Gastric ulcer with hemorrhage AND perforation but without obstruction',
    },
    {
      Code: '2070002',
      Display: 'Burning sensation in eye',
    },
    {
      Code: '2073000',
      Display: 'Delusions',
    },
    {
      Code: '2087000',
      Display: 'Pulmonary nocardiosis',
    },
    {
      Code: '2089002',
      Display: 'Pagets disease of bone',
    },
    {
      Code: '2091005',
      Display: 'Pharyngeal diverticulitis',
    },
    {
      Code: '2094002',
      Display: 'Carbon disulfide causing toxic effect',
    },
    {
      Code: '2102007',
      Display: 'Deep corneal vascularization',
    },
    {
      Code: '2103002',
      Display: 'Reflex sympathetic dystrophy of upper extremity',
    },
    {
      Code: '2107001',
      Display: 'Anisomelia',
    },
    {
      Code: '2109003',
      Display: 'Isolated somatotropin deficiency',
    },
    {
      Code: '2114004',
      Display: 'Infection caused by Cysticercus pisiformis',
    },
    {
      Code: '2116002',
      Display: 'Intramembranous bone formation',
    },
    {
      Code: '2120003',
      Display: 'Weak cry',
    },
    {
      Code: '2121004',
      Display: 'Ethopropazine poisoning',
    },
    {
      Code: '2128005',
      Display: 'Disorder of adenoid',
    },
    {
      Code: '2129002',
      Display: 'Edema of pharynx',
    },
    {
      Code: '2132004',
      Display:
        'Meconium in amniotic fluid noted before labor in liveborn infant',
    },
    {
      Code: '2134003',
      Display: 'Diffuse pain',
    },
    {
      Code: '2136001',
      Display: 'Open wound of jaw with complication',
    },
    {
      Code: '2138000',
      Display: 'LSP - Left sacroposterior position',
    },
    {
      Code: '2145000',
      Display: 'Anal intercourse',
    },
    {
      Code: '2149006',
      Display: 'Decreased hormone production',
    },
    {
      Code: '2158004',
      Display: 'Infection caused by Contracaecum',
    },
    {
      Code: '2167004',
      Display: 'Retinal hemangioblastomatosis',
    },
    {
      Code: '2169001',
      Display: 'Thoracic radiculitis',
    },
    {
      Code: '2170000',
      Display: 'Gallop rhythm',
    },
    {
      Code: '2176006',
      Display: 'Halogen acne',
    },
    {
      Code: '2177002',
      Display: 'PHN - Post-herpetic neuralgia',
    },
    {
      Code: '2186007',
      Display: 'Compensated metabolic alkalosis',
    },
    {
      Code: '2198002',
      Display: 'Visceral epilepsy',
    },
    {
      Code: '2202000',
      Display: 'Open posterior dislocation of distal end of femur',
    },
    {
      Code: '2204004',
      Display: 'Acquired deformity of pinna',
    },
    {
      Code: '2213002',
      Display: 'Congenital anomaly of vena cava',
    },
    {
      Code: '2216005',
      Display: 'Nocturnal emission',
    },
    {
      Code: '2217001',
      Display: 'Superficial injury of perineum without infection',
    },
    {
      Code: '2219003',
      Display: 'Disability evaluation, disability 100%',
    },
    {
      Code: '2224000',
      Display: 'Selenium poisoning',
    },
    {
      Code: '2228002',
      Display: 'Scintillating scotoma',
    },
    {
      Code: '2229005',
      Display: 'Chimera',
    },
    {
      Code: '2231001',
      Display: 'Nerve plexus disorder',
    },
    {
      Code: '2237002',
      Display: 'Painful breathing -pleurodynia',
    },
    {
      Code: '2239004',
      Display: 'Previous pregnancies 6',
    },
    {
      Code: '2241003',
      Display: 'X-linked absence of thyroxine-binding globulin',
    },
    {
      Code: '2243000',
      Display: 'Hypercalcemia due to hyperthyroidism',
    },
    {
      Code: '2245007',
      Display: 'Foreign body in nasopharynx',
    },
    {
      Code: '2251002',
      Display: 'Primary hypotony of eye',
    },
    {
      Code: '2256007',
      Display: 'Monovular twins',
    },
    {
      Code: '2261009',
      Display: 'Obstetrical pulmonary fat embolism',
    },
    {
      Code: '2268003',
      Display: 'Victim of homosexual aggression',
    },
    {
      Code: '2284002',
      Display: 'Pulsating exophthalmos',
    },
    {
      Code: '2295008',
      Display: 'Closed fracture of upper end of forearm',
    },
    {
      Code: '2296009',
      Display: 'Iron dextran toxicity',
    },
    {
      Code: '2298005',
      Display: 'Focal facial dermal dysplasia',
    },
    {
      Code: '2301009',
      Display:
        'Psychosomatic factor in physical condition, psychological component of unknown degree',
    },
    {
      Code: '2303007',
      Display: 'Inguinal hernia with gangrene',
    },
    {
      Code: '2304001',
      Display: 'Intervertebral discitis',
    },
    {
      Code: '2307008',
      Display: 'Peripancreatic fat necrosis',
    },
    {
      Code: '2308003',
      Display: 'Silent alleles',
    },
    {
      Code: '2312009',
      Display:
        'Reactive attachment disorder of infancy OR early childhood, inhibited type',
    },
    {
      Code: '2314005',
      Display: 'Unprotected intercourse',
    },
    {
      Code: '2326000',
      Display: 'Marriage annulment',
    },
    {
      Code: '2339001',
      Display: 'Sexual overexposure',
    },
    {
      Code: '2341000',
      Display: 'Infection caused by Moniliformis',
    },
    {
      Code: '2351004',
      Display: 'Genetic transduction',
    },
    {
      Code: '2355008',
      Display: 'Rud syndrome',
    },
    {
      Code: '2359002',
      Display: 'Hyper-beta-alaninemia',
    },
    {
      Code: '2365002',
      Display: 'Simple chronic pharyngitis',
    },
    {
      Code: '2366001',
      Display: 'Late effect of dislocation',
    },
    {
      Code: '2367005',
      Display: 'Acute hemorrhagic gastritis',
    },
    {
      Code: '2374000',
      Display: 'Monofascicular block',
    },
    {
      Code: '2385003',
      Display: 'Cellulitis of pectoral region',
    },
    {
      Code: '2388001',
      Display: 'Normal variation in translucency',
    },
    {
      Code: '2390000',
      Display: 'Acute gonococcal vulvovaginitis',
    },
    {
      Code: '2391001',
      Display: 'Achondrogenesis',
    },
    {
      Code: '2396006',
      Display: 'Malignant pyoderma',
    },
    {
      Code: '2398007',
      Display: 'Quinidine toxicity by electrocardiogram',
    },
    {
      Code: '2403008',
      Display: 'Dependence syndrome',
    },
    {
      Code: '2415007',
      Display: 'Lumbosacral root lesion',
    },
    {
      Code: '2418009',
      Display: 'Polyester fume causing toxic effect',
    },
    {
      Code: '2419001',
      Display: 'Open wound of forehead with complication',
    },
    {
      Code: '2420007',
      Display: 'Third degree burn of multiple sites of upper limb',
    },
    {
      Code: '2432006',
      Display: 'Cerebrospinal fluid circulation disorder',
    },
    {
      Code: '2435008',
      Display: 'Ascaridiasis',
    },
    {
      Code: '2437000',
      Display: 'Placenta circumvallata',
    },
    {
      Code: '2438005',
      Display: 'Iniencephaly',
    },
    {
      Code: '2439002',
      Display: 'Purulent endocarditis',
    },
    {
      Code: '2443003',
      Display: 'Hydrogen sulfide poisoning',
    },
    {
      Code: '2452007',
      Display: 'Fetal rotation',
    },
    {
      Code: '2463005',
      Display: 'Acquired heterochromia of iris',
    },
    {
      Code: '2469009',
      Display: 'Onychomalacia',
    },
    {
      Code: '2470005',
      Display: 'Brain damage',
    },
    {
      Code: '2471009',
      Display: 'Intra-abdominal abscess postprocedure',
    },
    {
      Code: '2472002',
      Display: 'Passes no urine',
    },
    {
      Code: '2473007',
      Display: 'Intermittent vertical squint',
    },
    {
      Code: '2477008',
      Display: 'Superficial phlebitis',
    },
    {
      Code: '2492009',
      Display: 'Disorder of nutrition',
    },
    {
      Code: '2495006',
      Display: 'Congenital cerebral arteriovenous aneurysm',
    },
    {
      Code: '2496007',
      Display: 'Acalculia',
    },
    {
      Code: '2506003',
      Display: 'Early onset dysthymia',
    },
    {
      Code: '2513003',
      Display: 'Tinea capitis caused by Trichophyton',
    },
    {
      Code: '2518007',
      Display: 'Cryptogenic sexual precocity',
    },
    {
      Code: '2521009',
      Display: 'Bone conduction better than air',
    },
    {
      Code: '2523007',
      Display: 'Salmonella pneumonia',
    },
    {
      Code: '2526004',
      Display: 'Noninflammatory disorder of the female genital organs',
    },
    {
      Code: '2528003',
      Display: 'Viremia',
    },
    {
      Code: '2532009',
      Display: 'Choroidal rupture',
    },
    {
      Code: '2534005',
      Display: 'Congenital absence of vena cava',
    },
    {
      Code: '2538008',
      Display: 'Ketosis',
    },
    {
      Code: '2541004',
      Display: 'Compulsive buying',
    },
    {
      Code: '2554006',
      Display: 'Acute purulent pericarditis',
    },
    {
      Code: '2556008',
      Display: 'Disease of supporting structures of teeth',
    },
    {
      Code: '2560006',
      Display: 'Complex syndactyly of fingers',
    },
    {
      Code: '2562003',
      Display: 'Athanasia trifurcata poisoning',
    },
    {
      Code: '2576002',
      Display: 'Trachoma',
    },
    {
      Code: '2581006',
      Display: 'Clasp knife rigidity',
    },
    {
      Code: '2582004',
      Display: 'Deep third degree burn of multiple sites of lower limb',
    },
    {
      Code: '2583009',
      Display: 'Filigreed network of venous valves',
    },
    {
      Code: '2584003',
      Display: 'Cerebral degeneration in childhood',
    },
    {
      Code: '2585002',
      Display: 'Pneumococcal pleurisy',
    },
    {
      Code: '2589008',
      Display: 'Acute dacryoadenitis',
    },
    {
      Code: '2591000',
      Display: 'Crush injury of shoulder region',
    },
    {
      Code: '2593002',
      Display: 'Dubowitz syndrome',
    },
    {
      Code: '2602008',
      Display: 'Hemarthrosis of shoulder',
    },
    {
      Code: '2606006',
      Display: 'Boil of perineum',
    },
    {
      Code: '2615004',
      Display: 'Graafian follicle cyst',
    },
    {
      Code: '2618002',
      Display: 'Chronic recurrent major depressive disorder',
    },
    {
      Code: '2622007',
      Display: 'Infected ulcer of skin',
    },
    {
      Code: '2624008',
      Display: 'Prepubertal periodontitis',
    },
    {
      Code: '2625009',
      Display: 'Senter syndrome',
    },
    {
      Code: '2630008',
      Display: 'Open wound of finger without complication',
    },
    {
      Code: '2634004',
      Display: 'Decreased blood erythrocyte volume',
    },
    {
      Code: '2638001',
      Display: 'Hypercalcemia caused by a drug',
    },
    {
      Code: '2640006',
      Display: 'Clinical stage 4',
    },
    {
      Code: '2651006',
      Display: 'Closed traumatic dislocation of elbow joint',
    },
    {
      Code: '2655002',
      Display: 'Invalidism',
    },
    {
      Code: '2657005',
      Display: 'Overflow proteinuria',
    },
    {
      Code: '2663001',
      Display: 'Palpatory proteinuria',
    },
    {
      Code: '2665008',
      Display: 'Coordinate convulsion',
    },
    {
      Code: '2683000',
      Display: 'Nonvenomous insect bite of axilla without infection',
    },
    {
      Code: '2689001',
      Display: 'Dominant dystrophic epidermolysis bullosa with absence of skin',
    },
    {
      Code: '2694001',
      Display: 'Myelophthisic anemia',
    },
    {
      Code: '2704003',
      Display: 'Acute disease',
    },
    {
      Code: '2707005',
      Display: 'Necrotizing enterocolitis',
    },
    {
      Code: '2713001',
      Display: 'Closed pneumothorax',
    },
    {
      Code: '2724004',
      Display: 'Auditory recruitment',
    },
    {
      Code: '2725003',
      Display: 'Previous abnormality of glucose tolerance',
    },
    {
      Code: '2733002',
      Display: 'Heel pain',
    },
    {
      Code: '2736005',
      Display: 'Honeycomb atrophy of face',
    },
    {
      Code: '2740001',
      Display: 'Gouty proteinuria',
    },
    {
      Code: '2749000',
      Display: 'Congenital deformity of hip',
    },
    {
      Code: '2751001',
      Display: 'Fibrocalculous pancreatic diabetes',
    },
    {
      Code: '2761008',
      Display: 'Decreased stool caliber',
    },
    {
      Code: '2764000',
      Display: 'Joint crackle',
    },
    {
      Code: '2770006',
      Display:
        'Fetal or neonatal effect of antibiotic transmitted via placenta and/or breast milk',
    },
    {
      Code: '2772003',
      Display: 'Epidermolysis bullosa acquisita',
    },
    {
      Code: '2775001',
      Display: 'Intra-articular loose body',
    },
    {
      Code: '2776000',
      Display: 'Organic brain syndrome',
    },
    {
      Code: '2781009',
      Display: 'Miscarriage complicated by delayed and/or excessive hemorrhage',
    },
    {
      Code: '2782002',
      Display: 'Temporomandibular dysplasia',
    },
    {
      Code: '2783007',
      Display: 'Gastrojejunal ulcer without hemorrhage AND without perforation',
    },
    {
      Code: '2786004',
      Display:
        'Epithelial ovarian tumor, International Federation of Gynecology and Obstetrics stage III',
    },
    {
      Code: '2790002',
      Display:
        'Impairment level: one eye: total impairment: other eye: not specified',
    },
    {
      Code: '2805007',
      Display: 'Phosmet poisoning',
    },
    {
      Code: '2806008',
      Display: 'Impaired psychomotor development',
    },
    {
      Code: '2807004',
      Display: 'Chronic gastrojejunal ulcer with perforation',
    },
    {
      Code: '2808009',
      Display: 'Infection caused by Prosthenorchis elegans',
    },
    {
      Code: '2815001',
      Display: 'Sexual pyromania',
    },
    {
      Code: '2816000',
      Display: 'Dilated cardiomyopathy secondary to myotonic dystrophy',
    },
    {
      Code: '2818004',
      Display: 'Congenital vascular anomaly of eye',
    },
    {
      Code: '2819007',
      Display: 'Magnesium sulfate poisoning',
    },
    {
      Code: '2825006',
      Display: 'Abrasion and/or friction burn of gum without infection',
    },
    {
      Code: '2828008',
      Display: 'Congenital stenosis of nares',
    },
    {
      Code: '2829000',
      Display: 'Uhl disease',
    },
    {
      Code: '2831009',
      Display: 'Pyloric antral vascular ectasia',
    },
    {
      Code: '2835000',
      Display: 'Hemolytic anemia due to cardiac trauma',
    },
    {
      Code: '2836004',
      Display: 'Butane causing toxic effect',
    },
    {
      Code: '2838003',
      Display: 'Piblokto',
    },
    {
      Code: '2840008',
      Display:
        'Open fracture of vault of skull with cerebral laceration AND/OR contusion',
    },
    {
      Code: '2850009',
      Display: 'Infection caused by Schistosoma incognitum',
    },
    {
      Code: '2853006',
      Display: 'Macular keratitis',
    },
    {
      Code: '2856003',
      Display: 'Vitamin A-responsive dermatosis',
    },
    {
      Code: '2858002',
      Display: 'Postpartum sepsis',
    },
    {
      Code: '2884008',
      Display: 'Spherophakia-brachymorphia syndrome',
    },
    {
      Code: '2893009',
      Display: 'Anomaly of chromosome pair 10',
    },
    {
      Code: '2897005',
      Display: 'Immune thrombocytopenia',
    },
    {
      Code: '2899008',
      Display: 'Thought blocking',
    },
    {
      Code: '2900003',
      Display: 'Fibromuscular dysplasia of renal artery',
    },
    {
      Code: '2901004',
      Display: 'Altered blood passed per rectum',
    },
    {
      Code: '2902006',
      Display: 'Decreased lymphocyte life span',
    },
    {
      Code: '2904007',
      Display: 'Male infertility',
    },
    {
      Code: '2910007',
      Display: 'Discharge from penis',
    },
    {
      Code: '2912004',
      Display: 'Cystic-bullous disease of the lung',
    },
    {
      Code: '2917005',
      Display: 'Transient hypothyroidism',
    },
    {
      Code: '2918000',
      Display: 'Infection caused by Bacteroides',
    },
    {
      Code: '2919008',
      Display: 'Nausea, vomiting and diarrhea',
    },
    {
      Code: '2929001',
      Display: 'Arterial occlusion',
    },
    {
      Code: '2935001',
      Display: 'Antiasthmatic poisoning',
    },
    {
      Code: '2940009',
      Display: 'Intrabasal vesicular dermatitis',
    },
    {
      Code: '2946003',
      Display: 'Osmotic diarrhea',
    },
    {
      Code: '2951009',
      Display: 'Atopic cataract',
    },
    {
      Code: '2955000',
      Display: 'Chronic ulcerative pulpitis',
    },
    {
      Code: '2965006',
      Display: 'Nevoid congenital alopecia',
    },
    {
      Code: '2967003',
      Display: 'Non-comitant strabismus',
    },
    {
      Code: '2972007',
      Display: 'Occlusion of anterior spinal artery',
    },
    {
      Code: '2973002',
      Display: 'Pelvic organ injury without open wound into abdominal cavity',
    },
    {
      Code: '2978006',
      Display: 'Aneurysm of conjunctiva',
    },
    {
      Code: '2981001',
      Display: 'Pulsatile mass of abdomen',
    },
    {
      Code: '2989004',
      Display: 'Complication following molar AND/OR ectopic pregnancy',
    },
    {
      Code: '2990008',
      Display: 'Lymphocytic leukemoid reaction',
    },
    {
      Code: '2992000',
      Display: 'Pigmentary pallidal degeneration',
    },
    {
      Code: '2994004',
      Display: 'Brain fag',
    },
    {
      Code: '2999009',
      Display: 'Injury of ear region',
    },
    {
      Code: '3002002',
      Display: 'Thyroid hemorrhage',
    },
    {
      Code: '3004001',
      Display: 'Congenital dilatation of esophagus',
    },
    {
      Code: '3006004',
      Display: 'Altered consciousness',
    },
    {
      Code: '3009006',
      Display: 'Solanum malacoxylon poisoning',
    },
    {
      Code: '3013004',
      Display: 'Open wound of ear drum without complication',
    },
    {
      Code: '3014005',
      Display: 'Autoeczematization',
    },
    {
      Code: '3018008',
      Display: 'Penetration of eyeball with magnetic foreign body',
    },
    {
      Code: '3019000',
      Display: 'Closed anterior dislocation of elbow',
    },
    {
      Code: '3021005',
      Display: 'Normal gastric acidity',
    },
    {
      Code: '3023008',
      Display:
        'Acute peptic ulcer without hemorrhage, without perforation AND without obstruction',
    },
    {
      Code: '3032005',
      Display: 'Nonvenomous insect bite of cheek without infection',
    },
    {
      Code: '3033000',
      Display:
        'Bone AND/OR joint disorder of pelvis in mother complicating pregnancy, childbirth AND/OR puerperium',
    },
    {
      Code: '3038009',
      Display: 'Acute lymphangitis of umbilicus',
    },
    {
      Code: '3044008',
      Display: 'Vitreous prolapse',
    },
    {
      Code: '3053001',
      Display: 'Poisoning caused by nitroglycerin',
    },
    {
      Code: '3059002',
      Display: 'Acute lymphangitis of thigh',
    },
    {
      Code: '3067005',
      Display: 'Weak C phenotype',
    },
    {
      Code: '3071008',
      Display: 'Widow',
    },
    {
      Code: '3072001',
      Display: 'Hormone-induced hypopituitarism',
    },
    {
      Code: '3073006',
      Display: 'Ruvalcaba syndrome',
    },
    {
      Code: '3084004',
      Display: 'Nonvenomous insect bite of gum without infection',
    },
    {
      Code: '3089009',
      Display: 'Disability evaluation, impairment, class 7',
    },
    {
      Code: '3094009',
      Display: 'Vomiting in infants AND/OR children',
    },
    {
      Code: '3095005',
      Display: 'Induced malaria',
    },
    {
      Code: '3097002',
      Display: 'Superficial injury of lip with infection',
    },
    {
      Code: '3098007',
      Display: 'Ventricular septal rupture',
    },
    {
      Code: '3105002',
      Display: 'Intron',
    },
    {
      Code: '3109008',
      Display: 'Secondary dysthymia early onset',
    },
    {
      Code: '3110003',
      Display: 'AOM - Acute otitis media',
    },
    {
      Code: '3119002',
      Display:
        'Brain stem laceration with open intracranial wound AND loss of consciousness',
    },
    {
      Code: '3129009',
      Display: 'Infarction of ovary',
    },
    {
      Code: '3135009',
      Display: 'OE - Otitis externa',
    },
    {
      Code: '3140001',
      Display: 'Citrullinemia, subacute type',
    },
    {
      Code: '3144005',
      Display: 'Staphylococcal pleurisy',
    },
    {
      Code: '3158007',
      Display:
        'Panic disorder with agoraphobia, agoraphobic avoidance in partial remission AND panic attacks in partial remission',
    },
    {
      Code: '3160009',
      Display: 'Infertility of cervical origin',
    },
    {
      Code: '3163006',
      Display: 'Acute adenoviral follicular conjunctivitis',
    },
    {
      Code: '3168002',
      Display: 'Thrombophlebitis of intracranial venous sinus',
    },
    {
      Code: '3185000',
      Display: 'Mood-congruent delusion',
    },
    {
      Code: '3199001',
      Display: 'Sprain of shoulder joint',
    },
    {
      Code: '3200003',
      Display: 'Sacrocoxalgia',
    },
    {
      Code: '3208005',
      Display: 'Open wound of ossicles without complication',
    },
    {
      Code: '3214003',
      Display: 'Invasive pulmonary aspergillosis',
    },
    {
      Code: '3217005',
      Display: 'Open dislocation of sixth cervical vertebra',
    },
    {
      Code: '3218000',
      Display: 'Mycotic disease',
    },
    {
      Code: '3219008',
      Display: 'Disease type AND/OR category unknown',
    },
    {
      Code: '3228009',
      Display: 'Closed fracture of the radial shaft',
    },
    {
      Code: '3229001',
      Display: 'Tracheal ulcer',
    },
    {
      Code: '3230006',
      Display: 'Illegal termination of pregnancy with afibrinogenemia',
    },
    {
      Code: '3238004',
      Display: 'Pericarditis',
    },
    {
      Code: '3239007',
      Display: 'Lymphocyte disorder',
    },
    {
      Code: '3253007',
      Display: 'Dyschromia',
    },
    {
      Code: '3254001',
      Display: 'Infection caused by Strongyloides westeri',
    },
    {
      Code: '3259006',
      Display: 'Homeria species poisoning',
    },
    {
      Code: '3261002',
      Display: 'Migratory osteolysis',
    },
    {
      Code: '3263004',
      Display: 'Verumontanitis',
    },
    {
      Code: '3272007',
      Display: 'Stomatocytosis',
    },
    {
      Code: '3274008',
      Display: 'Flat chest',
    },
    {
      Code: '3275009',
      Display: 'Behcet syndrome, vascular type',
    },
    {
      Code: '3276005',
      Display: 'Toad poisoning',
    },
    {
      Code: '3277001',
      Display: 'Terminal mood insomnia',
    },
    {
      Code: '3282008',
      Display: 'Arc eye',
    },
    {
      Code: '3283003',
      Display: 'Feeling of sand or foreign body in eye',
    },
    {
      Code: '3286006',
      Display: 'Patient status determination, greatly improved',
    },
    {
      Code: '3289004',
      Display: 'Anisometropia',
    },
    {
      Code: '3291007',
      Display: 'Closed fracture of two ribs',
    },
    {
      Code: '3298001',
      Display: 'Amnestic syndrome',
    },
    {
      Code: '3303004',
      Display: 'Disease caused by Arenavirus',
    },
    {
      Code: '3304005',
      Display: 'Bronchial compression',
    },
    {
      Code: '3305006',
      Display: 'Disorder of lymphatic vessel',
    },
    {
      Code: '3308008',
      Display: 'Atrophic-hyperplastic gastritis',
    },
    {
      Code: '3310005',
      Display: 'Foreign body granuloma of skin',
    },
    {
      Code: '3321001',
      Display: 'Renal abscess',
    },
    {
      Code: '3323003',
      Display: 'Leukoplakia of penis',
    },
    {
      Code: '3327002',
      Display: 'Acquired jerk nystagmus',
    },
    {
      Code: '3331008',
      Display: 'Open fracture of neck of metacarpal bone',
    },
    {
      Code: '3344003',
      Display: 'Toxic labyrinthitis',
    },
    {
      Code: '3345002',
      Display: 'Idiopathic osteoporosis',
    },
    {
      Code: '3355003',
      Display: 'Anti-common cold drug poisoning',
    },
    {
      Code: '3358001',
      Display: 'Lichen ruber moniliformis',
    },
    {
      Code: '3368006',
      Display: 'Dull chest pain',
    },
    {
      Code: '3376008',
      Display: 'Pseudoptyalism',
    },
    {
      Code: '3381004',
      Display: 'Open fracture of astragalus',
    },
    {
      Code: '3387000',
      Display: 'Auditory discrimination aphasia',
    },
    {
      Code: '3391005',
      Display: 'Negative for tumor cells',
    },
    {
      Code: '3393008',
      Display: 'Phlebitis following infusion, perfusion AND/OR transfusion',
    },
    {
      Code: '3398004',
      Display: 'Cadmium poisoning',
    },
    {
      Code: '3401001',
      Display: 'Cercopithecus herpesvirus 1 disease',
    },
    {
      Code: '3415004',
      Display: 'Cyanosis',
    },
    {
      Code: '3419005',
      Display: 'Faucial diphtheria',
    },
    {
      Code: '3421000',
      Display: 'Open blow-out fracture orbit',
    },
    {
      Code: '3424008',
      Display: 'Heart rate fast',
    },
    {
      Code: '3426005',
      Display: 'Retained magnetic intraocular foreign body',
    },
    {
      Code: '3427001',
      Display: 'Nonglucosuric melituria',
    },
    {
      Code: '3434004',
      Display: 'Myotonia',
    },
    {
      Code: '3439009',
      Display:
        'Severe combined immunodeficiency (SCID) due to absent peripheral T cell maturation',
    },
    {
      Code: '3441005',
      Display: 'Disorder of sebaceous gland',
    },
    {
      Code: '3446000',
      Display: 'Open fracture of T7-T12 level with spinal cord injury',
    },
    {
      Code: '3449007',
      Display: 'Finger agnosia',
    },
    {
      Code: '3456001',
      Display: 'Chronic progressive non-hereditary chorea',
    },
    {
      Code: '3458000',
      Display: 'Myositis ossificans associated with dermato / polymyositis',
    },
    {
      Code: '3461004',
      Display: 'Deep third degree burn of thumb',
    },
    {
      Code: '3464007',
      Display: 'Infection caused by Oesophagostomum dentatum',
    },
    {
      Code: '3468005',
      Display: 'Neonatal infective mastitis',
    },
    {
      Code: '3469002',
      Display: 'Partial thickness burn of thumb',
    },
    {
      Code: '3472009',
      Display: 'Spondylolisthesis, grade 4',
    },
    {
      Code: '3474005',
      Display: 'Glycine max poisoning',
    },
    {
      Code: '3480002',
      Display: 'Burn of wrist',
    },
    {
      Code: '3482005',
      Display: 'Postoperative esophagitis',
    },
    {
      Code: '3483000',
      Display: 'Chronic peptic ulcer with perforation',
    },
    {
      Code: '3487004',
      Display: 'Pulmonary candidiasis',
    },
    {
      Code: '3500002',
      Display: 'Open wound of ossicles with complication',
    },
    {
      Code: '3502005',
      Display: 'Cervical lymphadenitis',
    },
    {
      Code: '3503000',
      Display: 'Gender identity disorder of adolescence, previously asexual',
    },
    {
      Code: '3505007',
      Display: 'Nonallopathic lesion of the arm',
    },
    {
      Code: '3506008',
      Display: 'Stenosis of retinal artery',
    },
    {
      Code: '3507004',
      Display: 'Abscess of thigh',
    },
    {
      Code: '3511005',
      Display: 'Infectious thyroiditis',
    },
    {
      Code: '3514002',
      Display: 'Peribronchial fibrosis of lung',
    },
    {
      Code: '3519007',
      Display: 'Disorder of synovium',
    },
    {
      Code: '3528008',
      Display: 'Restricted carbohydrate fat controlled diet',
    },
    {
      Code: '3529000',
      Display: 'Infection caused by Sanguinicola',
    },
    {
      Code: '3530005',
      Display: 'Bipolar 1 disorder, single manic episode, full remission',
    },
    {
      Code: '3531009',
      Display: 'Intrapsychic conflict',
    },
    {
      Code: '3533007',
      Display: 'Acute palmoplantar pustular psoriasis',
    },
    {
      Code: '3539006',
      Display: 'Enteromenia',
    },
    {
      Code: '3542000',
      Display:
        'Laceration extending into parenchyma of spleen with open wound into abdominal cavity',
    },
    {
      Code: '3544004',
      Display: 'Hair-splitting',
    },
    {
      Code: '3545003',
      Display: 'Diastolic dysfunction',
    },
    {
      Code: '3548001',
      Display: 'Brachial plexus disorder',
    },
    {
      Code: '3549009',
      Display: 'Pancreatic acinar atrophy',
    },
    {
      Code: '3558002',
      Display: 'Mesenteric infarction',
    },
    {
      Code: '3560000',
      Display: 'Bilateral recurrent inguinal hernia',
    },
    {
      Code: '3570003',
      Display: 'Increased blood erythrocyte volume',
    },
    {
      Code: '3571004',
      Display: 'Megaloblastic anemia due to pancreatic insufficiency',
    },
    {
      Code: '3577000',
      Display: 'Lattice retinal degeneration',
    },
    {
      Code: '3585009',
      Display: 'Blinking',
    },
    {
      Code: '3586005',
      Display: 'Psychogenic fugue',
    },
    {
      Code: '3589003',
      Display: 'Syphilitic pericarditis',
    },
    {
      Code: '3590007',
      Display: 'Enteroenteric fistula',
    },
    {
      Code: '3591006',
      Display: 'Metabolic acidosis, normal anion gap, bicarbonate losses',
    },
    {
      Code: '3598000',
      Display: 'Partial recent retinal detachment with single defect',
    },
    {
      Code: '3611003',
      Display: 'Demeton poisoning',
    },
    {
      Code: '3633001',
      Display: 'Abscess of hand',
    },
    {
      Code: '3634007',
      Display:
        'Legal termination of pregnancy complicated by metabolic disorder',
    },
    {
      Code: '3639002',
      Display: 'Glossoptosis',
    },
    {
      Code: '3640000',
      Display: 'Late effect of traumatic amputation',
    },
    {
      Code: '3641001',
      Display: 'Infection caused by Coenurosis serialis',
    },
    {
      Code: '3642008',
      Display: 'Steryl-sulfate sulfohydrolase deficiency',
    },
    {
      Code: '3644009',
      Display: 'Macerated skin',
    },
    {
      Code: '3649004',
      Display: 'Contusion, multiple sites of trunk',
    },
    {
      Code: '3650004',
      Display: 'Congenital absence of liver,total',
    },
    {
      Code: '3652007',
      Display: 'Overproduction of growth hormone',
    },
    {
      Code: '3657001',
      Display: 'Osteospermum species poisoning',
    },
    {
      Code: '3660008',
      Display: 'Lethal glossopharyngeal defect',
    },
    {
      Code: '3662000',
      Display: 'Rolling hiatus hernia',
    },
    {
      Code: '3677008',
      Display: 'Academic problem',
    },
    {
      Code: '3680009',
      Display: 'Monocephalus tripus dibrachius',
    },
    {
      Code: '3681008',
      Display: 'Thrombophlebitis of torcular Herophili',
    },
    {
      Code: '3696007',
      Display: 'Functional dyspepsia',
    },
    {
      Code: '3699000',
      Display: 'Transverse deficiency of arm',
    },
    {
      Code: '3703002',
      Display: 'Ischiatic hernia with gangrene',
    },
    {
      Code: '3704008',
      Display: 'Diffuse endocapillary proliferative glomerulonephritis',
    },
    {
      Code: '3705009',
      Display: 'Congenital malformation of anterior chamber of eye',
    },
    {
      Code: '3712000',
      Display: 'Degenerated eye',
    },
    {
      Code: '3716002',
      Display: 'Thyroid goiter',
    },
    {
      Code: '3720003',
      Display: 'Abnormal presence of hemoglobin',
    },
    {
      Code: '3723001',
      Display: 'Joint inflammation',
    },
    {
      Code: '3733009',
      Display: 'Congenital eventration of right crus of diaphragm',
    },
    {
      Code: '3736001',
      Display: 'Open wound of thumbnail with tendon involvement',
    },
    {
      Code: '3738000',
      Display: 'VH - Viral hepatitis',
    },
    {
      Code: '3744001',
      Display: 'Hyperlipoproteinemia',
    },
    {
      Code: '3745000',
      Display: 'Sleep rhythm problem',
    },
    {
      Code: '3747008',
      Display: 'EC - Ejection click',
    },
    {
      Code: '3750006',
      Display: 'Arteriospasm',
    },
    {
      Code: '3751005',
      Display: 'Contusion of labium',
    },
    {
      Code: '3752003',
      Display: 'Infection by Trichuris',
    },
    {
      Code: '3754002',
      Display: 'Dysplasia of vagina',
    },
    {
      Code: '3755001',
      Display: 'PRP - Pityriasis rubra pilaris',
    },
    {
      Code: '3756000',
      Display: 'Static ataxia',
    },
    {
      Code: '3759007',
      Display: 'Injury of heart with open wound into thorax',
    },
    {
      Code: '3760002',
      Display: 'Familial multiple factor deficiency syndrome, type V',
    },
    {
      Code: '3762005',
      Display: 'Bilateral recurrent femoral hernia with gangrene',
    },
    {
      Code: '3763000',
      Display: 'Expected bereavement due to life event',
    },
    {
      Code: '3783004',
      Display: 'Enamel pearls',
    },
    {
      Code: '3797007',
      Display: 'Periodontal cyst',
    },
    {
      Code: '3798002',
      Display: 'Premature birth of identical twins, both stillborn',
    },
    {
      Code: '3815005',
      Display: 'Crohn disease of rectum',
    },
    {
      Code: '3820005',
      Display: 'Inner ear conductive hearing loss',
    },
    {
      Code: '3827008',
      Display: 'Aneurysm of artery of neck',
    },
    {
      Code: '3830001',
      Display: 'Subcutaneous emphysema',
    },
    {
      Code: '3841004',
      Display: 'Blister of cheek with infection',
    },
    {
      Code: '3845008',
      Display: 'Duplication of intestine',
    },
    {
      Code: '3855007',
      Display: 'Disorder of pancreas',
    },
    {
      Code: '3859001',
      Display: 'Late effect of open wound of extremities without tendon injury',
    },
    {
      Code: '3873005',
      Display:
        'Failed attempted termination of pregnancy with acute necrosis of liver',
    },
    {
      Code: '3885002',
      Display: 'ABO isoimmunization in pregnancy',
    },
    {
      Code: '3886001',
      Display: 'Congenital fecaliths',
    },
    {
      Code: '3899003',
      Display: 'Neutropenic typhlitis',
    },
    {
      Code: '3900008',
      Display: 'Mixed sensory-motor polyneuropathy',
    },
    {
      Code: '3902000',
      Display: 'Non dose-related drug-induced neutropenia',
    },
    {
      Code: '3903005',
      Display: 'Closed traumatic pneumothorax',
    },
    {
      Code: '3908001',
      Display: 'Infestation caused by Haematopinus',
    },
    {
      Code: '3909009',
      Display: 'Coeur en sabot',
    },
    {
      Code: '3913002',
      Display:
        'Injury of gastrointestinal tract with open wound into abdominal cavity',
    },
    {
      Code: '3914008',
      Display: 'Mental disorder in childhood',
    },
    {
      Code: '3928002',
      Display: 'Zika virus disease',
    },
    {
      Code: '3939004',
      Display: 'Bacterial colony density, transparent',
    },
    {
      Code: '3944006',
      Display: 'X-linked placental steryl-sulfatase deficiency',
    },
    {
      Code: '3947004',
      Display: 'High oxygen affinity hemoglobin polycythemia',
    },
    {
      Code: '3950001',
      Display: 'Birth',
    },
    {
      Code: '3951002',
      Display: 'Proctitis',
    },
    {
      Code: '3972004',
      Display: 'Idiopathic insomnia',
    },
    {
      Code: '3975002',
      Display: 'Deep third degree burn of lower limb',
    },
    {
      Code: '3978000',
      Display: 'AIHA - Warm autoimmune hemolytic anemia',
    },
    {
      Code: '3987009',
      Display: 'Congenital absence of trachea',
    },
    {
      Code: '3993001',
      Display: 'Infection caused by Muellerius',
    },
    {
      Code: '3999002',
      Display: 'Acute pyelitis without renal medullary necrosis',
    },
    {
      Code: '4003003',
      Display: 'Alphavirus disease',
    },
    {
      Code: '4004009',
      Display: 'Monster with cranial anomalies',
    },
    {
      Code: '4006006',
      Display: 'Foetal tachycardia affecting management of mother',
    },
    {
      Code: '4009004',
      Display: 'Lower urinary tract infection',
    },
    {
      Code: '4016003',
      Display: 'Empyema of mastoid',
    },
    {
      Code: '4017007',
      Display: 'Increased stratum corneum adhesiveness',
    },
    {
      Code: '4022007',
      Display: 'Vulvitis circumscripta plasmacellularis',
    },
    {
      Code: '4026005',
      Display: 'Interstitial mastitis associated with childbirth',
    },
    {
      Code: '4030008',
      Display: 'Le Dantec virus disease',
    },
    {
      Code: '4038001',
      Display: 'Myrotheciotoxicosis',
    },
    {
      Code: '4039009',
      Display: 'Multiple vitamin deficiency disease',
    },
    {
      Code: '4040006',
      Display: 'Hassall-Henle bodies',
    },
    {
      Code: '4041005',
      Display: 'Congenital anomaly of macula',
    },
    {
      Code: '4046000',
      Display: 'Degenerative spondylolisthesis',
    },
    {
      Code: '4062006',
      Display: 'Lumbosacral plexus lesion',
    },
    {
      Code: '4063001',
      Display: 'Achillodynia',
    },
    {
      Code: '4069002',
      Display: 'Anoxic brain damage during AND/OR resulting from a procedure',
    },
    {
      Code: '4070001',
      Display: 'Palinphrasia',
    },
    {
      Code: '4075006',
      Display: 'Peganum harmala poisoning',
    },
    {
      Code: '4082005',
      Display: 'Syphilitic myocarditis',
    },
    {
      Code: '4088009',
      Display: 'Acquired hydrocephalus',
    },
    {
      Code: '4089001',
      Display: 'Meningococcemia',
    },
    {
      Code: '4092002',
      Display: 'Nonallopathic lesion of costovertebral region',
    },
    {
      Code: '4103001',
      Display: 'Complex partial seizure',
    },
    {
      Code: '4106009',
      Display: 'Rotator cuff rupture',
    },
    {
      Code: '4107000',
      Display: 'Infertile male syndrome',
    },
    {
      Code: '4113009',
      Display: 'Arrested hydrocephalus',
    },
    {
      Code: '4120002',
      Display: 'Bronchiolitis',
    },
    {
      Code: '4124006',
      Display: 'Insect bite, nonvenomous, of vagina, infected',
    },
    {
      Code: '4127004',
      Display: 'Prostatic obstruction',
    },
    {
      Code: '4129001',
      Display: 'Argyll-Robertson pupil',
    },
    {
      Code: '4135001',
      Display: '11p partial monosomy syndrome',
    },
    {
      Code: '4136000',
      Display: 'Macrodactylia of toes',
    },
    {
      Code: '4142001',
      Display: 'Muscular asthenopia',
    },
    {
      Code: '4152002',
      Display: 'Acquired hypoprothrombinemia',
    },
    {
      Code: '4160001',
      Display: 'Congenital anomaly of upper respiratory system',
    },
    {
      Code: '4168008',
      Display: 'Tibial plateau chondromalacia',
    },
    {
      Code: '4170004',
      Display: 'Ehlers-Danlos syndrome, procollagen proteinase resistant',
    },
    {
      Code: '4174008',
      Display: 'Tripartite placenta',
    },
    {
      Code: '4175009',
      Display: 'Infestation by Estrus',
    },
    {
      Code: '4178006',
      Display: 'Partial recent retinal detachment with multiple defects',
    },
    {
      Code: '4181001',
      Display: 'Normal peak expiratory flow rate',
    },
    {
      Code: '4183003',
      Display: 'Charcot-Marie-Tooth disease, type IC',
    },
    {
      Code: '4184009',
      Display: 'Congenital malformation of the endocrine glands',
    },
    {
      Code: '4191007',
      Display: 'Scaphoid head',
    },
    {
      Code: '4195003',
      Display: 'Duplication of anus',
    },
    {
      Code: '4197006',
      Display: 'Disability evaluation, impairment, class 5',
    },
    {
      Code: '4199009',
      Display: '18p partial trisomy syndrome',
    },
    {
      Code: '4208000',
      Display: 'Closed multiple fractures of both lower limbs',
    },
    {
      Code: '4210003',
      Display: 'OH - Ocular hypertension',
    },
    {
      Code: '4223005',
      Display: 'Parkinsonism caused by drug',
    },
    {
      Code: '4224004',
      Display: 'Complication of infusion',
    },
    {
      Code: '4225003',
      Display: 'Nasal tuberculosis',
    },
    {
      Code: '4229009',
      Display: 'Phthisical eye',
    },
    {
      Code: '4232007',
      Display: 'Chronic vulvitis',
    },
    {
      Code: '4237001',
      Display: 'Suppurative pulpitis',
    },
    {
      Code: '4240001',
      Display: 'Rupture of aorta',
    },
    {
      Code: '4241002',
      Display: 'Listeria infection',
    },
    {
      Code: '4242009',
      Display: '18q partial monosomy syndrome',
    },
    {
      Code: '4244005',
      Display: 'Urticaria neonatorum',
    },
    {
      Code: '4248008',
      Display: 'Synovitis AND/OR tenosynovitis associated with another disease',
    },
    {
      Code: '4249000',
      Display: 'Poor peripheral circulation',
    },
    {
      Code: '4251001',
      Display: 'Internal eye sign',
    },
    {
      Code: '4260009',
      Display: 'Sacral spinal cord injury without bone injury',
    },
    {
      Code: '4262001',
      Display: 'Phlebitis of superior sagittal sinus',
    },
    {
      Code: '4264000',
      Display: 'Chronic pericoronitis',
    },
    {
      Code: '4269005',
      Display:
        'Chronic gastrojejunal ulcer without hemorrhage AND without perforation',
    },
    {
      Code: '4273008',
      Display: 'Closed posterior dislocation of elbow',
    },
    {
      Code: '4275001',
      Display: 'Conjugate gaze spasm',
    },
    {
      Code: '4278004',
      Display:
        'Superficial foreign body of axilla without major open wound but with infection',
    },
    {
      Code: '4283007',
      Display: 'Mirizzi syndrome',
    },
    {
      Code: '4287008',
      Display: 'Chordee of penis',
    },
    {
      Code: '4294006',
      Display: 'Isosexual precocious puberty',
    },
    {
      Code: '4300009',
      Display: 'Deep third degree burn of forearm',
    },
    {
      Code: '4301008',
      Display: 'Autoimmune state',
    },
    {
      Code: '4306003',
      Display: 'Cluster B personality disorder',
    },
    {
      Code: '4307007',
      Display:
        'Pregestational diabetes mellitus AND/OR impaired glucose tolerance, modified White class F',
    },
    {
      Code: '4308002',
      Display: 'RSIS - Repetitive strain injury syndrome',
    },
    {
      Code: '4310000',
      Display: 'Third degree burn of wrist AND/OR hand',
    },
    {
      Code: '4313003',
      Display: 'Acardiacus anceps',
    },
    {
      Code: '4316006',
      Display: 'Myometritis',
    },
    {
      Code: '4320005',
      Display: 'Factor V deficiency',
    },
    {
      Code: '4324001',
      Display: 'Subacute cystitis',
    },
    {
      Code: '4325000',
      Display: '11q partial monosomy syndrome',
    },
    {
      Code: '4332009',
      Display:
        'Subarachnoid hemorrhage following injury without open intracranial wound AND with concussion',
    },
    {
      Code: '4338008',
      Display: 'Arnold nerve reflex cough syndrome',
    },
    {
      Code: '4340003',
      Display: 'Acrodermatitis chronica atrophicans',
    },
    {
      Code: '4349002',
      Display: 'Open fracture of multiple sites of metacarpus',
    },
    {
      Code: '4354006',
      Display: 'Open dislocation of scapula',
    },
    {
      Code: '4356008',
      Display: 'Gingival soft tissue recession',
    },
    {
      Code: '4359001',
      Display: 'Early congenital syphilis',
    },
    {
      Code: '4364002',
      Display: 'Structure of associations',
    },
    {
      Code: '4367009',
      Display: 'Hoover sign',
    },
    {
      Code: '4373005',
      Display: 'Clubbing of nail',
    },
    {
      Code: '4374004',
      Display: 'TV - Congenital tricuspid valve abnormality',
    },
    {
      Code: '4381006',
      Display: 'Verbal paraphasia',
    },
    {
      Code: '4386001',
      Display: 'Bronchospasm',
    },
    {
      Code: '4390004',
      Display: 'Chronic lithium nephrotoxicity',
    },
    {
      Code: '4397001',
      Display: 'Partial congenital duodenal obstruction',
    },
    {
      Code: '4399003',
      Display: 'Acute hemorrhagic pancreatitis',
    },
    {
      Code: '4403007',
      Display: 'Exclamation point hair',
    },
    {
      Code: '4406004',
      Display: 'Congenital anomaly of male genital system',
    },
    {
      Code: '4409006',
      Display:
        'Combined methylmalonic acidemia and homocystinuria due to defects in adenosylcobalamin and methylcobalamin synthesis',
    },
    {
      Code: '4410001',
      Display: 'Retroperitoneal hernia with obstruction',
    },
    {
      Code: '4412009',
      Display: 'Digital nerve injury',
    },
    {
      Code: '4414005',
      Display: 'Infection caused by Setaria',
    },
    {
      Code: '4416007',
      Display: 'Heerfordt syndrome',
    },
    {
      Code: '4418008',
      Display: 'Gangrenous ergotism',
    },
    {
      Code: '4426000',
      Display: 'Ten previous induced terminations of pregnancy',
    },
    {
      Code: '4434006',
      Display: 'BS - Bloom syndrome',
    },
    {
      Code: '4439001',
      Display: 'Axenfeld-Schurenberg syndrome',
    },
    {
      Code: '4441000',
      Display: 'Severe bipolar disorder with psychotic features',
    },
    {
      Code: '4445009',
      Display: 'TB - Urogenital tuberculosis',
    },
    {
      Code: '4448006',
      Display: 'Allergic headache',
    },
    {
      Code: '4451004',
      Display: 'Illegal termination of pregnancy with renal tubular necrosis',
    },
    {
      Code: '4461006',
      Display: 'Complication of administrative procedure',
    },
    {
      Code: '4463009',
      Display: 'Indiana-Maryland type amyloid polyneuropathy',
    },
    {
      Code: '4464003',
      Display: 'Rocio virus disease',
    },
    {
      Code: '4465002',
      Display: 'Spherophakia',
    },
    {
      Code: '4468000',
      Display: 'Oppenheim gait',
    },
    {
      Code: '4470009',
      Display: 'Blanching of skin',
    },
    {
      Code: '4473006',
      Display: 'Migraine with aura',
    },
    {
      Code: '4477007',
      Display: 'Juvenile myopathy AND lactate acidosis',
    },
    {
      Code: '4478002',
      Display: 'Multiple fractures of upper AND lower limbs',
    },
    {
      Code: '4481007',
      Display: 'Abnormal gastric secretion regulation',
    },
    {
      Code: '4483005',
      Display: 'Syphilitic punched out ulcer',
    },
  ],
  goalDesc: [
    {
      code: '109006',
      label: 'Anxiety disorder of childhood OR adolescence',
    },
    {
      code: '122003',
      label: 'Choroidal hemorrhage',
    },
    {
      code: '127009',
      label: 'Spontaneous abortion with laceration of cervix',
    },
    {
      code: '129007',
      label: 'Homoiothermia',
    },
    {
      code: '134006',
      label: 'Decreased hair growth',
    },
    {
      code: '140004',
      label: 'Chronic pharyngitis',
    },
    {
      code: '144008',
      label: 'Normal peripheral vision',
    },
    {
      code: '147001',
      label:
        'Superficial foreign body of scrotum without major open wound but with infection',
    },
    {
      code: '150003',
      label: 'Abnormal bladder continence',
    },
    {
      code: '151004',
      label: 'Meningitis due to gonococcus',
    },
    {
      code: '162004',
      label: 'Severe manic bipolar I disorder without psychotic features',
    },
    {
      code: '165002',
      label: 'Accident-prone',
    },
    {
      code: '168000',
      label: 'Typhlolithiasis',
    },
    {
      code: '171008',
      label:
        'Injury of ascending right colon without open wound into abdominal cavity',
    },
    {
      code: '172001',
      label: 'Endometritis following molar AND/OR ectopic pregnancy',
    },
    {
      code: '175004',
      label: 'Supraorbital neuralgia',
    },
    {
      code: '177007',
      label: 'Poisoning by sawfly larvae',
    },
    {
      code: '179005',
      label: 'Apraxia of dressing',
    },
    {
      code: '181007',
      label: 'Hemorrhagic bronchopneumonia',
    },
    {
      code: '183005',
      label: 'Autoimmune pancytopenia',
    },
    {
      code: '184004',
      label: 'Withdrawal arrhythmia',
    },
    {
      code: '188001',
      label: 'Intercostal artery injury',
    },
    {
      code: '192008',
      label: 'Congenital syphilitic hepatomegaly',
    },
    {
      code: '193003',
      label: 'Benign hypertensive renal disease',
    },
    {
      code: '195005',
      label: 'Illegal abortion with endometritis',
    },
    {
      code: '198007',
      label: 'Disease due to Filoviridae',
    },
    {
      code: '199004',
      label: 'Decreased lactation',
    },
    {
      code: '208008',
      label: 'Neurocutaneous melanosis sequence',
    },
    {
      code: '216004',
      label: 'Delusion of persecution',
    },
    {
      code: '219006',
      label: 'Alcohol user',
    },
    {
      code: '222008',
      label: 'Acute epiglottitis with obstruction',
    },
    {
      code: '223003',
      label: 'Tumor of body of uterus affecting pregnancy',
    },
    {
      code: '228007',
      label: 'Lucio phenomenon',
    },
    {
      code: '241006',
      label: 'Motor simple partial status',
    },
    {
      code: '242004',
      label: 'Noninfectious jejunitis',
    },
    {
      code: '253005',
      label: 'Sycosis',
    },
    {
      code: '257006',
      label: 'Acne rosacea, erythematous telangiectatic type',
    },
    {
      code: '258001',
      label: 'Pseudoknuckle pad',
    },
    {
      code: '264008',
      label: 'Blind hypertensive eye',
    },
    {
      code: '276008',
      label: 'Oxytocin poisoning',
    },
    {
      code: '279001',
      label: 'Senile myocarditis',
    },
    {
      code: '281004',
      label: 'Alcoholic dementia',
    },
    {
      code: '282006',
      label: 'Acute myocardial infarction of basal-lateral wall',
    },
    {
      code: '290006',
      label: 'Melnick-Fraser syndrome',
    },
    {
      code: '292003',
      label: 'EEG finding',
    },
    {
      code: '297009',
      label: 'Acute myringitis',
    },
    {
      code: '299007',
      label: 'Paraffinoma of skin',
    },
    {
      code: '303002',
      label: 'Apoplectic pancreatitis',
    },
    {
      code: '308006',
      label: 'Pearly penile papules',
    },
    {
      code: '310008',
      label: 'Penile boil',
    },
    {
      code: '313005',
      label: 'Déjà vu',
    },
    {
      code: '317006',
      label: 'Reactive hypoglycemia',
    },
    {
      code: '320003',
      label: 'Cervical dilatation, 1cm',
    },
    {
      code: '324007',
      label: 'Plaster ulcer',
    },
    {
      code: '330007',
      label: 'Occipital headache',
    },
    {
      code: '335002',
      label: 'Pylorospasm',
    },
    {
      code: '341009',
      label: 'ABO incompatibility reaction',
    },
    {
      code: '349006',
      label: 'Absent tendon reflex',
    },
    {
      code: '355001',
      label: 'Hemorrhagic shock',
    },
    {
      code: '357009',
      label: 'Closed fracture trapezoid',
    },
    {
      code: '358004',
      label: 'Smallpox vaccine poisoning',
    },
    {
      code: '359007',
      label: 'Kernicterus due to isoimmunization',
    },
    {
      code: '360002',
      label: 'Acute radiation disease',
    },
    {
      code: '364006',
      label: 'Acute left-sided heart failure',
    },
    {
      code: '366008',
      label: 'Hidromeiosis',
    },
    {
      code: '368009',
      label: 'Heart valve disorder',
    },
    {
      code: '369001',
      label: 'Normal jugular venous pressure',
    },
    {
      code: '378007',
      label: 'Morquio syndrome',
    },
    {
      code: '382009',
      label: 'Legal history finding relating to child',
    },
    {
      code: '383004',
      label: 'Finding of passive range of hip extension',
    },
    {
      code: '385006',
      label: 'Secondary peripheral neuropathy',
    },
    {
      code: '387003',
      label: 'Melanuria',
    },
    {
      code: '398002',
      label: 'Left axis deviation greater than -90 degrees by EKG',
    },
    {
      code: '407000',
      label: 'Congenital hepatomegaly',
    },
    {
      code: '408005',
      label: 'Tooth chattering',
    },
    {
      code: '409002',
      label: 'Food allergy diet',
    },
    {
      code: '426008',
      label: 'Superficial injury of ankle without infection',
    },
    {
      code: '431005',
      label: 'Hypertrophy of scrotum',
    },
    {
      code: '437009',
      label: 'Abnormal composition of urine',
    },
    {
      code: '440009',
      label: 'Persistent hyperphenylalaninemia',
    },
    {
      code: '442001',
      label: 'Secondary hypopituitarism',
    },
    {
      code: '443006',
      label: 'Cystocele affecting pregnancy',
    },
    {
      code: '447007',
      label: 'Coach in sports activity accident',
    },
    {
      code: '450005',
      label: 'Ulcerative stomatitis',
    },
    {
      code: '452002',
      label: 'Blister of groin without infection',
    },
    {
      code: '460001',
      label: 'Squamous metaplasia of prostate gland',
    },
    {
      code: '467003',
      label: 'Old laceration of pelvic floor muscle',
    },
    {
      code: '470004',
      label: 'Vitreous touch syndrome',
    },
    {
      code: '479003',
      label:
        "Graves' disease with pretibial myxedema AND with thyrotoxic crisis",
    },
    {
      code: '486006',
      label: 'Acute vascular insufficiency',
    },
    {
      code: '488007',
      label: 'Fibroid myocarditis',
    },
    {
      code: '490008',
      label: 'Upper respiratory tract hypersensitivity reaction',
    },
    {
      code: '496002',
      label: 'Closed traumatic dislocation of third cervical vertebra',
    },
    {
      code: '504009',
      label: 'Androgen-dependent hirsutism',
    },
    {
      code: '517007',
      label: 'Foreign body in hypopharynx',
    },
    {
      code: '518002',
      label: 'Multiple aggregation',
    },
    {
      code: '520004',
      label: 'Congenital bent nose',
    },
    {
      code: '527001',
      label: "Spontaneous fetal evolution, Roederer's method",
    },
    {
      code: '536002',
      label: 'Glissonian cirrhosis',
    },
    {
      code: '539009',
      label: 'Conjunctival argyrosis',
    },
    {
      code: '547009',
      label: 'Hypersecretion of calcitonin',
    },
    {
      code: '548004',
      label: '13p partial trisomy syndrome',
    },
    {
      code: '554003',
      label: '2p partial trisomy syndrome',
    },
    {
      code: '555002',
      label: 'Dicentra species poisoning',
    },
    {
      code: '563001',
      label: 'Nystagmus',
    },
    {
      code: '568005',
      label: 'Habit disorder',
    },
    {
      code: '586008',
      label: 'Contact dermatitis due to primrose',
    },
    {
      code: '590005',
      label: 'Congenital aneurysm of anterior communicating artery',
    },
    {
      code: '596004',
      label: 'Premenstrual dysphoric disorder',
    },
    {
      code: '599006',
      label: 'Persistent pneumothorax',
    },
    {
      code: '600009',
      label: 'Pyromania',
    },
    {
      code: '602001',
      label: 'Ross river fever',
    },
    {
      code: '607007',
      label: 'Decreased vital capacity',
    },
    {
      code: '610000',
      label: 'Spastic aphonia',
    },
    {
      code: '613003',
      label: 'FRAXA - Fragile X syndrome',
    },
    {
      code: '615005',
      label:
        'Obstruction due to foreign body accidentally left in operative wound AND/OR body cavity during a procedure',
    },
    {
      code: '616006',
      label: 'Sensorimotor disorder of eyelid',
    },
    {
      code: '626004',
      label: 'Hypercortisolism due to nonpituitary tumor',
    },
    {
      code: '631002',
      label: 'Transfusion reaction due to minor incompatibility',
    },
    {
      code: '634005',
      label: 'Saddle boil',
    },
    {
      code: '640003',
      label: 'Injury of pneumogastric nerve',
    },
    {
      code: '643001',
      label: 'Hypertrophy of lip',
    },
    {
      code: '646009',
      label: 'Idiopathic cyst of anterior chamber',
    },
    {
      code: '649002',
      label: 'Open fracture of distal end of ulna',
    },
    {
      code: '651003',
      label: 'Root work',
    },
    {
      code: '652005',
      label: 'Gangrenous tonsillitis',
    },
    {
      code: '655007',
      label: 'Abnormal fetal heart beat noted before labor in liveborn infant',
    },
    {
      code: '658009',
      label: 'Injury of colon without open wound into abdominal cavity',
    },
    {
      code: '663008',
      label: 'Pulmonary embolism following molar AND/OR ectopic pregnancy',
    },
    {
      code: '664002',
      label: 'Delayed ovulation',
    },
    {
      code: '666000',
      label: 'Poisoning by antivaricose drug AND/OR sclerosing agent',
    },
    {
      code: '675003',
      label: 'Torsion of intestine',
    },
    {
      code: '682004',
      label: 'Thrombosis complicating pregnancy AND/OR puerperium',
    },
    {
      code: '685002',
      label: 'Acquired telangiectasia of small AND/OR large intestines',
    },
    {
      code: '701003',
      label: 'Adult osteochondritis of spine',
    },
    {
      code: '703000',
      label: 'Congenital adhesion of tongue',
    },
    {
      code: '714002',
      label: 'Abrasion AND/OR friction burn of toe with infection',
    },
    {
      code: '715001',
      label: 'Nontraumatic rupture of urethra',
    },
    {
      code: '718004',
      label: 'Acute bronchiolitis with obstruction',
    },
    {
      code: '733007',
      label:
        'Superficial foreign body of groin without major open wound but with infection',
    },
    {
      code: '734001',
      label: 'Opocephalus',
    },
    {
      code: '736004',
      label: 'Abscess of hip',
    },
    {
      code: '750009',
      label: 'Schistosoma mansoni infection',
    },
    {
      code: '755004',
      label: 'Postgastrectomy phytobezoar',
    },
    {
      code: '756003',
      label: 'Chronic rheumatic myopericarditis',
    },
    {
      code: '758002',
      label: 'Cyst of uterus',
    },
    {
      code: '775008',
      label: 'Open wound of head with complication',
    },
    {
      code: '776009',
      label: 'Partial arterial retinal occlusion',
    },
    {
      code: '781000',
      label: 'Cestrum diurnum poisoning',
    },
    {
      code: '786005',
      label: 'Clinical stage I B',
    },
    {
      code: '787001',
      label: 'Rheumatic mitral stenosis with regurgitation',
    },
    {
      code: '788006',
      label: 'Disease-related diet',
    },
    {
      code: '792004',
      label: 'CJD - Creutzfeldt-Jakob disease',
    },
    {
      code: '799008',
      label: 'Sigmoid colon ulcer',
    },
    {
      code: '801006',
      label: 'Insect bite, nonvenomous, of foot, infected',
    },
    {
      code: '805002',
      label: 'Pneumoconiosis due to silica',
    },
    {
      code: '811004',
      label: 'Flail motion',
    },
    {
      code: '813001',
      label: 'Ankle instability',
    },
    {
      code: '815008',
      label: 'Episcleritis',
    },
    {
      code: '816009',
      label: 'Genetic recombination',
    },
    {
      code: '818005',
      label: 'Third degree burn of multiple sites of lower limb',
    },
    {
      code: '825003',
      label: 'Superficial injury of axilla with infection',
    },
    {
      code: '827006',
      label:
        'Late congenital syphilis, latent (+ sero., - C.S.F., 2 years OR more)',
    },
    {
      code: '832007',
      label: 'Moderate major depression',
    },
    {
      code: '834008',
      label: 'Chair-seated facing coital position',
    },
    {
      code: '841002',
      label: 'Congenital absence of skull bone',
    },
    {
      code: '842009',
      label: 'Consanguinity',
    },
    {
      code: '843004',
      label: 'Poliomyelomalacia',
    },
    {
      code: '844005',
      label: 'Finding relating to behavior',
    },
    {
      code: '845006',
      label: 'Inferior mesenteric artery injury',
    },
    {
      code: '849000',
      label: 'Total cataract',
    },
    {
      code: '857002',
      label: 'Erythema simplex',
    },
    {
      code: '862001',
      label: 'Anemia due to chlorate',
    },
    {
      code: '865004',
      label: 'Hyperalimentation formula for ileus',
    },
    {
      code: '871005',
      label: 'Contracted pelvis',
    },
    {
      code: '874002',
      label: 'Therapeutic diuresis',
    },
    {
      code: '875001',
      label: 'Chalcosis of eye',
    },
    {
      code: '888003',
      label: 'Foetal or neonatal effect of maternal blood loss',
    },
    {
      code: '890002',
      label: 'Deep third degree burn of elbow',
    },
    {
      code: '899001',
      label: 'Axis I diagnosis',
    },
    {
      code: '903008',
      label: 'Chorioretinal infarction',
    },
    {
      code: '904002',
      label: "Pinard's sign",
    },
    {
      code: '908004',
      label: 'Superficial injury of interscapular region without infection',
    },
    {
      code: '919001',
      label: 'Pseudohomosexual state',
    },
    {
      code: '928000',
      label: 'Musculoskeletal disorder',
    },
    {
      code: '931004',
      label: 'Gestation period, 9 weeks',
    },
    {
      code: '932006',
      label: 'Flat affect',
    },
    {
      code: '934007',
      label: 'Thalassemia intermedia',
    },
    {
      code: '943003',
      label: 'Congenital retinal aneurysm',
    },
    {
      code: '954008',
      label: 'Renon-Delille syndrome',
    },
    {
      code: '961007',
      label: 'Erythema nodosum, acute form',
    },
    {
      code: '962000',
      label: 'Disability evaluation, disability 6%',
    },
    {
      code: '964004',
      label: 'Open wound of pharynx without complication',
    },
    {
      code: '965003',
      label: 'Toxic amblyopia',
    },
    {
      code: '975000',
      label: 'Anorectal agenesis',
    },
    {
      code: '978003',
      label: 'Chronic infantile eczema',
    },
    {
      code: '981008',
      label: 'Hemorrhagic proctitis',
    },
    {
      code: '984000',
      label: 'Perirectal cellulitis',
    },
    {
      code: '987007',
      label: 'Cellulitis of temple region',
    },
    {
      code: '991002',
      label: 'Wide QRS complex',
    },
    {
      code: '998008',
      label: "Chagas' disease with heart involvement",
    },
    {
      code: '1003002',
      label: 'Religious discrimination',
    },
    {
      code: '1020003',
      label: 'Disease due to Nairovirus',
    },
    {
      code: '1023001',
      label: 'Apneic',
    },
    {
      code: '1027000',
      label: 'Biliary esophagitis',
    },
    {
      code: '1031006',
      label: 'Open wound of trachea without complication',
    },
    {
      code: '1033009',
      label: 'Thoracic arthritis',
    },
    {
      code: '1034003',
      label: 'Mesenteric-portal fistula',
    },
    {
      code: '1038000',
      label: 'Disacchariduria',
    },
    {
      code: '1045000',
      label: 'Colonospasm',
    },
    {
      code: '1046004',
      label: 'Ureteritis glandularis',
    },
    {
      code: '1051005',
      label: 'Hyperplasia of islet alpha cells with gastrin excess',
    },
    {
      code: '1055001',
      label: 'Stenosis of precerebral artery',
    },
    {
      code: '1059007',
      label: 'Opisthorchiasis',
    },
    {
      code: '1070000',
      label: 'Facial myokymia',
    },
    {
      code: '1073003',
      label: 'Xeroderma pigmentosum group B',
    },
    {
      code: '1074009',
      label: 'Glucocorticoid-responsive primary hyperaldosteronism',
    },
    {
      code: '1077002',
      label: 'Septal infarction by EKG',
    },
    {
      code: '1079004',
      label: 'Macular retinal cyst',
    },
    {
      code: '1085006',
      label: 'Vulval candidiasis',
    },
    {
      code: '1089000',
      label: 'Congenital sepsis',
    },
    {
      code: '1102005',
      label: 'Intraerythrocytic parasitosis by Nuttallia',
    },
    {
      code: '1107004',
      label:
        'Early latent syphilis, positive serology, negative cerebrospinal fluid, with relapse after treatment',
    },
    {
      code: '1108009',
      label: 'Female pattern alopecia',
    },
    {
      code: '1111005',
      label: 'Normal sebaceous gland activity',
    },
    {
      code: '1112003',
      label: 'Degenerative disorder of eyelid',
    },
    {
      code: '1116000',
      label: 'Chronic aggressive type B viral hepatitis',
    },
    {
      code: '1124005',
      label: 'Postpartum period, 6 days',
    },
    {
      code: '1125006',
      label: 'Septicemia during labor',
    },
    {
      code: '1126007',
      label: 'Knee locking',
    },
    {
      code: '1131009',
      label: 'Congenital valvular insufficiency',
    },
    {
      code: '1134001',
      label: 'Muehrcke lines',
    },
    {
      code: '1135000',
      label: 'Solar retinitis',
    },
    {
      code: '1139006',
      label: 'Confrontation (visual) test',
    },
    {
      code: '1140008',
      label: 'Thermal hypesthesia',
    },
    {
      code: '1141007',
      label: 'Circumoral paresthesia',
    },
    {
      code: '1145003',
      label: 'DSD - Developmental speech disorder',
    },
    {
      code: '1150009',
      label: 'Congenital microcheilia',
    },
    {
      code: '1151008',
      label: 'Constricted visual field',
    },
    {
      code: '1152001',
      label: 'Skin reaction negative',
    },
    {
      code: '1155004',
      label: 'Myocardial hypertrophy, determined by electrocardiogram',
    },
    {
      code: '1156003',
      label: 'Cavitary prostatitis',
    },
    {
      code: '1168007',
      label: 'Allotype',
    },
    {
      code: '1184008',
      label: 'Glasgow coma scale, 10',
    },
    {
      code: '1192004',
      label: 'Familial amyloid neuropathy, Finnish type',
    },
    {
      code: '1194003',
      label: 'Disease condition determination, well controlled',
    },
    {
      code: '1196001',
      label:
        'Chronic bipolar II disorder, most recent episode major depressive',
    },
    {
      code: '1197005',
      label: 'Carbuncle of heel',
    },
    {
      code: '1201005',
      label: 'Benign essential hypertension',
    },
    {
      code: '1203008',
      label:
        'Deep third degree burn of forehead AND/OR cheek with loss of body part',
    },
    {
      code: '1207009',
      label: 'Optic disc glaucomatous atrophy',
    },
    {
      code: '1208004',
      label: 'Gastroptosis',
    },
    {
      code: '1212005',
      label: 'Juvenile dermatomyositis',
    },
    {
      code: '1214006',
      label: 'Infection by Strongyloides',
    },
    {
      code: '1230003',
      label: 'No diagnosis on Axis 1',
    },
    {
      code: '1232006',
      label: 'Congenital articular rigidity with myopathy',
    },
    {
      code: '1239002',
      label: 'Congenital anteversion of femoral neck',
    },
    {
      code: '1240000',
      label: 'Lying prone',
    },
    {
      code: '1259003',
      label: 'Schistosis',
    },
    {
      code: '1261007',
      label: 'Multiple fractures of ribs',
    },
    {
      code: '1264004',
      label:
        'Injury of descending left colon without open wound into abdominal cavity',
    },
    {
      code: '1271009',
      label: 'Knuckle pads, leuconychia and deafness',
    },
    {
      code: '1280009',
      label: 'Isologous chimera',
    },
    {
      code: '1282001',
      label: 'Laryngeal perichondritis',
    },
    {
      code: '1283006',
      label:
        'Visual acuity less than .02 (1/60, count fingers 1 meter) or visual field less than 5 degrees, but better than 5.',
    },
    {
      code: '1284000',
      label: 'Abnormal jaw closure',
    },
    {
      code: '1286003',
      label: 'Vitamin K deficiency coagulation disorder',
    },
    {
      code: '1287007',
      label: 'Congenital absence of bile duct',
    },
    {
      code: '1297003',
      label: 'Infection by Cladosporium carrionii',
    },
    {
      code: '1308001',
      label: 'Complication of reimplant',
    },
    {
      code: '1310004',
      label: 'Impaired glucose tolerance associated with genetic syndrome',
    },
    {
      code: '1317001',
      label: 'Injury of ovary without open wound into abdominal cavity',
    },
    {
      code: '1318006',
      label: 'Post-translational genetic protein processing',
    },
    {
      code: '1323006',
      label: 'Kanamycin poisoning',
    },
    {
      code: '1332008',
      label: 'Conjugated visual deviation',
    },
    {
      code: '1335005',
      label: 'Peyronies disease',
    },
    {
      code: '1343000',
      label: 'DTA - Deep transverse arrest',
    },
    {
      code: '1345007',
      label: 'Hang nail',
    },
    {
      code: '1351002',
      label: 'Iliac artery injury',
    },
    {
      code: '1356007',
      label: 'Calculus of common duct with obstruction',
    },
    {
      code: '1361009',
      label: 'Leucocoria',
    },
    {
      code: '1363007',
      label: 'Fetal or neonatal effect of chronic maternal respiratory disease',
    },
    {
      code: '1367008',
      label: 'Injury of superior mesenteric artery',
    },
    {
      code: '1370007',
      label: 'Open fracture of metacarpal bone(s)',
    },
    {
      code: '1372004',
      label: 'Unicornate uterus',
    },
    {
      code: '1376001',
      label: 'Obsessive compulsive personality disorder',
    },
    {
      code: '1378000',
      label: 'Supination-eversion injury of ankle',
    },
    {
      code: '1380006',
      label:
        'Agoraphobia without history of panic disorder with limited symptom attacks',
    },
    {
      code: '1383008',
      label: 'Hallucinogen induced mood disorder',
    },
    {
      code: '1384002',
      label: 'Diffuse cholesteatosis of middle ear',
    },
    {
      code: '1386000',
      label: 'Intracranial hemorrhage',
    },
    {
      code: '1387009',
      label: 'Solanum nigrum poisoning',
    },
    {
      code: '1388004',
      label: 'Metabolic alkalosis',
    },
    {
      code: '1393001',
      label: 'Lenz-Majewski dysplasia',
    },
    {
      code: '1395008',
      label: 'Complication of ultrasound therapy',
    },
    {
      code: '1402001',
      label: 'Frightened',
    },
    {
      code: '1412008',
      label: 'Anterior subcapsular polar cataract',
    },
    {
      code: '1415005',
      label: 'Inflammation of lymphatics',
    },
    {
      code: '1418007',
      label: 'Hypoplastic chondrodystrophy',
    },
    {
      code: '1419004',
      label: 'Injury of prostate without open wound into abdominal cavity',
    },
    {
      code: '1426004',
      label: 'Necrotizing glomerulonephritis',
    },
    {
      code: '1427008',
      label: 'Intraspinal abscess',
    },
    {
      code: '1430001',
      label:
        'Intracranial hemorrhage following injury without open intracranial wound AND with prolonged loss of consciousness (more than 24 hours) without return to pre-existing level',
    },
    {
      code: '1447000',
      label: 'Icthyoparasitism',
    },
    {
      code: '1469007',
      label: 'Miscarriage with urinary tract infection',
    },
    {
      code: '1474004',
      label:
        'Hypertensive heart AND renal disease complicating AND/OR reason for care during childbirth',
    },
    {
      code: '1475003',
      label: 'Herpes labialis',
    },
    {
      code: '1478001',
      label: 'Obliteration of lymphatic vessel',
    },
    {
      code: '1479009',
      label: '20q partial trisomy syndrome',
    },
    {
      code: '1482004',
      label: 'Chalazion',
    },
    {
      code: '1486001',
      label: 'Orbital congestion',
    },
    {
      code: '1488000',
      label: 'PONV - Postoperative nausea and vomiting',
    },
    {
      code: '1489008',
      label: 'External hordeolum',
    },
    {
      code: '1492007',
      label: 'Congenital anomaly of large intestine',
    },
    {
      code: '1493002',
      label: 'Acute endophthalmitis',
    },
    {
      code: '1499003',
      label: 'Bipolar I disorder, single manic episode with postpartum onset',
    },
    {
      code: '1512006',
      label: 'Congenital stricture of bile duct',
    },
    {
      code: '1515008',
      label: 'Gorham disease',
    },
    {
      code: '1518005',
      label: 'Splenitis',
    },
    {
      code: '1519002',
      label: 'Congenital phlebectasia',
    },
    {
      code: '1521007',
      label: 'Blister of buttock without infection',
    },
    {
      code: '1523005',
      label: 'Clinical stage IV B',
    },
    {
      code: '1525003',
      label: 'Blister of foot without infection',
    },
    {
      code: '1531000',
      label: 'Nitrofuran derivative poisoning',
    },
    {
      code: '1532007',
      label: 'Viral pharyngitis',
    },
    {
      code: '1534008',
      label: 'Palsy of conjugate gaze',
    },
    {
      code: '1538006',
      label:
        'Central nervous system malformation in foetus affecting obstetrical care',
    },
    {
      code: '1539003',
      label: 'Nodular tendinous disease of finger',
    },
    {
      code: '1542009',
      label: 'Omphalocele with obstruction',
    },
    {
      code: '1544005',
      label: 'Open dislocation of knee',
    },
    {
      code: '1551001',
      label: 'Osteomyelitis of femur',
    },
    {
      code: '1556006',
      label: 'Clark melanoma level 4',
    },
    {
      code: '1563006',
      label: 'Protein S deficiency',
    },
    {
      code: '1567007',
      label:
        'Chronic gastric ulcer without hemorrhage, without perforation AND without obstruction',
    },
    {
      code: '1588003',
      label: 'Heterosexual precocious puberty',
    },
    {
      code: '1592005',
      label: 'Failed attempted termination of pregnancy with uremia',
    },
    {
      code: '1593000',
      label: 'Infantile hemiplegia',
    },
    {
      code: '1606009',
      label: 'Infection caused by Macracanthorhynchus hirudinaceus',
    },
    {
      code: '1608005',
      label: 'Increased capillary permeability',
    },
    {
      code: '1639007',
      label:
        'Abnormality of organs AND/OR soft tissues of pelvis affecting pregnancy',
    },
    {
      code: '1647007',
      label: 'Primaquine poisoning',
    },
    {
      code: '1648002',
      label: 'Lymphocytic pseudotumor of lung',
    },
    {
      code: '1654001',
      label: 'Steroid-induced glaucoma',
    },
    {
      code: '1657008',
      label: 'Toxic effect of phosdrin',
    },
    {
      code: '1658003',
      label: 'Closed fracture clavicle, lateral end',
    },
    {
      code: '1663004',
      label: 'Tumor grade G2',
    },
    {
      code: '1667003',
      label: 'Early fontanel closure',
    },
    {
      code: '1670004',
      label: 'Cerebral hemiparesis',
    },
    {
      code: '1671000',
      label: 'Sago spleen',
    },
    {
      code: '1674008',
      label: "Meesman's epithelial corneal dystrophy",
    },
    {
      code: '1679003',
      label: 'Arthritis associated with another disorder',
    },
    {
      code: '1682008',
      label: 'Transitory amino acid metabolic disorder',
    },
    {
      code: '1685005',
      label: 'Rat-bite fever',
    },
    {
      code: '1686006',
      label: 'Sedative, hypnotic AND/OR anxiolytic-induced anxiety disorder',
    },
    {
      code: '1694004',
      label: 'Accessory lobe of lung',
    },
    {
      code: '1698001',
      label: 'Ulcer of bile duct',
    },
    {
      code: '1703007',
      label: 'Increased leg circumference',
    },
    {
      code: '1705000',
      label: 'Closed fracture of base of neck of femur',
    },
    {
      code: '1708003',
      label: 'Open dislocation of clavicle',
    },
    {
      code: '1714005',
      label: 'Photokeratitis',
    },
    {
      code: '1717003',
      label: 'Guttate hypomelanosis',
    },
    {
      code: '1723008',
      label: 'Urethral stricture due to schistosomiasis',
    },
    {
      code: '1724002',
      label: 'Infection caused by Crenosoma',
    },
    {
      code: '1734006',
      label: 'Fracture of vertebral column with spinal cord injury',
    },
    {
      code: '1735007',
      label: 'Thrill',
    },
    {
      code: '1739001',
      label: 'Occipital fracture',
    },
    {
      code: '1742007',
      label: 'Female hypererotism',
    },
    {
      code: '1744008',
      label: 'Connation of teeth',
    },
    {
      code: '1748006',
      label: 'Thrombophlebitis of deep femoral vein',
    },
    {
      code: '1755008',
      label: 'Healed coronary',
    },
    {
      code: '1761006',
      label: 'Biliary cirrhosis',
    },
    {
      code: '1763009',
      label: 'Stromal keratitis',
    },
    {
      code: '1767005',
      label: 'Fisher syndrome',
    },
    {
      code: '1769008',
      label: 'Thoracodidymus',
    },
    {
      code: '1771008',
      label: 'Insulin biosynthesis defect',
    },
    {
      code: '1776003',
      label: 'RTA - Renal tubular acidosis',
    },
    {
      code: '1777007',
      label: 'Increased molecular dissociation',
    },
    {
      code: '1778002',
      label: 'Malocclusion due to abnormal swallowing',
    },
    {
      code: '1779005',
      label: 'OFD II - Orofacial-digital syndrome II',
    },
    {
      code: '1794009',
      label: 'Idiopathic corneal edema',
    },
    {
      code: '1816003',
      label:
        'Panic disorder with agoraphobia, severe agoraphobic avoidance AND mild panic attacks',
    },
    {
      code: '1821000',
      label: 'Chemoreceptor apnea',
    },
    {
      code: '1822007',
      label: 'Impaired glucose tolerance associated with pancreatic disease',
    },
    {
      code: '1824008',
      label: 'Allergic gastritis',
    },
    {
      code: '1826005',
      label: 'Granuloma of lip',
    },
    {
      code: '1828006',
      label: 'Infestation caused by Gasterophilus hemorrhoidalis',
    },
    {
      code: '1829003',
      label: 'Microcephalus',
    },
    {
      code: '1833005',
      label: 'Phacoanaphylactic endophthalmitis',
    },
    {
      code: '1835003',
      label: 'Necrosis of pancreas',
    },
    {
      code: '1837006',
      label: 'Orciprenaline poisoning',
    },
    {
      code: '1845001',
      label: 'Paraparesis',
    },
    {
      code: '1847009',
      label: 'Endophthalmitis',
    },
    {
      code: '1848004',
      label: 'Poisoning caused by gaseous anesthetic',
    },
    {
      code: '1852004',
      label: 'Traumatic injury of sixth cranial nerve',
    },
    {
      code: '1855002',
      label: 'Developmental academic disorder',
    },
    {
      code: '1856001',
      label: 'Accessory nose',
    },
    {
      code: '1857005',
      label: 'Congenital rubella syndrome',
    },
    {
      code: '1858000',
      label: 'Infection caused by Stilesia globipunctata',
    },
    {
      code: '1860003',
      label: 'Fluid volume disorder',
    },
    {
      code: '1865008',
      label: 'Impaired intestinal protein absorption',
    },
    {
      code: '1869002',
      label: 'Rupture of iris sphincter',
    },
    {
      code: '1881003',
      label: 'Increased nutritional requirement',
    },
    {
      code: '1892002',
      label: 'Star figure at the macula',
    },
    {
      code: '1896004',
      label: 'Ectopic breast tissue',
    },
    {
      code: '1897008',
      label: 'Amsinckia species poisoning',
    },
    {
      code: '1899006',
      label: 'Autosomal hereditary disorder',
    },
    {
      code: '1903004',
      label: 'Infestation caused by Psorergates ovis',
    },
    {
      code: '1908008',
      label: 'von Willebrand disease, type IIC',
    },
    {
      code: '1909000',
      label:
        'Impairment level: better eye: severe impairment: lesser eye: near-total impairment',
    },
    {
      code: '1922008',
      label: 'Congenital absence of urethra',
    },
    {
      code: '1926006',
      label: 'Osteopetrosis',
    },
    {
      code: '1938002',
      label:
        'Emotional AND/OR mental disease in mother complicating pregnancy, childbirth AND/OR puerperium',
    },
    {
      code: '1939005',
      label: 'Abnormal vascular flow',
    },
    {
      code: '1943009',
      label: 'Left-right confusion',
    },
    {
      code: '1953005',
      label: 'Congenital deficiency of pigment of skin',
    },
    {
      code: '1954004',
      label: 'Dilated cardiomyopathy secondary to toxic reaction',
    },
    {
      code: '1955003',
      label: 'Preauricular pit',
    },
    {
      code: '1959009',
      label: 'Encephalartos species poisoning',
    },
    {
      code: '1961000',
      label: 'Chronic polyarticular juvenile rheumatoid arthritis',
    },
    {
      code: '1963002',
      label: 'PNH - Paroxysmal nocturnal hemoglobinuria',
    },
    {
      code: '1965009',
      label: 'Normal skin pH',
    },
    {
      code: '1967001',
      label: 'Congenital absence of forearm only',
    },
    {
      code: '1973000',
      label:
        'Sedative, hypnotic AND/OR anxiolytic-induced psychotic disorder with delusions',
    },
    {
      code: '1977004',
      label: 'Oxymetholone poisoning',
    },
    {
      code: '1979001',
      label: 'Focal choroiditis',
    },
    {
      code: '1980003',
      label: 'Seromucinous otitis media',
    },
    {
      code: '1981004',
      label: 'Urhidrosis',
    },
    {
      code: '1988005',
      label:
        'Late effect of injury to nerve roots, spinal plexus AND/OR other nerves of trunk',
    },
    {
      code: '1989002',
      label: 'Burn of vagina AND/OR uterus',
    },
    {
      code: '2004005',
      label: 'Normotensive',
    },
    {
      code: '2012002',
      label: 'Fracture of lunate',
    },
    {
      code: '2024009',
      label: 'Dilated cardiomyopathy secondary to metazoal myocarditis',
    },
    {
      code: '2028007',
      label: 'Erythema induratum',
    },
    {
      code: '2032001',
      label: 'Cerebral edema',
    },
    {
      code: '2036003',
      label: 'Acquired factor VII deficiency disease',
    },
    {
      code: '2040007',
      label: 'Neurogenic thoracic outlet syndrome',
    },
    {
      code: '2041006',
      label: 'Eunuchoid gigantism',
    },
    {
      code: '2043009',
      label: 'Alcoholic gastritis',
    },
    {
      code: '2053005',
      label:
        'Late effect of injury to blood vessels of thorax, abdomen AND/OR pelvis',
    },
    {
      code: '2055003',
      label: 'Recurrent erosion syndrome',
    },
    {
      code: '2058001',
      label: 'Bilateral loss of labyrinthine reactivity',
    },
    {
      code: '2061000',
      label: 'Conductive hearing loss of combined sites',
    },
    {
      code: '2065009',
      label: 'Autosomal dominant optic atrophy',
    },
    {
      code: '2066005',
      label:
        'Gastric ulcer with hemorrhage AND perforation but without obstruction',
    },
    {
      code: '2070002',
      label: 'Burning sensation in eye',
    },
    {
      code: '2073000',
      label: 'Delusions',
    },
    {
      code: '2087000',
      label: 'Pulmonary nocardiosis',
    },
    {
      code: '2089002',
      label: 'Pagets disease of bone',
    },
    {
      code: '2091005',
      label: 'Pharyngeal diverticulitis',
    },
    {
      code: '2094002',
      label: 'Carbon disulfide causing toxic effect',
    },
    {
      code: '2102007',
      label: 'Deep corneal vascularization',
    },
    {
      code: '2103002',
      label: 'Reflex sympathetic dystrophy of upper extremity',
    },
    {
      code: '2107001',
      label: 'Anisomelia',
    },
    {
      code: '2109003',
      label: 'Isolated somatotropin deficiency',
    },
    {
      code: '2114004',
      label: 'Infection caused by Cysticercus pisiformis',
    },
    {
      code: '2116002',
      label: 'Intramembranous bone formation',
    },
    {
      code: '2120003',
      label: 'Weak cry',
    },
    {
      code: '2121004',
      label: 'Ethopropazine poisoning',
    },
    {
      code: '2128005',
      label: 'Disorder of adenoid',
    },
    {
      code: '2129002',
      label: 'Edema of pharynx',
    },
    {
      code: '2132004',
      label: 'Meconium in amniotic fluid noted before labor in liveborn infant',
    },
    {
      code: '2134003',
      label: 'Diffuse pain',
    },
    {
      code: '2136001',
      label: 'Open wound of jaw with complication',
    },
    {
      code: '2138000',
      label: 'LSP - Left sacroposterior position',
    },
    {
      code: '2145000',
      label: 'Anal intercourse',
    },
    {
      code: '2149006',
      label: 'Decreased hormone production',
    },
    {
      code: '2158004',
      label: 'Infection caused by Contracaecum',
    },
    {
      code: '2167004',
      label: 'Retinal hemangioblastomatosis',
    },
    {
      code: '2169001',
      label: 'Thoracic radiculitis',
    },
    {
      code: '2170000',
      label: 'Gallop rhythm',
    },
    {
      code: '2176006',
      label: 'Halogen acne',
    },
    {
      code: '2177002',
      label: 'PHN - Post-herpetic neuralgia',
    },
    {
      code: '2186007',
      label: 'Compensated metabolic alkalosis',
    },
    {
      code: '2198002',
      label: 'Visceral epilepsy',
    },
    {
      code: '2202000',
      label: 'Open posterior dislocation of distal end of femur',
    },
    {
      code: '2204004',
      label: 'Acquired deformity of pinna',
    },
    {
      code: '2213002',
      label: 'Congenital anomaly of vena cava',
    },
    {
      code: '2216005',
      label: 'Nocturnal emission',
    },
    {
      code: '2217001',
      label: 'Superficial injury of perineum without infection',
    },
    {
      code: '2219003',
      label: 'Disability evaluation, disability 100%',
    },
    {
      code: '2224000',
      label: 'Selenium poisoning',
    },
    {
      code: '2228002',
      label: 'Scintillating scotoma',
    },
    {
      code: '2229005',
      label: 'Chimera',
    },
    {
      code: '2231001',
      label: 'Nerve plexus disorder',
    },
    {
      code: '2237002',
      label: 'Painful breathing -pleurodynia',
    },
    {
      code: '2239004',
      label: 'Previous pregnancies 6',
    },
    {
      code: '2241003',
      label: 'X-linked absence of thyroxine-binding globulin',
    },
    {
      code: '2243000',
      label: 'Hypercalcemia due to hyperthyroidism',
    },
    {
      code: '2245007',
      label: 'Foreign body in nasopharynx',
    },
    {
      code: '2251002',
      label: 'Primary hypotony of eye',
    },
    {
      code: '2256007',
      label: 'Monovular twins',
    },
    {
      code: '2261009',
      label: 'Obstetrical pulmonary fat embolism',
    },
    {
      code: '2268003',
      label: 'Victim of homosexual aggression',
    },
    {
      code: '2284002',
      label: 'Pulsating exophthalmos',
    },
    {
      code: '2295008',
      label: 'Closed fracture of upper end of forearm',
    },
    {
      code: '2296009',
      label: 'Iron dextran toxicity',
    },
    {
      code: '2298005',
      label: 'Focal facial dermal dysplasia',
    },
    {
      code: '2301009',
      label:
        'Psychosomatic factor in physical condition, psychological component of unknown degree',
    },
    {
      code: '2303007',
      label: 'Inguinal hernia with gangrene',
    },
    {
      code: '2304001',
      label: 'Intervertebral discitis',
    },
    {
      code: '2307008',
      label: 'Peripancreatic fat necrosis',
    },
    {
      code: '2308003',
      label: 'Silent alleles',
    },
    {
      code: '2312009',
      label:
        'Reactive attachment disorder of infancy OR early childhood, inhibited type',
    },
    {
      code: '2314005',
      label: 'Unprotected intercourse',
    },
    {
      code: '2326000',
      label: 'Marriage annulment',
    },
    {
      code: '2339001',
      label: 'Sexual overexposure',
    },
    {
      code: '2341000',
      label: 'Infection caused by Moniliformis',
    },
    {
      code: '2351004',
      label: 'Genetic transduction',
    },
    {
      code: '2355008',
      label: 'Rud syndrome',
    },
    {
      code: '2359002',
      label: 'Hyper-beta-alaninemia',
    },
    {
      code: '2365002',
      label: 'Simple chronic pharyngitis',
    },
    {
      code: '2366001',
      label: 'Late effect of dislocation',
    },
    {
      code: '2367005',
      label: 'Acute hemorrhagic gastritis',
    },
    {
      code: '2374000',
      label: 'Monofascicular block',
    },
    {
      code: '2385003',
      label: 'Cellulitis of pectoral region',
    },
    {
      code: '2388001',
      label: 'Normal variation in translucency',
    },
    {
      code: '2390000',
      label: 'Acute gonococcal vulvovaginitis',
    },
    {
      code: '2391001',
      label: 'Achondrogenesis',
    },
    {
      code: '2396006',
      label: 'Malignant pyoderma',
    },
    {
      code: '2398007',
      label: 'Quinidine toxicity by electrocardiogram',
    },
    {
      code: '2403008',
      label: 'Dependence syndrome',
    },
    {
      code: '2415007',
      label: 'Lumbosacral root lesion',
    },
    {
      code: '2418009',
      label: 'Polyester fume causing toxic effect',
    },
    {
      code: '2419001',
      label: 'Open wound of forehead with complication',
    },
    {
      code: '2420007',
      label: 'Third degree burn of multiple sites of upper limb',
    },
    {
      code: '2432006',
      label: 'Cerebrospinal fluid circulation disorder',
    },
    {
      code: '2435008',
      label: 'Ascaridiasis',
    },
    {
      code: '2437000',
      label: 'Placenta circumvallata',
    },
    {
      code: '2438005',
      label: 'Iniencephaly',
    },
    {
      code: '2439002',
      label: 'Purulent endocarditis',
    },
    {
      code: '2443003',
      label: 'Hydrogen sulfide poisoning',
    },
    {
      code: '2452007',
      label: 'Fetal rotation',
    },
    {
      code: '2463005',
      label: 'Acquired heterochromia of iris',
    },
    {
      code: '2469009',
      label: 'Onychomalacia',
    },
    {
      code: '2470005',
      label: 'Brain damage',
    },
    {
      code: '2471009',
      label: 'Intra-abdominal abscess postprocedure',
    },
    {
      code: '2472002',
      label: 'Passes no urine',
    },
    {
      code: '2473007',
      label: 'Intermittent vertical squint',
    },
    {
      code: '2477008',
      label: 'Superficial phlebitis',
    },
    {
      code: '2492009',
      label: 'Disorder of nutrition',
    },
    {
      code: '2495006',
      label: 'Congenital cerebral arteriovenous aneurysm',
    },
    {
      code: '2496007',
      label: 'Acalculia',
    },
    {
      code: '2506003',
      label: 'Early onset dysthymia',
    },
    {
      code: '2513003',
      label: 'Tinea capitis caused by Trichophyton',
    },
    {
      code: '2518007',
      label: 'Cryptogenic sexual precocity',
    },
    {
      code: '2521009',
      label: 'Bone conduction better than air',
    },
    {
      code: '2523007',
      label: 'Salmonella pneumonia',
    },
    {
      code: '2526004',
      label: 'Noninflammatory disorder of the female genital organs',
    },
    {
      code: '2528003',
      label: 'Viremia',
    },
    {
      code: '2532009',
      label: 'Choroidal rupture',
    },
    {
      code: '2534005',
      label: 'Congenital absence of vena cava',
    },
    {
      code: '2538008',
      label: 'Ketosis',
    },
    {
      code: '2541004',
      label: 'Compulsive buying',
    },
    {
      code: '2554006',
      label: 'Acute purulent pericarditis',
    },
    {
      code: '2556008',
      label: 'Disease of supporting structures of teeth',
    },
    {
      code: '2560006',
      label: 'Complex syndactyly of fingers',
    },
    {
      code: '2562003',
      label: 'Athanasia trifurcata poisoning',
    },
    {
      code: '2576002',
      label: 'Trachoma',
    },
    {
      code: '2581006',
      label: 'Clasp knife rigidity',
    },
    {
      code: '2582004',
      label: 'Deep third degree burn of multiple sites of lower limb',
    },
    {
      code: '2583009',
      label: 'Filigreed network of venous valves',
    },
    {
      code: '2584003',
      label: 'Cerebral degeneration in childhood',
    },
    {
      code: '2585002',
      label: 'Pneumococcal pleurisy',
    },
    {
      code: '2589008',
      label: 'Acute dacryoadenitis',
    },
    {
      code: '2591000',
      label: 'Crush injury of shoulder region',
    },
    {
      code: '2593002',
      label: 'Dubowitz syndrome',
    },
    {
      code: '2602008',
      label: 'Hemarthrosis of shoulder',
    },
    {
      code: '2606006',
      label: 'Boil of perineum',
    },
    {
      code: '2615004',
      label: 'Graafian follicle cyst',
    },
    {
      code: '2618002',
      label: 'Chronic recurrent major depressive disorder',
    },
    {
      code: '2622007',
      label: 'Infected ulcer of skin',
    },
    {
      code: '2624008',
      label: 'Prepubertal periodontitis',
    },
    {
      code: '2625009',
      label: 'Senter syndrome',
    },
    {
      code: '2630008',
      label: 'Open wound of finger without complication',
    },
    {
      code: '2634004',
      label: 'Decreased blood erythrocyte volume',
    },
    {
      code: '2638001',
      label: 'Hypercalcemia caused by a drug',
    },
    {
      code: '2640006',
      label: 'Clinical stage 4',
    },
    {
      code: '2651006',
      label: 'Closed traumatic dislocation of elbow joint',
    },
    {
      code: '2655002',
      label: 'Invalidism',
    },
    {
      code: '2657005',
      label: 'Overflow proteinuria',
    },
    {
      code: '2663001',
      label: 'Palpatory proteinuria',
    },
    {
      code: '2665008',
      label: 'Coordinate convulsion',
    },
    {
      code: '2683000',
      label: 'Nonvenomous insect bite of axilla without infection',
    },
    {
      code: '2689001',
      label: 'Dominant dystrophic epidermolysis bullosa with absence of skin',
    },
    {
      code: '2694001',
      label: 'Myelophthisic anemia',
    },
    {
      code: '2704003',
      label: 'Acute disease',
    },
    {
      code: '2707005',
      label: 'Necrotizing enterocolitis',
    },
    {
      code: '2713001',
      label: 'Closed pneumothorax',
    },
    {
      code: '2724004',
      label: 'Auditory recruitment',
    },
    {
      code: '2725003',
      label: 'Previous abnormality of glucose tolerance',
    },
    {
      code: '2733002',
      label: 'Heel pain',
    },
    {
      code: '2736005',
      label: 'Honeycomb atrophy of face',
    },
    {
      code: '2740001',
      label: 'Gouty proteinuria',
    },
    {
      code: '2749000',
      label: 'Congenital deformity of hip',
    },
    {
      code: '2751001',
      label: 'Fibrocalculous pancreatic diabetes',
    },
    {
      code: '2761008',
      label: 'Decreased stool caliber',
    },
    {
      code: '2764000',
      label: 'Joint crackle',
    },
    {
      code: '2770006',
      label:
        'Fetal or neonatal effect of antibiotic transmitted via placenta and/or breast milk',
    },
    {
      code: '2772003',
      label: 'Epidermolysis bullosa acquisita',
    },
    {
      code: '2775001',
      label: 'Intra-articular loose body',
    },
    {
      code: '2776000',
      label: 'Organic brain syndrome',
    },
    {
      code: '2781009',
      label: 'Miscarriage complicated by delayed and/or excessive hemorrhage',
    },
    {
      code: '2782002',
      label: 'Temporomandibular dysplasia',
    },
    {
      code: '2783007',
      label: 'Gastrojejunal ulcer without hemorrhage AND without perforation',
    },
    {
      code: '2786004',
      label:
        'Epithelial ovarian tumor, International Federation of Gynecology and Obstetrics stage III',
    },
    {
      code: '2790002',
      label:
        'Impairment level: one eye: total impairment: other eye: not specified',
    },
    {
      code: '2805007',
      label: 'Phosmet poisoning',
    },
    {
      code: '2806008',
      label: 'Impaired psychomotor development',
    },
    {
      code: '2807004',
      label: 'Chronic gastrojejunal ulcer with perforation',
    },
    {
      code: '2808009',
      label: 'Infection caused by Prosthenorchis elegans',
    },
    {
      code: '2815001',
      label: 'Sexual pyromania',
    },
    {
      code: '2816000',
      label: 'Dilated cardiomyopathy secondary to myotonic dystrophy',
    },
    {
      code: '2818004',
      label: 'Congenital vascular anomaly of eye',
    },
    {
      code: '2819007',
      label: 'Magnesium sulfate poisoning',
    },
    {
      code: '2825006',
      label: 'Abrasion and/or friction burn of gum without infection',
    },
    {
      code: '2828008',
      label: 'Congenital stenosis of nares',
    },
    {
      code: '2829000',
      label: 'Uhl disease',
    },
    {
      code: '2831009',
      label: 'Pyloric antral vascular ectasia',
    },
    {
      code: '2835000',
      label: 'Hemolytic anemia due to cardiac trauma',
    },
    {
      code: '2836004',
      label: 'Butane causing toxic effect',
    },
    {
      code: '2838003',
      label: 'Piblokto',
    },
    {
      code: '2840008',
      label:
        'Open fracture of vault of skull with cerebral laceration AND/OR contusion',
    },
    {
      code: '2850009',
      label: 'Infection caused by Schistosoma incognitum',
    },
    {
      code: '2853006',
      label: 'Macular keratitis',
    },
    {
      code: '2856003',
      label: 'Vitamin A-responsive dermatosis',
    },
    {
      code: '2858002',
      label: 'Postpartum sepsis',
    },
    {
      code: '2884008',
      label: 'Spherophakia-brachymorphia syndrome',
    },
    {
      code: '2893009',
      label: 'Anomaly of chromosome pair 10',
    },
    {
      code: '2897005',
      label: 'Immune thrombocytopenia',
    },
    {
      code: '2899008',
      label: 'Thought blocking',
    },
    {
      code: '2900003',
      label: 'Fibromuscular dysplasia of renal artery',
    },
    {
      code: '2901004',
      label: 'Altered blood passed per rectum',
    },
    {
      code: '2902006',
      label: 'Decreased lymphocyte life span',
    },
    {
      code: '2904007',
      label: 'Male infertility',
    },
    {
      code: '2910007',
      label: 'Discharge from penis',
    },
    {
      code: '2912004',
      label: 'Cystic-bullous disease of the lung',
    },
    {
      code: '2917005',
      label: 'Transient hypothyroidism',
    },
    {
      code: '2918000',
      label: 'Infection caused by Bacteroides',
    },
    {
      code: '2919008',
      label: 'Nausea, vomiting and diarrhea',
    },
    {
      code: '2929001',
      label: 'Arterial occlusion',
    },
    {
      code: '2935001',
      label: 'Antiasthmatic poisoning',
    },
    {
      code: '2940009',
      label: 'Intrabasal vesicular dermatitis',
    },
    {
      code: '2946003',
      label: 'Osmotic diarrhea',
    },
    {
      code: '2951009',
      label: 'Atopic cataract',
    },
    {
      code: '2955000',
      label: 'Chronic ulcerative pulpitis',
    },
    {
      code: '2965006',
      label: 'Nevoid congenital alopecia',
    },
    {
      code: '2967003',
      label: 'Non-comitant strabismus',
    },
    {
      code: '2972007',
      label: 'Occlusion of anterior spinal artery',
    },
    {
      code: '2973002',
      label: 'Pelvic organ injury without open wound into abdominal cavity',
    },
    {
      code: '2978006',
      label: 'Aneurysm of conjunctiva',
    },
    {
      code: '2981001',
      label: 'Pulsatile mass of abdomen',
    },
    {
      code: '2989004',
      label: 'Complication following molar AND/OR ectopic pregnancy',
    },
    {
      code: '2990008',
      label: 'Lymphocytic leukemoid reaction',
    },
    {
      code: '2992000',
      label: 'Pigmentary pallidal degeneration',
    },
    {
      code: '2994004',
      label: 'Brain fag',
    },
    {
      code: '2999009',
      label: 'Injury of ear region',
    },
    {
      code: '3002002',
      label: 'Thyroid hemorrhage',
    },
    {
      code: '3004001',
      label: 'Congenital dilatation of esophagus',
    },
    {
      code: '3006004',
      label: 'Altered consciousness',
    },
    {
      code: '3009006',
      label: 'Solanum malacoxylon poisoning',
    },
    {
      code: '3013004',
      label: 'Open wound of ear drum without complication',
    },
    {
      code: '3014005',
      label: 'Autoeczematization',
    },
    {
      code: '3018008',
      label: 'Penetration of eyeball with magnetic foreign body',
    },
    {
      code: '3019000',
      label: 'Closed anterior dislocation of elbow',
    },
    {
      code: '3021005',
      label: 'Normal gastric acidity',
    },
    {
      code: '3023008',
      label:
        'Acute peptic ulcer without hemorrhage, without perforation AND without obstruction',
    },
    {
      code: '3032005',
      label: 'Nonvenomous insect bite of cheek without infection',
    },
    {
      code: '3033000',
      label:
        'Bone AND/OR joint disorder of pelvis in mother complicating pregnancy, childbirth AND/OR puerperium',
    },
    {
      code: '3038009',
      label: 'Acute lymphangitis of umbilicus',
    },
    {
      code: '3044008',
      label: 'Vitreous prolapse',
    },
    {
      code: '3053001',
      label: 'Poisoning caused by nitroglycerin',
    },
    {
      code: '3059002',
      label: 'Acute lymphangitis of thigh',
    },
    {
      code: '3067005',
      label: 'Weak C phenotype',
    },
    {
      code: '3071008',
      label: 'Widow',
    },
    {
      code: '3072001',
      label: 'Hormone-induced hypopituitarism',
    },
    {
      code: '3073006',
      label: 'Ruvalcaba syndrome',
    },
    {
      code: '3084004',
      label: 'Nonvenomous insect bite of gum without infection',
    },
    {
      code: '3089009',
      label: 'Disability evaluation, impairment, class 7',
    },
    {
      code: '3094009',
      label: 'Vomiting in infants AND/OR children',
    },
    {
      code: '3095005',
      label: 'Induced malaria',
    },
    {
      code: '3097002',
      label: 'Superficial injury of lip with infection',
    },
    {
      code: '3098007',
      label: 'Ventricular septal rupture',
    },
    {
      code: '3105002',
      label: 'Intron',
    },
    {
      code: '3109008',
      label: 'Secondary dysthymia early onset',
    },
    {
      code: '3110003',
      label: 'AOM - Acute otitis media',
    },
    {
      code: '3119002',
      label:
        'Brain stem laceration with open intracranial wound AND loss of consciousness',
    },
    {
      code: '3129009',
      label: 'Infarction of ovary',
    },
    {
      code: '3135009',
      label: 'OE - Otitis externa',
    },
    {
      code: '3140001',
      label: 'Citrullinemia, subacute type',
    },
    {
      code: '3144005',
      label: 'Staphylococcal pleurisy',
    },
    {
      code: '3158007',
      label:
        'Panic disorder with agoraphobia, agoraphobic avoidance in partial remission AND panic attacks in partial remission',
    },
    {
      code: '3160009',
      label: 'Infertility of cervical origin',
    },
    {
      code: '3163006',
      label: 'Acute adenoviral follicular conjunctivitis',
    },
    {
      code: '3168002',
      label: 'Thrombophlebitis of intracranial venous sinus',
    },
    {
      code: '3185000',
      label: 'Mood-congruent delusion',
    },
    {
      code: '3199001',
      label: 'Sprain of shoulder joint',
    },
    {
      code: '3200003',
      label: 'Sacrocoxalgia',
    },
    {
      code: '3208005',
      label: 'Open wound of ossicles without complication',
    },
    {
      code: '3214003',
      label: 'Invasive pulmonary aspergillosis',
    },
    {
      code: '3217005',
      label: 'Open dislocation of sixth cervical vertebra',
    },
    {
      code: '3218000',
      label: 'Mycotic disease',
    },
    {
      code: '3219008',
      label: 'Disease type AND/OR category unknown',
    },
    {
      code: '3228009',
      label: 'Closed fracture of the radial shaft',
    },
    {
      code: '3229001',
      label: 'Tracheal ulcer',
    },
    {
      code: '3230006',
      label: 'Illegal termination of pregnancy with afibrinogenemia',
    },
    {
      code: '3238004',
      label: 'Pericarditis',
    },
    {
      code: '3239007',
      label: 'Lymphocyte disorder',
    },
    {
      code: '3253007',
      label: 'Dyschromia',
    },
    {
      code: '3254001',
      label: 'Infection caused by Strongyloides westeri',
    },
    {
      code: '3259006',
      label: 'Homeria species poisoning',
    },
    {
      code: '3261002',
      label: 'Migratory osteolysis',
    },
    {
      code: '3263004',
      label: 'Verumontanitis',
    },
    {
      code: '3272007',
      label: 'Stomatocytosis',
    },
    {
      code: '3274008',
      label: 'Flat chest',
    },
    {
      code: '3275009',
      label: 'Behcet syndrome, vascular type',
    },
    {
      code: '3276005',
      label: 'Toad poisoning',
    },
    {
      code: '3277001',
      label: 'Terminal mood insomnia',
    },
    {
      code: '3282008',
      label: 'Arc eye',
    },
    {
      code: '3283003',
      label: 'Feeling of sand or foreign body in eye',
    },
    {
      code: '3286006',
      label: 'Patient status determination, greatly improved',
    },
    {
      code: '3289004',
      label: 'Anisometropia',
    },
    {
      code: '3291007',
      label: 'Closed fracture of two ribs',
    },
    {
      code: '3298001',
      label: 'Amnestic syndrome',
    },
    {
      code: '3303004',
      label: 'Disease caused by Arenavirus',
    },
    {
      code: '3304005',
      label: 'Bronchial compression',
    },
    {
      code: '3305006',
      label: 'Disorder of lymphatic vessel',
    },
    {
      code: '3308008',
      label: 'Atrophic-hyperplastic gastritis',
    },
    {
      code: '3310005',
      label: 'Foreign body granuloma of skin',
    },
    {
      code: '3321001',
      label: 'Renal abscess',
    },
    {
      code: '3323003',
      label: 'Leukoplakia of penis',
    },
    {
      code: '3327002',
      label: 'Acquired jerk nystagmus',
    },
    {
      code: '3331008',
      label: 'Open fracture of neck of metacarpal bone',
    },
    {
      code: '3344003',
      label: 'Toxic labyrinthitis',
    },
    {
      code: '3345002',
      label: 'Idiopathic osteoporosis',
    },
    {
      code: '3355003',
      label: 'Anti-common cold drug poisoning',
    },
    {
      code: '3358001',
      label: 'Lichen ruber moniliformis',
    },
    {
      code: '3368006',
      label: 'Dull chest pain',
    },
    {
      code: '3376008',
      label: 'Pseudoptyalism',
    },
    {
      code: '3381004',
      label: 'Open fracture of astragalus',
    },
    {
      code: '3387000',
      label: 'Auditory discrimination aphasia',
    },
    {
      code: '3391005',
      label: 'Negative for tumor cells',
    },
    {
      code: '3393008',
      label: 'Phlebitis following infusion, perfusion AND/OR transfusion',
    },
    {
      code: '3398004',
      label: 'Cadmium poisoning',
    },
    {
      code: '3401001',
      label: 'Cercopithecus herpesvirus 1 disease',
    },
    {
      code: '3415004',
      label: 'Cyanosis',
    },
    {
      code: '3419005',
      label: 'Faucial diphtheria',
    },
    {
      code: '3421000',
      label: 'Open blow-out fracture orbit',
    },
    {
      code: '3424008',
      label: 'Heart rate fast',
    },
    {
      code: '3426005',
      label: 'Retained magnetic intraocular foreign body',
    },
    {
      code: '3427001',
      label: 'Nonglucosuric melituria',
    },
    {
      code: '3434004',
      label: 'Myotonia',
    },
    {
      code: '3439009',
      label:
        'Severe combined immunodeficiency (SCID) due to absent peripheral T cell maturation',
    },
    {
      code: '3441005',
      label: 'Disorder of sebaceous gland',
    },
    {
      code: '3446000',
      label: 'Open fracture of T7-T12 level with spinal cord injury',
    },
    {
      code: '3449007',
      label: 'Finger agnosia',
    },
    {
      code: '3456001',
      label: 'Chronic progressive non-hereditary chorea',
    },
    {
      code: '3458000',
      label: 'Myositis ossificans associated with dermato / polymyositis',
    },
    {
      code: '3461004',
      label: 'Deep third degree burn of thumb',
    },
    {
      code: '3464007',
      label: 'Infection caused by Oesophagostomum dentatum',
    },
    {
      code: '3468005',
      label: 'Neonatal infective mastitis',
    },
    {
      code: '3469002',
      label: 'Partial thickness burn of thumb',
    },
    {
      code: '3472009',
      label: 'Spondylolisthesis, grade 4',
    },
    {
      code: '3474005',
      label: 'Glycine max poisoning',
    },
    {
      code: '3480002',
      label: 'Burn of wrist',
    },
    {
      code: '3482005',
      label: 'Postoperative esophagitis',
    },
    {
      code: '3483000',
      label: 'Chronic peptic ulcer with perforation',
    },
    {
      code: '3487004',
      label: 'Pulmonary candidiasis',
    },
    {
      code: '3500002',
      label: 'Open wound of ossicles with complication',
    },
    {
      code: '3502005',
      label: 'Cervical lymphadenitis',
    },
    {
      code: '3503000',
      label: 'Gender identity disorder of adolescence, previously asexual',
    },
    {
      code: '3505007',
      label: 'Nonallopathic lesion of the arm',
    },
    {
      code: '3506008',
      label: 'Stenosis of retinal artery',
    },
    {
      code: '3507004',
      label: 'Abscess of thigh',
    },
    {
      code: '3511005',
      label: 'Infectious thyroiditis',
    },
    {
      code: '3514002',
      label: 'Peribronchial fibrosis of lung',
    },
    {
      code: '3519007',
      label: 'Disorder of synovium',
    },
    {
      code: '3528008',
      label: 'Restricted carbohydrate fat controlled diet',
    },
    {
      code: '3529000',
      label: 'Infection caused by Sanguinicola',
    },
    {
      code: '3530005',
      label: 'Bipolar 1 disorder, single manic episode, full remission',
    },
    {
      code: '3531009',
      label: 'Intrapsychic conflict',
    },
    {
      code: '3533007',
      label: 'Acute palmoplantar pustular psoriasis',
    },
    {
      code: '3539006',
      label: 'Enteromenia',
    },
    {
      code: '3542000',
      label:
        'Laceration extending into parenchyma of spleen with open wound into abdominal cavity',
    },
    {
      code: '3544004',
      label: 'Hair-splitting',
    },
    {
      code: '3545003',
      label: 'Diastolic dysfunction',
    },
    {
      code: '3548001',
      label: 'Brachial plexus disorder',
    },
    {
      code: '3549009',
      label: 'Pancreatic acinar atrophy',
    },
    {
      code: '3558002',
      label: 'Mesenteric infarction',
    },
    {
      code: '3560000',
      label: 'Bilateral recurrent inguinal hernia',
    },
    {
      code: '3570003',
      label: 'Increased blood erythrocyte volume',
    },
    {
      code: '3571004',
      label: 'Megaloblastic anemia due to pancreatic insufficiency',
    },
    {
      code: '3577000',
      label: 'Lattice retinal degeneration',
    },
    {
      code: '3585009',
      label: 'Blinking',
    },
    {
      code: '3586005',
      label: 'Psychogenic fugue',
    },
    {
      code: '3589003',
      label: 'Syphilitic pericarditis',
    },
    {
      code: '3590007',
      label: 'Enteroenteric fistula',
    },
    {
      code: '3591006',
      label: 'Metabolic acidosis, normal anion gap, bicarbonate losses',
    },
    {
      code: '3598000',
      label: 'Partial recent retinal detachment with single defect',
    },
    {
      code: '3611003',
      label: 'Demeton poisoning',
    },
    {
      code: '3633001',
      label: 'Abscess of hand',
    },
    {
      code: '3634007',
      label: 'Legal termination of pregnancy complicated by metabolic disorder',
    },
    {
      code: '3639002',
      label: 'Glossoptosis',
    },
    {
      code: '3640000',
      label: 'Late effect of traumatic amputation',
    },
    {
      code: '3641001',
      label: 'Infection caused by Coenurosis serialis',
    },
    {
      code: '3642008',
      label: 'Steryl-sulfate sulfohydrolase deficiency',
    },
    {
      code: '3644009',
      label: 'Macerated skin',
    },
    {
      code: '3649004',
      label: 'Contusion, multiple sites of trunk',
    },
    {
      code: '3650004',
      label: 'Congenital absence of liver,total',
    },
    {
      code: '3652007',
      label: 'Overproduction of growth hormone',
    },
    {
      code: '3657001',
      label: 'Osteospermum species poisoning',
    },
    {
      code: '3660008',
      label: 'Lethal glossopharyngeal defect',
    },
    {
      code: '3662000',
      label: 'Rolling hiatus hernia',
    },
    {
      code: '3677008',
      label: 'Academic problem',
    },
    {
      code: '3680009',
      label: 'Monocephalus tripus dibrachius',
    },
    {
      code: '3681008',
      label: 'Thrombophlebitis of torcular Herophili',
    },
    {
      code: '3696007',
      label: 'Functional dyspepsia',
    },
    {
      code: '3699000',
      label: 'Transverse deficiency of arm',
    },
    {
      code: '3703002',
      label: 'Ischiatic hernia with gangrene',
    },
    {
      code: '3704008',
      label: 'Diffuse endocapillary proliferative glomerulonephritis',
    },
    {
      code: '3705009',
      label: 'Congenital malformation of anterior chamber of eye',
    },
    {
      code: '3712000',
      label: 'Degenerated eye',
    },
    {
      code: '3716002',
      label: 'Thyroid goiter',
    },
    {
      code: '3720003',
      label: 'Abnormal presence of hemoglobin',
    },
    {
      code: '3723001',
      label: 'Joint inflammation',
    },
    {
      code: '3733009',
      label: 'Congenital eventration of right crus of diaphragm',
    },
    {
      code: '3736001',
      label: 'Open wound of thumbnail with tendon involvement',
    },
    {
      code: '3738000',
      label: 'VH - Viral hepatitis',
    },
    {
      code: '3744001',
      label: 'Hyperlipoproteinemia',
    },
    {
      code: '3745000',
      label: 'Sleep rhythm problem',
    },
    {
      code: '3747008',
      label: 'EC - Ejection click',
    },
    {
      code: '3750006',
      label: 'Arteriospasm',
    },
    {
      code: '3751005',
      label: 'Contusion of labium',
    },
    {
      code: '3752003',
      label: 'Infection by Trichuris',
    },
    {
      code: '3754002',
      label: 'Dysplasia of vagina',
    },
    {
      code: '3755001',
      label: 'PRP - Pityriasis rubra pilaris',
    },
    {
      code: '3756000',
      label: 'Static ataxia',
    },
    {
      code: '3759007',
      label: 'Injury of heart with open wound into thorax',
    },
    {
      code: '3760002',
      label: 'Familial multiple factor deficiency syndrome, type V',
    },
    {
      code: '3762005',
      label: 'Bilateral recurrent femoral hernia with gangrene',
    },
    {
      code: '3763000',
      label: 'Expected bereavement due to life event',
    },
    {
      code: '3783004',
      label: 'Enamel pearls',
    },
    {
      code: '3797007',
      label: 'Periodontal cyst',
    },
    {
      code: '3798002',
      label: 'Premature birth of identical twins, both stillborn',
    },
    {
      code: '3815005',
      label: 'Crohn disease of rectum',
    },
    {
      code: '3820005',
      label: 'Inner ear conductive hearing loss',
    },
    {
      code: '3827008',
      label: 'Aneurysm of artery of neck',
    },
    {
      code: '3830001',
      label: 'Subcutaneous emphysema',
    },
    {
      code: '3841004',
      label: 'Blister of cheek with infection',
    },
    {
      code: '3845008',
      label: 'Duplication of intestine',
    },
    {
      code: '3855007',
      label: 'Disorder of pancreas',
    },
    {
      code: '3859001',
      label: 'Late effect of open wound of extremities without tendon injury',
    },
    {
      code: '3873005',
      label:
        'Failed attempted termination of pregnancy with acute necrosis of liver',
    },
    {
      code: '3885002',
      label: 'ABO isoimmunization in pregnancy',
    },
    {
      code: '3886001',
      label: 'Congenital fecaliths',
    },
    {
      code: '3899003',
      label: 'Neutropenic typhlitis',
    },
    {
      code: '3900008',
      label: 'Mixed sensory-motor polyneuropathy',
    },
    {
      code: '3902000',
      label: 'Non dose-related drug-induced neutropenia',
    },
    {
      code: '3903005',
      label: 'Closed traumatic pneumothorax',
    },
    {
      code: '3908001',
      label: 'Infestation caused by Haematopinus',
    },
    {
      code: '3909009',
      label: 'Coeur en sabot',
    },
    {
      code: '3913002',
      label:
        'Injury of gastrointestinal tract with open wound into abdominal cavity',
    },
    {
      code: '3914008',
      label: 'Mental disorder in childhood',
    },
    {
      code: '3928002',
      label: 'Zika virus disease',
    },
    {
      code: '3939004',
      label: 'Bacterial colony density, transparent',
    },
    {
      code: '3944006',
      label: 'X-linked placental steryl-sulfatase deficiency',
    },
    {
      code: '3947004',
      label: 'High oxygen affinity hemoglobin polycythemia',
    },
    {
      code: '3950001',
      label: 'Birth',
    },
    {
      code: '3951002',
      label: 'Proctitis',
    },
    {
      code: '3972004',
      label: 'Idiopathic insomnia',
    },
    {
      code: '3975002',
      label: 'Deep third degree burn of lower limb',
    },
    {
      code: '3978000',
      label: 'AIHA - Warm autoimmune hemolytic anemia',
    },
    {
      code: '3987009',
      label: 'Congenital absence of trachea',
    },
    {
      code: '3993001',
      label: 'Infection caused by Muellerius',
    },
    {
      code: '3999002',
      label: 'Acute pyelitis without renal medullary necrosis',
    },
    {
      code: '4003003',
      label: 'Alphavirus disease',
    },
    {
      code: '4004009',
      label: 'Monster with cranial anomalies',
    },
    {
      code: '4006006',
      label: 'Foetal tachycardia affecting management of mother',
    },
    {
      code: '4009004',
      label: 'Lower urinary tract infection',
    },
    {
      code: '4016003',
      label: 'Empyema of mastoid',
    },
    {
      code: '4017007',
      label: 'Increased stratum corneum adhesiveness',
    },
    {
      code: '4022007',
      label: 'Vulvitis circumscripta plasmacellularis',
    },
    {
      code: '4026005',
      label: 'Interstitial mastitis associated with childbirth',
    },
    {
      code: '4030008',
      label: 'Le Dantec virus disease',
    },
    {
      code: '4038001',
      label: 'Myrotheciotoxicosis',
    },
    {
      code: '4039009',
      label: 'Multiple vitamin deficiency disease',
    },
    {
      code: '4040006',
      label: 'Hassall-Henle bodies',
    },
    {
      code: '4041005',
      label: 'Congenital anomaly of macula',
    },
    {
      code: '4046000',
      label: 'Degenerative spondylolisthesis',
    },
    {
      code: '4062006',
      label: 'Lumbosacral plexus lesion',
    },
    {
      code: '4063001',
      label: 'Achillodynia',
    },
    {
      code: '4069002',
      label: 'Anoxic brain damage during AND/OR resulting from a procedure',
    },
    {
      code: '4070001',
      label: 'Palinphrasia',
    },
    {
      code: '4075006',
      label: 'Peganum harmala poisoning',
    },
    {
      code: '4082005',
      label: 'Syphilitic myocarditis',
    },
    {
      code: '4088009',
      label: 'Acquired hydrocephalus',
    },
    {
      code: '4089001',
      label: 'Meningococcemia',
    },
    {
      code: '4092002',
      label: 'Nonallopathic lesion of costovertebral region',
    },
    {
      code: '4103001',
      label: 'Complex partial seizure',
    },
    {
      code: '4106009',
      label: 'Rotator cuff rupture',
    },
    {
      code: '4107000',
      label: 'Infertile male syndrome',
    },
    {
      code: '4113009',
      label: 'Arrested hydrocephalus',
    },
    {
      code: '4120002',
      label: 'Bronchiolitis',
    },
    {
      code: '4124006',
      label: 'Insect bite, nonvenomous, of vagina, infected',
    },
    {
      code: '4127004',
      label: 'Prostatic obstruction',
    },
    {
      code: '4129001',
      label: 'Argyll-Robertson pupil',
    },
    {
      code: '4135001',
      label: '11p partial monosomy syndrome',
    },
    {
      code: '4136000',
      label: 'Macrodactylia of toes',
    },
    {
      code: '4142001',
      label: 'Muscular asthenopia',
    },
    {
      code: '4152002',
      label: 'Acquired hypoprothrombinemia',
    },
    {
      code: '4160001',
      label: 'Congenital anomaly of upper respiratory system',
    },
    {
      code: '4168008',
      label: 'Tibial plateau chondromalacia',
    },
    {
      code: '4170004',
      label: 'Ehlers-Danlos syndrome, procollagen proteinase resistant',
    },
    {
      code: '4174008',
      label: 'Tripartite placenta',
    },
    {
      code: '4175009',
      label: 'Infestation by Estrus',
    },
    {
      code: '4178006',
      label: 'Partial recent retinal detachment with multiple defects',
    },
    {
      code: '4181001',
      label: 'Normal peak expiratory flow rate',
    },
    {
      code: '4183003',
      label: 'Charcot-Marie-Tooth disease, type IC',
    },
    {
      code: '4184009',
      label: 'Congenital malformation of the endocrine glands',
    },
    {
      code: '4191007',
      label: 'Scaphoid head',
    },
    {
      code: '4195003',
      label: 'Duplication of anus',
    },
    {
      code: '4197006',
      label: 'Disability evaluation, impairment, class 5',
    },
    {
      code: '4199009',
      label: '18p partial trisomy syndrome',
    },
    {
      code: '4208000',
      label: 'Closed multiple fractures of both lower limbs',
    },
    {
      code: '4210003',
      label: 'OH - Ocular hypertension',
    },
    {
      code: '4223005',
      label: 'Parkinsonism caused by drug',
    },
    {
      code: '4224004',
      label: 'Complication of infusion',
    },
    {
      code: '4225003',
      label: 'Nasal tuberculosis',
    },
    {
      code: '4229009',
      label: 'Phthisical eye',
    },
    {
      code: '4232007',
      label: 'Chronic vulvitis',
    },
    {
      code: '4237001',
      label: 'Suppurative pulpitis',
    },
    {
      code: '4240001',
      label: 'Rupture of aorta',
    },
    {
      code: '4241002',
      label: 'Listeria infection',
    },
    {
      code: '4242009',
      label: '18q partial monosomy syndrome',
    },
    {
      code: '4244005',
      label: 'Urticaria neonatorum',
    },
    {
      code: '4248008',
      label: 'Synovitis AND/OR tenosynovitis associated with another disease',
    },
    {
      code: '4249000',
      label: 'Poor peripheral circulation',
    },
    {
      code: '4251001',
      label: 'Internal eye sign',
    },
    {
      code: '4260009',
      label: 'Sacral spinal cord injury without bone injury',
    },
    {
      code: '4262001',
      label: 'Phlebitis of superior sagittal sinus',
    },
    {
      code: '4264000',
      label: 'Chronic pericoronitis',
    },
    {
      code: '4269005',
      label:
        'Chronic gastrojejunal ulcer without hemorrhage AND without perforation',
    },
    {
      code: '4273008',
      label: 'Closed posterior dislocation of elbow',
    },
    {
      code: '4275001',
      label: 'Conjugate gaze spasm',
    },
    {
      code: '4278004',
      label:
        'Superficial foreign body of axilla without major open wound but with infection',
    },
    {
      code: '4283007',
      label: 'Mirizzi syndrome',
    },
    {
      code: '4287008',
      label: 'Chordee of penis',
    },
    {
      code: '4294006',
      label: 'Isosexual precocious puberty',
    },
    {
      code: '4300009',
      label: 'Deep third degree burn of forearm',
    },
    {
      code: '4301008',
      label: 'Autoimmune state',
    },
    {
      code: '4306003',
      label: 'Cluster B personality disorder',
    },
    {
      code: '4307007',
      label:
        'Pregestational diabetes mellitus AND/OR impaired glucose tolerance, modified White class F',
    },
    {
      code: '4308002',
      label: 'RSIS - Repetitive strain injury syndrome',
    },
    {
      code: '4310000',
      label: 'Third degree burn of wrist AND/OR hand',
    },
    {
      code: '4313003',
      label: 'Acardiacus anceps',
    },
    {
      code: '4316006',
      label: 'Myometritis',
    },
    {
      code: '4320005',
      label: 'Factor V deficiency',
    },
    {
      code: '4324001',
      label: 'Subacute cystitis',
    },
    {
      code: '4325000',
      label: '11q partial monosomy syndrome',
    },
    {
      code: '4332009',
      label:
        'Subarachnoid hemorrhage following injury without open intracranial wound AND with concussion',
    },
    {
      code: '4338008',
      label: 'Arnold nerve reflex cough syndrome',
    },
    {
      code: '4340003',
      label: 'Acrodermatitis chronica atrophicans',
    },
    {
      code: '4349002',
      label: 'Open fracture of multiple sites of metacarpus',
    },
    {
      code: '4354006',
      label: 'Open dislocation of scapula',
    },
    {
      code: '4356008',
      label: 'Gingival soft tissue recession',
    },
    {
      code: '4359001',
      label: 'Early congenital syphilis',
    },
    {
      code: '4364002',
      label: 'Structure of associations',
    },
    {
      code: '4367009',
      label: 'Hoover sign',
    },
    {
      code: '4373005',
      label: 'Clubbing of nail',
    },
    {
      code: '4374004',
      label: 'TV - Congenital tricuspid valve abnormality',
    },
    {
      code: '4381006',
      label: 'Verbal paraphasia',
    },
    {
      code: '4386001',
      label: 'Bronchospasm',
    },
    {
      code: '4390004',
      label: 'Chronic lithium nephrotoxicity',
    },
    {
      code: '4397001',
      label: 'Partial congenital duodenal obstruction',
    },
    {
      code: '4399003',
      label: 'Acute hemorrhagic pancreatitis',
    },
    {
      code: '4403007',
      label: 'Exclamation point hair',
    },
    {
      code: '4406004',
      label: 'Congenital anomaly of male genital system',
    },
    {
      code: '4409006',
      label:
        'Combined methylmalonic acidemia and homocystinuria due to defects in adenosylcobalamin and methylcobalamin synthesis',
    },
    {
      code: '4410001',
      label: 'Retroperitoneal hernia with obstruction',
    },
    {
      code: '4412009',
      label: 'Digital nerve injury',
    },
    {
      code: '4414005',
      label: 'Infection caused by Setaria',
    },
    {
      code: '4416007',
      label: 'Heerfordt syndrome',
    },
    {
      code: '4418008',
      label: 'Gangrenous ergotism',
    },
    {
      code: '4426000',
      label: 'Ten previous induced terminations of pregnancy',
    },
    {
      code: '4434006',
      label: 'BS - Bloom syndrome',
    },
    {
      code: '4439001',
      label: 'Axenfeld-Schurenberg syndrome',
    },
    {
      code: '4441000',
      label: 'Severe bipolar disorder with psychotic features',
    },
    {
      code: '4445009',
      label: 'TB - Urogenital tuberculosis',
    },
    {
      code: '4448006',
      label: 'Allergic headache',
    },
    {
      code: '4451004',
      label: 'Illegal termination of pregnancy with renal tubular necrosis',
    },
    {
      code: '4461006',
      label: 'Complication of administrative procedure',
    },
    {
      code: '4463009',
      label: 'Indiana-Maryland type amyloid polyneuropathy',
    },
    {
      code: '4464003',
      label: 'Rocio virus disease',
    },
    {
      code: '4465002',
      label: 'Spherophakia',
    },
    {
      code: '4468000',
      label: 'Oppenheim gait',
    },
    {
      code: '4470009',
      label: 'Blanching of skin',
    },
    {
      code: '4473006',
      label: 'Migraine with aura',
    },
    {
      code: '4477007',
      label: 'Juvenile myopathy AND lactate acidosis',
    },
    {
      code: '4478002',
      label: 'Multiple fractures of upper AND lower limbs',
    },
    {
      code: '4481007',
      label: 'Abnormal gastric secretion regulation',
    },
    {
      code: '4483005',
      label: 'Syphilitic punched out ulcer',
    },
  ],
  subscriptionPlans: {
    BASIC: 'basic',
    MEMBERSHIP: 'membership',
    PREMIUM: 'premium',
  },
  subscriptionPlanOptions: [
    { label: 'Basic', value: 'basic' },
    { label: 'Membership', value: 'membership' },
    { label: 'Premium', value: 'premium' },
  ],
  genderOptions: {
    Male: 'Male',
    Female: 'Female',
    'Non-Binary': 'Non-Binary',
  },
  ETHNICITY_OPTIONS: [
    {
      label: 'American Indian or Alaska Native',
      value: 'American Indian or Alaska Native',
    },
    { label: 'Asian', value: 'Asian' },
    { label: 'Black or African American', value: 'Black or African American' },
    { label: 'Hispanic or Latino', value: 'Hispanic or Latino' },
    {
      label: 'Native Hawaiian or Other Pacific Islander',
      value: 'Native Hawaiian or Other Pacific Islander',
    },
    { label: 'White/Caucasian', value: 'White/Caucasian' },
    { label: 'Other', value: 'Other' },
    { label: 'Two or more races', value: 'Two or more races' },
    { label: 'Do not wish to answer', value: 'Do not wish to answer' },
  ],
  RELATIONSHIP_OPTIONS: [
    { label: 'Child', value: 'Child' },
    { label: 'Daughter', value: 'Daughter' },
    { label: 'Employee', value: 'Employee' },
    { label: 'Employer', value: 'Employer' },
    { label: 'Father', value: 'Father' },
    { label: 'Husband', value: 'Husband' },
    { label: 'Mother', value: 'Mother' },
    { label: 'Other', value: 'Other' },
    { label: 'Other Adult', value: 'Other Adult' },
    { label: 'Self', value: 'Self' },
    { label: 'Significant Other', value: 'Significant Other' },
    { label: 'Son', value: 'Son' },
    { label: 'Spouse', value: 'Spouse' },
    { label: 'Unknown', value: 'Unknown' },
    { label: 'Wife', value: 'Wife' },
  ],
  GENDER_OPTIONS: [
    { label: 'Female', value: 'Female' },
    { label: 'Male', value: 'Male' },
    { label: 'Ambiguous', value: 'Ambiguous' },
    { label: 'Other', value: 'Other' },
    { label: 'Not Applicable', value: 'Not Applicable' },
    { label: 'Unknown', value: 'Unknown' },
  ],

  SPECIALITY_OPTIONS: [
    { label: 'Allergist', value: 'Allergist' },
    { label: 'Audiologist', value: 'Audiologist' },
    { label: 'Cardiologist', value: 'Cardiologist' },
    { label: 'Chiropractor', value: 'Chiropractor' },
    { label: 'Dermatologist', value: 'Dermatologist' },
    { label: 'Dietitian', value: 'Dietitian' },
    { label: 'Health Coach', value: 'Health Coach' },
    {
      label: 'Ear, Nose and Throat Specialist',
      value: 'Ear, Nose and Throat Specialist',
    },
    {
      label: 'Emergency Medicine Physician',
      value: 'Emergency Medicine Physician',
    },
    { label: 'Endocrinologist', value: 'Endocrinologist' },
    { label: 'Endodontist', value: 'Endodontist' },
    { label: 'Eye Specialist', value: 'Eye Specialist' },
    { label: 'Family Physician', value: 'Family Physician' },
    {
      label: 'Functional Medicine Specialist',
      value: 'Functional Medicine Specialist',
    },
    { label: 'Gastroenterologist', value: 'Gastroenterologist' },
    { label: 'Geneticist', value: 'Geneticist' },
    { label: 'Hand Surgeon', value: 'Hand Surgeon' },
    { label: 'Hearing Specialist', value: 'Hearing Specialist' },
    { label: 'Hematologist', value: 'Hematologist' },
    {
      label: 'Infectious Disease Specialist',
      value: 'Infectious Disease Specialist',
    },
    { label: 'Infertility Specialist', value: 'Infertility Specialist' },
    {
      label: 'Integrative Medicine Specialist',
      value: 'Integrative Medicine Specialist',
    },
    {
      label: 'Internal Medicine Specialist',
      value: 'Internal Medicine Specialist',
    },
    { label: 'Naturopathic Doctor', value: 'Naturopathic Doctor' },
    { label: 'Nephrologist', value: 'Nephrologist' },
    { label: 'Neurologist', value: 'Neurologist' },
    { label: 'Neurosurgeon', value: 'Neurosurgeon' },
    { label: 'Nutritionist', value: 'Nutritionist' },
    { label: 'OB-GYN', value: 'OB-GYN' },
    { label: 'Oncologist', value: 'Oncologist' },
    { label: 'Ophthalmologist', value: 'Ophthalmologist' },
    { label: 'Optometrist', value: 'Optometrist' },
    { label: 'Oral Surgeon', value: 'Oral Surgeon' },
    { label: 'Orthodontist', value: 'Orthodontist' },
    { label: 'Orthopedic Surgeon', value: 'Orthopedic Surgeon' },
    {
      label: 'Pain Management Specialist',
      value: 'Pain Management Specialist',
    },
    { label: 'Pathologist', value: 'Pathologist' },
    { label: 'Pediatric Dentist', value: 'Pediatric Dentist' },
    {
      label: 'Pediatric Urgent Care Specialist',
      value: 'Pediatric Urgent Care Specialist',
    },
    { label: 'Pediatrician', value: 'Pediatrician' },
    { label: 'Periodontist', value: 'Periodontist' },
    { label: 'Physiatrist', value: 'Physiatrist' },
    { label: 'Physical Therapist', value: 'Physical Therapist' },
    { label: 'Plastic Surgeon', value: 'Plastic Surgeon' },
    { label: 'Podiatrist', value: 'Podiatrist' },
    { label: 'Primary Care', value: 'Primary Care' },
    { label: 'Prosthodontist', value: 'Prosthodontist' },
    { label: 'Psychiatrist', value: 'Psychiatrist' },
    { label: 'Psychologist', value: 'Psychologist' },
    { label: 'Psychotherapist', value: 'Psychotherapist' },
    { label: 'Pulmonologist', value: 'Pulmonologist' },
    { label: 'Radiologist', value: 'Radiologist' },
    { label: 'Rheumatologist', value: 'Rheumatologist' },
    { label: 'Sleep Medicine Specialist', value: 'Sleep Medicine Specialist' },
    {
      label: 'Sports Medicine Specialist',
      value: 'Sports Medicine Specialist',
    },
    { label: 'Surgeon', value: 'Surgeon' },
    { label: 'Therapist / Counselor', value: 'Therapist / Counselor' },
    {
      label: 'Travel Medicine Specialist',
      value: 'Travel Medicine Specialist',
    },
    { label: 'Urgent Care Specialist', value: 'Urgent Care Specialist' },
    { label: 'Urologist', value: 'Urologist' },
    { label: 'Other', value: 'Other' },
    { label: 'N/A', value: 'N/A' },
  ],
  COHORT_OPTIONS: [
    { label: 'Autoimmune', value: 'Autoimmune' },
    { label: 'Long Covid', value: 'Long Covid' },
    { label: 'Rheumatoid Arthritis', value: 'Rheumatoid Arthritis' },
    { label: 'General Health', value: 'General Health' },
  ],
  APPLY_DURING_VISIT: [
    {
      q_index: 0,
      question: '<b>Lifeline or similar fall notification device.</b>',
    },
    {
      q_index: 1,
      question:
        '<b>Referral to PT, OT, or PCP.</b> Ensure patient can tolerate usual activities without symptoms or complications. Activity as directed by physician. Review signs and symptoms reflecting, intolerance of present activity level and when to report health concerns. Educate on energy conservation.',
    },
    {
      q_index: 2,
      question:
        '<b>Balance Disturbance.</b> Safe in environment as evidenced by no incident or injury. Heel toe walking, posture, upright, look ahead while ambulating.',
    },
    { q_index: 3, question: '<b>Evaluation for DME needs.</b>' },
    {
      q_index: 4,
      question:
        '<b>Assess needs of home health or other assistance from caregiver.</b>',
    },
    {
      q_index: 5,
      question:
        '<b>Provide information for caregiving or other assistance.</b>',
    },
    { q_index: 6, question: '<b>Assess cognitive function or support.</b>' },
    {
      q_index: 7,
      question:
        '<b>Purpose, Administration, and side effects of medications.</b> Educate the use of “over-the-counter” medication with caution, especially cold and cough medications. Educate on the importance of establishing a daily routine with medications prescribed to manage chronic condition.',
    },
    {
      q_index: 8,
      question:
        '<b>Medication Reconciliation on initial screening and at every practitioner visit.</b>',
    },
    {
      q_index: 9,
      question:
        '<b>Medicare management or resources discussed </b>(e.g.pill box, Alexa reminders).',
    },
    { q_index: 10, question: '<b>Shared tobacco cessation resources.</b>' },
    {
      q_index: 11,
      question:
        '<b>Annual wellness visit and prevention screening with practitioner on birthday month.</b>',
    },
    {
      q_index: 12,
      question:
        '<b>Ensure patient up to date with Vaccines, including influenza, shingles, etc.</b>',
    },
    {
      q_index: 13,
      question:
        '<b>Educate patient/caregiver to plan activities with rest periods.</b>',
    },
    {
      q_index: 14,
      question:
        '<b>Assist with coordination of home care or personal care services.</b>',
    },
    {
      q_index: 15,
      question:
        '<b>Blood sugars will be in acceptable range.</b> Education on signs of hypoglycemia, altered mental status, cold and clammy skin, rapid pulse, hunger, irritability, anxiety, headache, lightheadedness, shakiness. Education on signs of hyperglycemia, frequent urination,increased thirst, blurred vision, fatigue o weakness, headache, fruity smelling breath, nausea/vomiting, confusion. Education on importance of adhering to medication regimens. Education on the importance of monitoring blood sugars and maintain a log as prescribed. Educate on red flag signs and symptoms and when to report health concerns. Review lab results and relevance with patient/caregiver. Diabetic Annual Screenings.',
    },
    {
      q_index: 16,
      question:
        '<b>Educate on healthy nutrition</b>(for example, adequate and appropriate caloric intake, healthy diet, adequate fluid balance, and overall health and wellbeing. Consider referring to dietary/nutritional counseling.',
    },
    { q_index: 17, question: '<b>Educate on Routine skin inspections.</b>' },
    {
      q_index: 18,
      question:
        '<b>Education for at risk for complication related to chronic condition, cardiovascular disease, hypertension, diabetes, obesity, neuropathy, nephropathy, retinopathy, etc.</b> Educate for developing lifestyle changes to manage disease.',
    },
    {
      q_index: 19,
      question:
        '<b>Education on the benefits and role of exercise, nutrition, medications, and fall prevention.</b>',
    },
    {
      q_index: 20,
      question:
        '<b>Education on the importance of limiting or avoiding caffeine and alcohol,</b> regular exercise and increase physical activity, and lifestyle changes including heart healthy foods low in salt, saturated fats, rich in fruits, vegetables, and whole grains.',
    },
    { q_index: 21, question: '<b>Refer to health coach</b>' },
    {
      q_index: 22,
      question:
        '<b>Annual Eye Exam,</b> foot care, cholesterol levels, and blood pressure control. Provide referrals when necessary.',
    },
    { q_index: 23, question: '<b>Consider Lab orders.</b>' },
    {
      q_index: 24,
      question:
        '<b>Educate for risk of infection.</b> Identify and participate in interventions to prevent/reduce risk of infection.',
    },
    {
      q_index: 25,
      question: '<b>Educate Personal Hygiene and hand washing.</b>',
    },
    {
      q_index: 26,
      question: '<b>Encourage avoidance of ill/contagious contacts.</b>',
    },
    {
      q_index: 28,
      question:
        '<b>Knowledge Deficit Related to Chronic Condition.</b> Patient to verbalize understanding of therapeutic regimen, chronic condition, plan of care following new orders.',
    },
    {
      q_index: 29,
      question:
        '<b>Assist with coordination of care with specialists and other ancillary health care providers.</b>',
    },
  ],
  TIME_SPENT_OPTIONS: [
    { label: '0 minutes', value: '0' },
    { label: '10 minutes', value: '10' },
    { label: '20 minutes', value: '20' },
    { label: '30 minutes', value: '30' },
    { label: '40 minutes', value: '40' },
    { label: '50 minutes', value: '50' },
    { label: '60 minutes', value: '60' },
  ],
  RPM_DEVICES: [
    { value: 'SMiBP-12C', label: 'iBloodPressure 12 Months Commercial Membership', count: 1},
    { value: 'SMiBP-12H', label: 'iBloodPressure 12 Months Direct to Home Membership', count: 1},
    { value: 'SMiBP-03C', label: 'iBloodPressure 3 Months Commercial Membership', count: 1},
    { value: 'SMiBP-03H', label: 'iBloodPressure 3 Months Direct to Home Membership', count: 1},
    { value: 'SMiBPXL-12C', label: 'iBloodPressure XL 12 Months Commercial Membership', count: 1},
    { value: 'SMiBPXL-12H', label: 'iBloodPressure XL 12 Months Direct to Home Membership', count: 1},
    { value: 'AC2242-11', label: 'Open Stock iBlood Pressure Cuff - Large', count: 1},
    { value: 'AC2245-021', label: 'Open Stock iBlood Pressure Cuff - XL', count: 1},
    { value: 'SMiG-04C', label: 'iGlucose 4 Months Commercial Membership', count: 1},
    { value: 'SMiG-04CU', label: 'iGlucose 4 Months Commercial Membership - Unbundled Kit', count: 1},
    { value: 'SMiG-04H', label: 'iGlucose 4 Months Direct to Home Membership', count: 1},
    { value: 'SMiG-12C', label: 'iGlucose 12 Months Commercial Membership', count: 1},
    { value: 'SMiG-12CU', label: 'iGlucose 12 Months Commercial Membership - Unbundled Kit', count: 1},
    { value: 'SMiG-12H', label: 'iGlucose 12 Months Direct to Home Membership', count: 1},
    { value: 'MGLUKITUSLTE', label: 'iGlucose Blood Glucose Monitoring System', count: 1},
    { value: '705-2GC300-010', label: 'Control Solution - Normal Range', count: 1},
    { value: 'MGLULNCTUS', label: 'SteriLance 30 Gauge Lancets 100 Pack', count: 1},
    { value: 'MGLULANCEUS', label: 'SteriLance Single Patient Use Lancing Device, Single Count', count: 1},
    { value: 'SGLU50US', label: 'iGlucose Test Strips Single Pack 50 Count', count: 1},
    { value: 'SMiS-12C', label: 'iScale 12 Months Commercial Membership', count: 1},
    { value: 'SMiS-12H', label: 'iScale 12 Months Direct to Home Membership', count: 1},
    { value: 'SMiS-03C', label: 'iScale 3 Months Commercial Membership', count: 1},
    { value: 'SMiS-03H', label: 'iScale 3 Months Direct to Home Membership', count: 1},
    { value: 'SMiPX-12C', label: 'iPulseOx 12 Months Commercial Membership', count: 1},
    { value: 'SMiPX-12H', label: 'iPulseOx 12 Months Direct to Home Membership', count: 1},
  ],
  LANGUAGES: [
    { label: 'English', value: 'English' },
    { label: 'Spanish', value: 'Spanish' },
    { label: 'French', value: 'French' },
    { label: 'Portuguese', value: 'Portuguese' },
    { label: 'German', value: 'German' },
    { label: 'Arabic', value: 'Arabic' },
    { label: 'Italian', value: 'Italian' },
    { label: 'Mandarin', value: 'Mandarin' },
    { label: 'Cantonese', value: 'Cantonese' },
    { label: 'Japanese', value: 'Japanese' },
    { label: 'Russian', value: 'Russian' },
    { label: 'Korean', value: 'Korean' },
  ]
,
  INFUSION_DATABASE: [
    {
      facility_name: 'Advanced Infusion Center',
      type: 'Freestanding Infusion Center',
      email: 'info@jrsinfusion.com',
      phone: '(916) 678-5840',
      fax: '(916) 678-5839',
      address: '4354 Auburn Blvd #100',
      city: 'Sacramento',
      state: 'CA',
      zipcode: 95841,
      operation_hours:
        'Mon-Fri 7:00 AM - 7:00 PM\r\nSat-Sun 10:00 AM - 4:00 PM\r\n',
      medications_infused:
        'REMICADE® (infliximab)\r\nSIMPONI ARIA® (golimumab)\r\nOther',
      referral_link: 'https://jrsinfusion.com/referral/',
      accepted_insurance:
        'Aetna, Inc.\r\nAnthem, Inc.\r\nContact the Infusion center with your specific insurance information to verify coverage.',
    },
    {
      facility_name: 'AleraCare Medical Group of California',
      type: 'Freestanding Infusion Center',
      email: 'info@aleracaremedicalgroup.com',
      phone: '888-209-8874',
      fax: '833-329-4738',
      address: '2331 Montpelier, Suite 20',
      city: 'San Jose',
      state: 'CA',
      zipcode: 95116,
      operation_hours: 'Mon-Sun 9:00 AM - 5:30 PM',
      medications_infused:
        'REMICADE® (infliximab)\r\nSIMPONI ARIA® (golimumab)\r\nSTELARA® (ustekinumab)\r\nOther',
      referral_link: 'https://www.aleracare.com/productforms/',
    },
    {
      facility_name: 'AleraCare Medical Group of California',
      type: 'Freestanding Infusion Center',
      email: 'info@aleracaremedicalgroup.com',
      phone: '(888) 209-8874',
      fax: '(833) 329-4738',
      address: '3448 Mowry Ave',
      city: 'Fremont',
      state: 'CA',
      zipcode: 94538,
      operation_hours: 'Mon-Sun 9:00 AM - 5:30 PM',
      medications_infused:
        'REMICADE® (infliximab)\r\nSIMPONI ARIA® (golimumab)\r\nSTELARA® (ustekinumab)\r\nOther',
      referral_link: 'https://www.aleracare.com/productforms//',
    },
    {
      facility_name: 'AleraCare Medical Group of California',
      type: 'Freestanding Infusion Center',
      email: 'info@aleracaremedicalgroup.com',
      phone: '(888) 209-8874',
      fax: '(833) 329-4738',
      address: '80 Grand Ave ',
      city: 'Oakland',
      state: 'CA',
      zipcode: 94612,
      operation_hours: 'Mon-Sun 9:00 AM - 5:30 PM',
      medications_infused:
        'REMICADE® (infliximab)\r\nSIMPONI ARIA® (golimumab)\r\nSTELARA® (ustekinumab)\r\nOther',
      referral_link: 'https://www.aleracare.com/productforms//',
    },
    {
      facility_name: 'AleraCare Medical Group of California',
      type: 'Freestanding Infusion Center',
      email: 'info@aleracaremedicalgroup.com',
      phone: '(888) 209-8874',
      fax: '(833) 329-4738',
      address: '520 Lawrence Expressway',
      city: 'Sunnyvale',
      state: 'CA',
      zipcode: 94085,
      operation_hours: 'Mon-Sun 9:00 AM - 5:30 PM',
      medications_infused:
        'REMICADE® (infliximab)\r\nSIMPONI ARIA® (golimumab)\r\nSTELARA® (ustekinumab)\r\nOther',
      referral_link: 'https://www.aleracare.com/productforms//',
    },
    {
      facility_name: 'Beacon Infusion',
      type: 'Freestanding Infusion Center',
      email: 'info@beaconinfusion.com',
      phone: '(833) 223-2266',
      fax: '\r\n(732) 329-2322',
      address: '1510 S Central Ave',
      city: 'Glendale',
      state: 'CA',
      zipcode: 91204,
      operation_hours: 'Mon-Fri 8:30 AM - 5:00 PM\r\nSat-Sun CLOSED',
      medications_infused:
        'REMICADE® (infliximab)\r\nSIMPONI ARIA® (golimumab)\r\nSTELARA® (ustekinumab)\r\nOther',
      referral_link: 'https://www.beaconinfusion.com/for-prescribers/',
      accepted_insurance:
        'Aetna, Inc.\r\nCIGNA Health Plans, Inc.\r\nHorizon Blue Cross Blue Shield of New Jersey\r\nHumana, Inc.\r\nMedicare\r\nNoridian Healthcare Solutions, LLC MAC\r\nOther\r\nUnitedHealth Group, Inc.',
    },
    {
      facility_name: '\r\nBriovaRx Infusion Services',
      type: 'Home Infusion Service',
      email: '-',
      phone: ' \r\n(916) 648-0124',
      fax: '(916) 648-0128',
      address: '4610 Northgate Blvd. Suite 130',
      city: 'Sacramento',
      state: 'CA',
      zipcode: 95834,
      operation_hours: 'Mon-Fri 8:30 AM - 5:00 PM\r\nSat-Sun CLOSED',
      medications_infused:
        'REMICADE® (infliximab)\r\nSIMPONI ARIA® (golimumab)\r\nSTELARA® (ustekinumab)\r\nOther',
      referral_link: 'http://www.briovarxinfusion.com/',
    },
    {
      facility_name: 'BriovaRx Infusion Services',
      type: 'Home Infusion Service',
      email: '-',
      phone: '  \r\n(831) 384-8080',
      fax: '(831) 384-8065',
      address: '455 Reservation Rd. Suite G',
      city: 'Marina',
      state: 'CA',
      zipcode: 93933,
      operation_hours: 'Mon-Fri 8:30 AM - 5:00 PM\r\nSat-Sun CLOSED',
      medications_infused:
        'REMICADE® (infliximab)\r\nSIMPONI ARIA® (golimumab)\r\nSTELARA® (ustekinumab)\r\nOther',
      referral_link: 'http://www.briovarxinfusion.com/',
    },
    {
      facility_name: 'BriovaRx Infusion Services',
      type: 'Services your area',
      email: '-',
      phone: '(562) 263-5600',
      fax: '\r\n(562) 263-5601',
      address: '12604 Hiddencreek Way, Ste C',
      city: 'Cerritos ',
      state: 'CA',
      zipcode: 90703,
      operation_hours: 'Mon-Fri 8:30 AM - 5:00 PM\r\nSat-Sun CLOSED',
      medications_infused:
        'REMICADE® (infliximab)\r\nSIMPONI ARIA® (golimumab)\r\nSTELARA® (ustekinumab)\r\nOther',
      referral_link: 'http://www.briovarxinfusion.com/',
    },
    {
      facility_name: 'Contra Costa Oncology',
      type: 'Freestanding Infusion Center',
      email: '-',
      phone: '(925) 939-9610',
      fax: '(925) 939-9630',
      address: '500 Lennon Ln.',
      city: 'Walnut Creek ',
      state: 'CA ',
      zipcode: 94598,
      operation_hours: 'Mon-Fri 9:00 AM - 5:00 PM',
      medications_infused: 'REMICADE® (infliximab)\r\nOther',
      referral_link: 'https://contracostaoncology.com/',
      accepted_insurance:
        'Aetna, Inc.\r\nUnitedHealth Group, Inc.\r\nAnthem, Inc.\r\nContact the Infusion center with your specific insurance information to verify coverage.',
    },
    {
      facility_name: 'Coram Specialty Infusion Services, Inc.',
      type: 'Home Infusion Service',
      email: '-',
      phone: '(800) 876-9101',
      fax: '(415) 292-6911',
      address: '1635 Divisadero, Suite 135',
      city: 'San Francisco',
      state: 'CA',
      zipcode: 94115,
      operation_hours: 'Mon-Fri 8:00 AM - 5:30 PM\r\nSat-Sun CLOSED',
      medications_infused:
        'REMICADE® (infliximab)\r\nIf you are not taking one of the medications listed, contact the facility to find out if your medication is infused.',
      referral_link: 'https://www.coramhc.com/providers/referrals',
    },
    {
      facility_name: 'Coram Specialty Infusion Services, Inc.',
      type: 'Home Infusion Service',
      email: '-',
      phone: '(800) 876-9101',
      fax: '(510) 732-8801',
      address: '3160 Corporate Place',
      city: 'Hayward',
      state: 'CA',
      zipcode: 94545,
      operation_hours: 'Mon-Fri 8:00 AM - 5:30 PM\r\nSat-Sun CLOSED',
      medications_infused:
        'REMICADE® (infliximab)\r\nIf you are not taking one of the medications listed, contact the facility to find out if your medication is infused.',
      referral_link: 'https://www.coramhc.com/providers/referrals',
    },
    {
      facility_name: 'Core Health Infusion Center',
      type: 'Doctor’s Office',
      email: '-',
      phone: '(650) 969-7006',
      fax: '(650) 969-7007',
      address: '2490 Hospital Dr., STE 102',
      city: 'Mountain View',
      state: 'CA',
      zipcode: 94040,
      operation_hours: 'Mon-Fri 9:30 AM - 4:30 PM\r\nSat-Sun CLOSED',
      medications_infused:
        'REMICADE® (infliximab)\r\nSIMPONI ARIA® (golimumab)\r\nSTELARA® (ustekinumab)\r\nOther',
      referral_link: 'https://www.corehealthinfusion.com\r\n',
    },
    {
      facility_name: 'Crescent Healthcare (Option Care)',
      type: 'Home Infusion Service',
      email: '-',
      phone: '(800) 824-8400',
      fax: '(800) 586-5300',
      address: '175 North Jackson Ave, Suite 100',
      city: 'San Jose',
      state: 'CA',
      zipcode: 95116,
      operation_hours: 'Mon-Fri 9:00 AM - 5:30 PM\r\nSat-Sun CLOSED',
      referral_link:
        'https://optioncarehealth.com/healthcare-providers/refer-a-patient',
      accepted_insurance:
        'Aetna, Inc.\r\nAnthem, Inc.\r\nUnitedHealth Group, Inc.\r\nContact the Infusion center with your specific insurance information to verify coverage.',
    },
    {
      facility_name: 'Crescent Healthcare Inc',
      type: 'Home Infusion Service',
      email: '-',
      phone: '(800) 736-4872',
      fax: '(619) 287-4536',
      address: '10070 Carroll Canyon Rd Ste 100',
      city: 'San Diego',
      state: 'CA',
      zipcode: 92131,
      operation_hours: 'Mon-Fri 9:00 AM - 5:00 PM\r\nSat-Sun CLOSED',
      medications_infused:
        'REMICADE® (infliximab)\r\nSIMPONI ARIA® (golimumab)\r\nOther',
      referral_link: 'http://www.healthcare.walgreens.com/',
      accepted_insurance:
        'Aetna, Inc.\r\nAnthem, Inc.\r\nUnitedHealth Group, Inc.\r\nContact the Infusion center with your specific insurance information to verify coverage.',
    },
    {
      facility_name: 'Crescent Healthcare Inc.',
      type: 'Home Infusion Service',
      email: '-',
      phone: '(800) 736-4872',
      fax: '(619) 287-4536',
      address: '10070 Carroll Canyon Rd., Ste 100',
      city: 'San Diego',
      state: 'CA',
      zipcode: 92131,
      operation_hours: 'Mon-Fri 9:00 AM - 5:00 PM\r\nSat-Sun CLOSED',
      medications_infused:
        'REMICADE® (infliximab)\r\nSIMPONI ARIA® (golimumab)\r\nOther',
      referral_link: 'http://www.healthcare.walgreens.com//',
      accepted_insurance:
        'Aetna, Inc.\r\nAnthem, Inc.\r\nUnitedHealth Group, Inc.',
    },
    {
      facility_name: 'Epic Care',
      type: 'Doctor’s Office',
      email: '-',
      phone: '(510) 247-9227',
      fax: '(510) 247-9241',
      address: '20400 Lake Chabot Road, Suite 102',
      city: 'Castro Valley',
      state: 'CA',
      zipcode: 94545,
      operation_hours: 'Mon-Fri 8:00 AM - 5:00 PM\r\nSat-Sun CLOSED',
      medications_infused: 'REMICADE® (infliximab)\r\nOther',
      referral_link: 'https://epic-care.com/contact/',
    },
    {
      facility_name: 'Epic Care',
      type: 'Doctor’s Office',
      email: '-',
      phone: '(925) 778-0679',
      fax: '(925) 778-3567',
      address: '4721 Dallas Ranch Rd.',
      city: 'Antioch',
      state: 'CA',
      zipcode: 94531,
      operation_hours: 'Mon-Fri 8:00 AM - 5:00 PM\r\nSat-Sun CLOSED',
      medications_infused: 'REMICADE® (infliximab)\r\nOther',
      referral_link: 'https://epic-care.com/contact//',
    },
    {
      facility_name: 'Infusion for Health',
      type: 'Freestanding Infusion Center',
      email: '-',
      phone: '(916) 314-7060',
      fax: '(805) 852-2636',
      address: '333 University Avenue Suite #140',
      city: 'Sacramento',
      state: 'CA',
      zipcode: 95825,
      operation_hours: 'Mon-Fri 9:00 AM - 5:30 PM\r\nSat-Sun CLOSED',
      medications_infused:
        'REMICADE® (infliximab)\r\nSIMPONI ARIA® (golimumab)\r\nSTELARA® (ustekinumab)\r\nOther',
      referral_link: 'https://infusionforhealth.com/make-a-referral/',
      accepted_insurance:
        'Aetna, Inc.\r\nBlue Shield of California\r\nDepartment of Defense - TRICARE\r\nMedi-Cal\r\nMedicaid\r\nMedicare\r\nOther\r\nTRICARE\r\nU.S. Department of Veterans Affairs\r\nUnitedHealth Group, Inc.',
    },
    {
      facility_name: 'IVX Health Infusion Center',
      type: 'Freestanding Infusion Center',
      email: '-',
      phone: '(650) 288-0826',
      fax: '(844) 889-0275',
      address: '66 Bovet Road Suite 105',
      city: 'San Mateo',
      state: 'CA',
      zipcode: 94402,
      operation_hours: 'Mon-Sat 7:00 AM - 7:00 PM\r\nSunday CLOSED',
      medications_infused:
        'REMICADE® (infliximab)\r\nSIMPONI ARIA® (golimumab)\r\nSTELARA® (ustekinumab)\r\nOther',
      referral_link: 'https://ivxhealth.com/referrals/',
      accepted_insurance:
        'Aetna, Inc.\r\nAnthem, Inc.\r\nBlue Shield of California\r\nCIGNA Health Plans, Inc.\r\nHealth Plan of San Mateo, Inc.\r\nHumana, Inc.\r\nMedi-Cal\r\nUnitedHealth Group, Inc.\r\nContact the Infusion center with your specific insurance information to verify coverage.',
    },
    {
      facility_name: 'KabaFusion',
      type: 'Home Infusion Service',
      email: '-',
      phone: '(877) 577-4844',
      fax: '877-445-8821',
      address: '17777 Center Ct Dr',
      city: 'Cerritos ',
      state: 'CA',
      zipcode: 90703,
      operation_hours: 'Mon-Fri 8:00 AM - 6:00 PM\r\nSat-Sun CLOSED',
      medications_infused:
        'REMICADE® (infliximab)\r\nSIMPONI ARIA® (golimumab)\r\nSTELARA® (ustekinumab)\r\nOther',
      referral_link: 'https://www.kabafusion.com/referral-forms/',
    },
    {
      facility_name: 'MedRx Infusion',
      type: 'Home Infusion Service',
      email: '-',
      phone: '(310) 671-2600',
      fax: '(310) 671-2601',
      address: '417 N Oak St',
      city: 'Inglewood ',
      state: 'CA',
      zipcode: 90302,
      operation_hours: 'Mon-Sat 9:00 AM - 10:00 PM\r\nSunday-  CLOSED',
      medications_infused:
        'REMICADE® (infliximab)\r\nSIMPONI ARIA® (golimumab)\r\nSTELARA® (ustekinumab)\r\nOther',
      referral_link: 'https://medrxinfusion.com/refer-a-patient/',
    },
    {
      facility_name: 'Option Care Health',
      type: 'Home Infusion Service',
      email: '-',
      phone: '(866) 628-1820',
      fax: '(661) 615-6080',
      address: '5800 District BlvdSuite 200',
      city: 'Bakersfield',
      state: 'CA',
      zipcode: 93313,
      operation_hours: 'Mon-Fri 8:00 AM - 5:00 PM\r\nSat-Sun CLOSED',
      medications_infused:
        'REMICADE® (infliximab)\r\nSIMPONI ARIA® (golimumab)\r\nSTELARA® (ustekinumab)\r\nOther',
      referral_link:
        'https://optioncarehealth.com/healthcare-providers/refer-a-patientt',
    },
    {
      facility_name: 'Option Care Health',
      type: 'Home Infusion Service',
      email: '-',
      phone: '(800) 735-4872',
      fax: '(951) 774-1849',
      address: '2010 Iowa Ave.Suite 110',
      city: 'Riverside',
      state: 'CA',
      zipcode: 92507,
      operation_hours: 'Mon-Fri 8:00 AM - 5:00 PM\r\nSat-Sun CLOSED',
      medications_infused:
        'REMICADE® (infliximab)\r\nSIMPONI ARIA® (golimumab)\r\nSTELARA® (ustekinumab)\r\nOther',
      referral_link:
        'https://optioncarehealth.com/healthcare-providers/refer-a-patientt',
      accepted_insurance:
        'INSURANCE ACCEPTED:\r\nAetna, Inc.\r\nBlue Shield of California\r\nCalifornia Medicaid\r\nCIGNA Health Plans, Inc.\r\nHumana, Inc.\r\nState of California\r\nUnitedHealth Group, Inc.',
    },
    {
      facility_name: 'Option Care Health',
      type: 'Home Infusion Service',
      email: '-',
      phone: '(800) 417-1337',
      fax: '(530) 893-1214',
      address: '132 Mission Ranch Blvd',
      city: 'Chico',
      state: 'CA',
      zipcode: 95926,
      operation_hours: 'Mon-Fri 8:00 AM - 5:00 PM\r\nSat-Sun CLOSED',
      medications_infused:
        'REMICADE® (infliximab)\r\nSIMPONI ARIA® (golimumab)',
      referral_link:
        'https://optioncarehealth.com/healthcare-providers/referral-benefits',
    },
    {
      facility_name: 'Optum Infusion Services',
      type: 'Home Infusion Service',
      email: '-',
      phone: '(949) 221-9900',
      fax: '(866) 476-2489',
      address: '2300 Main St., Ste. 150',
      city: 'Irvine',
      state: 'CA',
      zipcode: 92614,
      operation_hours: 'Mon-Fri 8:30 AM - 5:00 PM\r\nSat-Sun CLOSED',
      medications_infused:
        'REMICADE® (infliximab)\r\nSIMPONI ARIA® (golimumab)\r\nSTELARA® (ustekinumab)\r\nOther',
      referral_link: 'https://specialty.optumrx.com/infusion#referral-forms',
    },
    {
      facility_name: 'Optum Infusion Services',
      type: 'Home Infusion Service',
      email: '-',
      phone: '(866) 827-0972',
      fax: '\r\n(855) 602-4679',
      address: '1819 Aston Ave. STE 102',
      city: 'Carlsbad',
      state: 'CA',
      zipcode: 92008,
      operation_hours: 'Mon-Fri 8:00 AM - 5:00 PM\r\nSat-Sun CLOSED',
      medications_infused:
        'REMICADE® (infliximab)\r\nSIMPONI ARIA® (golimumab)\r\nSTELARA® (ustekinumab)\r\nOther',
      referral_link: 'https://specialty.optumrx.com/infusion#referral-forms',
    },
    {
      facility_name: 'Pacific Infusion Center',
      type: 'Doctor’s Office',
      email: '-',
      phone: '(310) 297-9269',
      fax: '(310) 297-9222',
      address: '5230 Pacific Concourse Dr Ste 100',
      city: 'Los Angeles',
      state: 'CA',
      zipcode: 90045,
      operation_hours: 'Tues-Thurs 7:30 AM - 6:00 PM\r\nMon, Fri-Sun - CLOSED',
      medications_infused:
        'REMICADE® (infliximab)\r\nSIMPONI ARIA® (golimumab)\r\nSTELARA® (ustekinumab)\r\nOther',
      referral_link: 'https://pacificinfusion.com/physician-offices/',
    },
    {
      facility_name: 'Soleo Health',
      type: 'Home Infusion Service',
      email: '-',
      phone: '(510) 362-7360',
      fax: '(510) 460-1389',
      address: '1324 W. Winton Avenue',
      city: 'Hayward',
      state: 'CA',
      zipcode: 94545,
      operation_hours: 'Mon-Fri 8:30 AM - 5:00 PM\r\nSat-Sun CLOSED',
      medications_infused:
        'REMICADE® (infliximab)\r\nSIMPONI ARIA® (golimumab)\r\nSTELARA® (ustekinumab)\r\nOther',
      referral_link:
        'https://www.soleohealth.com/healthcare-professionals/referral-forms/index',
    },
    {
      facility_name: 'Total Infusion, Inc.',
      type: 'Freestanding Infusion Center',
      email: ' info@totalinfusion.com',
      phone: '(510) 878-9528',
      fax: '(510) 969-5840',
      address: '6955 Foothill Blvd',
      city: 'Oakland',
      state: 'CA',
      zipcode: 94605,
      operation_hours: 'Mon-Fri 8:30 AM - 5:00 PM\r\nSat-Sun CLOSED',
      medications_infused:
        'REMICADE® (infliximab)\r\nSIMPONI ARIA® (golimumab)\r\nSTELARA® (ustekinumab)\r\nOther',
      referral_link: 'https://totalinfusion.com/make-a-referral/',
      accepted_insurance:
        'Medicare\r\nContact the Infusion center with your specific insurance information to verify coverage.',
    },
  ],
  IMAGING_DATABASE: [
    {
      facility_name: '3T Radiology and Research',
      type: 'Insite',
      phone: '(786) 665-8008',
      fax: '(305) 532-7648',
      address: '20803 Biscayne Blvd STE 103',
      city: 'Aventura',
      state: 'FL',
      zipcode: 33180,
      referral_link:
        'https://www.3tradiology.com/physicians/request-appointment/',
    },
    {
      facility_name: '3T Radiology and Research',
      type: 'Insite',
      phone: '954-686-2559',
      fax: '(305) 532-7648',
      address: '4515 Wiles Rd Suite 101',
      city: 'Coconut Creek',
      state: 'FL',
      zipcode: 33073,
      referral_link: 'https://www.3tradiology.com/request-appointment/',
    },
    {
      facility_name: '3T Radiology and Research',
      type: 'Insite',
      phone: '305-532-7460',
      fax: '(305) 532-7648',
      address: '310 W 41st St.',
      city: 'Miami Beach',
      state: 'FL',
      zipcode: 33140,
      referral_link: 'https://www.3tradiology.com/request-appointment/',
    },
    {
      facility_name: "ProScan 3T MRI and ProScan Women's Imaging",
      type: 'Outpatient',
      phone: '(239) 598-0035',
      fax: '(239) 598-0038',
      address: '1020 Crosspointe Drive Suite 103',
      city: 'Naples',
      state: 'FL',
      zipcode: 34110,
      referral_link: 'https://proscan.com/schedule/',
    },
    {
      facility_name: 'Total MRI & Diagnostic Center ',
      type: 'Outpatient',
      phone: '(786) 756-3038 ',
      fax: '(786) 756-3039',
      address: '4440 Sheridan St # B',
      city: 'Hollywood',
      state: 'FL',
      zipcode: 33021,
      referral_link: 'https://totalmridiagnostic.com/all-procedures/',
    },
    {
      facility_name: 'Renaissance Diagnostic Imaging Center',
      type: 'Outpatient',
      email: 'info@rimarad.com',
      phone: '(818) 435-1414',
      fax: '(818) 435-1490',
      address: '18436 Roscoe Blvd #4107',
      city: 'Northridge',
      state: 'CA',
      zipcode: 91325,
      referral_link: 'https://www.rimarad.com/',
    },
    {
      facility_name: 'Renaissance Diagnostic Imaging Center',
      type: 'Outpatient',
      email: 'info@rimarad.com',
      phone: '(661) 726-6700',
      fax: '(661) 949-5759',
      address: '44105 15th Street West, Ste. 100',
      city: 'Lancaster',
      state: 'CA',
      zipcode: 93534,
      referral_link: 'https://www.rimarad.com/',
    },
    {
      facility_name: 'Renaissance Diagnostic Imaging Center',
      type: 'Outpatient',
      email: 'info@rimarad.com',
      phone: '(323) 375-3950',
      fax: '(323) 375-3945',
      address: '500 South Virgil Street Ste 102',
      city: 'Los Angeles',
      state: 'CA',
      zipcode: 90020,
      referral_link: 'https://www.rimarad.com/',
    },
    {
      facility_name: 'Diagnostic Radiological Imaging',
      type: 'Outpatient',
      phone: '(916) 921-1300',
      fax: '(916) 921-1090',
      address: '79 Scripps Dr., #100',
      city: 'Sacramento',
      state: 'CA',
      zipcode: 95825,
      referral_link: 'https://www.driradiology.com/',
    },
    {
      facility_name: 'Dignity Health Mercy Medical Group',
      type: 'Outpatient',
      phone: '(855) 621-1642',
      fax: '916.408.8000',
      address: '3301 C St., Ste 500',
      city: 'Sacramento',
      state: 'CA',
      zipcode: 95816,
      referral_link:
        'https://www.dignityhealth.org/sacramento/medical-group/mercy-medical-group/services/mercy-imaging-centers',
    },
    {
      facility_name: 'Dignity Health Advanced Imaging, Roseville',
      type: 'Outpatient',
      phone: '(844) 316-8366',
      fax: '916.408.8000',
      address: '2110 Professional Drive, Ste. 190',
      city: 'Roseville',
      state: 'CA',
      zipcode: 95661,
      referral_link:
        'https://locations.dignityhealth.org/dignity-health-advanced-imaging-roseville',
    },
    {
      facility_name: 'Northern California PET Imaging Center',
      type: 'Outpatient',
      email: 'info@norcalscans.org',
      phone: '916-737-3211',
      fax: '916-737-6203',
      address: '3195 Folsom Boulevard',
      city: 'Sacramento',
      state: 'CA',
      zipcode: 95816,
      referral_link:
        'https://www.norcalscans.org/for-physicians/forms-and-brochures/',
    },
    {
      facility_name: 'Alvarado Hospital Medical Center',
      type: 'Hospital',
      email: 'info@imaginghealthcare.com',
      phone: '(858) 658-6500',
      fax: '(866) 558-4329',
      address: '6655 Alvarado Road',
      city: 'San Diego',
      state: 'CA',
      zipcode: 92120,
      referral_link:
        'https://alvaradohospital.com/our-services/imaging-services-radiology/',
    },
    {
      facility_name: 'San Diego Imaging',
      type: 'Outpatient',
      phone: '858.634.5900',
      fax: '858.634.5990',
      address: '7910 Frost St.',
      city: 'San Diego',
      state: 'CA',
      zipcode: 92123,
      referral_link: 'https://www.sdimaging.com/referring-physicians-2/',
    },
    {
      facility_name: 'San Diego Imaging Chula Vista',
      type: 'Outpatient',
      phone: '619.397.6577',
      fax: '619.502.8585',
      address: '765 Medical Center Court, Suite 100',
      city: 'Chula Vista',
      state: 'CA',
      zipcode: 91911,
      referral_link: 'https://www.sdimaging.com/san-diego-imaging-chula-vista/',
    },
    {
      facility_name: 'California Pacific Medical Center',
      type: 'Hospital',
      phone: '(415) 600-3232',
      fax: '(415) 447-6335',
      address: '1101 Van Ness Avenue, Floor 3',
      city: 'San Francisco',
      state: 'CA',
      zipcode: 94109,
      referral_link:
        'https://www.sutterhealth.org/find-location/facility/cpmc-imaging-van-ness-campus-1101-van-ness-avenue',
    },
    {
      facility_name: 'CPMC Imaging, Pacific Campus',
      type: 'Hospital',
      phone: '(415) 600-3180',
      fax: '(415) 600-3185 ',
      address: '2100 Webster Street, Suite 103',
      city: 'San Francisco',
      state: 'CA',
      zipcode: 94115,
      referral_link:
        'https://www.sutterhealth.org/find-location/facility/cpmc-imaging-van-ness-campus-1101-van-ness-avenuee',
    },
    {
      facility_name: 'RadNet Medical Imaging',
      type: 'Outpatient',
      phone: '415-922-6767',
      fax: '415-563-0468',
      address: '3440 California St.',
      city: 'San Francisco',
      state: 'CA',
      zipcode: 94118,
      referral_link:
        'https://www.radnet.com/northern-california/locations/radnet-medical-imaging-san-francisco',
    },
    {
      facility_name: 'RadNet Northern California | NorCal Imaging Fremont',
      type: 'Outpatient',
      phone: '510-713-1234',
      fax: '510-713-1236',
      address: '2201 Walnut Ave., Ste. 150',
      city: 'Fremont',
      state: 'CA',
      zipcode: 94538,
      referral_link:
        'https://www.radnet.com/northern-california/locations/fremont',
    },
    {
      facility_name: 'Saint Francis Memorial Hospital',
      type: 'Hospital',
      phone: '(415) 353-6390',
      fax: '(415) 353-6462',
      address: '900 Hyde St',
      city: 'San Francisco',
      state: 'CA',
      zipcode: 94109,
      referral_link:
        'https://www.dignityhealth.org/bayarea/locations/saintfrancis/services/imaging-radiology',
    },
    {
      facility_name: 'SimonMed Imaging',
      type: 'Outpatient',
      phone: '(760) 334-4065',
      fax: '(760) 334-4067',
      address: '499 N. El Camino Real Suite C100',
      city: 'Encinitas',
      state: 'CA',
      zipcode: 92024,
      referral_link: 'https://www.simonmed.com/online-scheduling/',
    },
    {
      facility_name: 'SimonMed Imaging',
      type: 'Outpatient',
      phone: '(415) 248-3700',
      fax: '(650) 257-6233',
      address: '1180 Post Street',
      city: 'San Francisco',
      state: 'CA',
      zipcode: 94109,
      referral_link: 'https://www.simonmed.com/online-scheduling/',
    },
    {
      facility_name: 'Encino Open MRI',
      type: 'Outpatient',
      phone: '(818) 986-4786',
      fax: '(818) 986-4798',
      address: '17323 Ventura Blvd Ste 101',
      city: 'Encino',
      state: 'CA',
      zipcode: 91316,
      referral_link: 'https://www.encinoopenmri.com/request-an-appointment/',
    },
    {
      facility_name: 'SoCal Imaging & Open MRI',
      type: 'Outpatient',
      email: 'support@socalimg.com',
      phone: '(818) 790-9300',
      fax: '818-790-4564',
      address: '1809 Verdugo Blvd Ste 100',
      city: 'Glendale',
      state: 'CA',
      zipcode: 91208,
      referral_link: 'https://socalimagingopenmri.com/request-an-appointment/',
    },
    {
      facility_name: 'Envision Radiology',
      type: 'Outpatient',
      phone: '719-955-4332',
      fax: '719-955-4338',
      address: '8610 Explorer Drive, Suite 300',
      city: 'Colorado Springs',
      state: 'CO',
      zipcode: 80920,
      referral_link: 'https://www.envrad.com/request-an-x-ray/',
    },
    {
      facility_name: 'SimonMed Imaging',
      type: 'Outpatient',
      phone: '(303) 643-0200',
      fax: '(303) 708-2284',
      address: '11960 Lioness Way Suite 140',
      city: 'Parker',
      state: 'CO',
      zipcode: 80134,
      referral_link: 'https://www.simonmed.com/online-scheduling/',
    },
    {
      facility_name: 'SimonMed Imaging',
      type: 'Outpatient',
      phone: '(303) 633-1280',
      fax: '(303) 708-2283',
      address: '939 N. Broadway',
      city: 'Denver',
      state: 'CO',
      zipcode: 80203,
      referral_link: 'https://www.simonmed.com/online-scheduling/',
    },
    {
      facility_name: 'Health Images',
      type: 'Outpatient',
      phone: '(303) 962-3900',
      fax: '(303) 962-3901',
      address: '8560 Northfield Blvd Suite #1925',
      city: 'Denver',
      state: 'CO',
      zipcode: 80238,
      referral_link: 'https://www.healthimages.com/go-online/',
    },
    {
      facility_name: 'Health Images',
      type: 'Outpatient',
      phone: '(303) 355-4674',
      fax: '(303) 355-7865',
      address: '210 University Blvd., Suite 102',
      city: 'Denver',
      state: 'CO',
      zipcode: 80206,
      referral_link: 'https://www.healthimages.com/go-online/',
    },
    {
      facility_name: 'Health Images',
      type: 'Outpatient',
      phone: '(303) 416-1040',
      fax: '(303) 278-0999',
      address: '1819 Denver West Dr Building 26, Suite #100',
      city: 'Lakewood',
      state: 'CO',
      zipcode: 80401,
      referral_link: 'https://www.healthimages.com/go-online/',
    },
    {
      facility_name: 'SimonMed Imaging',
      type: 'Outpatient',
      phone: '(346) 335-8519',
      fax: '(346) 291-1160',
      address: '2256 W. Holcombe Blvd',
      city: 'Houston',
      state: 'TX',
      zipcode: 77030,
      referral_link: 'https://www.simonmed.com/online-scheduling/',
    },
    {
      facility_name: 'SimonMed Imaging',
      type: 'Outpatient',
      phone: '346-900-8010',
      fax: '346-900-8011',
      address: '16550 Southwest Fwy #C',
      city: 'Sugar Land',
      state: 'TX',
      zipcode: 77479,
      referral_link: 'https://www.simonmed.com/online-scheduling/',
    },
    {
      facility_name: 'Envision Imaging of Dallas',
      type: 'Outpatient',
      phone: '(214) 276-0050',
      fax: '(214) 276-0057',
      address: '12201 Merit Dr., Suite 100',
      city: 'Dallas',
      state: 'TX',
      zipcode: 75251,
      referral_link:
        'https://www.envrad.com/location/envision-imaging-of-dallas/?utm_source=google&utm_medium=organic&utm_campaign=gmb&utm_content=Dallas',
    },
    {
      facility_name: 'Envision Imaging',
      type: 'Outpatient',
      phone: '(817) 225-3600',
      fax: '(817) 225-3601',
      address: '5701 Bryant Irvin Road, Suite 101',
      city: 'Fort Worth',
      state: 'TX',
      zipcode: 76132,
      referral_link:
        'https://www.envrad.com/locations/envision-imaging/envision-imaging-of-dallas-fort-worth-texas/',
    },
    {
      facility_name: 'Envision Imaging',
      type: 'Outpatient',
      phone: '(817) 207-9600',
      fax: '(817) 207-9692',
      address: '2911 Oak Park Circle',
      city: 'Fort Worth',
      state: 'TX',
      zipcode: 76109,
      referral_link:
        'https://www.envrad.com/location/envision-imaging-of-hulen/',
    },
    {
      facility_name: 'Houston Medical Imaging',
      type: 'Outpatient',
      phone: '713-797-1919',
      fax: '713-383-9933',
      address: '3310 Richmond Ave.',
      city: 'Houston',
      state: 'TX',
      zipcode: 77098,
      referral_link:
        'https://hmixray.com/wp-content/uploads/2021/06/referral-form-2016.pdf',
    },
    {
      facility_name: 'Houston Medical Imaging',
      type: 'Outpatient',
      phone: '713-589-5231',
      fax: '713-383-9933',
      address: '3322 E Walnut St #105',
      city: 'Pearland',
      state: 'TX',
      zipcode: 77581,
      referral_link:
        'https://hmixray.com/wp-content/uploads/2021/06/referral-form-2016.pdf',
    },
    {
      facility_name: 'Green Imaging',
      type: 'Outpatient',
      email: 'info@greenimaging.net',
      phone: '713-775-7252',
      fax: '866-653-0882',
      address: '2020 Albans Rd',
      city: 'Houston',
      state: 'TX',
      zipcode: 77005,
      referral_link: 'https://greenimaging.net/appointments/',
    },
    {
      facility_name: 'Central Park Imaging',
      type: 'Outpatient',
      phone: '(512) 501-3840',
      fax: '(512) 501-3841',
      address: '900 West 38th Street, Suite 100',
      city: 'Austin',
      state: 'TX',
      zipcode: 78705,
      referral_link: 'https://centralparkaustin.com/how-can-we-help/',
    },
    {
      facility_name: 'Crown Imaging',
      type: 'Outpatient',
      email: 'info@crownimaging.com',
      phone: '972-759-5140',
      fax: '972.759.5150',
      address: '7515 Greenville Ave #200',
      city: 'Dallas',
      state: 'TX',
      zipcode: 75231,
      referral_link:
        'https://crownimaging.com/patients/downloads/physicians-forms-downloads/',
    },
    {
      facility_name: 'DFW MRI ',
      type: 'Outpatient',
      phone: '(214) 320-1400',
      fax: '(214) 320-1402',
      address: '10611 Garland Road, Suite 101',
      city: 'Dallas',
      state: 'TX',
      zipcode: 75218,
      referral_link:
        'https://www.dfwopenmri.com/application/files/7315/5198/4128/REFERRAL-ORDER-FORM.pdf',
    },
    {
      facility_name: 'DFW MRI ',
      type: 'Outpatient',
      phone: '(972) 312-9994',
      fax: '-',
      address: '3801 W. George Bush Hwy, Suite 118',
      city: 'Plano',
      state: 'TX',
      zipcode: 75075,
      referral_link:
        'https://www.dfwopenmri.com/application/files/7315/5198/4128/REFERRAL-ORDER-FORM.pdf',
    },
    {
      facility_name: 'Up & Open Imaging',
      type: 'Outpatient',
      phone: '(972) 479-9500',
      fax: '972-479-9544',
      address: '1360 Wcampbell Rd Ste 122',
      city: 'Richardson',
      state: 'TX',
      zipcode: 75080,
      referral_link: 'http://ww1.upandopenimaging.com/',
    },
    {
      facility_name: 'Bexar Imaging',
      type: 'Outpatient',
      phone: '210-384-8439',
      fax: '210-348-1913',
      address: '25 NE Loop 410, Suite 121',
      city: 'San Antonio',
      state: 'TX',
      zipcode: 78216,
      referral_link: 'https://www.bexarimaging.com/contact-us/',
    },
    {
      facility_name: 'Bexar Imaging',
      type: 'Outpatient',
      phone: '210-806-9255',
      fax: '210-806-9256.',
      address: '7355 Barlite Blvd., Suite 201',
      city: 'San Antonio',
      state: 'TX',
      zipcode: 78224,
      referral_link: 'https://www.bexarimaging.com/contact-us/',
    },
    {
      facility_name: 'Life Image ',
      state: 'TX',
    },
    {
      facility_name: 'Intelerad',
    },
    {
      facility_name: 'BioReference Laboratories',
    },
    {
      facility_name: 'TaylorMed MRI',
      phone: '385-831-7674',
      fax: '(385) 327-7013',
      address: '965 Woodoak Lane',
      city: 'Murray',
      state: 'UT',
      zipcode: 84117,
      referral_link: 'https://intakeq.com/booking/7a0hc6?locationId=1',
    },
  ],
  DME_DATABASE: [
    {
      facility_name: 'Montgomery DME',
      phone: '562-777-7088',
      address: '14109 Pontlavoy Ave  ',
      city: 'Santa Fe Springs',
      state: 'CA',
      zipcode: 90670,
      referral_link: 'https://www.montgomerydme.com/',
    },
    {
      facility_name: 'Nationwide Medical, Inc.',
      email: 'patientservices@nationwidemedical.com',
      phone: '818-338-3500',
      fax: '818-338-3501',
      address: '5230 Las Virgenes Rd Ste 105',
      city: 'Calabasas',
      state: 'CA',
      zipcode: 91302,
      referral_link: 'http://www.nationwidemedical.com/',
    },
    {
      facility_name: 'South Bay Home Health Care - Retail Store',
      email: 'info@myhealthcenter.com',
      phone: '(310) 618-9555',
      fax: '(310) 618-0614',
      address: '1349 El Prado Ave',
      city: 'Torrance',
      state: 'CA',
      zipcode: 90501,
      referral_link: 'https://mobilitycare.com/',
    },
    {
      facility_name: 'Injury Medical Equipment- Majestic DME, LLC',
      phone: '424-250-9478',
      fax: '424-250-9439',
      address: '25616 Narbonne Ave Suite 202',
      city: 'Lomita',
      state: 'CA',
      zipcode: 90717,
      referral_link: 'http://injurymedicalequipment.com/',
    },
    {
      facility_name: 'Advance Medical Supply',
      email: 'info@advamedsupply.com',
      phone: '619-466-9255',
      fax: '619-466-9254',
      address: '7416 University Ave',
      city: 'La Mesa',
      state: 'CA',
      zipcode: 91942,
      referral_link: 'http://www.advamedsupply.com/',
    },
    {
      facility_name: 'Prime Medical Supply Inc',
      phone: '951-278-9111',
      fax: '951-278-9909',
      address: '1261 Carbide Dr.',
      city: 'Corona',
      state: 'CA',
      zipcode: 92881,
      referral_link: 'http://4primemedical.com/',
    },
    {
      facility_name: 'DMES Medical Supply Store Los Alamitos',
      phone: '562-344-4770',
      fax: '435-787-2301',
      address: '3474 Katella Ave',
      city: 'Los Alamitos',
      state: 'CA',
      zipcode: 90720,
      referral_link: 'https://dmes.com/',
    },
    {
      facility_name: 'King Medical Supply',
      phone: '800-488-6535',
      fax: '(310) 533-8810',
      address: '20816 Higgins Ct',
      city: 'Torrance',
      state: 'CA',
      zipcode: 90501,
      referral_link: 'http://www.kingmedicalsupply.com/',
    },
    {
      facility_name: 'DME-Direct Inc.',
      email: 'dmedirect@sbcglobal.net',
      phone: '661-705-8330',
      fax: '661-705-8335',
      address: '28910 Ave Penn #207',
      city: 'Valencia',
      state: 'CA',
      zipcode: 91355,
      referral_link: 'http://www.dme-direct.com/',
    },
    {
      facility_name: 'AEG Medical Supply',
      phone: '323-666-9410',
      fax: '323-666-9462',
      address: '5068 Hollywood Blvd # A',
      city: 'Los Angeles',
      state: 'CA',
      zipcode: 90027,
      referral_link: 'http://aegmedicalsupply.com/',
    },
    {
      facility_name: 'Broadway Medical Service and Supply, Inc',
      phone: '916-927-4047',
      fax: '916-927-5383',
      address: '123 Commerce Cir',
      city: 'Sacramento',
      state: 'CA',
      zipcode: 95815,
      referral_link: 'http://www.broadwaymed.com/',
    },
    {
      facility_name: 'Always Available DME',
      phone: '925-689-6887',
      fax: '925-689-6888',
      address: '1982 Concord Ave',
      city: 'Concord',
      state: 'CA',
      zipcode: 94520,
      referral_link: 'https://always-available-dme.business.site/',
    },
    {
      facility_name: 'a2z DME',
      phone: '760-281-3115',
      fax: '-',
      address: '2191 S El Camino Real Suite 103',
      city: 'Oceanside',
      state: 'CA',
      zipcode: 92054,
      referral_link: 'https://a2z-dme.com/',
    },
    {
      facility_name: 'Maximum DME',
      phone: '800-762-1511',
      fax: '657-216-2809',
      address: '200 Bradford Ave Suite J',
      city: 'Placentia',
      state: 'CA',
      zipcode: 92870,
      referral_link: 'http://www.maximumdme.org/',
    },
  ],

  INFUSION_DRUGS: [
    {
      link: 'https://totalinfusion.com/wp-content/uploads/2021/06/Total-Infusion-Tocilizumab-06-22-21-Updated-Draft.pdf',
      label: 'Tocilizumab (ACTEMRA)',
      diagnosis: 'Rheumatoid Arthritis (RA)',
      provider_reminders:
        ' All orders with a  will be placed unless otherwise noted\n Screen for viral hepatitis prior to use\n Ensure baseline PPD or quantiFERON-TB assay for latent TB\n Live vaccines should not be given concurrently or within 3\nmonths of discontinuation of therapy\n Do not combine with tumor necrosis factor (TNF) agents or\nother biologic DMARDS\n CBC w/diff and ALT/AST prior to each treatment\n Lipid Panel at baseline, then 4 and 8 weeks after initiation of\ntreatment and every 6 months thereafter',
      treatment_criteria:
        'Hold Tocilizumab (ACTEMRA) if:\n Temperature GREATER THAN 100 degrees F\n Complains of symptoms of acute viral or bacterial illness\n Taking antibiotics for current infection\n Initial dose only: Unable to verify negative TB results\n ANC LESS THAN 2,000 (first dose) 1,000 (subsequent)\n Platelets LESS THAN 100,000 (first dose) 50,000 (subsequent)\n AST/ALT GREATER THAN 1.5 x ULN\n Live vaccines recieved within 30 days of treatment\nNotify Provider if:\n Unable to verify lipid panel has been drawn as indicated above\n(Instruct patient to have labs drawn today and proceed with\ninfusion)',
      pre_medications: [
        {
          name: 'Acetaminophen (TYLENOL) 650mg PO, Once, 30 minutes prior to Tocilizumab infusion.',
          isChecked: false,
        },
        {
          name: 'Cetirizine (ZYRTEC) 10mg PO, Once, 30 minutes prior to Tocilizumab infusion.',
          isChecked: false,
        },
        {
          name: 'Hydrocortisone Sodium Succinate (SOLU-CORTEF) 50mg IVP (NOT ROUTINE; ONLY IF BREAKTHROUGH REACTION) Once PRN, 30 minutes prior to Tocilizumab infusion in addition to Acetaminophen and Cetirizine if patient experiences symptoms with Acetaminophen and Cetirizine alone.',
          isChecked: false,
        },
      ],
      medication_procedure:
        'Patient Weight ______kg \nInfuse Tocilizumab (ACTEMRA) Intravenously over 60 minutes',
      dose: [
        {
          name: '4mg/kg every 4 weeks for treatments, then 8mg/kg every 4 weeks',
          isChecked: false,
        },
        { name: '4mg/kg every 4 weeks', isChecked: false },
        { name: '8mg/kg every 4 weeks', isChecked: false },
      ],
      frequency: [
        {
          name: '4mg/kg every 4 weeks for treatments, then 8mg/kg every 4 weeks',
          isChecked: false,
        },
        { name: '4mg/kg every 4 weeks', isChecked: false },
        { name: '8mg/kg every 4 weeks', isChecked: false },
      ],
      guidelines: [
        {
          name: 'IV LINE CARE per Nursing Policy',
          isChecked: false,
        },
        {
          name: 'INFUSION REACTION MEDICATIONS per Standardized Procedures',
          isChecked: false,
        },
        {
          name: 'TREATMENT ORDER WEIGHT will be utilized for the duration of the order. Patient will be weighed prior to every treatment. Prescriber will be notified of weight change greater than 10% from baseline weight.',
          isChecked: false,
        },
      ],
    },
    {
      link: 'https://totalinfusion.com/wp-content/uploads/2021/06/Total-Infusion-Crizanlizumab-tmca-06-22-21-Updated-Copy.pdf',
      label: 'Crizanlizumab-tmca (ADAKVEO)',
      diagnosis:
        ' _______ Sickle Cell Disease\n _______ Other: ___________________',
      provider_reminders:
        ' All orders with a  will be placed unless otherwise noted',
      treatment_criteria:
        'Hold Crizanlizumab-tmca (ADAKVEO) if:\n Temperature GREATER THAN 100 degrees F\n Complains of symptoms of acute viral or bacterial illness\n Severe hypersensitivity reaction to Crizanlizumab-tmca\n(ADAKVEO)',
      pre_medications: [
        {
          name: 'No routine premedications necessary.',
          isChecked: false,
        },
        {
          name: 'Acetaminophen (TYLENOL) 650mg PO, Once PRN, 30 minutes prior to Crizanlizumab-tmca infusion, if patient experiences symptoms without premedications.',
          isChecked: false,
        },
        {
          name: 'Cetirizine (ZYRTEC) 10mg PO, Once PRN, at least 30 minutes prior to Crizanlizumab-tmca infusion, if patient experiences symptoms without premedications.',
          isChecked: false,
        },
      ],
      medication_procedure:
        'Patient Weight ______kg (REQUIRED)\nInfuse Crizanlizumab-tcma (ADAKVEO) Intravenously over 30\nminutes using a non-pyrogenic infusion set with 0.2 micron\ninline filter',
      dose: [
        {
          name: '5mg/kg',
          isChecked: false,
        },
      ],
      frequency: [
        {
          name: 'Induction + Maintenance: Week 0, Week 2, then every 4 weeks',
          isChecked: false,
        },
        { name: 'Maintenance: Every 4 weeks', isChecked: false },
      ],
      guidelines: [
        {
          name: 'IV LINE CARE per Nursing Policy',
          isChecked: false,
        },
        {
          name: 'INFUSION REACTION MEDICATIONS per Standardized Procedures',
          isChecked: false,
        },
        {
          name: 'TREATMENT ORDER WEIGHT will be utilized for the duration of the order. Patient will be weighed prior to every treatment. Prescriber will be notified of weight change greater than 10% from baseline weight.',
          isChecked: false,
        },
      ],
    },
    {
      link: 'https://totalinfusion.com/wp-content/uploads/2021/06/Total-Infusion-Belimumab-06-22-21-Updated.pdf',
      label: 'Belimumab (BENLYSTA)\n',
      diagnosis:
        ' _______ Systemic Lupus Erythematosus (SLE)\r\n _______ Other: ___________________',
      provider_reminders:
        ' All orders with a  will be placed unless otherwise noted\n Consider anti-nuclear antibody (ANA) and/or anti-double\nstranded DNA (anti-dsDNA) prior to initial treatment\n Live vaccines should not be given 30 days before or\nconcurrently during treatment\n Not recommended for use in combination with other\nbiologics or IV Cyclophosphamide',
      treatment_criteria:
        'Hold Belimumab (BENLYSTA) if:\n Temperature GREATER THAN 100 degrees F\n Complains of symptoms of acute viral or bacterial illness\n Taking antibiotics for current infection\n Live vaccines received within 30 days of treatment\nNotify Provider if:\n Baseline ANA and/or anti -dsDNA have not been drawn prior to\ninitial treatment\n New or worsening neurological symptoms such as memory\nloss, trouble thinking, dizziness, loss of balance, loss of vision,\ndifficulty talking or walking\n New or worsening symptoms of depression, suicidal thoughts/\nbehavior, or other mood changes\nNursing Considerations:\n Monitor for adverse reaction, including vital signs and pulse ox,\nevery 30 minutes during infusion and 30 minutes post infusion',
      pre_medications: [
        {
          name: 'Acetaminophen (TYLENOL) 650mg PO, Once, 30 minutes prior to Belimumab infusion.',
          isChecked: false,
        },
        {
          name: 'Cetirizine (ZYRTEC) 10mg PO, Once, 30 minutes prior to Belimumab infusion.',
          isChecked: false,
        },
      ],

      medication_procedure:
        'Patient Weight ______kg (REQUIRED)\r\nInfuse Belimumab (BENLYSTA) Intravenously over 60 minutes',
      dose: [
        {
          name: '10mg/kg',
          isChecked: false,
        },
      ],
      frequency: [
        {
          name: 'Every 2 weeks x 3 doses, then every 4 weeks',
          isChecked: false,
        },
        {
          name: 'Every 4 weeks',
          isChecked: false,
        },
      ],
      guidelines: [
        {
          name: 'IV LINE CARE per Nursing Policy',
          isChecked: false,
        },
        {
          name: 'INFUSION REACTION MEDICATIONS per Standardized Procedures',
          isChecked: false,
        },
        {
          name: 'TREATMENT ORDER WEIGHT will be utilized for the duration of the order. Patient will be weighed prior to every treatment. Prescriber will be notified of weight change greater than 10% from baseline weight.',
          isChecked: false,
        },
      ],
    },
    {
      link: 'https://totalinfusion.com/wp-content/uploads/2021/06/Total-Infusion-Reslizumab-06-22-21.pdf',
      label: 'Reslizumab (CINQAIR)',
      diagnosis:
        ' _______ Eosinophilic Asthma\r\n _______ Other: ___________________',
      provider_reminders:
        ' All orders with a  will be placed unless otherwise noted\n Eosinophilic phenotype as determined by blood eosinophils\nof 400 cells/μL or higher prior to initiation of therapy (within 4\nweeks of dosing)',
      treatment_criteria:
        'Hold Reslizumab (CINQAIR) if:\n Initial dose only: blood eosinophils LESS THAN 400 cells/μL\n Temperature GREATER THAN 100 degrees F\n Complains of symptoms of acute viral or bacterial illness\n Taking antibiotics for current infection\nNursing Considerations:\n Monitor for adverse reaction including vital signs and pulse ox,\nat the end of infusion, and 30 minutes post infusion',
      pre_medications: [
        { name: 'No routine premeditations necessary.', isChecked: false },
      ],
      medication_procedure:
        'Patient Weight ______kg (REQUIRED)\nInfuse Reslizumab (CINQAIR) Intravenously over 45 minutes\nthrough an infusion set with an in-line, low protein-binding\nfilter (pore size of 0.2 micron)',
      dose: [
        {
          name: '3mg/kg',
          isChecked: false,
        },
      ],
      frequency: [
        {
          name: ' Every 4 weeks',
          isChecked: false,
        },
      ],
      guidelines: [
        {
          name: 'IV LINE CARE per Nursing Policy',
          isChecked: false,
        },
        {
          name: 'INFUSION REACTION MEDICATIONS per Standardized Procedures',
          isChecked: false,
        },
        {
          name: 'TREATMENT ORDER WEIGHT will be utilized for the duration of the order. Patient will be weighed prior to every treatment. Prescriber will be notified of weight change greater than 10% from baseline weight.',
          isChecked: false,
        },
      ],
    },
    {
      link: 'https://totalinfusion.com/wp-content/uploads/2021/06/Total-Infusion-Vedolizumab-06-22-21-Draft.pdf',
      label: 'Vedolizumab (ENTYVIO)',
      diagnosis:
        ' _______ Ulcerative Colitis\r\n _______ Crohn’s Disease\r\n _______ Other: ___________________',
      provider_reminders:
        ' All orders with a  will be placed unless otherwise noted\n Ensure baseline PPD or quantiFERON-TB assay for latent TB\n Ensure all immunizations are current before initiating therapy\n CBC, AST, and ALT every 8 weeks',
      treatment_criteria:
        'Hold Vedolizumab (ENTYVIO) if:\r\n Temperature GREATER THAN 100 degrees F\r\n Complains of symptoms of acute viral or bacterial illness\r\n Taking antibiotics for current infection\r\n Initial dose only: Unable to verify negative TB results\r\n\r\nNotify Provider if:\r\n CBC, AST, and ALT have not been drawn within the last 12\r\nweeks\r\n New or worsening neurological symptoms, such as memory\r\nloss, trouble thinking, dizziness, loss of balance, loss of vision,\r\ndifficulty talking or walking\r\n Symptoms of liver injury, including fatigue, anorexia, right\r\nupper abdominal discomfort, dark urine or jaundice\r\n\r\nNursing Considerations:\r\n Ensure CBC, AST, and ALT have been drawn within the last 8\r\nweeks, if not, proceed with infusion and instruct patient to\r\nhave labs drawn today\r\n Monitor for adverse reaction including vitalsigns and pulse ox,\r\nevery 15 minutes during infusion',
      pre_medications: [
        {
          name: 'Acetaminophen (TYLENOL) 650mg PO, Once, 30 minutes prior to Vedolizumab infusion.',
          isChecked: false,
        },
        {
          name: 'Cetirizine (ZYRTEC) 10mg PO, Once, at least 30 minutes prior to Vedolizumab infusion.',
          isChecked: false,
        },
        {
          name: 'Hydrocortisone Sodium Succinate (SOLU-CORTEF) 50mg IVP (NOT ROUTINE; ONLY IF BREAKTHROUGH REACTION) Once PRN, 30 minutes prior to Vedolizumab infusion in addition to Acetaminophen and Cetirizine if patient experiences symptoms with Acetaminophen and Cetirizine alone.',
          isChecked: false,
        },
      ],

      medication_procedure:
        'Patient Weight ______kg (REQUIRED)\nInfuse Vedolizumab (ENTYVIO) Intravenously over 30\nminutes; after the infusion is complete, flush with 30 mL of\nsterile 0.9% Sodium Chloride injection.',
      dose: [
        {
          name: '300mg/kg',
          isChecked: false,
        },
      ],
      frequency: [
        {
          name: 'Induction + Maintenance: Every 2 weeks x 2 doses, then every 4 weeks x 1 dose, then every 8 weeks thereafter',
          isChecked: false,
        },
        {
          name: 'Maintenance: Every 8 weeks',
          isChecked: false,
        },
        {
          name: '_______________________________________',
          isChecked: false,
        },
      ],
      guidelines: [
        {
          name: 'IV LINE CARE per Nursing Policy',
          isChecked: false,
        },
        {
          name: 'INFUSION REACTION MEDICATIONS per Standardized Procedures',
          isChecked: false,
        },
        {
          name: 'TREATMENT ORDER WEIGHT will be utilized for the duration of the order. Patient will be weighed prior to every treatment. Prescriber will be notified of weight change greater than 10% from baseline weight.',
          isChecked: false,
        },
      ],
    },
    {
      link: 'https://totalinfusion.com/wp-content/uploads/2021/06/Total-Infusion-Infliximab-dyyb-06-22-21-Update.pdf',
      label: 'Infliximab-dyyb\r\n(INFLECTRA)',
      diagnosis:
        'diagnosis (ICD-10 code required): Premedications\r\n _______ Rheumatoid Arthritis\r\n _______ Psoriatic Arthritis\r\n _______ Plaque Psoriasis\r\n _______ Ankylosing Spondylitis\r\n _______ Crohn’s Disease\r\n _______ Ulcerative Colitis\r\n _______ Other: ___________________',
      provider_reminders:
        ' All orders with a  will be placed unless otherwise noted\n Screen for viral hepatitis prior to use\n Ensure baseline PPD or quantiFERON-TB assay for latent TB\n Live vaccines should not be given concurrently or within 3\nmonths of discontinuation of therapy\n Do not combine with tumor necrosis factor (TNF) agents or\nother biologic DMARDS\n Rheumatology: CBC, ALT, AST, and Creatinine every 8 weeks\n GI/Derm: CBC, ALT, AST, and Creatinine every 12 weeks',
      treatment_criteria:
        'Hold Infliximab-dyyb (INFLECTRA) if:\n Temperature GREATER THAN 100 degrees F\n Complains of symptoms of acute viral or bacterial illness\n Taking antibiotics for current infection\n Initial dose only: Unable to verify negative TB and HBV results\n Live vaccines received within 30 days of treatment\n Rheumatology: CBC, ALT, AST, and Creatinine have not been\ndrawn within the last 12 weeks\n GI/Derm: CBC, ALT, AST, and Creatinine have not been drawn\nwithin the last 16 weeks\n\nNursing Considerations:\n Rheumatology: Ensure CBC, ALT, AST, and Creatinine have\nbeen drawn within the last 8 weeks, if not, proceed with\ninfusion and instruct patient to have labs drawn today\n GI/Derm: Ensure CBC, ALT, AST, and Creatinine have been\ndrawn within the last 12 weeks, if not, proceed with infusion\nand instruct patient to have labs drawn today\n Monitor for adverse reaction, including vital signs and pulse ox,\nevery 15 minutes until max infusion rate achieved, then at the\nend of infusion, and 30 minutes post infusion\n Patient may forgo 30 minute post observation period after four\nconsecutive infusions at 125mL/hr (max rate), without adverse\nreaction',
      pre_medications: [
        {
          name: 'Acetaminophen (TYLENOL) 650mg PO, Once, 30 minutes prior to Infliximab-dyyb infusion.',
          isChecked: false,
        },
        {
          name: 'Cetirizine (ZYRTEC) 10mg PO, Once, at least 30 minutes prior to Infliximab-dyyb infusion.',
          isChecked: false,
        },
        {
          name: 'Hydrocortisone Sodium Succinate (SOLU-CORTEF) 50mg IVP, (NOT ROUTINE; ONLY IF BREAKTHROUGH REACTION) Once PRN, 30 minutes prior to Infliximabdyyb infusion in addition to Acetaminophen and Cetirizine if patient experiences symptoms with Acetaminophen and Cetirizine alone.',
          isChecked: false,
        },
      ],

      medication_procedure:
        'Patient Weight ______kg (REQUIRED)\r\nInfuse Infliximab-dyyb (INFLECTRA) Intravenously:',
      dose: [
        {
          name: '3mg/kg',
          isChecked: false,
        },
        {
          name: '5mg/kg',
          isChecked: false,
        },
        {
          name: '10mg/kg',
          isChecked: false,
        },
        {
          name: 'Initial Dose: Start infusion rate at 10mL/hr, then double rate every 15 minutes. After one hour, increase rate to 125mL/hr until infusion is complete.',
          isChecked: false,
        },
        {
          name: 'Subsequent Infusions: If no adverse reaction to initial dose, start infusion rate at 40mL/hr for 15 minutes, then increase rate to 125mL/hr until infusion is complete. Then, if no adverse reaction for at least 4 more consecutive treatments, start infusion rate at 125mL/hr.',
          isChecked: false,
        },
      ],
      frequency: [
        {
          name: 'Induction + Maintenance: Every 2 weeks x 2 doses, then every 4 weeks x 1 dose, then every 8 weeks thereafter',
          isChecked: false,
        },
        {
          name: 'Maintenance: Every 8 weeks',
          isChecked: false,
        },
      ],

      guidelines: [
        {
          name: 'IV LINE CARE per Nursing Policy',
          isChecked: false,
        },
        {
          name: 'INFUSION REACTION MEDICATIONS per Standardized Procedures',
          isChecked: false,
        },
        {
          name: 'TREATMENT ORDER WEIGHT will be utilized for the duration of the order. Patient will be weighed prior to every treatment. Prescriber will be notified of weight change greater than 10% from baseline weight.',
          isChecked: false,
        },
      ],
    },
    {
      link: 'https://totalinfusion.com/wp-content/uploads/2021/06/Total-Infusion-IV-Antibiotics-06-22-21.pdf',
      label: 'IV Antibiotics',
      diagnosis: '',
      provider_reminders:
        ' All orders with a  will be placed unless otherwise noted\n Intravenous Antibiotic orders are for one dose unless otherwise noted\n Total Infusion will administer daily intravenous antibiotics or the first dose for patients receiving multiple doses daily; subsequent doses\nfor home infusion must be coordinated by the prescriber and specialty pharmacy',
      medication_procedure: [
        {
          name: 'Cefepime IV 500mg',
          isChecked: false,
        },
        {
          name: 'Cefepime IV 1gm',
          isChecked: false,
        },
        {
          name: 'Cefepime IV 2gm',
          isChecked: false,
        },
        {
          name: 'Ceftriaxone IV 250mg',
          isChecked: false,
        },
        {
          name: 'Ceftriaxone IV 500mg',
          isChecked: false,
        },
        {
          name: 'Ceftriaxone IV 1gm',
          isChecked: false,
        },
        {
          name: 'Ceftriaxone IV 2gm',
          isChecked: false,
        },
        {
          name: 'Ertapenem IV 1gm',
          isChecked: false,
        },
        {
          name: 'Vancomycin IV 500mg',
          isChecked: false,
        },
        {
          name: 'Vancomycin IV 750mg',
          isChecked: false,
        },
        {
          name: 'Vancomycin IV 1gm',
          isChecked: false,
        },
      ],
      guidelines: [
        {
          name: 'IV LINE CARE per Nursing Policy',
          isChecked: false,
        },
        {
          name: 'INFUSION REACTION MEDICATIONS per Standardized Procedures',
          isChecked: false,
        },
      ],
    },
    {
      link: 'https://totalinfusion.com/wp-content/uploads/2021/06/Total-Infusion-IV-Hydration-Medication-06-22-20.pdf',
      label: 'IV Hydration/Medication',
      diagnosis: ' ',
      medication_procedure:
        'Patient Weight ______kg (REQUIRED)\nInfusion Therapy:\n Ketorolac IVP                    15mg        30mg\n Ketorolac IM                      30mg        60mg\n Lorazepam IVP                 0.5mg       1mg         2mg\n Ondansetron IVP              4mg           8mg\n Dexamethasone IVP         4mg          8mg          10mg      12mg\n Metoclopramide IVP          5mg          10mg\n Methylprednisolone IVP     125mg\n Methylprednisolone 250mg/50mL 0.9% sodium chloride Intravenously over 15 minutes\n Methylprednisolone 500mg/100mL 0.9% sodium chloride Intravenously over 30 minutes\n Methylprednisolone 1,000mg/250mL 0.9% sodium chloride Intravenously over 60 minutes\n Magnesium Sulfate in Water Injection 2gm/50mL Intravenously over 2 hours\n Other Medication: ______________ Dose: _________________',
      dose: 'Intravenous Fluid\n 0.9% Sodium Chloride  0.45% Sodium Chloride  5% Dextrose\n Lactated Ringers\n 20mEq/L Potassium Chloride in 0.9% Sodium Chloride 1000mL (serum potassium must\nbe GREATER THAN OR EQUAL TO 2.5mEq/L, maximum rate 10mEq/hr, total maximum dose\n40mEq, patient may not receive additional dose without repeat labs)\n ____________________________________________\nVolume\n 250mL  500mL  1,000mL  2,000mL  _______________\nDuration\nInfuse Over:  1 hour  2 hours  4 hours  Bolus  _______________',
      guidelines:
        'IV LINE CARE per Nursing Policy\r\nINFUSION REACTION MEDICATIONS per Standardized Procedures',
    },
    {
      link: 'https://totalinfusion.com/wp-content/uploads/2021/06/Total-Infusion-IV-Immune-Globulin-Form-06-22-21.pdf',
      label: 'IV Immune Globulin\r\n(GAMMAGARD LIQUID 10%) – IVIG',
      diagnosis:
        ' _______ Primary Humoral Immunodeficiency (PI)\r\n _______ Multifocal Motor Neuropathy (MMN)\r\n _______ Other: ________________________',
      provider_reminders:
        ' All orders with a  will be placed unless otherwise noted\n Baseline SCr within 3 months of initial treatment\n',
      treatment_criteria:
        'Hold IVIG (GAMMAGARD LIQUID) if:\r\n Temperature GREATER THAN 100 degrees F\r\n Complains of symptoms of acute viral or bacterial illness\r\nNursing Considerations:\r\n Monitor for adverse reaction including vital signs and pulse ox,\r\nevery 30 minutes until max infusion rate achieved, then at the\r\nend of infusion, and 30 minutes post infusion',
      pre_medications:
        ' Acetaminophen (TYLENOL) 650mg PO, Once, 30 minutes\r\nprior to IVIG infusion.\r\n Cetirizine (ZYRTEC) 10mg PO, Once, 60 minutes prior to\r\nIVIG infusion.\r\n ______________________________________________',
      medication_procedure:
        'Patient Weight ______kg (REQUIRED)\r\nInfuse Intravenous Immune Globulin (GAMMAGARD LIQUID 10%) – IVIG',
      dose: 'Dose:                                                  \n __________ mg/kg                          \n __________ grams/dose                \n ________________________      ',
      frequency:
        'frequency:\n  Daily x ______ doses\n  Every ______ weeks\n  ________________________   \n\n                                      Administration Rate Table     \n  \n                         First Infusion                         Subsequent Infusions\nStep 1        0.5ml/kg/hr for 30 minutes            0.5ml/kg/hr for 15 minutes\nStep 2        1ml/kg/hr for 30 minutes               1ml/kg/hr for 15 minutes\nStep 3        2ml/kg/hr for 30 minutes                2ml/kg/hr for 15 minutes\nStep 4        3ml/kg/hr to complete infusion       3ml/kg/hr to complete infusion\nMax Rate    Not to exceed 150ml/hr                 Not to exceed 300ml/hr\n\nNote:  Infusion rate may be decreased based on consideration of age, medical history, risk of renal failure, and patient tolerance.',
      guidelines:
        'IV LINE CARE per Nursing Policy\r\nINFUSION REACTION MEDICATIONS per Standardized Procedures\r\nTREATMENT ORDER WEIGHT will be utilized for the\r\nduration of the order. Patient will be weighed prior to every\r\ntreatment. Prescriber will be notified of weight change greater\r\nthan 10% from baseline weight.',
    },
    {
      link: 'https://totalinfusion.com/wp-content/uploads/2021/06/Total-Infusion-IV-Iron-06-22-21.pdf',
      label: 'IV Iron',
      diagnosis:
        ' _______ Iron Deficiency\r\n _______ Iron Deficiency Anemia\r\n _______ Other: ________________________',
      provider_reminders:
        ' All orders with a  will be placed unless otherwise noted\n Baseline labs: Ferritin, Fasting TSAT, (add H&H if patient is\nbeing treated for iron deficiency anemia)',
      treatment_criteria:
        'Hold IV Iron if:\r\n Ferritin > 200\r\n TSAT > 20%\r\n ____________________________________________\r\nNursing Considerations:\r\n Do not administer repeat doses of Iron Dextran (Infed) without\r\nreevaluation of iron labs',
      pre_medications:
        ' No routine premedications necessary.\r\n Methylprednisolone Sodium Succinate\r\n(SOLU-MEDROL) 125mg IVP, Once, 30 minutes prior to\r\nIV Iron (NOT ROUTINE; RECOMMENDED FOR PATIENTS\r\nWITH A HISTORY OF DRUG ALLERGIES, AN ALLERGIC\r\nDIATHESIS OR A HISTORY OF INFLAMMATORY\r\nARTHRITIS, WHEREIN BOTH PARENTERAL AND ORAL\r\nIRON HAVE BEEN SHOWN TO EXACERBATE SYMPTOMS)\r\n ____________________________________________\r\n ____________________________________________',
      dose: 'Patient Weight ______kg (REQUIRED)\nInfusion Therapy (Select One):\n Iron Dextran (INFED), 1,000mg\n Administer TEST DOSE: 25mg in 50mL 0.9% sodium chloride Intravenously over 5 minutes, then observe 60 minutes\n If no adverse reaction, administer the remaining 975mg in 250mL 0.9% sodium chloride Intravenously over 60 minutes\n If test dose is not required, administer 1,000mg in 250mL 0.9% sodium chloride Intravenously over 60 minutes\nA test dose is REQUIRED for the first dose of Iron Dextran (Infed). A repeat test dose will be administered if greater than 6\nmonths since the last Iron Dextran (Infed) infusion.\n Iron Sucrose (VENOFER), 200mg\n Administer in 100mL 0.9% sodium chloride Intravenously over 15 minutes\n frequency: every ________ for ________ doses\n Ferric Gluconate (FERRLECIT) 125mg\n Administer in 100mL 0.9% sodium chloride Intravenously over 60 minutes\n frequency: every ________ for ________ doses',
      guidelines:
        'IV LINE CARE per Nursing Policy\r\nINFUSION REACTION MEDICATIONS per Standardized Procedures',
    },
    {
      link: 'https://totalinfusion.com/wp-content/uploads/2021/06/Total-Infusion-Pegloticase-06-22-21-Draft.pdf',
      label: 'Pegloticase\r\n(KRYSTEXXA)',
      diagnosis: ' _______ Chronic Gout\r\n _______ Other: ___________________',
      provider_reminders:
        ' All orders with a  will be placed unless otherwise noted\n Uric Acid level prior to initial dose and within 48 hours prior to\nsubsequent doses\n Contraindicated in patients with G6PD deficiency; screen\npatients at risk prior to initial dose\n Begin prophylactic management for gout flares at least one\nweek prior to initial dose\n Discontinue urate lowering therapies prior to initial dose\n',
      treatment_criteria:
        'Hold Pegloticase (KRYSTEXXA) if:\r\n Initial dose only: Unable to verify normal G6PD activity\r\nfrom lab tests\r\n If two consecutive Uric Acid levels are GREATER THAN 6\r\nNotify Provider if:\r\n Uric Acid level is GREATER THAN 6\r\nNursing Considerations:\r\n Confirm patient has stopped urate lowering therapies e.g.\r\nallopurinol, febuxostat\r\n Remind patients they may have gout flares and ensure they\r\nare taking prophylactic treatment as prescribed\r\n Monitor for adverse reaction including vital signs and pulse ox,\r\nevery 30 minutes, at the end of infusion, and one hour post\r\ninfusion',
      pre_medications:
        ' Acetaminophen (TYLENOL) 650mg PO, Once, 30 minutes\r\nprior to Pegloticase Infusion.\r\n Loratadine (CLARITIN) 10MG PO, Once, 30 minutes prior\r\nto Pegloticase Infusion.\r\nMethylprednisolone Sodium Succinate (SOLU-MEDROL)\r\n125mg IV, Once, 30 minutes prior to Pegloticase Infusion.\r\n ____________________________________________',
      medication_procedure:
        'Patient Weight ______kg (REQUIRED)\r\nInfuse Pegloticase (KRYSTEXXA) Intravenously over 2 hours.',
      dose: 'Dose:\r\n 8mg',
      frequency: 'frequency:\r\n Every 2 weeks',
      guidelines:
        'IV LINE CARE per Nursing Policy\r\nINFUSION REACTION MEDICATIONS per Standardized Procedures',
    },
    {
      link: 'https://totalinfusion.com/wp-content/uploads/2021/07/Total-Infusion-Mepolizumab-07-07-21-Update.pdf',
      label: 'Mepolizumab\r\n(NUCALA)',
      diagnosis:
        ' _______ Severe Asthma (Maintenance Treatment)\r\n _______ Eosinophilic Granulomatosis with Polyangiitis (EGPA)\r\n _______ Hypereosinophilic Syndrome\r\n _______ Other: ________________________',
      provider_reminders:
        ' All orders with a R will be placed unless otherwise noted\r\n Consider Herpes Zoster vaccination if medically appropriate\r\n Treat patients with pre-existing helminth infections before\r\ninitiating therapy with Mepolizumab (NUCALA)',
      treatment_criteria:
        'Hold Mepolizumab (NUCALA) If:\r\n Temperature GREATER THAN 100 degrees F\r\n Complains of symptoms of acute viral or bacterial illness\r\n Severe hypersensitivity reaction to Mepolizumab (NUCALA)\r\nNursing Considerations:\r\n Monitor for adverse reaction, including vital signs and pulse ox,\r\nevery 30 minutes after the first injection, for one hour. Reduce\r\nobservation period to 30 minutes after the second injection and 15\r\nminutes thereafter.',
      pre_medications:
        ' No routine premeditations necessary.\r\n _____________________________________________\r\n _____________________________________________',
      medication_procedure:
        'Patient Weight ______kg (REQUIRED)\r\nInject Mepolizumab (NUCALA) subcutaneously into the upper\r\narm, thigh, or abdomen',
      dose: 'Dose:\r\n 100 mg\r\n 300 mg (3 separate 100-mg injections)\r\n __________________________',
      frequency:
        'frequency:\r\n Once every 4 weeks\r\n __________________________',
      guidelines: 'INFUSION REACTION MEDICATIONS per Standardized Procedures',
    },
    {
      link: 'https://totalinfusion.com/wp-content/uploads/2022/10/Total-Infusion-Ocrelizumab-10-31-22.pdf',
      label: 'Ocrelizumab\r\n(OCREVUS)',
      diagnosis:
        ' _______ Multiple Sclerosis\r\n _______ Other: _______________________',
      provider_reminders:
        ' All orders with a  will be placed unless otherwise noted\n Screen for viral hepatitis\n Complete any required live or live-attenuated vaccinations at\nleast 4 weeks and non-live vaccinations at least 2 weeks prior to\ninitiation of therapy\n Varicella immune screen, urinalysis, and urine culture before\ntreatment start day (continue urinalysis and urine culture prior to\neach treatment if patient has a history of bladder infections)\n CBC w/diff and Serum Immunoglobulins (IgG, IgM, IgA) prior to\ntreatment',
      treatment_criteria:
        'Hold Ocrelizumab (OCREVUS) if:\r\n Temperature GREATER THAN 100 degrees F\r\n Complains of symptoms of acute viral or bacterial illness\r\n Taking antibiotics for current infection\r\n Initial dose only: Unable to verify negative HBV results\r\nNotify Provider if:\r\n CBC w/diff and Serum Immunoglobulins are not drawn prior\r\nto infusion\r\nNursing Considerations:\r\n Monitor for adverse reaction, including vital signs and pulse\r\nox, every 30 minutes until max infusion rate achieved, then at\r\nthe end of infusion, and one hour post infusion\r\n Assess for Progressive Multifocal Leukoencephalopathy (PML)\r\nto include new or worsening neurological symptoms, such as\r\nmemory loss, trouble thinking, dizziness, loss of balance, loss\r\nof vision, difficulty talking or walking',
      pre_medications:
        ' Acetaminophen (TYLENOL) 650mg PO, Once, 30 minutes\r\nprior to Ocrelizumab infusion.\r\n Diphenhydramine (Benadryl) 50mg PO, Once, 30\r\nminutes prior to Ocrelizumab infusion.\r\n Methylprednisolone Sodium Succinate (SOLU-MEDROL)\r\n125mg IVP, Once, 30 minutes prior to Ocrelizumab\r\ninfusion.\r\n________________________________________________',
      medication_procedure:
        'Infuse Ocrelizumab (OCREVUS) Intravenously using an\r\ninfusion set with a 0.2 or 0.22 micron in-line filter',
      dose: 'Induction:\n 300mg on Day 1 and Day 15 (two doses, two weeks apart)\nInfuse at 30mL/hour, increase by 30mL every 30 minutes to a\nmaximum infusion rate of 180mL/hour\nMaintenance: (Starting 6 months after Day 1 of Induction)\n 600mg Once, every 6 months\nInfuse at 40mL/hour, increase by 40mL every 30 minutes to a\nmaximum infusion rate of 200mL/hour\n 600mg Once, every 6 months (2-hour shorter infusion)\nStart at 100 mL/hour. Increase to 200 mL/hour at 15 minutes,\n250 mL/hour at 30 minutes, and maximum infusion rate of 300\nmL/hour at 60 minutes until completion of infusion\n Meperidine (DEMEROL) 25mg IVP, Once PRN, for shaking,\nchills, or rigors. May repeat x1 in 15 minutes if symptoms\nunresolved',
      guidelines:
        'IV LINE CARE per Nursing Policy\r\nHYPERSENSITIVITY/ANAPHYLAXIS MANAGEMENT\r\nper Standardized Procedures',
    },
    {
      link: 'https://totalinfusion.com/wp-content/uploads/2021/06/Total-Infusion-Abatacept-06-22-21.pdf',
      label: 'Abatacept\r\n(ORENCIA)',
      diagnosis:
        ' _______ Rheumatoid Arthritis\r\n _______ Psoriatic Arthritis\r\n _______ Other: ___________________',
      provider_reminders:
        ' All orders with a  will be placed unless otherwise noted\n Screen for viral hepatitis prior to use\n Ensure baseline PPD or quantiFERON-TB assay for latent TB\n Ensure all immunizations are current before initiating therapy\n Live vaccines should not be given concurrently or within 3\nmonths of discontinuation of therapy\n Do not combine with tumor necrosis factor (TNF) agents or\nother biologic DMARDS\n CBC, ALT, AST, and Creatinine every 8 weeks',
      treatment_criteria:
        'Hold Abatacept (ORENCIA) if:\r\n Temperature GREATER THAN 100 degrees F\r\n Complains of symptoms of acute viral or bacterial illness\r\n Taking antibiotics for current infection\r\n Initial dose only: Unable to verify negative TB results\r\n CBC, ALT, AST and Creatinine have not been drawn within the\r\nlast 12 weeks\r\nNursing Considerations:\r\n Ensure CBC, ALT, AST and Creatinine have been drawn within\r\nthe last 8 weeks, if not, proceed with infusion and instruct\r\npatient to have labs drawn today',
      pre_medications:
        ' No routine premedications necessary.\r\n Acetaminophen (TYLENOL) 650mg PO, Once PRN, 30\r\nminutes prior to Abatacept infusion, if patient experiences\r\nsymptoms without premedications.\r\n Cetirizine (ZYRTEC) 10mg PO, Once PRN, at least 30\r\nminutes prior to Abatacept infusion, if patient experiences\r\nsymptoms without premedications.\r\n ____________________________________________',
      medication_procedure:
        'Patient Weight ______kg (REQUIRED)\r\nInfuse Abatacept (ORENCIA) Intravenously over 30\r\nminutes using a non-pyrogenic, low-protein binding filter\r\n(pore size of 0.2 to 1.2 micron).',
      dose: 'Dose:\r\n 500mg for less than 60kg\r\n 750mg for 60kg - 100kg\r\n 1,000mg for greater than 100kg\r\n ________________________',
      frequency:
        'frequency:\r\n Every 2 weeks x 3, then every 4 weeks thereafter\r\n Every 4 weeks\r\n ______________________________________________',
      guidelines:
        'IV LINE CARE per Nursing Policy\r\nINFUSION REACTION MEDICATIONS per Standardized Procedures\r\nTREATMENT ORDER WEIGHT will be utilized for the\r\nduration of the order. Patient will be weighed prior to every\r\ntreatment. Prescriber will be notified of weight change greater\r\nthan 10% from baseline weight.',
    },
    {
      link: 'https://totalinfusion.com/wp-content/uploads/2021/07/Total-Infusion-Denosumab-06-25-21-Updated.pdf',
      label: 'Denosumab\r\n(PROLIA)',
      diagnosis:
        ' _______ ________________________\r\n _______ ________________________',
      provider_reminders:
        ' All orders with a  will be placed unless otherwise noted\n Ensure patient receives calcium 1000mg daily and at least 400\nIU vitamin D daily\n Serum calcium level prior to each injection\n Pregnancy testing in all females of reproductive potential prior\nto each injection\n Ensure effective contraception plan during therapy\n Routine oral exam is recommended prior to initiation of\ntherapy',
      treatment_criteria:
        'Hold Denosumab (PROLIA) If:\r\n Temperature GREATER THAN 100 degrees F\r\n Complains of symptoms of acute viral or bacterial illness\r\n Severe hypersensitivity reaction to Denosumab (PROLIA)\r\n Labs indicate hypocalcemia\r\n Unable to rule out pregnancy\r\nNotify Provider if:\r\n Tooth or jaw swelling or pain\r\n New or unusual thigh/hip or groin pain\r\nNursing Considerations:\r\n Latex Allergy: If sensitive to latex, do not handle the grey needle\r\ncap on the single-use prefilled syringe, which contains dry natural\r\nrubber (a derivative of latex)\r\nPatient Reminders:\r\n Contraception plan during therapy and for at least 5 months\r\nafter the last dose of Denosumab (PROLIA)\r\n Good oral hygiene practices during treatment\r\n Inform dentist of being on Denosumab (PROLIA) prior to\r\ninvasive dental procedures\r\n Take calcium and vitamin D supplements as prescribed',
      pre_medications:
        ' No routine premeditations necessary.\r\n _____________________________________________\r\n _____________________________________________',
      medication_procedure:
        'Patient Weight ______kg (REQUIRED)\r\nInject Denosumab (PROLIA) subcutaneously in the upper\r\narm, upper thigh, or the abdomen',
      dose: 'Dose/frequency:\r\n 60 mg every 6 months\r\nLAST injection date if transferring from\r\nanother facility: ______________________',
      frequency:
        'Dose/frequency:\r\n 60 mg every 6 months\r\nLAST injection date if transferring from\r\nanother facility: ______________________',
      guidelines: 'INFUSION REACTION MEDICATIONS per Standardized Procedures',
    },
    {
      link: 'https://totalinfusion.com/wp-content/uploads/2021/07/Total-Infusion-Zoledronic-Acid-07-01-21-Update.pdf',
      label: 'Zoledronic Acid\r\n(RECLAST)',
      diagnosis:
        'diagnosis (ICD-10 code required): Premedications\r\n _______ Treatment of Osteoporosis\r\n _______ Prevention of Osteoporosis\r\n _______ Osteoporosis in Men\r\n _______ Glucocorticoid-Induced Osteoporosis\r\n _______ Paget’s Disease of Bone\r\n _______ Other: ___________________',
      provider_reminders:
        ' All orders with a  will be placed unless otherwise noted\n Serum Calcium & CrCl prior to treatment\n Consider calcium and vitamin D supplementation\n Routine oral exam recommended prior to treatment\n Pregnancy testing in all females of reproductive potential prior\nto each treatment',
      treatment_criteria:
        'Hold Zoledronic acid (RECLAST) If:\r\n Temperature GREATER THAN 100 degrees F\r\n Complains of symptoms of acute viral or bacterial illness\r\n Severe hypersensitivity reaction to Zoledronic acid (RECLAST)\r\n CrCl less than 35mL/min\r\n Labs indicate hypocalcemia\r\n Unable to rule out pregnancy\r\nNursing Considerations:\r\n Safe Handling Precautions\r\n Encourage patient to drink 2 glasses of fluid at least one hour\r\nprior to infusion\r\n Instruct patient to take Acetaminophen (TYLENOL) 650mg\r\nevery 6 hours for post-infusion-flu-like symptoms 2-3 days\r\nfollowing infusion as needed.',
      pre_medications:
        ' Acetaminophen (TYLENOL) 650mg PO, Once, at least\r\n15 minutes prior to Zoledronic acid (RECLAST) infusion.\r\n Cetirizine (ZYRTEC) 10mg PO, Once, at least\r\n15 minutes prior to Zoledronic acid (RECLAST) infusion.\r\n ______________________________________________',
      medication_procedure:
        'Patient Weight ______kg (REQUIRED)\r\nInfuse Zoledronic acid (RECLAST) 5mg/100mL through a\r\nvented infusion line over 30 minutes',
      frequency:
        'frequency:\r\nAnnually (a new order is required annually)\r\n ___________________________________________\r\nLast infusion date if transferring from\r\nanother facility: _______________________',
      guidelines:
        'IV LINE CARE per Nursing Policy\r\nINFUSION REACTION MEDICATIONS per Standardized Procedures',
    },
    {
      link: 'https://totalinfusion.com/wp-content/uploads/2021/06/Total-Infusion-Infliximab-06-22-21.pdf',
      label: 'Infliximab\r\n(REMICADE)',
      diagnosis:
        ' _______ Rheumatoid Arthritis\r\n _______ Psoriatic Arthritis\r\n _______ Plaque Psoriasis\r\n _______ Ankylosing Spondylitis\r\n _______ Crohn’s Disease\r\n _______ Ulcerative Colitis\r\n _______ Other: ___________________',
      provider_reminders:
        ' All orders with a  will be placed unless otherwise noted\n Screen for viral hepatitis prior to use\n Ensure baseline PPD or quantiFERON-TB assay for latent TB\n Live vaccines should not be given concurrently or within 3\nmonths of discontinuation of therapy\n Do not combine with tumor necrosis factor (TNF) agents or\nother biologic DMARDS\n Rheumatology: CBC, ALT, AST, and Creatinine every 8 weeks\n GI/Derm: CBC, ALT, AST, and Creatinine every 12 weeks',
      treatment_criteria:
        'Hold Infliximab (REMICADE) if:\r\n Temperature GREATER THAN 100 degrees F\r\n Complains of symptoms of acute viral or bacterial illness\r\n Taking antibiotics for current infection\r\n Initial dose only: Unable to verify negative TB and HBV results\r\n Live vaccines received within 30 days of treatment\r\n Rheumatology: CBC, ALT, AST, and Creatinine have not been\r\ndrawn within the last 12 weeks\r\n GI/Derm: CBC, ALT, AST, and Creatinine have not been drawn\r\nwithin the last 16 weeks\r\nNursing Considerations:\r\n Rheumatology: Ensure CBC, ALT, AST, and Creatinine have\r\nbeen drawn within the last 8 weeks, if not, proceed with\r\ninfusion and instruct patient to have labs drawn today\r\n GI/Derm: Ensure CBC, ALT, AST, and Creatinine have been\r\ndrawn within the last 12 weeks, if not, proceed with infusion\r\nand instruct patient to have labs drawn today\r\n Monitor for adverse reaction, including vital signs and pulse ox,\r\nevery 15 minutes until max infusion rate achieved, then at the\r\nend of infusion, and 30 minutes post infusion\r\n Patient may forgo 30 minute post observation period after four\r\nconsecutive infusions at 125mL/hr (max rate), without adverse\r\nreaction',
      pre_medications:
        ' Acetaminophen (TYLENOL) 650mg PO, Once, 30\r\nminutes prior to Infliximab infusion.\r\n Cetirizine (ZYRTEC) 10mg PO, Once, at least 30\r\nminutes prior to Infliximab infusion.\r\n Hydrocortisone Sodium Succinate (SOLU-CORTEF)\r\n50mg IVP (NOT ROUTINE; ONLY IF BREAKTHROUGH\r\nREACTION) Once PRN, 30 minutes prior to Infliximab\r\ninfusion in addition to Acetaminophen and Cetirizine\r\nif patient experiences symptoms with Acetaminophen\r\nand Cetirizine alone.\r\n ______________________________________________',
      medication_procedure:
        'Patient Weight ______kg (REQUIRED)\r\nInfuse Infliximab (REMICADE) Intravenously:',
      dose: 'Dose:\r\n 3mg/kg  5mg/kg  10mg/kg  __________\r\nInitial Dose: Start infusion rate at 10mL/hr, then double rate\r\nevery 15 minutes. After one hour, increase rate to 125mL/hr\r\nuntil infusion is complete.\r\nSubsequent Infusions: If no adverse reaction to initial dose,\r\nstart infusion rate at 40mL/hr for 15 minutes, then increase\r\nrate to 125mL/hr until infusion is complete. Then, if no adverse\r\nreaction for at least 4 more consecutive treatments, start\r\ninfusion rate at 125mL/hr.',
      frequency:
        'frequency:\r\n Induction + Maintenance: Every 2 weeks x 2 doses, then\r\nevery 4 weeks x 1 dose, then every 8 weeks thereafter\r\n Maintenance: Every 8 weeks',
      guidelines:
        'IV LINE CARE per Nursing Policy\r\nINFUSION REACTION MEDICATIONS per Standardized Procedures\r\nTREATMENT ORDER WEIGHT will be utilized for the\r\nduration of the order. Patient will be weighed prior to every\r\ntreatment. Prescriber will be notified of weight change greater\r\nthan 10% from baseline weight.',
    },
    {
      link: 'https://totalinfusion.com/wp-content/uploads/2021/06/Total-Infusion-Rituximab-06-22-21-Draft-Update.pdf',
      label: 'Rituximab\r\n(RITUXAN)',
      diagnosis:
        ' _______ Rheumatoid Arthritis (RA)\r\n _______ Granulomatosis w/Polyangitis (GPA)\r\n _______ Microscopic Polyangitis (MPA)\r\n _______ Pemphigus Vulgaris (PV)\r\n _______ Other: ________________________',
      provider_reminders:
        ' All orders with a  will be placed unless otherwise noted\n Screen for viral hepatitis prior to use\n CBC, ALT, AST, and Creatinine before treatment start day',
      treatment_criteria:
        'Hold Rituximab (RITUXAN) if:\r\n Temperature GREATER THAN 100 degrees F\r\n Complains of symptoms of acute viral or bacterial illness\r\n Taking antibiotics for current infection\r\n Initial dose only: Unable to verify negative HBV results\r\nNotify Provider if:\r\n New or worsening neurological symptoms such as memory\r\nloss, trouble thinking, dizziness, loss of balance, loss of vision,\r\ndifficulty talking or walking\r\nNursing Considerations:\r\n Monitor for adverse reaction, including vital signs and pulse ox,\r\nevery 30 minutes until max infusion rate achieved, then at the\r\nend of infusion',
      pre_medications:
        ' Acetaminophen (TYLENOL) 650mg PO, Once, 30 minutes\r\nprior to Rituximab infusion.\r\n Cetirizine (ZYRTEC) 10mg PO, Once, 60 minutes prior to\r\nRituximab infusion.\r\nMethylprednisolone Sodium Succinate (SOLU-MEDROL)\r\n125mg IVP, Once, 30 minutes prior to Rituximab infusion.',
      medication_procedure:
        'Patient Weight ______kg (REQUIRED)\r\nInfuse Rituximab (RITUXAN) Intravenously',
      dose: 'Dose:\r\n 500mg  375mg/m2\r\n 1,000mg  _________\r\nInitial Dose: Initiate infusion at a rate of 50 mg/HOUR. In the\r\nabsence of adverse reaction , increase infusion rate by 50 mg/\r\nHOUR increments every 30 minutes, to a maximum rate of 400\r\nmg/HOUR.\r\nSubsequent Infusions: Initiate infusion at a rate of 100 mg/HOUR.\r\nIn the absence of adverse reaction, increase infusion rate by 100\r\nmg/HOUR increments every 30 minutes, to a maximum rate of\r\n400 mg/HOUR.',
      frequency:
        ' Two doses, two weeks apart [Initial dose (Day 1), followed\r\nby second dose on (Day 15)]\r\n Four doses [once weekly for 4 weeks]\r\n Single dose\r\n Meperidine (DEMEROL) 25mg IVP, Once PRN, for\r\nshaking, chills, or rigors. May repeat x1 in 15 minutes if\r\nsymptoms unresolved.\r\n Methylprednisolone Sodium Succinate (SOLUMEDROL) 1000mg IV (NOT ROUTINE; RECOMMENDED\r\nTO TREAT SEVERE VASCULITIS SYMPTOMS) Once Daily,\r\nDays 1-3.',
      guidelines:
        'IV LINE CARE per Nursing Policy\r\nINFUSION REACTION MEDICATIONS per Standardized Procedures\r\nTREATMENT ORDER WEIGHT will be utilized for the\r\nduration of the order. Patient will be weighed prior to every\r\ntreatment. Prescriber will be notified of weight change greater\r\nthan 10% from baseline weight.',
    },
    {
      link: 'https://totalinfusion.com/wp-content/uploads/2021/06/Total-Infusion-Golimumab-06-22-21.pdf',
      label: 'Golimumab\r\n(SIMPONI ARIA)',
      diagnosis:
        ' _______ Rheumatoid Arthritis (RA)\r\n _______ Psoriatic Arthritis (PsA)\r\n _______ Ankylosing Spondylitis (AS)\r\n _______ Other: ________________________',
      provider_reminders:
        ' All orders with a  will be placed unless otherwise noted\n Screen for viral hepatitis prior to use\n Ensure baseline PPD or quantiFERON-TB Assay for latent TB\n Do not combine with tumor necrosis factor (TNF) agents,\nAbatacept, or Anakinra\n Ensure all immunizations are current before initiating therapy\n CBC at baseline and every 8 weeks',
      treatment_criteria:
        'Hold Golimumab (SIMPONI ARIA) if:\r\n Temperature GREATER THAN 100 degrees F\r\n Complains of symptoms of acute viral or bacterial illness\r\n Taking antibiotics for current infection\r\n Initial dose only: Unable to verify negative TB and HBV results\r\nNotify Provider If:\r\n Patient presents with a cough that lasts more than three\r\nweeks, loss of appetite, unintentional weight loss, fever, chills,\r\nor night sweats\r\nNursing Considerations:\r\n Ensure CBC has been drawn within the last 8 weeks, if not,\r\nproceed with infusion and instruct patient to have labs drawn\r\ntoday\r\n Monitor for adverse reaction, including vital signs and pulse\r\nox, at the end of infusion and 30 minutes post infusion\r\n Patient may forgo 30 minute observation period if no adverse\r\nreaction after the first three infusions',
      pre_medications:
        ' No routine premeditations necessary.\r\n _____________________________________________\r\n _____________________________________________',
      medication_procedure:
        'Patient Weight ______kg (REQUIRED)\r\nInfuse Golimumab (SIMPONI ARIA) Intravenously over 30\r\nminutes using an in-line, sterile, non-pyrogenic, low-protein\r\nbinding filter (pore size 0.22micrometer or less).',
      dose: ' 2mg/kg every 4 weeks x 2 doses, then every 8 weeks\r\n thereafter',
      frequency:
        ' 2mg/kg every 4 weeks x 2 doses, then every 8 weeks\r\n thereafter',
      guidelines:
        'IV LINE CARE per Nursing Policy\r\nINFUSION REACTION MEDICATIONS per Standardized Procedures\r\nTREATMENT ORDER WEIGHT will be utilized for the\r\nduration of the order. Patient will be weighed prior to every\r\ntreatment. Prescriber will be notified of weight change greater\r\nthan 10% from baseline weight.',
    },
    {
      link: 'https://totalinfusion.com/wp-content/uploads/2021/06/Total-Infusion-Ustekinumab-06-22-21.pdf',
      label: 'Ustekinumab\r\n(STELARA)',
      diagnosis:
        ' _______ Crohn’s Disease\r\n _______ Ulcerative Colitis\r\n _______ Other: ___________________',
      provider_reminders:
        ' All orders with a  will be placed unless otherwise noted\n Ensure baseline PPD or quantiFERON-TB assay for latent TB\n Ensure all immunizations are current before initiating therapy\n Live vaccines should not be given concurrently or within 3\nmonths of discontinuation of therapy\n BCG vaccine should not be administered 1 year prior to\ninitiation or 1 year following discontinuation of therapy\n CBC at baseline and every 8 weeks',
      treatment_criteria:
        'Hold Ustekinumab (STELARA) if:\r\n Temperature GREATER THAN 100 degrees F\r\n Complains of symptoms of acute viral or bacterial illness\r\n Taking antibiotics for current infection\r\n Initial dose only: Unable to verify negative TB results\r\nNotify Provider if:\r\n CBC has not been drawn within the last 12 weeks\r\n New or worsening neurological symptoms such as memory\r\nloss, trouble thinking, dizziness, loss of balance, loss of vision,\r\ndifficulty talking or walking\r\nNursing Considerations:\r\n Ensure CBC has been drawn within the last 8 weeks, if not,\r\nproceed with infusion/injection and instruct patient to have\r\nlabs drawn today',
      pre_medications:
        ' No routine premeditations necessary.\r\n Acetaminophen (TYLENOL) 650mg PO, Once PRN, 30\r\nminutes prior to Ustekinumab, if patient experiences\r\nsymptoms without premedications.\r\n Cetirizine (ZYRTEC) 10mg PO, Once PRN, at least 30\r\nminutes prior to Ustekinumab, if patient experiences\r\nsymptoms without premedications.\r\n ________________________',
      medication_procedure:
        'Patient Weight ______kg (REQUIRED)\r\nInfuse Ustekinumab (STELARA) Intravenously over 60\r\nminutes through an infusion set with an in-line, sterile,\r\nnon-pyrogenic, low protein binding filter (pore size 0.2\r\nmicrometer).',
      dose: ' 260mg for 55kg or less\r\n 390mg for 56kg - 85kg\r\n 520mg for greater than 85kg',
      frequency:
        ' Induction: Single intravenous dose, followed by:\r\nMust select one of the following options:\r\n Maintenance: 90mg subcutaneous, selfadministered 8 weeks after the initial\r\nintravenous dose, then every 8 weeks thereafter\r\n(coordinated by prescriber with specialty\r\npharmacy, not included in this order)\r\n Maintenance: 90mg subcutaneous, every 8\r\nweeks after initial intravenous dose and every 8\r\nweeks thereafter (coordinated and administered\r\nby Total Infusion)',
      guidelines:
        'IV LINE CARE per Nursing Policy\r\nINFUSION REACTION MEDICATIONS per Standardized Procedures\r\nTREATMENT ORDER WEIGHT will be utilized for the\r\nduration of the order. Patient will be weighed prior to every\r\ntreatment. Prescriber will be notified of weight change greater\r\nthan 10% from baseline weight.',
    },
    {
      link: 'https://totalinfusion.com/wp-content/uploads/2021/06/Total-Infusion-Therapeutic-Phlebotomy-06-22-21.pdf',
      label: 'Therapeutic Phlebotomy',
      diagnosis:
        ' _______ Hemochromatosis\r\n _______ Polycythemia Vera\r\n _______ Porphyria Cutanea Tarda\r\n _______ Other: ________________________',
      provider_reminders:
        ' All orders with a  will be placed unless otherwise noted\n Patient must have labs drawn within one week of every\ntherapeutic phlebotomy procedure\n 0.9% NaCl will be administered at a keep vein open rate for\nmild to moderate symptoms and at 999ml/hr for hypotension\nof <80mmHg systolic or symptomatic change from baseline',
      treatment_criteria:
        'Hold Therapeutic Phlebotomy if:\r\n Patient does not meet lab criteria specified\r\n Temperature GREATER THAN 100 degrees F\r\n Complains of symptoms of acute viral or bacterial illness\r\nNursing Considerations:\r\n Ensure baseline VS & Pulse Ox prior to procedure, immediately\r\npost procedure, and every 15 minutes x’s 2 prior to discharge\r\n Collect blood at a rate patient can tolerate, generally over\r\n10-15 minutes\r\n Ensure oral fluid intake throughout procedure\r\n Monitor for adverse reaction such as nausea, shortness\r\nof breath, chest pain, lightheadedness, diaphoresis, and\r\northostatic hypotension\r\n Discontinue treatment for any adverse reaction, document\r\namount of blood removed and notify physician\r\n Patient must be asymptomatic and vital signs, and pulse ox\r\nmust be WNL prior to discharge',
      medication_procedure:
        'Patient Weight ______kg (REQUIRED)\r\nPerform Therapeutic Phlebotomy\r\n Remove 500mL whole blood as tolerated\r\n Remove 250mL whole blood as tolerated\r\n Remove _____mL whole blood as tolerated',
      frequency:
        'Once every ____weeks(s) if:\r\nHgb GREATER THAN ______________\r\nHct GREATER THAN _______________\r\nFerritin GREATER THAN ____________\r\n\r\nIV Therapy\r\n No IV fluid replacement\r\n Administer 0.9% NaCl 250mL bolus immediately following\r\nphlebotomy\r\n Administer 0.9% NaCl 500mL bolus immediately following\r\nphlebotomy',
      guidelines: 'IV LINE CARE per Nursing Policy',
    },
    {
      link: 'https://totalinfusion.com/wp-content/uploads/2021/06/Total-Infusion-Rituximab-abbs-06-22-21.pdf',
      label: 'Rituximab-abbs\r\n(TRUXIMA)',
      diagnosis:
        ' _______ Rheumatoid Arthritis (RA)\r\n _______ Granulomatosis w/Polyangitis (GPA)\r\n _______ Microscopic Polyangitis (MPA)\r\n _______ Other: ________________________',
      provider_reminders:
        ' All orders with a  will be placed unless otherwise noted\n Screen for viral hepatitis prior to use\n CBC, ALT, AST, and Creatinine before treatment start day',
      treatment_criteria:
        'Hold Rituximab-abbs (TRUXIMA) if:\r\n Temperature GREATER THAN 100 degrees F\r\n Complains of symptoms of acute viral or bacterial illness\r\n Taking antibiotics for current infection\r\n Initial dose only: Unable to verify negative HBV results\r\nNotify Provider if:\r\n New or worsening neurological symptoms such as memory\r\nloss, trouble thinking, dizziness, loss of balance, loss of vision,\r\ndifficulty talking or walking\r\nNursing Considerations:\r\n Monitor for adverse reaction, including vital signs and pulse ox,\r\nevery 30 minutes until max infusion rate achieved, then at the\r\nend of infusion',
      pre_medications:
        ' Acetaminophen (TYLENOL) 650mg PO, Once, 30 minutes\r\nprior to Rituximab-abbs infusion.\r\n Cetirizine (ZYRTEC) 10mg PO, Once, 60 minutes prior to\r\nRituximab-abbs infusion.\r\n Methylprednisolone Sodium Succinate (SOLU-MEDROL)\r\n125mg IVP, Once, 30 minutes prior to Rituximab-abbs\r\ninfusion.',
      medication_procedure:
        'Patient Weight ______kg (REQUIRED)\r\nInfuse Rituximab-abbs (TRUXIMA) Intravenously',
      dose: 'Dose:\r\n 500mg  375mg/m2\r\n 1,000mg  _________\r\nInitial Dose: Initiate infusion at a rate of 50 mg/HOUR. In the\r\nabsence of adverse reaction, increase infusion rate by 50 mg/HOUR\r\nincrements every 30 minutes, to a maximum of 400 mg/HOUR.\r\nSubsequent Infusions: Initiate infusion at a rate of 100 mg/HOUR.\r\nIn the absence of adverse reaction, increase infusion rate by\r\n100 mg/HOUR increments every 30 minutes, to a maximum rate of\r\n400 mg/HOUR.',
      frequency:
        ' Two doses, two weeks apart [Initial dose (Day 1), followed\nby second dose on (Day 15)]\n Four doses [once weekly for 4 weeks]\n Single dose\n Meperidine (DEMEROL) 25mg IVP, Once PRN, for shaking,\nchills, or rigors. May repeat x1 in 15 minutes if symptoms\nunresolved.\n Methylprednisolone Sodium Succinate (SOLU-MEDROL)\n1000mg IV (NOT ROUTINE; RECOMMENDED TO TREAT\nSEVERE VASCULITIS SYMPTOMS) Once Daily, Days 1-3.',
      guidelines:
        'IV LINE CARE per Nursing Policy\r\nINFUSION REACTION MEDICATIONS per Standardized Procedures\r\nTREATMENT ORDER WEIGHT will be utilized for the\r\nduration of the order. Patient will be weighed prior to every\r\ntreatment. Prescriber will be notified of weight change greater\r\nthan 10% from baseline weight.',
    },
    {
      link: 'https://totalinfusion.com/wp-content/uploads/2021/06/Total-Infusion-Natalizumab-06-22-21.pdf',
      label: 'Natalizumab\r\n(TYSABRI)',
      diagnosis: ' _______ Crohn’s Disease\r\n _______ Multiple Sclerosis',
      provider_reminders:
        ' All orders with a  will be placed unless otherwise noted\n Natalizumab (TYSABRI) is only available through an FDA Risk\nEvaluation and Mitigation Strategies (REMS) Program, called\nthe TOUCH Prescribing Program; prescribers must call\n1-800-456-2255 for details/enrollment or enroll online at\nwww.TOUCHprogram.com',
      treatment_criteria:
        'Steps to Infusing Natalizumab (TYSABRI):\nStep 1: Confirm that the patient is currently authorized to\nreceive TYSABRI via TOUCH On-Line\nStep 2: Provide the patient with the Patient Medication Guide\nand ensure he or she has read and understands it\nStep 3: Complete the Pre-infusion Patient Checklist on\nTOUCH On-Line\nNURSING CONSIDERATIONS:\n Do not prepare the infusion until the Pre-infusion Patient\nChecklist has been successfully completed\n If the patient answered YES to question 1, 2, or 3 in Step 3 of\nthe Pre-infusion Patient Checklist, DO NOT INFUSE. Contact\nthe healthcare provider who prescribed TYSABRI, review the\npatient’s answers, and confirm authorization for infusion or hold\nas advised\n Submit the Pre-infusion Patient Checklist via TOUCH On-Line\nwithin 1 business day of the patient’s visit and place a copy in the\npatient record\n The Pre-infusion Patient Checklist must be completed at every\ninfusion visit, even if the patient is not infused',
      pre_medications:
        ' No routine premeditations necessary.\r\n _____________________________________________\r\n _____________________________________________',
      dose: 'Patient Weight ______kg (REQUIRED)\n Infuse Natalizumab (TYSABRI) 300mg Intravenously over\n60 minutes every 4 weeks, per the TOUCH Prescribing\nProgram Prescriber/Patient Enrollment Form.\n Monitor for adverse reaction, including vital signs and\npulse ox, every 30 minutes during infusion, and one hour\npost infusion.',
      guidelines:
        'IV LINE CARE per Nursing Policy\r\nINFUSION REACTION MEDICATIONS per Standardized Procedures\r',
    },
    {
      link: 'https://totalinfusion.com/wp-content/uploads/2021/06/Total-Infusion-Omalizumab-06-22-21.pdf',
      label: 'Omalizumab\r\n(XOLAIR)',
      diagnosis:
        ' _______ Asthma\r\n _______ Nasal Polyps\r\n _______ Chronic Idiopathic Urticaria (CIU)\r\n _______ Other: ________________________',
      provider_reminders:
        ' All orders with a R will be placed unless otherwise noted\r\n Serum IgE level required before initiation of treatment for\r\nAsthma & Nasal Polyps, not required for Chronic Idiopathic\r\nUrticaria (CUI)',
      treatment_criteria:
        'Hold Omalizumab (XOLAIR) if:\n Temperature GREATER THAN 100 degrees F\n Complains of symptoms of acute viral or bacterial illness\n Severe hypersensitivity reaction to Omalizumab (XOLAIR)\nNursing Considerations:\n The injection may take 5-10 seconds to administer because the\nsolution is slightly viscous.\n For the first 3 injections, monitor for adverse reaction including\nvital signs and pulse ox, every 30 minutes post injection for\ntwo hours. Reduce observation period to 30 minutes for the\n4th and subsequent injections. If the patient refuses to stay for\nthe recommended observation period, he or she must sign a\nwaiver.\n Do not administer more than 150mg per injection site.',
      pre_medications:
        ' No routine premeditations necessary.\r\n _____________________________________________\r\n _____________________________________________',
      medication_procedure:
        'Patient Weight ______kg (REQUIRED)\r\nInject Omalizumab (XOLAIR)',
      dose: ' ______ mg',
      frequency: ' Every 2 weeks\r\n Every 4 weeks',
      guidelines:
        'INFUSION REACTION MEDICATIONS per Standardized Procedures\nTREATMENT ORDER WEIGHT will be utilized for the duration\nof the order. Patient will be weighed prior to every treatment.\nPrescriber will be notified of weight change greater than 10%\nfrom baseline weight.',
    },
    {
      link: 'https://docs.google.com/forms/d/e/1FAIpQLSdW8pG1p53-dUSC664ieuctEnkK-cAJceVIN0tcFc9p3CfXfg/viewform?vc=0&c=0&w=1&flr=0',
      label: 'Prescriber Online Referral',
      diagnosis:
        'ICD-10 diagnosis Code: U07.1 COVID-19 (Treatment is for Active Infection Only) ',
      provider_reminders:
        'PRESCRIBER REMINDERS:\r\n1. Patient must have a positive COVID-19 test [please fax lab result to (510) 969-5840]\r\n2. Onset of symptoms must be within 7 days of treatment\r\n3. Patient must be at least 12 years of age and 40kg (88 pounds)\r\n5. Follow Up Care is the responsibility of the prescriber',
      treatment_criteria:
        'INFUSION THERAPY ORDERS:\r\n1. Bebtelovimab 175mg IV, Once\r\n2. Administer Bebtelovimab in alignment with manufacturer guidelines\r\n3. No routine premedications\r\n4. IV Line Care per Nursing Policy\r\n5. Infusion Reaction Medications per Standardized Procedures \r\n6. Monitor patient for adverse reaction during and one hour post administration \r',
    },
    {
      link: 'https://totalinfusion.com/wp-content/uploads/2022/04/Bebtelovimab-Form.pdf',
      label: 'Prescriber Order Form',
      diagnosis: ' U07.1 COVID-19',
      provider_reminders:
        ' Please fax lab result to (510) 969-5840\r\n Onset of symptoms must be within 7 days of treatment\r\n Patient must be at least 12 years of age and 40kg (88 pounds)\r\n Prescriber may use discretion in determining if patient is at high risk (see below)\r\n Follow Up Care is the responsibility of the prescriber',
      treatment_criteria:
        'COVID-19 Positive Test Result _____________ (Date)\r\nOnset of Symptoms _____________ (Date)\r\nPlease select high risk criteria [Other medical conditions or factors may also place individual patients at high risk for\r\nprogression to severe COVID-19; authorization of Bebtelovimab under the EUA is not limited to the medical conditions or\r\nfactors listed below. Physician may use clinical discretion in determining patient’s risk.]\r\n 65 years of age or older\r\n Overweight/Obesity (BMI>25)\r\n Pregnancy/Postpartum (up to 42 days)\r\n Chronic kidney disease\r\n Diabetes\r\n Immunosuppressive disease/treatment\r\n Cardiovascular disease/hypertension\r\n Chronic lung disease\r\n Sickle cell disease\r\n Neurodevelopmental disorders\r\n Medical related technological dependence\r\n Race/Ethnicity\r\n Other _______________________________________\r\n\r\nFor higher prioritization, please indicate if any of the following apply:\r\n Been receiving active cancer treatment for tumors or\r\ncancers of the blood\r\n Received an organ transplant and are taking medicine\r\nto suppress the immune system\r\n Received a stem cell transplant within the last 2 years\r\nor are taking medicine to suppress the immune system\r\n Moderate or severe primary immunodeficiency (such\r\nas DiGeorge syndrome, Wiskott-Aldrich syndrome)\r\n Advanced or untreated HIV infection\r\n Active treatment with high-dose corticosteroids or other\r\ndrugs that may suppress patient’s immune response\r\n High Risk Pregnancy: Existing health conditions,\r\noverweight/obesity, preeclampsia, gestational diabetes,\r\nstillbirth, neural tube defects, cesarean delivery, multiple\r\nbirths, age <17 or >35\r\n Unvaccinated',
      guidelines:
        'INFUSION THERAPY ORDERS:\r\n Bebtelovimab 175mg, IV, Once\r\n No routine premedications\r\n IV Line Care perNursing Policy\r\n Infusion Reaction Medications per Standardized\r\nProcedures\r\n Monitor patient for adverse reaction during and\r\none hour post administration\r',
    },
    {
      link: 'https://docs.google.com/forms/d/1SXcIBXIdsuKgkFzBn4nlPQzV995Uc1byAvYOPgtPIxY/viewform?edit_requested=true',
      label: 'Patient Self-Referral',
      treatment_criteria:
        '1. Must be COVID-19 positive\r\n2. Must be within 7 days of symptom onset\r\n3. Must be at least 12 years of age and 88 pounds \r\n\r\nUpon submission, a member of our healthcare team will review the referral and contact you within 4 business hours to coordinate scheduling.\r\n\r\nIf you are experiencing difficulty breathing, chest pain, or shortness of breath - please seek emergency medical care immediately.',
      guidelines:
        'PLEASE NOTE: WHILE BEBTELOVIMAB IS APPROVED FOR INDIVIDUALS AT LEAST 12 YEARS OF AGE AND 88 POUNDS, THOSE UNDER 18 YEARS OF AGE MAY NOT SELF REFER (PLEASE CONTACT YOUR PRIMARY CAREGIVER AND ASK FOR A REFERRAL TO TOTAL INFUSION FOR BEBTELOVIMAB). \r\n\r\nPREGNANT WOMEN MUST ALSO CONTACT THEIR PCP OR OB FOR A REFERRAL TO TOTAL INFUSION FOR BEBTELOVIMAB. ',
    },
    {
      link: 'https://totalinfusion.com/wp-content/uploads/2022/04/Bebtelovimab-Roster-Form.pdf',
      label: 'Roster Order Form for Congregate Settings',
    },
    {
      link: 'https://totalinfusion.com/wp-content/uploads/2022/08/EVUSHELD-8-16-22.pdf',
      label: 'Evushield Order Form',
      diagnosis:
        ' Z28.04 Immunization not carried out because of patient\r\nallergy to vaccine or component\r\n D84.9 Immunodeficiency, unspecified\r\n ______ Other:______________________\r',
      provider_reminders:
        ' All orders with a R will be placed unless otherwise noted\r\n EVUSHELD is NOT authorized for treatment of COVID-19, or for\r\npost-exposure prophylaxis\r\n Patient must be at least 12 years of age and 40kg (88 pounds)\r\n Consider the risks and benefits prior to initiating EVUSHELD\r\nin individuals at high risk for cardiovascular events\r\n EVUSHELD contains polysorbate 80, which is similar to\r\npolyethylene glycol (PEG), an ingredient in some COVID-19\r\nvaccines. Consider consultation with allergy and/or\r\nimmunology prior to ordering EVUSHELD if the patient has\r\nhad a severe reaction to the COVID-19 vaccine\r\n Follow Up Care is the responsibility of the prescriber\r\n\r\nHold EVUSHELD If:\r\n Temperature GREATER THAN 100 degrees F\r\n Complains of symptoms of acute viral or bacterial illness\r\n Severe hypersensitivity reaction to EVUSHELD\r\n\r\nNursing Considerations:\r\n Clinically monitor individuals after injections and observe\r\nfor at least 1 hour',
      treatment_criteria:
        '\nPatient is NOT currently infected with SARS-CoV-2, has no known recent exposure to an individual infected with SARS-CoV-2 and\nwhom vaccination with any available COVID-19 vaccine is not recommended due to a history of severe adverse reaction, or\nhas moderate to severe immune compromise and may not mount an adequate immune response to COVID-19 vaccination :\n\nPlease select patient’s medical conditions or treatments:\n Active treatment for solid tumor and hematologic malignancies\n Receipt of solid-organ transplant and taking immunosuppressive therapy\n Receipt of chimeric antigen receptor (CAR)-T-cell or hematopoietic stem cell transplant\n Moderate or severe primary immunodeficiency (e.g., DiGeorge syndrome, Wiskott-Aldrich syndrome)\n Advanced or untreated HIV infection\n Active treatment with high-dose corticosteroids\n Other ____________________________________________',
      guidelines:
        '☐ HYPERSENSITIVITY/ANAPHYLAXIS MANAGEMENT per Standardized Procedures',
    },
    {
      link: 'https://docs.google.com/forms/d/15do6CcXWg5SLZRRD_-m5VgTRkcyiz0pgCMBMvpVF5oc/viewform?edit_requested=true',
      label: 'Remdesivir (Veklury) Online Referral',
      diagnosis:
        'ICD-10 diagnosis Code: U07.1 COVID-19 (Treatment is for Active Infection Only)',
      provider_reminders:
        '1. Patients must be ≥12 years old and weigh ≥40 kg to receive Remdesivir at Total Infusion INC\r\n2. Required Labs: eGFR, hepatic laboratory, and prothrombin time [fax to (510) 969-5840]\r\n3. Co-administration with chloroquine phosphate or hydroxychloroquine sulfate is not recommended\r\n4. Remdesivir should be initiated as soon as possible after diagnosis of\r\nsymptomatic COVID-19 has been made and within 7 days of symptom onset',
      treatment_criteria:
        'CONTRAINDICATED in patients with a history of clinically significant hypersensitivity reactions to REMDESIVIR or any of its components.\r\n\r\nPREGNANCY:\r\nThere is insufficient human data on the use of REMDESIVIR during pregnancy. REMDESIVIR should be used during pregnancy only if the potential benefit justifies the potential risk for the mother and the fetus.\r\n\r\nLACTATION:\r\nIt is not known whether REMDESIVIR can pass into breast milk.\r\n\r\nNURSING INSTRUCTIONS:\r\n1. CONTACT PROVIDER if: ALT levels >10x ULN or eGFR <30mL/min\r\n2. No routine premedications\r\n3. Monitor for hypersensitivity reaction during and one hour post infusion\r\n5. Flush line with 30mL NS after REMDESIVIR infusion is complete\r\n6. INFUSION REACTION MEDICATIONS per Standardized Procedures\r\n7. IV LINE CARE per Nursing Policy',
    },
  ],
  RPM_Eligible_ICD: [
    'E10.9',
    'E11.9',
    'E03.9',
    'E66.9',
    'E78.00',
    'I10',
    'I25.10',
    'I47.1',
    'E34.9',
    'F17.200',
    'I48.0',
    'I48.2',
    'I49.9',
    'J30.9',
    'J45.20',
    'J45.21',
    'K21.9',
    'K22.70',
    'R00.2',
    'R21',
    'R94.5',
    'E88.81',
    'F41.9',
    'E05.90',
    'K57.90',
    'E53.8',
    'I25.9',
    'F42.9',
    'E71.30',
    'G47.9',
    'R26.81',
    'R55',
    'F43.10',
    'K59.00',
    'F31.9',
    'R25.2',
    'M25.551',
    'M25.552',
    'M06.9',
    'R19.00',
    'U09.9',
  ],
  OPTIONS_EMPLOYMENT: [
    {label: 'Employed Full-time', value: 'Employed Full-time'},
    {label: 'Employed Part-time', value: 'Employed Part-time'},
    {label: 'Unemployed and seeking work', value: 'Unemployed and seeking work'},
    {label: 'Unemployed and not seeking work ', value: 'Unemployed and not seeking work '},
    {label: 'Retired', value: 'Retired'},
    {label: 'Military', value: 'Military'},
    {label: 'Student', value: 'Student'},
  ],
  OPTIONS_ETHNICITY: [
    {label: 'American Indian or Alaska Native', value: 'American Indian or Alaska Native'},
    {label: 'Asian', value: 'Asian'},
    {label: 'Black or African American', value: 'Black or African American'},
    {label: 'Hispanic or Latino', value: 'Hispanic or Latino'},
    {label: 'Native Hawaiian or Other Pacific Islander', value: 'Native Hawaiian or Other Pacific Islander'},
    {label: 'White/Caucasian', value: 'White/Caucasian'},
    {label: 'Other', value: 'Other'},
    {label: 'Two or more races', value: 'Two or more races'},
    {label: 'Do not wish to answer', value: 'Do not wish to answer'},
  ],
  OPTIONS_EDUCATION: [
    {label: 'Less than high school', value: 'Less than high school'},
    {label: 'High school diploma or equivalent', value: 'High school diploma or equivalent'},
    {label: 'Some college, no degree', value: 'Some college, no degree'},
    {label: 'Post secondary non-degree award', value: 'Post secondary non-degree award'},
    {label: 'Associate’s degree', value: 'Associate’s degree'},
    {label: 'Bachelor’s degree', value: 'Bachelor’s degree'},
    {label: 'Master’s degree', value: 'Master’s degree'},
    {label: 'Doctoral or professional degree', value: 'Doctoral or professional degree'}
  ]
}
