import React, {Component, useState, useEffect} from 'react'
import { connect } from 'react-redux'
import GlobalStyles from '../../styles/global.module.scss'
import LoincSearchModal from '../../../components/LoincSearchModal'
import GeneralChart from '../../../components/musculoSkeletal/GeneralChart'

import Styles from "./styles/BodySystems.module.scss";
import Constants from "../../../values";

//import images
import SkeletonIcon from "../../../assets/images/skeleton.png";
import HeartIcon from "../../../assets/images/heart.png";
import LungsIcon from "../../../assets/images/lungs.png";
import PainIcon from '../../../assets/images/pain.png'
import StomachIcon from '../../../assets/images/stomach.png'
import ReproductiveIcon from '../../../assets/images/reproductive.png';
import ImmuneIcon from '../../../assets/images/immune.png';
import EndocrineIcon from '../../../assets/images/kidney2.png'

//import child components
import PatientInfoStrip from "../../../components/PatientInfoStrip";
import Gastrointestinal from './InnerTabs/Gastrointestinal';
import ReproductiveSystem from './InnerTabs/ReproductiveSystem';
import ImmuneSystem from './InnerTabs/ImmuneSystem';
import EndocrineSystem from './InnerTabs/EndocrineSystem';
import NeurologicalSystem from "./InnerTabs/NeurologicalSystem";
import MusculoSkeletalSystem from "./InnerTabs/MusculoskeletalSystem";
import QuestionnaireModal from "../../../components/QuestionnaireModal";
import ChartModal from "../../../components/ChartModal";
import QChartToolTip from "../../../components/QChartToolTip";
import {getQuestionnaireResults} from "../../../components/getQuestionnaireResults";
import {getChartReferenceLines} from "../../../components/getChartReferenceLines";
import {renderQuestionnaireResults} from "../../../components/renderQuestionnaireResults";
import {useSelector} from "react-redux";


import Config from "../../../config";
import moment from "moment";
import {faRunning,faBrain } from "@fortawesome/free-solid-svg-icons";
import {} from '@fortawesome/free-regular-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
  ReferenceLine,
  ReferenceArea,
  AreaChart,
  Label,
  CartesianGrid,
  Area
} from "recharts";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import {
  getDummyData,
  updatePatientData
} from '../../../redux/actions';
import * as Metadata from "../../../metadata";
import Button from "react-bootstrap/Button";
import {ToastContainer, toast} from "react-toastify";


const axios = require('axios');


let GREEN_RANGE_COLOR = '#20A89266'

let DAS_DATA = [
  {
    created_at: '09/01/2020',
    value: 7
  },
  {
    created_at: '09/20/2020',
    value: 5
  },
  {
    created_at: '10/20/2020',
    value: 8
  }
]

let PHYSICAL_DATA = [
  {
    created_at: '09/01/2020',
    value: 7.3
  },
  {
    created_at: '09/28/2020',
    value: 2.4
  },
  {
    created_at: '10/15/2020',
    value: 9.4
  },
  {
    created_at: '11/10/2020',
    value: 6.3
  }
];

let CROHNS_DATA = [
  {
    created_at: '09/05/2020',
    value: 0
  },
  {
    created_at: '10/07/2020',
    value: 14
  },
  {
    created_at: '11/01/2020',
    value:18
  },
  {
    created_at: '11/10/2020',
    value: 11
  }
];

let COLITIS_DATA = [
  {
    created_at: '08/06/2020',
    value: 0
  },
  {
    created_at: '09/07/2020',
    value: 6
  },
  {
    created_at: '10/01/2020',
    value:2
  },
  {
    created_at: '11/05/2020',
    value: 11
  }
];

const ACTIVITIES = {
  1: ["Leave Bed", "Get Dressed", "Phone Call", "Take Pills", "Use Toilet"],
  2: ["Read", "Bathe", "Internet", "Watch TV", "Pet Care"],
  3: ["Cook", "Clean", "Drive", "Public Transit", "Visit Doctor"],
  4: ["Shop", "Work", "Childcare", "Exercise", "Socialize"]
}

const PAIN = {
  "Shoulder Girdle": ["shoulder_girdle_left", "shoulder_girdle_right"],
  "Upper Arm": ["upper_arm_left", "upper_arm_right"],
  "Lower Arm": ["lower_arm_left", "lower_arm_right"],
  "Hip(buttock)": ["hip_left", "hip_right"],
  "Upper Leg": ["upper_leg_left", "upper_leg_right"],
  "Lower Leg": ["lower_leg_left", "lower_leg_right"],
  "Jaw": "jaw",
  "Neck": "neck",
  "Chest": "chest",
  "Abdomen": "abdomen",
  "Upper Back": "upper_back",
  "Lower Back": "lower_back",


}

const JOINTS_SELECT_OPTIONS = [
  {label: "Left Shoulder", value: "left_shoulder"},
  {label: "Right Shoulder", value: "right_shoulder"},
  {label: "Left Elbow", value: "left_elbow"},
  {label: "Right Elbow", value: "right_elbow"},
  {label: "Left Wrist", value: "left_wrist"},
  {label: "Right Wrist", value: "right_wrist"},
  {label: "Left MCP 1", value: "1_MCP_left"},
  {label: "Right MCP 1", value: "1_MCP_right"},
  {label: "Left MCP 2", value: "2_MCP_left"},
  {label: "Right MCP 2", value: "2_MCP_right"},
  {label: "Left MCP 3", value: "3_MCP_left"},
  {label: "Right MCP 3", value: "3_MCP_right"},
  {label: "Left MCP 4", value: "4_MCP_left"},
  {label: "Right MCP 4", value: "4_MCP_right"},
  {label: "Left MCP 5", value: "5_MCP_left"},
  {label: "Right MCP 5", value: "5_MCP_right"},
  {label: "Left PIP 1", value: "1_PIP_left"},
  {label: "Right PIP 1", value: "1_PIP_right"},
  {label: "Left PIP 2", value: "2_PIP_left"},
  {label: "Right PIP 2", value: "2_PIP_right"},
  {label: "Left PIP 3", value: "3_PIP_left"},
  {label: "Right PIP 3", value: "3_PIP_right"},
  {label: "Left PIP 4", value: "4_PIP_left"},
  {label: "Right PIP 4", value: "4_PIP_right"},
  {label: "Left PIP 5", value: "5_PIP_left"},
  {label: "Right PIP 1", value: "5_PIP_right"},
  {label: "Left Toe PIP 1", value: "1_PIP_toe_left"},
  {label: "Right Toe PIP 1", value: "1_PIP_toe_right"},
  {label: "Left Toe PIP 2", value: "2_PIP_toe_left"},
  {label: "Right Toe PIP 2", value: "2_PIP_toe_right"},
  {label: "Left Toe PIP 3", value: "3_PIP_toe_left"},
  {label: "Right Toe PIP 3", value: "3_PIP_toe_right"},
  {label: "Left Toe PIP 4", value: "4_PIP_toe_left"},
  {label: "Right Toe PIP 4", value: "4_PIP_toe_right"},
  {label: "Left Toe PIP 5", value: "5_PIP_toe_left"},
  {label: "Right Toe PIP 1", value: "5_PIP_toe_right"},
  {label: "Left Hip", value: "left_hip"},
  {label: "Right Hip", value: "right_hip"},
  {label: "Left Knee", value: "left_knee"},
  {label: "Right Knee", value: "right_knee"},
  {label: "Left Ankle", value: "left_ankle"},
  {label: "Right Ankle", value: "right_ankle"},
  {label: "Left Chest", value: "left_chest"},
  {label: "Right Chest", value: "right_chest"}
]

const JOINTS = {
  "Shoulder": ["left_shoulder", "right_shoulder"],
  "Elbow": ["left_elbow", "right_elbow"],
  "Wrist": ["left_wrist", "right_wrist"],
  "MCP 1": ["1_MCP_left", "1_MCP_right"],
  "MCP 2": ["2_MCP_left", "2_MCP_right"],
  "MCP 3": ["3_MCP_left", "3_MCP_right"],
  "MCP 4": ["4_MCP_left", "4_MCP_right"],
  "MCP 5": ["5_MCP_left", "5_MCP_right"],
  "PIP 1": ["1_PIP_left", "1_PIP_right"],
  "PIP 2": ["2_PIP_left", "2_PIP_right"],
  "PIP 3": ["3_PIP_left", "3_PIP_right"],
  "PIP 4": ["4_PIP_left", "4_PIP_right"],
  "PIP 5": ["5_PIP_left", "5_PIP_right"],
  "Toe PIP 1": ["1_PIP_toe_left", "1_PIP_toe_right"],
  "Toe PIP 2": ["2_PIP_toe_left", "2_PIP_toe_right"],
  "Toe PIP 3": ["3_PIP_toe_left", "3_PIP_toe_right"],
  "Toe PIP 4": ["4_PIP_toe_left", "4_PIP_toe_right"],
  "Toe PIP 5": ["5_PIP_toe_left", "5_PIP_toe_right"],
  "Hip": ["left_hip", "right_hip"],
  "Knee": ["left_knee", "right_knee"],
  "Ankle": ["left_ankle", "right_ankle"],
  "Chest": ["left_chest", "right_chest"],
}

const BIOMARKER_OPTIONS = [
  {lab: true, label: "Total Cholesterol", unit: "mg/dL", value: 'tc', val: null},
  {lab: true, label: "HDL", unit: "mg/dL", value: 'hdl', val: 45},
  {lab: true, label: "LDL", unit: "mg/dL", value: 'hdl', val: 100},
  {lab: true, label: "Triglycerides", unit: "mg/dL", value: 'triglycerides', val: 150},
  {lab: true, label: "Hs-CRP", unit: "mg/L", value: 'hscrp', val: null},
  {lab: true, label: "Blood Pressure", unit: "mm Hg", value: "bp", val: '120/80'},
  {lab: true, label: "White Blood Cells", unit: "1/μL", value: 'wbc', val: 5000},
  {lab: true, label: "Red Blood Cells", unit: "1/μL", value: 'rbc', val: null},
  {lab: true, label: "Hemoglobin", unit: "g/dL", value: 'hemoglobin', val: 12},
  {lab: true, label: "Hematrocit", unit: "-", value: 'hematrocit', val: null}
]

const RS_BIOMARKER_OPTIONS = [
  {lab: true, label: "Smoking", unit: "cigarettes/day", value: 'smoking', val: null},
  {lab: true, label: "Spo2", unit: "-", value: 'oxygen_saturation', val: '95%'},
  {lab: true, label: "Respiratory Rate", unit: "breaths/min", value: 'respiration_rate', val: 22},
  {lab: true, label: "Arterial Blood pH", unit: "-", value: 'Arterial Blood pH', val: 7.4},
  {lab: true, label: "PtO2", unit: "mm HG", value: 'PtO2', val: 75},
  {lab: true, label: "Bicarbonate", unit: "mEq/L ", value: 'Bicarbonate', val: 25},
  {lab: true, label: "PCO2", unit: "mm HG ", value: 'PCO2', val: 40},
]

const PATIENT_REPORTED_OPTIONS = [
  {chartDomain: [0, 300], lab: true, label: "SAQ-7", unit: "-", val: "40", value: "saq"},
  {chartDomain: [0, 300], lab: true, label: "KCCQ-OS", unit: "-", val: "50", value: "kccq"},
  {chartDomain: [0, 300], lab: true, label: "AF Symptom Severity", unit: "-", val: "80", value: "ass"},
]

const PATIENT_REPORTED_DATA = [
  {progress: [{value: 70, date: "2021-01-19T00:00:00Z"}], snapshot: 40},
  {progress: [{value: 70, date: "2021-01-19T00:00:00Z"}], snapshot: 50},
  {progress: [{value: 70, date: "2021-01-19T00:00:00Z"}], snapshot: 80},
]

const RS_PATIENT_REPORTED_OPTIONS = [
  {chartDomain: [0, 300], lab: true, label: "Shortness of Breath", unit: "severe", val: "10%", value: "sob"},
  {chartDomain: [0, 300], lab: true, label: "Asthma Severity", unit: "severe", val: "15%", value: "as"},
  {chartDomain: [0, 300], lab: true, label: "COPD Assessment", unit: "points", val: "10", value: "copd-a"},
]

const RS_PATIENT_REPORTED_DATA = [
  {progress: [{value: "10%", date: "2021-01-19T00:00:00Z"}], snapshot: "10%"},
  {progress: [{value: "15%", date: "2021-01-19T00:00:00Z"}], snapshot: "15%"},
  {progress: [{value: 10, date: "2021-01-19T00:00:00Z"}], snapshot: 10},
]

let Cardiovascular_radioData = [
  {key: 1, name: "Blood Pressure", checked: 0},
  {key: 2, name: "Heart Rate"},
  {key: 3, name: "Heart Rate Variability"}
]

let Cardiovascular_GraphType = 1;

let RESPIRATION_GRAPH_Key = 1;

let RESPIRATION_GRAPH_DATA = {
  PPG: [
    {amplitude: -1000, sec: 0, unit: "Amplitude"},
    {amplitude: -500, sec: 1, unit: "Amplitude"},
    {amplitude: 0, sec: 2, unit: "Amplitude"},
    {amplitude: 500, sec: 3, unit: "Amplitude"},
    {amplitude: 1000, sec: 4, unit: "Amplitude"},
    {amplitude: 1000, sec: 5, unit: "Amplitude"},
    {amplitude: 1000, sec: 6, unit: "Amplitude"}
  ],
  Accelerometer: [
    {metres: -10, sec: 0, unit: "metres"},
    {metres: -5, sec: 1, unit: "metres"},
    {metres: 0, sec: 2, unit: "metres"},
    {metres: 5, sec: 3, unit: "metres"},
    {metres: 10, sec: 4, unit: "metres"},
    {metres: 100, sec: 5, unit: "metres"},
    {metres: 100, sec: 6, unit: "metres"}
  ],
}

const GRAPHTEMPDATASP =[
  {time: 0, volume: 0, },
  {time: 1, volume: 4.2, },
  {time: 2, volume: 4.3, },
  {time: 3, volume: 4.3, },
  {time: 4, volume: 4.3, },
  {time: 5, volume: 4.3, },
  {time: 6, volume: 4.3, }
]

let Respiration_radioData = [
  {key: 1, name: "PPG", checked: 0},
  {key: 2, name: "Accelerometer"}
]

const ecgData = [
  {name: 'Page 1', uv: 30, pv: 10, amt: 50},
  {name: 'Page 2', uv: 29, pv: 10, amt: 50},
  {name: 'Page 3', uv: 30, pv: 10, amt: 50},
  {name: 'Page 4', uv: 31, pv: 11, amt: 52},
  {name: 'Page 5', uv: 32, pv: 10, amt: 50},
  {name: 'Page 6', uv: 30, pv: 10, amt: 50},
  {name: 'Page 7', uv: 38, pv: 10, amt: 51},
  {name: 'Page 8', uv: 30, pv: 12, amt: 50},
  {name: 'Page 9', uv: 30, pv: 10, amt: 50},
  {name: 'Page 10', uv: 32, pv: 10, amt: 48},
  {name: 'Page 11', uv: 30, pv: 10, amt: 50},
  {name: 'Page 12', uv: 30, pv: 9, amt: 50},
  {name: 'Page 13', uv: 31, pv: 10, amt: 51},
  {name: 'Page 14', uv: 30, pv: 10, amt: 50},
  {name: 'Page 15', uv: 29, pv: 10, amt: 50},
  {name: 'Page 16', uv: 30, pv: 11, amt: 48},
  {name: 'Page 17', uv: 28, pv: 10, amt: 50},
  {name: 'Page 18', uv: 30, pv: 10, amt: 50},
  {name: 'Page 19', uv: 30, pv: 9, amt: 51},
  {name: 'Page 20', uv: 36, pv: 10, amt: 50},
  {name: 'Page 21', uv: 30, pv: 10, amt: 50},
  {name: 'Page 22', uv: 31, pv: 10, amt: 50},
  {name: 'Page 23', uv: 30, pv: 10, amt: 53},
  {name: 'Page 24', uv: 29, pv: 10, amt: 50},
  {name: 'Page 25', uv: 30, pv: 11, amt: 50},
  {name: 'Page 26', uv: 31, pv: 10, amt: 50},
  {name: 'Page 27', uv: 30, pv: 10, amt: 51},
  {name: 'Page 28', uv: 30, pv: 10, amt: 50},
  {name: 'Page 29', uv: 32, pv: 10, amt: 50},
  {name: 'Page 30', uv: 30, pv: 10, amt: 49},
  {name: 'Page 31', uv: 33, pv: 9, amt: 50},
  {name: 'Page 32', uv: 30, pv: 10, amt: 50},
  {name: 'Page 33', uv: 31, pv: 10, amt: 50},
  {name: 'Page 34', uv: 30, pv: 10, amt: 50},
  {name: 'Page 35', uv: 28, pv: 10, amt: 51},
  {name: 'Page 36', uv: 30, pv: 10, amt: 50},
  {name: 'Page 37', uv: 34, pv: 10, amt: 50},
  {name: 'Page 38', uv: 30, pv: 11, amt: 48},
  {name: 'Page 39', uv: 31, pv: 10, amt: 50},
  {name: 'Page 40', uv: 30, pv: 10, amt: 50},
  {name: 'Page 41', uv: 30, pv: 10, amt: 50},
  {name: 'Page 42', uv: 31, pv: 10, amt: 52},
  {name: 'Page 43', uv: 30, pv: 10, amt: 50},
  {name: 'Page 45', uv: 30, pv: 9, amt: 50},
  {name: 'Page 46', uv: 29, pv: 10, amt: 50},
  {name: 'Page 47', uv: 30, pv: 10, amt: 51},
  {name: 'Page 48', uv: 31, pv: 10, amt: 50},
  {name: 'Page 49', uv: 30, pv: 10, amt: 58},
  {name: 'Page 50', uv: 30, pv: 12, amt: 50},
  {name: 'Page 51', uv: 36, pv: 10, amt: 50},
  {name: 'Page 52', uv: 31, pv: 8, amt: 49},
  {name: 'Page 53', uv: 30, pv: 10, amt: 50},
  {name: 'Page 54', uv: 30, pv: 10, amt: 54},
  {name: 'Page 55', uv: 28, pv: 13, amt: 50},
  {name: 'Page 57', uv: 30, pv: 10, amt: 50},
  {name: 'Page 58', uv: 30, pv: 10, amt: 50},
  {name: 'Page 59', uv: 32, pv: 12, amt: 48},
  {name: 'Page 60', uv: 30, pv: 10, amt: 50},
  {name: 'Page 61', uv: 30, pv: 10, amt: 53},
  {name: 'Page 62', uv: 33, pv: 10, amt: 50},
  {name: 'Page 63', uv: 30, pv: 10, amt: 50},
  {name: 'Page 64', uv: 29, pv: 8, amt: 52},
];

let selectArray = [
  { name:'Year', value: 1, id: '1'},
  { name:'Month', value: 2, id: '2' },
  { name:'Day', value: 3, id: '3' }
]

let selectValue = 1;

let setSelectValue = 'Year'


class BodySystems extends Component {
  constructor() {
    super()
    this.state = {
      loading: true,
      jointsData: null,
      tab: 'musculo_skeletal',
      jointsCount: [0,0],
      loadingContent: true,
      chartData: [],
      selectedJoint: {label: 'Left Shoulder', value: 'left_shoulder'},
      painData: null,
      completeGastroData: null,
      completePhysicalData: null,
      dateOptions: null,
      vitalsProgressData: null,
      vitalsData: []
    }
    this.getJointsScreening = this.getJointsScreening.bind(this)
    this.getPainScreening = this.getPainScreening.bind(this)
    this.getGastroScreening = this.getGastroScreening.bind(this)
    this.getPhysicalScreening = this.getPhysicalScreening.bind(this)
    this.getVitalsData = this.getVitalsData.bind(this)
    this.preparePhysicalData = this.preparePhysicalData.bind(this)
    this.updatePreferences = this.updatePreferences.bind(this)
  }

  componentDidMount() {
    if(!this.props.patient) {
      this.props.history.push('/patient/list')
      return
    }

    this.getJointsScreening()
    this.getPhysicalScreening()
    this.getVitalsData()
    this.getPainScreening()
    this.props.getDummyData();
  }

  static getDerivedStateFromProps(props, state) {
    let toReturn = {}
    if(props.patient && props.token)
      toReturn.loading = false

    if(props.patientProgress && !state.vitalsProgressData) {
      toReturn.vitalsLoading = false
      let vitals = props.patientProgress.vitals || []
      vitals.forEach(each => {
        each.date = moment(each.created_at).format('MM/DD/YYYY')
        let sleep = each.sleep
        each.sleep = sleep ? Number(sleep).toFixed(1) : 0
        each.steps = each.steps ? Math.round(each.steps) : 0
      })
      let newVitals = vitals.slice().reverse();
      toReturn.vitalsProgressData = newVitals
    }
    return toReturn
  }

  updatePreferences(data) {
    let {token, adminId, patientCore} = this.props
    data['doctor_id'] = adminId
    data['patient_id'] = patientCore.firebase_id
    this.props.updatePatientData(data, token)
    toast.success('Preferences saved for this patient')
  }

  getPhysicalScreening() {
    let {patient, token} = this.props

    //get joints screening data from backend
    let url = Config.BACKEND_URL + 'doctor/patient/screenings/physical'
    axios({
      method: 'get',
      url: url,
      headers: {
        x_firebase_id: patient.uid,
        Authorization: 'JWT ' + token
      },
    }).then(({ data }) => {
      data.physical.reverse()
      this.preparePhysicalData(data)
    }).catch(err => {
      console.log('error when getting pain screening data', err)
    })
  }

  getVitalsData() {
    let {patient, token} = this.props

    //get joints screening data from backend
    let url = Config.BACKEND_URL + 'user/vitals'
    axios({
      method: 'get',
      url: url,
      headers: {
        x_firebase_id: patient.uid,
        Authorization: 'JWT ' + token,
        complete: true
      },
    }).then(({ data }) => {
      this.setState({
        vitalsData: data.reverse()
      })
    }).catch(err => {
      console.log('error when getting pain screening data', err)
    })
  }

  preparePhysicalData(data) {
    let physical = data.physical
    let activities = data.activities
    physical.forEach(screening => {
      screening.date = moment(screening.created_at).format('D-MMM')
      let score = screening.haqScore || 0
      screening.haqScore = Number(score.toFixed(2))
    })

    let dateOptions = []
    activities.forEach(screening => {
      let formatDate = moment(screening.created_at).format('MMM Do YYYY')
      dateOptions.push({label: formatDate, value: screening.created_at})
    })

    this.setState({
      completePhysicalData: data,
      loadingContent: false,
      dateOptions: dateOptions
    })
  }

  getGastroScreening() {
    let {patient, token} = this.props

    //get joints screening data from backend
    let url = Config.BACKEND_URL + 'doctor/patient/screenings/gastro'
    axios({
      method: 'get',
      url: url,
      headers: {
        x_firebase_id: patient.uid,
        Authorization: 'JWT ' + token
      },
    }).then(({ data }) => {
      console.log('got gastro data', data)
      data.crohns.reverse()
      data.colitis.reverse()
      this.setState({
        completeGastroData: data,
        loadingContent: false
      })
    }).catch(err => {
      console.log('error when getting pain screening data', err)
    })
  }

  getPainScreening() {
    let {patient, token} = this.props

    //get joints screening data from backend
    let url = Config.BACKEND_URL + 'screenings/pain'
    axios({
      method: 'get',
      url: url,
      headers: {
        x_firebase_id: patient.uid,
        Authorization: 'JWT ' + token,
        complete: true
      },
    }).then(({ data }) => {
      let snapshot = data && data.length > 0 ? data[0] : {}
      this.setState({
        painData: snapshot,
        completePainData: data.slice().reverse(),
      })
    }).catch(err => {
      console.log('error when getting pain screening data', err)
    })
  }

  getJointsScreening() {
    let {patient, token} = this.props

    //get joints screening data from backend
    let url = Config.BACKEND_URL + 'screenings/joints'
    axios({
      method: 'get',
      url: url,
      headers: {
        x_firebase_id: patient.uid,
        Authorization: 'JWT ' + token,
        complete: true
      },
    }).then(({ data }) => {
      this.setState({
        jointsData: data && data.length > 0 ? data[0] : {},
        completeJointsData: data,
        loadingContent: this.state.completePhysicalData ? false : true
      })
      this.calculateJointsCount(data)
    }).catch(err => {
      console.log('error when getting joints screening', err)
    })
  }

  calculateJointsCount(jointsData) {
    let chartData = {}
    let jointsCount = [0,0]
    jointsData.forEach((entry,index) => {
      let date = entry.created_at
      Object.keys(entry).forEach(key => {
        if(key !== 'created_at'){
          let tempArray = chartData[key] || []
          tempArray.unshift({
            tenderness: entry[key].tenderness,
            swollen: entry[key].swollen,
            date: moment(date).format('MM/DD/YYYY')
          })
          chartData[key] = tempArray

          //for total joints count
          if(index === 0) {
            if(entry[key].tenderness > 0)
              jointsCount[0] += 1
            if(entry[key].swollen > 0)
              jointsCount[1] += 1
          }
        }
      })
    })

    this.setState({chartData, jointsCount})

  }

  onTabSelected(index) {
    switch(index) {
      case 1:
        this.setState({tab: 'musculo_skeletal'})
        if(!this.state.completePhysicalData) {
          this.setState({loadingContent: true})
          this.getPhysicalScreening()
        }
        if(!this.state.painData) {
          this.setState({loadingContent: true})
          this.getPainScreening()
        }
        break;
      // case '12':
      //   this.setState({tab: 'joints'})
      //   break
      // case '123':
      //   if(!this.state.painData) {
      //     this.setState({loadingContent: true})
      //     this.getPainScreening()
      //   }
      //   this.setState({tab: 'pain'})
      //   break
      // case '123123':
      //   if(!this.state.completePhysicalData) {
      //     this.setState({loadingContent: true})
      //     this.getPhysicalScreening()
      //   }
      //   this.setState({tab: 'physical'})
      //   break
      case 5:
        this.setState({tab: 'cardiovascular'})
        break
      case 6:
        this.setState({tab: 'respiratory'})
        break
      case 8:
        this.setState({tab: 'gastrointestinal'})
        if(!this.state.completeGastroData) {
          this.setState({loadingContent: true})
          this.getGastroScreening()
        }
        break
      case 9:
        this.setState({tab: 'reproductive'})
        break
      case 10:
        this.setState({tab: 'immune'})
        break
      case 11:
        this.setState({tab: 'endocrine'})
        break
      case 12:
        this.setState({tab: 'neurological'})
        break
      default:
        this.setState({tab: 'joints'})
    }
  }

  renderPainTableRow() {
    let {painData} = this.state
    let singleKeys = ["Neck", "Chest", "Abdomen", "Upper Back", "Lower Back", "Jaw"]
    let toReturn = []
    Object.keys(PAIN).forEach(part => {
      let keys = PAIN[part]
      let leftAndRight = !singleKeys.includes(part)
      let types = []
      let leftValue,rightValue,oneValue
      if(leftAndRight) {
        let leftTypes = painData[keys[0]] && painData[keys[0]].type
        let rightTypes = painData[keys[1]] && painData[keys[1]].type
        leftValue = painData[keys[0]] && painData[keys[0]].value
        rightValue = painData[keys[1]] && painData[keys[1]].value

        leftTypes && leftTypes.forEach((each,index) => {
          types.push(
            <span>
              {each}{index === leftTypes.length-1 ? (rightTypes.length > 1 ? ',':'') : ','}
            </span>
          )
        })
        rightTypes && rightTypes.forEach((each,index) => {
          types.push(
            <span>
              {each}{index === rightTypes.length-1 ? '' : ','}
            </span>
          )
        })
      } else {
        let typesArray = painData[keys] ? painData[keys].type : []
        oneValue = painData && painData[keys] && painData[keys].value
        types =  typesArray.map((each,index) => (
          <span>
            {each}{index === typesArray.length-1 ? '' : ','}
          </span>
        ))
      }

      toReturn.push(
        <tr>
          <td className={Styles.td}>{part}</td>
          <td
            className={Styles.td} colSpan={leftAndRight ? "1" : "2"}>{leftAndRight ? (leftValue || '') : (oneValue || '')}</td>
          {
            leftAndRight &&
            <td
              className={Styles.td}>{rightValue || ''}</td>
          }
          <td className={Styles.td}>{types}</td>
        </tr>
      )
    })

    return toReturn
  }

  renderJointsTableRow() {
    let {jointsData} =  this.state
    if(!jointsData)
      return null

    let toReturn = []

    Object.keys(JOINTS).forEach(joint => {
      let keys = JOINTS[joint]
      let leftTenderness =  jointsData[keys[0]] && jointsData[keys[0]].tenderness
      let leftSwollen = jointsData[keys[0]] && jointsData[keys[0]].swollen
      let rightTenderness = jointsData[keys[1]] && jointsData[keys[1]].tenderness
      let rightSwollen = jointsData[keys[1]] && jointsData[keys[1]].swollen

      toReturn.push(
        <tr>
          <td className={Styles.td}>{joint}</td>
          <td className={Styles.td}>{leftTenderness || ''}</td>
          <td className={Styles.td}>{leftSwollen || ''}</td>
          <td className={Styles.td}>{rightTenderness || ''}</td>
          <td className={Styles.td}>{rightSwollen || ''}</td>
        </tr>
      )
    })

    return toReturn
  }

  renderJointsTable() {
    return (
      <table style={{width: '100%'}}>
        <thead>
        <tr>
          <th></th>
          <th colSpan={"2"}>Left Side</th>
          <th colSpan={"2"}>Right Side</th>
        </tr>
        <tr>
          <th>Joint</th>
          <th>Tender</th>
          <th>Swollen</th>
          <th>Tender</th>
          <th>Swollen</th>
        </tr>
        </thead>
        <tbody>
        {this.renderJointsTableRow()}
        </tbody>
      </table>
    )
  }

  renderPainTable() {
    return(
      <table style={{width: '100%'}}>
        <thead>
        <tr>
          <th>Body Area</th>
          <th>Left</th>
          <th>Right</th>
          <th>Pain Type</th>
        </tr>
        </thead>
        <tbody>
        {this.renderPainTableRow()}
        </tbody>
      </table>
    )
  }

  renderJointsData() {
    let {jointsData, progressData, loadingContent} = this.state

    if(loadingContent) {
      return (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100%', height: 200}}>
          <div className={GlobalStyles.loader} />
        </div>
      )
    }

    return (
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', margin: '0 auto'}}>
        {jointsData &&
          <div style={{width: '100%'}}>
            <h4 className={Styles.sectionHeader}>Joint Pain</h4>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>

              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '50%'}}>
                <div className={Styles.chartWrapper} style={{marginTop: 80,  display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <p style={{fontWeight: 'bold', width: '100%', textAlign: 'center', fontSize: 17, color: Constants.primaryTheme}}>RA Progress</p>
                  {this.renderDASChart()}
                </div>

                <div className={Styles.chartWrapper} style={{marginTop: 60, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <p style={{fontWeight: 'bold', width: '100%', textAlign: 'center', fontSize: 17, color: Constants.primaryTheme}}>Joint Pain Progress</p>
                  <div style={{width: '90%',marginTop: 8, marginBottom: 8}}>
                    <Select
                      isSearchable={true}
                      placeholder={'Select joint...'}
                      isMulti={false}
                      options={JOINTS_SELECT_OPTIONS}
                      value={this.state.selectedJoint}
                      onChange={(val) => this.setState({selectedJoint: val})}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: '#20A89233',
                          primary: '#20A892',
                        },
                      })}
                    />
                  </div>
                  {this.renderJointsChart()}
                </div>
              </div>

              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '50%', padding: '0px 10px'}}>
                <p style={{width: '100%', textAlign: 'center'}}>Screening taken on: <span className={Styles.value}>{moment(jointsData.created_at).format('MMM Do YYYY')}</span></p>
                <div style={{width: '100%', marginTop: 10}}>
                  <p>Tender Joint Count: <span className={Styles.value}>{this.state.jointsCount[0]}</span></p>
                  <p>Swollen Joint Count: <span className={Styles.value}>{this.state.jointsCount[1]}</span></p>
                </div>
                {this.renderJointsTable()}
              </div>
            </div>
          </div>

        }
      </div>
    )
  }

  renderDASChart() {
    let graphWidth = (window.innerWidth) / 3.1

    return (
      <LineChart width={graphWidth} height={350} data={DAS_DATA} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
        <Legend verticalAlign="top"/>
        <Line dot={false} name={'DAS'}  type="monotone" dataKey="value" stroke={Constants.graphLineOne} />
        <XAxis dataKey="created_at" tickFormatter={(tickItem) => {
          return moment(tickItem, "MM/DD/YYYY").format('D-MMM')
        }}/>
        <YAxis domain={[0,10]}/>
        <ReferenceLine x={"09/20/2020"} label="Humira Started" stroke="gray" strokeDasharray="3 3" />
        <Tooltip />
      </LineChart>
    )
  }

  renderJointsChart() {
    let graphWidth = (window.innerWidth) / 3.1
    let {selectedJoint, chartData} = this.state
    let currentChartData = []
    if(selectedJoint) {
      currentChartData = chartData[selectedJoint.value] //this is constructed after the GET call
    }

    return (
      <LineChart width={graphWidth} height={350} data={currentChartData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
        <Legend verticalAlign="top"/>
        <Line dot={false} name={'Tenderness'}  type="monotone" dataKey="tenderness" stroke={Constants.graphLineOne} />
        <Line dot={false} name={'Swollen'}  type="monotone" dataKey="swollen" stroke={Constants.graphLineTwo} />
        <XAxis dataKey="date" tickFormatter={(tickItem) => {
          return moment(tickItem, "MM/DD/YYYY").format('D-MMM')
        }}/>
        <YAxis domain={[0,10]}/>

        {/*<ReferenceLine x={"09/20/2020"} label="Humira Started" stroke="gray" strokeDasharray="3 3" />*/}

        <Tooltip />
      </LineChart>
    )
  }

  renderPainChart() {
    let graphWidth = (window.innerWidth) / 3.1
    let {completePainData} = this.state

    return (
      <LineChart width={graphWidth} height={350} data={completePainData || []} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
        <Legend verticalAlign="top"/>
        <Line dot={false} name={'Pain Index'}  type="monotone" dataKey="painIndex" stroke={Constants.graphLineOne} />
        <XAxis dataKey="created_at" tickFormatter={(tickItem) => {
          return moment(tickItem).format('D-MMM')
        }}/>
        <YAxis domain={[0, 20]}/>
        <Tooltip content={({ active, payload, label }) => {
          let date = moment(label).format('D-MMM')
          return (
            <div className={Styles.painChartTooltip}>
              <p>{date}</p>
              <p style={{fontWeight: 'bold'}}>Pain Index: <span style={{fontWeight: 'normal', marginLeft: 5}}>{payload && payload.length > 0 && payload[0].value}</span></p>
            </div>
          )
        }}/>
      </LineChart>
    )
  }

  renderPhysicalData() {
    let {loadingContent} = this.state
    if(loadingContent) {
      return (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100%', height: 200}}>
          <div className={GlobalStyles.loader} />
        </div>
      )
    }

    return(
      <Physical screeningData={this.state.completePhysicalData} dateOptions={this.state.dateOptions}/>
    )

  }

  onRadioClick = (index) =>{
    this.setState({radioIndex: index})
  }

  onChangeSelect = (value) =>{
    this.setState({selectValue: value})
  }

  onChangeECGSelect = (value) =>{
    this.setState({selectECGValue: value})
  }

  onResRadioClick = (index) =>{
    this.setState({radioRespIndex: index})
  }

  onChangeAutoMarker = (value) =>{
    this.setState({autoimmuneMarker: value})
  }

  onChangeGeneticMarker = (value) =>{
    this.setState({geneticMarker: value})
  }

  renderCardiovascularData() {
    let {loadingContent} = this.state
    if(loadingContent) {
      return (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100%', height: 200}}>
          <div className={GlobalStyles.loader} />
        </div>
      )
    }

    return(
      <Cardiovascular
        screeningData={this.state.completeCardiovascularHealthData}
        dateOptions={this.state.dateOptions}
        vitalsProgressData={this.state.vitalsProgressData}
        renderDASChart={this.renderDASChart}
        healthData={this.props.healthData}
        onRadioClick={this.onRadioClick}
        onChangeSelect={this.onChangeSelect}
        updatePreferences={this.updatePreferences}
        patient={this.props.patient}
        vitalData={this.state.vitalsData}
      />
    )

  }

  renderRespiratoryData() {
    let {loadingContent} = this.state
  console.log('vaitals Data: ', this.state.vitalsData)
    if(loadingContent) {
      return (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100%', height: 200}}>
          <div className={GlobalStyles.loader} />
        </div>
      )
    }

    return(
      <Respiratory
        updatePreferences={this.updatePreferences}
        patient={this.props.patient}
        screeningData={this.state.completeRespiratorySystemData}
        dateOptions={this.state.dateOptions}
        onResRadioClick={this.onResRadioClick}
        vitalData={this.state.vitalsData}
      />
    )

  }


  renderGastroIntestinal() {
    let {loadingContent} = this.state
    if(loadingContent) {
      return (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100%', height: 200}}>
          <div className={GlobalStyles.loader} />
        </div>
      )
    }

    return(
      <Gastrointestinal
        updatePreferences={this.updatePreferences}
        patient={this.props.patient}
        gastroData={this.state.completeGastroData}
      />
    )

  }

  renderReproductive() {
    let {loadingContent} = this.state
    if(loadingContent) {
      return (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100%', height: 200}}>
          <div className={GlobalStyles.loader} />
        </div>
      )
    }

    return(
      <ReproductiveSystem
        screeningData={this.state.completeRespiratorySystemSecond}
        dateOptions={this.state.dateOptions}
        onResRadioClick={this.onResRadioClick}
        onChangeSelect={this.onChangeSelect}
        updatePreferences={this.updatePreferences}
        patient={this.props.patient}
      />
    )

  }

  renderMusculoSkeletalData() {
    let {loadingContent} = this.state
    if(loadingContent) {
      return (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100%', height: 200}}>
          <div className={GlobalStyles.loader} />
        </div>
      )
    }

    return(
      <div>
        <MusculoSkeletalSystem
          updatePreferences={this.updatePreferences}
          patient={this.props.patient}
        />

        <Physical screeningData={this.state.completePhysicalData} dateOptions={this.state.dateOptions}/>
      </div>
    )
  }

  renderNeurological() {
    let {loadingContent} = this.state
    if(loadingContent) {
      return (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100%', height: 200}}>
          <div className={GlobalStyles.loader} />
        </div>
      )
    }

    return(
      <NeurologicalSystem
        updatePreferences={this.updatePreferences}
        patient={this.props.patient}
      />
    )
  }

  renderEndocrine() {
    let {loadingContent} = this.state
    if(loadingContent) {
      return (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100%', height: 200}}>
          <div className={GlobalStyles.loader} />
        </div>
      )
    }

    return(
      <EndocrineSystem
        updatePreferences={this.updatePreferences}
        patient={this.props.patient}
        vitalData={this.state.vitalsData}
      />
    )
  }

  renderImmune() {
    let {loadingContent} = this.state
    if(loadingContent) {
      return (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100%', height: 200}}>
          <div className={GlobalStyles.loader} />
        </div>
      )
    }

    return(
      <ImmuneSystem
        screeningData={this.state.completeRespiratorySystemSecond}
        dateOptions={this.state.dateOptions}
        onResRadioClick={this.onResRadioClick}
        onChangeAutoMarker={this.onChangeAutoMarker}
        onChangeGeneticMarker={this.onChangeGeneticMarker}
        onChangeSelect={this.onChangeSelect}
        updatePreferences={this.updatePreferences}
        patient={this.props.patient}
      />
    )

  }


  renderGastroData() {
    let {loadingContent} = this.state
    if(loadingContent) {
      return (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100%', height: 200}}>
          <div className={GlobalStyles.loader} />
        </div>
      )
    }

    return (
      <Gastro gastroData={this.state.completeGastroData}/>
    )
  }

  renderPainData() {
    let {loadingContent, painData, completePainData} = this.state

    if(loadingContent) {
      return (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100%', height: 200}}>
          <div className={GlobalStyles.loader} />
        </div>
      )
    }

    return (
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '90%', margin: '0 auto'}}>
        <div style={{width: '100%'}}>
          <h4 className={Styles.sectionHeader}>Widespread Pain Index</h4>

          <div style={{display: 'flex', flexDirection: 'row', marginTop: 20}}>
            <div style={{width: '50%'}}>
              <div className={Styles.chartWrapper} style={{marginTop: 50}}>
                <h5 style={{color: Constants.primaryTheme}}>Pain Progress</h5>
                {this.renderPainChart()}
              </div>
            </div>

            <div style={{width: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <p >Screening taken on:
                {
                  completePainData && completePainData.length > 0 &&
                  <span className={Styles.value}>{moment(completePainData[completePainData.length - 1].created_at).format('MMM Do YYYY')}</span>
                }

              </p>
              <p>Widespread Pain Index: <span className={Styles.value}>{painData && painData.painIndex}</span></p>
              {this.renderPainTable()}
            </div>

          </div>
        </div>
      </div>
    )
  }

  renderContent() {
    let {tab} = this.state
    switch(tab) {
      case 'musculo_skeletal':
        return this.renderMusculoSkeletalData()
      // case 'joints':
      //   return this.renderJointsData()
      // case 'pain':
      //   return this.renderPainData()
      case 'gastro':
        return this.renderGastroData()
      // case 'physical':
      //   return this.renderPhysicalData()
      case 'cardiovascular':
        return this.renderCardiovascularData()
      case 'respiratory':
        return this.renderRespiratoryData()
      case 'gastrointestinal':
        return this.renderGastroIntestinal()
      case 'reproductive':
        return this.renderReproductive()
      case 'immune':
        return this.renderImmune()
      case 'endocrine':
        return this.renderEndocrine()
      case 'neurological':
        return this.renderNeurological()
      default:
        return null
    }
  }

  render() {
    return (
      <div className={GlobalStyles.container} style={{overflow: 'hidden', margin: '0 auto'}}>
        {
          this.state.loading ?
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100%', height: 200}}>
              <div className={GlobalStyles.loader} />
            </div>
            :
            <div>
              <PatientInfoStrip
                medications={this.props.medications}
                conditions={this.props.conditions}
                patient={this.props.patient}
                patientCore={this.props.patientCore}
                color={this.props.color}
                cohorts={this.props.mongoUser ? this.props.mongoUser.cohorts : []}/>

              <div className={GlobalStyles.contentWrapper}>
                <Tabs onTabSelected={this.onTabSelected.bind(this)}/>
                {this.renderContent()}
              </div>

            </div>
        }
      </div>
    )
  }
}

const renderNewLabValue = (option) => {
  return (
    <div
      // onClick={() => this.setState({modalIsOpen: true, modalOption: option, modalProgress: data.progress})}
      data-tip
      data-for={option.label}
      key={option.label}
      style={{width: 180, marginRight: 10, marginLeft: 10, height: 120, alignItems: 'center', justifyContent: 'end', display: 'flex', flexDirection: 'column', cursor: 'pointer', marginBottom: 10}}>
      <div>
        {
          option.val ?
            <p style={{fontWeight: 'bold', fontSize: 16}}>{option.val}</p>
            :
            <p style={{fontWeight: 'bold', fontSize: 16}}>-</p>
        }
        <p style={{fontSize: 12}}>{option.EXAMPLE_UCUM_UNITS || option.EXAMPLE_UNITS}</p>
      </div>
      <div style={{width: '90%', backgroundColor: option.val ? GREEN_RANGE_COLOR : Constants.lightGray, display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '4px 2px', borderRadius: 8, marginTop: 15}}>
        <p style={{textAlign: 'center'}}>{option.SHORTNAME}</p>
      </div>
      {/* {this.getTooltip(option, data.progress)} */}
      <ChartTooltip option={option}/>


    </div>
  )
}

const Cardiovascular = (props) =>{
  let {screeningData, dateOptions, renderDASChart, healthData, onRadioClick, onChangeSelect, vitalData} = props
  console.log('Health Data: ', healthData)
  const [showSearchModal, setShowSearchModal] = useState(false)
  const [selectedLabs, setSelectedLabs] = useState([])
  const [checked, setChecked] = useState(false)
  const token = useSelector(state => state.authReducer.token)
	const patient = useSelector(state => state.patientReducer.patient)
  const [selectedChart, setSelectedChart] = useState(1);
  const [showQuestionnaireModal, setShowQuestionnaireModal] = useState(false)
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState([])
  const [questionnaireChecked, setQuestionnaireChecked] = useState(false)
  const [anxietyScreening, setAnxietyScreening] = useState(null)
  const [mentalHealthScreening, setMentalHealthScreening] = useState(null)
  const [crohnsScreening, setCrohnsScreening] = useState(null)
  const [colitisScreening, setColitisScreening] = useState(null)
  const [physicalScreening, setPhysicalScreening] = useState(null)
  const [burnoutScreening, setBurnoutScreening] = useState(null)
  const [aceScreening, setAceScreening] = useState(null)
  const [sleepapneaScreening, setSleepapneaScreening] = useState(null)
  const [globalhealthScreening, setGlobalhealthScreening] = useState(null)
  const [showChartModal, setShowChartModal] = useState(false)
  const [modalChartData, setModalChartData] = useState({data: [], path: '', name: ''})
  const patientReportedOptions = PATIENT_REPORTED_OPTIONS;
  const patientReportedData = PATIENT_REPORTED_DATA;

  let graphWidth = (window.innerWidth) / 2
  let chartData = healthData || []
  let lines = []
  let tempData = [];
  let subtitleName = 'mm Hg';
  let stockName = 'BloodPressure';

  let graphTempData = [];

  if(Cardiovascular_GraphType == 1){
    chartData.BloodPressure.forEach((item)=>{
      graphTempData.push({created_at: moment(item.created_at).format('MM/DD'), BloodPressure: item.value})
      subtitleName = item.unit
      stockName = 'BloodPressure';
    })
  }else if(Cardiovascular_GraphType == 2){
    chartData.HeartRate.forEach((item)=>{
      graphTempData.push({created_at: moment(item.created_at).format('MM/DD'), HeartRate: item.value})
      subtitleName = item.unit
      stockName = 'HeartRate';
    })
  }else if(Cardiovascular_GraphType == 3){
    console.log('here', chartData.HeartRateVariability)
    chartData.HeartRateVariabilityOld.forEach((item)=>{
      graphTempData.push({created_at: moment(item.created_at).format('MM/DD'), HeartRateVariability: item.value})
      subtitleName = item.unit
      stockName = 'HeartRateVariability';
    })
  }else if(Cardiovascular_GraphType == 4){
    chartData.HDL.forEach((item)=>{
      graphTempData.push({created_at: moment(item.created_at).format('MM/DD'), HDL: item.value})
      subtitleName = item.unit
      stockName = 'HDL';
    })
  }else if(Cardiovascular_GraphType == 5){
    chartData.TotalCholesterol.forEach((item)=>{
      graphTempData.push({created_at: moment(item.created_at).format('MM/DD'), TotalCholesterol: item.value})
      subtitleName = item.unit
      stockName = 'TotalCholesterol';
    })
  }else{
    chartData.Triglycerided.forEach((item)=>{
      graphTempData.push({created_at: moment(item.created_at).format('MM/DD'), Triglycerided: item.value})
      subtitleName = item.unit
      stockName = 'Triglycerided';
    })
  }

  lines.push(
    <Line
      dot={false}
      name={'Steps'}
      type="monotone"
      dataKey="steps"
      stroke={'#282828'}/>)

  useEffect(() => {
    let patient = props.patient
    if(patient?.body_systems_preferences?.cardio?.length > 0) {
      setSelectedLabs(patient.body_systems_preferences.cardio)
      setChecked(true)
    } else {
      setChecked(false)
    }

  },[props.patient])

  const onLabsSelected = (entries) => {
    setSelectedLabs(entries || [])
    setShowSearchModal(false)
    if(checked) {
      props.updatePreferences({
        'body_systems_preferences.cardio': entries || []
      })
    }
  }

  const onChecked = (e) => {
    let data
    if(e.target.checked) {
      //save the preferences
      data = {
        'body_systems_preferences.cardio': selectedLabs || []
      }
      // save prevf for questionnaire
      onQuestionnaireChecked(true)
    } else {
      data = {
        'body_systems_preferences.cardio': []
      }
      onQuestionnaireChecked(false)
    }
    setChecked(e.target.checked)
    props.updatePreferences(data)
  }

  useEffect(() => {
    let patient = props.patient
    if(patient?.questionnaire_preferences?.cardio?.length > 0) {
      setSelectedQuestionnaire(patient.questionnaire_preferences.cardio)
      setQuestionnaireChecked(true)
    } else {
      setQuestionnaireChecked(false)
    }

  },[props.patient])

  useEffect(() => {
    getAllScreeners()
  }, [selectedQuestionnaire])

  const getAllScreeners = () => {
    selectedQuestionnaire && selectedQuestionnaire.forEach((screener) => {
      getScreening(screener.path)
    })
  }

  const getScreening = (path) => {
    const url = Config.BACKEND_URL + 'screenings/' + path
    axios({
      method: 'get',
      url,
      headers: { Authorization: `JWT ${token}`, x_firebase_id: props.patient.patient_id, complete: true },
    })
      .then(({ data }) => {
        setData(path, data)
      })
      .catch((err) => {
        console.log('error when getting screening', err)
      })
  }

  const setData = (path, data) => {
    switch (path) {
      case 'anxiety':
        setAnxietyScreening(data)
        break
      case 'mental':
        setMentalHealthScreening(data)
        break
      case 'gastro':
        setCrohnsScreening(data)
        break
      case 'colitis':
        setColitisScreening(data)
        break
      case 'physical':
        setPhysicalScreening(data)
        break
      case 'burnout':
        setBurnoutScreening(data)
        break
      case 'ace':
        setAceScreening(data)
        break
      case 'sleepapnea':
        setSleepapneaScreening(data)
        break
      case 'globalhealth':
        setGlobalhealthScreening(data)
        break
      default:
    }
  }

  const getScreeningData = (path) => {
    switch (path) {
      case 'anxiety':
        return anxietyScreening
      case 'mental':
        return mentalHealthScreening
      case 'gastro':
        return crohnsScreening
      case 'colitis':
        return colitisScreening
      case 'physical':
        return physicalScreening
      case 'burnout':
        return burnoutScreening
      case 'ace':
        return aceScreening
      case 'sleepapnea':
        return sleepapneaScreening
      case 'globalhealth':
        return globalhealthScreening
      default:
        return null
    }
  }

  const hideChartModal = () => {
    setShowChartModal(false)
  }

  const onQuestionnaireSelected = (entries) => {
    setSelectedQuestionnaire(entries || [])
    setShowQuestionnaireModal(false)
    if(checked) {
      props.updatePreferences({
        'questionnaire_preferences.cardio': entries || []
      })
    }
  }

  const onQuestionnaireChecked = (isChecked) => {
    let data
    if(isChecked) {
      //save the preferences
      data = {
        'questionnaire_preferences.cardio': selectedQuestionnaire || []
      }
    } else {
      data = {
        'questionnaire_preferences.cardio': []
      }
    }
    props.updatePreferences(data)
  }

  function showBpLine() {
    let toReturn = []
    // toReturn.push (
    //   <Line 
    //     dot={false} 
    //     name={'Upper'}  
    //     type="monotone" 
    //     dataKey={'blood_pressure.upper'}
    //     stroke={Constants.graphLineOne} 
    //   />
    // )
    toReturn.push (
      <Line 
        dot={false} 
        name={'Lower'}  
        type="monotone" 
        dataKey={'blood_pressure.lower'}
        stroke={Constants.graphLineTwo} 
      />
    )
    return toReturn
  }

  function extraToolTip (payload) {
    let toReturn = []
    toReturn.push (
      <p style={{fontWeight: 'bold', textAlign: 'left'}}>{payload[1]?.name}: <span style={{fontWeight: 'normal', marginLeft: 5}}>{payload && payload.length > 0 && payload[1]?.value}</span></p>
    )
    return toReturn
  }

  const renderCardioCharts = () => {
    switch (selectedChart) {
      case 1:
        return (
          <GeneralChart
						token={token}
						patientId={patient?.patient_id}
						// chartWidth={'74%'}
						// questionName={'physical'}
						scoreName={'blood_pressure.upper'}
						lineName={'Upper'}
						yAxisName={'mmHg'}
						chartName={'Blood Pressure'}
						graphData={vitalData}
            extraToolTip={extraToolTip}
            referenceLine={showBpLine}
						// domainRange={[0, 20]}
					/> 
        )
      case 2:
        return (
          <GeneralChart
						token={token}
						patientId={patient?.patient_id}
						// chartWidth={'74%'}
						// questionName={'physical'}
						scoreName={'heart_rate'}
						lineName={'Heart Rate'}
						yAxisName={'bpm'}
						chartName={'Heart Rate'}
						graphData={vitalData}
						// domainRange={[0, 20]}
					/> 
        )
      case 3:
        return (
          <GeneralChart
						token={token}
						patientId={patient?.patient_id}
						// chartWidth={'74%'}
						// questionName={'physical'}
						scoreName={'heart_rate_variability'}
						lineName={'Heart Rate Variability'}
						yAxisName={'ms'}
						chartName={'Heart Rate Variability'}
						graphData={vitalData}
						// domainRange={[0, 20]}
					/> 
        )
    
      default:
        return null;
    }
  }

  return (
    <div style={{width: '100%', margin: '0 auto', display: 'flex', flexDirection: 'column',}}>
      <p style={{width: '100%', textAlign: 'center',fontWeight: 'bold', color: Constants.primaryTheme, fontSize: Constants.headerSize}}>Cardiovascular health</p>
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 10, marginTop: 10, marginBottom: 10}}>
        <input type="checkbox" style={{marginRight: 10}} onChange={onChecked} checked={checked}/>
        <p>Save this search</p>
      </div>

      <LoincSearchModal
        selectedLabs={selectedLabs}
        onLabsSelected={onLabsSelected}
        showModal={showSearchModal}
        hideModal={() => setShowSearchModal(false)}/>

      <QuestionnaireModal
        selectedQuestionnaire={selectedQuestionnaire}
        onQuestionnaireSelected={onQuestionnaireSelected}
        showModal={showQuestionnaireModal}
        hideModal={() => setShowQuestionnaireModal(false)}/>
      <ChartModal
        data={modalChartData.data}
        path={modalChartData.path}
        name={modalChartData.name}
        getQuestionnaireResults={getQuestionnaireResults}
        showChartModal={showChartModal}
        hideChartModal={hideChartModal}
      />

      <div style={{display: 'flex', flexDirection: 'row', margin: 10, alignItems: 'center', width: '100%'}}>
        <p style={{fontWeight: 'bold', color: Constants.primaryTheme, width: 100}}>Biomarkers</p>
        <Button
          onClick={() => setShowSearchModal(true)}
          className={GlobalStyles.button}
          style={{width: 200, marginLeft: 20}}
          variant="primary"
        >
          Add Biomarkers
        </Button>
      </div>

      <div style={{flexDirection: 'row', display: 'flex', marginTop: 10, flexWrap: 'wrap', alignSelf: "flex-start"}}>
        {
          selectedLabs && selectedLabs.map(item => {
            return renderNewLabValue(item)
          })
        }
      </div>

      {/* <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 10, marginTop: 50}}>
        <input type="checkbox" style={{marginRight: 10}} onChange={onQuestionnaireChecked} checked={questionnaireChecked}/>
        <p>Save this search</p>
      </div> */}
      <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 30}}>
        <p style={{fontWeight: 'bold', color: Constants.primaryTheme, width: 140, marginLeft: 10}}>Questionnaires</p>
        <Button
          onClick={() => setShowQuestionnaireModal(true)}
          className={GlobalStyles.button}
          style={{width: 200, marginLeft: 20}}
          variant="primary"
        >
          Add Questionnaire
        </Button>
      </div>
      <div style={{flexDirection: 'row', display: 'flex', marginTop: 10, flexWrap: 'wrap', alignSelf: "flex-start"}}>
        {
          selectedQuestionnaire && selectedQuestionnaire.map(item => {
            return renderQuestionnaireResults({option: item, getScreeningData, setModalChartData, setShowChartModal})
          })
        }
      </div>

      <p style={{fontWeight: 'bold', color: Constants.primaryTheme, marginLeft: 10, marginTop: 30, marginBottom: 20}}>Remote Monitoring</p>


      <div className="row" style={{marginLeft: 20}}>
        <div className="col">
          {
            // renderCardiovascularChart(options,series,graphWidth)
            // renderCardiovascularChart(graphTempData, graphWidth, stockName, subtitleName, onChangeSelect)
            renderCardioCharts()
          }
        </div>
        <div className="col-sm-4" style={{marginTop: 50}}>
          <div className="radioButton">
            {
              Cardiovascular_radioData.map((radioData, index)=>{
                return(
                  <label className={Styles.radioConatinerNew} key={index.toString()}>{radioData.name}
                    <input
                      type="radio"
                      checked={Cardiovascular_GraphType-1 == index ? true : false }
                      onClick={()=> {
                        Cardiovascular_radioData[index].checked = index;
                        Cardiovascular_GraphType = index+1;
                        onRadioClick(index);
                        setSelectedChart(radioData?.key)
                      }}
                      name="radio"
                    />
                    <span className={Styles.checkmark}></span>
                  </label>
                )
              })
            }
          </div>
        </div>
      </div>


      {/* <p style={{fontWeight: 'bold', color: Constants.primaryTheme, marginTop: 30, marginLeft: 10}}>ECG</p>

      <div style={{display: 'flex', flexDirection: 'row', padding: 20, marginLeft: 10}}>
        <div style={{width: window.innerWidth/1.7}}>
          {
            renderECGraph(ecgData)
          }
        </div>
        <div className="col-sm-4">
          <div className="squareButton">
            <div className={Styles.rowDirectionBox}>
            <label className={Styles.squareConatiner}>Lead I
                <input
                  type="checkbox"
                  // onClick={()=> {
                  //   Cardiovascular_radioData[index].checked = index;
                  //   Cardiovascular_GraphType = index+1;
                  //   onRadioClick(index);
                  // }}
                  name="checkbox"
                />
                <span className={Styles.checkmark}></span>
              </label>
            </div>
            <div className={Styles.rowDirectionBox}>
              <label className={Styles.squareConatiner}>Lead II
                <input
                  type="checkbox"
                  // onClick={()=> {
                  //   Cardiovascular_radioData[index].checked = index;
                  //   Cardiovascular_GraphType = index+1;
                  //   onRadioClick(index);
                  // }}
                  name="checkbox"
                />
                <span className={Styles.checkmark}></span>
              </label>
            </div>
            <div className={Styles.rowDirectionBox}>
              <label className={Styles.squareConatiner}>Lead III
                <input
                  type="checkbox"
                  // onClick={()=> {
                  //   Cardiovascular_radioData[index].checked = index;
                  //   Cardiovascular_GraphType = index+1;
                  //   onRadioClick(index);
                  // }}
                  name="checkbox"
                />
                <span className={Styles.checkmark}></span>
              </label>
            </div>
          </div>
        </div>
      </div> */}


      {/* <div style={{width: '100%', margin: '10px auto 0px auto', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%'}}>
          <p style={{fontWeight: 'bold', color: "rgb(32, 168, 146)"}}>Patient Reported Outcomes</p>
          <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: 50, flexGrow: 1}}>
            <div style={{flexGrow: 1}}> */}
              {/* <div className={Styles.customFormControl}>
                <input
                  className={Styles.formControl}
                />
              </div> */}
              {/* <Select
                // placeholder={'Select  ....'}
                isMulti={true}
                style={{color: 'red'}}
                // options={options}
                // value={this.state[stateOptionKey]}
                // onChange={(val) => this.onSelectEntryChanged(stateOptionKey, val)}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              /> */}
            {/* </div>
          </div>
        </div>
      </div>
      <div style={{flexDirection: 'row', display: 'flex', marginTop: 0, flexWrap: 'wrap', alignSelf: "flex-start"}}>
        {
          patientReportedOptions.map((item, index) =>{
            return renderLabValue(item, 'biomarker', patientReportedData[index])
          })
        }
      </div> */}

    </div>
  )
}

const renderECGraph = (data) =>{
  let width = (window.innerWidth / 1.7) - 20
  return (
    <div id="chart" className={Styles.ecgChartClass} style={{alignSelf: "flex-start"}}>
      <LineChart
        width={width}
        height={250}
        data={data}
        margin={{
          top: 0, right: 0, left: 0, bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="name"
          interval={0}
          padding={{ right: 20 }}
          // type='number'
          hide={true}
        />
        <YAxis
          interval={0}
          hide={true}
          type='number'
          scale='sqrt'
        />
        <Tooltip />
        {/* <Legend /> */}
        <Line type="linear" dataKey="pv" stroke="#000000" activeDot={{ r: 0 }} dot={false} />
        <Line type="linear" dataKey="uv" stroke="#000000" dot={false} />
        <Line type="linear" dataKey="amt" stroke="#000000" dot={false} />
      </LineChart>
    </div>
  )
}

const renderCardiovascularChart = (data, graphWidth, stockName, subtitleName, onChangeSelect) =>{
  let width = (window.innerWidth/1.7) - 40
  return(
    <div id="chart" className={Styles.reproductiveChartClass} style={{alignSelf: "flex-start"}}>
      <div className={Styles.yAxisLabel}>
        <p className={Styles.yAxisLabelP}>
          { subtitleName }
        </p>
      </div>


      <div className={Styles.chartSelect}>
        <div className={Styles.chartSelectCurrent} tabIndex="1">
          {
            selectArray.map((item, index)=>{
              return(
                <div className={Styles.chartSelectValue} key={index.toString()}>
                  <input className={Styles.chartSelectInput}  type="radio" id={item.id} value={item.value} name={item.name} checked={item.value == selectValue ? true : false}  onChange={null}/>
                  <p className={Styles.chartSelectInputText}>{item.name}</p>
                </div>
              )
            })
          }
            <img className={Styles.chartSelectIcon}
            src="http://cdn.onlinewebfonts.com/svg/img_295694.svg" alt="Arrow Icon" aria-hidden="true"/>
        </div>
        <ul className={Styles.chartSelectList}>
          <li onClick={()=>{ onChangeSelect('Year'); setSelectValue = 'Year'; selectValue=1}}>
            <label className={Styles.chartSelectOption}  htmlFor="1" aria-hidden="aria-hidden">Year</label>
          </li>
          <li onClick={()=>{onChangeSelect('Month'); setSelectValue = 'Month'; selectValue=2}}>
            <label className={Styles.chartSelectOption} htmlFor="2" aria-hidden="aria-hidden">Month</label>
          </li>
          <li onClick={()=>{onChangeSelect('Day'); setSelectValue = 'Day'; selectValue=3}}>
            <label className={Styles.chartSelectOption} htmlFor="3" aria-hidden="aria-hidden">Day</label>
          </li>
        </ul>
      </div>


      {/* <div className={Styles.monthDiv}>
        <p className={Styles.monthLabel}>
          month
        </p>
      </div> */}
      <AreaChart width={graphWidth-100} height={280} data={data}
        margin={{ top: 10, right: 10, left: 0, bottom: 0 }}>
        {/* <defs>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#20A892" stopOpacity={0.6}/>
            <stop offset="95%" stopColor="#20A892" stopOpacity={0}/>
          </linearGradient>
        </defs> */}
        <XAxis
          // label={{ value: 'Sec', position: 'insideBottomRight', offset:0, marginLeft: 70}}
          dataKey="created_at"
          padding={{ left: 0, right: 20 }}
          axisLine={true}
          tickLine={true}
          // height={60}
          // tick={<CustomizedAxisTick />}
        >
        {/* <Label value="sec" offset={0} position="insideBottomRight" style={{marginLeft:"15px;"}} /> */}
        </XAxis>
        <YAxis
          // label={{ value: subtitleName, position: 'insideTopLeft', offset:10 }}
          axisLine={true}
          tickLine={true}
        >

          {/* <Label value="sec" offset={0} position="insideTopLeft" style={{marginBottom:"15px;"}} /> */}
        </YAxis>
        {/* <CartesianGrid vertical={false} /> */}
        <Tooltip
          content={<CustomTooltip />}
          contentStyle={{ width: 100, height: 50, borderRadius: 20 }}
          // viewBox={{x: 0, y: 0, width: 400, height: 100 }}
        />
        <Area type="monotone" dataKey={stockName} stroke={Constants.graphLineOne} fillOpacity={1} fill="url(#colorPv)" />
      </AreaChart>
      {/* <div className={Styles.xAxisLabel}>
        <p className={Styles.xAxisLabelText}>
          sec
        </p>
      </div> */}
    </div>
  )
}

// const CustomizedAxisTick = (props) =>{
//   return(
//     <div style={{backgroundColor: 'red'}}>
//       <text>{props.payload.value}</text>
//     </div>
//   )
// }



const renderRespChart = (data, graphWidth, stockName, subtitleName) =>{
  return(
    <div id="chart" className={Styles.chartClassOld} style={{alignSelf: "flex-start"}}>
      <div className={Styles.yAxisLabelOld}>
        <p className={Styles.yAxisLabelPOld}>
          { subtitleName }
        </p>
      </div>
      <AreaChart width={graphWidth} height={280} data={data}
        margin={{ top: 10, right: 10, left: 0, bottom: 0 }}>
        {/* <defs>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#20A892" stopOpacity={0.6}/>
            <stop offset="95%" stopColor="#20A892" stopOpacity={0}/>
          </linearGradient>
        </defs> */}
        <XAxis
          // label={{ value: 'Sec', position: 'insideBottomRight', offset:0, marginLeft: 70}}
          dataKey="created_at"
          padding={{ left: 0, right: 40 }}
          axisLine={true}
          tickLine={true}
        >
        {/* <Label value="sec" offset={0} position="insideBottomRight" style={{marginLeft:"15px;"}} /> */}
        </XAxis>
        <YAxis
          // label={{ value: subtitleName, position: 'insideTopLeft', offset:10 }}
          axisLine={true}
          tickLine={true}
        >

          {/* <Label value="sec" offset={0} position="insideTopLeft" style={{marginBottom:"15px;"}} /> */}
        </YAxis>
        {/* <CartesianGrid vertical={false} /> */}
        <Tooltip
          content={<CustomTooltip />}
          contentStyle={{ width: 100, height: 50, borderRadius: 20 }}
          // viewBox={{x: 0, y: 0, width: 400, height: 100 }}
        />
        <Area type="monotone" dataKey={stockName} stroke={Constants.graphLineOne} fillOpacity={0} fill="url(#colorPv)" />
      </AreaChart>
      <div className={Styles.xAxisLabel}>
        <p className={Styles.xAxisLabelText}>
          sec
        </p>
      </div>
    </div>
  )
}

const renderRespChart1 = (data, graphWidth, stockName, subtitleName) =>{
  return(
    <div id="chart" className={Styles.chartClassOld} style={{alignSelf: "flex-start"}}>
      <p style={{width: '100%', textAlign: 'center', color: Constants.primaryTheme}}>Spirometry</p>

      <AreaChart width={graphWidth} height={280} data={data}
        margin={{ top: 10, right: 10, left: 0, bottom: 0 }}>
         <defs>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#20A892" stopOpacity={0.6}/>
            <stop offset="95%" stopColor="#20A892" stopOpacity={0}/>
          </linearGradient>
        </defs>
        <XAxis
          dataKey="time"
          padding={{ left: 0, right: 40 }}
          axisLine={true}
        >
          <Label value="Time(seconds)" offset={0} position="insideBottom" />
        </XAxis>
        <YAxis
          label={{ value: 'Volume(liters)', angle: -90, position: 'insideLeft' }}
          dataKey="volume"
          axisLine={true}
        />

        <Tooltip/>
        <Area type="monotone" dataKey="volume" stroke={Constants.graphLineOne} fillOpacity={1} fill="url(#colorPv)" />
      </AreaChart>
    </div>
  )
}

  const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    return (
      <div className={Styles.customTooltip}>
        <p className={Styles.tooleTipLabel}>{`Treatment: ${label}`}</p>
        {/* <p className="intro">{getIntroOfPage(label)}</p>
        <p className="desc">Anything you want can be displayed here.</p> */}
      </div>
    );
  }

  return null;
};

// const renderCardiovascularChart = (options,series,graphWidth) =>{
//   return(
//     <div id="chart" className={Styles.chartClass} style={{alignSelf: "flex-start"}}>
//         <Chart options={options} series={series} type="area" height={300} width={'100%'} />
//     </div>
//   )
// }

const renderLabValue = (option) => {
  return (
    <div
      // onClick={() => this.setState({modalIsOpen: true, modalOption: option, modalProgress: data.progress})}
      data-tip
      data-for={option.label}
      key={option.label}
      style={{width: 180, marginRight: 5, marginLeft: 5, height: 120, alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', cursor: 'pointer'}}>
      {
        option.val ?
          <p style={{fontWeight: 'bold', fontSize: 16}}>{option.val}</p>
          :
          <p style={{fontWeight: 'bold', fontSize: 16}}>-</p>
      }
      <p style={{fontSize: 12}}>{option.unit}</p>

      <div style={{width: '90%', backgroundColor: option.val ? GREEN_RANGE_COLOR : Constants.lightGray, display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '4px 2px', borderRadius: 8, marginTop: 15}}>
        <p style={{textAlign: 'center'}}>{option.label}</p>
      </div>
      {/* {this.getTooltip(option, data.progress)} */}
      <ChartTooltip option={option}/>


    </div>
  )
}


const Respiratory = (props) =>{
  let {screeningData, dateOptions, onResRadioClick, vitalData} = props
  const [checked,setChecked] = useState(false)
  const [showSearchModal, setShowSearchModal] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState([])
  const [selectedLabs, setSelectedLabs] = useState([])
  const token = useSelector(state => state.authReducer.token)
  const patient = useSelector(state => state.patientReducer.patient)
  const [showQuestionnaireModal, setShowQuestionnaireModal] = useState(false)
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState([])
  const [questionnaireChecked, setQuestionnaireChecked] = useState(false)
  const [anxietyScreening, setAnxietyScreening] = useState(null)
  const [mentalHealthScreening, setMentalHealthScreening] = useState(null)
  const [crohnsScreening, setCrohnsScreening] = useState(null)
  const [colitisScreening, setColitisScreening] = useState(null)
  const [physicalScreening, setPhysicalScreening] = useState(null)
  const [burnoutScreening, setBurnoutScreening] = useState(null)
  const [aceScreening, setAceScreening] = useState(null)
  const [sleepapneaScreening, setSleepapneaScreening] = useState(null)
  const [globalhealthScreening, setGlobalhealthScreening] = useState(null)
  const [showChartModal, setShowChartModal] = useState(false)
  const [modalChartData, setModalChartData] = useState({data: [], path: '', name: ''})
  const biomarkersOption = RS_BIOMARKER_OPTIONS;
  const patientReportedOptions = RS_PATIENT_REPORTED_OPTIONS;
  const patientReportedData = RS_PATIENT_REPORTED_DATA;
  let graphWidth = (window.innerWidth) / 2;

  let tempData = [];
  let stockName = 'PPG';
  let subtitleName = 'amplitude';

  let graphTempData = [];

  if(RESPIRATION_GRAPH_Key == 1){
    RESPIRATION_GRAPH_DATA.PPG.forEach((item)=>{
      graphTempData.push({created_at: item.sec, PPG: item.amplitude})
      subtitleName = item.unit
      stockName = 'PPG';
    })
  }else{
    RESPIRATION_GRAPH_DATA.Accelerometer.forEach((item)=>{
      graphTempData.push({created_at: item.sec, Accelerometer: item.metres})
      subtitleName = item.unit
      stockName = 'Accelerometer';
    })
  }


  const stockNameSP = "Spirometry";
  const subtitleNameSP = "Liters";

  useEffect(() => {
    let patient = props.patient
    if(patient?.body_systems_preferences?.respiratory?.length > 0) {
      setSelectedLabs(patient.body_systems_preferences.respiratory)
      setChecked(true)
    } else {
      setChecked(false)
    }

  },[props.patient])

  const onLabsSelected = (entries) => {
    setSelectedLabs(entries || [])
    setShowSearchModal(false)
    if(checked) {
      props.updatePreferences({
        'body_systems_preferences.respiratory': entries || []
      })
    }
  }

  const onChecked = (e) => {
    let data
    if(e.target.checked) {
      //save the preferences
      data = {
        'body_systems_preferences.respiratory': selectedLabs || []
      }
      onQuestionnaireChecked(true)
    } else {
      data = {
        'body_systems_preferences.respiratory': []
      }
      onQuestionnaireChecked(false)
    }
    setChecked(e.target.checked)
    props.updatePreferences(data)
  }

  useEffect(() => {
    let patient = props.patient
    if(patient?.questionnaire_preferences?.respiratory?.length > 0) {
      setSelectedQuestionnaire(patient.questionnaire_preferences.respiratory)
      setQuestionnaireChecked(true)
    } else {
      setQuestionnaireChecked(false)
    }

  },[props.patient])

  useEffect(() => {
    getAllScreeners()
  }, [selectedQuestionnaire])

  const getAllScreeners = () => {
    selectedQuestionnaire && selectedQuestionnaire.forEach((screener) => {
      getScreening(screener.path)
    })
  }

  const getScreening = (path) => {
    const url = Config.BACKEND_URL + 'screenings/' + path
    axios({
      method: 'get',
      url,
      headers: { Authorization: `JWT ${token}`, x_firebase_id: props.patient.patient_id, complete: true },
    })
      .then(({ data }) => {
        setData(path, data)
      })
      .catch((err) => {
        console.log('error when getting screening', err)
      })
  }

  const setData = (path, data) => {
    switch (path) {
      case 'anxiety':
        setAnxietyScreening(data)
        break
      case 'mental':
        setMentalHealthScreening(data)
        break
      case 'gastro':
        setCrohnsScreening(data)
        break
      case 'colitis':
        setColitisScreening(data)
        break
      case 'physical':
        setPhysicalScreening(data)
        break
      case 'burnout':
        setBurnoutScreening(data)
        break
      case 'ace':
        setAceScreening(data)
        break
      case 'sleepapnea':
        setSleepapneaScreening(data)
        break
      case 'globalhealth':
        setGlobalhealthScreening(data)
        break
      default:
    }
  }

  const getScreeningData = (path) => {
    switch (path) {
      case 'anxiety':
        return anxietyScreening
      case 'mental':
        return mentalHealthScreening
      case 'gastro':
        return crohnsScreening
      case 'colitis':
        return colitisScreening
      case 'physical':
        return physicalScreening
      case 'burnout':
        return burnoutScreening
      case 'ace':
        return aceScreening
      case 'sleepapnea':
        return sleepapneaScreening
      case 'globalhealth':
        return globalhealthScreening	
      default:
        return null
    }
  }

  const hideChartModal = () => {
    setShowChartModal(false)
  }

  const onQuestionnaireSelected = (entries) => {
    setSelectedQuestionnaire(entries || [])
    setShowQuestionnaireModal(false)
    if(checked) {
      props.updatePreferences({
        'questionnaire_preferences.respiratory': entries || []
      })
    }
  }

  const onQuestionnaireChecked = (isChecked) => {
    let data
    if(isChecked) {
      //save the preferences
      data = {
        'questionnaire_preferences.respiratory': selectedQuestionnaire || []
      }
    } else {
      data = {
        'questionnaire_preferences.respiratory': []
      }
    }
    // setQuestionnaireChecked(e.target.checked)
    props.updatePreferences(data)
  }

  return (
    <div style={{width: '100%',display: 'flex', flexDirection: 'column'}}>
      <p style={{width: '100%', fontWeight: 'bold', color: Constants.primaryTheme, textAlign: 'center', marginBottom: 20, fontSize: Constants.headerSize}}>Respiratory System</p>
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 10, marginTop: 10, marginBottom: 10}}>
        <input type="checkbox" style={{marginRight: 10}} onChange={onChecked} checked={checked}/>
        <p>Save this search</p>
      </div>

      <LoincSearchModal
        selectedLabs={selectedLabs}
        onLabsSelected={onLabsSelected}
        showModal={showSearchModal}
        hideModal={() => setShowSearchModal(false)}/>

      <QuestionnaireModal
        selectedQuestionnaire={selectedQuestionnaire}
        onQuestionnaireSelected={onQuestionnaireSelected}
        showModal={showQuestionnaireModal}
        hideModal={() => setShowQuestionnaireModal(false)}/>
      <ChartModal
        data={modalChartData.data}
        path={modalChartData.path}
        name={modalChartData.name}
        getQuestionnaireResults={getQuestionnaireResults}
        showChartModal={showChartModal}
        hideChartModal={hideChartModal}
      />

      <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <p style={{fontWeight: 'bold', color: Constants.primaryTheme, width: 100}}>Biomarkers</p>
        <Button
          onClick={() => setShowSearchModal(true)}
          className={GlobalStyles.button}
          style={{width: 200, marginLeft: 20}}
          variant="primary"
        >
          Add Biomarkers
        </Button>

      </div>

      <div style={{flexDirection: 'row', display: 'flex', marginTop: 10, flexWrap: 'wrap', alignSelf: "flex-start"}}>
        {
          selectedLabs && selectedLabs.map(item => {
            return renderNewLabValue(item)
          })
        }
      </div>

      {/* <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 10, marginTop: 50}}>
        <input type="checkbox" style={{marginRight: 10}} onChange={onQuestionnaireChecked} checked={questionnaireChecked}/>
        <p>Save this search</p>
      </div> */}
      <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 30}}>
        <p style={{fontWeight: 'bold', color: Constants.primaryTheme, width: 140}}>Questionnaires</p>
        <Button
          onClick={() => setShowQuestionnaireModal(true)}
          className={GlobalStyles.button}
          style={{width: 200, marginLeft: 20}}
          variant="primary"
        >
          Add Questionnaire
        </Button>
      </div>
      <div style={{flexDirection: 'row', display: 'flex', marginTop: 10, flexWrap: 'wrap', alignSelf: "flex-start"}}>
        {
          selectedQuestionnaire && selectedQuestionnaire.map(item => {
            return renderQuestionnaireResults({option: item, getScreeningData, setModalChartData, setShowChartModal})
          })
        }
      </div>

      <p style={{marginBottom: 10,marginTop: 30, fontWeight: 'bold', color: Constants.primaryTheme}}>Remote Monitoring</p>

      {
        renderRespChart1(GRAPHTEMPDATASP, graphWidth, stockNameSP, subtitleNameSP)
      }

      <GeneralChart
        token={token}
        patientId={patient?.patient_id}
        chartWidth={'74%'}
        // questionName={'physical'}
        scoreName={'respiration_rate'}
        lineName={'Respiration Rate'}
        yAxisName={'Rate'}
        chartName={'Respiration Rate'}
        graphData={vitalData}
        // domainRange={[0, 20]}
      /> 

      <GeneralChart
        token={token}
        patientId={patient?.patient_id}
        chartWidth={'74%'}
        // questionName={'physical'}
        scoreName={'oxygen_saturation'}
        lineName={'SpO2'}
        yAxisName={'SpO2 %'}
        chartName={'SpO2'}
        graphData={vitalData}
        // domainRange={[0, 20]}
      /> 

      {/* <div style={{width: '100%', marginTop: 30, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%'}}>
          <p style={{fontWeight: 'bold', color: "rgb(32, 168, 146)"}}>Patient Reported Outcomes</p>
          <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: 50, flexGrow: 1}}>
            <div style={{flexGrow: 1}}> */}
              {/* <div className={Styles.customFormControl}>
                <input
                  className={Styles.formControl}
                />
              </div> */}

              {/* <Select
                // placeholder={'Select  ....'}
                isMulti={true}
                style={{color: 'red'}}
                // options={options}
                // value={this.state[stateOptionKey]}
                // onChange={(val) => this.onSelectEntryChanged(stateOptionKey, val)}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              /> */}
            {/* </div>
          </div>
        </div>
      </div>
      <div style={{flexDirection: 'row', display: 'flex', marginTop: 0, flexWrap: 'wrap', alignSelf: "flex-start"}}>
        {
          patientReportedOptions.map((item, index) =>{
            return renderLabValue(item, 'biomarker', patientReportedData[index])
          })
        }
      </div> */}


    </div>
  )
}


const Physical = (props) => {
  let {screeningData, dateOptions} = props
  let token = useSelector(state => state.authReducer.token)
  let patient = useSelector(state => state.patientReducer.patient)
  let [date,setDate] = useState(dateOptions[0])


  function renderPhysicalChart() {
    let graphWidth = (window.innerWidth) / 3.1

    return (
      <LineChart width={graphWidth} height={350} data={screeningData.physical} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
        <Legend verticalAlign="top"/>
        <Line dot={false} name={'HAQ-II Score'}  type="monotone" dataKey="haqScore" stroke={Constants.graphLineOne}/>
        <XAxis dataKey="date" tickFormatter={(tickItem) => {
          return moment(tickItem).format('D-MMM')
        }}/>
        <YAxis domain={[0, 20]}/>
        <Tooltip />
      </LineChart>
    )
  }

  function checkIfActivityDone(array, activity) {
    for(let i=0; i<array.length; i++) {
      let userActivity = array[i]
      if(userActivity.toLowerCase() === activity.toLowerCase())
        return true
    }

    return false
  }

  function renderActivitiesContent() {
    let rows = []
    let activities = screeningData && screeningData.activities
    let activityScreening
    activities.forEach(screening => {
      if(screening.created_at === date.value)
        activityScreening = screening
    })

    Object.keys(ACTIVITIES).forEach(levelKey => {
      let allActivities = ACTIVITIES[levelKey]
      let userActivities = (activityScreening && activityScreening.energy && activityScreening.energy[levelKey]) ?  activityScreening.energy[levelKey] : []
      allActivities.forEach(activity => {
        rows.push(
          <tr>
            <td>{activity}</td>
            <td>{checkIfActivityDone(userActivities, activity) ? '\u2713' : ''}</td>
          </tr>
        )
      })
    })


    return (
      <div style={{width: '90%', margin: '0 auto', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <div style={{display: 'flex', flexDirection: 'row', margin: 10, alignItems: 'center', width: '100%'}}>
          <p style={{width: 60, fontWeight: 'bold', color: 'gray'}}>Date</p>
          <div style={{width: '50%'}}>
            <Select
              placeholder={'Select Date...'}
              isMulti={false}
              options={props.dateOptions}
              value={date}
              onChange={(val) => setDate(val)}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
        </div>
        <table style={{width: '100%'}}>
          <thead>
          <tr>
            <th>Activity</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          {rows}
          </tbody>
        </table>
      </div>
    )
  }

  function getAllHAQStatus(data) {
    if(!data || data.length === 0)
      return '-'

    let score = data.haqScore
    if(score <= 0.25)
      return 'Remission'
    else if(score >= 0.26 && score <= 3.70)
      return 'Low'
    else if(score >= 3.71 && score <= 7.99)
      return 'Moderate'
    else
      return 'Severe'
  }

  function getHAQStatus(data) {
    if(!data || data.length === 0)
      return '-'

    let lastScore = data[data.length - 1].haqScore
    if(lastScore <= 0.25)
      return 'Remission'
    else if(lastScore >= 0.26 && lastScore <= 3.70)
      return 'Low'
    else if(lastScore >= 3.71 && lastScore <= 7.99)
      return 'Moderate'
    else
      return 'Severe'
  }
  
  function getHaqReferenceAndLine() {
    let toReturn = []
    toReturn.push(<ReferenceArea y1={0} y2={0.25} fill={Constants.graphReferenceColors[0]} fillOpacity={0.1}/>)
    toReturn.push(<ReferenceArea y1={0.25} y2={3.70} fill={Constants.graphReferenceColors[1]} fillOpacity={0.1}/>)
    toReturn.push(<ReferenceArea y1={3.70} y2={8} fill={Constants.graphReferenceColors[2]} fillOpacity={0.1}/>)
    toReturn.push(<ReferenceArea y1={8} fill={Constants.graphReferenceColors[4]} fillOpacity={0.1}/>)
    
    return toReturn
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', margin: '0 auto'}}>
        <div style={{display: 'flex', flexDirection: 'row', marginTop: 20, width: '100%'}}>
          {/* <div style={{width: '50%'}}>
            <div className={Styles.chartWrapper} style={{marginTop: 50}}>
              <h5 style={{color: Constants.primaryTheme}}>Physical Function Score</h5>
              {renderPhysicalChart()}
              <p style={{width: '100%', textAlign: 'center'}}>Status: {getHAQStatus(screeningData && screeningData.physical)}</p>
            </div>
          </div> */}
          <GeneralChart
            token={token}
            patientId={patient?.patient_id}
            chartWidth={'100%'}
            questionName={'physical'}
            scoreName={'haqScore'}
            lineName={'HAQ-II Score'}
            yAxisName={'Score'}
            chartName={'Physical Function Score'}
            graphData={screeningData.physical}
            domainRange={[0, 20]}
            statusFunc={getAllHAQStatus}
            status={getHAQStatus(screeningData && screeningData.physical)}
            // referenceLine={getHaqReferenceAndLine}
            referenceLine={getChartReferenceLines}
          /> 

          <div style={{width: '50%'}}>
            <h5 style={{color: Constants.primaryTheme, width: '100%', textAlign: 'center', marginTop: 50}}>Activities of Daily Living</h5>
            {renderActivitiesContent()}
          </div>
        </div>
    </div>
  )
}

const ReferenceLineLabel = (props) => {
  return (
    <div style={{height: 20, width: 20, backgroundColor: 'green'}}>
      <p>testing</p>
    </div>
  )
}

const Gastro = (props) => {

  function getCrohnsReferenceAndLine() {
    let toReturn = []
    toReturn.push(<Line dot={false} name={'Harvey Bradshaw Index'}  type="monotone" dataKey={'harvey_bradshaw_index'} stroke={Constants.graphLineOne} />)
    toReturn.push(<ReferenceArea y1={0} y2={4} fill={Constants.graphReferenceColors[0]} fillOpacity={0.1}/>)
    toReturn.push(<ReferenceArea y1={4} y2={7} fill={Constants.graphReferenceColors[1]} fillOpacity={0.1}/>)
    toReturn.push(<ReferenceArea y1={7} y2={16} fill={Constants.graphReferenceColors[2]} fillOpacity={0.1}/>)
    toReturn.push(<ReferenceArea y1={16} fill={Constants.graphReferenceColors[4]} fillOpacity={0.1}/>)
    toReturn.push(
      <ReferenceLine
        x={"2020-11-23T07:11:01.782Z"}
        stroke="gray"
        strokeDasharray="3 3"
      >
        <Label>{`Humira Started (40mg/0.4mL)`}</Label>
      </ReferenceLine>
    )

    return toReturn
  }

  function getColitisReferenceAndLine() {
    let toReturn = []
    toReturn.push(<Line dot={false} name={'P-SCCAI'}  type="monotone" dataKey={'colitisScore'} stroke={Constants.graphLineOne} />)
    toReturn.push(<ReferenceArea y1={0} y2={5} fill={Constants.graphReferenceColors[0]} fillOpacity={0.1}/>)
    toReturn.push(<ReferenceArea y1={5} y2={10} fill={Constants.graphReferenceColors[2]} fillOpacity={0.1}/>)
    toReturn.push(<ReferenceArea y1={10} fill={Constants.graphReferenceColors[4]} fillOpacity={0.1}/>)

    return toReturn
  }

  function renderChart(crohns) {
    let graphWidth = (window.innerWidth) / 3.1
    let data = props.gastroData || {}

    return (
      <LineChart width={graphWidth} height={350} data={crohns ? data.crohns : data.colitis} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
        <Legend verticalAlign="top"/>
        {
          crohns ?
            getCrohnsReferenceAndLine()
            :
            getColitisReferenceAndLine()
        }

        <XAxis dataKey="created_at" tickFormatter={(tickItem) => {
          return moment(tickItem).format('D-MMM')
        }}/>
        <YAxis domain={crohns ? [0, 22] : [0,20]}/>
        <Tooltip content={({ active, payload, label }) => {
          let date = moment(label).format('D-MMM')
          return (
            <div className={Styles.painChartTooltip} style={{width: 240}}>
              <p>{date}</p>
              {
                crohns ?
                  <div style={{width: '100%', padding: '0px 5px'}}>
                    <p style={{fontWeight: 'bold', textAlign: 'left'}}>Harvey Bradshaw Index: <span style={{fontWeight: 'normal', marginLeft: 5}}>{payload && payload.length > 0 && payload[0].value}</span></p>
                    <p style={{fontWeight: 'bold', textAlign: 'left'}}>Status: <span style={{fontWeight: 'normal', marginLeft: 5}}>{getCrohnsStatus(payload && payload.length>0 && payload[0].payload)}</span></p>
                  </div>
                  :
                  <div style={{width: '100%', padding: '0px 5px'}}>
                    <p style={{fontWeight: 'bold'}}>P-SCCAI: <span style={{fontWeight: 'normal', marginLeft: 5}}>{payload && payload.length > 0 && payload[0].value}</span></p>
                    <p style={{fontWeight: 'bold', textAlign: 'left'}}>Status: <span style={{fontWeight: 'normal', marginLeft: 5}}>{getColitisStatus(payload && payload.length>0 && payload[0].payload)}</span></p>
                  </div>

              }
            </div>
          )
        }}/>
      </LineChart>
    )
  }

  function getCrohnsStatus(screening) {
    if(!screening)
      return 'N/A'

    if(screening.harvey_bradshaw_index < 5)
      return 'Remission'
    else if(screening.harvey_bradshaw_index >= 5 && screening.harvey_bradshaw_index <= 7)
      return 'Mild Disease'
    else if(screening.harvey_bradshaw_index >= 8 && screening.harvey_bradshaw_index <= 16)
      return 'Moderate Disease'
    else if(screening.harvey_bradshaw_index > 16 )
      return 'Severe Disease'
    else
      return 'N/A'
  }

  function getColitisStatus(screening) {
    if(!screening)
      return 'N/A'

    if(screening.colitisScore <= 5)
      return 'Remission'
    else if(screening.colitisScore >= 6 && screening.colitisScore <= 10)
      return 'Moderate'
    else if(screening.colitisScore > 10)
      return 'Active'
    else
      return 'N/A'
  }

  return(
    <div style={{display: 'flex', flexDirection: 'row', width: '100%', margin: '0 auto', padding: '20px 0px', justifyContent: 'center'}}>
        <div style={{width: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <h4 style={{color: Constants.primaryTheme, width: '90%', textAlign: 'center'}}>Crohn's Disease Activity</h4>
          <div className={Styles.chartWrapper} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            {renderChart(true)}
          </div>
        </div>

        <div style={{width: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <h4 style={{color: Constants.primaryTheme, width: '90%', textAlign: 'center'}}>Colitis Disease Activity</h4>
          <div className={Styles.chartWrapper} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            {renderChart(false)}
          </div>
        </div>
    </div>
  )
}

const Tabs = (props) => {
  const [activeTab, setActiveTab] = useState(1)

  const onTabSelected = (index) => {
    setActiveTab(index)
    props.onTabSelected(index)
  }

  function getTooltip(label) {
    return (
      <ReactTooltip id={label}  textColor='black' backgroundColor={Constants.primaryThemeMedium} effect='solid'>
        <p style={{color: 'white', fontSize: 18}}>{label}</p>
      </ReactTooltip>
    )
  }

  function getTab(name, index, icon, fontAwesome) {
    if(index == 5 || index == 6 || index == 7 || index == 8 || index == 9 || index == 10){
      return (
        <div
          data-tip
          data-for={name}
          onClick={() => {
            setActiveTab(index)
            props.onTabSelected(index)
          }}
          className={Styles.rowIcon}
          style={{backgroundColor: activeTab === index ? Constants.primaryTheme : Constants.primaryThemeDark}}>
          {fontAwesome ?
            <FontAwesomeIcon icon={icon} style={{color: 'white', fontSize: 30}}/>
            :
              <img src={icon} style={{height: '60%', width: '60%', alignSelf: 'center'}} />
          }
          {getTooltip(name)}

        </div>
      )
    }
    else{
      return (
        <div
          data-tip
          data-for={name}
          onClick={() => {
            setActiveTab(index)
            props.onTabSelected(index)
          }}
          className={Styles.rowIcon}
          style={{backgroundColor: activeTab === index ? Constants.primaryTheme : Constants.primaryThemeDark}}>
          {fontAwesome ?
            <FontAwesomeIcon icon={icon} style={{color: 'white', fontSize: 30}}/>
            :
              <img src={icon} style={{height: '75%', width: '75%'}} />
          }
          {getTooltip(name)}

        </div>
      )
    }
  }

  return (
    <div style={{flexDirection: 'row', display: 'flex', width: '100%', padding: '20px 40px'}}>
      {getTab("Musculoskeletal", 1, SkeletonIcon, false)}
      {/*{getTab("Widespread Pain",2, PainIcon, false)}*/}
      {/*{getTab("Gastro",3, StomachIcon, false)}*/}
      {/*{getTab("Physical Function",4, faRunning, true)}*/}
      {getTab("Cardiovascular Health",5, HeartIcon, false)}
      {getTab("Respiratory System",6, LungsIcon, false)}
      {/*{getTab("Cardiovascular health",7, HeartIcon, false)}*/}
      {getTab("Gastrointestinal System",8, StomachIcon, false)}
      {getTab("Reproductive System",9, ReproductiveIcon, false)}
      {/* {getTab("Immune System",10, ImmuneIcon, false)} */}
      {getTab("Endocrine System",11, EndocrineIcon, false)}
      {getTab("Neurological System",12, faBrain, true)}
    </div>
  )
}

const ChartTooltip = ({option}) => {
  const [chartData, setChartData] = useState([])

  useEffect(() => {
    let temp = []
    temp.push({date: moment().toDate(), value: 3})
    temp.push({date: moment().subtract(2, 'days').toDate(), value: 5})
    temp.push({date: moment().subtract(2, 'weeks').toDate(), value: 8})
    temp.push({date: moment().subtract(3, 'weeks').toDate(), value: 4})
    setChartData(temp)
  },[option])

  return (
    <ReactTooltip id={option.label}  textColor='black' backgroundColor='white' effect='solid' className={Styles.hoverChatWrapper}>
      <div>
        <LineChart width={500} height={400} data={chartData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
          <Legend verticalAlign="top"/>
          <Line name={'Value'} type="monotone" dataKey="value" stroke={'#606060'} />
          <XAxis dataKey="date" stroke={'#000000cc'}  tickFormatter={(tickItem) => {
            return moment(tickItem).format('MMM-D')
          }}/>
          <YAxis stroke={'#000000cc'}/>
          <Tooltip />
        </LineChart>
      </div>
    </ReactTooltip>
  )
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    patient: state.patientReducer.patient,
    loggedIn: state.userReducer.loggedIn,
    patientCore: state.patientReducer.patientCore,
    token: state.authReducer.token,
    color: state.patientReducer.color,
    mongoUser: state.userReducer.mongoUser,
    medications: state.patientReducer.medications,
    conditions: state.patientReducer.conditions,
    patientProgress: state.patientReducer.patientProgress,
    healthData: state.healthReducer.healthData,
    adminId: state.userReducer.adminId,
  }
}

const mapDispatchToProps = { getDummyData, updatePatientData}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BodySystems)


