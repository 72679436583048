import React, { Component } from 'react'
import Styles from './styles/AilaWebQuestions.module.scss'
import GlobalStyles from '../styles/global.module.scss'
import FancyField from 'react-fancy-field'
import values from '../../values'
import Button from 'react-bootstrap/Button'
import AilaLogo from '../../assets/images/aila_logo.png'
import Form from 'react-bootstrap/Form'
import Progress_bar from '../../components/ProgressBar'
import algoliasearch from 'algoliasearch/lite'
import Config from '../../config'
import Select from 'react-select'
import * as Metadata from '../../metadata'
import firebase from '../../services/firebase'
import {
  addFirebaseUser,
  addFirebaseAuthUser,
  addPatient,
} from '../../redux/actions'
import { connect } from 'react-redux'
import OnboardingFooter from '../../components/OnboardingFooter'

const axios = require('axios')

const questionSet = [
  'Do you experience any of the following?',
  'Do you experience any of the following?',
  'Do you experience any of the following?',
  'Have you ever been diagnosed with an autoimmune condition?',
  'Has your doctor ever suggested or suggested that you have an autoimmune condition?',
  'Do any of your first degree relatives (parents, children, siblings) have an autoimmune disease?',
  'Have you had a positive diagnosis for Covid-19?',
  'Are you experiencing long term symptoms after having Covid-19 (e.g. Long Covid)?',
  'For how long have you been experiencing post-Covid symptoms?',
  // 'At Aila Health, we want to take care of you as a whole person. That means managing your current conditions and working to prevent illness before it starts. Our team will generally recommend that you pursue evidence based screening such as pap smears, mammograms, and colonoscopies. We will also generally recommend other preventive measures such as vaccines and STD checks. We understand each of you is an individual, and understanding you is part of who we are and how we treat you. When your symptoms do not respond to conventional treatment, we want to work with you to figure out why, and how to make you better. So let’s work together to get the preventive care that is so well studied, so that we can focus on getting you, and keeping you, on track towards your goals.',
  // 'What state are you located in?',
]

const specialDocQuestion = 'Please specify which condition'

const answerSet = [
  [
    { name: 'Chest pain', isChecked: false },
    { name: 'Cough', isChecked: false },
    { name: 'Difficulty breathing or shortness of breath', isChecked: false },
    { name: 'Lower than average body temperature', isChecked: false },
    { name: 'Cold hands or feet', isChecked: false },
    { name: 'Recurrent infections', isChecked: false },
    { name: 'Change in smell or taste', isChecked: false },
    { name: 'Fever', isChecked: false },
    { name: 'Dizziness on standing (lightheadedness)', isChecked: false },
    { name: 'Feeling of fainting', isChecked: false },
    { name: 'Changes in menstrual period cycles', isChecked: false },
    { name: 'Infertility or miscarriages', isChecked: false },
    { name: 'Low blood pressure', isChecked: false },
    { name: 'Elevated cholesterol', isChecked: false },
    { name: 'Slowed heart rate', isChecked: false },
    {
      name: 'Fast-beating or pounding heart (also known as heart palpitations)',
      isChecked: false,
    },
    { name: 'Stomach pain', isChecked: false },
    { name: 'Constipation', isChecked: false },
    { name: 'Diarrhea', isChecked: false },
    { name: 'Weight gain', isChecked: false },
    { name: 'Difficulty losing weight', isChecked: false },
    { name: 'Puffy face', isChecked: false },
  ],
  [
    { name: 'Vision problems', isChecked: false },
    { name: 'Gritty or sandy sensation in your eyes', isChecked: false },
    { name: 'Fatigue', isChecked: false },
    { name: 'Low energy', isChecked: false },
    {
      name: 'Symptoms that get worse after physical or mental activities (also known as post-exertional malaise)',
      isChecked: false,
    },
    { name: 'Restless Sleep/ Insomnia', isChecked: false },
    { name: 'Recurrent headaches', isChecked: false },
    { name: 'Brain Fog ', isChecked: false },
    { name: 'Impaired memory', isChecked: false },
    { name: 'Trouble Concentrating', isChecked: false },
    { name: 'Tingling/Pins and needles feeling', isChecked: false },
    { name: 'Poor mood', isChecked: false },
    { name: 'Depression', isChecked: false },
    { name: 'Anxiety', isChecked: false },
  ],
  [
    { name: 'Thinning hair', isChecked: false },
    { name: 'Dry skin', isChecked: false },
    { name: 'Skin thickening', isChecked: false },
    { name: 'Itchy skin', isChecked: false },
    { name: 'Rash', isChecked: false },
    {
      name: 'Skin break out (rash) after being in the sun (not sunburn)',
      isChecked: false,
    },
    { name: 'Sores in your mouth or nose ', isChecked: false },
    { name: 'Muscle weakness', isChecked: false },
    { name: 'Muscle aches, tenderness and stiffnessy', isChecked: false },
    { name: 'Pain, stiffness or swelling in your joints', isChecked: false },
    { name: 'Hypermobility', isChecked: false },
  ],
  ['Yes', 'No'],
  ['Yes', 'No'],
  ['Yes', 'No'],
  ['Yes', 'No'],
  ['Yes', 'No'],
  [
    'Less than 30 days',
    '1-3 Months',
    '3-6 Months',
    '6 -12 Month',
    'More than 12 Months',
  ],
  // [
  //   {
  //     name: 'I have read and understand this approach to medical care',
  //     isChecked: false,
  //   },
  // ],
  // Metadata.stateResidence,
]

class AilaWebQuestions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedQuestion: 0,
      progressPercent: 0,
      patientAnswers: [
        {
          qn: 'Do you experience any of the following?',
          value: answerSet[0],
        },
        {
          qn: 'Do you experience any of the following?',
          value: answerSet[1],
        },
        {
          qn: 'Do you experience any of the following?',
          value: answerSet[2],
        },
        {
          qn: 'Have you ever been diagnosed with an autoimmune condition?',
          value: '',
        },
        {
          qn: 'Has your doctor ever suggested or suggested that you have an autoimmune condition?',
          value: '',
        },
        {
          qn: 'Do any of your first degree relatives (parents, children, siblings) have an autoimmune disease?',
          value: '',
        },

        {
          qn: 'Have you had a positive diagnosis for Covid-19?',
          value: '',
        },
        {
          qn: 'Are you experiencing long term symptoms after having Covid-19 (e.g. Long Covid)?',
          value: '',
        },

        {
          qn: 'For how long have you been experiencing post-Covid symptoms?',
          value: '',
        },
        // {
        //   qn: 'At Aila Health, we want to take care of you as a whole person. That means managing your current conditions and working to prevent illness before it starts. Our team will generally recommend that you pursue evidence based screening such as pap smears, mammograms, and colonoscopies. We will also generally recommend other preventive measures such as vaccines and STD checks. We understand each of you is an individual, and understanding you is part of who we are and how we treat you. When your symptoms do not respond to conventional treatment, we want to work with you to figure out why, and how to make you better. So let’s work together to get the preventive care that is so well studied, so that we can focus on getting you, and keeping you, on track towards your goals.',
        //   value: answerSet[9],
        // },

        // {
        //   qn: 'What state are you located in?',
        //   value: '',
        // },
      ],
      patient_condition: '',
      fName: '',
      lName: '',
      successorUrl: '',
      error: false,
      errorString: '',
    }
    this.renderMainContent = this.renderMainContent.bind(this)
    this.onNextPressed = this.onNextPressed.bind(this)
    this.onSubmitPressed = this.onSubmitPressed.bind(this)
    this.storeUsertoMongo = this.storeUsertoMongo.bind(this)
    this.saveDetailsToFirebase = this.saveDetailsToFirebase.bind(this)
    this.onPreviousPressed = this.onPreviousPressed.bind(this)
    this.getAnswerOptions = this.getAnswerOptions.bind(this)
    this.handleMultipleOptions = this.handleMultipleOptions.bind(this)
    this.handleRadioInput = this.handleRadioInput.bind(this)
    this.handleSelectInput = this.handleSelectInput.bind(this)
  }

  // componentDidMount() {
  //   if (!this.props.patient) {
  //     this.props.history.push('/signup/patient')
  //     return
  //   }
  // }

  onPreviousPressed() {
    if (this.state.selectedQuestion <= 0) this.setState({ selectedQuestion: 0 })
    else {
      this.state.selectedQuestion === 5 &&
      this.state.patientAnswers[3].value === 'Yes'
        ? this.setState({ selectedQuestion: this.state.selectedQuestion - 2 })
        : this.state.selectedQuestion === 9 &&
          this.state.patientAnswers[7].value === 'No'
        ? this.setState({ selectedQuestion: this.state.selectedQuestion - 2 })
        : this.setState({ selectedQuestion: this.state.selectedQuestion - 1 })
    }
  }

  onNextPressed() {
    if (this.state.selectedQuestion >= 8) this.setState({ selectedQuestion: 8 })
    else {
      this.state.selectedQuestion === 3 &&
      this.state.patientAnswers[3].value === 'Yes'
        ? this.setState({
            selectedQuestion: this.state.selectedQuestion + 2,
          })
        : this.state.selectedQuestion === 7 &&
          this.state.patientAnswers[7].value === 'No'
        ? this.setState({
            selectedQuestion: 7,
          })
        : this.setState({
            selectedQuestion: this.state.selectedQuestion + 1,
          })
    }
  }

  storeUsertoMongo = (firebaseID, token) => {
    let data = {
      firebase_id: firebaseID,
    }
    let config = {
      method: 'post',
      headers: { Authorization: 'JWT ' + token, x_firebase_id: firebaseID },
      url: `${Config.BACKEND_URL}users`,
      data: data,
    }
    axios(config)
      .then((resp) => {
        console.log('saved mongo data')
      })
      .catch((err) => {
        console.log('error when saving data', err)
      })
  }

  saveDetailsToFirebase = () => {
    let { patient, token } = this.props
    let ccfmDeeplink = localStorage.getItem('ccfm')
    let dacPilotDeeplink = localStorage.getItem('dac')
    let raPilotDeeplink = localStorage.getItem('rapilot')

    let toSaveObject = {
      email: this.props?.location?.state?.email_id.trim(),
      firstName: this.state.fName.trim(),
      lastName: this.state.lName.trim(),
      uid: patient.uid,
      lastSignedIn: new Date(),
      platform: 'web',
      signup_platform: 'web',
    }

    if (ccfmDeeplink === 'yes') {
      toSaveObject.ccfm = true
      toSaveObject.userType = 'ccfm-patient'
    }

    if (dacPilotDeeplink === 'yes') {
      toSaveObject.dac = true
      toSaveObject.userType = 'dac-patient'
    }

    if (raPilotDeeplink === 'yes') {
      toSaveObject.rapilot = true
      toSaveObject.userType = 'ra-pilot-patient'
    }

    firebase
      .firestore()
      .collection('users')
      .doc(patient.uid)
      .get()
      .then(async (doc) => {
        if (doc.exists) {
          //user data is already present in firestore, do not save again
          firebase
            .firestore()
            .collection('users')
            .doc(patient.uid)
            .update(toSaveObject)
            .then(() => {
              console.log('updated user last signed in ')
              this.props.addFirebaseUser(toSaveObject)
              this.props.addPatient(toSaveObject, 'gray')
              // this.storeUsertoMongo(patient.uid, token)
            })
            .catch((err) =>
              console.log('error when updating user last signed in', err),
            )
          // return
        } else {
          firebase
            .firestore()
            .collection('users')
            .doc(patient.uid)
            .set(toSaveObject)
            .then(() => {
              console.log('Saved user to firestore')
              this.props.addFirebaseUser(toSaveObject)
              this.props.addPatient(toSaveObject, 'gray')
              // this.storeUsertoMongo(patient.uid, token)
            })
            .catch((err) => {
              console.log(
                'somethign went wrong when saving user to firestore',
                err,
              )
            })
        }
      })
  }

  onSubmitPressed() {
    let { patientAnswers, patient_condition, fName, lName } = this.state
    let temp1 = []
    let temp2 = []
    let temp3 = []

    // let stateResidence = patientAnswers[9].value.abbreviation

    // if (!patientAnswers[9].value || patientAnswers[9].value.length === 0) {
    //   this.setState({ error: true })
    //   this.setState({ errorString: 'Please enter your state of residence ' })
    //   console.log('STATE RESIDENCE ERROR')
    //   return
    // }

    // if (!fName || fName.length === 0) {
    //   this.setState({ error: true })
    //   this.setState({ errorString: 'Please enter your first name ' })
    //   console.log('FNAME ERROR')
    //   return
    // }
    // if (!lName || lName.length === 0) {
    //   this.setState({ error: true })
    //   this.setState({ errorString: 'Please enter your last name' })
    //   console.log('LNAME ERROR')
    //   return
    // }

    patientAnswers[0].value.forEach((val) => {
      if (val.isChecked === true) temp1.push(val.name)
    })

    patientAnswers[1].value.forEach((val) => {
      if (val.isChecked === true) temp2.push(val.name)
    })

    patientAnswers[2].value.forEach((val) => {
      if (val.isChecked === true) temp3.push(val.name)
    })

    let data = {
      // patient_id: this.props?.location?.state?.patient_id,
      autoimmune: patientAnswers[3].value,
      doctor_suggested: patientAnswers[4].value,
      special_condition: patient_condition,
      autoimmune_relatives: patientAnswers[5].value,
      symptom1: temp1,
      symptom2: temp2,
      symptom3: temp3,
      covid_positive: patientAnswers[6].value,
      longterm_symptoms: patientAnswers[7].value,
      term_experience: patientAnswers[8].value,
      // aila_terms: temp4,
      // state_residence: patientAnswers[9].value,
    }
    console.log('FINAL ANSWER: ', data)

    if (!this.props?.patient) {
      this.props.history.push('/signup/patient', { questionnaire_data: data })
      return
    }

    // this.saveDetailsToFirebase()

    // let url = Config.BACKEND_URL + 'web/questionnaires'
    // axios({
    //   method: 'post',
    //   url: url,
    //   data: data,
    // })
    //   .then(() => {
    //     console.log('saved questionnaire')
    //   })
    //   .catch((err) => {
    //     console.log('error when posting patient web quess', err)
    //   })

    // if (
    //   stateResidence === 'CO' ||
    //   (patientAnswers[7].value === 'Yes' &&
    //     (stateResidence === 'AZ' ||
    //       stateResidence === 'NJ' ||
    //       stateResidence === 'NY' ||
    //       stateResidence === 'OR' ||
    //       stateResidence === 'VA' ||
    //       stateResidence === 'VT' ||
    //       stateResidence === 'WA' ||
    //       stateResidence === 'MN'))
    // ) {
    // this.props.history.push('/wix/aila/careteam', {
    //   stateResidence: stateResidence,
    //   fName: fName,
    //   lName: lName,
    // })
    // } else {
    //   this.props.history.push('/wix/aila-download', {
    //     patient_id: this.props?.location?.state?.patient_id,
    //     email_id: this.props?.location?.state?.email_id,
    //     patient_type: false,
    //   })
    // }

    this.setState({
      selectedQuestion: 0,
      progressPercent: 0,
      patientAnswers: [
        {
          qn: 'Do you experience any of the following?',
          value: answerSet[0],
        },
        {
          qn: 'Do you experience any of the following?',
          value: answerSet[1],
        },
        {
          qn: 'Do you experience any of the following?',
          value: answerSet[2],
        },
        {
          qn: 'Have you ever been diagnosed with an autoimmune condition?',
          value: '',
        },
        {
          qn: 'Has your doctor ever suggested or suggested that you have an autoimmune condition?',
          value: '',
        },
        {
          qn: 'Do any of your first degree relatives (parents, children, siblings) have an autoimmune disease?',
          value: '',
        },

        {
          qn: 'Have you had a positive diagnosis for Covid-19?',
          value: '',
        },
        {
          qn: 'Are you experiencing long term symptoms after having Covid-19 (e.g. Long Covid)?',
          value: '',
        },

        {
          qn: 'For how long have you been experiencing post-Covid symptoms?',
          value: '',
        },
        // {
        //   qn: 'At Aila Health, we want to take care of you as a whole person. That means managing your current conditions and working to prevent illness before it starts. Our team will generally recommend that you pursue evidence based screening such as pap smears, mammograms, and colonoscopies. We will also generally recommend other preventive measures such as vaccines and STD checks. We understand each of you is an individual, and understanding you is part of who we are and how we treat you. When your symptoms do not respond to conventional treatment, we want to work with you to figure out why, and how to make you better. So let’s work together to get the preventive care that is so well studied, so that we can focus on getting you, and keeping you, on track towards your goals.',
        //   value: answerSet[9],
        // },
        // {
        //   qn: 'What state are you located in?',
        //   value: '',
        // },
      ],
    })
  }

  handleSelectInput(val) {
    let tempQ = [...this.state.patientAnswers]
    tempQ[this.state.selectedQuestion].value = val
    this.setState({ patientAnswers: tempQ })
  }

  handleRadioInput(val) {
    let tempQ = [...this.state.patientAnswers]
    tempQ[this.state.selectedQuestion].value = val
    this.setState({ patientAnswers: tempQ })
  }

  handleMultipleOptions(value) {
    let tempq = this.state.patientAnswers[this.state.selectedQuestion].value
    tempq.forEach((item) => {
      if (item.name === value) item.isChecked = !item.isChecked
    })

    let temp = [...this.state.patientAnswers]
    temp[this.state.selectedQuestion].value = tempq

    this.setState({ patientAnswers: temp })
  }

  getAnswerOptions() {
    if (this.state.selectedQuestion === 9) {
      return (
        <div
          style={{
            margin: '2vh',
            // textAlign: 'left',
            fontSize: '3vh',
            // height: '40vh',
          }}
        >
          <Select
            placeholder="Select State of residence"
            inputId="state"
            options={Metadata.stateResidence}
            isMulti={false}
            isSearchable
            autoBlur
            onChange={(val) => {
              this.handleSelectInput(val)
              // console.log('STATE: ', val)
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        </div>
      )
    } else if (
      this.state.selectedQuestion > 2 &&
      this.state.selectedQuestion !== 9
    ) {
      return (
        <Form.Group
          controlId={questionSet[this.state.selectedQuestion]}
          style={{
            margin: '2vh',
            textAlign: 'left',
            fontSize: '3vh',
          }}
        >
          {answerSet[this.state.selectedQuestion].map((type) => (
            <Form.Check
              type={'radio'}
              label={type}
              value={type}
              onClick={(val) => {
                this.handleRadioInput(val.target.value)
              }}
              style={{ color: 'rgb(23,61,68)', fontFamily: 'Avenir' }}
              checked={
                this.state.patientAnswers[this.state.selectedQuestion].value ===
                type
              }
              classes={Styles.radioButton}
            />
          ))}
        </Form.Group>
      )
    } else if (this.state.selectedQuestion < 3)
      return (
        <Form.Group
          controlId={questionSet[this.state.selectedQuestion]}
          style={{ margin: '2vh', textAlign: 'left', fontSize: '3vh' }}
        >
          {this.state.patientAnswers[this.state.selectedQuestion].value.map(
            (type) => {
              return (
                <>
                  <label class={Styles.checkContainer}>
                    {type.name}
                    <input
                      type="checkbox"
                      checked={type.isChecked}
                      onClick={() => {
                        // console.log('clocked')
                        this.handleMultipleOptions(type.name)
                      }}
                    />
                    <span class={Styles.checkmark}></span>
                  </label>
                </>
              )
            },
          )}
        </Form.Group>
      )
  }
  renderMainContent = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          minHeight: '50vh',
          justifyContent: 'center',
          textAlign: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            width: '70%',
            alignSelf: 'center',
            marginTop: '4vh',
            justifyContent: 'center',
            overflow: 'hidden',
          }}
        >
          <p
            style={{
              fontSize: '4vh',
              margin: 'auto',
              color: values.primaryThemeDark,
              textAlign: this.state.selectedQuestion === 9 ? 'center' : 'left',
              fontWeight: 'bold',
              fontFamily: 'Avenir',
              wordWrap: 'break-word',
            }}
          >
            {questionSet[this.state.selectedQuestion]}
          </p>
          {/* )} */}
          {this.getAnswerOptions()}

          {this.state.selectedQuestion === 4 &&
            this.state.patientAnswers[4].value === 'Yes' && (
              <div>
                <p
                  style={{
                    fontSize: '3vh',
                    margin: 'auto',
                    color: values.primaryThemeDark,
                    textAlign: 'left',
                    fontWeight: 'bold',
                    fontFamily: 'Avenir',
                    wordWrap: 'break-word',
                  }}
                >
                  {specialDocQuestion}
                </p>

                <div style={{ width: '50%', margin: '20px' }}>
                  <FancyField
                    value={this.state.patient_condition}
                    required={false}
                    name="specialInput"
                    //   autoFocus
                    onChange={(val) =>
                      this.setState({ patient_condition: val })
                    }
                    placeholder="Enter your condition... "
                  />
                </div>
              </div>
            )}

          {this.state.selectedQuestion === 9 && (
            <div style={{ margin: '50px auto' }}>
              <p
                style={{
                  fontSize: '4vh',
                  margin: '10px auto',
                  color: values.primaryThemeDark,
                  textAlign: 'center',
                  fontWeight: 'bold',
                  fontFamily: 'Avenir',
                  wordWrap: 'break-word',
                }}
              >
                What is your name?
              </p>

              {/* <div style={{ width: '50%', margin: '20px' }}>
                <FancyField
                  value={this.state.fName}
                  label="First Name"
                  name="fnameInput"
                  onChange={(val) => this.setState({ fname: val })}
                  placeholder="First Name"
                />
              </div>

              <div style={{ width: '50%', margin: '20px' }}>
                <FancyField
                  value={this.state.lname}
                  label="Last Name"
                  name="lnameInput"
                  onChange={(val) => this.setState({ lName: val })}
                  placeholder="Last Name"
                />
              </div> */}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '50%',
                  margin: '10px auto',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{ width: '50%', margin: '20px', textAlign: 'left' }}
                >
                  <FancyField
                    value={this.state.fName}
                    label="First Name"
                    name="fnameInput"
                    onChange={(val) => this.setState({ fName: val })}
                    placeholder="First Name"
                  />
                </div>

                <div
                  style={{ width: '50%', margin: '20px', textAlign: 'left' }}
                >
                  <FancyField
                    value={this.state.lName}
                    label="Last Name"
                    name="lnameInput"
                    onChange={(val) => this.setState({ lName: val })}
                    placeholder="Last Name"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
  render() {
    let pcal = ((this.state.selectedQuestion + 1) / questionSet.length) * 100

    return (
      <div
        className={GlobalStyles.container}
        style={{
          overflow: 'hidden',
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'left',
            alignSelf: 'left',
            marginTop: '1vh',
          }}
        >
          <img
            alt="Aila Health"
            src={AilaLogo}
            style={{
              width: '15vh',
              height: '15vh',
              alignSelf: 'flex-start',
            }}
          />
        </div>

        {this.renderMainContent()}

        <p
          style={{
            marginTop: 20,
            fontSize: 15,
            color: 'red',
            visibility: this.state.error ? 'visible' : 'hidden',
            textAlign: 'center',
          }}
        >
          {this.state.errorString}
        </p>

        <div
          style={{
            width: '80%',
            alignSelf: 'self-start',
            marginRight: '5vh',
            marginLeft: '10vh',
          }}
        >
          <Progress_bar
            bgcolor={values.primaryThemeMedium}
            progress={pcal}
            height={20}
          />
        </div>

        <div
          style={{
            flex: 1,
            alignSelf: 'self-end',
            marginRight: '5vh',
          }}
        >
          <Button
            onClick={() => this.onPreviousPressed()}
            className={Styles.button2}
            style={{
              margin: '1vh',
              width: 200,
              color: '#20A892',
              fontWeight: 'bold',
            }}
            variant="primary"
          >
            {'Previous'}
          </Button>

          <Button
            onClick={() => {
              this.state.selectedQuestion === questionSet.length - 1 ||
              (this.state.selectedQuestion === 7 &&
                this.state.patientAnswers[7].value === 'No')
                ? this.onSubmitPressed()
                : this.onNextPressed()
            }}
            className={GlobalStyles.button}
            style={{
              fontWeight: 'bold',
              margin: '1vh',
              width: 200,
            }}
            variant="primary"
          >
            {this.state.selectedQuestion === questionSet.length - 1 ||
            (this.state.selectedQuestion === 7 &&
              this.state.patientAnswers[7].value === 'No')
              ? 'Submit'
              : 'Next'}
          </Button>
        </div>
        <OnboardingFooter />
      </div>
    )
  }
}

const mapStateToProps = (state /* , ownProps */) => {
  return {
    patient: state.patientReducer.patient,
    token: state.authReducer.token,
    firebaseAuthUser: state.userReducer.firebaseAuthUser,
  }
}

const mapDispatchToProps = {
  addFirebaseUser,
  addFirebaseAuthUser,
  addPatient,
}

export default connect(mapStateToProps, mapDispatchToProps)(AilaWebQuestions)
