import React, { Component, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlusCircle,
  faStar,
  faCheckCircle,
  faExclamationCircle,
  faTrash,
  faEye,
} from '@fortawesome/free-solid-svg-icons'
import { ToastContainer, toast } from 'react-toastify'
import Modal from 'react-modal'
import DatePicker from 'react-date-picker'

import Select from 'react-select'
import Button from 'react-bootstrap/Button'
import { loadStripe } from '@stripe/stripe-js'
import { addMongoUser, stopTimer } from '../../redux/actions'
import firebase from '../../services/firebase.js'
import PlaceHolderProfileImage from '../../assets/images/placeholder.png'
import metadata, * as Metadata from '../../metadata.js'

import GlobalStyles from '../styles/global.module.scss'
import Header from '../../components/Header'
import Constants from '../../values.js'
import Styles from './styles/Credentialing.module.scss'
import Config from '../../config'
import FileViewer from '../../components/pdf/FileViewer'

const axios = require('axios')
const windowHeight = window.innerHeight
const windowWidth = window.innerWidth
const customModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: windowWidth / 2,
    height: windowHeight / 2,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
  },
}

const customDocModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 10,
  },
  content: {
    zIndex: 10,
    width: '80vw',
    height: '90vh',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const customModalStyles2 = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: windowWidth - 200,
    height: windowHeight - 20,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
  },
}

const WorkHistory = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const twilioToken = useSelector((state) => state.genericReducer?.twilioToken)
  const token = useSelector((state) => state.authReducer.token)

  const mongoUser = useSelector((state) => state.userReducer?.providerData)
  const userType = useSelector((state) => state.userReducer?.userType)
  const adminId = useSelector((state) => state.userReducer?.adminId)
  const firebaseAuthUser = useSelector(
    (state) => state.userReducer?.firebaseAuthUser,
  )

  const [loading, setLoading] = useState(false)
  const [openDoc, setOpenDoc] = useState(false)
  const [docFile, setDocFile] = useState('')

  //WORK HISTORY DATA
  const [reference1, setReference1] = useState(null)
  const [reference2, setReference2] = useState(null)
  const [reference3, setReference3] = useState(null)
  const [workHistory, setWorkHistory] = useState([])
  const [currentCv, setCurrentCv] = useState('')

  //IDENTIFICATION DATA
  const [npi, setNpi] = useState(null)
  const [caqh, setCaqh] = useState('')
  const [accessData, setAccessData] = useState(false)
  const [dea, setDea] = useState('')
  const [cds, setCds] = useState('')
  const [medicareId, setMedicareId] = useState('')
  const [medicaidId, setMedicaidId] = useState('')
  const [attest, setAttest] = useState(false)

  useEffect(() => {
    console.log('testing')
    setLoading(true)
    getProviderData()
    setLoading(false)
  }, [])

  const getProviderData = () => {
    setLoading(true)
    // console.log('mongo user::', adminId, userType, mongoUser)
    // console.log('firebaseuser::', firebaseAuthUser)

    const url = `${Config.BACKEND_URL}providers`
    return axios({
      method: 'get',
      url,
      headers: { x_firebase_id: mongoUser.firebase_id, Authorization: `JWT ${token}` },
    })
      .then(({ data }) => {
        console.log('got doctor mongo details', data)

        setCurrentCv(data?.current_cv || '')
        setReference1(data?.work_history?.reference1 || null)
        setReference2(data?.work_history?.reference2 || null)
        setReference3(data?.work_history?.reference3 || null)
        setWorkHistory(
          data?.work_history?.work_history || [
            {
              employer: '',
              from: new Date(),
              to: new Date(),
            },
          ],
        )

        setLoading(false)
        // dispatch(addMongoUser(data))
      })
      .catch((err) => {
        console.log('error when getting doctor mongo users workhistory', err)
      })
  }

  const onSavePressed = () => {
    let returnData = {
      work_history: {
        work_history: workHistory,
        reference1: reference1,
        reference2: reference2,
        reference3: reference3,
      },
      current_cv: currentCv,
    }
    // console.log(returnData)
    props.parentCallback(returnData)
  }

  // On file select (from the pop up)
  const onFileChange = (event) => {
    // Update the state
    console.log(event)
    if (event?.target?.files[0]) {
      console.log(event?.target?.files[0])
      //   this.setState({
      //     modalFileName: event.target.files[0].name,
      //     modalFileType: event.target.files[0].type,
      //   })

      // if (event?.target?.files[0].type !== 'application/pdf') {
      //   toast.warning('Please upload PDF files')
      //   return
      // }
      setLoading(true)

      //SAVE IN FIRESTORE STORAGE
      const file = event?.target?.files[0]
      const storageRef = firebase.storage().ref()
      const uploadTask = storageRef
        .child(`providers/${mongoUser.firebase_id}/CV_${file.name}`)
        .put(file)
      uploadTask.on(
        'state_changed',
        function (snapshot) {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log(`${progress}%`)
        },
        function (err) {
          // Handle unsuccessful uploads
          console.log('error when uploading image', err)
          setLoading(false)
          // self.saveUserToBackend(user)
        },
        function () {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            console.log(downloadURL)
            setCurrentCv(downloadURL)
            setLoading(false)
          })
        },
      )
    }
  }

  const renderLoader = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '100%',
        height: '60vh', // do not change this
      }}
    >
      <div className={GlobalStyles.loader} />
    </div>
  )

  const verifyNpiPressed = () => {
    if (!npi || npi.length !== 10) {
      // npi number must be exactly 10 digits
      toast.warning(
        'NPI number must be unique 10-digit National Provider Identifier assigned to the provider',
      )
      //   this.setState({
      //     error: true,
      //     modalErrorString:
      //       'NPI number must be unique 10-digit National Provider Identifier assigned to the provider',
      //   })
      return
    }

    // use the NPI registry API to verify the provided NPI
    // const { mongoUser, token } = this.props
    const url = `${Config.BACKEND_URL}provider/npi-verification`
    axios({
      method: 'post',
      headers: { Authorization: `JWT ${token}` },
      url,
      data: { number: npi, firebase_id: mongoUser.firebase_id },
    })
      .then(({ data }) => {
        console.log('got the response', data)
        if (data.npiVerification) {
          toast.success('Successfully verified NPI. You are all set.')
          //   this.props.addMongoUser(data.user)
          dispatch(addMongoUser(data.user))
          //   this.setState({
          //     modalLoading: false,
          //     modalError: false,
          //     modalIsOpen: false,
          //   })
        } else {
          toast.error('Invalid NPI number')
          //   this.setState({
          //     modalLoading: false,
          //     modalError: true,
          //     modalErrorString: 'Invalid NPI number. Could not verify.',
          //   })
        }
      })
      .catch((err) => {
        console.log('error when verifying NPI', err)
        toast.warning(
          'The verification system is down. Please try again later. If the problem persists, contact support.',
        )
        // this.setState({
        //   modalLoading: false,
        //   modalError: true,
        //   modalErrorString:
        //     'The verification system is down. Please try again later. If the problem persists, contact support. ',
        // })
      })
  }

  const renderHistories = () => {
    return (
      workHistory &&
      workHistory?.map((work, index) => {
        return (
          <div
            key={index.toString()}
            style={{
              width: '100%',
              marginBottom: '4%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                width: '100%',
                marginBottom: '2%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  width: '40%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <p className={Styles.rowLabel} style={{ width: '100%' }}>
                  Employer
                </p>
                <div style={{ width: '80%', marginLeft: 10 }}>
                  <input
                    placeholder="Employer"
                    className={Styles.textInput}
                    style={{ width: '80%' }}
                    type="text"
                    value={work.employer}
                    onChange={(e) => {
                      let temp = [...workHistory]
                      temp[index].employer = e.target.value
                      setWorkHistory(temp)
                      //   const { licenseNumbers } = this.state
                      //   licenseNumbers[index] = e.target.value
                      //   this.setState({ licenseNumbers })
                    }}
                  />
                </div>
              </div>

              <div
                style={{
                  width: '40%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <p className={Styles.rowLabel} style={{ width: '100%' }}>
                  From
                </p>
                <div style={{ width: '80%', marginLeft: 10 }}>
                  <DatePicker
                    // minDate={new Date('09/01/2019')}
                    // format={'MMM-dd-y'}
                    onChange={(date) => {
                      let temp = [...workHistory]
                      temp[index].from = date
                      setWorkHistory(temp)
                    }}
                    value={work.from ? new Date(work.from) : new Date()}
                    style={{ width: '40%', margin: '0% 0% 0% 5%' }}
                  />
                </div>
              </div>

              <div
                style={{
                  width: '40%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <p className={Styles.rowLabel} style={{ width: '100%' }}>
                  To
                </p>
                {work.present ? (
                  <p
                    className={Styles.rowLabel}
                    style={{
                      width: '100%',
                      // visibility: work.present ? 'visible' : 'hidden',
                      fontStyle: 'italic',
                      fontWeight: 'bold',
                    }}
                  >
                    Present
                  </p>
                ) : (
                  <div
                    style={{
                      width: '100%',
                      marginLeft: 10,
                      // visibility: work.present ? 'hidden' : 'visible',
                    }}
                  >
                    <DatePicker
                      // minDate={new Date('09/01/2019')}
                      // format={'MMM-dd-y'}
                      disabled={work?.present}
                      onChange={(date) => {
                        let temp = [...workHistory]
                        temp[index].to = date
                        setWorkHistory(temp)
                      }}
                      value={work.to ? new Date(work.to) : new Date()}
                      style={{ width: '40%', margin: '0% 0% 0% 5%' }}
                    />
                  </div>
                )}
              </div>
            </div>
            <div
              style={{
                width: '100%',
                marginBottom: '2%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <label style={{ color: '#20A892' }} class={Styles.checkContainer}>
                I am currently working in this role
                <input
                  type="checkbox"
                  checked={work?.present}
                  onClick={() => {
                    // console.log('clocked')
                    let temp = [...workHistory]
                    temp[index].present = !work?.present
                    setWorkHistory(temp)
                  }}
                />
                <span class={Styles.checkmark}></span>
              </label>
            </div>
          </div>
        )
      })
    )
  }

  const renderContent = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: '1% 5%',
        }}
      >
        {/*  WORK HISTORY */}
        <div className={Styles.row}>
          <p className={Styles.rowLabel} style={{ alignSelf: 'flex-start' }}>
            Work History
          </p>
          <div
            style={{
              width: '80%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {renderHistories()}
          </div>
          <div
            onClick={() => {
              let temp = [...workHistory]
              temp.push({
                employer: '',
                from: new Date(),
                to: new Date(),
              })
              setWorkHistory(temp)
            }}
            style={{
              cursor: 'pointer',
              marginLeft: 10,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <FontAwesomeIcon
              icon={faPlusCircle}
              style={{
                color: Constants.primaryTheme,
                fontSize: 20,
                marginRight: 5,
              }}
            />
            <p style={{ color: Constants.primaryTheme }}>Add another</p>
          </div>
        </div>

        {/* Professional Reference 1 */}
        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Professional Reference 1</p>
          <input
            placeholder="Name"
            className={Styles.textInput}
            style={{ width: '20%' }}
            type="text"
            value={reference1?.name}
            onChange={(e) => {
              let temp = { ...reference1 }
              temp.name = e.target.value
              setReference1(temp)
            }}
          />
          <input
            placeholder="Email"
            className={Styles.textInput}
            style={{ width: '20%' }}
            type="text"
            value={reference1?.email}
            onChange={(e) => {
              let temp = { ...reference1 }
              temp.email = e.target.value
              setReference1(temp)
            }}
          />
          <input
            placeholder="Phone"
            className={Styles.textInput}
            style={{ width: '20%' }}
            type="text"
            value={reference1?.phone}
            onChange={(e) => {
              let temp = { ...reference1 }
              temp.phone = e.target.value
              setReference1(temp)
            }}
          />
        </div>

        {/* Professional Reference 2 */}
        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Professional Reference 2</p>
          <input
            placeholder="Name"
            className={Styles.textInput}
            style={{ width: '20%' }}
            type="text"
            value={reference2?.name}
            onChange={(e) => {
              let temp = { ...reference2 }
              temp.name = e.target.value
              setReference2(temp)
            }}
          />
          <input
            placeholder="Email"
            className={Styles.textInput}
            style={{ width: '20%' }}
            type="text"
            value={reference2?.email}
            onChange={(e) => {
              let temp = { ...reference2 }
              temp.email = e.target.value
              setReference2(temp)
            }}
          />
          <input
            placeholder="Phone"
            className={Styles.textInput}
            style={{ width: '20%' }}
            type="text"
            value={reference2?.phone}
            onChange={(e) => {
              let temp = { ...reference2 }
              temp.phone = e.target.value
              setReference2(temp)
            }}
          />
        </div>

        {/* Professional Reference 3 */}
        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Professional Reference 3</p>
          <input
            placeholder="Name"
            className={Styles.textInput}
            style={{ width: '20%' }}
            type="text"
            value={reference3?.name}
            onChange={(e) => {
              let temp = { ...reference3 }
              temp.name = e.target.value
              setReference3(temp)
            }}
          />
          <input
            placeholder="Email"
            className={Styles.textInput}
            style={{ width: '20%' }}
            type="text"
            value={reference3?.email}
            onChange={(e) => {
              let temp = { ...reference3 }
              temp.email = e.target.value
              setReference3(temp)
            }}
          />
          <input
            placeholder="Phone"
            className={Styles.textInput}
            style={{ width: '20%' }}
            type="text"
            value={reference3?.phone}
            onChange={(e) => {
              let temp = { ...reference3 }
              temp.phone = e.target.value
              setReference3(temp)
            }}
          />
        </div>

        {/* Upload CDS (Controlled and Dangerous Substances) certificate */}
        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Upload Current CV</p>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            {currentCv && (
              <FontAwesomeIcon
                onClick={() => {
                  setDocFile(currentCv)
                  setOpenDoc(true)
                }}
                icon={faEye}
                style={{
                  color: Constants.primaryTheme,
                  fontSize: 20,
                  width: '10%',
                  cursor: 'pointer',
                }}
              />
            )}
            <label
              htmlFor="proof_files"
              style={{
                textDecoration: 'underline',
                color: Constants.primaryTheme,
                cursor: 'pointer',
              }}
            >
              Upload PDF
            </label>
            <input
              id="proof_files"
              style={{ display: 'none' }}
              type="file"
              accept=".pdf"
              onChange={onFileChange}
            />
            {/* <input
              placeholder="Slect File to be Uploaded"
              // className={Styles.textInput}
              style={{ width: '100%' }}
              type="file"
              onChange={onFileChange}
            /> */}
          </div>
        </div>

        <div
          style={{
            width: '100%',
            marginTop: 60,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          <Button
            onClick={onSavePressed}
            className={GlobalStyles.button}
            disabled={loading}
            style={{ width: 200 }}
            variant="primary"
          >
            {loading ? 'Loading....' : 'Save'}
          </Button>
          {/* {error && (
            <p style={{ marginTop: 4, fontSize: 12, color: 'red' }}>
              {errorString}
            </p>
          )} */}
        </div>

        <Modal
          ariaHideApp={false}
          isOpen={openDoc}
          onRequestClose={() => {
            setOpenDoc(false)
            setDocFile('')
          }}
          style={customDocModalStyles}
          contentLabel="Modal"
        >
          <FileViewer fileType="pdf" fileName={docFile} />
          {/* <iframe
            style={{ height: '100%', width: '100%' }}
            src={`data:application/pdf;base64,${docFile}`}
          /> */}
        </Modal>
      </div>
    )
  }

  if (loading) return renderLoader()
  return renderContent()
}

export default WorkHistory
