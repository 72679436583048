import React, { Component, useState } from 'react'
import socketIOClient from 'socket.io-client'
import 'react-chat-elements/dist/main.css'
import Linkify from 'react-linkify'
import { ChatItem } from 'react-chat-elements'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFileMedical,
  faUserLock,
  faCalendarPlus,
  faPaperclip,
  faVideo,
  faMicrophone,
  faMicrophoneSlash,
  faVideoSlash,
} from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import Modal from 'react-modal'
import DateTimePicker from 'react-datetime-picker'
import Fuse from 'fuse.js'
import Switch from 'react-switch'
import { Link } from 'react-router-dom'
import update from 'react-addons-update'
import { connect } from 'react-redux'
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify'
import Constants from '../../../values.js'
// import Header from '../../components/Header'
import GlobalStyles from '../../styles/global.module.scss'
import Styles from './styles/MessageChat.module.scss'
import Config from '../../../config'
import Video from '../../Appointments/Video'
import {
  stopTimer,
  updateSideNavBar,
  addRpmPatient,
  addPatientLP,
  getUnreadMessages,
  resetTimer,
  startTimer,
  addVirgilE3,
  updateDoctorMongoUser,
  addMongoUser,
  addPatient,
  userLoggedIn,
  fetchPatientCoreDate,
  fetchPatientProgressData,
  fetchPatientWellnessScreening,
  fetchPatientCalculateProgressData,
  updatePatientData,
  addPatientList,
  setPatientCoreData,
  fetchPatientConditions,
  fetchPatientMedications,
  fetchPatientLabs,
} from '../../../redux/actions'
import EducationMessages from '../../Messages/EducationMessages'
import QuestionnaireMessages from '../../Messages/QuestionnaireMessages'
import PatientExperienceMessages from '../../Messages/PatientExperienceMessages'

const momentDurationFormatSetup = require('moment-duration-format')
const axios = require('axios')
momentDurationFormatSetup(moment)

// const ENDPOINT = 'https://chat.ailahealth.net'
// const ENDPOINT = 'http://localhost:8080/'
const PLACEHOLDER_IMAGE =
  'https://firebasestorage.googleapis.com/v0/b/aila-health-b469c.appspot.com/o/images%2Fplaceholder.png?alt=media&token=89bc348e-7ccd-4e35-a291-50c6e21a3b6a'

const windowHeight = window.innerHeight
const SCHEDULE_MODAL_STYLES = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '50%',
    height: windowHeight / 1.2,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
  },
}

const MODAL_STYLES = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '60%',
    height: '90%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
  },
}

const AILA_IMAGE_LOCATION =
  'https://firebasestorage.googleapis.com/v0/b/aila-health-b469c.appspot.com/o/images%2Faila_logo.png?alt=media&token=f0018d3c-4875-4d49-babf-a187c92cd7ec'

class MessageChat extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchText: '',
      messageText: '',
      patientActiveIndex: props?.location?.ai ? props?.location?.ai : 0,
      previousKeyDown: '',
      messageData: [],
      loading: true,
      ailaMessages: null,
      ailaMessageSelected: false,
      modalIsOpen: false,
      videoModalIsOpen: false,
      toggleModalChecked: 'Content Library',
      modalECIsOpen: false,
      schedulemodalECIsOpen: false,
      modalECLoading: false,
      modalLoading: false,
      patientList: [],
      modalPatients: null,
      modalMessage: '',
      modalError: false,
      modalSchedule: new Date(),
      modalErrorString: '',
      updatedUnread: false,
      searchResults: null,
      showSearchResults: false,
      scheduleModalChecked: false,
      modalCohortOptions: [],
      modalCohortSelection: null,
      twilioToken: null,
      videoPatientId: null,
      roomName: null,
      videoCallJoined: false,
      videoCallRoom: null,
      audioMuted: false,
      videoMuted: false,
      listEducation: [],
      listQuestion: [],
      listPatientExperience: [],
      listTitles: [],
    }
    this.socket = null
    this.fuse = null
    this.patientListFull = []
    this.fileUploaderRef = React.createRef()
    this.dummyRef = React.createRef()
    this.scrollToBottom = this.scrollToBottom.bind(this)
    this.getMessagesFromBackend = this.getMessagesFromBackend.bind(this)
    this.updateUnread = this.updateUnread.bind(this)
    this.preparePatientList = this.preparePatientList.bind(this)
    this.scheduleMessageInBackend = this.scheduleMessageInBackend.bind(this)
    this.prepareSocket = this.prepareSocket.bind(this)
    this.decryptMessageToDisplay = this.decryptMessageToDisplay.bind(this)
    this.decryptMessages = this.decryptMessages.bind(this)
    this.fetchAilaMessages = this.fetchAilaMessages.bind(this)
    this.fetchTwilioToken = this.fetchTwilioToken.bind(this)
  }

  componentDidMount() {
    if (!this.props.loggedIn) {
      this.props.history.push('/login')
      return
    }
    const { mongoUser, token, adminId } = this.props
    console.log('mongoUser: ', mongoUser)
    if (this.props?.location?.ai)
      this.setState({ patientActiveIndex: this.props?.location?.ai })

    this.props.stopTimer(adminId, token)
    this.props.getUnreadMessages(adminId, token, mongoUser)
    this.fetchTwilioToken()
    this.fetchAilaMessages()
    this.prepareSocket()
    this.getMessagesFromBackend()
    this.preparePatientList()
    // this.testEncryption()
    if (this.props.mongoUser && this.props.mongoUser.cohorts) {
      const cohorts = []
      this.props.mongoUser.cohorts.forEach((each) => {
        cohorts.push({ label: each, value: each })
      })
      this.setState({ modalCohortOptions: cohorts })
    }

    // this.scrollToBottom()
  }

  prepareSocket() {
    const { mongoUser } = this.props

    this.socket = socketIOClient(Config.CHAT_ENDPOINT)
    this.socket.on('connect', () => {
      console.log('connected to chat server')
      this.socket.emit('login', { firebase_id: mongoUser.firebase_id })
    })

    this.socket.on('logged_in_clients', (data) => {
      console.log('logged in clients', data)
    })

    this.socket.on('connecting', () => {
      console.log('connecting to chat server....')
    })

    this.socket.on('connect_failed', () => {
      console.log('connection to chat server failed :(')
    })

    const eventNameToListen = `${mongoUser.firebase_id}Message`
    this.socket.on(eventNameToListen, async (data) => {
      const { e3 } = this.props

      try {
        const senderCard = await e3.findUsers(data.sender_id)

        // Decrypt text and ensure it was written by sender
        const decryptedText = await e3.authDecrypt(data.message, senderCard)
        const newMessage = {
          message: decryptedText,
          sender: 'Patient',
          created_at: data.created_at,
        }

        const { messageData } = this.state
        messageData.every((each) => {
          if (each.uid === data.sender_id) {
            const { messages } = each
            messages.push(newMessage)
            each.unread_doc += 1
            return false
          }
          return true
        })

        let dataPassed = messageData.filter((message) => !message.inactive)

        dataPassed.sort((a, b) => {
          return moment(b?.messages[b.messages.length - 1]?.created_at).diff(
            moment(a?.messages[a.messages.length - 1]?.created_at),
          )
        })

        dataPassed.sort((a, b) => {
          return b.unread_doc - a.unread_doc
        })
        // messageData.sort((a, b) => {
        //   return moment(b?.messages[b.messages.length - 1]?.created_at).diff(
        //     moment(a?.messages[a.messages.length - 1]?.created_at),
        //   )
        // })

        // messageData.sort((a, b) => {
        //   return b.unread_doc - a.unread_doc
        // })

        this.setState({ messageData: dataPassed, updatedUnread: false })

        this.scrollToBottom()
      } catch (err) {
        console.log('error when decoding received text', err)
      }
    })
  }

  fetchTwilioToken() {
    const { token, mongoUser } = this.props
    const url = `${Config.BACKEND_URL}twilio/token`
    axios({
      method: 'get',
      url,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: mongoUser.firebase_id,
      },
    })
      .then((result) => {
        const { token } = result.data
        console.log('got twilio token')
        this.setState({ twilioToken: token })
      })
      .catch((err) => {
        console.log('error when getting twilio token', err)
      })
  }

  preparePatientList() {
    const list = []
    const { patientList } = this.props
    if (!patientList) return

    Object.values(patientList).forEach((colorCodedPatients) => {
      if (colorCodedPatients.length > 0) {
        colorCodedPatients.forEach((patient) => {
          list.push({
            label: `${patient.firstName} ${patient.lastName}`,
            value: patient.uid,
          })
          this.patientListFull.push(patient)
        })
      }
    })
    this.setState({ patientList: list })
  }

  componentWillUnmount() {
    if (this.socket) this.socket.disconnect()
  }

  updateUnreadAilaMessages() {
    const { patientActiveIndex, messageData, ailaMessages } = this.state
    const { mongoUser, token } = this.props
    const self = this
    const url = `${Config.BACKEND_URL}aila-messages/read`
    axios({
      method: 'put',
      url,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: mongoUser.firebase_id,
      },
    })
      .then(() => {
        ailaMessages.unread_messages = 0
        self.setState({ ailaMessages })
      })
      .catch((err) => {
        console.log('error when updating unread messages', err)
      })
  }

  updateUnread() {
    const { patientActiveIndex, messageData } = this.state
    const { mongoUser, token } = this.props
    const self = this
    const url = `${Config.BACKEND_URL}provider/messages/unread`
    axios({
      method: 'put',
      url,
      headers: {
        Authorization: `JWT ${token}`,
        x_doctor_id: mongoUser.firebase_id,
        x_patient_id: patientActiveIndex,
        // messageData[patientActiveIndex].uid,
      },
    })
      .then(() => {
        let index = messageData.findIndex(
          (obj) => obj.patient_id === patientActiveIndex,
        )
        messageData[index].unread_doc = 0
        self.setState({ messageData, updatedUnread: true })
      })
      .catch((err) => {
        console.log('error when updating unread messages', err)
      })
  }

  fetchAilaMessages() {
    const { mongoUser, token } = this.props
    const self = this
    const url = `${Config.BACKEND_URL}aila-messages`
    axios({
      method: 'get',
      url,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: mongoUser.firebase_id,
      },
    })
      .then(({ data }) => {
        self.setState({ ailaMessages: data })
      })
      .catch((err) => {
        console.log('error when getting messages from backend', err)
      })
  }

  getMessagesFromBackend() {
    const { mongoUser, token, patient } = this.props
    const self = this
    const url = `${Config.BACKEND_URL}provider/messages`
    axios({
      method: 'get',
      url,
      headers: {
        Authorization: `JWT ${token}`,
        x_doctor_id: mongoUser.firebase_id,
        x_patient_id: patient?.uid,
      },
    })
      .then(({ data }) => {
        // self.setState({messageData: data || [],loading: false})
        // self.scrollToBottom()
        //FILTERING OUT INACTIVE PATIENTS
        let dataPassed = data.filter((message) => !message.inactive)

        dataPassed.sort((a, b) => {
          return moment(b?.messages[b.messages.length - 1]?.created_at).diff(
            moment(a?.messages[a.messages.length - 1]?.created_at),
          )
        })

        dataPassed.sort((a, b) => {
          return b.unread_doc - a.unread_doc
        })

        self.decryptMessages(dataPassed)
      })
      .catch((err) => {
        console.log('error when getting messages from backend', err)
      })
  }

  async decryptMessages(messageData) {
    const { mongoUser, e3, patientCore } = this.props
    if (!messageData || messageData.length === 0 || !e3) {
      this.setState({ messageData: [], loading: false })
      return
    }

    let activeIndex =
      this.state.patientActiveIndex === 0
        ? messageData[0].patient_id
        : this.state.patientActiveIndex
    // this.state.patientActiveIndex
    for (let i = 0; i < messageData.length; i++) {
      const patientMessage = messageData[i]
      patientMessage.index = i
      try {
        const doctorCard = await e3.findUsers(mongoUser.firebase_id)
        const sender = patientMessage.patient_id
        // if coming from the patient profile pages by clicking on the top bar
        if (
          patientCore &&
          patientCore.firebase_id === patientMessage.patient_id
        ) {
          // activeIndex = i
          activeIndex = patientMessage.patient_id
        }

        const senderCard = await e3.findUsers(sender)

        if (patientMessage.messages && patientMessage.messages.length > 0) {
          // if there are messages, decrypt it
          const newMessages = []
          for (let j = 0; j < patientMessage.messages.length; j++) {
            const eachMessage = patientMessage.messages[j]
            if (eachMessage.sender === 'Patient') {
              // console.log('testing: ', eachMessage?.support_request, patientMessage?.patient_id, eachMessage)
              // decrypting doctors own message
              try {
                let decryptedText = ''
                if (eachMessage.message && eachMessage.message.length) {
                  decryptedText = await e3.authDecrypt(
                    eachMessage.message,
                    senderCard,
                  )
                } else if (eachMessage?.support_request) {
                  decryptedText = eachMessage?.support_request
                }
                newMessages.push({
                  created_at: eachMessage.created_at,
                  message: decryptedText,
                  sender: eachMessage.sender,
                })
              } catch (err) {
                console.log('error when decrypting patients message', err)
                newMessages.push(eachMessage)
              }
            } else {
              try {
                let decryptedText
                if (eachMessage.message && eachMessage.message.length) {
                  decryptedText = await e3.authDecrypt(
                    eachMessage.message,
                    doctorCard,
                  )
                } else {
                  decryptedText = eachMessage?.automated_message || ''
                }
                {
                  eachMessage.education ||
                  eachMessage.education_ids ||
                  eachMessage.question ||
                  eachMessage.patientExperience
                    ? newMessages.push({
                        created_at: eachMessage.created_at,
                        message: decryptedText,
                        education: eachMessage.education,
                        education_ids: eachMessage.education_ids,
                        question: eachMessage.question,
                        patientExperience: eachMessage.patientExperience,
                        sender: eachMessage.sender,
                      })
                    : newMessages.push({
                        created_at: eachMessage.created_at,
                        message: decryptedText,
                        sender: eachMessage.sender,
                      })
                }
              } catch (err) {
                console.log('error when decrypting patients message', err)
                newMessages.push(eachMessage)
              }
            }
          }

          patientMessage.messages = newMessages
        } else {
          // messageData.sort((a, b) => {
          //   return moment(b?.messages[b.messages.length - 1]?.created_at).diff(
          //     moment(a?.messages[a.messages.length - 1]?.created_at),
          //   )
          // })

          // messageData.sort((a, b) => {
          //   return b.unread_doc - a.unread_doc
          // })

          this.setState({
            messageData,
            loading: false,
            patientActiveIndex: activeIndex,
          })
        }
      } catch (err) {
        console.log('error when trying to decyprt', err)

        // messageData.sort((a, b) => {
        //   return moment(b?.messages[b.messages.length - 1]?.created_at).diff(
        //     moment(a?.messages[a.messages.length - 1]?.created_at),
        //   )
        // })

        // messageData.sort((a, b) => {
        //   return b.unread_doc - a.unread_doc
        // })

        this.setState({
          messageData,
          loading: false,
          patientActiveIndex: activeIndex,
        })
      }
    }

    if (messageData && messageData.length > 0)
      this.prepareSearchIndex(messageData)

    // messageData.sort((a, b) => {
    //   return moment(b?.messages[b.messages.length - 1]?.created_at).diff(
    //     moment(a?.messages[a.messages.length - 1]?.created_at),
    //   )
    // })

    // messageData.sort((a, b) => {
    //   return b.unread_doc - a.unread_doc
    // })
    this.setState({
      messageData,
      loading: false,
      patientActiveIndex: activeIndex,
    })
    this.props.getUnreadMessages(
      this.props.adminId,
      this.props.token,
      this.props.mongoUser,
    )
  }

  prepareSearchIndex(messageData) {
    const options = {
      includeScore: true,
      keys: ['firstName', 'lastName'],
    }

    this.fuse = new Fuse(messageData, options)
  }

  onSearchTextChanged(searchText) {
    this.setState({ searchText })
    if (!this.fuse) return

    if (searchText.length > 0) {
      const results = this.fuse.search(searchText)
      const searchResults = []
      results.forEach((result) => {
        searchResults.push(result.item)
      })

      this.setState({
        showSearchResults: true,
        searchResults,
        patientActiveIndex: -1,
      })
    } else {
      this.setState({
        showSearchResults: false,
        patientActiveIndex: 0,
        ailaMessageSelected: false,
      })
    }
  }

  onPatientEntryClicked(index) {
    const { mongoUser, token, adminId } = this.props
    const { messageData } = this.state
    this.setState({ patientActiveIndex: index, ailaMessageSelected: false })
    this.props.getUnreadMessages(adminId, token, mongoUser)
    this.scrollToBottom()
  }

  scrollToBottom() {
    // if (this.dummyRef.current)
    //   this.dummyRef.current.scrollIntoView({ behavior: 'smooth' })

    var elem = document.getElementById('scrollingDiv')
    if (elem) {
      elem.scrollTop = elem.scrollHeight
    }
  }

  async onAddPressed() {
    this.setState({ schedulemodalECIsOpen: false })
  }
  async onSubmitPressed() {
    if (this.state.ailaMessageSelected) {
      toast.warning('Cannot send messages')
      toast.warning('Please submit a support ticket')
      return
    }
    let tempQuestions = []
    let tempPatientExperience = []
    this.state.listQuestion.forEach((ques) => {
      if (
        ques === 'Patient Experience (pre)' ||
        ques === 'Patient Experience (post)'
      ) {
        tempPatientExperience.push(ques)
      } else {
        tempQuestions.push(ques)
      }
    })
    const message = this.state.messageText
    const education = this.state.listTitles
    const education_ids = this.state.listEducation
    const question = tempQuestions
    const patientExperience = tempPatientExperience

    // if (!message && message.length === 0) return

    const { patientActiveIndex, messageData } = this.state
    // const patient = messageData[patientActiveIndex]
    const patient = messageData.filter((obj) => {
      return obj.patient_id === patientActiveIndex
    })[0]

    try {
      let val = {}
      const { e3 } = this.props
      const identities = [patient.uid]

      // Find users cards with public keys
      const findUsersResult = await e3.findUsers(identities)

      // Encrypt text string
      const encryptedText = await e3.authEncrypt(message, findUsersResult)

      const { mongoUser } = this.props

      val = {
        sender_id: mongoUser.firebase_id,
        message: encryptedText,
        // message: message,
        education: education,
        education_ids: education_ids,
        question: question,
        patientExperience: patientExperience,
        receiver_id: patient.uid,
      }
      const newMessageToSend = val

      // const current = messageData[patientActiveIndex].messages
      const current = patient.messages

      current.push({
        message,
        education,
        education_ids,
        question,
        patientExperience,
        sender: 'Doctor',
        created_at: new Date(),
      })
      this.socket.emit('DoctorMessage', newMessageToSend)
      this.setState({
        messageData,
        messageText: '',
        listEducation: [],
        listTitles: [],
        listQuestion: [],
        listPatientExperience: [],
        modalECIsOpen: false,
      })
    } catch (err) {
      const error = err.toString()
      console.log('error when encrypting text with virgil', err)
      if (error.includes('UsersNotFoundError')) {
        toast.error('Cannot send encrypted message to this user')
      } else {
        toast.error('Cannot send message right now')
      }
      this.setState({
        messageData,
        messageText: '',
        listEducation: [],
        listTitles: [],
        listQuestion: [],
        listPatientExperience: [],
        modalECIsOpen: false,
      })
    }

    // if(this.state.ailaMessageSelected) {
    //   //append to aila messages
    //   let {ailaMessages} = this.state
    //   let messages = ailaMessages.messages
    //   messages.push({
    //     message: message,
    //     sender: 'Doctor',
    //     created_at: new Date()
    //   })
    //   this.setState({ailaMessages, messageText: ''})
    //   this.scrollToBottom()
    //   return
    // }
    //
    // let {docFirebaseAuthUser} = this.props
    // let {patientActiveIndex, messageData} = this.state
    //
    // let newMessageToSend = {
    //   sender_id: docFirebaseAuthUser.uid,
    //   message: message,
    //   receiver_id: messageData[patientActiveIndex].uid
    // }
    //
    // let current = messageData[patientActiveIndex].messages
    // current.push({
    //   message: message,
    //   sender: 'Doctor',
    //   created_at: new Date()
    // })
    //
    // this.socket.emit('DoctorMessage', newMessageToSend)
    // this.setState({messageData: messageData, messageText: ''})
    // this.scrollToBottom()
  }

  renderPatientNames() {
    const toReturn = []
    const { messageData } = this.state
    if (!messageData || messageData.length === 0) {
      return null
    }

    if (this.state.showSearchResults) {
      // show search results
      const results = this.state.searchResults
      results.forEach((patient) => {
        let subtitle =
          patient.messages && patient.messages.length !== 0
            ? patient.messages[patient.messages.length - 1].message
            : ''
        if (subtitle && subtitle.length > 15)
          subtitle = `${subtitle.substring(0, 15)}......`
        const date =
          patient.messages && patient.messages.length !== 0
            ? patient.messages[patient.messages.length - 1].created_at
            : ''
        !patient.inactive &&
          toReturn.push(
            <ChatItem
              key={patient.index}
              // onClick={() => this.onPatientEntryClicked(patient.index)}
              onClick={() => this.onPatientEntryClicked(patient.patient_id)}
              className={
                // patient.index === this.state.patientActiveIndex
                patient.patient_id === this.state.patientActiveIndex
                  ? Styles.userChatEntryActive
                  : Styles.userChatEntry
              }
              avatar={patient.profilePictureURL || PLACEHOLDER_IMAGE}
              alt="No Image"
              title={
                patient.inactive
                  ? 'Deleted User'
                  : `${patient.firstName} ${patient.lastName}`
              }
              subtitle={subtitle}
              date={new Date(date)}
              unread={patient.unread_doc}
            />,
          )
      })
    } else {
      messageData.forEach((patient, index) => {
        let subtitle =
          patient.messages && patient.messages.length !== 0
            ? patient.messages[patient.messages.length - 1].message
            : ''
        if (subtitle && subtitle.length > 15)
          subtitle = `${subtitle.substring(0, 15)}......`
        const date =
          patient.messages && patient.messages.length !== 0
            ? patient.messages[patient.messages.length - 1].created_at
            : ''

        !patient.inactive &&
          toReturn.push(
            <ChatItem
              key={index.toString()}
              // onClick={() => this.onPatientEntryClicked(index)}
              onClick={() => this.onPatientEntryClicked(patient.patient_id)}
              className={
                // index === this.state.patientActiveIndex
                patient.patient_id === this.state.patientActiveIndex
                  ? Styles.userChatEntryActive
                  : Styles.userChatEntry
              }
              avatar={patient.profilePictureURL || PLACEHOLDER_IMAGE}
              alt="No Image"
              title={
                patient.inactive
                  ? 'Deleted User'
                  : `${patient.firstName} ${patient.lastName}`
              }
              subtitle={subtitle}
              date={new Date(date)}
              unread={patient.unread_doc}
            />,
          )
      })
    }

    return toReturn
  }

  onModalSubmitPressed() {
    const {
      modalPatients,
      modalMessage,
      modalSchedule,
      modalCohortSelection,
      scheduleModalChecked,
    } = this.state
    if (!scheduleModalChecked && !modalPatients) {
      // trying to send to patients but no patient selected
      this.setState({
        modalError: true,
        modalErrorString: 'Please select at least one patient',
      })
      return
    }

    if (scheduleModalChecked && !modalCohortSelection) {
      this.setState({
        modalError: true,
        modalErrorString: 'Please select at least one cohort',
      })
      return
    }

    if (!modalMessage || modalMessage.length === 0) {
      this.setState({
        modalError: true,
        modalErrorString: 'Please enter a message',
      })
      return
    }

    if (!modalSchedule) {
      this.setState({
        modalError: true,
        modalErrorString: 'Please select a time and date to schedule',
      })
      return
    }

    if (moment(modalSchedule).isBefore(moment())) {
      this.setState({
        modalError: true,
        modalErrorString: 'Please select a time and date in the future',
      })
      return
    }
    this.scheduleMessageInBackend()
  }
  async scheduleMessageInBackend() {
    this.setState({ modalLoading: true })
    const {
      modalPatients,
      modalMessage,
      listEducation,
      listTitles,
      listQuestion,
      listPatientExperience,
      modalSchedule,
      scheduleModalChecked,
      modalCohortSelection,
    } = this.state
    const { e3 } = this.props
    const { mongoUser, token } = this.props
    const self = this
    const url = `${Config.BACKEND_URL}provider/messages/schedule`
    const ids = []
    const includedIdsMap = {}
    const message = modalMessage
    const education = listTitles
    const education_ids = listEducation
    const question = listQuestion
    const patientExperience = listPatientExperience
    if (scheduleModalChecked) {
      this.patientListFull.forEach((patient) => {
        const patientCohorts = patient.cohorts || []
        modalCohortSelection.forEach((each) => {
          // if the patient cohorts array contains any one of the selected
          if (
            patientCohorts.includes(each.label) &&
            !includedIdsMap[patient.uid]
          ) {
            ids.push(patient.uid)
            includedIdsMap[patient.uid] = true
          }
        })
      })
    } else {
      modalPatients.forEach((each) => {
        ids.push(each.value)
      })
    }

    if (scheduleModalChecked && ids.length === 0) {
      // no patients in the selected cohorts
      toast.error('No patients present in the selected cohorts')
      this.setState({ modalLoading: false })
      return
    }

    try {
      let val = {}

      // Find users cards with public keys
      const findUsersResult = await e3.findUsers(ids)
      // Encrypt text string
      const encryptedText = await e3.authEncrypt(message, findUsersResult)
      val = {
        patient_ids: ids,
        doctor_id: mongoUser.firebase_id,
        message: encryptedText,
        education: education,
        education_ids: education_ids,
        question: question,
        patientExperience: patientExperience,
        schedule: moment.utc(modalSchedule),
        offset: moment(modalSchedule).utcOffset(),
      }
      const toSend = val

      axios({
        method: 'post',
        url,
        headers: { Authorization: `JWT ${token}` },
        data: toSend,
      })
        .then(() => {
          console.log('successfully scheduled message with backend')
          const successString = `Successfully scheduled messages to ${ids.length} user(s)`
          toast.success(successString)
          self.setState({
            modalIsOpen: false,
            modalLoading: false,
            modalMessage: '',
            listEducation: [],
            listTitles: [],
            listQuestion: [],
            listPatientExperience: [],
            modalPatients: null,
            modalSchedule: new Date(),
            modalCohortSelection: null,
          })
        })
        .catch((err) => {
          console.log('error when updating scheduling messages', err)
        })
    } catch (err) {
      console.log('error when scheduling messages(with virgil)', err)
      const error = err.toString()
      if (error.includes('UsersNotFoundError')) {
        toast.error('Cannot send encrypted message to one or more users')
        toast.error('Please contact support')
      } else {
        toast.error('Cannot schedule message right now')
      }
      this.setState({
        modalIsOpen: false,
        modalLoading: false,
        modalMessage: '',
        listEducation: [],
        listTitles: [],
        listQuestion: [],
        listPatientExperience: [],
        modalPatients: null,
        modalSchedule: new Date(),
        modalCohortSelection: null,
      })
    }
  }

  navigatetoPatientProfile(p_id) {
    let { mongoUser, token, adminId } = this.props
    let { patientList } = this.props
    if (!patientList) return

    Object.values(patientList).forEach((colorCodedPatients, index) => {
      if (colorCodedPatients.length > 0) {
        let result = colorCodedPatients.filter(
          (patient) => patient.uid === p_id,
        )
        console.log('RESUKT ARRAY : ', result)
        result.forEach((patient) => {
          let colorcode = Object.keys(patientList)[index]
          if (patient.uid === p_id) {
            this.props.updatePatientData(
              {
                doctor_id: adminId,
                patient_id: patient.uid,
                last_reviewed: new Date(),
              },
              token,
            )

            //if the doctor was already on on another patient page and just clicked on a new patient, reset time
            if (this.props.patientTimer && this.props.patientTimer !== 0)
              this.props.resetTimer(patient.uid, adminId, token)
            else this.props.startTimer(patient.uid)
            let preferences = patient.preferences
            let timeline = 'complete'
            if (preferences && preferences.timeline) {
              timeline = preferences.timeline.value
            }
            this.props.fetchPatientProgressData(
              patient.uid,
              this.props.token,
              timeline,
            )
            this.props.fetchPatientCoreDate(patient.uid, this.props.token)
            this.props.fetchPatientWellnessScreening(
              patient.uid,
              this.props.token,
            )
            this.props.fetchPatientCalculateProgressData(
              patient.uid,
              this.props.token,
            )
            this.props.fetchPatientConditions(patient.uid, this.props.token)
            this.props.fetchPatientMedications(patient.uid, this.props.token)
            this.props.fetchPatientLabs(patient.uid, this.props.token)
            this.props.addPatient(patient, colorcode)
            this.props.updateSideNavBar(!this.props.updateRender)
          }
        })
      }
    })
  }

  onPatientNavLinkClicked(lp) {
    this.props.addPatientLP(lp)
    console.log('PATIENT LANDING PAGE: ', lp)
  }

  renderPatients() {
    const { ailaMessages } = this.state
    let subTitle = ''
    let date = null
    let unread = 0
    if (
      ailaMessages &&
      ailaMessages.messages &&
      ailaMessages.messages.length > 0
    ) {
      const len = ailaMessages.messages.length
      subTitle = ailaMessages.messages[len - 1].message
        ? `${ailaMessages.messages[len - 1].message.substring(0, 20)}.....`
        : ''
      date = ailaMessages.messages[len - 1].created_at
      unread = ailaMessages.unread_messages
    }

    return (
      <div style={{ width: '100%', textAlign: 'center' }}>
        <h4
          style={{
            marginTop: 10,
            marginBottom: 14,
            color: Constants.primaryThemeDark,
          }}
        >
          Patients
        </h4>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: '0px 10px',
            justifyContent: 'center',
          }}
        >
          <input
            placeholder="search....."
            className={Styles.textInput}
            type="textInput"
            value={this.state.searchText}
            onChange={(e) => this.onSearchTextChanged(e.target.value)}
          />
        </div>

        <div style={{ height: '100%' }}>
          {this.renderPatientNames()}
          <ChatItem
            key="Aila"
            onClick={() =>
              this.setState({
                ailaMessageSelected: true,
                patientActiveIndex: -1,
              })
            }
            className={
              this.state.ailaMessageSelected
                ? Styles.userChatEntryActive
                : Styles.userChatEntry
            }
            avatar={AILA_IMAGE_LOCATION}
            alt="No Image"
            title="Aila Health"
            subtitle={subTitle}
            date={date}
            unread={unread}
          />
        </div>
      </div>
    )
  }

  getAilaMessages(messages) {
    const toReturn = []
    let i = 0
    messages.forEach((message) => {
      const duration = moment.duration(
        moment().diff(moment(message.created_at)),
      )
      const durationString = `sent ${duration.format(
        'd [days] h [hrs] m [min]',
      )} ago`

      toReturn.push(
        <div
          style={{ padding: '8px 8px', marginBottom: '5%', width: '100%' }}
        ></div>,
      )

      toReturn.push(
        <div
          key={i.toString()}
          style={{ padding: '8px 8px', marginBottom: 6, width: '100%' }}
        >
          <div
            className={
              message.sender === 'aila'
                ? Styles.messageWrapperLeft
                : Styles.messageWrapperRight
            }
          >
            {message.sender === 'aila' && (
              <div
                style={{
                  marginRight: 10,
                  height: 40,
                  width: 40,
                  borderRadius: '50%',
                  backgroundColor: Constants.primaryThemeFadedDeep,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <p style={{ fontWeight: 'bold', color: 'white' }}>A</p>
              </div>
            )}
            <div
              className={
                message.sender === 'aila'
                  ? Styles.messageLeft
                  : Styles.messageRight
              }
            >
              <p style={{ whiteSpace: 'pre-wrap' }}>
                <Linkify>{message.message}</Linkify>
              </p>
              {message.patient_id && (
                <p>
                  This
                  <Link
                    style={{ marginLeft: 4, marginRight: 4 }}
                    onClick={() => {
                      this.props.updateSideNavBar(!this.props.updateRender)
                      this.props.addRpmPatient(message.patient_id)
                      this.props.addPatientLP('progress')
                    }}
                    to="/patient/list"
                  >
                    link
                  </Link>
                  will bring you to the patient's progress page to view the
                  data.
                </p>
              )}
              <p style={{ fontSize: 14, textAlign: 'right', color: 'gray' }}>
                {durationString}
              </p>
            </div>
          </div>
        </div>,
      )
      i++
    })

    toReturn.push(
      <div
        style={{ padding: '8px 8px', marginBottom: 6, width: '100%' }}
      ></div>,
    )

    return toReturn
  }

  async decryptMessageToDisplay(encryptedMessage, senderIsDoctor) {
    return new Promise((resolve) => {
      const { e3 } = this.props
      const { patientActiveIndex, messageData } = this.state
      // const patient = messageData[patientActiveIndex]
      const patient = messageData.filter((obj) => {
        return obj.patient_id === patientActiveIndex
      })[0]
      // steve - sender identity
      const sender = patient.uid

      if (senderIsDoctor) {
        // trying to decrypt your own message
        e3.authDecrypt(encryptedMessage)
          .then((decryptedText) => {
            resolve(decryptedText)
          })
          .catch((err) => {
            console.log('error when decrypting text', err.status)
            resolve(encryptedMessage)
          })
      } else {
        // Get sender card with public key
        e3.findUsers(sender)
          .then((senderCard) => {
            e3.authDecrypt(encryptedMessage, senderCard)
              .then((decryptedText) => {
                resolve(decryptedText)
              })
              .catch((err) => {
                console.log('error when decrypting text', err.status)
                resolve(encryptedMessage)
              })
          })
          .catch((err) => {
            console.log('no user identity found on virgil', err)
            resolve(encryptedMessage)
          })
      }
    })
  }

  onVideoCallClicked(patientId) {
    // let {twilioToken} = this.state
    // if(!twilioToken) {
    //   console.log('missing twilio token')
    //   toast.error('Cannot call patient right now')
    //   return
    // }
    //
    // if(!TWILIO_VIDEO.isSupported) {
    //   console.log('not supported by browser')
    //   toast.error('Browser not supported')
    //   return
    // }
    //
    // TWILIO_VIDEO.createLocalVideoTrack().then(track => {
    //   console.log('created local track')
    //   const localMediaContainer = document.getElementById('local-media');
    //   localMediaContainer.appendChild(track.attach());
    // });
    this.setState({ videoModalIsOpen: true, videoPatientId: patientId })
  }

  getMessages(messages) {
    if (!messages) return null
    const toReturn = []
    let i = 0
    // const patient = this.state.messageData[this.state.patientActiveIndex]
    const patient = this.state.messageData.filter((obj) => {
      return obj.patient_id === this.state.patientActiveIndex
    })[0]
    messages.forEach(async (message) => {
      const duration = moment.duration(
        moment().diff(moment(message.created_at)),
      )
      const durationString = `sent ${duration.format(
        'd [days] h [hrs] m [min]',
      )} ago`

      toReturn.push(
        <div
          key={i.toString()}
          style={{ padding: '8px 8px', marginBottom: 6, width: '100%' }}
        >
          <div
            className={
              message.sender === 'Patient'
                ? Styles.messageWrapperLeft
                : Styles.messageWrapperRight
            }
          >
            {message.sender === 'Patient' && (
              <div
                style={{
                  marginRight: 10,
                  height: 40,
                  width: 40,
                  borderRadius: '50%',
                  backgroundColor: Constants.primaryThemeFadedDeep,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <p style={{ fontWeight: 'bold', color: 'white' }}>
                  {patient &&
                    patient.firstName &&
                    patient.firstName.charAt(0).toUpperCase()}
                </p>
              </div>
            )}
            <div
              className={
                message.sender === 'Patient'
                  ? Styles.messageLeft
                  : Styles.messageRight
              }
            >
              <div
                style={{
                  whiteSpace: 'pre-wrap',
                  wordBreak: 'break-all',
                }}
              >
                <Linkify>
                  {message.message}
                  {message.education && message.education.length !== 0
                    ? message.education.map((edu, index) => (
                        <p
                          key={index}
                          style={{
                            fontStyle: 'italic',
                            textDecorationLine: 'underline',
                          }}
                        >
                          {edu}
                        </p>
                      ))
                    : message.education_ids &&
                      message.education_ids.length !== 0 &&
                      message.education_ids.map((edu, index) => (
                        <p
                          key={index}
                          style={{
                            fontStyle: 'italic',
                            textDecorationLine: 'underline',
                          }}
                        >
                          {edu.title}
                        </p>
                      ))}
                  {message.question &&
                    message.question.length !== 0 &&
                    message.question.map((edu, index) => (
                      <p
                        key={index}
                        style={{
                          fontStyle: 'italic',
                          textDecorationLine: 'underline',
                        }}
                      >
                        {edu}
                      </p>
                    ))}
                  {message.patientExperience &&
                    message.patientExperience.length !== 0 &&
                    message.patientExperience.map((edu, index) => (
                      <p
                        key={index}
                        style={{
                          fontStyle: 'italic',
                          textDecorationLine: 'underline',
                        }}
                      >
                        {edu}
                      </p>
                    ))}
                </Linkify>
              </div>
              <p style={{ fontSize: 14, textAlign: 'right', color: 'gray' }}>
                {durationString}
              </p>
            </div>
          </div>
        </div>,
      )
      i++
    })

    // toReturn.push(
    //   <div
    //     ref={this.dummyRef}
    //     style={{ marginTop: 50, visibility: 'hidden' }}
    //     key="hidden"
    //   >
    //     asdasdasdasdas
    //   </div>,
    // )

    return toReturn
  }

  renderMessages() {
    if (!this.state.messageData || this.state.messageData.length === 0) {
      return (
        <div
          style={{
            height: '60%',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <h3>No messages to show</h3>
        </div>
      )
    }

    // const patient = this.state.messageData[this.state.patientActiveIndex]
    const patient = this.state.messageData.filter((obj) => {
      return obj.patient_id === this.state.patientActiveIndex
    })[0]

    if (!patient) {
      return (
        <div
          style={{
            minWidth: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'absolute',
            bottom: 80,
            top: 10,
            height: '100%',
            justifyContent: 'center',
          }}
        >
          <p>Select a patient from the list</p>
        </div>
      )
    }

    const { messages } = patient
    let messagesElement
    if (!messages || messages.length === 0) {
      messagesElement = (
        <div
          style={{
            height: '60%',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <h3>No messages to show</h3>
        </div>
      )
    } else {
      let tempMessages = messages

      messagesElement = (
        <div
          id="scrollingDiv"
          ref={this.dummyRef}
          className={Styles.messagesWrapper}
        >
          {/* {this.getMessages(messages.reverse())} */}
          {/* {this.getMessages(tempMessages.reverse())} */}

          {this.getMessages(messages)}
        </div>
      )

      // this.scrollToBottom()
    }

    if (patient.unread_doc !== 0) {
      // && !this.state.updatedUnread
      // update unread
      this.updateUnread()
    }

    return (
      <div
        style={{
          minWidth: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'absolute',
          bottom: 80,
          top: 10,
          height: '90%',
        }}
      >
        {/* <div className={Styles.nameWrapper}>
          <Link
            onClick={() => {
              this.navigatetoPatientProfile(patient.uid)
              this.onPatientNavLinkClicked('profile')
            }}
            to={{
              pathname: '/patient/profile',
              state: 'info',
              key: 2,
            }}
          >
            <div
              style={{
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <img
                src={patient.profilePictureURL || PLACEHOLDER_IMAGE}
                style={{
                  height: 50,
                  width: 50,
                  borderRadius: '50%',
                  marginRight: 16,
                }}
              />
              <h5 style={{ color: Constants.primaryThemeDark }}>
                {patient.inactive
                  ? 'Deleted User'
                  : `${patient.firstName} ${patient.lastName}`}
              </h5>
            </div>
          </Link> */}
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            // justifyContent: 'center',
          }}
        >
          {/* <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              position: 'relative',
            }}
          > */}
          <h5 style={{ color: Constants.primaryTheme }}>Patient Messages</h5>

          <p style={{ fontSize: 12, color: 'gray' }}>
            <FontAwesomeIcon
              icon={faUserLock}
              style={{ color: 'gray', fontSize: 16, marginRight: 10 }}
            />
            Messages are encrypted end to end
          </p>
        </div>
        {/* </div> */}

        {messagesElement}
      </div>
    )
  }

  renderAilaMessages() {
    const { ailaMessages } = this.state
    const messages = ailaMessages ? ailaMessages.messages.reverse() : []

    if (ailaMessages.unread_messages !== 0) {
      // update unread aila health
      this.updateUnreadAilaMessages()
    }

    return (
      <div
        style={{
          minWidth: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'absolute',
          bottom: 80,
          top: 10,
        }}
      >
        <div className={Styles.nameWrapper}>
          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img
              src={AILA_IMAGE_LOCATION}
              style={{
                height: 50,
                width: 50,
                borderRadius: '50%',
                marginRight: 16,
              }}
            />
            <h5 style={{ color: Constants.primaryThemeDark }}>Aila Health</h5>
          </div>
        </div>

        <div className={Styles.messagesWrapper}>
          {ailaMessages &&
          ailaMessages.messages &&
          ailaMessages.messages.length > 0 ? (
            this.getAilaMessages(messages)
          ) : (
            <div
              style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <h3>No messages to show</h3>
            </div>
          )}
        </div>
      </div>
    )
  }

  onModalCloseRequested() {
    if (this.state.videoCallJoined) {
      console.log('disconnecting')
      this.state.videoCallRoom.disconnect()
      this.setState({ videoCallJoined: false, videoCallRoom: null })
    }

    this.setState({
      videoModalIsOpen: false,
      modalIsOpen: false,
      modalLoading: false,
      modalMessage: '',
      listEducation: [],
      listTitles: [],
      listQuestion: [],
      listPatientExperience: [],
      modalPatients: null,
      modalSchedule: new Date(),
      modalCohortSelection: null,
    })
  }

  onModalECCloseRequested() {
    this.setState({
      listEducation: [],
      listTitles: [],
      listQuestion: [],
      listPatientExperience: [],
      modalECIsOpen: false,
      schedulemodalECIsOpen: false,
    })
  }

  handleCallbackEducation = (childData) => {
    let result = childData.map(({ title, id }) => ({ title, id }))
    let titles = childData.map((edu) => edu.title)
    this.setState({ listEducation: result, listTitles: titles })
  }

  handleCallbackQuestion = (childData) => {
    let res = childData.filter((list) => list.isChecked === true)
    let result = res.map((list) => list.title)
    this.setState({ listQuestion: result })
  }

  handleCallbackPatientExperience = (childData) => {
    let res = childData.filter((list) => list.isChecked === true)
    let result = res.map((list) => list.title)
    this.setState({ listPatientExperience: result })
  }
  renderModal() {
    return (
      <div style={{ height: '100%', width: '100%' }}>
        <p
          className={Styles.closeModalBtn}
          onClick={() => this.onModalCloseRequested()}
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Schedule Message
        </h4>
        {this.state.modalLoading ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              height: '100%',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        ) : (
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '100%',
              overflowY: 'scroll',
            }}
          >
            <div
              style={{
                width: '100%',
                margin: 12,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <p style={{ fontWeight: 'bold', marginRight: 30 }}>
                Schedule for{' '}
                {this.state.scheduleModalChecked ? 'Cohorts' : 'Patients'}
              </p>
              <Switch
                onColor={Constants.primaryThemeDark}
                onChange={(checked) =>
                  this.setState({ scheduleModalChecked: checked })
                }
                checked={this.state.scheduleModalChecked}
              />
            </div>
            <div style={{ width: '100%' }}>
              {this.state.scheduleModalChecked ? (
                <Select
                  placeholder="Select cohorts...."
                  isMulti
                  options={this.state.modalCohortOptions}
                  value={this.state.modalCohortSelection}
                  onChange={(val) =>
                    this.setState({
                      modalCohortSelection: val,
                      modalError: false,
                    })
                  }
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#20A89233',
                      primary: '#20A892',
                    },
                  })}
                />
              ) : (
                <Select
                  placeholder="Select patients...."
                  isMulti
                  options={this.state.patientList}
                  value={this.state.modalPatients}
                  onChange={(val) =>
                    this.setState({ modalPatients: val, modalError: false })
                  }
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#20A89233',
                      primary: '#20A892',
                    },
                  })}
                />
              )}
            </div>

            <textarea
              placeholder="Enter your message here....."
              style={{
                width: '100%',
                height: '40%',
                padding: '5px 20px',
                resize: 'none',
                borderColor: '#aeaeae',
                borderRadius: 10,
                marginTop: 30,
                marginBottom: 10,
              }}
              value={this.state.modalMessage}
              onChange={(e) => this.setState({ modalMessage: e.target.value })}
            />
            <FontAwesomeIcon
              onClick={() => {
                if (!this.state.ailaMessageSelected)
                  this.setState({ schedulemodalECIsOpen: true })
              }}
              icon={faPaperclip}
              style={{
                color: 'gray',
                fontSize: 30,
                flexGrow: 1,
                cursor: 'pointer',
                position: 'absolute',
                top: '63%',
                right: '5%',
              }}
            />

            <p
              style={{
                width: '100%',
                height: '20%',
                padding: '5px 20px',
                resize: 'none',
                borderColor: '#aeaeae',
                borderStyle: 'solid',
                borderRadius: 10,
                marginTop: 30,
                marginBottom: 20,
                whiteSpace: 'pre',
                overflow: 'scroll',
              }}
            >
              Attachments : {'\n'}
              {this.state.listTitles.length !== 0
                ? this.state.listTitles.map(
                    (edu, index) => `${index + 1}. ${edu}\n`,
                  )
                : this.state.listQuestion.length !== 0
                ? this.state.listQuestion.join('\n')
                : this.state.listPatientExperience.length !== 0
                ? this.state.listPatientExperience.join('\n')
                : ' None '}
            </p>

            <DateTimePicker
              onChange={(val) => {
                this.setState({ modalSchedule: val, modalError: false })
              }}
              value={this.state.modalSchedule}
            />

            {this.state.modalError && (
              <p style={{ fontSize: 12, color: 'red', marginTop: 10 }}>
                {this.state.modalErrorString}
              </p>
            )}

            <Button
              onClick={this.onModalSubmitPressed.bind(this)}
              className={GlobalStyles.button}
              disabled={this.state.modalLoading}
              style={{ width: 100, marginTop: 20 }}
              variant="primary"
            >
              Schedule
            </Button>
          </div>
        )}
      </div>
    )
  }
  renderModalSelectedEC() {
    switch (this.state.toggleModalChecked) {
      case 'Content Library':
        return (
          <EducationMessages parentCallback={this.handleCallbackEducation} />
        )

      case 'Questionnaires':
        return (
          <QuestionnaireMessages parentCallback={this.handleCallbackQuestion} />
        )

      // case 'Patient Experience':
      //   return <PatientExperienceMessages parentCallback={this.handleCallbackPatientExperience} />

      default:
        break
    }
  }
  renderModalEC() {
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() => this.onModalECCloseRequested()}
        >
          X
        </p>
        <div
          style={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <div
            style={{
              width: '50%',
              height: 40,
              marginTop: 20,
              border: '1px solid black',
              borderRadius: 4,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              onClick={() =>
                this.setState({
                  toggleModalChecked: 'Content Library',
                  listQuestion: [],
                  listPatientExperience: [],
                })
              }
              style={{
                borderRadius: 4,
                cursor: 'pointer',
                width: '50%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:
                  this.state.toggleModalChecked === 'Content Library'
                    ? Constants.primaryTheme
                    : 'white',
              }}
            >
              <p
                style={{
                  color:
                    this.state.toggleModalChecked === 'Content Library'
                      ? 'white'
                      : 'black',
                }}
              >
                Content Library
              </p>
            </div>
            <div
              onClick={() =>
                this.setState({
                  toggleModalChecked: 'Questionnaires',
                  listEducation: [],
                  listTitles: [],
                  listPatientExperience: [],
                })
              }
              style={{
                borderRadius: 4,
                cursor: 'pointer',
                width: '50%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:
                  this.state.toggleModalChecked === 'Questionnaires'
                    ? Constants.primaryTheme
                    : 'white',
                borderLeftWidth: 1,
                borderLeftColor: 'black',
                borderLeftStyle: 'solid',
                borderRightWidth: 1,
                borderRightColor: 'black',
                borderRightStyle: 'solid',
              }}
            >
              <p
                style={{
                  color:
                    this.state.toggleModalChecked === 'Questionnaires'
                      ? 'white'
                      : 'black',
                }}
              >
                Questionnaires
              </p>
            </div>
            {/* <div
              onClick={() =>
                this.setState({
                  toggleModalChecked: 'Patient Experience',
                  listEducation: [],
                  listQuestion: [],
                })
              }
              style={{
                borderRadius: 4,
                cursor: 'pointer',
                width: '33.3%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:
                  this.state.toggleModalChecked === 'Patient Experience'
                    ? Constants.primaryTheme
                    : 'white',
              }}
            >
              <p
                style={{
                  color:
                    this.state.toggleModalChecked === 'Patient Experience'
                      ? 'white'
                      : 'black',
                }}
              >
                Patient Experience
              </p>
            </div> */}
          </div>
        </div>

        <div style={{ width: '100%' }}>{this.renderModalSelectedEC()}</div>

        <div style={{ paddingBottom: '10%' }}>
          <Button
            onClick={
              this.state.schedulemodalECIsOpen
                ? this.onAddPressed.bind(this)
                : this.onSubmitPressed.bind(this)
            }
            className={GlobalStyles.button}
            // disabled={this.state.messageText.length === 0}
            style={{ width: 100, flexGrow: 1 }}
            variant="primary"
          >
            {this.state.schedulemodalECIsOpen ? 'Add' : 'Send'}
          </Button>
        </div>
      </div>
    )
  }

  renderContent() {
    return (
      //   <div
      //     className={GlobalStyles.contentWrapper}
      //     style={{
      //       display: 'flex',
      //       flexDirection: 'row',
      //       justifyContent: 'center',
      //       height: 500,
      //       padding: '0px 0px',
      //     }}
      //   >
      <div
        className={Styles.contentBackground}
        style={{ height: 550, marginTop: '40px' }}
      >
        {/* <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '25%',
            height: '100%',
            overflowY: 'scroll',
          }}
        >
          {this.renderPatients()}
        </div> */}

        <div
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            // borderLeft: '1px solid gray',
            paddingBottom: 30,
          }}
        >
          {this.state.ailaMessageSelected
            ? this.renderAilaMessages()
            : this.renderMessages()}

          <div className={Styles.bottomInputWrapper}>
            <input
              type="file"
              id="file"
              ref={this.fileUploaderRef}
              style={{ display: 'none' }}
            />
            <input
              onKeyDown={(e) => {
                if (e.key === 'Enter') this.onSubmitPressed()
              }}
              style={{
                position: 'relative',
                top: '20%',
                flexGrow: 4,
                borderRadius: 4,
              }}
              placeholder="Enter your message..."
              className={Styles.textInput}
              type="text"
              value={this.state.messageText}
              onChange={(e) => {
                if (this.state.ailaMessageSelected) return
                this.setState({ messageText: e.target.value })
              }}
            />

            {/* <FontAwesomeIcon onClick={() => this.fileUploaderRef.current.click()} icon={faFileMedical} style={{color: 'gray', fontSize: 30, marginRight: 10, flexGrow: 1, cursor: 'pointer'}}/> */}
            <FontAwesomeIcon
              onClick={() => {
                if (!this.state.ailaMessageSelected)
                  this.setState({ modalIsOpen: true })
              }}
              icon={faCalendarPlus}
              style={{
                color: 'gray',
                fontSize: 30,
                marginRight: 10,
                flexGrow: 1,
                cursor: 'pointer',
              }}
            />
            <FontAwesomeIcon
              onClick={() => {
                if (!this.state.ailaMessageSelected)
                  this.setState({ modalECIsOpen: true })
              }}
              icon={faPaperclip}
              style={{
                color: 'gray',
                fontSize: 30,
                marginRight: 10,
                flexGrow: 1,
                cursor: 'pointer',
              }}
            />
            <Button
              onClick={this.onSubmitPressed.bind(this)}
              className={GlobalStyles.button}
              disabled={this.state.messageText.length === 0}
              style={{ width: 100, flexGrow: 1 }}
              variant="primary"
            >
              Send
            </Button>
          </div>

          <Modal
            ariaHideApp={false}
            onRequestClose={() => this.onModalCloseRequested()}
            isOpen={this.state.modalIsOpen}
            style={SCHEDULE_MODAL_STYLES}
            contentLabel="Modal"
          >
            {this.renderModal()}
          </Modal>
          <Modal
            ariaHideApp={false}
            onRequestClose={() => this.onModalCloseRequested()}
            isOpen={this.state.modalECIsOpen}
            style={MODAL_STYLES}
            contentLabel="Modal"
          >
            {this.renderModalEC()}
          </Modal>
          <Modal
            ariaHideApp={false}
            onRequestClose={() => this.onModalCloseRequested()}
            isOpen={this.state.schedulemodalECIsOpen}
            style={MODAL_STYLES}
            contentLabel="Modal"
          >
            {this.renderModalEC()}
          </Modal>
        </div>
      </div>
    )
  }

  renderVideoContent() {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'relative',
          padding: 20,
        }}
      >
        <p
          className={Styles.closeVideoBtn}
          onClick={() => this.onModalCloseRequested()}
        >
          Close
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Video Call
        </h4>

        <Button
          onClick={this.onJoinCallPressed.bind(this)}
          className={GlobalStyles.button}
          style={{ width: 100, marginTom: 14 }}
          variant="primary"
        >
          {this.state.videoCallJoined ? 'Leave Call' : 'Join Call'}
        </Button>

        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: 10,
          }}
        >
          <div
            style={{
              width: '48%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <p style={{ marginBottom: 5 }}>Your Video</p>
            <div id="local-media"></div>
          </div>
          <div
            style={{
              width: '48%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <p style={{ marginBottom: 5 }}>Patient's Video</p>
            <div id="remote-media"></div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    let selectedPatient
    if (
      this.state.messageData &&
      this.state.messageData.length > 0
      // &&
      // this.state.patientActiveIndex >= 0
    ) {
      // selectedPatient = this.state.messageData[this.state.patientActiveIndex]
      selectedPatient = this.state.messageData.filter((obj) => {
        return obj.patient_id === this.state.patientActiveIndex
      })[0]
    }

    if (this.state.loading) {
      return (
        // <div
        //   className={GlobalStyles.container}
        //   style={{ overflow: 'hidden', paddingBottom: '1px' }}
        // >
        <div
          className={Styles.contentBackground}
          style={{ height: 550, marginTop: '40px' }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              height: '100vh',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        </div>
      )
    }
    return this.renderContent()
    //  (
    //   <div
    //     className={GlobalStyles.container}
    //     style={{
    //       overflow: 'hidden',
    //       paddingBottom: '10px',
    //       maxHeight: '100vh',
    //     }}
    //   >
    {
      /* <Header header="Messages" /> */
    }

    // {this.renderContent()}
    //   </div>
    // )
  }
}

const mapStateToProps = (state /* , ownProps */) => ({
  loggedIn: state.userReducer.loggedIn,
  token: state.authReducer.token,
  docFirebaseAuthUser: state.userReducer.firebaseAuthUser,
  patientList: state.patientReducer.patientList,
  virgilToken: state.authReducer.virgilToken,
  e3: state.authReducer.e3,
  mongoUser: state.userReducer.mongoUser,
  patientCore: state.patientReducer.patientCore,
  updateRender: state.userReducer.render,
  adminId: state.userReducer.adminId,
  patient: state.patientReducer.patient,
})

const mapDispatchToProps = {
  stopTimer,
  updateSideNavBar,
  addRpmPatient,
  addPatientLP,
  getUnreadMessages,
  resetTimer,
  startTimer,
  addVirgilE3,
  updateDoctorMongoUser,
  addMongoUser,
  addPatient,
  userLoggedIn,
  fetchPatientCoreDate,
  fetchPatientProgressData,
  fetchPatientWellnessScreening,
  fetchPatientCalculateProgressData,
  updatePatientData,
  addPatientList,
  setPatientCoreData,
  fetchPatientConditions,
  fetchPatientMedications,
  fetchPatientLabs,
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageChat)
