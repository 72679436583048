import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import moment from 'moment'
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend } from 'recharts'
import Styles from './styles/Progress.module.scss'
import Helpers from '../../../helpers/Global.js'
import GlobalStyles from '../../styles/global.module.scss'
import { addPatient, updatePatientData } from '../../../redux/actions'
import Config from '../../../config'
import Constants from '../../../values'
const axios = require('axios')
const graphWidth = window.innerWidth / 1.4 - 100

export default function MedMonitor({medMonitorData}) {

  const [chartData, setChartData] = useState([])
  const [chartOptions, setChartOptions] = useState([])
  const [selectedGraphOptions, setSelectedGraphOptions] = useState([])
	const [lineData, setLineData] = useState([])

  useEffect(() => {
    if (medMonitorData?.length) {
      let medOptions = medMonitorData.reduce((unique, o) => {
        if(!unique.some(obj => obj.name === o.name)) {
          unique.push({ label: o.name, value: o.name, name: o.name});
        }
        return unique;
      },[]);
			const groups = medMonitorData.reduce((groups, med) => {
				console.log(med)
				const date = moment(med.created_at).format('MM/DD/YYYY')
				if (!groups[date]) {
					groups[date] = [];
				}
				groups[date].push(med);
				return groups;
			}, {});
			let tempData = Object.keys(groups).map((date) => {
				let final = {
					created_at: date
				}
				groups[date].forEach(data => {
					final[data.name] = data
				})
				return final;
			});
			tempData.sort(function (left, right) {
				return moment(left.created_at, 'MM/DD/YYYY').diff(moment(right.created_at, 'MM/DD/YYYY')
			)})
      setChartData(tempData)
      setChartOptions(medOptions)
    }
  }, [medMonitorData])

	useEffect(() => {
		if(selectedGraphOptions) {
			const lines =
				selectedGraphOptions &&
				selectedGraphOptions.map((x) => {
					return (
						<Line
							name={x.label}
							type="monotone"
							dataKey={`${x.label}.score`}
							stroke={Helpers.random_hex_color_code()}
							strokeWidth={2.5}
						/>
					)
				})
				setLineData(lines)
		}
	}, [selectedGraphOptions])

	const CustomTooltip = ({ active, payload, label }) => {
		if (active && payload && payload.length) {
			return (
				<div className={Styles.symptomChartToolTipWrapper}>
					<p className="label">{`${label}`}</p>
					{
						Object.keys(payload?.[0]?.payload)
							.filter(data => data !== 'created_at')
							.map(meds => {
								let medData = payload?.[0]?.payload?.[meds]
								return (
									<>
										<p className="intro" style={{fontWeight: 'bolder', paddingTop: 5}}>{meds}</p>
										<p className="desc">Score: {medData.score}; Notes: {medData.monitor_note || 'N/A'}</p>
									</>
								)
							})
					}
				</div>
			);
		} else return(<p>Hellpo</p>)
	}
	
  return (
		<div
			className={GlobalStyles.container}
			style={{ backgroundColor: 'white' }}
		>
			<div style={{ width: '100%', marginTop: 10 }}>
				<h4
					style={{
						color: Constants.primaryTheme,
						width: '100%',
						textAlign: 'center',
					}}
				>
					{'Medication Review'}
				</h4>

				<div
					style={{
						width: '100%',
						display: 'flex',
						flexDirection: 'column',
						marginTop: 30,
						paddingLeft: 40,
					}}
				>
					<div style={{ width: '60%', marginBottom: 40, paddingLeft: 10 }}>
						<Select
							isMulti
							placeholder="Select ..."
							options={chartOptions}
							value={selectedGraphOptions}
							onChange={(val) => {
								console.log(val)
								setSelectedGraphOptions(val)
							}}
							theme={(theme) => ({
								...theme,
								colors: {
									...theme.colors,
									primary25: '#20A89233',
									primary: '#20A892',
								},
							})}
						/>
					</div>
					<div
						style={{
							margin: '0 auto',
							display: 'flex',
							width: graphWidth + 40,
							flexDirection: 'column',
							alignItems: 'center',
							boxShadow: '5px 5px 5px 5px grey',
							padding: '20px 0px',
						}}
					>
						{chartData?.length > 0 ? (
							<LineChart
								width={graphWidth}
								height={400}
								data={chartData}
								strokeWidth={2.5}
								margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
							>
								<Legend verticalAlign="top" />
								{lineData}
								<XAxis
									label={{
										value: 'Timeline',
										position: 'insideBottom',
										offset: 0,
									}}
									dataKey="created_at"
									tickFormatter={(tickItem) => moment(tickItem).format('D-MMM')}
								/>
								<YAxis
									label={{
										value: 'Score',
										angle: -90,
										position: 'insideLeft',
									}}
									domain={[0, 10]}
								/>
								<Tooltip
									content={<CustomTooltip />}
								/>
							</LineChart>
						) : (
							<div
								style={{
									height: 400,
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<p>Select options from dropdown to view the chart / No Data Present</p>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
  )

}
