import React, { Component } from 'react'
import Styles from './styles/LongcovidDashboard.module.scss'
import GlobalStyles from '../styles/global.module.scss'
import FancyField from 'react-fancy-field'
import Constants from '../../values'
import Button from 'react-bootstrap/Button'
import AilaLogo from '../../assets/images/aila_logo.png'
import BrainIcon from '../../assets/images/brain_icon.png'
import CardioIcon from '../../assets/images/cardio_icon.png'
import EndocrineIcon from '../../assets/images/endocrine_icon.png'
import GeneralHealthIcon from '../../assets/images/general_health_icon.png'
import JointIcon from '../../assets/images/joint_icon.png'
import LungIcon from '../../assets/images/lung_icon.png'
import MentalHealthIcon from '../../assets/images/mental_health_icon.png'
import StomachIcon from '../../assets/images/stomach_icon.png'
import Image1 from '../../assets/images/longhauler.png'
import Image2 from '../../assets/images/postcovidteam.png'
import Image3 from '../../assets/images/covidresources.png'
import Form from 'react-bootstrap/Form'
import Progress_bar from '../../components/ProgressBar'
import algoliasearch from 'algoliasearch/lite'
import Config from '../../config'
import Select from 'react-select'
import * as Metadata from '../../metadata'
import moment, { months } from 'moment'
import DatePicker from 'react-date-picker'
import Slider from 'react-rangeslider'
import Modal from 'react-modal'
import Footer from '../../components/Footer'
import SeverityScale from '../../components/SeverityScale'
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ComposedChart,
  Bar,
  BarChart,
  CartesianGrid,
  Area,
  Label,
} from 'recharts'

import zipcodes from 'zipcodes'
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  PinterestIcon,
  PinterestShareButton,
} from 'react-share'

import { useSelector, useDispatch } from 'react-redux'
import {
  addAuthToken,
  addFirebaseUser,
  addFirebaseAuthUser,
  addPatient,
  fromWebFlow,
} from '../../redux/actions'
import { connect } from 'react-redux'

import {
  firebase,
  auth,
  googleProvider,
  appleProvider,
  analytics,
} from '../../services/firebase'

import 'react-rangeslider/lib/index.css'
import './rangeSlider.css'
import {
  InstantSearch,
  SearchBox,
  Hits,
  RefinementList,
  InfiniteHits,
  connectStateResults,
  Highlight,
} from 'react-instantsearch-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

// import DatePicker from '../../components/DatePicker'

const axios = require('axios')

const urlSocial = 'https://www.longcovid.io/'

const socialTitle = `I just completed this symptom checker to help me get support for my Long Covid symptoms.`

const HEAD_NEURO = [
  'Dental Issues (e.g. broken or cracked teeth)',
  'Ear ache',
  'Eye pain',
  'Migraines',
  'Recurrent headaches',
  'Sinus pain',
  'Sores in Mouth',
  'Vision problems',
  'Brain Fog',
  'Convulsions',
  'Difficulty with speaking or using the wrong word',
  'Impaired memory',
  'Shaking',
  'Tingling/Pins and needles feeling',
  'Tremor',
  'Trouble Concentrating',
  'Other',
]

const CARDIO_VASCULAR = [
  'Fast-beating or pounding heart (also known as heart palpitations)',
  'Low blood pressure',
  'Elevated cholesterol',
  'Slowed heart rate',
  'Chest pain',
  'Tightness of chest',
  'Irregular Heart Rhythm',
  'Other',
]

const RESPI_RATORY = [
  'Cough',
  'Difficulty breathing',
  'Low oxygen levels/saturation at rest',
  'Low oxygen levels/saturation with movement',
  'Lung pain',
  'Shortness of breath',
  'Other',
]

const STO_MACH = [
  'Acid reflux',
  'Bloating',
  'Burning in the throat',
  'Burning pain in stomach',
  'Constipation',
  'Cramping',
  'Diarrhea',
  'Food allergies',
  'Gas',
  'Heartburn',
  'Loss of appetite',
  'Nausea',
  'Stabbing pain in stomach',
  'Stomach pain',
  'Upper stomach pain',
  'Vomiting',
  'Other',
]

const ENDO_CRINE = [
  'Changes in menstrual period cycles',
  'Feeling cold easily',
  'Feeling hot and sweaty',
  'High blood sugar',
  'Low blood sugar',
  'Lower than average body temperature',
  'Thinning hair',
  'Weight gain',
  'Weight loss',
  'Other',
]

const JOINT_PAIN = [
  'Body Pain',
  'Hypermobility (Are you extremely flexible?)',

  'Inflexibility',
  'Joint Pain',
  'Muscle aches, tenderness and stiffness',
  'Muscle weakness',
  'Nerve pain',
  'Numbness and tingling',
  'Paralysis',
  'Stiffness',
  'Swelling in the joints',
  'Other',
]

const MENTAL_HEALTH = [
  'Anxiety',
  'Depression',
  'Hallucinations',
  'Mood swings',
  'Nervousness',
  'Psychosis',
  'Other',
]

const GENERAL_HEALTH = [
  'Chills',
  'Cold hands or feet',
  'Dizziness on standing (lightheadedness)',
  'Excessive Thirst',
  'Fatigue',
  'Feeling of fainting',
  'Itchy skin',
  'Loss of smell',
  'Loss of taste',
  'Low energy',
  'Low-grade fever',
  'Rash',
  'Recurrent fever',
  'Restless Sleep/ Insomnia',
  'Sore throat',
  'Other',
]

const pieChart = Styles.pieChart

class LongcovidDashboard extends Component {
  constructor() {
    super()
    this.state = {
      longcovidData: null,
      selectedQuestion: 0,
      progressPercent: 0,
      showIntro: false,
      showThankyou: false,
      totalNumber: 0,
      covid_infection: 0,
      positive_test: 0,
      recovered_covid: 0,
      seen_doctor: 0,
      diagnosis_recieved: 0,
      test_type: [],
    }
    this.renderInsights = this.renderInsights.bind(this)
    this.renderCovidHistory = this.renderCovidHistory.bind(this)
    this.renderBodySummary = this.renderBodySummary.bind(this)
    this.renderPieCharts = this.renderPieCharts.bind(this)
    this.renderBarChart = this.renderBarChart.bind(this)
    this.renderSeverity = this.renderSeverity.bind(this)
    this.getLongcovidData = this.getLongcovidData.bind(this)
    this.renderSeverityBars = this.renderSeverityBars.bind(this)
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.getLongcovidData()

    // set Interval
    this.interval = setInterval(this.getLongcovidData, 300000)
  }

  componentWillUnmount() {
    // Clear the interval right before component unmount
    clearInterval(this.interval)
  }

  getLongcovidData = () => {
    const { token, patient } = this.props

    let url = Config.BACKEND_URL + 'longcovid/questionnaire'
    axios({
      method: 'get',
      url: url,
      headers: {
        'Access-Control-Allow-Origin': '*',
        Accept: '*/*',
        Authorization: `JWT ${token}`,
      },
    })
      .then((res) => {
        console.log('covid data updated')
        const data = res.data
        this.setState({ longcovidData: res.data, totalNumber: res.data.length })
        // let covid_infection = data.map((a) => a.covidHistory.covid)
        let covid_infection = data.filter(
          (a) => a?.covidHistory?.covid === 'Yes',
        )
        this.setState({ covid_infection: covid_infection.length })
        let positive_test = data.filter(
          (a) => a?.covidHistory?.covid_test === 'Yes, it was Positive',
        )
        this.setState({ positive_test: positive_test.length })
        let recovered_covid = data.filter(
          (a) => a?.covidHistory?.recover === 'Yes',
        )
        this.setState({ recovered_covid: recovered_covid.length })
        this.setState({ positive_test: positive_test.length })
        let seen_doctor = data.filter(
          (a) => a?.covidHistory?.care_providers?.length > 0,
        )
        this.setState({ seen_doctor: seen_doctor.length })
        let diagnosis_recieved = data.filter(
          (a) => a?.covidHistory?.pasc_diagnosis === 'Yes',
        )
        this.setState({ diagnosis_recieved: diagnosis_recieved.length })

        let test_temp = data.map((a) => a.covidHistory.covid_test_type)
        let test_type = test_temp.filter((a) => a?.length > 0)
        this.setState({ test_type: test_type })
      })
      .catch((err) => {
        console.log('error when posting patient web quess', err)
      })
  }

  renderSeverityBars = (dataSet, compareData) => {
    let windowWidth = window.innerWidth
    let windowHeight = window.innerHeight

    let { test_type } = this.state
    let dataLabel = {}
    let paramData = []

    let tempData = compareData.filter((a) => a.length > 0)

    dataSet.forEach((ele) => {
      dataLabel[ele] = tempData.filter((a) => a.includes(ele))
    })

    dataSet.forEach((ele) => {
      paramData.push({
        name: ele,
        Number: Math.round((dataLabel[ele].length / tempData.length) * 100),
      })
    })

    paramData.sort((a, b) => {
      return b.Number - a.Number
    })

    paramData.push({})

    // const data = [
    //   {
    //     name: 'PCR',

    //     Number: pcr.length,
    //   },
    //   {
    //     name: 'Antigen',

    //     Number: antigen.length,
    //   },
    //   {
    //     name: 'Antibody',

    //     Number: antibody.length,
    //   },
    //   {
    //     name: 'T-Cell test',
    //     Number: tcell.length,
    //   },
    //   {
    //     name: `Don't know`,
    //     Number: dontknow.length,
    //   },
    // ]

    const COLORS = [
      '#7067CF',
      '#20A892',
      '#FF9F0E',
      '#FF0E0E',
      '#585858',
      '#F08080',
    ]

    return (
      <div style={{ margin: '1%' }}>
        <ComposedChart
          layout="vertical"
          width={windowWidth - windowWidth / 9}
          height={windowHeight - windowHeight / 6}
          data={paramData}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <YAxis
            dataKey="name"
            type="category"
            scale="point"
            // dy={10}
            width={windowWidth > 600 ? windowWidth / 5 : windowWidth / 2}
          />
          <Tooltip />
          {/* <Legend /> */}
          {/* <Area dataKey="amt" fill="#8884d8" stroke="#8884d8" /> */}
          {/* <Bar dataKey="pv" barSize={20} fill="#FF0E0E" /> */}
          <Bar
            name="Percentage"
            dataKey="Number"
            barSize={20}
            fill={Constants.primaryTheme}
            stackId="name"
            unit="%"
          />
          <XAxis type="number" orientation="bottom" />

          {/* <Bar dataKey="Number" barSize={20}>
          {paramData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Bar> */}

          {/* <Line dataKey="uv" stroke="#ff7300" /> */}
        </ComposedChart>

        <p
          style={{
            textAlign: 'right',
            color: Constants.darkGray,
            fontStyle: 'italic',
          }}
        >{`N = ${tempData.length} of respondents`}</p>
      </div>
    )
  }

  renderBarChart = () => {
    let windowWidth = window.innerWidth
    let windowHeight = window.innerHeight
    let { test_type } = this.state

    let pcr = test_type.filter((a) => a.includes('PCR'))
    let antigen = test_type.filter((a) => a.includes('Antigen'))
    let antibody = test_type.filter((a) => a.includes('Antibody'))
    let tcell = test_type.filter((a) => a.includes('T-Cell test'))
    let dontknow = test_type.filter((a) =>
      a.includes('I dont know what type of test I took'),
    )

    const data = [
      {
        name: 'PCR',

        Percentage: Math.round((pcr.length / test_type.length) * 100),
      },
      {
        name: 'Antigen',

        Percentage: Math.round((antigen.length / test_type.length) * 100),
      },
      {
        name: 'Antibody',

        Percentage: Math.round((antibody.length / test_type.length) * 100),
      },
      {
        name: 'T-Cell test',
        Percentage: Math.round((tcell.length / test_type.length) * 100),
      },
      {
        name: `Don't know`,
        Percentage: Math.round((dontknow.length / test_type.length) * 100),
      },
      {},
    ]

    const COLORS = [
      '#7067CF',
      '#20A892',
      '#FF9F0E',
      '#FF0E0E',
      '#585858',
      '#F08080',
    ]

    return (
      <ComposedChart
        layout="vertical"
        width={windowWidth > 600 ? 500 : windowWidth - windowWidth / 10}
        // width={windowWidth - windowWidth / 10}
        height={windowHeight - windowHeight / 2}
        data={data}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >
        {/* <CartesianGrid stroke="#f5f5f5" /> */}
        <YAxis
          dataKey="name"
          type="category"
          // scale="band"
          scale="point"
          // tickLine={false}
        />

        <Tooltip />
        {/* <Legend align="right" /> */}
        {/* <Area dataKey="amt" fill="#8884d8" stroke="#8884d8" /> */}
        <Bar
          dataKey="Percentage"
          barSize={20}
          fill={Constants.primaryTheme}
          unit="%"
        />

        <XAxis type="number" />

        {/* <Line dataKey="uv" stroke="#ff7300" /> */}
      </ComposedChart>
    )
  }

  renderPieCharts = (positive, negative, label, color1, color2) => {
    let windowWidth = window.innerWidth
    let windowHeight = window.innerHeight

    let perecentPositive = (positive / (positive + negative)) * 100
    let percentNegative = (negative / (negative + positive)) * 100

    const data = [
      {
        name: 'Group A',
        value: Math.round(perecentPositive),
        percent: Math.round(perecentPositive),
      },
      { name: 'Group B', value: Math.round(percentNegative) },
      // { name: "Group C", value: 300 },
      // { name: "Group D", value: 200 }
    ]
    const COLORS = [color1, color2]

    return (
      <div className={Styles.childDiv}>
        <PieChart
          width={
            windowWidth > 600 ? windowWidth / 7 : windowWidth - windowWidth / 4
          }
          height={windowHeight / 2.8}
        >
          {/* <Legend
            content={
              <div
                className={Styles.legendDiv}
                style={{
                  color: color1,
                }}
              >
                {data.map(
                  (entry, index) => entry.percent && `${entry.percent}%`,
                )}
              </div>
            }
          /> */}
          <Pie
            data={data}
            cx={windowWidth > 600 ? windowWidth / 7 / 2 : windowWidth / 4}
            cy={150}
            innerRadius={50}
            outerRadius={70}
            fill="#8884d8"
            paddingAngle={5}
            dataKey="value"
            // isAnimationActive={false}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}

            {data.map((entry, index) => (
              <Label
                value={entry.name === 'Group A' ? `${entry.value}%` : ``}
                position="center"
                fill={COLORS[index % COLORS.length]}
                style={{ fontWeight: 'bold', fontSize: 'larger' }}
              />
            ))}
          </Pie>
        </PieChart>
        <p className={Styles.labelText}>
          {label}
          {/* {' : '}
          <p
            className={Styles.legendDiv}
            style={{
              color: color1,
            }}
          >
            {data.map((entry, index) => entry.percent && `${entry.percent}%`)}
          </p> */}
        </p>
      </div>
    )
  }

  renderSeverity = (severity) => {
    return (
      <div
        style={{
          width: '60%',
          alignSelf: 'self-start',
          marginRight: '5vw',
          marginLeft: '10vw',
          display: 'flex',
          flexDirection: 'row',
          marginTop: '1%',
          marginBottom: '1%',
        }}
      >
        <SeverityScale
          bgcolor={Constants.primaryThemeMedium}
          progress={severity}
          height={20}
        />
      </div>
    )
  }

  renderBodySummary = () => {
    let windowWidth = window.innerWidth
    let windowHeight = window.innerHeight

    let {
      covid_infection,
      positive_test,
      recovered_covid,
      seen_doctor,
      diagnosis_recieved,
      totalNumber,
      longcovidData,
    } = this.state

    let quality_data_before =
      longcovidData &&
      longcovidData.map((a) => Number(a.generalAbility.before_covid))
    let before_covid =
      (quality_data_before &&
        quality_data_before.reduce((a, b) => a + b, 0) /
          quality_data_before.length) ||
      0

    let quality_data_after =
      longcovidData &&
      longcovidData.map((a) => Number(a.generalAbility.after_covid))
    let after_covid =
      (quality_data_after &&
        quality_data_after.reduce((a, b) => a + b, 0) /
          quality_data_after.length) ||
      0

    let covid_data =
      longcovidData && longcovidData.map((a) => Number(a.covidHistory.severity))
    let covid_severity =
      (covid_data &&
        covid_data.reduce((a, b) => a + b, 0) / covid_data.length) ||
      0

    let headNeuro =
      longcovidData && longcovidData.map((a) => Number(a.headNeuro.severity))
    let head_severity =
      (headNeuro && headNeuro.reduce((a, b) => a + b, 0) / headNeuro.length) ||
      0

    let cardioVascular =
      longcovidData &&
      longcovidData.map((a) => Number(a.cardioVascular.severity))
    let cardio_severity =
      (cardioVascular &&
        cardioVascular.reduce((a, b) => a + b, 0) / cardioVascular.length) ||
      0

    let respiRatory =
      longcovidData && longcovidData.map((a) => Number(a.respiRatory.severity))
    let respiro_severity =
      (respiRatory &&
        respiRatory.reduce((a, b) => a + b, 0) / respiRatory.length) ||
      0

    let stomachDigestive =
      longcovidData &&
      longcovidData.map((a) => Number(a.stomachDigestive.severity))
    let stomach_severity =
      (stomachDigestive &&
        stomachDigestive.reduce((a, b) => a + b, 0) /
          stomachDigestive.length) ||
      0

    let endoCrine =
      longcovidData && longcovidData.map((a) => Number(a.endoCrine.severity))
    let endocrine_severity =
      (endoCrine && endoCrine.reduce((a, b) => a + b, 0) / endoCrine.length) ||
      0

    let jointPain =
      longcovidData && longcovidData.map((a) => Number(a.jointPain.severity))
    let joint_severity =
      (jointPain && jointPain.reduce((a, b) => a + b, 0) / jointPain.length) ||
      0

    let mentalHealth =
      longcovidData && longcovidData.map((a) => Number(a.mentalHealth.severity))
    let mental_severity =
      (mentalHealth &&
        mentalHealth.reduce((a, b) => a + b, 0) / mentalHealth.length) ||
      0

    let generalHealth =
      longcovidData &&
      longcovidData.map((a) => Number(a.generalHealth.severity))
    let general_severity =
      (generalHealth &&
        generalHealth.reduce((a, b) => a + b, 0) / generalHealth.length) ||
      0

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '4%',
        }}
      >
        <h4
          style={{
            // marginTop: 10,
            color: Constants.primaryThemeDark,
            // fontWeight: 'bold',
            // marginBottom: 20,
            fontSize: 25,
            flexWrap: 'wrap',
            width: windowWidth - windowWidth / 5,
            textAlign: 'left',
            margin: '1%',
            zIndex: 10,
          }}
        >
          Body System Summary
        </h4>

        <p style={{ borderBottom: '1px solid #d0d0d0', width: '90%' }}></p>

        <h5
          style={{
            color: Constants.primaryThemeDark,
            fontSize: 20,
            flexWrap: 'wrap',
            width: windowWidth - windowWidth / 5,
            textAlign: 'left',
            margin: '1%',
            zIndex: 10,
          }}
        >
          Overall Severity of Long Covid
        </h5>
        {this.renderSeverity(Math.round(covid_severity))}

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'left',
            alignSelf: 'left',
          }}
        >
          <img alt="Aila Health" src={BrainIcon} className={Styles.imageIcon} />
          <h5
            className={Styles.miniHeading}
            style={{ width: windowWidth - windowWidth / 5 }}
          >
            Head & Neurological Symptoms
          </h5>
        </div>
        {this.renderSeverity(Math.round(head_severity))}

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'left',
            alignSelf: 'left',
          }}
        >
          <img
            alt="Aila Health"
            src={CardioIcon}
            className={Styles.imageIcon}
          />

          <h5
            className={Styles.miniHeading}
            style={{ width: windowWidth - windowWidth / 5 }}
          >
            Cardiovascular Symptoms
          </h5>
        </div>

        {this.renderSeverity(Math.round(cardio_severity))}

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'left',
            alignSelf: 'left',
          }}
        >
          <img alt="Aila Health" src={LungIcon} className={Styles.imageIcon} />

          <h5
            className={Styles.miniHeading}
            style={{ width: windowWidth - windowWidth / 5 }}
          >
            Respiratory Symptoms
          </h5>
        </div>

        {this.renderSeverity(Math.round(respiro_severity))}

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'left',
            alignSelf: 'left',
          }}
        >
          <img
            alt="Aila Health"
            src={StomachIcon}
            className={Styles.imageIcon}
          />

          <h5
            className={Styles.miniHeading}
            style={{ width: windowWidth - windowWidth / 5 }}
          >
            Gastrointestinal Symptoms
          </h5>
        </div>

        {this.renderSeverity(Math.round(stomach_severity))}

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'left',
            alignSelf: 'left',
          }}
        >
          <img
            alt="Aila Health"
            src={EndocrineIcon}
            className={Styles.imageIcon}
          />

          <h5
            className={Styles.miniHeading}
            style={{ width: windowWidth - windowWidth / 5 }}
          >
            Endocrine and Hormonal Symptoms
          </h5>
        </div>

        {this.renderSeverity(Math.round(endocrine_severity))}

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'left',
            alignSelf: 'left',
          }}
        >
          <img alt="Aila Health" src={JointIcon} className={Styles.imageIcon} />

          <h5
            className={Styles.miniHeading}
            style={{ width: windowWidth - windowWidth / 5 }}
          >
            Joint and Muscle Pain Symptoms
          </h5>
        </div>

        {this.renderSeverity(Math.round(joint_severity))}

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'left',
            alignSelf: 'left',
          }}
        >
          <img
            alt="Aila Health"
            src={MentalHealthIcon}
            className={Styles.imageIcon}
          />

          <h5
            className={Styles.miniHeading}
            style={{ width: windowWidth - windowWidth / 5 }}
          >
            Mental Health Symptoms
          </h5>
        </div>

        {this.renderSeverity(Math.round(mental_severity))}

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'left',
            alignSelf: 'left',
          }}
        >
          <img
            alt="Aila Health"
            src={GeneralHealthIcon}
            className={Styles.imageIcon}
          />

          <h5
            className={Styles.miniHeading}
            style={{ width: windowWidth - windowWidth / 5 }}
          >
            General Symptoms
          </h5>
        </div>

        {this.renderSeverity(Math.round(general_severity))}

        <p
          style={{
            borderBottom: '1px solid #d0d0d0',
            width: '90%',
            margin: '1%',
          }}
        ></p>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            // alignItems: 'left',
            // alignSelf: 'left',
            justifyContent: 'center',
          }}
        >
          <img alt="Aila Health" src={BrainIcon} className={Styles.imageIcon} />
          <h5
            className={Styles.miniHeading}
            // style={{ width: windowWidth - windowWidth / 5 }}
            style={{ textAlign: 'center', fontSize: 25, margin: 'auto' }}
          >
            Head & Neurological Symptoms
          </h5>
        </div>
        {longcovidData &&
          this.renderSeverityBars(
            HEAD_NEURO,
            longcovidData.map((a) => a.headNeuro.symptoms),
          )}

        <p
          style={{
            borderBottom: '1px solid #d0d0d0',
            width: '90%',
            margin: '1%',
          }}
        ></p>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            // alignItems: 'left',
            // alignSelf: 'left',
            justifyContent: 'center',
          }}
        >
          <img
            alt="Aila Health"
            src={CardioIcon}
            className={Styles.imageIcon}
          />
          <h5
            className={Styles.miniHeading}
            // style={{ width: windowWidth - windowWidth / 5 }}
            style={{ textAlign: 'center', fontSize: 25, margin: 'auto' }}
          >
            Cardiovascular Symptoms
          </h5>
        </div>
        {longcovidData &&
          this.renderSeverityBars(
            CARDIO_VASCULAR,
            longcovidData.map((a) => a.cardioVascular.symptoms),
          )}

        <p
          style={{
            borderBottom: '1px solid #d0d0d0',
            width: '90%',
            margin: '1%',
          }}
        ></p>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            // alignItems: 'left',
            // alignSelf: 'left',
            justifyContent: 'center',
          }}
        >
          <img alt="Aila Health" src={LungIcon} className={Styles.imageIcon} />
          <h5
            className={Styles.miniHeading}
            // style={{ width: windowWidth - windowWidth / 5 }}
            style={{ textAlign: 'center', fontSize: 25, margin: 'auto' }}
          >
            Respiratory Symptoms
          </h5>
        </div>
        {longcovidData &&
          this.renderSeverityBars(
            RESPI_RATORY,
            longcovidData.map((a) => a.respiRatory.symptoms),
          )}

        <p
          style={{
            borderBottom: '1px solid #d0d0d0',
            width: '90%',
            margin: '1%',
          }}
        ></p>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            // alignItems: 'left',
            // alignSelf: 'left',
            justifyContent: 'center',
          }}
        >
          <img
            alt="Aila Health"
            src={StomachIcon}
            className={Styles.imageIcon}
          />
          <h5
            className={Styles.miniHeading}
            // style={{ width: windowWidth - windowWidth / 5 }}
            style={{ textAlign: 'center', fontSize: 25, margin: 'auto' }}
          >
            Gastrointestinal Symptoms
          </h5>
        </div>
        {longcovidData &&
          this.renderSeverityBars(
            STO_MACH,
            longcovidData.map((a) => a.stomachDigestive.symptoms),
          )}

        <p
          style={{
            borderBottom: '1px solid #d0d0d0',
            width: '90%',
            margin: '1%',
          }}
        ></p>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            // alignItems: 'left',
            // alignSelf: 'left',
            justifyContent: 'center',
          }}
        >
          <img
            alt="Aila Health"
            src={EndocrineIcon}
            className={Styles.imageIcon}
          />
          <h5
            className={Styles.miniHeading}
            // style={{ width: windowWidth - windowWidth / 5 }}
            style={{ textAlign: 'center', fontSize: 25, margin: 'auto' }}
          >
            Endocrine and Hormonal Symptoms
          </h5>
        </div>
        {longcovidData &&
          this.renderSeverityBars(
            ENDO_CRINE,
            longcovidData.map((a) => a.endoCrine.symptoms),
          )}

        <p
          style={{
            borderBottom: '1px solid #d0d0d0',
            width: '90%',
            margin: '1%',
          }}
        ></p>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            // alignItems: 'left',
            // alignSelf: 'left',
            justifyContent: 'center',
          }}
        >
          <img alt="Aila Health" src={JointIcon} className={Styles.imageIcon} />
          <h5
            className={Styles.miniHeading}
            // style={{ width: windowWidth - windowWidth / 5 }}
            style={{ textAlign: 'center', fontSize: 25, margin: 'auto' }}
          >
            Joint and Muscle Pain Symptoms
          </h5>
        </div>
        {longcovidData &&
          this.renderSeverityBars(
            JOINT_PAIN,
            longcovidData.map((a) => a.jointPain.symptoms),
          )}

        <p
          style={{
            borderBottom: '1px solid #d0d0d0',
            width: '90%',
            margin: '1%',
          }}
        ></p>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            // alignItems: 'left',
            // alignSelf: 'left',
            justifyContent: 'center',
          }}
        >
          <img
            alt="Aila Health"
            src={MentalHealthIcon}
            className={Styles.imageIcon}
          />
          <h5
            className={Styles.miniHeading}
            // style={{ width: windowWidth - windowWidth / 5 }}
            style={{ textAlign: 'center', fontSize: 25, margin: 'auto' }}
          >
            Mental Health Symptoms
          </h5>
        </div>
        {longcovidData &&
          this.renderSeverityBars(
            MENTAL_HEALTH,
            longcovidData.map((a) => a.mentalHealth.symptoms),
          )}

        <p
          style={{
            borderBottom: '1px solid #d0d0d0',
            width: '90%',
            margin: '1%',
          }}
        ></p>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            // alignItems: 'left',
            // alignSelf: 'left',
            justifyContent: 'center',
          }}
        >
          <img
            alt="Aila Health"
            src={GeneralHealthIcon}
            className={Styles.imageIcon}
          />
          <h5
            className={Styles.miniHeading}
            // style={{ width: windowWidth - windowWidth / 5 }}
            style={{ textAlign: 'center', fontSize: 25, margin: 'auto' }}
          >
            General Symptoms
          </h5>
        </div>
        {longcovidData &&
          this.renderSeverityBars(
            GENERAL_HEALTH,
            longcovidData.map((a) => a.generalHealth.symptoms),
          )}

        <h4
          style={{
            // marginTop: 10,
            color: Constants.primaryThemeDark,
            // fontWeight: 'bold',
            // marginBottom: 20,
            fontSize: 25,
            flexWrap: 'wrap',
            width: windowWidth - windowWidth / 5,
            textAlign: 'left',
            margin: '1%',
            zIndex: 10,
          }}
        >
          Quality of Life Scores
        </h4>

        <p style={{ borderBottom: '1px solid #d0d0d0', width: '90%' }}></p>

        <h5
          style={{
            color: Constants.primaryThemeDark,
            fontSize: 20,
            flexWrap: 'wrap',
            width: windowWidth - windowWidth / 5,
            textAlign: 'left',
            margin: '1%',
            zIndex: 10,
          }}
        >
          Before Long Covid
        </h5>
        {this.renderSeverity(Math.round(before_covid))}

        {/* <p style={{ borderBottom: '1px solid #d0d0d0', width: '90%' }}></p> */}

        <h5
          style={{
            color: Constants.primaryThemeDark,
            fontSize: 20,
            flexWrap: 'wrap',
            width: windowWidth - windowWidth / 5,
            textAlign: 'left',
            margin: '1%',
            zIndex: 10,
          }}
        >
          After Long Covid
        </h5>
        {this.renderSeverity(Math.round(after_covid))}
      </div>
    )
  }

  renderCovidHistory = () => {
    let windowWidth = window.innerWidth
    let windowHeight = window.innerHeight

    let {
      covid_infection,
      positive_test,
      recovered_covid,
      seen_doctor,
      diagnosis_recieved,
      test_type,
      totalNumber,
      longcovidData,
    } = this.state

    let temp_weeks =
      longcovidData &&
      longcovidData.map((a) => Number(a.covidHistory.duration_weeks) * 7)
    let temp_days =
      longcovidData &&
      longcovidData.map((a) => Number(a.covidHistory.duration_days))

    let temp = temp_days && temp_weeks && temp_weeks.concat(temp_days)

    let avg_days = (temp && temp.reduce((a, b) => a + b, 0) / temp.length) || 0
    let duration_weeks = avg_days / 7
    let duration_days = avg_days % 7

    //calculate avg time of long covid
    let tempArray =
      longcovidData &&
      longcovidData.filter((a) => a.covidHistory.symptoms_start.length > 0)

    let datesArray =
      tempArray &&
      tempArray.map((a) =>
        moment(new Date()).diff(moment(a.covidHistory.symptoms_start), 'days'),
      )

    let avg_time =
      (datesArray &&
        datesArray.reduce((a, b) => a + b, 0) / datesArray.length) ||
      0

    var time_years = Math.floor(avg_time / 365)
    var time_months = Math.floor((avg_time % 365) / 30)
    var time_days = Math.floor((avg_time % 365) % 30)

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '4%',
        }}
      >
        <h4
          style={{
            // marginTop: 10,
            color: Constants.primaryThemeDark,
            // fontWeight: 'bold',
            // marginBottom: 20,
            fontSize: 25,
            flexWrap: 'wrap',
            width: windowWidth - windowWidth / 5,
            textAlign: 'left',
            margin: '1%',
            zIndex: 10,
          }}
        >
          Covid History
        </h4>

        <p style={{ borderBottom: '1px solid #d0d0d0', width: '90%' }}></p>
        <div
          className={Styles.parentDiv}
          // style={{
          //   display: 'flex',
          //   flexDirection: 'row',
          //   alignItems: 'center',
          //   justifyContent: 'center',
          //   // margin: '1%',
          //   height: windowHeight / 3,
          // }}
        >
          {this.renderPieCharts(
            covid_infection,
            totalNumber - covid_infection,
            'Had a Covid-19 infection',
            '#FF0E0E',
            '#FFCDD2',
          )}
          {this.renderPieCharts(
            positive_test,
            totalNumber - positive_test,
            'Received a Positive Covid test',
            Constants.primaryTheme,
            '#d9e6e6',
          )}
          {this.renderPieCharts(
            recovered_covid,
            totalNumber - recovered_covid,
            'Recovered from initial infection',
            '#EF6C00',
            '#FFE0B2',
          )}
          {this.renderPieCharts(
            seen_doctor,
            totalNumber - seen_doctor,
            'Seen a doctor for Long Covid Symptoms',
            '#6A1B9A',
            '#E1BEE7',
          )}
          {this.renderPieCharts(
            diagnosis_recieved,
            totalNumber - diagnosis_recieved,
            'Received a Long Covid diagnosis',
            '#0277BD',
            '#BBDEFB',
          )}
        </div>

        <p
          style={{
            borderBottom: '1px solid #d0d0d0',
            width: '90%',
            marginTop: '3%',
          }}
        ></p>

        <div
          className={Styles.testTypeDiv}
          // style={{
          //   display: 'flex',
          //   flexDirection: 'row',
          //   justifyContent: 'space-evenly',
          //   margin: '1%',
          // }}
        >
          <h5
            className={Styles.covidInfection}
            style={{
              // marginTop: 10,
              color: Constants.primaryThemeDark,
            }}
          >
            {`Average Duration of initial Covid infection: ${Math.round(
              duration_weeks,
            )} Weeks, ${Math.round(duration_days)} Days`}
            <br />
            <br />
            Average Duration of Long Covid:{' '}
            {time_years === 0 && time_months === 0 && time_days === 0 && `NIL`}
            {time_years !== 0 && `${Math.round(time_years)} Years,`}{' '}
            {time_months !== 0 && `${Math.round(time_months)} Months,`}{' '}
            {time_days !== 0 && `${Math.round(time_days)} Days`}
          </h5>

          <div style={{ width: '50%' }}>
            <h5
              style={{
                // marginTop: 10,
                color: Constants.primaryThemeDark,
                // fontWeight: 'bold',
                // marginBottom: 20,
                fontSize: 20,
                flexWrap: 'wrap',
                width: windowWidth - windowWidth / 5,
                textAlign: 'left',
                margin: '1%',
                zIndex: 10,
              }}
            >
              Test Type
            </h5>
            <div style={{ textAlign: 'center', margin: '1%' }}>
              {this.renderBarChart()}
              <p
                style={{
                  textAlign: 'center',
                  color: Constants.darkGray,
                  fontStyle: 'italic',
                  // width: 500,
                }}
              >{`N = ${test_type.length} of respondents`}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderInsights = () => {
    let windowWidth = window.innerWidth

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '4%',
        }}
      >
        <h4
          style={{
            // marginTop: 10,
            color: Constants.primaryThemeDark,
            // fontWeight: 'bold',
            // marginBottom: 20,
            fontSize: 25,
            flexWrap: 'wrap',
            width: windowWidth - windowWidth / 5,
            textAlign: 'left',
            margin: '1%',
          }}
        >
          Long Covid Insights
        </h4>
        {/* <div className={Styles.row}> */}
        <p style={{ borderBottom: '1px solid #d0d0d0', width: '90%' }}></p>
        {/* </div> */}
        <h5
          style={{
            // marginTop: 10,
            // color: Constants.primaryThemeDark,
            // fontWeight: 'bold',
            // marginBottom: 20,
            fontSize: 20,
            flexWrap: 'wrap',
            width: windowWidth - windowWidth / 5,
            textAlign: 'left',
            margin: '1%',
          }}
        >
          Total Number who have taken this survey: {this.state.totalNumber}
          <br />
          <br />
          Share this survey on social media to increase awareness and support
          for fellow longhaulers.
        </h5>

        <div
          style={{
            textAlign: 'left',
            // marginTop: 30,
            // marginBottom: 30,
            width: windowWidth - windowWidth / 5,
            margin: '1%',
          }}
        >
          <FacebookShareButton
            url="https://www.facebook.com/sharer/sharer.php?u=longcovid.io"
            // title={socialTitle}
            quote={socialTitle}
            hashtag={'#Cronicillness'}
          >
            <FacebookIcon size={40} style={{ margin: 10 }} round />
          </FacebookShareButton>

          <TwitterShareButton url="longcovid.io" title={socialTitle}>
            <TwitterIcon size={40} style={{ margin: 10 }} round />
          </TwitterShareButton>

          {/* <PinterestShareButton
                    description={socialTitle}
                    media="https://longcovid.io/"
                  >
                    <PinterestIcon size={50} style={{ margin: 10 }} round />
                  </PinterestShareButton> */}

          <WhatsappShareButton url={urlSocial} title={socialTitle}>
            <WhatsappIcon size={40} style={{ margin: 10 }} round />
          </WhatsappShareButton>

          <LinkedinShareButton
            url="https://www.linkedin.com/sharing/share-offsite/?url=longcovid.io"
            title={socialTitle}
          >
            <LinkedinIcon size={40} style={{ margin: 10 }} round />
          </LinkedinShareButton>

          <EmailShareButton
            url="longcovid.io"
            subject={'Support the Long Covid community!'}
            body={socialTitle}
            openShareDialogOnClick={true}
            onClick={() => {}}
            // title={socialTitle}
          >
            <EmailIcon size={40} style={{ margin: 10 }} round />
          </EmailShareButton>
        </div>
        <p style={{ borderBottom: '1px solid #d0d0d0', width: '90%' }}></p>
      </div>
    )
  }

  render() {
    return (
      <div
        className={GlobalStyles.container}
        style={{
          overflow: 'hidden',
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <p
          style={{
            fontSize: '30px',
            color: Constants.primaryThemeDark,
            textAlign: 'center',
            fontWeight: 'bold',
            fontFamily: 'Avenir',
            wordWrap: 'break-word',
            marginTop: '4vh',
          }}
        >
          Long Covid Dashboard
        </p>
        {this.renderInsights()}
        {this.renderCovidHistory()}
        {this.renderBodySummary()}
        <div
          style={{
            bottom: 0,
            marginTop: '10%',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <img
            src={AilaLogo}
            style={{ height: 80, width: 80, float: 'center' }}
          />
          Powered by Aila Health
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state /* , ownProps */) => {
  return {
    patient: state.patientReducer.patient,
    token: state.authReducer.token,
    firebaseAuthUser: state.userReducer.firebaseAuthUser,
  }
}

const mapDispatchToProps = {
  addFirebaseUser,
  addFirebaseAuthUser,
  addPatient,
}

export default connect(mapStateToProps, mapDispatchToProps)(LongcovidDashboard)
