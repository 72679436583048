import React, { Component } from 'react'
import GlobalStyles from '../styles/global.module.scss'
import Styles from './styles/PracticeInfo.module.scss'
import Constants from '../../values.js'
import Config from '../../config'
import * as Metadata from '../../metadata.js'
import { ToastContainer, toast } from 'react-toastify'
import BSModal from 'react-bootstrap/Modal'
import ModalDialog from 'react-bootstrap/ModalDialog'
import moment from 'moment'
import Modal from 'react-modal'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTrash,
  faPlusCircle,
  faPaperclip,
  faCheckCircle,
  faCalendarPlus,
  faEdit,
  faSearch,
} from '@fortawesome/free-solid-svg-icons'
import Header from '../../components/Header'
import algoliasearch from 'algoliasearch/lite'
import {
  InstantSearch,
  SearchBox,
  Hits,
  RefinementList,
  InfiniteHits,
  connectStateResults,
  connectSearchBox,
  connectCurrentRefinements,
} from 'react-instantsearch-dom'
import { Link } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Draggable from 'react-draggable'
import Select from 'react-select'
import cloneDeep from 'lodash/cloneDeep'
import TaskModal from '../Patients/Careplan/TaskModal'
import {
  stopTimer,
  resetTimer,
  startTimer,
  setAppointmentNotesData,
  fetchPatientCarePlanData,
  addPatientList,
  addPatient,
  toggleSidebar,
  fetchPatientCoreDate,
  fetchPatientProgressData,
  fetchPatientWellnessScreening,
  fetchPatientCalculateProgressData,
  fetchPatientConditions,
  fetchPatientMedications,
  fetchPatientLabs,
  updatePatientData,
  updateSideNavBar,
  addPatientLP,
} from '../../redux/actions'
import ReactTooltip from 'react-tooltip'
import firebase from '../../services/firebase'
import Fuse from 'fuse.js'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import { geocodeByAddress } from 'react-google-places-autocomplete'
import PlaceHolderProfileImage from '../../assets/images/placeholder.png'
import { claimsColumns } from './Columns/claimsColumns'
import { invoicesColumns } from './Columns/invoicesColumns'
import { payoutsColumns } from './Columns/payoutsColumns'
import NotesModal from '../Appointments/NotesModal'
import htmlModule from '../../helpers/PdfTemplates/SuperbillTemplate.txt'
import NotesPdf from '../../helpers/PdfTemplates/NotesPdf.txt'
import VisitNotesPdf from '../../helpers/PdfTemplates/VisitNotesPdf.txt'
import Helpers from '../../helpers/Global'
import Axios from 'axios'
import Handlebars from 'handlebars'
import questionMetadata from '../../questionMetadata'
import PaginationTable from '../../components/PaginationTable'
import Table from '../../components/Table'
import PatientInsurance from '../PatientWeb/PatientAccount/PatientInsurance'
import { debounce, map } from 'lodash'
import { CSVLink } from 'react-csv'
import { qualityManagementColumns } from './Columns/qualityManagementColumns'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const axios = require('axios')

const PROVIDER_GOALS = [
  { text: 'sleep_goal', value: '' },
  { text: 'weight_goal', value: '' },
  { text: 'step_goal', value: '' },
  { text: 'meditation_goal', value: '' },
  { text: 'exercise_goal', value: '' },
  { text: 'weight_loss_goal', value: '' },
  { text: 'change_habits', value: '' },
]

const customModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '60%',
    height: '80%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
  },
}

const customChangeModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 10,
  },
  content: {
    zIndex: 10,
    width: '80%',
    height: '95%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}
const insuranceAddModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '75%',
    height: '90%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 10,
    zIndex: 2,
  },
}
const stripeModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '55%',
    height: '35%',
    top: '30%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 10,
    zIndex: 2,
  },
}

const searchClient = algoliasearch(
  Config.ALGOLIA_APP_ID,
  Config.ALGOLIA_API_KEY,
)

const condnClient = {
  search(requests) {
    if (requests[0].params.query === '') {
      return []
    }
    return searchClient.search(requests)
  },
}

const eventFire = (el, etype) => {
  if (el.fireEvent) {
    el.fireEvent('on' + etype)
  } else {
    var evObj = document.createEvent('Events')
    evObj.initEvent(etype, true, false)
    el.dispatchEvent(evObj)
  }
}

const ClearQuery = ({ refine }) => {
  const onClick = (ev) => {
    refine('')
    ev.stopPropagation()
  }
  return (
    <div onClick={onClick} style={{ display: 'none' }}>
      <ConnectedClearAllRefinements />
    </div>
  )
}

const ClearAllRefinements = ({ refine, items }) => {
  const onClick = () => refine(items)
  return (
    <button id="icdclear" onClick={onClick}>
      ClearAll
    </button>
  )
}

const ClearQueryAndRefinements = connectSearchBox(ClearQuery)
const ConnectedClearAllRefinements =
  connectCurrentRefinements(ClearAllRefinements)

class QualityManagement extends Component {
  constructor(props) {
    super(props)
    let notesData = this.props?.carePlanNotesData
    let reduxAppNotes = this.props?.appointmentNotesData

    this.state = {
      loading: false,
      selectedTab: 'Claims',
      providerMap: {},
      patientList: [],
      patientMap: {},
      events: null,
      reload: false,
      eventsFromBackend: {},
      eventIdsFromBackend: [],
      ailaEventsSelected: true,
      claims: [],
      invoices: [],
      payouts: [],
      downloadData: [],
      dataToDownload: [],
      stripeCharge: {},
      pdfData: '',
      showModal: false,
      showNotesModal: false,
      selectedPatient: null,
      providerConditions: [],
      providerAllergies: [],
      providerFoodSensitivities: [],
      providerImmunization: [],
      providerMedications: [],
      providerGoals: [],
      wellnessData: null,
      conditionsData: [],
      refund_amount: '',
      charge_amount: '',
      appChargeData: {},
      stripeModalIsOpen: false,
      stripeChargeModalIsOpen: false,
      refundData: {},
      page: 1,
      size: 50,
      appointmentType: {
        label: 'Completed Appointments',
        value: 'Completed Appointments',
      },
      selectedNotesFromVisit: {},
      modalAddNotesIsOpen: false,
      insuranceModalIsOpen: false,
      editInsuranceData: null,
      selectedPatientId: null,
      selectedPatient: null,
      searchMonth: new Date(),
    }
    this.fuse = null
    this.imageUploadRef = React.createRef()
    this.fileRef = React.createRef()
    this.csvlink = React.createRef()
    this.getAllProviders = this.getAllProviders.bind(this)
    this.preparePatientList = this.preparePatientList.bind(this)
    this.getInsuranceClaims = this.getInsuranceClaims.bind(this)
    this.generatePdf = this.generatePdf.bind(this)
    this.generateNotesPdf = this.generateNotesPdf.bind(this)
    this.getStripeChargeUrl = this.getStripeChargeUrl.bind(this)
    this.getStripeRefund = this.getStripeRefund.bind(this)
    this.openStripeRefundModal = this.openStripeRefundModal.bind(this)
    this.openStripeChargeModal = this.openStripeChargeModal.bind(this)
    this.hideNotesModal = this.hideNotesModal.bind(this)
    this.openNotesEditModal = this.openNotesEditModal.bind(this)
    this.getProviderMedications = this.getProviderMedications.bind(this)
    this.getProviderAllergies = this.getProviderAllergies.bind(this)
    this.getPatientGoals = this.getPatientGoals.bind(this)
    this.getProviderConditions = this.getProviderConditions.bind(this)
    this.navigatetoPatientProfile = this.navigatetoPatientProfile.bind(this)
    this.handleQualityReview = this.handleQualityReview.bind(this)
    this.onInsuranceEditClicked = this.onInsuranceEditClicked.bind(this)
    this.renderEditable = this.renderEditable.bind(this)
    this.handleInputChange = debounce(this.handleInputChange.bind(this), 5000)
    this.download = this.download.bind(this)
  }

  componentDidMount() {
    if (!this.props.loggedIn) {
      this.props.history.push('/login')
      return
    }
    this.setState({ loading: true })
    this.getAllProviders()
    this.getAppointmentInvoice()
    this.getInsuranceClaims()
  }

  async getAllProviders() {
    const { token, adminId } = this.props

    // get all providers
    const url = `${Config.BACKEND_URL}providers`

    axios({
      method: 'get',
      headers: {
        'Access-Control-Allow-Origin': '*',
        Accept: '*/*',
        Authorization: `JWT ${token}`,
        x_firebase_id: adminId,
        getallproviders: true,
      },
      url,
    })
      .then(async ({ data }) => {
        let docObj = {}
        if (data) {
          data.forEach((x) => {
            docObj[x.firebase_id] = x
          })
          this.setState(
            {
              providerMap: docObj,
            },
            () => this.preparePatientList(),
          )
        }
      })
      .catch((err) => {
        console.log('error when getting all providers', err)
        toast.error('Something went wrong')
        this.setState({ loading: false, showScheduleModal: false })
      })
  }

  getConditionsData(patient) {
    let { token } = this.props
    let url = Config.BACKEND_URL + 'user/conditions'
    let self = this
    axios({
      method: 'get',
      url: url,
      headers: { Authorization: 'JWT ' + token, x_firebase_id: patient?.uid },
    })
      .then((response) => {
        console.log('got conditions data')
        self.setState({ conditionsData: response.data })
      })
      .catch((err) => {
        console.log('error when getting conditions data', err)
        toast.error('Something went wrong')
      })
  }

  async getProviderMedications(patient) {
    let { token } = this.props
    try {
      const getMeds = async () => {
        let url =
          Config.BACKEND_URL + 'fhir/MedicationRequest?patient=' + patient.uid
        return axios({
          method: 'get',
          url: url,
          headers: {
            x_firebase_id: patient.uid,
            Authorization: 'JWT ' + token,
          },
        })
      }
      let response = await getMeds()
      if (response?.data) {
        let meds = response.data
        this.setState({ providerMedications: meds })
      } else {
        this.setState({ providerMedications: [] })
      }
    } catch (err) {
      console.log('error when getting meds data', err)
      toast.error('Something went wrong when getting meds')
    }
  }

  async getProviderAllergies(patient) {
    let { token } = this.props
    try {
      const getAllergy = async () => {
        let url =
          Config.BACKEND_URL + 'fhir/AllergyIntolerance?patient=' + patient?.uid
        return axios({
          method: 'get',
          url: url,
          headers: {
            x_firebase_id: patient?.uid,
            Authorization: 'JWT ' + token,
          },
        })
      }
      let response = await getAllergy()
      if (response?.data) {
        //if there is something in data, then the particle records for the patient has been pulled at least once
        let allergies = response?.data || []
        this.setState({ providerAllergies: allergies })
      } else {
        this.setState({ providerAllergies: [] })
      }
    } catch (err) {
      console.log('error when getting allergies data', err)
      toast.error('Something went wrong when getting allergies')
    }
  }

  handleQualityReview(appointment, toSave) {
    let { token, adminId } = this.props
    console.log('claim updating')
    // this.setState({ loading: true })
    let updatedFields = toSave
    if (toSave?.updateReviewed) {
      updatedFields = {
        reviewed: toSave?.reviewed,
        reviewed_by: { supervisor: adminId, date: new Date() },
      }
    }
    console.log('claim updating', {
      event_id: appointment.event_id,
      ...updatedFields,
    })
    axios({
      method: 'put',
      url: `${Config.BACKEND_URL}appointment`,
      headers: {
        Authorization: `JWT ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        event_id: appointment.event_id,
        ...updatedFields,
      },
    })
      .then((result) => {
        console.log('updated claim')
        toast.success('Successfully updated')
        if (
          toSave?.updateReviewed ||
          toSave.hasOwnProperty('errorType') ||
          toSave.hasOwnProperty('quality_task')
        ) {
          this.getInsuranceClaims()
        }
      })
      .catch((err) => {
        this.setState({ loading: false })
        toast.error('Something went wrong, please try again')
        console.log('error when updating appointment', err)
      })
  }

  onInsuranceEditClicked(val, insurance, patientID) {
    console.log(val, insurance)
    this.props.toggleSidebar(val)
    this.setState({
      insuranceModalIsOpen: val,
      editInsuranceData: insurance,
      selectedPatientId: patientID,
    })
  }

  getPatientGoals(patient) {
    // this.setState({ loadingContent: true })
    let { token } = this.props
    let self = this
    let url = Config.BACKEND_URL + 'patients/goals'
    axios({
      method: 'get',
      headers: { Authorization: 'JWT ' + token, x_firebase_id: patient.uid },
      url: url,
    })
      .then(({ data }) => {
        // console.log('getting patient goals', data)
        self.setState({ patientGoals: data })
        let tempData = []
        if (!patient || !patient.goals || patient.goals.length === 0) {
          if (Object.keys(data).length === 0) {
            tempData = PROVIDER_GOALS.reverse()
          } else {
            Object.keys(data).forEach((element) => {
              if (element === 'free_form_goals') {
                return
              }
              tempData.push({ text: element, value: data[element] })
            })
          }
        } else {
          let data =
            patient?.goals?.length &&
            patient?.goals.map((x) => ({
              text: x.text,
              value: x.value,
              created_at: x.created_at,
            }))
          data.forEach((element) => {
            tempData.push(element)
          })
        }
        this.setState({
          providerGoals: tempData,
          // loadingContent: false
        })
      })
      .catch((err) => {
        console.log('error when getting patient goals data', err)
      })
  }

  async getProviderConditions(patient) {
    let { token } = this.props
    try {
      const getCondition = async () => {
        let url = Config.BACKEND_URL + 'fhir/Condition?patient=' + patient?.uid
        return axios({
          method: 'get',
          url: url,
          headers: {
            x_firebase_id: patient?.uid,
            Authorization: 'JWT ' + token,
          },
        })
      }
      let response = await getCondition()
      if (response?.data) {
        //if there is something in data, then the particle records for the patient has been pulled at least once
        let conditions = response.data || []
        this.setState({ providerConditions: conditions })
        // console.log(conditions)
      } else {
        this.setState({ providerConditions: [] })
      }
    } catch (err) {
      console.log('error when getting particle conditions data', err)
      toast.error('Something went wrong when getting external conditions')
    }
  }

  async preparePatientList(data) {
    const { patientList } = this.props
    if (!patientList) return
    let patientListData = data || patientList
    const patientMap = {}
    const list = []
    Object.keys(patientListData).forEach((rpmColor) => {
      const patientsColorList = patientListData[rpmColor]
      patientsColorList.forEach((patient) => {
        patientMap[patient.uid] = patient
        list.push({
          label: `${patient.firstName} ${patient.lastName}`,
          value: patient.uid,
        })
      })
    })

    this.setState({ patientMap, patientList: list })
  }

  hideNotesModal() {
    this.setState({ modalAddNotesIsOpen: false })
  }

  openNotesEditModal(careplan, appData) {
    let careplanNote = careplan
    careplanNote.doctor = careplanNote.doctor || appData?.doctor || {}
    this.setState({
      modalAddNotesIsOpen: true,
      selectedNotesFromVisit: careplanNote,
      selectedPatient: appData?.patientData || {},
    })
  }

  async getStripeChargeUrl(charge_id) {
    const { token, mongoUser } = this.props
    const url = `${Config.BACKEND_URL}stripe/charge`

    try {
      const response = await axios({
        method: 'get',
        url,
        headers: {
          Authorization: `JWT ${token}`,
          x_firebase_id: mongoUser.firebase_id,
          charge_id: charge_id,
        },
      })

      const chargeData = response.data

      console.log('stripeCharge recieved', chargeData)
      if (chargeData.receipt_url) window.open(chargeData.receipt_url)
      this.setState({ stripeCharge: chargeData || [] })
    } catch (err) {
      toast.error('Could not fetch stripeCharge, please try again')
      console.log('error when getting stripeCharge from backend', err)
    }
  }

  openStripeRefundModal(charge_id, transfer, amount_paid) {
    console.log('CLICKED on open modal:', charge_id, transfer, amount_paid)
    this.setState({
      stripeModalIsOpen: true,
      refundData: { charge_id, transfer, amount_paid },
    })
  }
  openStripeChargeModal(
    customer_id,
    transfer,
    event_id,
    duration,
    doctor_id,
    allowed_amount,
  ) {
    this.setState({
      stripeChargeModalIsOpen: true,
      appChargeData: {
        customer_id,
        transfer,
        event_id,
        duration,
        doctor_id,
        allowed_amount,
      },
    })
  }

  async getStripeRefund(charge_id, transfer, refund_amount) {
    const { token, mongoUser } = this.props
    const url = `${Config.BACKEND_URL}stripe/refund`

    try {
      let configData = {
        method: 'get',
        url,
        headers: {
          Authorization: `JWT ${token}`,
          x_firebase_id: mongoUser.firebase_id,
          charge_id: charge_id,
          refund_amount: Number(refund_amount) * 100,
        },
      }
      if (transfer) configData.headers.transfer = true

      const response = await axios(configData)

      const refundData = response.data
      if (refundData?.status === 'succeeded')
        toast.success('Successfully refunded')
      console.log('stripeRefund recieved', refundData)
      this.setState({
        refundData: {},
        stripeModalIsOpen: false,
        refund_amount: '',
      })
      this.getInsuranceClaims()
      // this.setState({ stripeCharge: chargeData || [] })
    } catch (err) {
      toast.error('Could not fetch stripeRefund, please try again')
      console.log('error when getting stripeRefund from backend', err)
    }
  }

  async getStripeCharge() {
    const { token, mongoUser } = this.props
    let { appChargeData, charge_amount } = this.state
    const url = `${Config.BACKEND_URL}stripe/appointment-payment`

    try {
      let configData = {
        method: 'get',
        url,
        headers: {
          Authorization: `JWT ${token}`,
          x_firebase_id: mongoUser.firebase_id,
          customer_id: appChargeData?.customer_id,
          event_id: appChargeData?.event_id,
          duration: appChargeData?.duration,
          doctor_id: appChargeData?.doctor_id,
          charge_amount: Number(charge_amount) * 100,
        },
      }

      if (appChargeData?.transfer) configData.headers.transfer = true

      const response = await axios(configData)

      const charge = response.data
      if (charge?.status === 'paid') toast.success('Successfully Charged')
      console.log('stripeCharge recieved', charge)
      this.setState({
        appChargeData: {},
        stripeChargeModalIsOpen: false,
        charge_amount: '',
      })
      this.getInsuranceClaims()
      // this.setState({ stripeCharge: chargeData || [] })
    } catch (err) {
      toast.error('Could not fetch stripeRefund, please try again')
      console.log('error when getting stripeRefund from backend', err)
    }
  }

  async generateNotesPdf(selectedNote, selectedPatient) {
    console.log('selectedNote: ', selectedNote)
    // this.setState({ loading: true })
    let patient = selectedPatient?.patientData || {}
    let wellnessData = selectedPatient?.wellnessData?.length
      ? selectedPatient?.wellnessData[selectedPatient?.wellnessData?.length - 1]
      : {}
    let supervisor = selectedPatient?.reviewed
      ? this.state.providerMap[selectedPatient?.reviewed_by?.supervisor]
      : null

    let { medications } = this.props
    this.setState({ selectedPatient: patient })

    await this.getConditionsData(patient)
    await this.getProviderConditions(patient)

    await this.getProviderAllergies(patient)
    await this.getProviderMedications(patient)

    // await this.props.fetchPatientCoreDate(patient.uid, this.props.token)
    // await this.props.fetchPatientConditions(patient.uid, this.props.token)
    await this.props.fetchPatientMedications(patient.uid, this.props.token)
    let { providerMedications, providerAllergies, conditionsData } = this.state

    let doctor = selectedPatient.providerData || {}
    let data
    let noteData
    let icd_10_data = ''
    let cpt_data = ''
    let amended_cpt_data = ''
    let conditions_data = ''
    let medication_data = ''
    let allergies_data = ''
    let family_history_data = ''
    let goals_list = ''
    let referrals_requested = ''
    let time_spent = ''

    //FAMILY HISTORY
    let familyScreening =
      selectedPatient && selectedPatient?.screening_family
        ? selectedPatient?.screening_family?.relations
        : []

    familyScreening &&
      familyScreening.forEach((each, index) => {
        let conditions = []
        if (each.conditions)
          conditions = each.conditions.map(
            (x, index) =>
              `${x} ${index === each.conditions.length - 1 ? '' : ', '}`,
          )
        family_history_data = family_history_data.concat(
          index + 1,
          '. ',
          each.relationship,
          ' : ',
          conditions,
          index !== familyScreening?.length && '\n',
        )
      })

    //MEDICATION
    let array1 = (medications && medications['active_medications']) || []
    let array2 =
      (providerMedications &&
        providerMedications['active_medications_provider']) ||
      []

    let array3 = (medications && medications['inactive_medications']) || []
    let array4 =
      (providerMedications &&
        providerMedications['inactive_medications_provider']) ||
      []

    let finalArray = array4.concat(array3.concat(array2.concat(array1)))
    // medication_data = finalArray

    finalArray.forEach((x, index) => {
      medication_data = medication_data.concat(
        index + 1,
        '. ',
        x.dose?.quantity
          ? `${x.name} (${x.dose?.quantity} ${x.dose?.unit}) : `
          : `${x.name} : `,

        x.frequency?.quantity
          ? `${x.frequency.quantity} / ${x.frequency.recurring} : `
          : '- : ',
        moment(x.start).format('MMM Do YYYY') + ' : ',
        x.end ? 'Inactive' : 'Active',
        index !== finalArray?.length && '\n',
      )
    })

    // CONDITIONS
    let conditions = conditionsData || []
    // filter out user entered data
    conditions = conditions.filter(
      (userCondn) =>
        !this.state.providerConditions?.some(
          (prodCondn) =>
            prodCondn?.code?.coding?.[0]?.display === userCondn?.symptom,
        ),
    )
    let newConditions = conditions?.concat(this.state.providerConditions)

    newConditions?.length &&
      newConditions.forEach((condn, index) => {
        conditions_data = conditions_data.concat(
          index + 1,
          '. ',
          condn.symptom
            ? condn.symptom
            : condn?.code?.coding?.[0]?.display?.replace(/"/g, ''),
          condn?.code?.coding?.[0]?.display && !condn?.userEntered
            ? ' (Doctor Reported) '
            : '',

          index !== newConditions?.length && '\n',
        )
      })

    //ALLERGIES
    let allergies = patient && patient.allergies ? patient?.allergies : []
    let newAllergies = providerAllergies?.concat(allergies)
    newAllergies.forEach((allergy, index) => {
      allergies_data = allergies_data.concat(
        index + 1,
        '. ',
        allergy?.code?.coding?.[0]?.display ||
          (allergy?.type &&
            (allergy?.name?.length ? allergy?.name?.join() : allergy?.type)) ||
          allergy,
        index !== newAllergies?.length && '\n',
      )
    })

    //NOTES DATA TO BE SHOWN IN THE PDF
    noteData = {
      selected_note: 'visible',
      chief_concern: selectedNote?.chief_concern,
      present_illness: selectedNote?.present_illness,
      physical_exam: selectedNote?.physical_exam,
    }

    let tempArray1 = selectedNote?.careplan || []
    tempArray1.forEach((careplan, index) => {
      icd_10_data = icd_10_data.concat(
        index + 1,
        '. ',
        careplan?.icd_10?.icd10_code,
        ' : ',
        careplan?.icd_10?.title,
        ' : ',
        careplan?.assessment,
        ' : ',
        careplan?.note,
        index !== tempArray1.length && '\n',
        // '\n',
      )
    })
    noteData.icd_10 = icd_10_data

    let tempArray2 = selectedNote?.cpt || []
    tempArray2.forEach(function (cpt, index) {
      cpt_data = cpt_data.concat(
        index + 1,
        '. ',
        cpt?.cpt_code,
        ' : ',
        cpt?.title,
        index !== tempArray2.length && '\n',
        // '\n',
      )
    })

    let tempAmendCptArray = selectedNote?.amended_cpt || []
    tempAmendCptArray.forEach(function (cpt, index) {
      amended_cpt_data = amended_cpt_data.concat(
        index + 1,
        '. ',
        cpt?.cpt_code,
        ' : ',
        cpt?.title,
        index !== tempAmendCptArray.length && '\n',
        // '\n',
      )
    })

    let socialHistory =
      questionMetadata['screenings/wellness']['lifestyle_habits.cigarette'] +
      `: ${wellnessData?.lifestyle_habits?.cigarette || 'N/A'}\n` +
      questionMetadata['screenings/wellness']['lifestyle_habits.alcohol'] +
      `: ${wellnessData?.lifestyle_habits?.alcohol || 'N/A'}\n` +
      questionMetadata['screenings/wellness']['lifestyle_habits.drugs'] +
      `: ${wellnessData?.lifestyle_habits?.drugs || 'N/A'}\n` +
      questionMetadata['screenings/wellness']['relationships.marital_status'] +
      `: ${wellnessData?.relationships?.marital_status || 'N/A'}\n`

    noteData.cpt_codes = cpt_data
    noteData.amended_cpt_codes = amended_cpt_data

    let bmiValues = Helpers.calculateBMIValues(
      patient?.height,
      patient?.weight_update || patient?.weight,
      patient?.gender,
      moment().diff(moment(patient?.dob), 'years'),
    )

    patient?.goals &&
      Object.keys(patient.goals).forEach(function (entry, index) {
        if (entry !== 'free_form_goals')
          goals_list = goals_list.concat(
            index + 1,
            '. ',
            entry === 'sleep_goal'
              ? 'Sleep'
              : entry === 'weight_goal'
              ? 'Weight'
              : entry === 'step_goal'
              ? 'Steps'
              : entry === 'meditation_goal'
              ? 'Meditation'
              : entry === 'exercise_goal'
              ? 'Exercise'
              : entry === 'weight_loss_goal'
              ? 'Weight Loss'
              : entry === 'change_habits'
              ? 'Habits'
              : entry || 'N/A',
            ' : ',
            patient.goals[entry] || 'N/A',
            index !== patient?.goals?.length && '\n',
            // '\n',
          )
      })

    let follow_up = selectedNote?.followupData?.follow_up_value
      ? `Requested follow up in ${selectedNote?.followupData?.follow_up_value} ${selectedNote?.followupData?.follow_up_unit?.label}`
      : 'N/A'

    referrals_requested =
      (
        selectedNote?.followupData?.refer_specialty_arr?.join(", ") ||
        selectedNote?.followupData?.refer_specialty?.label ||
        'N/A'
      ) +
      '\n' +
      (selectedNote?.followupData?.referral_suggested || '')

    time_spent = `
    Video Visit Start Time: ${moment
      .unix(selectedNote?.appointment_date / 1000)
      .format('lll')} ${new Date()
      .toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'long' })
      .substring(4)} \n
    Video Visit End Time: ${moment
      .unix(selectedNote?.appointment_end_date / 1000)
      .format('lll')} ${new Date()
      .toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'long' })
      .substring(4)} \n
    Video Consult Minutes: ${moment
      .unix(selectedNote?.appointment_end_date / 1000)
      .diff(
        moment.unix(selectedNote?.appointment_date / 1000),
        'minute',
      )} minutes \n
    Pre-Visit Review of Records: ${
      selectedNote?.time_spent?.pre_review_time || 0
    } minutes \n
    After Visit Documentation: ${
      selectedNote?.time_spent?.after_visit || 0
    } minutes \n
    Provider Collaboration: ${
      selectedNote?.time_spent?.provider_colab || 0
    } minutes \n
    Total Time Spent: ${
      Number(
        moment
          .unix(selectedNote?.appointment_end_date / 1000)
          .diff(moment.unix(selectedNote?.appointment_date / 1000), 'minute') ||
          0,
      ) +
      Number(selectedNote?.time_spent?.pre_review_time || 0) +
      Number(selectedNote?.time_spent?.after_visit || 0) +
      Number(selectedNote?.time_spent?.provider_colab || 0)
    } minutes\n
    Interpreter Present at Visit: N/A\n
    Method of visit: ${selectedNote?.time_spent?.method_of_visit || 'N/A'}
    `

    let insurance = Array.isArray(selectedPatient?.insurances)
      ? selectedPatient?.insurances?.find((ins) => ins.preference === 1) ||
        selectedPatient?.insurances[0]
      : selectedPatient?.insurances || null

    data = {
      //PATIENT INFORMATION
      date_of_service: `${moment
        .unix(selectedNote?.appointment_date / 1000)
        .format('MM/DD/YYYY')}`,
      patient_name:
        selectedPatient?.firstName + ' ' + selectedPatient?.lastName,
      patient_dob: moment(patient?.dob).format('MM/DD/YYYY'),
      patient_gender: patient?.gender,
      patient_address: selectedPatient?.streetAddress,
      patient_phone: selectedPatient?.phoneNumber,
      patient_email: selectedPatient?.email,
      insurance_provider: insurance?.insurance_provider,
      insurance_plan: insurance?.plan_type,
      insurance_member: insurance?.member_name,
      insurance_subscriber: insurance?.subscriber_id,
      insurance_group: insurance?.group_number,
      height: patient?.height
        ? `${Math.floor(patient?.height / 12)} ft ${patient?.height % 12} in`
        : 'N/A',
      weight: (patient?.weight_update || patient?.weight || 'N/A') + ' lbs',
      bmi: bmiValues?.bmi || 'N/A',
      social_history: socialHistory,
      diagnostic_review: selectedNote?.diagnostic_review?.no_review
        ? 'There are no studies to review at today’s visit'
        : selectedNote?.diagnostic_review?.text || 'N/A',
      goals_list,
      follow_up,
      auth_request: selectedNote?.followupData?.requested_auth || 'N/A',
      referrals_requested,
      time_spent,

      //PROVIDER INFORMATION
      provider_signature: doctor?.sign_image,
      provider_name: doctor?.first_name + ' ' + doctor?.last_name,
      provider_specialty: doctor?.speciality,
      medication_data: medication_data,
      allergies_data: allergies_data,
      conditions_data: conditions_data,
      family_history_data: family_history_data,
      careplan: selectedNote?.careplan,
      reviewed: selectedPatient.reviewed ? 'visible' : 'hidden',
      supervisor_signature: supervisor?.sign_image,
      supervisor_name: supervisor?.first_name + ' ' + supervisor?.last_name,
      supervising_date:
        selectedPatient?.reviewed_by?.date || moment(new Date()).format('ll'),
    }
    // console.log('TESTING: ', data)

    Axios(VisitNotesPdf)
      .then((res) => {
        let templateHtml = Handlebars.compile(res.data.toString())
        let bodyHtml = templateHtml({ ...data, ...noteData })
        this.setState({
          pdfData: bodyHtml,
          showModal: false,
          showNotesModal: true,
          // loading: false,
        })
      })
      .catch((err) => {
        this.setState({ loading: false })
        console.log('error with pdf generation', err)
        toast.error('Could not display notes, please try again')
      })
  }

  generatePdf(data) {
    let insurance = Array.isArray(data?.insurances)
      ? data?.insurances?.find((ins) => ins.preference === 1) || null
      : data?.insurances || null

    let icd_10_data = []
    let tempArray = data?.careplan?.careplan || []
    tempArray.forEach((icd, index) => {
      icd_10_data.push({
        index: index + 1,
        icd_10:
          // icd?.icd_10?.icd10_code?
          `${icd?.icd_10?.icd10_code || ''} : ${icd?.icd_10?.title}`,
        // : '' + icd?.icd_10?.title
        // ? `${icd?.icd_10?.title}`
        // : '',
      })
    })
    let cpt_data = []
    let tempArray1 = data?.careplan?.cpt || []
    tempArray1.forEach(function (cpt, index) {
      cpt_data.push({
        date: moment(data?.careplan?.created_at).format('MM/DD/YYYY'),
        code: cpt.cpt_code,
        dx: '',
        desc: cpt.title,
        fee: '',
        paid: '',
      })
    })
    let pdfValues = {
      practice_name: 'Aila Health',
      practice_address: '1999 Harrison St 18th Floor, #5135 Oakland, CA 94612',
      patient_name: `${data?.firstName} ${data?.lastName}`,
      patient_address: `${data?.streetAddress}`,
      patient_phone: data?.phoneNumber,
      patient_dob: moment(data?.patientData?.dob).format('MM/DD/YYYY'),
      statement_num: 1,
      issue_date: moment().format('MM/DD/YYYY'),
      insurance_plan: `${insurance?.insurance_company?.insurance_name || ''}`,
      member_number: `${insurance?.subscriber_id || ''}`,
      provider_name: `${data?.providerData?.first_name} ${data?.providerData?.last_name}`,
      credentials: `${data?.providerData?.npi?.number}`,
      provider_npi: `${data?.providerData?.npi?.number}`,
      license: ``,
      icd_10: icd_10_data || [],
      cpt_10: cpt_data || [],
    }
    Axios(htmlModule).then((res) => {
      let templateHtml = Handlebars.compile(res.data.toString())
      let bodyHtml = templateHtml(pdfValues)
      this.setState({
        pdfData: bodyHtml,
        showModal: true,
        showNotesModal: false,
      })
    })
  }

  download(event) {
    const currentRecords = this.state.downloadData
    var data_to_download = []
    for (var index = 0; index < currentRecords.length; index++) {
      data_to_download.push(currentRecords[index].values)
    }
    this.setState({ dataToDownload: data_to_download }, () => {
      // click the CSVLink component to trigger the CSV download
      // this.csvlink.current.click()
      this.csvLink.link.click()
    })
  }

  handleTableCallBack = (childData) => {
    this.setState(
      {
        page: childData.pageIndex + 1,
        size: childData.pageSize,
        downloadData: childData?.page,
      },
      () => {
        this.getInsuranceClaims()
      },
    )
  }

  getInsuranceClaims = async () => {
    const { token, mongoUser, firebaseUser } = this.props
    const url = `${Config.BACKEND_URL}quality-management`
    let queryParams = {
      page: this.state.page,
      size: this.state.size,
      upcoming:
        this.state.appointmentType?.label === 'Upcoming Appointments'
          ? true
          : false,
    }

    try {
      const response = await axios({
        method: 'get',
        url,
        headers: {
          Authorization: `JWT ${token}`,
          x_doctor_id: mongoUser.firebase_id,
          search_month:
            moment(
              moment(this.state.searchMonth).format('YYYY-MM-DD'),
            ).month() + 1,
          search_year: moment(
            moment(this.state.searchMonth).format('YYYY-MM-DD'),
          ).year(),
        },
        params: queryParams,
      })

      const events = response.data

      console.log('CLaims recieved: ', events)
      this.setState({ claims: events || [] })
      this.setState({ loading: false })
    } catch (err) {
      toast.error('Could not fetch payments, please try again')
      console.log('error when getting calendar events from backend', err)
    }
  }

  getAppointmentInvoice = async () => {
    const { token, mongoUser } = this.props
    const url = `${Config.BACKEND_URL}admin/appointments`

    try {
      this.setState({ loading: true })
      const response = await axios({
        method: 'get',
        url,
        headers: {
          Authorization: `JWT ${token}`,
          x_firebase_id: mongoUser.firebase_id,
          invoice: true,
        },
      })

      const invoiceData = response.data

      console.log('invoices recieved', invoiceData)
      this.setState({ invoices: invoiceData || [], loading: false })
    } catch (err) {
      toast.error('Could not fetch invoices, please try again')
      console.log('error when getting invoices from backend', err)
    }
  }

  onPatientNavLinkClicked = (lp) => {
    this.props.addPatientLP(lp)
    console.log('PATIENT LANDING PAGE: ', lp)
  }

  navigatetoPatientProfile = (p_id) => {
    const { patientList, patientTimer, updateRender, adminId, token } =
      this.props
    console.log('testing on click: ', p_id)
    if (!patientList) return

    Object.values(patientList).forEach((colorCodedPatients, index) => {
      if (colorCodedPatients.length > 0) {
        let result = colorCodedPatients.filter(
          (patient) => patient.uid === p_id,
        )
        // console.log('RESUKT ARRAY : ', result)
        result.forEach((patient) => {
          let colorcode = Object.keys(patientList)[index]
          if (patient.uid === p_id) {
            this.props.updatePatientData(
              {
                doctor_id: adminId,
                patient_id: patient.uid,
                last_reviewed: new Date(),
              },
              token,
            )

            //if the doctor was already on on another patient page and just clicked on a new patient, reset time
            if (patientTimer && patientTimer !== 0)
              this.props.resetTimer(patient.uid, adminId, token)
            else this.props.startTimer(patient.uid)
            let preferences = patient.preferences
            let timeline = 'complete'
            if (preferences && preferences.timeline) {
              timeline = preferences.timeline.value
            }
            this.props.fetchPatientProgressData(patient.uid, token, timeline)
            this.props.fetchPatientCoreDate(patient.uid, token)
            this.props.fetchPatientWellnessScreening(patient.uid, token)
            this.props.fetchPatientCalculateProgressData(patient.uid, token)
            this.props.fetchPatientConditions(patient.uid, token)
            this.props.fetchPatientMedications(patient.uid, token)
            this.props.fetchPatientLabs(patient.uid, token)
            this.props.addPatient(patient, colorcode)
            this.props.updateSideNavBar(!updateRender)
          }
        })
      }
    })
  }

  handleInsuranceCallback = (childData) => {
    console.log('callback::', childData)
    const self = this
    const { token } = this.props
    if (childData) {
      self.setState({ showInsuranceModal: false }, () => {
        self.props.toggleSidebar(false)
        this.getInsuranceClaims()
      })
    } else {
      self.setState({ insuranceModalIsOpen: false })
      self.props.toggleSidebar(false)
    }
  }

  renderInsuranceModal() {
    const { editInsuranceData, selectedPatientId } = this.state

    let primary_insurance = Array.isArray(editInsuranceData)
      ? editInsuranceData?.find((ins) => ins.preference === 1) || null
      : editInsuranceData || null

    let secondary_insurance = Array.isArray(editInsuranceData)
      ? editInsuranceData?.find((ins) => ins.preference === 2)
      : {}

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() => this.onInsuranceEditClicked(false)}
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Edit Patient Insurance
        </h4>
        <PatientInsurance
          parentCallback={this.handleInsuranceCallback.bind(this)}
          primaryInsurance={primary_insurance}
          secondaryInsurance={secondary_insurance}
          patientId={selectedPatientId}
          fromProvider={true}
        />
      </div>
    )
  }

  renderRefundModal() {
    const { refund_amount, refundData } = this.state

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() =>
            this.setState({
              refundData: {},
              stripeModalIsOpen: false,
              refund_amount: '',
            })
          }
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Refund Amount
        </h4>
        <div className={Styles.row} style={{ padding: 20 }}>
          <p className={Styles.rowLabel}>
            Refund from remaining ${refundData.amount_paid || '-'}
          </p>
          {/* <div className={Styles.studyEntryRow}>
          <p className={Styles.studyEntryKey}>User Name</p> */}
          <input
            placeholder={'Enter Refund Amount'}
            className={Styles.studyEntryInput}
            type="text"
            value={refund_amount}
            onChange={(e) => this.setState({ refund_amount: e.target.value })}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={() => {
              console.log(
                'CLICK ON REFUND: ',
                refundData.charge_id,
                refundData.transfer,
              )
              this.getStripeRefund(
                refundData.charge_id,
                refundData.transfer,
                refund_amount,
              )
            }}
            className={GlobalStyles.button}
            style={{ width: 300, margin: '4%' }}
            variant="primary"
          >
            Refund
          </Button>
        </div>
      </div>
    )
  }

  renderChargeModal() {
    const { charge_amount, appChargeData } = this.state

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() =>
            this.setState({
              stripeChargeModalIsOpen: false,
              charge_amount: '',
              appChargeData: {},
            })
          }
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Charge Amount
        </h4>
        <div className={Styles.row} style={{ padding: 20 }}>
          <p className={Styles.rowLabel}>
            Allowed Amount ${appChargeData?.allowed_amount || '-'}
          </p>
        </div>
        <div className={Styles.row} style={{ padding: 20 }}>
          <p className={Styles.rowLabel}>
            Patient Due With Professional Discount $
          </p>
          {/* <div className={Styles.studyEntryRow}>
          <p className={Styles.studyEntryKey}>User Name</p> */}
          <input
            placeholder={'Enter Amount'}
            className={Styles.studyEntryInput}
            type="text"
            value={charge_amount}
            onChange={(e) => this.setState({ charge_amount: e.target.value })}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={() => {
              this.getStripeCharge()
            }}
            className={GlobalStyles.button}
            style={{ width: 300, margin: '4%' }}
            variant="primary"
          >
            Charge
          </Button>
        </div>
      </div>
    )
  }

  handleInputChange = (cellInfo, editable_field, event) => {
    console.log('saved', cellInfo, editable_field, event)
    let data = [...this.state.claims]
    data[cellInfo.index][editable_field] = event.target.value
    this.setState({ claims: data })
    let toSave = {}
    toSave[editable_field] = event.target.value
    this.handleQualityReview(data[cellInfo.index], toSave)
  }

  renderEditable = (cellInfo, editable_field) => {
    console.log(cellInfo, editable_field)
    const cellValue = this.state.claims[cellInfo.index][editable_field]

    return (
      <input
        key={cellInfo.index}
        placeholder="Enter"
        name="input"
        type="text"
        onChange={this.handleInputChange.bind(null, cellInfo, editable_field)}
        value={cellValue}
      />
    )
  }

  render() {
    if (this.state.loading) {
      return (
        <div
          className={GlobalStyles.container}
          style={{ overflow: 'hidden', maxHeight: '100vh' }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              height: '80vh',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        </div>
      )
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <h4
          style={{
            color: Constants.primaryTheme,
            width: '100%',
            marginBottom: 10,
            textAlign: 'center',
          }}
        >
          Quality Management
        </h4>

        <div style={{ alignSelf: 'center' }}>
          Search Date
          <DatePicker
            dateFormat="MMMM yyyy"
            showMonthYearPicker
            selected={this.state.searchMonth}
            onChange={(date) => {
              console.log(date)
              this.setState({ searchMonth: date }, () => {
                this.getInsuranceClaims()
              })
            }}
          />
          {/* <DatePicker
            defaultDate={moment().format()}
            dateChange={(date) => {
              console.log(date)
              this.setState({ searchMonth: date })
            }}
          /> */}
        </div>

        <NotesModal
          modalIsOpen={this.state.modalAddNotesIsOpen}
          hideNotesModal={this.hideNotesModal}
          getUserCarePlan={() => this.getInsuranceClaims()}
          carePlanNotesData={this.state.selectedNotesFromVisit}
          comingFrom={'billing'}
          patientId={this.state.selectedNotesFromVisit?.patient_id}
          selectedPatient={this.state.selectedPatient}
        />

        <Modal
          ariaHideApp={false}
          isOpen={this.state.insuranceModalIsOpen}
          onRequestClose={() => this.onInsuranceEditClicked(false)}
          style={insuranceAddModalStyles}
          contentLabel="Modal"
        >
          {this.renderInsuranceModal()}
        </Modal>

        <BSModal
          // ariaHideApp={false}
          show={this.state.stripeModalIsOpen}
          onHide={() =>
            this.setState({
              refundData: {},
              stripeModalIsOpen: false,
              refund_amount: '',
              charge_amount: '',
              appChargeData: {},
            })
          }
          // style={stripeModalStyles}
          size="lg"
          contentLabel="Slot Modal"
          centered
        >
          {this.renderRefundModal()}
        </BSModal>

        <BSModal
          // ariaHideApp={false}
          show={this.state.stripeChargeModalIsOpen}
          onHide={() =>
            this.setState({
              refundData: {},
              stripeChargeModalIsOpen: false,
              refund_amount: '',
              charge_amount: '',
              appChargeData: {},
            })
          }
          // style={stripeModalStyles}
          size="lg"
          contentLabel="Modal"
          centered
        >
          {this.renderChargeModal()}
        </BSModal>

        {(this.state.showModal || this.state.showNotesModal) && (
          <Modal
            ariaHideApp={false}
            isOpen={this.state.showModal || this.state.showNotesModal}
            onRequestClose={() =>
              this.setState({ showModal: false, showNotesModal: false })
            }
            style={customChangeModalStyles}
            contentLabel="Modal"
          >
            <h3>
              {this.state.showModal
                ? `Superbill Template`
                : `Appointment Notes`}
            </h3>
            <Button
              onClick={() => {
                let postData = {
                  htmlContent: this.state.pdfData,
                }
                let url = Config.CHANGE_BACKEND_URL + `pdf/generate`
                //API REQUEST TO GET PDF BASE64 RIGHT FORMAT FROM BACKEND - PUPPETEER
                axios({
                  method: 'post',
                  headers: {
                    Authorization: 'JWT ' + this.props?.token,
                    x_firebase_id: this.props?.adminId,
                  },
                  url: url,
                  data: postData,
                })
                  .then(({ data }) => {
                    // Insert a link that allows the user to download the PDF file
                    var link = document.createElement('a')
                    link.innerHTML = 'Download PDF file'
                    link.download = this.state.showModal
                      ? `Superbill.pdf`
                      : `PatientNote.pdf`
                    link.href =
                      'data:application/octet-stream;base64,' + data.result
                    // document.body.appendChild(link);
                    link.click()
                    console.log('downloaded')
                    toast.success('Document downloaded')
                  })
                  .catch((err) => {
                    console.log(
                      'error when getting saving provider goals data',
                      err,
                    )
                    toast.error('Could not download Notes, please try again')
                  })
              }}
              className={GlobalStyles.button}
              style={{
                width: 100,
                position: 'absolute',
                right: 120,
                top: 10,
              }}
              variant="primary"
            >
              Download
            </Button>
            <iframe
              srcDoc={this.state.pdfData}
              title="Superbill Template"
              style={{ height: '100%', width: '100%' }}
            />
          </Modal>
        )}

        <div
          className={GlobalStyles.contentWrapper}
          style={{
            flex: 1,
            visibility:
              this.state.insuranceModalIsOpen ||
              this.state.showModal ||
              this.state.showNotesModal ||
              this.state.modalAddNotesIsOpen
                ? 'hidden'
                : 'visible',
          }}
        >
          <Table
            columns={qualityManagementColumns({
              providersList: this.state.providersList,
              patientList: this.state.patientList,
              generatePdf: this.generatePdf,
              generateNotesPdf: this.generateNotesPdf,
              navigatetoPatientProfile: this.navigatetoPatientProfile,
              onPatientNavLinkClicked: this.onPatientNavLinkClicked,
              handleQualityReview: this.handleQualityReview,
              supervisor: this.props?.mongoUser?.supervisor,
              openNotesEditModal: this.openNotesEditModal,
              onInsuranceEditClicked: this.onInsuranceEditClicked,
              renderEditable: this.renderEditable,
              handleInputChange: this.handleInputChange,
              getStripeChargeUrl: this.getStripeChargeUrl,
              getStripeRefund: this.getStripeRefund,
              openStripeRefundModal: this.openStripeRefundModal,
              openStripeChargeModal: this.openStripeChargeModal,
            })}
            data={this.state.claims}
            pageCnt={1000}
            pageIdx={this.state.page}
            parentCallback={this.handleTableCallBack}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedIn: state.userReducer.loggedIn,
    token: state.authReducer.token,
    docFirebaseAuthUser: state.userReducer.firebaseAuthUser,
    mongoUser: state.userReducer.mongoUser,
    adminId: state.userReducer.adminId,
    patientList: state.patientReducer.patientList,
    doctorPatientData: state.appointmentReducer.patientData,
    appointmentData: state.appointmentReducer.appointmentData,
    patient: state.patientReducer.patient,
    appointmentNotesData: state.appointmentReducer.appointmentNotesData,
    openNotesModal: state.genericReducer.openNotesModal,
  }
}

const mapDispatchToProps = {
  stopTimer,
  resetTimer,
  startTimer,
  setAppointmentNotesData,
  fetchPatientCarePlanData,
  addPatientList,
  toggleSidebar,
  fetchPatientCoreDate,
  fetchPatientProgressData,
  fetchPatientWellnessScreening,
  fetchPatientCalculateProgressData,
  fetchPatientConditions,
  fetchPatientMedications,
  fetchPatientLabs,
  updatePatientData,
  updateSideNavBar,
  addPatientLP,
  addPatient,
}

export default connect(mapStateToProps, mapDispatchToProps)(QualityManagement)
