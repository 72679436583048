import React, { Component, useState } from 'react'
import socketIOClient from 'socket.io-client'
import 'react-chat-elements/dist/main.css'
import Linkify from 'react-linkify'
import { ChatItem } from 'react-chat-elements'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { EThree } from '@virgilsecurity/e3kit-browser'

import {
  faFileMedical,
  faUserLock,
  faCalendarPlus,
  faPaperclip,
  faVideo,
  faMicrophone,
  faMicrophoneSlash,
  faVideoSlash,
} from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import Modal from 'react-modal'
import DateTimePicker from 'react-datetime-picker'
import Fuse from 'fuse.js'
import Switch from 'react-switch'
import { Link } from 'react-router-dom'
import update from 'react-addons-update'
import { connect } from 'react-redux'
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify'
import Constants from '../../../values'
import Header from '../../../components/Header'
import GlobalStyles from '../../styles/global.module.scss'
import Styles from './styles/PatientMessages.module.scss'
import Config from '../../../config'
// import Video from '../Appointments/Video'
import {
  stopTimer,
  updateSideNavBar,
  addRpmPatient,
  addPatientLP,
  getUnreadMessages,
  resetTimer,
  startTimer,
  addVirgilE3,
  updateDoctorMongoUser,
  addMongoUser,
  addPatient,
  userLoggedIn,
  fetchPatientCoreDate,
  fetchPatientProgressData,
  fetchPatientWellnessScreening,
  fetchPatientCalculateProgressData,
  updatePatientData,
  addPatientList,
  setPatientCoreData,
  fetchPatientConditions,
  fetchPatientMedications,
  fetchPatientLabs,
} from '../../../redux/actions'
// import EducationMessages from './EducationMessages.js'
// import QuestionnaireMessages from './QuestionnaireMessages.js'
// import PatientExperienceMessages from './PatientExperienceMessages'

const momentDurationFormatSetup = require('moment-duration-format')
const axios = require('axios')
momentDurationFormatSetup(moment)

// const ENDPOINT = 'https://chat.ailahealth.net'
// const ENDPOINT = 'http://localhost:8080/'
const PLACEHOLDER_IMAGE =
  'https://firebasestorage.googleapis.com/v0/b/aila-health-b469c.appspot.com/o/images%2Fplaceholder.png?alt=media&token=89bc348e-7ccd-4e35-a291-50c6e21a3b6a'

const windowHeight = window.innerHeight
const SCHEDULE_MODAL_STYLES = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '50%',
    height: windowHeight / 1.2,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
  },
}

const MODAL_STYLES = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '60%',
    height: '90%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
  },
}

const AILA_IMAGE_LOCATION =
  'https://firebasestorage.googleapis.com/v0/b/aila-health-b469c.appspot.com/o/images%2Faila_logo.png?alt=media&token=f0018d3c-4875-4d49-babf-a187c92cd7ec'

class PatientMessages extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchText: '',
      messageText: '',
      doctorActiveIndex: 0,
      doctorActiveIndex: 0,
      previousKeyDown: '',
      messageData: [],
      loading: true,
      ailaMessages: null,
      ailaMessageSelected: false,
      videoModalIsOpen: false,
      toggleModalChecked: 'Content Library',

      modalECLoading: false,
      modalLoading: false,
      patientList: [],
      modalPatients: null,
      modalMessage: '',
      modalError: false,
      modalSchedule: new Date(),
      modalErrorString: '',
      updatedUnread: false,
      searchResults: null,
      showSearchResults: false,
      scheduleModalChecked: false,
      modalCohortOptions: [],
      modalCohortSelection: null,
      twilioToken: null,
      videoPatientId: null,
      roomName: null,
      videoCallJoined: false,
      videoCallRoom: null,
      audioMuted: false,
      videoMuted: false,
      listEducation: [],
      listQuestion: [],
      listPatientExperience: [],
      listTitles: [],
      initializingVirgil: false,
    }
    this.socket = null
    this.fuse = null
    this.patientListFull = []
    this.fileUploaderRef = React.createRef()
    this.dummyRef = React.createRef()
    this.scrollToBottom = this.scrollToBottom.bind(this)
    this.getMessagesFromBackend = this.getMessagesFromBackend.bind(this)
    this.updateUnread = this.updateUnread.bind(this)
    // this.prepareDoctorList = this.prepareDoctorList.bind(this)
    this.scheduleMessageInBackend = this.scheduleMessageInBackend.bind(this)
    this.prepareSocket = this.prepareSocket.bind(this)
    this.decryptMessageToDisplay = this.decryptMessageToDisplay.bind(this)
    this.decryptMessages = this.decryptMessages.bind(this)
    this.fetchAilaMessages = this.fetchAilaMessages.bind(this)
    this.fetchTwilioToken = this.fetchTwilioToken.bind(this)

    // this.initializeVirgil = this.initializeVirgil.bind(this)
    // this.getVirgilTokenFromBackend = this.getVirgilTokenFromBackend.bind(this)
    // this.restorePrivateKey = this.restorePrivateKey.bind(this)

    // this.checkAndRegisterVirgil = this.checkAndRegisterVirgil.bind(this)
  }

  componentDidMount() {
    const { patient, token, e3, adminId } = this.props

    if (!this.props.loggedIn) {
      this.props.history.push('/login')
      return
    }

    //PATIENT MESSAGES
    this.props.getUnreadMessages(adminId, token, patient)
    this.fetchTwilioToken()
    this.getMessagesFromBackend()
    this.prepareSocket()

    if (this.props.mongoUser && this.props.mongoUser.cohorts) {
      const cohorts = []
      this.props.mongoUser.cohorts.forEach((each) => {
        cohorts.push({ label: each, value: each })
      })
      this.setState({ modalCohortOptions: cohorts })
    }
  }

  prepareSocket() {
    const { mongoUser, patient, e3 } = this.props

    console.log(patient)

    this.socket = socketIOClient(Config.CHAT_ENDPOINT)
    this.socket.on('connect', () => {
      console.log('connected to chat server')
      this.socket.emit('login', { firebase_id: patient.uid })
    })

    this.socket.on('logged_in_clients', (data) => {
      console.log('logged in clients', data)
    })

    this.socket.on('connecting', () => {
      console.log('connecting to chat server....')
    })

    this.socket.on('connect_failed', () => {
      console.log('connection to chat server failed :(')
    })

    const eventNameToListen = `${patient.uid}Message`
    this.socket.on(eventNameToListen, async (data) => {
      console.log('EVENT LISTEN:::', data)

      try {
        const { e3 } = this.props

        const senderCard = await e3.findUsers(data.sender_id)

        const { messageData } = this.state

        let decryptedText
        // if the doctor is offline and the patient send a text message
        if (data?.status === 'wait') {
          decryptedText = data?.message
          // this.setState({disableInputText: true});
        } else {
          if (data.message && data.message.length) {
            decryptedText = await e3.authDecrypt(data.message, senderCard)
          } else {
            decryptedText = data?.automated_message || ''
          }
        }
        const newMessage =
          data.education ||
          data.education_ids ||
          data.question ||
          data.patientExperience
            ? {
                created_at: data.created_at,
                message: decryptedText,
                education: data.education,
                education_ids: data.education_ids,
                question: data.question,
                patientExperience: data.patientExperience,
                sender: 'Doctor',
              }
            : {
                created_at: data.created_at,
                message: decryptedText,
                sender: 'Doctor',
              }

        messageData.every((each) => {
          if (each.doctor_id === data.sender_id) {
            const { messages } = each
            messages.push(newMessage)
            each.unread += 1
            return false
          }
          return true
        })
        this.setState({ messageData, updatedUnread: false }, () => {
          console.log('message data after update::', messageData)
        })

        this.scrollToBottom()
      } catch (err) {
        console.log('error when decoding received text', err)
      }
    })
  }

  fetchTwilioToken() {
    const { token, mongoUser, patient } = this.props
    const url = `${Config.BACKEND_URL}twilio/token`
    axios({
      method: 'get',
      url,
      headers: { Authorization: 'JWT ' + token, x_firebase_id: patient.uid },
    })
      .then((result) => {
        const { token } = result.data
        console.log('got twilio token')
        this.setState({ twilioToken: token })
      })
      .catch((err) => {
        console.log('error when getting twilio token', err)
      })
  }

  componentWillUnmount() {
    if (this.socket) this.socket.disconnect()
  }

  updateUnread() {
    const { doctorActiveIndex, messageData } = this.state
    const { mongoUser, token, patient } = this.props
    const self = this
    const url = `${Config.BACKEND_URL}patient/messages/unread`
    axios({
      method: 'put',
      url,
      headers: {
        Authorization: `JWT ${token}`,
        x_doctor_id: messageData[doctorActiveIndex].doctor_id,
        x_patient_id: patient.uid,
      },
    })
      .then(() => {
        messageData[doctorActiveIndex].unread = 0
        self.setState({ messageData, updatedUnread: true })
      })
      .catch((err) => {
        console.log('error when updating unread messages', err)
      })
  }

  fetchAilaMessages() {
    const { mongoUser, token, patient } = this.props
    const self = this
    const url = `${Config.BACKEND_URL}aila-messages`
    axios({
      method: 'get',
      url,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: patient.uid,
      },
    })
      .then(({ data }) => {
        self.setState({ ailaMessages: data })
      })
      .catch((err) => {
        console.log('error when getting messages from backend', err)
      })
  }

  getMessagesFromBackend() {
    const { mongoUser, token, patient } = this.props
    const self = this
    const url = `${Config.BACKEND_URL}patient/messages`
    axios({
      method: 'get',
      url,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: patient.uid,
      },
    })
      .then(({ data }) => {
        // self.setState({messageData: data || [],loading: false})
        // self.scrollToBottom()
        console.log('GET UNREAD:::', data)
        self.decryptMessages(data)
      })
      .catch((err) => {
        console.log('error when getting messages from backend', err)
      })
  }

  async decryptMessages(messageData) {
    const { mongoUser, e3, patientCore, patient } = this.props
    if (!messageData || messageData.length === 0 || !e3) {
      this.setState({ messageData: [], loading: false })
      return
    }

    let activeIndex = this.state.doctorActiveIndex
    for (let i = 0; i < messageData.length; i++) {
      const doctorMessage = messageData[i]
      doctorMessage.index = i
      try {
        const patientCard = await e3.findUsers(patient.uid)
        // const doctorCard = await e3.findUsers(mongoUser.firebase_id)
        const sender = doctorMessage.doctor_id
        // if coming from the patient profile pages by clicking on the top bar

        // if (
        //   patientCore &&
        //   patientCore.firebase_id === doctorMessage.doctor_id
        // ) {
        //   activeIndex = i
        // }

        const senderCard = await e3.findUsers(sender)

        if (doctorMessage.messages && doctorMessage.messages.length > 0) {
          // if there are messages, decrypt it
          const newMessages = []
          for (let j = 0; j < doctorMessage.messages.length; j++) {
            const eachMessage = doctorMessage.messages[j]
            if (eachMessage.sender === 'Doctor') {
              // decrypting doctors own message

              try {
                let decryptedText
                if (eachMessage.message && eachMessage.message.length) {
                  decryptedText = await e3.authDecrypt(
                    eachMessage.message,
                    senderCard,
                  )
                } else {
                  decryptedText = eachMessage?.automated_message || ''
                }
                {
                  eachMessage.education ||
                  eachMessage.education_ids ||
                  eachMessage.question ||
                  eachMessage.patientExperience
                    ? newMessages.push({
                        created_at: eachMessage.created_at,
                        message: decryptedText,
                        education: eachMessage.education,
                        education_ids: eachMessage.education_ids,
                        question: eachMessage.question,
                        patientExperience: eachMessage.patientExperience,
                        sender: eachMessage.sender,
                      })
                    : newMessages.push({
                        created_at: eachMessage.created_at,
                        message: decryptedText,
                        sender: eachMessage.sender,
                      })
                }
              } catch (err) {
                console.log('error when decrypting doctors message', err)
                newMessages.push(eachMessage)
              }
            } else {
              try {
                const decryptedText = await e3.authDecrypt(
                  eachMessage.message,
                  patientCard,
                )
                newMessages.push({
                  created_at: eachMessage.created_at,
                  message: decryptedText,
                  sender: eachMessage.sender,
                })
              } catch (err) {
                console.log('error when decrypting patients message', err)
                newMessages.push(eachMessage)
              }
            }
          }

          doctorMessage.messages = newMessages
        } else {
          this.setState({
            messageData,
            loading: false,
            doctorActiveIndex: activeIndex,
          })
        }
      } catch (err) {
        console.log('error when trying to decyprt', err)
        console.log('identities', err.identities)
        this.setState({
          messageData,
          loading: false,
          doctorActiveIndex: activeIndex,
        })
      }
    }

    if (messageData && messageData.length > 0)
      this.prepareSearchIndex(messageData)
    this.setState({
      messageData,
      loading: false,
      doctorActiveIndex: activeIndex,
    })
    this.props.getUnreadMessages(
      this.props.adminId,
      this.props.token,
      this.props.patient,
    )
  }

  prepareSearchIndex(messageData) {
    const options = {
      includeScore: true,
      keys: ['firstName', 'lastName'],
    }

    this.fuse = new Fuse(messageData, options)
  }

  onSearchTextChanged(searchText) {
    this.setState({ searchText })
    if (!this.fuse) return

    if (searchText.length > 0) {
      const results = this.fuse.search(searchText)
      const searchResults = []
      results.forEach((result) => {
        searchResults.push(result.item)
      })

      this.setState({
        showSearchResults: true,
        searchResults,
        doctorActiveIndex: -1,
      })
    } else {
      this.setState({
        showSearchResults: false,
        doctorActiveIndex: 0,
        ailaMessageSelected: false,
      })
    }
  }

  onDoctorEntryClicked(index) {
    const { mongoUser, token, adminId, patient } = this.props
    const { messageData } = this.state
    this.setState({ doctorActiveIndex: index, ailaMessageSelected: false })
    this.props.getUnreadMessages(adminId, token, patient)
  }

  scrollToBottom() {
    if (this.dummyRef.current)
      this.dummyRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  async onSubmitPressed() {
    if (this.state.ailaMessageSelected) {
      toast.warning('Cannot send messages')
      toast.warning('Please submit a support ticket')
      return
    }
    let tempQuestions = []
    let tempPatientExperience = []
    this.state.listQuestion.forEach((ques) => {
      if (
        ques === 'Patient Experience (pre)' ||
        ques === 'Patient Experience (post)'
      ) {
        tempPatientExperience.push(ques)
      } else {
        tempQuestions.push(ques)
      }
    })
    const message = this.state.messageText
    const education = this.state.listTitles
    const education_ids = this.state.listEducation
    const question = tempQuestions
    const patientExperience = tempPatientExperience

    // if (!message && message.length === 0) return

    const { doctorActiveIndex, messageData } = this.state
    const doctor = messageData[doctorActiveIndex]

    console.log(messageData, doctorActiveIndex)

    try {
      let val = {}
      const { e3 } = this.props
      const identities = [doctor.doctor_id]

      // Find users cards with public keys
      const findUsersResult = await e3.findUsers(identities)

      // Encrypt text string
      const encryptedText = await e3.authEncrypt(message, findUsersResult)

      const { mongoUser, patient } = this.props

      val = {
        sender_id: patient.uid,
        message: encryptedText,
        receiver_id: doctor.doctor_id,
        provider_type: doctor.user_type,
      }
      const newMessageToSend = val

      const current = messageData[doctorActiveIndex].messages
      current.push({
        message,
        sender: 'Patient',
        created_at: new Date(),
      })
      this.socket.emit('PatientMessage', newMessageToSend)
      this.setState({
        messageData,
        messageText: '',
        listEducation: [],
        listTitles: [],
        listQuestion: [],
        listPatientExperience: [],
      })
    } catch (err) {
      const error = err.toString()
      console.log('error when encrypting text with virgil', err)
      if (error.includes('UsersNotFoundError')) {
        toast.error('Cannot send encrypted message to this user')
      } else {
        toast.error('Cannot send message right now')
      }
      this.setState({
        messageData,
        messageText: '',
        listEducation: [],
        listTitles: [],
        listQuestion: [],
        listPatientExperience: [],
      })
    }
  }
  renderDoctorName() {
    const toReturn = []
    const { messageData } = this.state
    if (!messageData || messageData.length === 0) {
      return null
    }

    if (this.state.showSearchResults) {
      // show search results
      const results = this.state.searchResults
      results.forEach((patient) => {
        console.log(patient)
        // let subtitle =
        //   patient.messages && patient.messages.length !== 0
        //     ? patient.messages[patient.messages.length - 1].message
        //     : ''
        // if (subtitle && subtitle.length > 15)
        //   subtitle = `${subtitle.substring(0, 15)}......`
        const date =
          patient.messages && patient.messages.length !== 0
            ? patient.messages[patient.messages.length - 1].created_at
            : ''
        toReturn.push(
          <ChatItem
            key={patient.index}
            onClick={() => this.onDoctorEntryClicked(patient.index)}
            className={
              patient.index === this.state.doctorActiveIndex
                ? Styles.userChatEntryActive
                : Styles.userChatEntry
            }
            avatar={patient.image_url || PLACEHOLDER_IMAGE}
            alt="No Image"
            title={`${patient.first_name} ${patient.last_name}`}
            // subtitle={subtitle}
            date={new Date(date)}
            unread={patient.unread}
          />,
        )
      })
    } else {
      messageData.forEach((patient, index) => {
        // console.log(patient)
        // let subtitle =
        //   patient.messages && patient.messages.length !== 0
        //     ? patient.messages[patient.messages.length - 1].message
        //     : ''
        // if (subtitle && subtitle.length > 15)
        //   subtitle = `${subtitle.substring(0, 15)}......`
        const date =
          patient.messages && patient.messages.length !== 0
            ? patient.messages[patient.messages.length - 1].created_at
            : ''
        toReturn.push(
          <ChatItem
            key={index.toString()}
            onClick={() => this.onDoctorEntryClicked(index)}
            className={
              index === this.state.doctorActiveIndex
                ? Styles.userChatEntryActive
                : Styles.userChatEntry
            }
            avatar={patient.image_url || PLACEHOLDER_IMAGE}
            alt="No Image"
            title={`${patient.first_name} ${patient.last_name}`}
            // subtitle={subtitle}
            date={new Date(date)}
            unread={patient.unread}
          />,
        )
      })
    }

    return toReturn
  }

  onModalSubmitPressed() {
    const {
      modalPatients,
      modalMessage,
      modalSchedule,
      modalCohortSelection,
      scheduleModalChecked,
    } = this.state
    if (!scheduleModalChecked && !modalPatients) {
      // trying to send to patients but no patient selected
      this.setState({
        modalError: true,
        modalErrorString: 'Please select at least one patient',
      })
      return
    }

    if (scheduleModalChecked && !modalCohortSelection) {
      this.setState({
        modalError: true,
        modalErrorString: 'Please select at least one cohort',
      })
      return
    }

    if (!modalMessage || modalMessage.length === 0) {
      this.setState({
        modalError: true,
        modalErrorString: 'Please enter a message',
      })
      return
    }

    if (!modalSchedule) {
      this.setState({
        modalError: true,
        modalErrorString: 'Please select a time and date to schedule',
      })
      return
    }

    if (moment(modalSchedule).isBefore(moment())) {
      this.setState({
        modalError: true,
        modalErrorString: 'Please select a time and date in the future',
      })
      return
    }
    this.scheduleMessageInBackend()
  }
  async scheduleMessageInBackend() {
    this.setState({ modalLoading: true })
    const {
      modalPatients,
      modalMessage,
      listEducation,
      listTitles,
      listQuestion,
      listPatientExperience,
      modalSchedule,
      scheduleModalChecked,
      modalCohortSelection,
    } = this.state
    const { e3 } = this.props
    const { mongoUser, token } = this.props
    const self = this
    const url = `${Config.BACKEND_URL}provider/messages/schedule`
    const ids = []
    const includedIdsMap = {}
    const message = modalMessage
    const education = listTitles
    const education_ids = listEducation
    const question = listQuestion
    const patientExperience = listPatientExperience
    if (scheduleModalChecked) {
      this.patientListFull.forEach((patient) => {
        const patientCohorts = patient.cohorts || []
        modalCohortSelection.forEach((each) => {
          // if the patient cohorts array contains any one of the selected
          if (
            patientCohorts.includes(each.label) &&
            !includedIdsMap[patient.uid]
          ) {
            ids.push(patient.uid)
            includedIdsMap[patient.uid] = true
          }
        })
      })
    } else {
      modalPatients.forEach((each) => {
        ids.push(each.value)
      })
    }

    if (scheduleModalChecked && ids.length === 0) {
      // no patients in the selected cohorts
      toast.error('No patients present in the selected cohorts')
      this.setState({ modalLoading: false })
      return
    }

    try {
      let val = {}

      // Find users cards with public keys
      const findUsersResult = await e3.findUsers(ids)
      // Encrypt text string
      const encryptedText = await e3.authEncrypt(message, findUsersResult)
      val = {
        patient_ids: ids,
        doctor_id: mongoUser.firebase_id,
        message: encryptedText,
        education: education,
        education_ids: education_ids,
        question: question,
        patientExperience: patientExperience,
        schedule: moment.utc(modalSchedule),
        offset: moment(modalSchedule).utcOffset(),
      }
      const toSend = val

      axios({
        method: 'post',
        url,
        headers: { Authorization: `JWT ${token}` },
        data: toSend,
      })
        .then(() => {
          console.log('successfully scheduled message with backend')
          const successString = `Successfully scheduled messages to ${ids.length} user(s)`
          toast.success(successString)
          self.setState({
            modalLoading: false,
            modalMessage: '',
            listEducation: [],
            listTitles: [],
            listQuestion: [],
            listPatientExperience: [],
            modalPatients: null,
            modalSchedule: new Date(),
            modalCohortSelection: null,
          })
        })
        .catch((err) => {
          console.log('error when updating scheduling messages', err)
        })
    } catch (err) {
      console.log('error when scheduling messages(with virgil)', err)
      const error = err.toString()
      if (error.includes('UsersNotFoundError')) {
        toast.error('Cannot send encrypted message to one or more users')
        toast.error('Please contact support')
      } else {
        toast.error('Cannot schedule message right now')
      }
      this.setState({
        modalLoading: false,
        modalMessage: '',
        listEducation: [],
        listTitles: [],
        listQuestion: [],
        listPatientExperience: [],
        modalPatients: null,
        modalSchedule: new Date(),
        modalCohortSelection: null,
      })
    }
  }

  navigatetoPatientProfile(p_id) {
    let { mongoUser, token, adminId } = this.props
    let { patientList } = this.props
    if (!patientList) return

    Object.values(patientList).forEach((colorCodedPatients, index) => {
      if (colorCodedPatients.length > 0) {
        let result = colorCodedPatients.filter(
          (patient) => patient.uid === p_id,
        )
        console.log('RESUKT ARRAY : ', result)
        result.forEach((patient) => {
          let colorcode = Object.keys(patientList)[index]
          if (patient.uid === p_id) {
            this.props.updatePatientData(
              {
                doctor_id: adminId,
                patient_id: patient.uid,
                last_reviewed: new Date(),
              },
              token,
            )

            //if the doctor was already on on another patient page and just clicked on a new patient, reset time
            if (this.props.patientTimer && this.props.patientTimer !== 0)
              this.props.resetTimer(patient.uid, adminId, token)
            else this.props.startTimer(patient.uid)
            let preferences = patient.preferences
            let timeline = 'complete'
            if (preferences && preferences.timeline) {
              timeline = preferences.timeline.value
            }
            this.props.fetchPatientProgressData(
              patient.uid,
              this.props.token,
              timeline,
            )
            this.props.fetchPatientCoreDate(patient.uid, this.props.token)
            this.props.fetchPatientWellnessScreening(
              patient.uid,
              this.props.token,
            )
            this.props.fetchPatientCalculateProgressData(
              patient.uid,
              this.props.token,
            )
            this.props.fetchPatientConditions(patient.uid, this.props.token)
            this.props.fetchPatientMedications(patient.uid, this.props.token)
            this.props.fetchPatientLabs(patient.uid, this.props.token)
            this.props.addPatient(patient, colorcode)
            this.props.updateSideNavBar(!this.props.updateRender)
          }
        })
      }
    })
  }

  onPatientNavLinkClicked(lp) {
    this.props.addPatientLP(lp)
    console.log('PATIENT LANDING PAGE: ', lp)
  }

  renderDoctors() {
    const { ailaMessages } = this.state
    let subTitle = ''
    let date = null
    let unread = 0
    if (
      ailaMessages &&
      ailaMessages.messages &&
      ailaMessages.messages.length > 0
    ) {
      const len = ailaMessages.messages.length
      subTitle = ailaMessages.messages[len - 1].message
        ? `${ailaMessages.messages[len - 1].message.substring(0, 20)}.....`
        : ''
      date = ailaMessages.messages[len - 1].created_at
      unread = ailaMessages.unread_messages
    }

    return (
      <div style={{ width: '100%', textAlign: 'center' }}>
        <h4
          style={{
            marginTop: 10,
            marginBottom: 14,
            color: Constants.primaryThemeDark,
          }}
        >
          Care Team
        </h4>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: '0px 10px',
            justifyContent: 'center',
          }}
        >
          <input
            placeholder="search....."
            className={Styles.textInput}
            type="textInput"
            value={this.state.searchText}
            onChange={(e) => this.onSearchTextChanged(e.target.value)}
          />
        </div>

        <div style={{ height: '100%' }}>{this.renderDoctorName()}</div>
      </div>
    )
  }

  renderPatients() {
    const { ailaMessages } = this.state
    let subTitle = ''
    let date = null
    let unread = 0
    if (
      ailaMessages &&
      ailaMessages.messages &&
      ailaMessages.messages.length > 0
    ) {
      const len = ailaMessages.messages.length
      subTitle = ailaMessages.messages[len - 1].message
        ? `${ailaMessages.messages[len - 1].message.substring(0, 20)}.....`
        : ''
      date = ailaMessages.messages[len - 1].created_at
      unread = ailaMessages.unread_messages
    }

    return (
      <div style={{ width: '100%', textAlign: 'center' }}>
        <h4
          style={{
            marginTop: 10,
            marginBottom: 14,
            color: Constants.primaryThemeDark,
          }}
        >
          Patients
        </h4>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: '0px 10px',
            justifyContent: 'center',
          }}
        >
          <input
            placeholder="search....."
            className={Styles.textInput}
            type="textInput"
            value={this.state.searchText}
            onChange={(e) => this.onSearchTextChanged(e.target.value)}
          />
        </div>

        <div style={{ height: '100%' }}>{this.renderDoctorName()}</div>
      </div>
    )
  }

  getAilaMessages(messages) {
    const toReturn = []
    let i = 0
    messages.forEach((message) => {
      const duration = moment.duration(
        moment().diff(moment(message.created_at)),
      )
      const durationString = `sent ${duration.format(
        'd [days] h [hrs] m [min]',
      )} ago`

      toReturn.push(
        <div
          key={i.toString()}
          style={{ padding: '8px 8px', marginBottom: 6, width: '100%' }}
        >
          <div
            className={
              message.sender === 'aila'
                ? Styles.messageWrapperLeft
                : Styles.messageWrapperRight
            }
          >
            {message.sender === 'aila' && (
              <div
                style={{
                  marginRight: 10,
                  height: 40,
                  width: 40,
                  borderRadius: '50%',
                  backgroundColor: Constants.primaryThemeFadedDeep,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <p style={{ fontWeight: 'bold', color: 'white' }}>A</p>
              </div>
            )}
            <div
              className={
                message.sender === 'aila'
                  ? Styles.messageLeft
                  : Styles.messageRight
              }
            >
              <p style={{ whiteSpace: 'pre-wrap' }}>
                <Linkify>{message.message}</Linkify>
              </p>
              {message.patient_id && (
                <p>
                  This
                  <Link
                    style={{ marginLeft: 4, marginRight: 4 }}
                    onClick={() => {
                      this.props.updateSideNavBar(!this.props.updateRender)
                      this.props.addRpmPatient(message.patient_id)
                      this.props.addPatientLP('progress')
                    }}
                    to="/patient/list"
                  >
                    link
                  </Link>
                  will bring you to the patient's progress page to view the
                  data.
                </p>
              )}
              <p style={{ fontSize: 14, textAlign: 'right', color: 'gray' }}>
                {durationString}
              </p>
            </div>
          </div>
        </div>,
      )
      i++
    })
    return toReturn
  }

  async decryptMessageToDisplay(encryptedMessage, senderIsDoctor) {
    return new Promise((resolve) => {
      const { e3 } = this.props
      const { doctorActiveIndex, messageData } = this.state
      const patient = messageData[doctorActiveIndex]
      // steve - sender identity
      const sender = patient.doctor_id

      if (senderIsDoctor) {
        // trying to decrypt your own message
        e3.authDecrypt(encryptedMessage)
          .then((decryptedText) => {
            resolve(decryptedText)
          })
          .catch((err) => {
            console.log('error when decrypting text', err.status)
            resolve(encryptedMessage)
          })
      } else {
        // Get sender card with public key
        e3.findUsers(sender)
          .then((senderCard) => {
            e3.authDecrypt(encryptedMessage, senderCard)
              .then((decryptedText) => {
                resolve(decryptedText)
              })
              .catch((err) => {
                console.log('error when decrypting text', err.status)
                resolve(encryptedMessage)
              })
          })
          .catch((err) => {
            console.log('no user identity found on virgil', err)
            resolve(encryptedMessage)
          })
      }
    })
  }

  getMessages(messages) {
    if (!messages) return null
    const toReturn = []
    let i = 0
    const patient = this.state.messageData[this.state.doctorActiveIndex]
    messages.forEach(async (message) => {
      const duration = moment.duration(
        moment().diff(moment(message.created_at)),
      )
      const durationString = `sent ${duration.format(
        'd [days] h [hrs] m [min]',
      )} ago`

      toReturn.push(
        <div
          key={i.toString()}
          style={{ padding: '8px 8px', marginBottom: 6, width: '100%' }}
        >
          <div
            className={
              message.sender === 'Doctor'
                ? Styles.messageWrapperLeft
                : Styles.messageWrapperRight
            }
          >
            {message.sender === 'Doctor' && (
              <div
                style={{
                  marginRight: 10,
                  height: 40,
                  width: 40,
                  borderRadius: '50%',
                  backgroundColor: Constants.primaryThemeFadedDeep,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <p style={{ fontWeight: 'bold', color: 'white' }}>
                  {patient &&
                    patient.first_name &&
                    patient.first_name.charAt(0).toUpperCase()}
                </p>
              </div>
            )}
            <div
              className={
                message.sender === 'Doctor'
                  ? Styles.messageLeft
                  : Styles.messageRight
              }
            >
              <div
                style={{
                  whiteSpace: 'pre-wrap',
                  wordBreak: 'break-all',
                }}
              >
                <Linkify>
                  {message.message}
                  {message.education && message.education.length !== 0
                    ? message.education.map((edu, index) => (
                        <p
                          key={index}
                          style={{
                            fontStyle: 'italic',
                            textDecorationLine: 'underline',
                          }}
                        >
                          {edu}
                        </p>
                      ))
                    : message.education_ids &&
                      message.education_ids.length !== 0 &&
                      message.education_ids.map((edu, index) => (
                        <p
                          key={index}
                          style={{
                            fontStyle: 'italic',
                            textDecorationLine: 'underline',
                          }}
                        >
                          {edu.title}
                        </p>
                      ))}
                  {message.question &&
                    message.question.length !== 0 &&
                    message.question.map((edu, index) => (
                      <p
                        key={index}
                        style={{
                          fontStyle: 'italic',
                          textDecorationLine: 'underline',
                        }}
                      >
                        {edu}
                      </p>
                    ))}
                  {message.patientExperience &&
                    message.patientExperience.length !== 0 &&
                    message.patientExperience.map((edu, index) => (
                      <p
                        key={index}
                        style={{
                          fontStyle: 'italic',
                          textDecorationLine: 'underline',
                        }}
                      >
                        {edu}
                      </p>
                    ))}
                </Linkify>
              </div>
              <p style={{ fontSize: 14, textAlign: 'right', color: 'gray' }}>
                {durationString}
              </p>
            </div>
          </div>
        </div>,
      )
      i++
    })

    // toReturn.push(
    //   <div
    //     ref={this.dummyRef}
    //     style={{ marginTop: 50, visibility: 'hidden' }}
    //     key="hidden"
    //   >
    //     asdasdasdasdas
    //   </div>,
    // )

    return toReturn
  }

  renderMessages() {
    if (!this.state.messageData || this.state.messageData.length === 0) {
      return (
        <div
          style={{
            height: '60%',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <h3>No messages to show</h3>
        </div>
      )
    }

    const patient = this.state.messageData[this.state.doctorActiveIndex]
    if (!patient) {
      return (
        <div
          style={{
            minWidth: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'absolute',
            bottom: 80,
            top: 10,
            height: '100%',
            justifyContent: 'center',
          }}
        >
          <p>Select a care team member from the list</p>
        </div>
      )
    }

    const { messages } = patient
    let messagesElement
    if (!messages || messages.length === 0) {
      messagesElement = (
        <div
          style={{
            height: '60%',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <h3>No messages to show</h3>
        </div>
      )
    } else {
      var elem = document.getElementById('scrollingDiv')
      if (elem) {
        // console.log('ELEM::', elem)
        elem.scrollTop = elem.scrollHeight
      }

      messagesElement = (
        <div id="scrollingDiv" className={Styles.messagesWrapper}>
          {this.getMessages(messages)}

          {/* {this.getMessages(messages.reverse())} */}
        </div>
      )
    }

    if (patient.unread !== 0 && !this.state.updatedUnread) {
      // update unread
      this.updateUnread()
    }

    return (
      <div
        style={{
          minWidth: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'absolute',
          bottom: 80,
          top: 10,
          height: '80%',
        }}
      >
        <div className={Styles.nameWrapper}>
          {/* <Link
            // onClick={() => {
            //   this.navigatetoPatientProfile(patient.uid)
            //   this.onPatientNavLinkClicked('profile')
            // }}
            to={{
              pathname: '/patient/profile',
              state: 'info',
              key: 2,
            }}
          > */}
          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img
              src={patient.image_url || PLACEHOLDER_IMAGE}
              style={{
                height: 50,
                width: 50,
                borderRadius: '50%',
                marginRight: 16,
              }}
            />
            <h5 style={{ color: Constants.primaryThemeDark }}>
              {patient.inactive
                ? 'Deleted User'
                : `${patient.first_name} ${patient.last_name}`}
            </h5>
          </div>
          {/* </Link> */}
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <FontAwesomeIcon
              icon={faUserLock}
              style={{ color: 'gray', fontSize: 16, marginRight: 10 }}
            />
            <p style={{ fontSize: 12, color: 'gray' }}>
              Messages are encrypted end to end
            </p>
          </div>
        </div>

        {messagesElement}
      </div>
    )
  }

  renderContent() {
    return (
      <div
        className={GlobalStyles.contentWrapper}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          height: windowHeight,
          padding: '0px 0px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '25%',
            height: '100%',
            overflowY: 'scroll',
          }}
        >
          {/* {this.renderPatients()} */}
          {this.renderDoctors()}
        </div>

        <div
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '75%',
            height: '100%',
            borderLeft: '1px solid gray',
            paddingBottom: 30,
          }}
        >
          {this.renderMessages()}
          <div className={Styles.bottomInputWrapper}>
            <input
              type="file"
              id="file"
              ref={this.fileUploaderRef}
              style={{ display: 'none' }}
            />
            <input
              onKeyDown={(e) => {
                if (e.key === 'Enter') this.onSubmitPressed()
              }}
              style={{
                position: 'relative',
                top: '20%',
                flexGrow: 4,
                borderRadius: 4,
              }}
              placeholder="Enter your message..."
              className={Styles.textInput}
              type="text"
              value={this.state.messageText}
              onChange={(e) => {
                if (this.state.ailaMessageSelected) return
                this.setState({ messageText: e.target.value })
              }}
            />

            <Button
              onClick={this.onSubmitPressed.bind(this)}
              className={GlobalStyles.button}
              disabled={this.state.messageText.length === 0}
              style={{ width: 100, flexGrow: 1 }}
              variant="primary"
            >
              Send
            </Button>
          </div>
        </div>
      </div>
    )
  }

  render() {
    let selectedPatient
    if (
      this.state.messageData &&
      this.state.messageData.length > 0 &&
      this.state.doctorActiveIndex >= 0
    ) {
      selectedPatient = this.state.messageData[this.state.doctorActiveIndex]
    }

    if (this.state.loading) {
      return (
        <div
          className={GlobalStyles.container}
          style={{ overflow: 'hidden', paddingBottom: '1px' }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              height: '100vh',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        </div>
      )
    }
    return (
      <div
        className={GlobalStyles.container}
        style={{
          overflow: 'hidden',
          paddingBottom: '10px',
          maxHeight: '100vh',
        }}
      >
        <Header header="Messages" />

        {/* <p>Patient messages</p> */}

        {this.renderContent()}
      </div>
    )
  }
}

const mapStateToProps = (state /* , ownProps */) => ({
  loggedIn: state.userReducer.loggedIn,
  token: state.authReducer.token,
  docFirebaseAuthUser: state.userReducer.firebaseAuthUser,
  patientList: state.patientReducer.patientList,
  virgilToken: state.authReducer.virgilToken,
  e3: state.authReducer.e3,
  mongoUser: state.userReducer.mongoUser,
  patientCore: state.patientReducer.patientCore,
  updateRender: state.userReducer.render,
  adminId: state.userReducer.adminId,
  patient: state.patientReducer.patient,
})

const mapDispatchToProps = {
  stopTimer,
  updateSideNavBar,
  addRpmPatient,
  addPatientLP,
  getUnreadMessages,
  resetTimer,
  startTimer,
  addVirgilE3,
  updateDoctorMongoUser,
  addMongoUser,
  addPatient,
  userLoggedIn,
  fetchPatientCoreDate,
  fetchPatientProgressData,
  fetchPatientWellnessScreening,
  fetchPatientCalculateProgressData,
  updatePatientData,
  addPatientList,
  setPatientCoreData,
  fetchPatientConditions,
  fetchPatientMedications,
  fetchPatientLabs,
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientMessages)
