import React, { Component } from 'react'
import GlobalStyles from '../styles/global.module.scss'
import Styles from "./styles/Careteam.module.scss";
import PatientInfoStrip from '../../components/PatientInfoStrip.js'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faHandHoldingMedical} from '@fortawesome/free-solid-svg-icons'
import Constants from '../../values.js'
import {connect} from "react-redux";
import Config from "../../config";
const axios = require('axios');

const FIELDS = [
  {
    label: 'Primary Care',
    fields:[{label: 'Name', value: 'Dr. John Davis'},{label: 'Email', value: 'john@gmail.com'}, {label: 'Phone', value: '(555)555-5555'}, {label: 'Address', value: '#1040 Folsom st, San Francisco, CA 94103'} ]
  },
  {
    label: 'Pharmacy',
    fields:[{label: 'Name', value: 'CVS Pharmacy'},{label: 'Email', value: 'cvs@gmail.com'}, {label: 'Phone', value: '(123)412-1312'}, {label: 'Address', value: '#451 Shattuck ave, San Diego, CA 95130'} ]
  },
  {
    label: 'Social Worker',
    fields:[{label: 'Name', value: 'Dr. Navas Sharif'},{label: 'Email', value: 'sharif@hotmail.com'}, {label: 'Phone', value: '(123)123-1231'}, {label: 'Address', value: '#456 Yes st, New York, NY 94111'} ]
  },
  {
    label: 'Psychologist',
    fields:[{label: 'Name', value: 'Dr Donald Reagan'},{label: 'Email', value: 'ronald@gmail.com'}, {label: 'Phone', value: '(123)312-3818'}, {label: 'Address', value: '#1289 North West ave, san Antonia, CA 84312'} ]
  },
  {
    label: 'Nutritionist',
    fields:[{label: 'Name', value: 'Bridget Clever'},{label: 'Email', value: 'bridget@gmail.com'}, {label: 'Phone', value: '(212)321-2827'}, {label: 'Address', value: '#4512 Tether ave, Houston, TX 98271'} ]
  },
  {
    label: 'Emergency Contact',
    fields:[{label: 'Name', value: 'Hebrew Gerard'},{label: 'Email', value: 'gerard@gmail.com'}, {label: 'Phone', value: '(212)312-1231'}, {label: 'Address', value: '#4312 Spear st, San Francisco, 94105'} ]
  }
]

class CareTeam extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      careteam: null
    }

    this.getUserCareteam = this.getUserCareteam.bind(this)
  }

  static getDerivedStateFromProps(props, state) {
    if(props.patientCore && state.careteam)
      return {loading: false}

    return null
  }

  componentDidMount() {
    if(!this.props.patient) {
      this.props.history.push('/patient/list')
      return
    }
    window.scrollTo(0, 0)

    if(!this.state.careteam)
      this.getUserCareteam()
  }

  getUserCareteam() {
    let self = this
    let {patient, token} = this.props
    let url = Config.BACKEND_URL + 'user/careteam'
    axios({
      method: 'get',
      url: url,
      headers: {Authorization: 'JWT ' + token, x_firebase_id: patient.uid}
    }).then(({ data }) => {
      if(data) {
        self.setState({careteam: data.doctors})
      }
    }).catch(err => {
      console.log('error when getting user care team', err)
    })
  }

  renderFields() {
    let toReturn = []
    let careteam = this.state.careteam
    if(!careteam)
      return

    careteam.forEach(doc => {
      let name = doc.doctor_name
      if(name.charAt(name.length - 1) === ',')
        name = name.substring(0, name.length-1)
      toReturn.push(
        <div key={doc.doctor_name} style={{width: '45%', backgroundColor: '#e8e8e8', margin: 10, padding: 16, borderRadius: 10}}>
          <h4 style={{color: Constants.primaryTheme}}>{doc.speciality}</h4>
          <div className={Styles.entryRow}>
            <p style={{fontWeight: 'bold', width: '20%'}}>Name : </p>
            <p style={{width: '70%'}}>{name}</p>
          </div>

          <div className={Styles.entryRow}>
            <p style={{fontWeight: 'bold', width: '20%'}}>Email : </p>
            <p style={{width: '70%'}}></p>
          </div>

          <div className={Styles.entryRow}>
            <p style={{fontWeight: 'bold', width: '20%'}}>Phone : </p>
            <p style={{width: '70%'}}>{doc.phone}</p>
          </div>

          <div className={Styles.entryRow}>
            <p style={{fontWeight: 'bold', width: '20%'}}>Address: </p>
            <p style={{width: '70%'}}>{doc.address}</p>
          </div>
        </div>
      )
    })

    return toReturn
  }

  render() {
    return (
      <div className={GlobalStyles.container}>
        {
          this.state.loading ?
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100%', height: 200}}>
              <div className={GlobalStyles.loader} />
            </div>
            :
            <div>
              <PatientInfoStrip
                patient={this.props.patient}
                patientCore={this.props.patientCore}
                color={this.props.color}
                cohorts={this.props.mongoUser ? this.props.mongoUser.cohorts : []}
              />

              <div style={{width: '100%', padding: '50px 25px'}}>
                <div className={Styles.rowIcon}>
                  <FontAwesomeIcon icon={faHandHoldingMedical} style={{color: 'white', fontSize: 30}}/>
                  <p className={Styles.rowIconText}>Care Team</p>
                </div>

                <div style={{width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', marginTop: 40}}>
                  {this.renderFields()}
                </div>
              </div>
            </div>
        }

      </div>
    )
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    patient: state.patientReducer.patient,
    patientCore: state.patientReducer.patientCore,
    color: state.patientReducer.color,
    token: state.authReducer.token,
    mongoUser: state.userReducer.mongoUser
  }
}


export default connect(
  mapStateToProps,
  null
)(CareTeam)
