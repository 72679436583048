import React, { Component } from 'react'
import GlobalStyles from '../styles/global.module.scss'
import values from '../../values'
import Button from 'react-bootstrap/Button'
import AilaLogo from '../../assets/images/aila_logo.png'
import Config from '../../config'
import Constants from '../../values'
import Modal from 'react-modal'

const axios = require('axios')

const customModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: 600,
    height: '40vh',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const paymentModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: 600,
    height: '80vh',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

export default class AilaWebAppointmentScheduler extends Component {
  constructor() {
    super()
    this.state = {
      patient_id: this.props?.location?.state?.patient_id
        ? this.props?.location?.state?.patient_id
        : '',
      programSelected: '',
      planType: true,
      lat: '',
      lon: '',
      token: '',
      docInfo: '',
      setConfirmCustomModal: false,
      setCustomModalDetails: '',
      selectedDrName: '',
      accessToken: '',
      doctorDetails: '',
      userLocation: '',
      showScheduler: false,
      openAppointmentUrl: '',
      showPaymentModal: false,
    }
    this.renderMainContent = this.renderMainContent.bind(this)
    this.renderAppointmentScheduler = this.renderAppointmentScheduler.bind(this)
    this.onDoctorClicked = this.onDoctorClicked.bind(this)
    this.getAppointmentUrl = this.getAppointmentUrl.bind(this)
    this.confirmFunction = this.confirmFunction.bind(this)
    this.renderIframe = this.renderIframe.bind(this)
    this.getLocation = this.getLocation.bind(this)
    this.googleGeocodingApi = this.googleGeocodingApi.bind(this)
    this.getAuthToken = this.getAuthToken.bind(this)
    this.getProviderDetails = this.getProviderDetails.bind(this)
  }

  componentDidMount() {
    let tokenResp = this.getAuthToken(this.props?.location?.state?.patient_id)
    // let tokenResp = this.getAuthToken('jBUcZKnmYuTWYAbHU1XJXfCegim1')

    if (tokenResp.error === true) {
      console.log('Token not accessed')
    } else {
      console.log('Success token auth')
    }
  }

  showPosition = (position) => {
    let geoValueLat = position.coords.latitude
    let geoValueLon = position.coords.longitude

    this.setState({ lat: geoValueLat, lon: geoValueLon })

    console.log('licensed_states: ', this.state.docInfo?.licensed_states)
    // if gps enabled request for lat and long
    if (this.state.docInfo?.licensed_states?.length) {
      let licensedFullStateName = this.state.docInfo?.licensed_states?.map(
        (address) => address?.state?.toLowerCase(),
      )
      let licensedAbbrStateName = this.state.docInfo?.licensed_states?.map(
        (address) => address?.abbr?.toLowerCase(),
      )
      console.log('Array: ', licensedFullStateName, licensedAbbrStateName)

      var lat = 36.7783
      var lon = -119.4179

      // var lat = 34.0489
      // var lon = -111.0937

      this.googleGeocodingApi(
        lat,
        lon,
        // geoValueLat,
        // geoValueLon,
        licensedFullStateName,
        licensedAbbrStateName,
        1,
      )
      this.onDoctorClicked()
    }
  }

  getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.showPosition)
    }
  }

  onDoctorClicked = () => {
    let doc = this.state.docInfo
    if (!doc?.nylas || !doc?.nylas?.access_token) {
      //cannot book appoinmtnet ith the selected team memeber
      console.log(
        'Calendar Error : Cannot book an appointment with the selected care team member, Please contact support if the problem persists.',
      )
      return
    }
    this.setState({
      selectedDrName: `${doc.first_name} ${doc.last_name}`,
      accessToken: doc.nylas.access_token,
      doctorDetails: doc,
    })
  }

  renderIframe = (apptUrl) => {
    console.log(apptUrl)
    return (
      <iframe
        src={apptUrl + '#toolbar=0'}
        style={{ height: '80vh', width: '100%', margin: '20px 20px' }}
      ></iframe>
    )
  }

  // Token from backend
  getAuthToken = async (currentUser) => {
    if (!currentUser) {
      console.log('Invalid user')
      return { error: true }
    }

    let authUrl = `${Config.BACKEND_URL}authorization`
    try {
      let response = await axios({
        method: 'get',
        url: authUrl,
        headers: { x_firebase_id: currentUser },
      })
      if (response.status === 200) {
        this.setState({ token: response.data.token })
        // console.log('AUTHORIZATION TOEN : ', response.data.token)
        this.getProviderDetails(Config.APPOINTMENT_BOOKING_PROVIDER_ID)

        return { error: false }
      } else {
        console.log('error', response)
        return { error: true }
      }
    } catch (err) {
      console.log('error when getting auth token', err)
      return { error: true, errorString: 'error when getting auth token' }
    }
  }

  getAppointmentUrl = async () => {
    try {
      let respose = await axios({
        method: 'get',
        url: `${Config.BACKEND_URL}provider/appointment/url`,
        headers: {
          Authorization: `JWT ${this.state.token}`,
          nylas_access_token: this.state.accessToken,
        },
      })

      if (respose.status === 200) {
        this.setState({ openAppointmentUrl: respose.data.url })
        return { error: false, response: respose.data.url }
      } else {
        console.log('error', respose.data)
        return { error: true }
      }
    } catch (err) {
      console.log('error when getting auth token', err)
      return { error: true, errorString: 'error when getting auth token' }
    }
  }

  confirmFunction = async (lat, lon, resultAddress) => {
    this.setState({
      userLocation: {
        lat: lat,
        long: lon,
        state: resultAddress?.long_name,
        state_abbr: resultAddress?.short_name,
        showScheduler: true,
      },
      setConfirmCustomModal: false,
    })
  }

  // to get the address using lat and long
  googleGeocodingApi = (
    lat,
    lon,
    licensedFullStateName,
    licensedAbbrStateName,
  ) => {
    // for testing purpose uncomment it to get lat long of CA
    // var lat = 36.7783
    // var long = -119.4179
    var config = {
      method: 'get',
      url: `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&result_type=administrative_area_level_1&key=${Config.GOOGLE_API_KEY}`,
    }

    axios(config)
      .then((response) => {
        // setLoading(false)
        console.log('LAT: ', lat)
        console.log('LON: ', lon)
        console.log('got address from google', response?.data?.results?.[0])
        let resultAddress =
          response?.data?.results?.[0]?.address_components?.[0]
        if (
          licensedFullStateName.includes(
            resultAddress?.long_name.toLowerCase(),
          ) ||
          licensedAbbrStateName.includes(
            resultAddress?.short_name.toLowerCase(),
          )
        ) {
          let apptUrl = this.getAppointmentUrl()

          console.log('state matched')
          this.setState({
            setCustomModalDetails: {
              title: 'Confirm your Location',
              body:
                `Please confirm that you will be in the state of ${resultAddress?.long_name} at the time of your appointment. ` +
                '\nAila Health Clinicians can only see patients in states where they are licensed to practice.',
              confirmFn: () =>
                this.confirmFunction(lat, lon, resultAddress, apptUrl),

              confirmText: 'Confirm',
              rejectFn: () => {
                console.log('modal closed')
                this.props.history.push('/wix/aila-download', {
                  patient_id: this.props?.location?.state?.patient_id,
                  email_id: this.props?.location?.state?.email_id,
                  stateResidence: this.props?.location?.state?.stateResidence,
                  planSelected: this.props?.location?.state?.planSelected,
                  patient_type: true,
                })
              },
              rejectText: 'Cancel',
              disableOptions: false,
            },
            setConfirmCustomModal: true,
          })
        } else {
          console.log('state does not match')
          this.setState({
            setCustomModalDetails: {
              title: 'Sorry',
              body:
                'Doctors can only see patients in states. ' +
                "\nUnfortunately, we haven't arrived in your state yet. Please stay tuned as we expand into new states. " +
                '\nIn the meantime, please continue to use the app and connect with the community.',
              confirmFn: () => {
                console.log('modal closed')
                this.props.history.push('/wix/aila-download', {
                  patient_id: this.props?.location?.state?.patient_id,
                  email_id: this.props?.location?.state?.email_id,
                  stateResidence: this.props?.location?.state?.stateResidence,
                  planSelected: this.props?.location?.state?.planSelected,
                  patient_type: false,
                })
              },
              rejectFn: () => {
                console.log('modal closed')
                this.props.history.push('/wix/aila-download', {
                  patient_id: this.props?.location?.state?.patient_id,
                  email_id: this.props?.location?.state?.email_id,
                  stateResidence: this.props?.location?.state?.stateResidence,
                  planSelected: this.props?.location?.state?.planSelected,
                  patient_type: false,
                })
              },
              rejectText: 'Ok',
              disableOptions: true,
            },
            setConfirmCustomModal: true,
          })
        }
      })
      .catch(function (error) {
        // setLoading(false)
        console.log('error when getting address from google', error)
        // setLoading(false)
      })
  }

  // Token from backend
  async getProviderDetails(provider_uid) {
    const url = `${Config.BACKEND_URL}providers`

    try {
      const response = await axios({
        method: 'get',
        url,
        headers: {
          Authorization: `JWT ${this.state.token}`,
          x_firebase_id: provider_uid,
        },
      })
      if (response.status === 200) {
        console.log('DOC INFO :', response)
        this.setState({ docInfo: response.data })
        this.getLocation()

        return { error: false }
      } else {
        console.log('error', response)
        return { error: true }
      }
    } catch (err) {
      console.log('error when getting doc details', err)
      return { error: true, errorString: 'error when getting doc details' }
    }
  }

  renderPaymentModal() {
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          style={{
            fontSize: '30px',
            color: values.primaryThemeDark,
            textAlign: 'center',
            fontWeight: 'bold',
            fontFamily: 'Avenir',
            wordWrap: 'break-word',
            position: 'relative',
          }}
        >
          Confirm your booking by entering
          <br />
          your payment details
        </p>

        <div
          style={{
            flex: 1,
            alignSelf: 'center',
            width: '100%',
            overflow: 'hidden',
          }}
        >
          <iframe
            id="payment_web"
            src={`${Config.WEB_HOST}stripe/subscription-new?patient_id=${this.props?.location?.state?.patient_id}&email=${this.props?.location?.state?.email_id}&state=${this.props?.location?.state?.stateResidence}`}
            title="payment"
            style={{ height: '100%', width: '100%' }}
          />
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            marginTop: '4vh',
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          <div
            style={{
              width: '50%',
              margin: '20px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              onClick={() => {
                console.log('Payment modal closed !')
                this.props.history.push('/wix/aila-download', {
                  patient_id: this.props?.location?.state?.patient_id,
                  email_id: this.props?.location?.state?.email_id,
                  stateResidence: this.props?.location?.state?.stateResidence,
                  planSelected: this.props?.location?.state?.planSelected,
                  patient_type: true,
                })
              }}
              className={GlobalStyles.button}
              disabled={this.state.modalLoading}
              variant="primary"
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    )
  }

  renderCustomModal() {
    let customDetails = this.state.setCustomModalDetails
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          style={{
            fontSize: '30px',
            color: values.primaryThemeDark,
            textAlign: 'center',
            fontWeight: 'bold',
            fontFamily: 'Avenir',
            wordWrap: 'break-word',
            position: 'relative',
          }}
        >
          {customDetails.title}
        </p>

        <p
          style={{
            fontSize: '20px',
            marginTop: '4vh',
            color: values.primaryTheme,
            textAlign: 'center',
            fontFamily: 'Avenir',
            wordWrap: 'break-word',
            position: 'relative',
          }}
        >
          {customDetails.body}
        </p>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            marginTop: '4vh',
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          <div
            style={{
              width: '50%',
              margin: '20px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              onClick={() => {
                customDetails.confirmFn && customDetails.confirmFn()
                console.log('CONFIRMED MODAL YES !')
              }}
              className={GlobalStyles.button}
              disabled={this.state.modalLoading}
              style={{ width: 200 }}
              variant="primary"
            >
              {customDetails.confirmText || 'Yes'}
            </Button>
          </div>

          <div
            style={{
              width: '50%',
              margin: '20px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              onClick={() => {
                customDetails.rejectFn && customDetails.rejectFn()
                console.log('CONFIRMED MODAL CANCEL !')
              }}
              className={GlobalStyles.button}
              disabled={this.state.modalLoading}
              style={{ width: 200, backgroundColor: 'gray' }}
              variant="primary"
            >
              {customDetails.rejectText || 'Cancel'}
            </Button>
          </div>
        </div>
      </div>
    )
  }

  renderAppointmentScheduler = () => {
    let doc = this.state.docInfo
    return (
      <div
        style={{
          display: 'flex',
          width: '60vh',
          alignSelf: 'center',
          border: '1px',
          borderColor: Constants.primaryThemeDark,
          borderStyle: 'solid',
          marginTop: '10px',
        }}
      >
        <div>
          <img
            alt="Aila Health"
            src={doc.image_url}
            style={{
              width: '200px',
              height: '200px',
            }}
          />
        </div>
        <div style={{ flex: 1, margin: 'auto' }}>
          <p
            style={{
              fontSize: '20px',
              color: values.primaryThemeDark,
              textAlign: 'left',
              fontWeight: 'bold',
              fontFamily: 'Avenir',
              wordWrap: 'break-word',
              textDecorationLine: 'underline',
            }}
          >
            {`${doc.first_name} ${doc.last_name}`}
          </p>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 10,
              fontSize: '20px',
            }}
          >
            <p style={{ fontWeight: 'bold', marginRight: 10, width: '40%' }}>
              Profile:
            </p>
            <p>{doc.user_type && doc.user_type}</p>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 10,
              fontSize: '20px',
            }}
          >
            <p style={{ fontWeight: 'bold', marginRight: 10, width: '40%' }}>
              Specialty:
            </p>
            <p>{doc.speciality && doc.speciality}</p>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 10,
              fontSize: '20px',
            }}
          >
            <p style={{ fontWeight: 'bold', marginRight: 10, width: '40%' }}>
              Languages Spoken:{' '}
            </p>
            <p style={{ width: '50%', textOverflow: 'break-word' }}>
              {doc.languages && doc.languages}
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 10,
              fontSize: '20px',
            }}
          >
            <p style={{ fontWeight: 'bold', marginRight: 10, width: '40%' }}>
              Residency:
            </p>
            <p>{doc.residency && doc.residency}</p>
          </div>
        </div>
      </div>
    )
  }

  renderMainContent = () => {
    return (
      <div
        style={{
          flex: 1,
          alignSelf: 'center',
          marginTop: '-8vh',
        }}
      >
        <p
          style={{
            fontSize: '30px',
            margin: 'auto',
            color: values.primaryThemeDark,
            textAlign: 'center',
            fontWeight: 'bold',
            fontFamily: 'Avenir',
            wordWrap: 'break-word',
            marginTop: '2vh',
          }}
        >
          Schedule your first appointment
        </p>
      </div>
    )
  }
  render() {
    return (
      <div
        className={GlobalStyles.container}
        style={{
          overflow: 'hidden',
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'left',
            alignSelf: 'left',
            marginTop: '1vh',
          }}
        >
          <img
            alt="Aila Health"
            src={AilaLogo}
            style={{
              width: '15vh',
              height: '15vh',
              alignSelf: 'flex-start',
            }}
          />
        </div>

        {this.renderMainContent()}
        {this.renderAppointmentScheduler()}
        <div
          style={{
            flex: 1,
            alignSelf: 'center',
            width: '60vh',
            overflow: 'hidden',
          }}
        >
          <iframe
            src={this.state.openAppointmentUrl}
            title="appointment"
            style={{ height: '80vh', width: '60vh' }}
          ></iframe>
          {/* )} */}
        </div>

        <div
          style={{
            flex: 1,
            alignSelf: 'center',
            position: 'relative',
          }}
        >
          <Button
            onClick={(e) => {
              this.setState({ showPaymentModal: true })
              // e.preventDefault()
              // this.props.history.push('/wix/aila/payment', {
              //   patient_id: this.props?.location?.state?.patient_id,
              //   email_id: this.props?.location?.state?.email_id,
              //   stateResidence: this.props?.location?.state?.stateResidence,
              //   planSelected: this.props?.location?.state?.planSelected,
              // })
            }}
            className={GlobalStyles.button}
            style={{
              fontWeight: 'bold',
              margin: '2vh',
              alignSelf: 'center',
              width: 200,
            }}
            variant="primary"
          >
            {'Submit'}
          </Button>
        </div>
        {this.state.setCustomModalDetails.length !== 0 && (
          <Modal
            ariaHideApp={false}
            isOpen={this.state.setConfirmCustomModal}
            style={customModalStyles}
            contentLabel="Modal"
          >
            {this.renderCustomModal()}
          </Modal>
        )}
        {this.state.showPaymentModal && (
          <Modal
            ariaHideApp={false}
            isOpen={this.state.showPaymentModal}
            style={paymentModalStyles}
            contentLabel="Modal"
          >
            {this.renderPaymentModal()}
          </Modal>
        )}
      </div>
    )
  }
}
