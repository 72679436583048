import React, { Component } from 'react'
import Styles from './styles/LongcovidIntake.module.scss'
import GlobalStyles from '../styles/global.module.scss'
import FancyField from 'react-fancy-field'
import values from '../../values'
import Button from 'react-bootstrap/Button'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import { geocodeByAddress } from 'react-google-places-autocomplete'
import AilaLogo from '../../assets/images/aila_logo.png'
import BrainIcon from '../../assets/images/brain_icon.png'
import CardioIcon from '../../assets/images/cardio_icon.png'
import EndocrineIcon from '../../assets/images/endocrine_icon.png'
import GeneralHealthIcon from '../../assets/images/general_health_icon.png'
import JointIcon from '../../assets/images/joint_icon.png'
import LungIcon from '../../assets/images/lung_icon.png'
import MentalHealthIcon from '../../assets/images/mental_health_icon.png'
import StomachIcon from '../../assets/images/stomach_icon.png'
import Image1 from '../../assets/images/longhauler.png'
import Image2 from '../../assets/images/postcovidteam.png'
import Image3 from '../../assets/images/covidresources.png'
import Form from 'react-bootstrap/Form'
import Progress_bar from '../../components/ProgressBar'
import algoliasearch from 'algoliasearch/lite'
import Config from '../../config'
import Select from 'react-select'
import * as Metadata from '../../metadata'
import moment, { months } from 'moment'
import DatePicker from 'react-date-picker'
import Slider from 'react-rangeslider'
import Modal from 'react-modal'
import Footer from '../../components/Footer'
import zipcodes from 'zipcodes'
import PatientInsurance from '../PatientWeb/PatientAccount/PatientInsurance'
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  PinterestIcon,
  PinterestShareButton,
} from 'react-share'

import { useSelector, useDispatch } from 'react-redux'
import {
  addAuthToken,
  addFirebaseUser,
  addFirebaseAuthUser,
  addPatient,
  fromWebFlow,
  fetchPatientCoreDate,
  toggleSidebar,
} from '../../redux/actions'
import { connect } from 'react-redux'

import {
  firebase,
  auth,
  googleProvider,
  appleProvider,
  analytics,
} from '../../services/firebase'

import 'react-rangeslider/lib/index.css'
import './rangeSlider.css'
import {
  InstantSearch,
  SearchBox,
  Hits,
  RefinementList,
  InfiniteHits,
  connectStateResults,
  Highlight,
} from 'react-instantsearch-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons'

// import DatePicker from '../../components/DatePicker'

const axios = require('axios')

const paymentModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '75%',
    height: '90%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 10,
    zIndex: 2,
  },
}

class LongcovidIntake extends Component {
  constructor() {
    super()
    this.state = {
      address: '',
      address2: null,
      addressComponents: null,
      postalCode: '',
      emergencyContactName: '',
      emergencyContactPhone: '',
      phoneNumber: '',
      insuranceBack: false,
      backLocation: '',
      backImage: null,
      back_image_url: null,
      backUploaded: false,
      frontLocation: '',
      frontImage: null,
      front_image_url: null,
      frontUploaded: false,
      insuranceFront: false,
      userInsurance: null,
      pharmacy_others: '',
      state_residence: '',

      insurance_provider: '',
      subscriber_id: '',
      plan_type: '',
      member_name: '',
      group_number: '',

      pharmacyList: [],
      zip: '',
      pharma: null,

      loading: false,
      imageUploaded: false,
      imageLocation: '',
      image: null,
      error: false,
      errorString: '',

      //INSURANCE NEW FLOW TAKE IN SECONDARY AND PRIMARY INSURANCE
      showInsuranceModal: false,
    }
    this.imageUploadRef = React.createRef()
    this.frontImageRef = React.createRef()
    this.backImageRef = React.createRef()
    this.saveUserToBackend = this.saveUserToBackend.bind(this)
    this.saveImageToFirebase = this.saveImageToFirebase.bind(this)
    this.onSearch = this.onSearch.bind(this)
    this.updateUserInsurance = this.updateUserInsurance.bind(this)
    this.updateUserDataOnFirebase = this.updateUserDataOnFirebase.bind(this)
  }

  componentDidMount() {
    if (!this.props.patient) {
      this.props.history.push('/signup/long-covid')
      return
    }

    const user = { ...this.props.patient, ...this.props.patientCore }
    const userInsurance = user.insurance || null
    this.setState({ userInsurance: userInsurance })
  }

  onSearch() {
    const { patient, token } = this.props
    // setLoading(true)
    let url = Config.CHANGE_BACKEND_URL
    var config = {
      method: 'get',
      url: `${url}change/pharmacies/search/zip`,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: patient.uid,
        zip: this.state.zip,
      },
    }

    axios(config)
      .then(({ data }) => {
        let tempList = []
        data.forEach((patientPharmacy) => {
          tempList.push({
            label: patientPharmacy
              ? patientPharmacy?.name?.[1] +
                `, ${patientPharmacy?.address_1}` +
                `, ${patientPharmacy?.city}`
              : '',
            value: patientPharmacy,
          })
        })
        this.setState({ pharmacyList: tempList || [] })
        // if (data) {
        //   setPharmacyList(data || [])
        //   setLoading(false)
        // }
      })
      .catch(function (error) {
        // setLoading(false)
        // setPharmacyList([])
        console.log('error - pharmacy', error)
      })
  }

  updateUserDataOnFirebase = (uid, data) => {
    console.log('inside-firebase')
    firebase
      .firestore()
      .collection('users')
      .doc(uid)
      .update(data)
      .then((doc) => {
        console.log('success-firebase')

        firebase
          .firestore()
          .collection('users')
          .doc(uid)
          .get()
          .then((doc) => {
            if (doc && doc.data()) {
              const data = doc.data()
              // console.log('user firebase saved :: ', data)
              console.log('success || firebase || savedata')
              this.props.addFirebaseUser(data)
              this.props.addPatient(data, 'gray')
            }
          })
        // getFirebaseUser(uid)
      })
      .catch((err) => {
        console.log('error - saving firebase ', err)
      })
  }

  updateUserInsurance(uid, data) {
    let { userInsurance } = this.state
    const { token } = this.props

    console.log('inside insurance::', userInsurance)

    if (!userInsurance?._id) {
      //need to send firebase ID the first time
      data.firebase_id = uid
    }

    var config = {
      method: userInsurance?._id ? 'put' : 'post',
      url: `${Config.BACKEND_URL}patients/insurances${
        userInsurance?._id ? `/${userInsurance?._id}` : ''
      }`,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: uid,
      },
      data: data,
    }

    axios(config)
      .then(function (response) {
        console.log('saved insurance information in the backend')
        // toast.success('Successfully updated insurance')
      })
      .catch(function (error) {
        console.log('error when saving info to backend', error)
        // toast.warning('Could not update Insurance')
      })
  }

  getRandomString = (len) => {
    var result = []
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    var charactersLength = characters.length
    for (let i = 0; i < len; i++) {
      result.push(
        characters.charAt(Math.floor(Math.random() * charactersLength)),
      )
    }
    return result.join('')
  }

  saveImageToFirebase(user) {
    const self = this

    if (this.state.frontUploaded) {
      const file = this.state.frontImage
      const storageRef = firebase.storage().ref()
      const fileExtension = self.state.frontImage.name.split('.').pop()

      const uploadTask = storageRef
        .child(
          `user/insurances/${user.uid}/${
            self.state.frontUploaded
              ? `front-${self.getRandomString(10)}`
              : `back-${self.getRandomString(10)}`
          }.${fileExtension}`,
        )
        .put(file)
      uploadTask.on(
        'state_changed',
        function (snapshot) {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log(`${progress}%`)
        },
        function (err) {
          // Handle unsuccessful uploads
          console.log('error when uploading image', err)
          return

          // self.saveUserToBackend(user)
        },
        function () {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            // user.insurance.front_image_url = downloadURL
            self.setState({ front_image_url: downloadURL })
            return

            // self.saveUserToBackend(user)
          })
        },
      )
    }
    if (this.state.backUploaded) {
      const file = this.state.backImage
      const storageRef = firebase.storage().ref()
      const fileExtension = self.state.backImage.name.split('.').pop()

      const uploadTask = storageRef
        .child(
          `user/insurances/${user.uid}/${
            self.state.frontUploaded
              ? `front-${self.getRandomString(10)}`
              : `back-${self.getRandomString(10)}`
          }.${fileExtension}`,
        )
        .put(file)
      uploadTask.on(
        'state_changed',
        function (snapshot) {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log(`${progress}%`)
        },
        function (err) {
          // Handle unsuccessful uploads
          console.log('error when uploading image', err)
          return

          // self.saveUserToBackend(user)
        },
        function () {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            // user.insurance.back_image_url = downloadURL
            self.setState({ back_image_url: downloadURL })
            return
            // self.saveUserToBackend(user)
          })
        },
      )
    }
  }

  onImageChange(e) {
    let { patient, patientCore, token } = this.props

    const file = e.target.files[0] || false
    if (
      file &&
      window.confirm(`Are you sure you want to upload ${file.name}?`)
    ) {
      // this.setState({imageUploaded: true, imageLocation: })

      const reader = new FileReader()
      const self = this

      reader.onload = function (e) {
        const image = e.target.result
        // if (self.state.profileChanged) {
        //   console.log('confirmed upload - profile')

        //   self.setState(
        //     {
        //       profileLocation: image,
        //       // imageLocation: image,
        //       // imageUploaded: true,
        //       // image: file,
        //       profileUploaded: true,
        //       profileImage: file,
        //     },
        //     () => {
        //       self.setState({ profileChanged: false })
        //     },
        //   )
        // } else
        if (self.state.insuranceFront) {
          console.log('confirmed upload - insurance front')

          self.setState(
            {
              frontLocation: image,
              // imageUploaded: true,
              // image: file,
              frontUploaded: true,
              frontImage: file,
            },
            () => {
              self.setState({ insuranceFront: false })
              self.saveImageToFirebase(patient)
            },
          )
        } else if (self.state.insuranceBack) {
          console.log('confirmed upload - insurance back')

          self.setState(
            {
              backLocation: image,
              // imageUploaded: true,
              backUploaded: true,
              backImage: file,
            },
            () => {
              self.setState({ insuranceBack: false })
              self.saveImageToFirebase(patient)
            },
          )
        }
      }
      reader.readAsDataURL(file) // convert to base64 string
    } else {
      console.log('ignored')
    }
  }

  onSavePressed() {
    let { patient, patientCore, token } = this.props
    let {
      address,
      address2,
      addressComponents,
      postalCode,
      emergencyContactName,
      emergencyContactPhone,
      pharma,
      front_image_url,
      back_image_url,
      insurance_provider,
      subscriber_id,
      plan_type,
      member_name,
      group_number,
    } = this.state

    console.log('save presed')

    if (!addressComponents) {
      this.setState({ error: true, errorString: 'Please input your address' })
      return
    }
    if (!emergencyContactName || !emergencyContactPhone) {
      this.setState({
        error: true,
        errorString: 'Please enter the emergency contact information',
      })
      return
    }
    if (!patientCore?.insurance) {
      this.setState({
        error: true,
        errorString: 'Please enter insurance details',
      })
    }
    if (!pharma) {
      this.setState({
        error: true,
        errorString: 'Please select your preferred pharmacy',
      })
      return
    }
    // if (this.state.frontUploaded) {
    //   this.saveImageToFirebase(patient)
    // } else if (this.state.backUploaded) {
    //   this.saveImageToFirebase(patient)
    // } else {
    this.saveUserToBackend(patient)
    // }
  }

  saveUserToBackend() {
    const self = this

    let { patient, patientCore, token } = this.props
    // console.log('saved pressed', this.state)
    let {
      address,
      address2,
      pharmacy_others,
      addressComponents,
      postalCode,
      emergencyContactName,
      emergencyContactPhone,
      pharma,
      front_image_url,
      back_image_url,
      insurance_provider,
      subscriber_id,
      plan_type,
      member_name,
      group_number,
    } = this.state

    const insuranceData = {
      insurance_provider: insurance_provider && insurance_provider.trim(),
      subscriber_id: subscriber_id && subscriber_id.trim(),
      plan_type: plan_type && plan_type.trim(),
      member_name: member_name && member_name.trim(),
      group_number: group_number && group_number.trim(),
      front_image_url: front_image_url,
      back_image_url: back_image_url,
    }

    // this.updateUserInsurance(patient.uid, insuranceData)

    const firebaseData = {
      // email: '',
      emergencyContactName: emergencyContactName,
      emergencyContactPhone: emergencyContactPhone,
      streetAddress: address || '',
      streetAddress2: address2 || '',
      zipcode: postalCode,
      addressComponents: addressComponents || [],
    }

    this.updateUserDataOnFirebase(patient.uid, firebaseData)

    self.setState({ loading: true, saving: false })

    let change = pharma?.value ? { pharmacy: pharma.value } : {}

    const mongoData = {
      change: change,
      pharmacy_other: pharmacy_others,
    }

    const url = `${Config.BACKEND_URL}users`

    // console.log('mongo data::', mongoData, url)

    axios({
      method: 'put',
      url,
      headers: { Authorization: `JWT ${token}`, x_firebase_id: patient.uid },
      data: mongoData,
    })
      .then(({ data }) => {
        console.log('success-mongo')
        // self.props.addMongoUser(user)
        // toast.success('Successfully updated profile')

        this.props.fetchPatientCoreDate(patient.uid, token)

        // if (self.state.imageUploaded) toast.success('Updated profile picture')
        self.setState({ loading: false, saving: false })

        // this.props.history.push('/wix/aila/appointment-scheduler')
        this.props.history.push('/wix/aila/program', {
          programType: this.state.state_residence?.abbreviation,
        })

        // , imageUploaded: false })
      })
      .catch((err) => {
        console.log('error when adding doctor data to backend', err)
      })
  }

  handleInsuranceCallback = (childData) => {
    console.log('callback::', childData)
    const self = this
    const { token, patient } = this.props
    if (childData) {
      self.setState({ showInsuranceModal: false }, () => {
        self.props.toggleSidebar(false)
        self.setState({
          error: false,
          errorString: '',
          loading: true,
          saving: true,
        })
        self.props.fetchPatientCoreDate(patient.uid, token)

        // if (self.state.imageUploaded) toast.success('Updated profile picture')
        self.setState({ loading: false, saving: false })
        const userInsurance = Array.isArray(patient.insurance)
          ? patient.insurance[0]
          : patient.insurance || null
        this.setState({ userInsurance: userInsurance }, () => {
          console.log('updated user insurance')
        })
      })
    } else {
      self.setState({ showInsuranceModal: false })
      self.props.toggleSidebar(false)
    }
  }

  renderInsuranceModal() {
    const { userDetails } = this.state
    const { patientCore } = this.props

    let primary_insurance = Array.isArray(patientCore?.insurance)
      ? patientCore?.insurance?.find((ins) => ins.preference === 1) || null
      : patientCore?.insurance || null

    let secondary_insurance = Array.isArray(patientCore?.insurance)
      ? patientCore?.insurance?.find((ins) => ins.preference === 2)
      : {}

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() => {
            this.setState({ showInsuranceModal: false })
            this.props.toggleSidebar(false)
          }}
        >
          X
        </p>
        <h4 style={{ color: values.primaryTheme, textAlign: 'center' }}>
          Insurance
        </h4>
        <PatientInsurance
          parentCallback={this.handleInsuranceCallback.bind(this)}
          primaryInsurance={primary_insurance}
          secondaryInsurance={secondary_insurance}
          fromLCFlow={true}
        />
      </div>
    )
  }

  renderAccountDetails() {
    const { emergencyContactPhone, emergencyContactName, phoneNumber } =
      this.state

    const { token, patient, patientCore } = this.props

    let insurance = Array.isArray(patientCore?.insurance)
      ? patientCore?.insurance?.find((ins) => ins.preference === 1) || null
      : patientCore?.insurance || null

    let frontImage
    if (this.state.frontUploaded) frontImage = this.state.frontLocation
    else frontImage = insurance?.front_image_url || null

    let backImage
    if (this.state.backUploaded) backImage = this.state.backLocation
    else backImage = insurance?.back_image_url || null

    // let frontImage
    // if (this.state.frontUploaded) frontImage = this.state.frontLocation
    // else frontImage = null

    // let backImage
    // if (this.state.backUploaded) backImage = this.state.backLocation
    // else backImage = null

    return (
      <div
        className={Styles.subContentWrapper}
        // style={{ width: '65%', padding: '20px 40px' }}
      >
        {/*  ADDRESS */}

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Address</p>
          <div className={Styles.rowAnswer}>
            <GooglePlacesAutocomplete
              apiKey={Config.GOOGLE_API_KEY}
              selectProps={{
                placeholder: this.state.address || 'Select Address',
                value: this.state.address,
                onChange: (picked = null) => {
                  //get ADDRESS COMPONENT
                  geocodeByAddress(picked.label)
                    .then((details) => {
                      // 'details' is provided when fetchDetails = true
                      const zip = details[0]?.address_components.find(
                        (addressComponent) =>
                          addressComponent.types.includes('postal_code'),
                      )?.short_name
                      const address = picked.value.description || ''
                      this.setState({ address: address, postalCode: zip })

                      const addressComponents =
                        details && details[0].address_components
                      this.setState({ addressComponents: addressComponents })
                    })
                    .catch((error) => console.error(error))
                },
              }}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Apt, Floor</p>
          <div className={Styles.rowAnswer}>
            <input
              placeholder="Enter Apt, Floor"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={this.state.address2}
              onChange={(e) => {
                this.setState({ address2: e.target.value })
              }}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Zip Code</p>
          <div className={Styles.rowAnswer}>
            <input
              placeholder="Enter Zip Code"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={this.state.postalCode}
              onChange={(e) => {
                this.setState({ postalCode: e.target.value })
              }}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>State</p>
          <div className={Styles.rowAnswer}>
            <Select
              placeholder="State"
              inputId="stateInput"
              options={Metadata.stateResidence}
              value={this.state.state_residence}
              isSearchable
              autoBlur
              onChange={(val) => this.setState({ state_residence: val })}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <p style={{ borderBottom: '1px solid #d0d0d0', width: '80%' }}></p>
        </div>

        {/*INSURANCE */}

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Insurance Provider</p>
          <div className={Styles.rowAnswer} style={{ display: 'flex' }}>
            <input
              placeholder="Edit Insurance"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              disabled={true}
              value={insurance?.insurance_company?.insurance_name}
              // onChange={(e) => {
              //   this.setState({ insurance_provider: e.target.value })
              // }}
            />
            <FontAwesomeIcon
              onClick={() => {
                this.props.toggleSidebar(true)
                this.setState({ showInsuranceModal: true })
              }}
              icon={faEdit}
              style={{
                color: values.primaryTheme,
                fontSize: 20,
                marginLeft: 10,
                cursor: 'pointer',
              }}
            />
          </div>
        </div>

        {/* <div className={Styles.row}>
          <p className={Styles.rowLabel}>Insurance Plan Type</p>
          <div className={Styles.rowAnswer}>
            <input
              placeholder="Enter Insurance Plan Type"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={this.state.plan_type}
              onChange={(e) => {
                this.setState({ plan_type: e.target.value })
              }}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Member Name</p>
          <div className={Styles.rowAnswer}>
            <input
              placeholder="Enter Member Name"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={this.state.member_name}
              onChange={(e) => {
                this.setState({ member_name: e.target.value })
              }}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Subscriber ID</p>
          <div className={Styles.rowAnswer}>
            <input
              placeholder="Enter Subscriber ID"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={this.state.subscriber_id}
              onChange={(e) => {
                this.setState({ subscriber_id: e.target.value })
              }}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Group Number</p>
          <div className={Styles.rowAnswer}>
            <input
              placeholder="Enter Group Number"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={this.state.group_number}
              onChange={(e) => {
                this.setState({ group_number: e.target.value })
              }}
            />
          </div>
        </div> */}

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Insurance Card Image</p>
          <div style={{ width: '50%', display: 'flex', flexDirection: 'row' }}>
            <div
              style={{
                width: '50%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {frontImage && (
                <img
                  src={frontImage}
                  style={{ height: '100%', width: '90%', objectFit: 'cover' }}
                  alt="No Image"
                />
              )}

              {/* <input
                onChange={(e) => {
                  this.setState({ insuranceFront: true })
                  this.onImageChange(e)
                }}
                type="file"
                id="file"
                ref={this.frontImageRef}
                accept="image/*"
                style={{ display: 'none' }}
                multiple={false}
              />
              <p
                onClick={() => this.frontImageRef.current.click()}
                style={{
                  textDecoration: 'underline',
                  color: 'gray',
                  cursor: 'pointer',
                }}
              >
                Upload Front
              </p> */}
            </div>
            <div
              style={{
                width: '50%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {backImage && (
                <img
                  src={backImage}
                  style={{ height: '100%', width: '90%', objectFit: 'cover' }}
                  alt="No Image"
                />
              )}

              {/* <input
                onChange={(e) => {
                  this.setState({ insuranceBack: true })
                  this.onImageChange(e)
                }}
                type="file"
                id="file"
                ref={this.backImageRef}
                accept="image/*"
                style={{ display: 'none' }}
                multiple={false}
              />
              <p
                onClick={() => this.backImageRef.current.click()}
                style={{
                  textDecoration: 'underline',
                  color: 'gray',
                  cursor: 'pointer',
                }}
              >
                Upload Back
              </p> */}
            </div>
          </div>
        </div>

        <div className={Styles.row}>
          <p style={{ borderBottom: '1px solid #d0d0d0', width: '80%' }}></p>
        </div>

        <div className={Styles.row} style={{}}>
          <p className={Styles.rowLabel}>Preferred Pharmacy</p>
          <div className={Styles.rowAnswer}>
            <input
              placeholder="Search Zip Code"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={
                // patientPharmacy
                //   ? patientPharmacy?.name?.[1] +
                //     `, ${patientPharmacy?.address_1}` +
                //     `, ${patientPharmacy?.city}`
                //   :
                this.state.zip
              }
              onChange={(e) => {
                this.setState({ zip: e.target.value }, () => {
                  this.onSearch()
                })
                this.onSearch.bind(this)
              }}
            />
          </div>
        </div>

        <div className={Styles.row} style={{}}>
          <p className={Styles.rowLabel}></p>
          <div className={Styles.rowAnswer}>
            <Select
              inputId="pharmacyZip"
              placeholder="Please select pharmacy"
              options={this.state.pharmacyList}
              value={this.state.pharma}
              isSearchable
              autoBlur
              onChange={(val) => {
                this.setState({ pharma: val })
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>If not found</p>
          <div className={Styles.rowAnswer}>
            <input
              placeholder="Please enter "
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={this.state.pharmacy_others}
              onChange={(e) => {
                this.setState({ pharmacy_others: e.target.value })
              }}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <p style={{ borderBottom: '1px solid #d0d0d0', width: '80%' }}></p>
        </div>

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Emergency Contact Name</p>
          <div className={Styles.rowAnswer}>
            <input
              placeholder="Enter emergency contact name"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={emergencyContactName}
              onChange={(e) => {
                this.setState({ emergencyContactName: e.target.value })
              }}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Emergency Contact Number</p>
          <div className={Styles.rowAnswer}>
            <input
              placeholder="Enter emergency contact number"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={emergencyContactPhone}
              onChange={(e) => {
                this.setState({ emergencyContactPhone: e.target.value })
              }}
            />
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div
        className={GlobalStyles.container}
        style={{
          overflow: 'hidden',
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <p
          style={{
            fontSize: '30px',
            color: values.primaryThemeDark,
            textAlign: 'center',
            fontWeight: 'bold',
            fontFamily: 'Avenir',
            wordWrap: 'break-word',
            marginTop: '4vh',
          }}
        >
          Now let's get a few more details to book your appointment
          <br />
          and personalize your care
        </p>

        {this.state.loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              minWidth: '100%',
              height: 400,
              alignItems: 'center',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        ) : (
          <div className={GlobalStyles.contentWrapper}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              {this.renderAccountDetails()}
            </div>
            {this.state.showInsuranceModal && (
              <Modal
                ariaHideApp={false}
                onRequestClose={() => {
                  this.setState({ showInsuranceModal: false })
                  this.props.toggleSidebar(false)
                }}
                isOpen={this.state.showInsuranceModal}
                style={paymentModalStyles}
                contentLabel="Modal"
              >
                {this.renderInsuranceModal()}
              </Modal>
            )}

            <div
              style={{
                width: '100%',
                marginTop: 60,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {this.state.error && (
                <p
                  style={{
                    marginTop: 4,
                    marginBottom: 4,
                    fontSize: 14,
                    color: 'red',
                  }}
                >
                  {this.state.errorString}
                </p>
              )}
              <Button
                onClick={this.onSavePressed.bind(this)}
                className={GlobalStyles.button}
                disabled={this.state.loading}
                style={{ width: 200 }}
                variant="primary"
              >
                Save
              </Button>
            </div>
          </div>
        )}
        <Footer />
      </div>
    )
  }
}

const mapStateToProps = (state /* , ownProps */) => {
  return {
    patient: state.patientReducer.patient,
    token: state.authReducer.token,
    firebaseAuthUser: state.userReducer.firebaseAuthUser,
    patientCore: state.patientReducer.patientCore,
  }
}

const mapDispatchToProps = {
  addFirebaseUser,
  addFirebaseAuthUser,
  addPatient,
  fetchPatientCoreDate,
  toggleSidebar,
}

export default connect(mapStateToProps, mapDispatchToProps)(LongcovidIntake)
