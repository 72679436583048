import React, { Component } from 'react'
import GlobalStyles from '../styles/global.module.scss'
import Styles from './styles/Video.module.scss'
import Constants from '../../values.js'
import Config from '../../config'
import { ToastContainer, toast } from 'react-toastify'
import Modal from 'react-modal'
import BSModal from 'react-bootstrap/Modal'
import ModalDialog from 'react-bootstrap/ModalDialog'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faMicrophoneSlash,
  faVideoSlash,
  faPhone,
  faTrash,
  faPlusCircle,
  faUserMd,
} from '@fortawesome/free-solid-svg-icons'
import {
  addAppointmentData,
  addPatientLP,
  addRpmPatient,
  setPatientCoreData,
  updateSideNavBar,
  stopTimer,
  resetTimer,
  startTimer,
  addVirgilE3,
  addPatient,
  userLoggedIn,
  fetchPatientCoreDate,
  fetchPatientProgressData,
  fetchPatientWellnessScreening,
  fetchPatientCalculateProgressData,
  updatePatientData,
  addPatientList,
  addMongoUser,
  updateDoctorMongoUser,
  fetchPatientConditions,
  fetchPatientMedications,
  fetchPatientLabs,
  toggleVideoModal,
  toggleNotesModal,
  addTwilioToken,
} from '../../redux/actions'
import { connect } from 'react-redux'
import Header from '../../components/Header'
import NotesModal from './NotesModal'
import algoliasearch from 'algoliasearch/lite'
import {
  InstantSearch,
  SearchBox,
  Hits,
  RefinementList,
  InfiniteHits,
  connectStateResults,
} from 'react-instantsearch-dom'
import { Link } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Draggable from 'react-draggable'
import Select from 'react-select'
import AppointmentNotes from './VideoCarePlanNotes'
const searchClient = algoliasearch(
  Config.ALGOLIA_APP_ID,
  Config.ALGOLIA_API_KEY,
)
const TWILIO_VIDEO = require('twilio-video')
const axios = require('axios')
const PLACEHOLDER_IMAGE =
  'https://firebasestorage.googleapis.com/v0/b/aila-health-b469c.appspot.com/o/images%2Fplaceholder.png?alt=media&token=89bc348e-7ccd-4e35-a291-50c6e21a3b6a'
const windowWidth = window.innerWidth

const NOTES_MODAL_STYLES = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '40%',
    height: '50%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
  },
}

class DraggableModalDialog extends React.Component {
  render() {
    return (
      <Draggable handle=".modal-title">
        <ModalDialog {...this.props} />
      </Draggable>
    )
  }
}
class Video extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      appointmentData: null,
      patientData: null,
      conditionsData: [],
      roomName: null,
      localStreamCreated: false,
      videoCallJoined: false,
      videoCallRoom: null,
      audioMuted: false,
      videoMuted: false,
      hideLocalMedia: false,
      modalOpen: false,
      icd10: [],
      notes: '',
      notesSaved: [],
      addIcd10: {},
      addNotes: '',
      addCarePlan: '',
      addCpt: [],
      notesList: [],
      remoteTrackAvailable: false,
      showAddNotes: true,
      tracks: [],
      mentalHealthData: [],
      showFollowUpModal: false,
      followupData: null,
    }
    this.getConditionsData = this.getConditionsData.bind(this)
    this.getMedicationsData = this.getMedicationsData.bind(this)
    this.getMentalHealthData = this.getMentalHealthData.bind(this)
    // this.createLocalVideoStream = this.createLocalVideoStream.bind(this)
    this.fetchTwilioToken = this.fetchTwilioToken.bind(this)
    this.fetchPatientInformation = this.fetchPatientInformation.bind(this)
    this.showModal = this.showModal.bind(this)
    this.hideModal = this.hideModal.bind(this)
    this.saveNoShow = this.saveNoShow.bind(this)
    this.saveAppointmentStartTime = this.saveAppointmentStartTime.bind(this)
  }

  static getDerivedStateFromProps(props, state) {
    if (props.doctorPatientData && state.patientData && state.twilioToken)
      return { loading: false }

    return null
  }

  componentDidMount() {
    if (!this.props.loggedIn) {
      this.props.history.push('/login')
      return
    }

    this.fetchTwilioToken()
    this.fetchPatientInformation()
    this.getConditionsData()
    this.getMedicationsData()
    this.getMentalHealthData()
  }

  // componentWillUnmount() {
  //   // this?.state?.videoCallRoom?.disconnect()
  // }

  // componentDidUpdate() {
  //   if(!this.state.loading && !this.state.localStreamCreated)
  //     this.createLocalVideoStream()
  // }

  fetchPatientInformation() {
    let { token, doctorPatientData } = this.props
    console.log(doctorPatientData)
    if (!doctorPatientData) return
    let url =
      Config.BACKEND_URL +
      'users?fields=dob,height,weight,gender,language,ethnicity'

    axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: 'JWT ' + token,
        x_firebase_id: doctorPatientData.patient_id,
      },
    })
      .then((result) => {
        const data = result.data
        console.log('got patient profile data', data)
        this.setState({ patientData: data })
      })
      .catch((err) => {
        console.log('error when getting twilio token', err)
        if (
          window.confirm(
            'Something went wrong. If the problem persists, please contact support.',
          )
        ) {
          this.props.history.push('/appointments')
        } else {
          this.props.history.push('/appointments')
        }
      })
  }

  fetchTwilioToken() {
    let { token, mongoUser } = this.props
    let url = Config.BACKEND_URL + 'twilio/token'
    axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: 'JWT ' + token,
        x_firebase_id: mongoUser.firebase_id,
      },
    })
      .then((result) => {
        const { token } = result.data
        console.log('got twilio token')
        this.setState({ twilioToken: token })
        this.props.addTwilioToken(token)
      })
      .catch((err) => {
        console.log('error when getting twilio token', err)
        if (
          window.confirm(
            'Something went wrong. If the problem persists, please contact support.',
          )
        ) {
          this.props.history.push('/appointments')
        } else {
          this.props.history.push('/appointments')
        }
      })
  }

  // createLocalVideoStream() {
  //   let {twilioToken} = this.state
  //   if(!twilioToken) {
  //     console.log('missing twilio token')
  //     toast.error('Cannot call patient right now')
  //     return
  //   }
  //
  //   if(!TWILIO_VIDEO.isSupported) {
  //     console.log('not supported by browser')
  //     toast.error('Browser not supported')
  //     return
  //   }
  //
  //   this.setState({localStreamCreated: true})
  //   let self = this
  //   TWILIO_VIDEO.createLocalTracks({
  //     audio: true,
  //     video: { width: (windowWidth/4)},
  //   }).then(tracks => {
  //     console.log('created local track')
  //     const localMediaContainer = document.getElementById('local-media');
  //     tracks.forEach(track => {
  //       localMediaContainer.appendChild(track.attach());
  //     })
  //     self.setState({localStreamCreated: true, tracks: tracks})
  //   });
  // }

  onJoinCallPressed() {
    let self = this
    let { twilioToken } = this.state
    let { videoPatientId, videoCallJoined, videoCallRoom, tracks } = this.state
    // if(this.props.appointmentStarted) {
    //   //call already joined.. user wants to disconnect
    //   if(!videoCallRoom)
    //     return
    //
    //   console.log('disconnecting')
    //   videoCallRoom.disconnect()
    //   this.setState({ videoCallJoined: false, videoCallRoom: null})
    //   tracks && tracks.forEach((track) => track.stop())
    //   return
    // }

    let { mongoUser, appointmentData } = this.props
    if (!appointmentData || !appointmentData.patient_id) {
      toast.error('Cannot place the video call right now')
      return
    }

    console.log('appointment data', appointmentData)
    let roomName = `${mongoUser.firebase_id}-${appointmentData.patient_id}`
    TWILIO_VIDEO.connect(twilioToken, {
      name: roomName,
      automaticSubscription: true,
    }).then((room) => {
      console.log('Connected to Room "%s"', room.name)
      toast.success('Connected to room')
      self.setState({ videoCallJoined: true, videoCallRoom: room })

      room.participants.forEach(participantConnected)
      room.on('participantConnected', participantConnected)

      room.on('participantDisconnected', participantDisconnected)
      room.once('disconnected', (error) =>
        room.participants.forEach(participantDisconnected),
      )
    })

    function participantConnected(participant) {
      console.log('Participant "%s" connected', participant.identity)
      toast.info('participant connected')

      const div = document.getElementById('remote-media')

      participant.tracks.forEach((publication) => {
        console.log(
          'existing tracks of remote participant',
          JSON.stringify(publication),
        )
        if (publication.isSubscribed) {
          trackPublished(div, publication, participant)
        }

        publication.on('subscribed', (track) => {
          console.log('new subscribed here', track)
          trackPublished(div, publication, participant)
        })

        publication.on('unsubscribed', (track) => {
          console.log('unpublished bevarc')
          if (track.kind !== 'data') {
            track.detach().forEach((element) => element.remove())
            self.setState({ remoteTrackAvailable: false })
          }
        })
      })

      participant.on('trackPublished', (publication) => {
        console.log('track published by remote participant')
        trackPublished(div, publication, participant)
      })

      participant.on('trackUnpublished', (publication) => {
        console.log(
          `RemoteParticipant ${participant.identity} unpublished a RemoteTrack: ${publication}`,
        )
      })

      // document.body.appendChild(div);
    }

    function participantDisconnected(participant) {
      console.log('Participant "%s" disconnected', participant.identity)
      toast.info('participant disconnected')
    }

    function trackPublished(div, publication, participant) {
      console.log(
        `RemoteParticipant ${
          participant.identity
        } published a RemoteTrack: ${JSON.stringify(publication)}`,
      )
      if (publication.isSubscribed && publication.kind !== 'data') {
        console.log('we can attach', publication.kind)
        self.setState({ remoteTrackAvailable: true })
        div.appendChild(publication.track.attach())
      }
    }

    function appendTrack(div, track) {
      console.log('appending track to div')
      div.appendChild(track.attach())
    }

    function trackUnsubscribed(track) {
      console.log('removing track from div')
      // track.detach().forEach(element => element.remove());
    }
  }

  onMuteVideoPressed() {
    let { videoMuted, videoCallRoom } = this.state
    if (!videoCallRoom) return
    if (videoMuted) {
      //if its muted, unmute it
      videoCallRoom.localParticipant.videoTracks.forEach((publication) => {
        publication.track.enable()
      })
    } else {
      videoCallRoom.localParticipant.videoTracks.forEach((publication) => {
        publication.track.disable()
      })
    }

    this.setState({ videoMuted: !videoMuted })
  }

  onMuteAudioPressed() {
    let { audioMuted, videoCallRoom } = this.state
    if (!videoCallRoom) return
    if (audioMuted) {
      //if its muted, unmute it
      videoCallRoom.localParticipant.audioTracks.forEach((publication) => {
        publication.track.enable()
      })
    } else {
      videoCallRoom.localParticipant.audioTracks.forEach((publication) => {
        publication.track.disable()
      })
    }
    this.setState({ audioMuted: !audioMuted })
  }

  getConditionsData() {
    let { token, doctorPatientData } = this.props
    let url = Config.BACKEND_URL + 'user/conditions'
    let url1 =
      Config.BACKEND_URL +
      'fhir/Condition?patient=' +
      doctorPatientData.patient_id
    let self = this
    let userConditions = []
    let providerConditions = []
    console.log(url1)
    axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: 'JWT ' + token,
        x_firebase_id: doctorPatientData.patient_id,
      },
    })
      .then((response) => {
        console.log('got user conditions data')
        userConditions = response.data
        self.setState({
          conditionsData: userConditions.concat(providerConditions),
        })
        // self.setState({ conditionsData: response.data })
      })
      .catch((err) => {
        console.log('error when getting conditions data', err)
        toast.error('Something went wrong')
      })

    axios({
      method: 'get',
      url: url1,
      headers: {
        x_firebase_id: doctorPatientData.patient_id,
        Authorization: 'JWT ' + token,
      },
    })
      .then((response) => {
        console.log('got prov conditions data')
        providerConditions = response.data
        self.setState({
          conditionsData: userConditions.concat(providerConditions),
        })
        // self.setState({ conditionsData: response.data })
      })
      .catch((err) => {
        console.log('error when getting conditions data', err)
        toast.error('Something went wrong')
      })
  }

  getMentalHealthData() {
    let { token, doctorPatientData } = this.props
    let url = Config.BACKEND_URL + 'screenings/mental'
    let self = this
    axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: 'JWT ' + token,
        x_firebase_id: doctorPatientData.patient_id,
        complete: true
      },
    })
      .then(({data}) => {
        console.log('got user conditions data')
        self.setState({
          mentalHealthData: data || [],
        })
        // self.setState({ conditionsData: response.data })
      })
      .catch((err) => {
        console.log('error when getting conditions data', err)
        toast.error('Something went wrong')
      })
  }

  getMedicationsData() {
    let { token, doctorPatientData } = this.props
    let url = Config.BACKEND_URL + 'user/medications'
    let url1 =
      Config.BACKEND_URL +
      'fhir/MedicationRequest?patient=' +
      doctorPatientData.patient_id
    let self = this

    let userMeds = []
    let providerMeds = []

    axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: 'JWT ' + token,
        x_firebase_id: doctorPatientData.patient_id,
      },
    })
      .then((response) => {
        console.log('got user meds data')
        userMeds = response.data && response.data.active_medications
        self.setState({ medicationsData: userMeds?.concat(providerMeds) })
        // self.setState({ conditionsData: response.data })
      })
      .catch((err) => {
        console.log('error when getting user meds data', err)
        // toast.error('Something went wrong')
      })

    axios({
      method: 'get',
      url: url1,
      headers: {
        Authorization: 'JWT ' + token,
        x_firebase_id: doctorPatientData.patient_id,
      },
    })
      .then((response) => {
        console.log('got prov meds data')
        providerMeds =
          response.data && response.data.active_medications_provider
        self.setState({ medicationsData: providerMeds.concat(userMeds) })
        // self.setState({ conditionsData: response.data })
      })
      .catch((err) => {
        console.log('error when getting prov meds data', err)
        // toast.error('Something went wrong')
      })
  }

  getAppointmentData() {
    let { token, patient, mongoUser } = this.props
    let url = Config.BACKEND_URL + 'providers/patients/appointments'
    let self = this
    axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: 'JWT ' + token,
        x_doctor_id: mongoUser.firebase_id,
        x_patient_id: patient.uid,
      },
    })
      .then((response) => {
        self.setState({ appointmentData: response.data })
      })
      .catch((err) => {
        console.log('error when getting appointment information', err)
        toast.error('Something went wrong')
      })
  }

  showModal = () => {
    this.setState({ modalOpen: false, showAddNotes: true })
  }

  hideModal = () => {
    this.setState({ modalOpen: false, showAddNotes: false })
  }

  saveAppointmentStartTime() {
    const {token, appointmentData} = this.props
    if(appointmentData.event_id)
    axios({
      method: 'put',
      url: `${Config.BACKEND_URL}appointment`,
      headers: {
        Authorization: `JWT ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        event_id: appointmentData.event_id,
        visit_start_time: moment().valueOf(),
        visit_end_time: null
      },
    })
      .then(() => {
        console.log('updated visit start time', appointmentData.event_id, moment().valueOf())
      })
      .catch((err) => {
        console.log('error when saving visit start time', err)
      })
  }

  navigatetoCareplan(p_id) {
    let { mongoUser, token, adminId } = this.props
    let { patientList } = this.props
    if (!patientList) return

    Object.values(patientList).forEach((colorCodedPatients, index) => {
      if (colorCodedPatients.length > 0) {
        let result = colorCodedPatients.filter(
          (patient) => patient.uid === p_id,
        )
        console.log('RESUKT ARRAY : ', result)
        result.forEach((patient) => {
          let colorcode = Object.keys(patientList)[index]
          if (patient.uid === p_id) {
            this.props.updatePatientData(
              {
                doctor_id: adminId,
                patient_id: patient.uid,
                last_reviewed: new Date(),
              },
              token,
            )

            //if the doctor was already on on another patient page and just clicked on a new patient, reset time
            if (this.props.patientTimer && this.props.patientTimer !== 0)
              this.props.resetTimer(patient.uid, adminId, token)
            else this.props.startTimer(patient.uid)
            let preferences = patient.preferences
            let timeline = 'complete'
            if (preferences && preferences.timeline) {
              timeline = preferences.timeline.value
            }
            this.props.fetchPatientProgressData(
              patient.uid,
              this.props.token,
              timeline,
            )
            this.props.fetchPatientCoreDate(patient.uid, this.props.token)
            this.props.fetchPatientWellnessScreening(
              patient.uid,
              this.props.token,
            )
            this.props.fetchPatientCalculateProgressData(
              patient.uid,
              this.props.token,
            )
            this.props.fetchPatientConditions(patient.uid, this.props.token)
            this.props.fetchPatientMedications(patient.uid, this.props.token)
            this.props.fetchPatientLabs(patient.uid, this.props.token)
            this.props.addPatient(patient, colorcode)
            this.props.updateSideNavBar(!this.props.updateRender)
          }
        })
      }
    })
  }

  onPatientNavLinkClicked(lp) {
    this.props.addPatientLP(lp)
    console.log('PATIENT LANDING PAGE: ', lp)
  }

  renderPatientMedicalData() {
    function removeComma(text) {
      return text?.replace(',', '')
    }

    let { doctorPatientData, appointmentData } = this.props
    let { conditionsData, medicationsData, notesSaved } = this.state
    let symptoms =
      appointmentData && appointmentData.symptoms
        ? appointmentData.symptoms
        : []
    let symptomsElement = symptoms.map((x) => (
      <p style={{ fontSize: 12 }}>
        {x.name}, {x.days} days, {x.severity}/10 severity{' '}
      </p>
    ))
    let conditionsElement =
      conditionsData &&
      conditionsData.map((x, index) => (
        <span
          key={index.toString()}
          style={{ textAlign: 'left', padding: '2px 4px' }}
        >
          {index + 1}
          {'. '}
          {removeComma(x.code ? x.code.coding[0].display : x.symptom)}{' '}
          {x.code && (
            <FontAwesomeIcon
              icon={faUserMd}
              style={{ color: Constants.primaryTheme, fontSize: 'larger' }}
            />
          )}
          {index !== conditionsData.length - 1 && ', '} <br />
        </span>
      ))

    let medicationsElement =
      medicationsData &&
      medicationsData.map((x, index) => (
        <span
          key={index.toString()}
          style={{ textAlign: 'left', padding: '2px 4px' }}
        >
          {index + 1}
          {'. '}
          {removeComma(x.name)}{' '}
          {x.doctor_id && (
            <FontAwesomeIcon
              icon={faUserMd}
              style={{ color: Constants.primaryTheme, fontSize: 'larger' }}
            />
          )}
          {index !== medicationsData.length - 1 && ', '}
          <br />
        </span>
      ))
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '20px 10px',
          backgroundColor: 'white',
          borderRadius: Constants.borderRadius,
          width: '100%',
          marginTop: 20,
          // height: '100%',
        }}
      >
        <div style={{ marginBottom: 6, width: '100%' }}>
          <p style={{ color: Constants.primaryTheme, fontWeight: 'bold' }}>
            Reason for visit
          </p>
          <p style={{ fontSize: 12 }}>
            {appointmentData && appointmentData.reason}
          </p>
        </div>

        <div style={{ marginBottom: 6, width: '100%' }}>
          <p style={{ color: Constants.primaryTheme, fontWeight: 'bold' }}>
            Positive screen for depression
          </p>
          <p style={{ fontSize: 12 }}>
            {appointmentData?.audio_result?.recorded
              ? appointmentData?.audio_result?.value?.toString().replace(/_/g, ' ').replace(/\b[a-z]/g, function(letter) {
                return letter.toUpperCase();})
              : 'N/A'}
          </p>
        </div>

        { appointmentData?.audio_result?.recorded &&
          <div style={{ marginBottom: 6, width: '100%' }}>
            <p style={{ color: Constants.primaryTheme, fontWeight: 'bold' }}>
              Mental Health (PHQ-9)
            </p>
            {
              this.state.mentalHealthData.map((data) =>
                <p style={{ fontSize: 12 }}>
                  {moment(data.created_at).format('ll')}: {data?.result?.message?.replace('You are', 'Patient is')} - score:{data.phq9_score}
                </p>
              )
              }
          </div>
        }

        <div style={{ marginBottom: 6, width: '100%' }}>
          <p style={{ color: Constants.primaryTheme, fontWeight: 'bold' }}>
            Symptoms
          </p>
          {symptomsElement}
        </div>

        <div style={{ marginBottom: 6, width: '100%' }}>
          <p style={{ color: Constants.primaryTheme, fontWeight: 'bold' }}>
            Conditions
          </p>
          <p style={{ fontSize: 12 }}>{conditionsElement}</p>
        </div>

        <div style={{ marginBottom: 6, width: '100%' }}>
          <p style={{ color: Constants.primaryTheme, fontWeight: 'bold' }}>
            Medications
          </p>
          <p style={{ fontSize: 12 }}>
            {medicationsElement}
            {/* {appointmentData && appointmentData.medications} */}
          </p>
        </div>
        <div style={{ marginBottom: 6, width: '100%' }}>
          <p style={{ color: Constants.primaryTheme, fontWeight: 'bold' }}>
            Treatment
          </p>
          <p style={{ fontSize: 12 }}>
            {appointmentData?.treatement?.length
              ? appointmentData?.treatement
              : 'N/A'}
          </p>
        </div>
        <div
          style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
        >
          <p style={{ color: Constants.primaryTheme, fontWeight: 'bold' }}>
            Notes
          </p>
          {notesSaved &&
            notesSaved.map((notes) => (
              <p style={{ fontSize: 12 }}>- {notes}</p>
            ))}
          <p 
            className={Styles.openNotesBtn} 
            onClick={() => {
              this.props.toggleNotesModal(true)
            }}
          >
            Add Notes
          </p>
        </div>
        <div
          style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
        >
          <p
            className={Styles.openNotesBtn}
            style={{ backgroundColor: Constants.primaryThemeDark }}
          >
            <Link
              style={{ color: '#FFF' }}
              onClick={() => {
                this.navigatetoCareplan(appointmentData.patient_id)
                this.onPatientNavLinkClicked('careplan')
                console.log('clicked')
              }}
              to={{
                pathname: '/patient/careplan',
                state: 'care-plan',
                key: 2,
              }}
            >
              Care Plan
            </Link>
          </p>
        </div>
      </div>
    )
  }

  renderBasicPatientInfo() {
    let { doctorPatientData } = this.props
    let { patientData } = this.state
    let heightInInches = patientData.height
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '10px 10px',
          backgroundColor: 'white',
          borderRadius: Constants.borderRadius,
          width: '100%',
        }}
      >
        <img
          src={doctorPatientData.profilePictureURL || PLACEHOLDER_IMAGE}
          style={{ height: 100, width: 100, borderRadius: '50%' }}
        />
        <Link
          onClick={() => {
            this.navigatetoCareplan(doctorPatientData.uid)
            this.onPatientNavLinkClicked('profile')
            console.log('clicked')
          }}
          to={{
            pathname: '/patient/profile',
            state: 'info',
            key: 2,
          }}
        >
          <p
            style={{
              color: Constants.primaryTheme,
              textAlign: 'center',
              fontWeight: 'bold',
            }}
          >
            {doctorPatientData.firstName + ' ' + doctorPatientData.lastName}
          </p>
        </Link>
        <div className={Styles.infoRow}>
          <p className={Styles.infoKey}>Age</p>
          <p className={Styles.infoValue}>
            {patientData.dob && moment().diff(moment(patientData.dob), 'years')}
          </p>
        </div>
        <div className={Styles.infoRow}>
          <p className={Styles.infoKey}>Gender</p>
          <p className={Styles.infoValue}>{patientData.gender}</p>
        </div>
        <div className={Styles.infoRow}>
          <p className={Styles.infoKey}>Height</p>
          {heightInInches && heightInInches > 0 && (
            <p className={Styles.infoValue}>
              {Math.floor(heightInInches / 12)} ft {heightInInches % 12} in
            </p>
          )}
        </div>
        <div className={Styles.infoRow}>
          <p className={Styles.infoKey}>Weight</p>
          <p className={Styles.infoValue}>{patientData.weight} lbs</p>
        </div>
        <div className={Styles.infoRow}>
          <p className={Styles.infoKey}>Ethnicity</p>
          <p className={Styles.infoValue}>{patientData.ethnicity}</p>
        </div>
        <div className={Styles.infoRow}>
          <p className={Styles.infoKey}>Language</p>
          <p className={Styles.infoValue}>{patientData.language}</p>
        </div>
      </div>
    )
  }

  isNumber = (text) => {
    if (typeof text != 'string') return false // we only process strings!
    return (
      !isNaN(text) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(text))
    ) // ...and ensure strings of whitespace fail
  }

  saveNoShow() {
    let { token, mongoUser, appointmentData } = this.props
    let { followupData } = this.state

    if (!followupData || !followupData?.follow_up_value) {
      toast.warning('Please select follow up time frame')
      return
    }

    console.log('FOLLOW UP DATA : ', {
      event_id: appointmentData.event_id,
      ...{ follow_up: followupData },
    })

    axios({
      method: 'put',
      url: `${Config.BACKEND_URL}appointment`,
      headers: {
        Authorization: `JWT ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        event_id: appointmentData.event_id,
        ...{ follow_up: followupData },
      },
    })
      .then((result) => {
        console.log('updated no show')
        toast.success('Saved Follow Up Note')
        this.setState({ followupData: null, showFollowUpModal: false })
        // this.setState({ twilioToken: token })
        // this.props.addTwilioToken(token)
      })
      .catch((err) => {
        console.log('error when saving no show data', err)
        toast.error('Cannot save Follow Up Data, please try again')
      })
  }

  renderModal() {
    let { mongoUser, appointmentData } = this.props

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          paddingTop: 20,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          // alignItems: 'center',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() =>
            this.setState({ followupData: null, showFollowUpModal: false })
          }
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Follow Up
        </h4>

        <div
          style={{
            width: '100%',
            marginBottom: '2%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <label style={{ color: '#20A892' }} class={Styles.checkContainer}>
            No Show
            <input
              type="checkbox"
              checked={this.state.followupData?.no_show || false}
              onClick={() => {
                // console.log('clocked')
                let temp = { ...this.state.followupData }
                temp.no_show = !this.state.followupData?.no_show
                this.setState({ followupData: temp })
              }}
            />
            <span class={Styles.checkmark}></span>
          </label>
        </div>

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Note to Patient</p>
          <div style={{ width: '70%' }}>
            <textarea
              placeholder="Enter note..."
              cols="20"
              rows="5"
              style={{
                width: '100%',
                borderBlockColor: 'lightgray',
                borderRadius: '5px',
                resize: 'none',
                borderColor: 'lightgray',
              }}
              value={this.state.followupData?.doctor_followup_note}
              onChange={(e) => {
                let temp = { ...this.state.followupData }
                temp.doctor_followup_note = e.target.value
                this.setState({ followupData: temp })
              }}
            ></textarea>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
          }}
        >
          {/* <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              margin: 20,
            }}
          > */}
          <p className={Styles.rowLabel}> Follow up in</p>
          <input
            placeholder={'Value'}
            className={Styles.textInput}
            style={{ width: '20%', marginRight: '1%' }}
            type="text"
            value={this.state.followupData?.follow_up_value}
            onChange={(e) => {
              let text = e.target.value
              let temp = { ...this.state.followupData }
              temp.follow_up_value = text
              if (this.isNumber(text)) this.setState({ followupData: temp })
            }}
          />
          {/* </div> */}

          {/* <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              margin: 20,
            }}
          > */}
          {/* <p
            style={{
              alignSelf: 'center',
              color: Constants.primaryTheme,
              fontWeight: 'bold',
              marginRight: 20,
            }}
          ></p> */}
          <Select
            options={[
              { label: 'Days', value: 'Days' },
              { label: 'Weeks', value: 'Weeks' },
              { label: 'Months', value: 'Months' },
            ]}
            className={'w-50'}
            value={this.state.followupData?.follow_up_unit}
            autoBlur={true}
            onChange={(val) => {
              let temp = { ...this.state.followupData }
              temp.follow_up_unit = val
              this.setState({ followupData: temp })
            }}
          />
          {/* </div> */}
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            padding: 20,
            alignItems: 'center',
          }}
        >
          <Button
            onClick={() => this.saveNoShow()}
            className={GlobalStyles.button}
            style={{ width: 140 }}
            variant="primary"
          >
            Submit
          </Button>
        </div>
      </div>
    )
  }

  renderVideoContent() {
    let { doctorPatientData } = this.props
    return (
      <div
        className={Styles.videoWrapper}
        style={{ width: this.state.videoCallJoined ? '140%' : '100%' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            padding: 20,
          }}
        >
          {this.props.appointmentStarted ? (
            <div>
              <h5 style={{ color: Constants.primaryTheme }}>Visit Started</h5>
              {/* {moment().diff(
                moment(this.props.appointmentData?.start),
                'minutes',
              ) >= 15 && (
                <Button
                  onClick={() => this.setState({ showFollowUpModal: true })}
                  className={GlobalStyles.button}
                  style={{ width: 140, backgroundColor: 'red' }}
                  variant="primary"
                >
                  Follow Up
                </Button>
              )} */}
            </div>
          ) : (
            <Button
              onClick={() => {
                this.props.toggleVideoModal(true)
                console.log('APP: ', this.props.appointmentData)
                this.saveAppointmentStartTime()
                this.props.toggleNotesModal(true)
              }}
              className={GlobalStyles.button}
              style={{ width: 140 }}
              variant="primary"
            >
              Start Visit
            </Button>
          )}
        </div>

        {this.state.showAddNotes && (
          <div className={Styles.appointmentNotesContainer}>
            {/* <AppointmentNotes
              patientId={this.props?.doctorPatientData?.patient_id}
              hideNotes={() => this.setState({ showAddNotes: false })}
              getUserCarePlan={() => console.log('notes from video')}
              navigateFrom={'video'}
              carePlanNotesData={this.props.appointmentData}
            /> */}
          </div>
        )}
      </div>
    )
  }

  render() {
    if (this.state.loading) {
      return (
        <div
          className={GlobalStyles.container}
          style={{ overflow: 'hidden', maxHeight: '100vh' }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              height: '80vh',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        </div>
      )
    }
    return (
      <div
        className={GlobalStyles.container}
        style={{ overflowX: 'hidden', paddingBottom: '100px' }}
      >
        <Header header={'Video'} />

        <NotesModal
          modalIsOpen={this.state.modalOpen}
          hideNotesModal={this.hideModal}
          getUserCarePlan={() => console.log('notes from video')}
          patientId={this.props?.doctorPatientData?.patient_id}
          comingFrom={'visit_video'}
          selectedPatient={this.state.patientData}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            padding: 10,
          }}
        >
          <div
            className={Styles.patientInfoWrapper}
            style={{ display: this.state.videoCallJoined ? 'none' : 'flex' }}
          >
            {this.renderBasicPatientInfo()}
            {this.renderPatientMedicalData()}
          </div>
          {this.renderVideoContent()}
        </div>

        {/*MODAL TO JOIN APPOINTMENT */}

        <Modal
          ariaHideApp={false}
          onRequestClose={() =>
            this.setState({ followupData: null, showFollowUpModal: false })
          }
          isOpen={this.state.showFollowUpModal}
          style={NOTES_MODAL_STYLES}
          contentLabel="Modal"
        >
          {this.renderModal()}
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedIn: state.userReducer.loggedIn,
    token: state.authReducer.token,
    docFirebaseAuthUser: state.userReducer.firebaseAuthUser,
    mongoUser: state.userReducer.mongoUser,
    adminId: state.userReducer.adminId,
    patientList: state.patientReducer.patientList,
    doctorPatientData: state.appointmentReducer.patientData,
    appointmentData: state.appointmentReducer.appointmentData,
    patient: state.patientReducer.patient,
    appointmentStarted: state.genericReducer.showVideoModal,
  }
}

const mapDispatchToProps = {
  addPatientLP,
  updateSideNavBar,
  stopTimer,
  resetTimer,
  startTimer,
  addVirgilE3,
  updateDoctorMongoUser,
  addMongoUser,
  addPatient,
  userLoggedIn,
  fetchPatientCoreDate,
  fetchPatientProgressData,
  fetchPatientWellnessScreening,
  fetchPatientCalculateProgressData,
  updatePatientData,
  addPatientList,
  setPatientCoreData,
  addRpmPatient,
  fetchPatientConditions,
  fetchPatientMedications,
  fetchPatientLabs,
  toggleVideoModal,
  toggleNotesModal,
  addTwilioToken,
}

export default connect(mapStateToProps, mapDispatchToProps)(Video)
