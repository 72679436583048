import React, {useState} from 'react'
import Styles from './styles/AilaWebQues.module.scss';
import GlobalStyles from '../styles/global.module.scss'
import values from '../../values';
import Button from 'react-bootstrap/Button';
import FancyField from 'react-fancy-field';
import AilaLogo from '../../assets/images/aila_logo.png';
import Form from 'react-bootstrap/Form';
import Doctor from '../../assets/images/doc.svg'
import Config from "../../config";
import {Link} from 'react-router-dom'
import HowItWorks from '../../assets/images/howitworks.png'
import { InstantSearch, SearchBox, Hits, RefinementList, InfiniteHits, connectStateResults, Highlight } from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';

const searchClient = algoliasearch(
  Config.ALGOLIA_APP_ID,
  Config.ALGOLIA_API_KEY,
);

const condnClient = {
  search(requests) {
    if (requests[0].params.query === "") {
      return [];
    }
    return searchClient.search(requests);
  },
};

export default function AilaWebIntro() {
  const [newDisease, setNewDisease] = useState('');
  const [notInList, setNotList] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  console.log(searchQuery)
  const diseaseNames = [
    {symptom:'Rheumatoid arthritis', node: "D082480"},
    {symptom:'Multiple sclerosis', node: "D082446"},
    {symptom:'Ulcerative Colitis', node: "D082384"},
    {symptom:'Crohn’s disease', node: "D082360"},
    {symptom:'Scleroderma', node: "D082442"},
    {symptom:'Lupus', node: "D082482"},
    {symptom:'Psoriasis', node: "D011565"},
    {symptom:'Hashimoto’s thyroiditis', node: "D082425"},
  ]

  const Hit = (props) => {
    let {hit} = props
    return (
      <div
        style={{width: '100%', cursor:'pointer'}}
        onClick={() => {
          setNewDisease({symptom: hit.symptom, node: hit.node})
          setSearchQuery('')
        }}
      >
        {hit.symptom}
      </div>
    )
  };

  return (
    <div className={GlobalStyles.container} style={{overflow: 'hidden', margin: '0 auto', backgroundColor: 'white', display: 'flex', flexDirection: 'column'}}>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', marginTop: 50 }}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '50%'}}>
          <div style={{textAlign: 'center'}}>
            <img alt="stepping up" src={Doctor} style={{height: '30vh', width: '30vw'}}/>
          </div>
          <div style={{width: '80%', alignSelf: 'center', marginTop: 30, marginBottom: 30}}>
          <p style={{fontSize: 20, margin: 'auto', color: values.primaryThemeDark, fontFamily: 'Avenir-Regular', textAlign: 'center'}}>
            At Aila Health, we are working to make chronic illnesses visible and help bring personalized care to each and every patient. <br/><br/>
            We see patients share their experiences on social media all the time and there is so much insight that gets lost in the sea of comments and posts.<br/><br/>
            We want to organize your insights visually and share that with the chronic illness community so people can more quickly identify what treatments could work for them and help advocate for better research.
          </p>
          </div>
          <div style={{textAlign: 'center'}}>
            <img alt="how it works" src={HowItWorks} />
          </div>
        </div>
        <div style={{paddingBottom: 40}} className={Styles.selectWrapper}>
          <p style={{fontSize: 26,  marginTop: 40, marginBottom: 30, color: values.primaryThemeDark, fontFamily: 'Avenir-Regular', textDecoration: 'underline'}}>
            Select your condition
          </p>
          <ul className={Styles.listContainer}>
            {
              diseaseNames.map(diseaseName => {
                let path = {
                  pathname: `/wix/aila-questionnaire/${diseaseName.symptom}`,
                  diseaseInfo: diseaseName
                }
                return (
                  // redirect the user to the survey page after they click on the disease name
                  <Link to={path} style={{textDecoration: 'none'}}>
                    <li style={{cursor: 'pointer', fontSize: 18, color: '#637381', fontFamily: 'Avenir-Regular'}} className={Styles.listItem}>
                      {diseaseName.symptom}
                    </li>
                  </Link>
                )
              })
            }
          </ul>
          {
            // check if the disease is not present in the list user will have to enter the name in text box
            notInList ?
            (
              <>
                <Form.Group controlId="cronic condn" style={{margin: 10, marginLeft: 20, width: '40%'}}>
                  <InstantSearch 
                    searchClient={condnClient} 
                    indexName={'CONDITIONS'}
                    onSearchStateChange={({ query }) => {
                      setSearchQuery(query)
                    }}
                  >
                    <SearchBox/>
                    { (searchQuery && searchQuery.length !==0) &&
                      <div style={{overflow: 'auto', height: 180, marginTop: 20}}>
                        <RefinementList limit={5} />
                        <Hits hitComponent={(props) => Hit(props)} />
                      </div>
                    }
                  </InstantSearch>
                </Form.Group>
                {/* redirect user to survey screen after they click on the take survey button after entering the name */}
                <li style={{cursor: 'pointer', fontSize: 18, color: '#637381', fontFamily: 'Avenir-Regular'}} className={Styles.listItem}>
                  {newDisease.symptom}
                </li>
                <Link to={{pathname: `/wix/aila-questionnaire/${newDisease.symptom}`, diseaseInfo: newDisease}}>
                  <Button
                    className={GlobalStyles.button}
                    disabled={newDisease.length===0}
                    style={{marginTop: 40, width: 200}}
                    variant="primary"
                  >
                      Take Survey
                  </Button>
                </Link>
              </>
            )
            :
            (
              <p
                onClick={() => setNotList(true)}
                style={{fontSize: 26, marginTop: 30, color: values.primaryThemeDark, fontFamily: 'Avenir-Regular', textDecoration: 'underline', cursor: 'pointer'}}
              >
                Other Condition
              </p>
            )
          }
        </div>
      </div>
    </div>
  )
}
