import React, {useState, useEffect}from 'react'
import ReactTooltip from "react-tooltip";
import {
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
  ReferenceLine,
  ReferenceArea,
  AreaChart,
  Label,
  CartesianGrid,
  Area
} from "recharts";
import moment from 'moment';
import Styles from "./styles/styles.module.scss";
import Constants from '../values'
import {getQuestionnaireResults, getReadableToolTip} from "./getQuestionnaireResults";
import { getChartReferenceLines } from './getChartReferenceLines';

const QChartTooltip = ({data, path}) => {

  if(data.length === 0) {
    return null
  }

  return (
    <ReactTooltip id={path}  textColor='black' backgroundColor='white' effect='solid' className={Styles.hoverChatWrapper}>
      <div>
        <LineChart width={500} height={400} data={data?.reverse()} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
          <Legend verticalAlign="top"/>
          <Line name={getReadableToolTip(path)} type="monotone" dataKey={getQuestionnaireResults(path)} stroke={'#606060'} />
          {(path==='globalhealth') && <Line name={'Physical Score'} type="monotone" dataKey={'physical_score'} stroke={Constants.graphLineThree} />}
          {getChartReferenceLines && getChartReferenceLines(path)}
          <XAxis dataKey="created_at" stroke={'#000000cc'}  tickFormatter={(tickItem) => {
            return moment(tickItem).format('MMM-D')
          }}/>
          <YAxis stroke={'#000000cc'}/>
          <Tooltip />
        </LineChart>
      </div>
    </ReactTooltip>
  )
}

export default QChartTooltip;