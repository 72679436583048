import React, { useEffect, useState } from 'react'
import Constants from '../../../values.js'
import Styles from './styles/Careplan.module.scss'
import Config from '../../../config'
import GlobalStyles from '../../styles/global.module.scss'
import DocPlaceHolder from '../../../assets/images/docPlaceholder.png'
import Button from 'react-bootstrap/Button'
import { useSelector } from 'react-redux'
import moment from 'moment'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlusCircle,
  faPlay,
  faPause,
  faStop,
} from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-modal'
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify'

const manualAddModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '60vw',
    height: '80vh',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

export default function RpmMonitoringLog(props) {
  const token = useSelector((state) => state.authReducer.token)
  const patient = useSelector((state) => state.patientReducer.patient)
  const mongoUser = useSelector((state) => state.userReducer?.mongoUser)
  const [loading, setLoading] = useState(false)
  const [appointmentList, setAppointmentList] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [modalData, setModalData] = useState(null)
  const [counter, setCounter] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const [isPaused, setIsPaused] = useState(true)
  const [time, setTime] = useState(0)
  const [rpmOrders, setRpmOrders] = useState([])
  const [monitoringLogs, setMonitoringLogs] = useState([])
  const [totalTime, setTotalTime] = useState(0)

  useEffect(() => {
    getRmpOrders()
    getmonitoringLogs()
  }, [])

  useEffect(() => {
    let interval = null

    if (isActive && isPaused === false) {
      interval = setInterval(() => {
        setTime((time) => time + 10)
      }, 10)
    } else {
      clearInterval(interval)
    }
    return () => {
      clearInterval(interval)
    }
  }, [isActive, isPaused])

  const getRmpOrders = () => {
    setLoading(true)
    const url = `${Config.BACKEND_URL}rpm/order?patient=${patient?.uid}`
    axios({
      method: 'get',
      url,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: patient?.uid,
      },
    })
      .then(({ data }) => {
        console.log('RPM : ', data)
        setLoading(false)
        if (data) {
          let devices = []
          data.forEach((item) => {
            item.lines.forEach((line) => {
              devices.push(line)
            })
          })
          console.log(devices)
          setRpmOrders(devices)
        }
      })
      .catch((err) => {
        console.log('error when getting lab orders', err)
      })
  }
  const getmonitoringLogs = () => {
    setLoading(true)
    const url = `${Config.BACKEND_URL}rpm/monitoring-log/?patient=${patient?.uid}`
    axios({
      method: 'get',
      url,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: patient?.uid,
      },
    })
      .then(({ data }) => {
        setLoading(false)
        if (data) {
          let total_time = 0
          console.log('Monitoring logs : ', data)
          setMonitoringLogs(data)
          data.forEach((item) => {
            total_time = total_time + item?.time
          })
          console.log(total_time)
          setTotalTime(total_time)
        }
      })
      .catch((err) => {
        console.log('error when getting lab orders', err)
      })
  }

  const onSubmitPressed = () => {
    setLoading(true)

    let data = {
      //RPM ORDER DETAILS
      // lines: rpmItems,
      // order_id: '',
      patient_id: patient?.uid,
      //PROVIDER INFORMATION
      provider_id: mongoUser?.firebase_id,
      provider_signature: mongoUser?.sign_image,
      time: time,
      ...modalData,
    }
    console.log(data)

    let config = {
      method: 'post',
      url: Config.BACKEND_URL + `rpm/monitoring-log`,

      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: mongoUser?.firebase_id,
      },
      data: data,
    }
    axios(config)
      .then(({ data }) => {
        console.log('Monitoring Log saved', data)
        toast.success('Monitoring Log saved')
        getmonitoringLogs()
        setLoading(false)
        setModalData(null)
        setIsActive(false)
        setIsPaused(true)
        setModalOpen(false)
        setTime(0)
      })
      .catch((err) => {
        setLoading(false)

        console.log('Error ordering RPM for the Patient ', err)
        toast.error('Could not place RPM Order, please try after sometime')
      })
  }

  const handleStart = () => {
    setIsActive(true)
    setIsPaused(false)
  }

  const handlePauseResume = () => {
    setIsPaused(!isPaused)
  }

  const handleReset = () => {
    setIsActive(false)
    setTime(0)
  }

  const renderMonitoringModal = () => {
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p className={Styles.closeModalBtn} onClick={() => setModalOpen(false)}>
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          RPM Monitoring Log
        </h4>

        <div
          style={{
            // height: '70vh',
            width: '100%',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            overflowY: 'scroll',
            overflow: 'auto',
          }}
        >
          <div className={Styles.addManualRow}>
            <p className={Styles.addManualQuestion}>Activity Time </p>
            {isActive ? (
              <FontAwesomeIcon
                onClick={handlePauseResume}
                icon={isPaused ? faPlay : faPause}
                style={{
                  color: Constants.primaryTheme,
                  fontSize: 20,
                  // position: 'absolute',
                  right: 5,
                  top: 5,
                  cursor: 'pointer',
                }}
              />
            ) : (
              // </>
              <FontAwesomeIcon
                onClick={handleStart}
                icon={faPlay}
                style={{
                  color: Constants.primaryTheme,
                  fontSize: 20,
                  // position: 'absolute',
                  right: 5,
                  top: 5,
                  cursor: 'pointer',
                }}
              />
            )}

            <p className={Styles.addManualQuestion}>
              {/* <span className="digits"> */}
              {('0' + Math.floor((time / 60000) % 60)).slice(-2)}:
              {/* </span> */}
              {/* <span className="digits">   */}
              {('0' + Math.floor((time / 1000) % 60)).slice(-2)}.{/* </span> */}
              {/* <span className="digits mili-sec"> */}
              {('0' + ((time / 10) % 100)).slice(-2)}
              {/* </span> */}
              {/* {pad(parseInt(currentCount / 60))}:{pad(currentCount % 60)} */}
            </p>
          </div>

          <div className={Styles.addManualRow}>
            <p className={Styles.addManualQuestion}>Date </p>
            <input
              placeholder={'Please enter estimated or due date'}
              className={Styles.addManualTextInput}
              type="date"
              min={moment(new Date()).format('YYYY-MM-DD')}
              value={moment(modalData?.date).format('YYYY-MM-DD')}
              onChange={(e) => {
                let temp = { ...modalData }
                temp.date = e.target.value
                setModalData(temp)
              }}
            />
          </div>
          <div className={Styles.addManualRow}>
            <p className={Styles.addManualQuestion}>Program Type </p>
            <Select
              options={[
                { label: 'CCT', value: 'CCT' },
                { label: 'RPM', value: 'RPM' },
                { label: 'PCM', value: 'PCM' },
                { label: 'RTM', value: 'RTM' },
              ]}
              className={Styles.addSelectInput}
              autoBlur={true}
              onChange={(val) => {
                let temp = { ...modalData }
                temp.program_type = val
                setModalData(temp)
              }}
              value={modalData?.program_type}
              getOptionValue={(option) => option.label}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
          <div className={Styles.addManualRow}>
            <p className={Styles.addManualQuestion}>Rendering Provider </p>
            {/* <div style={{ width: '60%', margin: '0px 20px' }}> */}
            <input
              placeholder={'Physician'}
              className={Styles.addManualTextInput}
              type="text"
              value={mongoUser?.first_name + ' ' + mongoUser?.last_name}
              disabled={true}
            />
            {/* </div> */}
          </div>
          <div className={Styles.addManualRow}>
            <p className={Styles.addManualQuestion}>Monitor Name</p>
            {/* <div style={{ width: '60%', margin: '0px 20px' }}> */}
            <input
              placeholder={'Monitor'}
              className={Styles.addManualTextInput}
              type="text"
              value={mongoUser?.first_name + ' ' + mongoUser?.last_name}
              disabled={true}
            />
            {/* </div> */}
          </div>

          <div className={Styles.addManualRow}>
            <p className={Styles.addManualQuestion}>Interaction Type </p>
            <Select
              options={[
                { label: 'Monitoring', value: 'Monitoring' },
                { label: 'Email', value: 'Email' },
                { label: 'Text', value: 'Text' },
                { label: 'Phone Call', value: 'Phone Call' },
                { label: 'Video Visit', value: 'Video Visit' },
              ]}
              className={Styles.addSelectInput}
              autoBlur={true}
              onChange={(val) => {
                let temp = { ...modalData }
                temp.interaction_type = val
                setModalData(temp)
              }}
              value={modalData?.interaction_type}
              getOptionValue={(option) => option.label}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>

          <div className={Styles.addManualRow}>
            <p className={Styles.addManualQuestion}>Monitor Notes </p>
            <textarea
              placeholder={'Please enter notes'}
              style={{
                border: '1px solid #d0d0d0',
                padding: '5px 20px',
                borderRadius: '4px',
                width: '70%',
                // height: '40px',
              }}
              rows="4"
              type="text"
              value={modalData?.notes || ''}
              onChange={(e) => {
                let temp = { ...modalData }
                temp.notes = e.target.value
                setModalData(temp)
              }}
            />
          </div>
        </div>

        {/* <PatientAccount fromProvider={true} /> */}
        <Button
          onClick={() => onSubmitPressed()}
          className={GlobalStyles.button}
          style={{ width: 140, marginTop: '5%' }}
          variant="primary"
        >
          Save
        </Button>
      </div>
    )
  }

  const renderLogs = () => {
    const toReturn = []
    monitoringLogs.forEach((log, index) => {
      toReturn.push(
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 40,
            borderColor: 'gray',
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            paddingBottom: 10,
          }}
        >
          <div style={{ color: 'gray', width: '10%', textAlign: 'center' }}>
            <p>{moment(log.created_at).format('MMM Do YYYY')}</p>
          </div>
          <div style={{ color: 'gray', width: '15%', textAlign: 'center' }}>
            <p>{log.program_type.label}</p>
          </div>
          <div style={{ color: 'gray', width: '20%', textAlign: 'center' }}>
            <p>Rendering Provider</p>
          </div>
          <div style={{ color: 'gray', width: '15%', textAlign: 'center' }}>
            <p>Monitor</p>
          </div>
          <div style={{ color: 'gray', width: '10%', textAlign: 'center' }}>
            <p>
              {('0' + Math.floor((log.time / 60000) % 60)).slice(-2)}:
              {('0' + Math.floor((log.time / 1000) % 60)).slice(-2)}.
              {('0' + ((log.time / 10) % 100)).slice(-2)}
            </p>
          </div>
          <div style={{ color: 'gray', width: '20%', textAlign: 'center' }}>
            <p>Patient Engagement</p>
          </div>
          <div style={{ color: 'gray', width: '20%', textAlign: 'center' }}>
            <p>{log.notes}</p>
          </div>
        </div>,
      )
    })

    return toReturn
  }

  return (
    <div style={{ width: '100%', marginTop: 40 }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <h5 style={{ color: Constants.primaryTheme }}>RPM Monitoring</h5>
        <FontAwesomeIcon
          onClick={() => setModalOpen(true)}
          icon={faPlusCircle}
          style={{
            color: Constants.primaryTheme,
            fontSize: 18,
            position: 'absolute',
            right: 5,
            top: 10,
            cursor: 'pointer',
          }}
        />
      </div>
      <div
        className={Styles.contentBackground}
        style={{ height: 500, overflowY: 'scroll' }}
      >
        <div
          className={Styles.contentRow}
          style={{ flexDirection: 'column', alignItems: 'flex-start' }}
        >
          <div
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <h6 style={{ color: Constants.primaryTheme, whiteSpace: 'pre' }}>
              Monitoring Devices :{' '}
              {rpmOrders.map((item) => item.line_name).join(',\r\n')}
              <br />
              <br />
              Time Logged this Month:{' '}
              {('0' + Math.floor((totalTime / 60000) % 60)).slice(-2)}:
              {('0' + Math.floor((totalTime / 1000) % 60)).slice(-2)}.
              {('0' + ((totalTime / 10) % 100)).slice(-2)}
            </h6>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 40,
            borderColor: 'gray',
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            paddingBottom: 10,
          }}
        >
          <div style={{ color: 'gray', width: '10%', textAlign: 'center' }}>
            <p>Date</p>
          </div>
          <div style={{ color: 'gray', width: '15%', textAlign: 'center' }}>
            <p>Program</p>
          </div>
          <div style={{ color: 'gray', width: '20%', textAlign: 'center' }}>
            <p>Rendering Provider</p>
          </div>
          <div style={{ color: 'gray', width: '15%', textAlign: 'center' }}>
            <p>Monitor</p>
          </div>
          <div style={{ color: 'gray', width: '10%', textAlign: 'center' }}>
            <p>Time Spent</p>
          </div>
          <div style={{ color: 'gray', width: '20%', textAlign: 'center' }}>
            <p>Patient Engagement</p>
          </div>
          <div style={{ color: 'gray', width: '20%', textAlign: 'center' }}>
            <p>Monitoring Note</p>
          </div>
        </div>
        <div style={{ height: '80vh', overflowY: 'auto' }}>{renderLogs()}</div>
      </div>

      <Modal
        ariaHideApp={false}
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
        style={manualAddModalStyles}
        contentLabel="Modal"
      >
        {renderMonitoringModal()}
      </Modal>
    </div>
  )
}
