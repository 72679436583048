import React, { useState, useEffect } from 'react'
import GlobalStyles from '../styles/global.module.scss'
import Styles from './styles/Referrals.module.scss'
import Constants from '../../values.js'
import { connect, useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import ReactTooltip from 'react-tooltip'
import Select from 'react-select'
import moment from 'moment'
import Modal from 'react-modal'
import DateTimePicker from 'react-datetime-picker'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import algoliasearch from 'algoliasearch/lite'
import DatePicker from 'react-date-picker'
import DocPlaceHolder from '../../assets/images/docPlaceholder.png'
import Form from 'react-bootstrap/Form'
import { useHistory } from 'react-router'
import Fuse from 'fuse.js'

import {
  InstantSearch,
  SearchBox,
  Hits,
  RefinementList,
  InfiniteHits,
  connectStateResults,
  connectSearchBox,
  connectCurrentRefinements,
} from 'react-instantsearch-dom'
import Config from '../../config'
import PatientInfoStrip from '../../components/PatientInfoStrip'
import Header from '../../components/Header'
import { FaUserFriends } from 'react-icons/fa'
import {
  faClipboardList,
  faCapsules,
  faFlask,
  faUserFriends,
  faStethoscope,
  faPlusCircle,
  faTrash,
  faUserMd,
  faSearch,
} from '@fortawesome/free-solid-svg-icons'
import {
  stopTimer,
  updateSideNavBar,
  addRpmPatient,
  addPatientLP,
  getUnreadMessages,
  resetTimer,
  startTimer,
  addVirgilE3,
  updateDoctorMongoUser,
  addMongoUser,
  addPatient,
  userLoggedIn,
  fetchPatientCoreDate,
  fetchPatientProgressData,
  fetchPatientWellnessScreening,
  fetchPatientCalculateProgressData,
  updatePatientData,
  addPatientList,
  setPatientCoreData,
  fetchPatientConditions,
  fetchPatientMedications,
  fetchPatientLabs,
} from '../../redux/actions'
import { Link } from 'react-router-dom'
import ORDER_IMG from '../../assets/images/order_receipt.png'
import styles from '../Admin/styles/admin.module.scss'
import hl7parser from 'hl7parser'
import metadata, { SPECIALITY_OPTIONS } from '../../metadata.js'
import Handlebars from 'handlebars'
import htmlModule1 from '../../helpers/PdfTemplates/ProviderReferral.txt'
import htmlModule2 from '../../helpers/PdfTemplates/ProviderReferralInfusion.txt'
import htmlModule3 from '../../helpers/PdfTemplates/ProviderReferralImaging.txt'
import Axios from 'axios'
import { data } from 'branch-sdk'
const axios = require('axios')

const ORDER_STATUSES = {
  C: 'Corrected',
  E: 'Entered',
  F: 'Final Reported',
  I: 'Inactive',
  NA: 'Results Received',
  P: 'Partial Reported',
  R: 'Ready to Transmit',
  T: 'Transmitted',
  TX: 'Transmission Error',
  X: 'Error',
}
const PLACEHOLDER_IMAGE =
  'https://firebasestorage.googleapis.com/v0/b/aila-health-b469c.appspot.com/o/images%2Fplaceholder.png?alt=media&token=89bc348e-7ccd-4e35-a291-50c6e21a3b6a'
let windowHeight = window.innerHeight
let windowWidth = window.innerWidth

const searchClient = algoliasearch(
  Config.ALGOLIA_APP_ID,
  Config.ALGOLIA_API_KEY,
)

const condnClient = {
  search(requests) {
    if (requests[0].params.query === '') {
      return []
    }
    return searchClient.search(requests)
  },
}

const eventFire = (el, etype) => {
  if (el.fireEvent) {
    el.fireEvent('on' + etype)
  } else {
    var evObj = document.createEvent('Events')
    evObj.initEvent(etype, true, false)
    el.dispatchEvent(evObj)
  }
}

const ClearQuery = ({ refine }) => {
  const onClick = (ev) => {
    refine('')
    ev.stopPropagation()
  }
  return (
    <div onClick={onClick} style={{ display: 'none' }}>
      <ConnectedClearAllRefinements />
    </div>
  )
}

const ClearAllRefinements = ({ refine, items }) => {
  const onClick = () => refine(items)
  return (
    <button id="clearQuery" onClick={onClick}>
      ClearAll
    </button>
  )
}

const ClearQueryAndRefinements = connectSearchBox(ClearQuery)
const ConnectedClearAllRefinements =
  connectCurrentRefinements(ClearAllRefinements)

const customPatientAssignModalStyle = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 10,
  },
  content: {
    zIndex: 10,
    width: '75%',
    height: '60%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const PROVIDERS = [
  {
    label: 'Alison Shmerling',
    value: '4015227895',
    clinicianId: 'iazlt523',
    clinicianPwd: 'iazlt523',
  },
]

const UNITS = [
  { label: 'Bag', value: 'Bag' },
  { label: 'Bar', value: 'Bar' },
  { label: 'Capsules', value: 'Capsules' },
  { label: 'Canister', value: 'Canister' },
  { label: 'Cartridge', value: 'Cartridge' },
  { label: 'Pills', value: 'Pills' },
  { label: 'Strip', value: 'Strip' },
  { label: 'Swab', value: 'Swab' },
  { label: 'Syringe', value: 'Syringe' },
  { label: 'Tube', value: 'Tube' },
  { label: 'Vial', value: 'Vial' },
]

const PHARMACIES = [
  {
    label: 'Bannockburn Pharmacy[6798 PYLE RD, BETHESDA (301)644-2418]',
    value: '1',
  },
  {
    label:
      'Shollenberger Pharmacy[2002 S. MCDOWELL BLVD EXT, PETALUMA (707)984-5571]',
    value: '2',
  },
]

const customDrugModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 10,
  },
  content: {
    zIndex: 10,
    width: '50%',
    height: '75%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const customModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 10,
  },
  content: {
    zIndex: 10,
    width: 800,
    height: 800,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const customChangeModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 10,
  },
  content: {
    zIndex: 10,
    width: '80%',
    height: '95%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const selectTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#20A89233',
    primary: '#20A892',
  },
})

const Referrals = (props) => {
  const patientCarePlanData = useSelector(
    (state) => state.patientReducer.patientCarePlanData,
  )
  const patientMedicationsData = useSelector(
    (state) => state.patientReducer.patientMedicationsData,
  )
  const patientConditionsData = useSelector(
    (state) => state.patientReducer.patientConditionsData,
  )

  const patientCore = useSelector((state) => state.patientReducer.patientCore)

  const [loading, setLoading] = useState(false)
  const [referralType, setReferralType] = useState(null)
  const [referringTo, setReferringTo] = useState('')
  const [speciality, setSpeciality] = useState(null)
  const [extraSpecialty, setExtraSpecialty] = useState('')
  const [labLocation, setLabLocation] = useState(null)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [faxNumber, setFaxNumber] = useState('')
  const [priority, setPriority] = useState(null)
  const [dateInfusion, setDateInfusion] = useState(null)
  const [orderType, setOrderType] = useState(null)
  const [medication, setMedication] = useState([])
  const [infusionDrug, setInfusionDrug] = useState(null)
  const [medicationQuery, setMedicationQuery] = useState('')
  const [dosage, setDosage] = useState('')
  const [units, setUnits] = useState('')
  const [frequency, setFrequency] = useState('')
  const [otherMeds, setOtherMeds] = useState('')
  const [accessType, setAccessType] = useState(null)
  const [reasonIcd, setReasonIcd] = useState('')
  const [notes, setNotes] = useState('')
  const [includePChart, setIncludePChart] = useState(false)

  const [showNext, setShowNext] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [pdfData, setPdfData] = useState('')
  const [saveReferralData, setSaveReferralData] = useState(null)
  const [icdCodes, setIcdCodes] = useState([])
  const [selectedNote, setSelectedNote] = useState([])
  const [allProviderList, setAllProviderList] = useState([])
  const [providerOptionList, setProviderOptionList] = useState([])

  //MEDS, ALLERGIES AND CONDITIONS DATA
  const [providerMeds, setProviderMeds] = useState([])
  const [providerConds, setProviderConds] = useState([])
  const [providerAllergies, setProviderAllergies] = useState([])
  const [sendAllergies, setSendAllergies] = useState(false)
  const [sendMeds, setSendMeds] = useState(false)
  const [sendConditions, setSendConditions] = useState(false)
  const [sendFamilyHistory, setSendFamilyHistory] = useState(false)

  //LAB ORDERS DATA
  const [sendLabs, setSendLabs] = useState(false)
  const [labOrdersFromBackend, setLabOrdersFromBackend] = useState(null)
  const [labOrdersFromChange, setLabOrdersFromChange] = useState(null)

  //IMAGING NEW FIELDS
  const [imagingStudy, setImagingStudy] = useState(null)
  const [bodySite, setBodySite] = useState([])
  const [bodysiteQuery, setBodysiteQuery] = useState(null)
  const [contrast, setContrast] = useState(null)
  const [literality, setLiterality] = useState(null)
  const [claustrophobic, setClaustrophobic] = useState(false)
  const [imagingMed, setImagingMed] = useState([
    {
      name: 'Oral Sedative (Medication given by referring provider',
      isChecked: false,
    },
    { name: `IV Sedation by RAD's RN`, isChecked: false },
    { name: 'General Anesthesia', isChecked: false },
    { name: 'Perform as ordered, DO NOT ALTER', isChecked: false },
    { name: 'OK to be altered per radiologist Discretion', isChecked: true },
  ])

  //INFUSION, IMAGING, DME SEARCH DATABASE
  const [showSearchList, setShowSearchList] = useState(false)
  const [searchName, setSearchName] = useState('')
  const [searchList, setSearchList] = useState([])
  const [facilityType, setFacilityType] = useState({
    label: 'DME',
    value: 'DME_DATABASE',
  })
  const [selectedProvider, setSelectedProvider] = useState([])

  const [adminAccess, setAdminAccess] = useState(false)
  const history = useHistory()

  let fuse = null

  useEffect(() => {
    console.log('PATIENT INFO : ', props?.patient)
    getUserCarePlan()
    getProviderMedications()
    getProviderConditions()
    getAllProviderData()
    getLabOrders()
    getLabOrdersFromChange()
    getProviderAllergies()
    if (props?.mongoUser?.user_type === 'Administrator' && props?.task_description) setAdminAccess(true)
    if (props?.referral_id) {
      console.log('Referrals ID:', props?.referral_id)
      getReferralsData()
    }
  }, [props?.patientId])

  const addMedication = (hit) => {
    let temp = [{ id: hit.AILA_ID, name: hit.DrugName }]
    setMedication(temp)
  }

  const getReferralsData = () => {
    let config = {
      method: 'get',
      url:
        Config.BACKEND_URL +
        `referrals/patient?referral_id=${props?.referral_id}`,
      headers: {
        Authorization: `JWT ${props.token}`,
        x_firebase_id: props?.patientId,
      },
    }

    axios(config)
      .then(({ data }) => {
        console.log('Got referral', data)
        setSaveReferralData(data)
        setReferralType({
          label: data?.referral_type,
          value: data?.referral_type,
        })
        if(allProviderList.length)
          setSelectedProvider({provider: allProviderList.find(x => x.firebase_id === data?.doctor_id)})
        setPriority(data?.priority)
        setSendMeds(data?.selected_meds)
        setSendAllergies(data?.selected_allergies)
        setSendConditions(data?.selected_conditions)
        setSendFamilyHistory(data?.selected_family_history)
        setSendLabs(data?.selected_Labs)
        setIcdCodes([{ code: data?.icd_codes, display: data?.icd_codes }])
        setReasonIcd(data?.reason)
        setNotes(data?.notes)
      })
      .catch((err) => {
        console.log('Error saving referral to backend', err)
        // toast.error('Could not save referral')
      })
  }

  const updateReferralinMongo = () => {
    let data = {
      // ...saveReferralData,
      referring_to: referringTo,
      phone: phoneNumber,
      fax_number: faxNumber,
      referral_sent: true,
    }
    let config = {
      method: 'put',
      url:
        Config.BACKEND_URL +
        `referrals/patient?referral_id=${props?.referral_id}`,

      headers: {
        Authorization: `JWT ${props.token}`,
        x_firebase_id: props?.patientId,
      },
      data: data,
    }

    axios(config)
      .then(({ data }) => {
        console.log('Updated referral in backend')
        history.push('/provider/home')
        setReferralType(null)
        setReferringTo('')
        setSpeciality(null)
        setPhoneNumber('')
        setFaxNumber('')
        setDateInfusion(null)
        setMedication([])
        setMedicationQuery('')
        setDosage('')
        setFrequency('')
        setOtherMeds('')
        setAccessType(null)
        setReasonIcd('')
        setNotes('')
        setShowNext(false)
        setPdfData('')
        setSaveReferralData(null)
        setIcdCodes([])
      })
      .catch((err) => {
        console.log('Error updating referral to backend', err)
      })
  }

  const saveReferralstoMongo = (saveReferralData) => {
    console.log(saveReferralData)
    let config = {
      method: 'post',
      url: Config.BACKEND_URL + `referrals/patient`,

      headers: {
        Authorization: `JWT ${props.token}`,
        x_firebase_id: props?.patientId,
      },
      data: saveReferralData,
    }

    axios(config)
      .then(({ data }) => {
        console.log('Saved referral in backend')
        toast.success('Referral Placed')
        setReferralType(null)
        setReferringTo('')
        setSpeciality(null)
        setPhoneNumber('')
        setFaxNumber('')
        setDateInfusion(null)
        setMedication([])
        setMedicationQuery('')
        setDosage('')
        setFrequency('')
        setOtherMeds('')
        setAccessType(null)
        setReasonIcd('')
        setNotes('')
        setShowNext(false)
        setPdfData('')
        setSaveReferralData(null)
        setIcdCodes([])
      })
      .catch((err) => {
        console.log('Error saving referral to backend', err)
        // toast.error('Could not save referral')
      })
  }

  const onSendPressed = () => {
    console.log('sending fax')
    setShowModal(false)

    let doctor_details = saveReferralData?._id
      ? saveReferralData?.provider_name +
        ' ' +
        saveReferralData?.provider_specialty
      : selectedProvider?.provider?.first_name +
        ' ' +
        selectedProvider?.provider?.last_name +
        `${
          selectedProvider?.provider?.speciality
            ? ', ' + selectedProvider?.provider?.speciality
            : ''
        }`

    let result = {
      patient_id: props?.patientId,
      sSenderEmail: props?.mongoUser?.email,
      sToFaxNumber: faxNumber,
      sFileName_1: 'PatientReferral.pdf',
      htmlContent: pdfData,
      sCoverPage: 'Standard',
      sCPFromName: doctor_details,
      sCPToName: referringTo,
      sCPOrganization: 'Aila Health',
      sCPSubject: saveReferralData?._id
        ? saveReferralData?.referral_type
        : referralType?.label,
    }

    let config = {
      method: 'post',
      url: Config.CHANGE_BACKEND_URL + `srfax/referral-outbox`,

      headers: {
        Authorization: `JWT ${props.token}`,
        x_firebase_id: props?.providerId,
      },
      data: result,
    }
    axios(config)
      .then(({ data }) => {
        console.log('SRFAX SENT : ', data)
        if (data.Status !== 'Failed') {
          toast.success('Fax queued successfully')
          //SAVE TO MONGODB
          updateReferralinMongo()
        } else {
          toast.warning(`Could not send fax : ${data.Result}`)
        }
      })
      .catch((err) => {
        console.log('Error getting Faxes for the Patient ', err)
        toast.error('Could not send Fax')
      })
  }

  const getProviderAllergies = async () => {
    try {
      const getAllergy = async () => {
        let url =
          Config.BACKEND_URL +
          'fhir/AllergyIntolerance?patient=' +
          props?.patientId
        return axios({
          method: 'get',
          url: url,
          headers: {
            x_firebase_id: props?.patientId,
            Authorization: 'JWT ' + props?.token,
          },
        })
      }
      let response = await getAllergy()
      if (response?.data) {
        //if there is something in data, then the particle records for the patient has been pulled at least once
        let allergies = response?.data || []
        setProviderAllergies(allergies)
      } else {
        setProviderAllergies([])
      }
    } catch (err) {
      console.log('error when getting allergies data', err)
      // toast.error('Something went wrong when getting allergies')
    }
  }

  const getLabOrders = () => {
    setLoading(true)
    const url = `${Config.BACKEND_URL}patients/labs/orders`
    axios({
      method: 'get',
      url,
      headers: {
        Authorization: `JWT ${props?.token}`,
        x_firebase_id: props?.patientId,
      },
    })
      .then(({ data }) => {
        // console.log('data', data)
        setLoading(false)
        setLabOrdersFromBackend(data)
      })
      .catch((err) => {
        console.log('error when getting lab orders', err)
        // toast.error('Something went wrong')
      })
  }

  const getLabsInfo = (content, map) => {
    map[content.order] = {
      status: content.order_status,
      providerName: `${content.ref_cg_fname} ${content.ref_cg_lname}`,
    }
  }

  const getLabOrdersFromChange = () => {
    const url = `${Config.CHANGE_BACKEND_URL}change/patients/orders`
    axios({
      method: 'get',
      url,
      headers: {
        Authorization: `JWT ${props?.token}`,
        person: props?.patient?.change?.person,
      },
    })
      .then(({ data }) => {
        // console.log('change data', data)
        if (data?.RESULT?.OBJECT) {
          let orders = []
          let content = data.RESULT.OBJECT
          let ordersMap = {}
          if (Array.isArray(content)) {
            content.forEach((x) => getLabsInfo(x, ordersMap))
          } else {
            getLabsInfo(content, ordersMap)
          }
          setLabOrdersFromChange(ordersMap)
        } else {
          toast.error('Could not get lab orders from change')
        }
      })
      .catch((err) => {
        console.log('error when getting lab orders', err)
        // toast.error('Something went wrong')
      })
  }

  const getProviderConditions = async () => {
    try {
      const getCondition = async () => {
        let url =
          Config.BACKEND_URL + 'fhir/Condition?patient=' + props?.patientId
        return axios({
          method: 'get',
          url: url,
          headers: {
            x_firebase_id: props?.patientId,
            Authorization: 'JWT ' + props.token,
          },
        })
      }
      let response = await getCondition()
      if (response?.data) {
        //if there is something in data, then the particle records for the patient has been pulled at least once
        let conditions = response.data || []
        setProviderConds(conditions)
      } else {
        setProviderConds([])
      }
    } catch (err) {
      console.log('error when getting particle conditions data', err)
      // toast.error('Something went wrong when getting external conditions')
    }
  }
  const getProviderMedications = async () => {
    try {
      const getMeds = async () => {
        let url =
          Config.BACKEND_URL +
          'fhir/MedicationRequest?patient=' +
          props?.patientId
        return axios({
          method: 'get',
          url: url,
          headers: {
            x_firebase_id: props?.patientId,
            Authorization: 'JWT ' + props.token,
          },
        })
      }
      let response = await getMeds()
      if (response?.data) {
        // console.log('medications::: ', response.data)
        //if there is something in data, then the particle records for the patient has been pulled at least once
        // let meds1 = response.data.active_medications_provider || []
        // let meds2 = response.data.inactive_medications_provider || []
        // let meds = meds1.concat(meds2)
        let meds = response.data
        setProviderMeds(meds)
      } else {
        setProviderMeds([])
      }
    } catch (err) {
      console.log('error when getting meds data', err)
      // toast.error('Something went wrong when getting meds')
    }
  }

  const getUserCarePlan = () => {
    let url = Config.BACKEND_URL + 'careplan'
    axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: 'JWT ' + props.token,
        patient_id: props?.patientId,
        x_doctor_id: props?.providerId,
      },
    })
      .then(({ data }) => {
        if (data) {
          // console.log('PATIENT CAREPLAN : ', data)
          let temp = data?.filter((obj) => obj?.note_status !== 'archive') || []
          setSelectedNote(temp.map((obj) => ({ ...obj, isChecked: false })))
        }
      })
      .catch((err) => {
        console.log('error when getting user care plan', err)
      })
  }

  const getAllProviderData = () => {
    axios({
      method: 'get',
      url: Config.BACKEND_URL + 'providers',
      headers: {
        x_firebase_id: props.providerId,
        Authorization: 'JWT ' + props.token,
        getallproviders: true,
      },
    })
      .then(({ data }) => {
        data && setAllProviderList(data)
        let providerOptionList = []
        let allProviderData = 
          data
          .filter(doc => (doc.user_type !== 'Administrator'))
          .reduce((data, doc) => {
          data[doc.user_type || 'Other'] = data[doc.user_type || 'Other'] || []
          data[doc.user_type || 'Other'].push({
            label: doc?.first_name + ' ' + doc?.last_name + (doc?.speciality ? ` (${doc?.speciality})` : ''),
            value: doc?.firebase_id,
            provider: doc
          })
          return data
        }, Object.create(null))
        Object.entries(allProviderData).forEach(([userType, doctorArray]) => {
          providerOptionList.push({
            label: userType,
            options: doctorArray,
          })
        })
        setProviderOptionList(providerOptionList)
      })
      .catch((err) => {
        console.log('err while loading provider data: ', err)
      })
  }

  const onAdminSubmitPressed = () => {
    let data = {
      ...saveReferralData,
      referring_to: referringTo,
      phone: phoneNumber,
      fax_number: faxNumber,
    }

    if (
      adminAccess &&
      (!props?.patientId ||
        !faxNumber ||
        !phoneNumber ||
        !referringTo)
    ) {
      toast.error('Please fill all the fields')
      return
    }

    if (!saveReferralData?._id) {
      toast.warning('No referral found')
    }

    if (data?.referral_type === 'physician') {
      Axios(htmlModule1).then((res) => {
        let templateHtml = Handlebars.compile(res.data.toString())
        let bodyHtml = templateHtml(data)
        if (!adminAccess) {
          saveReferralstoMongo(data)
        } else {
          setPdfData(bodyHtml)
          setShowModal(true)
        }
      })
    } else if (data?.referral_type === 'medical') {
      Axios(htmlModule3).then((res) => {
        let templateHtml = Handlebars.compile(res.data.toString())
        let bodyHtml = templateHtml(data)
        if (!adminAccess) {
          saveReferralstoMongo(data)
        } else {
          setPdfData(bodyHtml)
          setShowModal(true)
        }
      })
    } else if (data?.referral_type === 'priorAuth') {
      Axios(htmlModule3).then((res) => {
        let templateHtml = Handlebars.compile(res.data.toString())
        let bodyHtml = templateHtml(data)
        if (!adminAccess) {
          saveReferralstoMongo(data)
        } else {
          setPdfData(bodyHtml)
          setShowModal(true)
        }
      })
    } else if (data?.referral_type === 'imaging') {
      Axios(htmlModule3).then((res) => {
        let templateHtml = Handlebars.compile(res.data.toString())
        let bodyHtml = templateHtml(data)
        if (!adminAccess) {
          saveReferralstoMongo(data)
        } else {
          setPdfData(bodyHtml)
          setShowModal(true)
        }
      })
    } else if (data?.referral_type === 'speciality') {
      Axios(htmlModule2).then((res) => {
        let templateHtml = Handlebars.compile(res.data.toString())
        let bodyHtml = templateHtml(data)
        if (!adminAccess) {
          saveReferralstoMongo(data)
        } else {
          setPdfData(bodyHtml)
          setShowModal(true)
        }
      })
    }
  }

  const onSubmitPressed = () => {
    let data

    console.log('submit pressed')

    if (
      adminAccess &&
      (!props?.patientId ||
        !referralType ||
        !faxNumber ||
        !phoneNumber ||
        !priority ||
        !referringTo ||
        notes.length === 0)
    ) {
      toast.error('Please refer to Task List')
      return
    }

    let primary_insurance = Array.isArray(props?.patient?.insurance)
      ? props?.patient?.insurance?.find((ins) => ins.preference === 1) ||
        props?.patient?.insurance[0]
      : props?.patient?.insurance || null

    let selected_note = saveReferralData?._id
      ? saveReferralData?.selected_note
      : true
    let note_data = []

    let medication_data = ''

    let labs_data = ''

    let allergies_data = ''

    let family_history_data = ''

    let condiions_data = ''
    let tempSelectedNotes = selectedNote.filter(
      (item) => item.isChecked === true,
    )

    if (!saveReferralData?._id && tempSelectedNotes.length) {
      tempSelectedNotes.forEach((selectedNote, index) => {
        let icd_10_data = ''
        let cpt_data = ''

        let doctorInfo =
          allProviderList.find(
            (item) => item.firebase_id === selectedNote?.doctor?.firebase_id,
          ) || null

        if (selectedNote?.careplan?.length) {
          let tempArray = selectedNote?.careplan || []
          tempArray.forEach((icd, index) => {
            icd_10_data = icd_10_data.concat(
              index + 1,
              '. ',
              icd?.icd_10?.icd10_code ? `${icd?.icd_10?.icd10_code} : ` : '',
              icd?.icd_10?.title ? `${icd?.icd_10?.title} : ` : '',
              icd?.assessment,
              // selectedNote?.careplan?.notes[index],
              ' : ',
              // selectedNote?.careplan?.text[index],
              icd?.note,
              index !== tempArray?.length && '\n',
              // '\n',
            )
          })
        }
        if (selectedNote?.cpt?.length) {
          let tempArray = selectedNote?.cpt || []
          tempArray.forEach(function (cpt, index) {
            cpt_data = cpt_data.concat(
              index + 1,
              '. ',
              cpt.cpt_code,
              ' : ',
              cpt.title,
              index !== tempArray?.length && '\n',
              // '\n',
            )
          })
        }

        note_data.push({
          index: index + 1,
          chief_concern: selectedNote?.chief_concern,
          present_illness: selectedNote?.present_illness,
          physical_exam: selectedNote?.physical_exam,
          icd_10: icd_10_data,
          cpt: cpt_data,
          doc_name: doctorInfo?.first_name + doctorInfo?.last_name,
          doc_type: doctorInfo?.speciality,
          date_note: selectedNote?.appointment_date
            ? moment(selectedNote?.appointment_date).format('MMM Do YYYY')
            : moment(selectedNote?.created_at, 'YYYY-MM-DD').format(
                'MMM Do YYYY',
              ),
          note_sign: doctorInfo?.sign_image,
        })
      })
    } else if (saveReferralData?._id) {
      note_data = saveReferralData?.note_data
    } else {
      selected_note = false
    }

    if (sendMeds) {
      let array1 =
        (patientMedicationsData &&
          patientMedicationsData['active_medications']) ||
        []
      let array2 =
        (providerMeds && providerMeds['active_medications_provider']) || []

      let array3 =
        (patientMedicationsData &&
          patientMedicationsData['inactive_medications']) ||
        []
      let array4 =
        (providerMeds && providerMeds['inactive_medications_provider']) || []

      let finalArray = array4.concat(array3.concat(array2.concat(array1)))
      // medication_data = finalArray

      finalArray.forEach((x, index) => {
        medication_data = medication_data.concat(
          index + 1,
          '. ',
          x.dose?.quantity
            ? `${x.name} (${x.dose?.quantity} ${x.dose?.unit}) : `
            : `${x.name} : `,

          x.frequency?.quantity
            ? `${x.frequency.quantity} / ${x.frequency.recurring} : `
            : '- : ',
          moment(x.start).format('MMM Do YYYY') + ' : ',
          x.end ? 'Inactive' : 'Active',
          index !== finalArray?.length && '\n',
        )
      })

      // finalArray.forEach((item) => {
      //   if(item.end) {
      //     medication_data.push({...item, status_info : 'Inactive'})
      //   }
      //   else{
      //     medication_data.push({})
      //   }
      // })
    }

    if (sendFamilyHistory) {
      let familyScreening =
        patientCore && patientCore?.screening_family
          ? patientCore?.screening_family?.relations
          : []

      familyScreening.forEach((each, index) => {
        let conditions = []
        if (each.conditions)
          conditions = each.conditions.map(
            (x, index) =>
              `${x} ${index === each.conditions.length - 1 ? '' : ', '}`,
          )
        family_history_data = family_history_data.concat(
          index + 1,
          '. ',
          each.relationship,
          ' : ',
          conditions,
          index !== familyScreening?.length && '\n',
        )
      })
    }

    if (sendConditions) {
      let conditions = patientConditionsData || []
      // filter out user entered data
      conditions = conditions.filter(
        (userCondn) =>
          !providerConds?.some(
            (prodCondn) =>
              prodCondn?.code?.coding?.[0]?.display === userCondn?.symptom,
          ),
      )
      let newConditions = conditions?.concat(providerConds)

      newConditions.forEach((condn, index) => {
        condiions_data = condiions_data.concat(
          index + 1,
          '. ',
          condn.symptom
            ? condn.symptom
            : condn?.code?.coding?.[0]?.display?.replace(/"/g, ''),
          condn?.code?.coding?.[0]?.display && !condn?.userEntered
            ? ' (Doctor Reported) '
            : '',

          index !== newConditions?.length && '\n',
        )
      })
    }
    if (sendAllergies) {
      let allergies =
        patientCore && patientCore.allergies ? patientCore?.allergies : []
      let newAllergies = providerAllergies?.concat(allergies)
      newAllergies.forEach((allergy, index) => {
        allergies_data = allergies_data.concat(
          index + 1,
          '. ',
          allergy?.code?.coding?.[0]?.display ||
            (allergy?.type &&
              (allergy?.name?.length
                ? allergy?.name?.join()
                : allergy?.type)) ||
            allergy,
          index !== newAllergies?.length && '\n',
        )
      })
    }

    if (sendLabs) {
      let labs = labOrdersFromBackend
      labs.forEach((x, index) => {
        let tests = x.tests || []
        let testElements = tests.map((test) => test.name)

        labs_data = labs_data.concat(
          index + 1,
          '. ',

          testElements,
          ' : ',
          x.lab?.label,
          '\n',
          x.paid ? 'Paid' : 'Unpaid',
          ' : ',
          x.payment_type,
          '\n',
          'Created - ',
          moment(x.created_at).format('MM/DD/YYYY'),
          ' : ',
          'Collected at - ',
          moment(x.collection_date_time).format('MM/DD/YYYY h:mmA'),
          '\n',
          getLabStatusText(x),

          index !== labs?.length && '\n',
        )
      })
    }

    if (referralType.value === 'physician') {
      console.log(props.mongoUser)
      data = {
        referring_to: referringTo,
        phone: phoneNumber,
        fax_number: faxNumber,
        specialty:
          speciality && speciality.value === 'Others'
            ? extraSpecialty
            : speciality.label,
        priority: priority && priority.label,
        notes: notes,
        icd_codes: icdCodes[0]?.code,
        reason: reasonIcd,

        //REFERRAL TYPE
        referral_type: referralType.value,
        doctor_id: selectedProvider?.provider?.firebase_id || props?.providerId,

        //PATIENT INFORMATION
        patient_id: props?.patientId,
        date: moment(new Date()).format('MM/DD/YYYY'),
        patient_name: props.patient?.label,
        patient_dob: moment(props.patient?.dob).format('MM/DD/YYYY'),
        patient_gender: props.patient?.gender,
        patient_address: props.patient?.streetAddress,
        patient_phone: props.patient?.phoneNumber,
        patient_email: props.patient?.email,
        patient_zipcode: props.patient?.zipcode,
        insurance_provider:
          primary_insurance?.insurance_company?.insurance_name || '',
        insurance_plan: primary_insurance?.plan_type || '',
        insurance_member: primary_insurance?.member_name || '',
        insurance_subscriber: primary_insurance?.subscriber_id || '',
        insurance_group: primary_insurance?.group_number || '',

        //PROVIDER INFORMATION
        provider_signature: selectedProvider?.provider?.sign_image,
        provider_name:
          selectedProvider?.provider?.first_name + ' ' + selectedProvider?.provider?.last_name + ', ' + (selectedProvider?.provider?.user_type),
        provider_specialty: selectedProvider?.provider?.speciality,

        //NOTE DATA
        note_data: note_data,
        medication_data: medication_data,
        labs_data: labs_data,
        allergies_data: allergies_data,
        condiions_data: condiions_data,
        family_history_data: family_history_data,
        selected_family_history: sendFamilyHistory,
        selected_conditions: sendConditions,
        selected_note: selected_note,
        selected_meds: sendMeds,
        selected_Labs: sendLabs,
        selected_allergies: sendAllergies,
      }
      setSaveReferralData(data)

      Axios(htmlModule1).then((res) => {
        let templateHtml = Handlebars.compile(res.data.toString())
        let bodyHtml = templateHtml(data)
        if (!adminAccess) {
          saveReferralstoMongo(data)
        } else {
          setPdfData(bodyHtml)
          setShowModal(true)
        }
      })
    } else if (referralType.value === 'medical') {
      data = {
        referring_to: referringTo,
        phone: phoneNumber,
        fax_number: faxNumber,
        priority: priority && priority.label,
        notes: notes,
        icd_codes: icdCodes[0]?.code,
        reason: reasonIcd,
        //REFERRAL TYPE
        referral_type: referralType.value,
        doctor_id: selectedProvider?.provider?.firebase_id || props?.providerId,
        //PATIENT INFORMATION
        patient_id: props?.patientId,
        date: moment(new Date()).format('MM/DD/YYYY'),
        patient_name: props.patient?.label,
        patient_dob: moment(props.patient?.dob).format('MM/DD/YYYY'),
        patient_gender: props.patient?.gender,
        patient_address: props.patient?.streetAddress,
        patient_phone: props.patient?.phoneNumber,
        patient_email: props.patient?.email,
        patient_zipcode: props.patient?.zipcode,
        insurance_provider:
          primary_insurance?.insurance_company?.insurance_name || '',
        insurance_plan: primary_insurance?.plan_type || '',
        insurance_member: primary_insurance?.member_name || '',
        insurance_subscriber: primary_insurance?.subscriber_id || '',
        insurance_group: primary_insurance?.group_number || '',

        //PROVIDER INFORMATION
        provider_signature: selectedProvider?.provider?.sign_image,
        provider_name:
          selectedProvider?.provider?.first_name + ' ' + selectedProvider?.provider?.last_name  + ', ' + (selectedProvider?.provider?.user_type),
        provider_specialty: selectedProvider?.provider?.speciality,

        //NOTE DATA
        note_data: note_data,
        medication_data: medication_data,
        labs_data: labs_data,
        allergies_data: allergies_data,
        condiions_data: condiions_data,
        family_history_data: family_history_data,
        selected_family_history: sendFamilyHistory,
        selected_conditions: sendConditions,
        selected_note: selected_note,
        selected_meds: sendMeds,
        selected_Labs: sendLabs,
        selected_allergies: sendAllergies,
      }
      setSaveReferralData(data)

      Axios(htmlModule3).then((res) => {
        let templateHtml = Handlebars.compile(res.data.toString())
        let bodyHtml = templateHtml(data)
        if (!adminAccess) {
          saveReferralstoMongo(data)
        } else {
          setPdfData(bodyHtml)
          setShowModal(true)
        }
      })
    } else if (referralType.value === 'priorAuth') {
      data = {
        referring_to: referringTo,
        phone: phoneNumber,
        fax_number: faxNumber,
        priority: priority && priority.label,
        notes: notes,
        icd_codes: icdCodes[0]?.code,
        reason: reasonIcd,
        //REFERRAL TYPE
        referral_type: referralType.value,
        doctor_id: selectedProvider?.provider?.firebase_id || props?.providerId,
        //PATIENT INFORMATION
        patient_id: props?.patientId,
        date: moment(new Date()).format('MM/DD/YYYY'),
        patient_name: props.patient?.label,
        patient_dob: moment(props.patient?.dob).format('MM/DD/YYYY'),
        patient_gender: props.patient?.gender,
        patient_address: props.patient?.streetAddress,
        patient_phone: props.patient?.phoneNumber,
        patient_email: props.patient?.email,
        patient_zipcode: props.patient?.zipcode,
        insurance_provider:
          primary_insurance?.insurance_company?.insurance_name || '',
        insurance_plan: primary_insurance?.plan_type || '',
        insurance_member: primary_insurance?.member_name || '',
        insurance_subscriber: primary_insurance?.subscriber_id || '',
        insurance_group: primary_insurance?.group_number || '',

        //PROVIDER INFORMATION
        provider_signature: selectedProvider?.provider?.sign_image,
        provider_name:
          selectedProvider?.provider?.first_name + ' ' + selectedProvider?.provider?.last_name  + ', ' + (selectedProvider?.provider?.user_type),
        provider_specialty: selectedProvider?.provider?.speciality,

        //NOTE DATA
        note_data: note_data,
        medication_data: medication_data,
        labs_data: labs_data,
        allergies_data: allergies_data,
        condiions_data: condiions_data,
        family_history_data: family_history_data,
        selected_family_history: sendFamilyHistory,
        selected_conditions: sendConditions,
        selected_note: selected_note,
        selected_meds: sendMeds,
        selected_Labs: sendLabs,
        selected_allergies: sendAllergies,
        //PRIOR AUTH
        prior_auth: referralType?.value === 'priorAuth' ? true : false,
      }
      setSaveReferralData(data)

      Axios(htmlModule3).then((res) => {
        let templateHtml = Handlebars.compile(res.data.toString())
        let bodyHtml = templateHtml(data)
        if (!adminAccess) {
          saveReferralstoMongo(data)
        } else {
          setPdfData(bodyHtml)
          setShowModal(true)
        }
      })
    } else if (referralType.value === 'imaging') {
      data = {
        referring_to: referringTo,
        phone: phoneNumber,
        fax_number: faxNumber,
        priority: priority && priority.label,
        notes: notes,
        icd_codes: icdCodes[0]?.code,
        reason: reasonIcd,
        //REFERRAL TYPE
        referral_type: referralType.value,
        doctor_id: selectedProvider?.provider?.firebase_id || props?.providerId,
        //PATIENT INFORMATION
        patient_id: props?.patientId,
        date: moment(new Date()).format('MM/DD/YYYY'),
        patient_name: props.patient?.label,
        patient_dob: moment(props.patient?.dob).format('MM/DD/YYYY'),
        patient_gender: props.patient?.gender,
        patient_address: props.patient?.streetAddress,
        patient_phone: props.patient?.phoneNumber,
        patient_email: props.patient?.email,
        patient_zipcode: props.patient?.zipcode,
        insurance_provider:
          primary_insurance?.insurance_company?.insurance_name || '',
        insurance_plan: primary_insurance?.plan_type || '',
        insurance_member: primary_insurance?.member_name || '',
        insurance_subscriber: primary_insurance?.subscriber_id || '',
        insurance_group: primary_insurance?.group_number || '',

        //PROVIDER INFORMATION
        provider_signature: selectedProvider?.provider?.sign_image,
        provider_name:
          selectedProvider?.provider?.first_name + ' ' + selectedProvider?.provider?.last_name  + ', ' + (selectedProvider?.provider?.user_type),
        provider_specialty: selectedProvider?.provider?.speciality,

        //NOTE DATA
        note_data: note_data,
        medication_data: medication_data,
        labs_data: labs_data,
        allergies_data: allergies_data,
        condiions_data: condiions_data,
        family_history_data: family_history_data,
        selected_family_history: sendFamilyHistory,
        selected_conditions: sendConditions,
        selected_note: selected_note,
        selected_meds: sendMeds,
        selected_Labs: sendLabs,
        selected_allergies: sendAllergies,
        //PRIOR AUTH
        prior_auth: referralType?.value === 'priorAuth' ? true : false,
      }
      setSaveReferralData(data)

      Axios(htmlModule3).then((res) => {
        let templateHtml = Handlebars.compile(res.data.toString())
        let bodyHtml = templateHtml(data)
        if (!adminAccess) {
          saveReferralstoMongo(data)
        } else {
          setPdfData(bodyHtml)
          setShowModal(true)
        }
      })
    } else if (referralType.value === 'speciality') {
      data = {
        referring_to: referringTo,
        phone: phoneNumber,
        fax_number: faxNumber,
        priority: priority && priority.label,
        date_infusion: moment(dateInfusion).format('MM/DD/YYYY'),
        order_type: orderType && orderType.label,
        medication: medication && medication[0]?.DrugName,
        dosage: dosage + ' ' + units,
        frequency: frequency,
        access_type: accessType && accessType.label,
        notes: notes,
        icd_codes: icdCodes[0]?.code,
        reason: reasonIcd,
        //REFERRAL TYPE
        referral_type: referralType.value,
        doctor_id: selectedProvider?.provider?.firebase_id || props?.providerId,
        //PATIENT INFORMATION
        patient_id: props?.patientId,
        date: moment(new Date()).format('MM/DD/YYYY'),
        patient_name: props.patient?.label,
        patient_dob: moment(props.patient?.dob).format('MM/DD/YYYY'),
        patient_gender: props.patient?.gender,
        patient_address: props.patient?.streetAddress,
        patient_phone: props.patient?.phoneNumber,
        patient_email: props.patient?.email,
        patient_zipcode: props.patient?.zipcode,
        insurance_provider:
          primary_insurance?.insurance_company?.insurance_name || '',
        insurance_plan: primary_insurance?.plan_type || '',
        insurance_member: primary_insurance?.member_name || '',
        insurance_subscriber: primary_insurance?.subscriber_id || '',
        insurance_group: primary_insurance?.group_number || '',

        //PROVIDER INFORMATION
        provider_signature: selectedProvider?.provider?.sign_image,
        provider_name:
          selectedProvider?.provider?.first_name + ' ' + selectedProvider?.provider?.last_name  + ', ' + (selectedProvider?.provider?.user_type),
        provider_specialty: selectedProvider?.provider?.speciality,

        //NOTE DATA
        note_data: note_data,
        medication_data: medication_data,
        labs_data: labs_data,
        allergies_data: allergies_data,
        condiions_data: condiions_data,
        family_history_data: family_history_data,
        selected_family_history: sendFamilyHistory,
        selected_conditions: sendConditions,
        selected_note: selected_note,
        selected_meds: sendMeds,
        selected_Labs: sendLabs,
        selected_allergies: sendAllergies,
      }
      setSaveReferralData(data)

      Axios(htmlModule2).then((res) => {
        let templateHtml = Handlebars.compile(res.data.toString())
        let bodyHtml = templateHtml(data)
        if (!adminAccess) {
          saveReferralstoMongo(data)
        } else {
          setPdfData(bodyHtml)
          setShowModal(true)
        }
      })
    }
  }

  const handleHitClick = (hit) => {
    if (handleChecked(hit.DrugName)) {
      let arr = medication.filter((data) => data.DrugName !== hit.DrugName)
      setMedication(arr)
    } else {
      setMedication([
        // ...medication,
        { DrugName: hit.DrugName, AILA_ID: hit.AILA_ID },
      ])
    }
  }

  // to handle the list of selected conditions from algolia
  const handleChecked = (DrugName) => {
    return medication.some((data) => data.DrugName === DrugName)
  }

  const Hit = (props) => {
    let { hit } = props
    let checked = handleChecked(hit.DrugName)
    return (
      <div
        style={{ width: '100%', cursor: 'pointer' }}
        onClick={() => {
          handleHitClick(hit)
        }}
      >
        {hit.AILA_ID} : {hit.DrugName}
      </div>
    )
  }

  const BodyHit = (props) => {
    let { hit } = props
    const handleClick = () => {
      setBodysiteQuery('')
      eventFire(document.getElementById('clearQuery'), 'click')
      setBodySite({ id: hit.Code, name: hit.Display })
    }
    return (
      <div
        style={{ width: '100%', cursor: 'pointer' }}
        onClick={(e) => {
          handleClick(e)
        }}
      >
        {hit.Code} : {hit.Display}
      </div>
    )
  }

  // const Hit = (props, type) => {
  //   let { hit } = props
  //   // let checked = handleChecked(q, hit.symptom)
  //   const handleClick = () => addMedication(hit)

  //   return (
  //     <>
  //       <button
  //         onClick={(e) => handleClick(e)}
  //         style={{ border: 'none', outline: 'none', display: 'block' }}
  //       >
  //         {hit.code}: {hit.display}
  //       </button>
  //       <hr />
  //     </>
  //   )
  // }

  const addIcdCode = (hit) => {
    // let temp = [...icdCodes, { code: hit.code, display: hit.display }]
    let temp = [{ code: hit.code, display: hit.display }]

    setIcdCodes(temp)
  }

  const HitICD = (props, type) => {
    let { hit } = props
    // let checked = handleChecked(q, hit.symptom)
    const handleClick = () => addIcdCode(hit)

    return (
      <>
        <button
          onClick={(e) => handleClick(e)}
          style={{ border: 'none', outline: 'none', display: 'block' }}
        >
          {hit.code}: {hit.display}
        </button>
        <hr />
      </>
    )
  }

  // Handle multiple option selection checkbox
  const handleMultipleOptions = (value) => {
    let tempq = [...imagingMed]
    tempq.forEach((item) => {
      if (item.name === value) item.isChecked = !item.isChecked
    })
    setImagingMed(tempq)
  }

  const renderImagingFields = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          margin: '5% 1%',
          borderBottomWidth: 1,
          borderBottomColor: Constants.primaryTheme,
          borderBottomStyle: 'solid',
        }}
      >
        <div className={Styles.inputDiv}>
          <p className={Styles.inputQuestion}>Order Type</p>
          <div style={{ width: '30%', margin: '0px 20px' }}>
            <Select
              options={[
                { label: 'CT Scan', value: 'CT Scan' },
                { label: 'CTA', value: 'CTA' },
                { label: 'MRA', value: 'MRA' },
                { label: 'MRI', value: 'MRI' },
                { label: 'Mammography', value: 'Mammography' },
                { label: 'Ultrasound', value: 'Ultrasound' },
                { label: 'X-ray', value: 'X-ray' },
                { label: 'PET San', value: 'PET San' },
                { label: 'Other', value: 'Other' },
              ]}
              value={imagingStudy}
              autoBlur={true}
              onChange={(val) => setImagingStudy(val)}
              theme={(theme) => selectTheme(theme)}
            />
          </div>
        </div>

        <div className={Styles.inputDiv}>
          <p className={Styles.inputQuestion}>Body Sites</p>
          <div style={{ width: '30%', margin: '0px 20px' }}>
            <InstantSearch
              searchClient={condnClient}
              indexName={'BODY_SITES'}
              onSearchStateChange={({ query }) => setBodysiteQuery(query)}
            >
              <SearchBox
                translations={{
                  placeholder: 'Search body sites',
                }}
              />
              <ClearQueryAndRefinements />
              {bodysiteQuery?.length !== 0 && (
                <div
                  style={{
                    overflow: 'auto',
                    height: 150,
                    backgroundColor: '#F1F1F1',
                  }}
                >
                  <RefinementList limit={5} />
                  <Hits hitComponent={(props) => BodyHit(props)} />
                </div>
              )}
            </InstantSearch>

            {bodySite?.length !== 0 && (
              <div className={Styles.searchAdded}>
                <p
                  className={Styles.addManualTextInput}
                  style={{ marginTop: 30 }}
                >
                  {bodySite?.id ? `${bodySite?.id} - ${bodySite?.name}` : ''}
                </p>
              </div>
            )}
          </div>
        </div>

        <div className={Styles.inputDiv}>
          <p className={Styles.inputQuestion}>Contrast</p>
          <div style={{ width: '30%', margin: '0px 20px' }}>
            <Select
              options={[
                { label: 'With Contrast', value: 'With Contrast' },
                { label: 'Without Contrast', value: 'Without Contrast' },
                {
                  label: 'With/ Without Contrast',
                  value: 'With/ Without Contrast',
                },
                { label: 'Allergy to Contrast', value: 'Allergy to Contrast' },
              ]}
              value={contrast}
              autoBlur={true}
              onChange={(val) => setContrast(val)}
              theme={(theme) => selectTheme(theme)}
            />
          </div>
        </div>

        <div className={Styles.inputDiv}>
          <p className={Styles.inputQuestion}>Literality</p>
          <div style={{ width: '30%', margin: '0px 20px' }}>
            <Select
              options={[
                { label: 'Left', value: 'Left' },
                { label: 'Right', value: 'Right' },
                {
                  label: 'Bilateral',
                  value: 'Bilateral',
                },
              ]}
              value={literality}
              autoBlur={true}
              onChange={(val) => setLiterality(val)}
              theme={(theme) => selectTheme(theme)}
            />
          </div>
        </div>

        <div className={Styles.inputDiv}>
          <p className={Styles.inputQuestion}>Is the patient claustrophobic?</p>
          <div style={{ width: '10%', margin: '0px 20px' }}>
            <label class={Styles.checkContainer}>
              Yes
              <input
                type="checkbox"
                checked={claustrophobic}
                onClick={() => {
                  setClaustrophobic(true)
                }}
              />
              <span class={Styles.checkmark}></span>
            </label>
          </div>
          <div style={{ width: '10%', margin: '0px 20px' }}>
            <label class={Styles.checkContainer}>
              No
              <input
                type="checkbox"
                checked={!claustrophobic}
                onClick={() => {
                  setClaustrophobic(false)
                }}
              />
              <span class={Styles.checkmark}></span>
            </label>
          </div>
        </div>

        <Form.Group
          controlId={'imaging'}
          className={Styles.inputDiv}
          style={{ flexDirection: 'column' }}
        >
          {imagingMed.map((type) => {
            return (
              <>
                <label class={Styles.checkContainer}>
                  {type.name}
                  <input
                    type="checkbox"
                    checked={type.isChecked}
                    onClick={() => {
                      // console.log('clocked')
                      handleMultipleOptions(type.name)
                    }}
                  />
                  <span class={Styles.checkmark}></span>
                </label>
              </>
            )
          })}
        </Form.Group>
      </div>
    )
  }

  const renderInfusion = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          margin: '5% 1%',
          borderBottomWidth: 1,
          borderBottomColor: Constants.primaryTheme,
          borderBottomStyle: 'solid',
        }}
      >
        <div className={Styles.inputDiv}>
          <p className={Styles.inputQuestion}>
            Date of Last Infusion/ Injectable
          </p>
          <div style={{ width: '30%', margin: '0px 20px' }}>
            <DatePicker
              onChange={(date) => {
                setDateInfusion(date)
              }}
              value={dateInfusion}
            />
          </div>

          <p className={Styles.inputQuestion}>Order Type</p>
          <div style={{ width: '30%', margin: '0px 20px' }}>
            <Select
              options={[
                { label: 'New', value: 'New' },
                { label: 'Renewal', value: 'Renewal' },
              ]}
              value={orderType}
              autoBlur={true}
              onChange={(val) => setOrderType(val)}
              theme={(theme) => selectTheme(theme)}
            />
          </div>
        </div>

        <div
          className={Styles.inputDiv}

          //   style={{
          //     display: 'flex',
          //     flexDirection: 'row',
          //     marginTop: 50,
          //     width: '100%',
          //   }}
        >
          <p
            className={Styles.inputQuestion}

            // style={{
            //   alignSelf: 'center',
            //   color: Constants.primaryTheme,
            //   fontWeight: 'bold',
            // }}
          >
            Medication Order
          </p>
          <div style={{ width: '30%', margin: '0px 20px' }}>
            <InstantSearch
              searchClient={condnClient}
              indexName={'MEDICATIONS'}
              onSearchStateChange={({ query }) => setMedicationQuery(query)}
            >
              <SearchBox />
              {medicationQuery.length !== 0 && (
                <div
                  style={{
                    overflow: 'auto',
                    height: 150,
                    backgroundColor: '#F1F1F1',
                  }}
                >
                  <RefinementList limit={5} />
                  <Hits hitComponent={(props) => Hit(props)} />
                </div>
              )}
            </InstantSearch>

            {medication.length !== 0 && (
              <div className={Styles.searchAdded}>
                {medication.map((data, index) => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: 10,
                      }}
                    >
                      <p>{data.DrugName + ' '}</p>
                      <FontAwesomeIcon
                        icon={faTrash}
                        className={Styles.deleteIcon}
                        onClick={() => handleHitClick(data)}
                      />
                      <br />
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        </div>

        {/* INFUSION NEW DRUG WORKFLOW - COMMENTED TILL JSON DATA IS READY */}

        {/* <div className={Styles.inputDiv}>
          <p className={Styles.inputQuestion}>Select Drug Name</p>
          <div style={{ width: '30%', margin: '0px 20px' }}>
            <Select
              options={INFUSION_DRUGS}
              value={infusionDrug}
              autoBlur={true}
              onChange={(val) => {
                setInfusionDrug(val)
                console.log(val)
              }}
              theme={(theme) => selectTheme(theme)}
            />
          </div>
        </div>

        {infusionDrug && (
          <>
            <div className={Styles.inputDiv}>
              <p className={Styles.inputQuestion}>Provider Reminders</p>
              <div style={{ width: '50%', margin: '0px 20px' }}>
                <textarea
                  placeholder="Enter"
                  style={{
                    border: '1px solid #d0d0d0',
                    padding: '5px 20px',
                    borderRadius: '4px',
                    width: '100%',
                    // height: '40px',
                  }}
                  rows="4"
                  type="text"
                  value={infusionDrug?.provider_reminders || ''}
                  onChange={(e) => {
                    let temp = { ...infusionDrug }
                    temp.provider_reminders = e.target.value
                    setInfusionDrug(temp)
                  }}
                ></textarea>
              </div>
            </div>

            <div className={Styles.inputDiv}>
              <p className={Styles.inputQuestion}>Treatment Criteria</p>
              <div style={{ width: '50%', margin: '0px 20px' }}>
                <textarea
                  placeholder="Enter"
                  style={{
                    border: '1px solid #d0d0d0',
                    padding: '5px 20px',
                    borderRadius: '4px',
                    width: '100%',
                    // height: '40px',
                  }}
                  rows="4"
                  type="text"
                  value={infusionDrug?.treatment_criteria || ''}
                  onChange={(e) => {
                    let temp = { ...infusionDrug }
                    temp.treatment_criteria = e.target.value
                    setInfusionDrug(temp)
                  }}
                ></textarea>
              </div>
            </div>

            <div className={Styles.inputDiv}>
              <p className={Styles.inputQuestion}>Pre-Medications</p>
              <div style={{ width: '50%', margin: '0px 20px' }}>
                {infusionDrug?.pre_medications &&
                  infusionDrug?.pre_medications?.map((premed, index) => (
                    <label class={Styles.checkContainer}>
                      {premed.name}
                      <input
                        type="checkbox"
                        checked={premed.isChecked}
                        onClick={() => {
                          let temp = { ...infusionDrug }
                          temp.pre_medications[index].isChecked =
                            !temp.pre_medications[index].isChecked
                          setInfusionDrug(temp)
                        }}
                      />
                      <span class={Styles.checkmark}></span>
                    </label>
                  ))}
               
              </div>
            </div>

            <div className={Styles.inputDiv}>
              <p className={Styles.inputQuestion}>Medications/ Procedure</p>
            </div>

            <div className={Styles.inputDiv}>
              <p className={Styles.inputQuestion}>Infuse</p>
              <div style={{ width: '50%', margin: '0px 20px' }}>
                <textarea
                  placeholder="Enter"
                  style={{
                    border: '1px solid #d0d0d0',
                    padding: '5px 20px',
                    borderRadius: '4px',
                    width: '100%',
                    // height: '40px',
                  }}
                  rows="4"
                  type="text"
                  value={infusionDrug?.medication_procedure || ''}
                  onChange={(e) => {
                    let temp = { ...infusionDrug }
                    temp.medication_procedure = e.target.value
                    setInfusionDrug(temp)
                  }}
                ></textarea>
              </div>
            </div>
            <div className={Styles.inputDiv}>
              <p className={Styles.inputQuestion}>Dose</p>
              <div style={{ width: '50%', margin: '0px 20px' }}>
                <textarea
                  placeholder="Enter"
                  style={{
                    border: '1px solid #d0d0d0',
                    padding: '5px 20px',
                    borderRadius: '4px',
                    width: '100%',
                    // height: '40px',
                  }}
                  rows="4"
                  type="text"
                  value={infusionDrug?.dose || ''}
                  onChange={(e) => {
                    let temp = { ...infusionDrug }
                    temp.dose = e.target.value
                    setInfusionDrug(temp)
                  }}
                ></textarea>
              </div>
            </div>

            <div className={Styles.inputDiv}>
              <p className={Styles.inputQuestion}>Frequency</p>
              <div style={{ width: '50%', margin: '0px 20px' }}>
                <textarea
                  placeholder="Enter"
                  style={{
                    border: '1px solid #d0d0d0',
                    padding: '5px 20px',
                    borderRadius: '4px',
                    width: '100%',
                    // height: '40px',
                  }}
                  rows="4"
                  type="text"
                  value={infusionDrug?.frequency || ''}
                  onChange={(e) => {
                    let temp = { ...infusionDrug }
                    temp.frequency = e.target.value
                    setInfusionDrug(temp)
                  }}
                ></textarea>
              </div>
            </div>
            <div className={Styles.inputDiv}>
              <p className={Styles.inputQuestion}>Guidelines</p>
              <div style={{ width: '50%', margin: '0px 20px' }}>
                <textarea
                  placeholder="Enter"
                  style={{
                    border: '1px solid #d0d0d0',
                    padding: '5px 20px',
                    borderRadius: '4px',
                    width: '100%',
                    // height: '40px',
                  }}
                  rows="4"
                  type="text"
                  value={infusionDrug?.guidelines || ''}
                  onChange={(e) => {
                    let temp = { ...infusionDrug }
                    temp.guidelines = e.target.value
                    setInfusionDrug(temp)
                  }}
                ></textarea>
              </div>
            </div>
          </>
        )} */}

        <div className={Styles.inputDiv}>
          <p className={Styles.inputQuestion}>Dosing</p>
          <div style={{ width: '20%', margin: '0px 20px' }}>
            <input
              placeholder={'Enter Dosage'}
              className={Styles.textInput}
              type="text"
              value={dosage}
              onChange={(e) => {
                let text = e.target.value
                setDosage(text)
              }}
            />
          </div>
          <p className={Styles.inputQuestion}>Units</p>
          <div style={{ width: '20%', margin: '0px 20px' }}>
            <input
              placeholder={'Enter Units'}
              className={Styles.textInput}
              type="text"
              value={units}
              onChange={(e) => {
                let text = e.target.value
                setUnits(text)
              }}
            />
          </div>
          <p className={Styles.inputQuestion}>Frequency</p>
          <div style={{ width: '20%', margin: '0px 20px' }}>
            <input
              placeholder={'Enter Frequency'}
              className={Styles.textInput}
              type="text"
              value={frequency}
              onChange={(e) => {
                let text = e.target.value
                setFrequency(text)
              }}
            />
          </div>
        </div>

        <div className={Styles.inputDiv}>
          <p className={Styles.inputQuestion}>If other, specify</p>
          <div style={{ width: '30%', margin: '0px 20px' }}>
            <input
              placeholder={'Enter Dosage'}
              className={Styles.textInput}
              type="text"
              value={otherMeds}
              onChange={(e) => {
                let text = e.target.value
                setOtherMeds(text)
              }}
            />
          </div>
        </div>

        <div className={Styles.inputDiv}>
          <p className={Styles.inputQuestion}>Type of Access</p>
          <div style={{ width: '30%', margin: '0px 20px' }}>
            <Select
              options={[
                { label: 'Peripheral', value: 'Peripheral' },
                { label: 'PICC', value: 'PICC' },
                { label: 'Port', value: 'Port' },
                { label: 'Midline', value: 'Midline' },
                { label: 'Subcutaneous', value: 'Subcutaneous' },
                { label: 'IM', value: 'IM' },
              ]}
              value={accessType}
              autoBlur={true}
              onChange={(val) => setAccessType(val)}
              theme={(theme) => selectTheme(theme)}
            />
          </div>
        </div>
        {/* <div className={Styles.horizontalLine} style={{ marginTop: '1vh' }} />

        {renderIcdCodes()}

        <div className={Styles.horizontalLine} /> */}
      </div>
    )
  }

  const renderIcdCodes = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          margin: '5% 1%',
          // borderBottomWidth: 1,
          // borderBottomColor: Constants.primaryTheme,
          // borderBottomStyle: 'solid',
          // borderTopWidth: 1,
          // borderTopColor: Constants.primaryTheme,
          // borderTopStyle: 'solid',
        }}
      >
        {/* <div    style={{ display: 'flex', flexDirection: 'row', padding: '30px 20px' }}   > */}

        <div className={Styles.inputDiv}>
          <p className={Styles.inputQuestion}>
            {/* <p
          style={{
            color: Constants.primaryTheme,
            fontWeight: 'bold',
            width: 140,
          }}
        > */}
            {'ICD-10 Code'}
          </p>
          <div style={{ width: '80%' }}>
            <div>
              <InstantSearch
                searchClient={searchClient}
                indexName={'ICD_10_COMPLETE'}
              >
                <div style={{ position: 'relative' }}>
                  <SearchBox />
                  <div
                    style={{
                      overflow: 'auto',
                      height: 150,
                      backgroundColor: '#F1F1F1',
                      paddingRight: 30,
                    }}
                  >
                    <RefinementList limit={5} />
                    <Hits hitComponent={(props) => HitICD(props)} />
                  </div>
                </div>
              </InstantSearch>
            </div>

            {icdCodes.length !== 0 && (
              <div>
                <p style={{ marginTop: 30, color: Constants.primaryTheme }}>
                  Selected ICD-10 Code
                </p>
                <div style={{ height: 160, overflow: 'auto', width: '50%' }}>
                  {icdCodes.map((icd, index) => (
                    <div
                      style={{
                        justifyContent: 'space-between',
                        borderTop: '1px solid #D3D3D3',
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '4px 10px',
                      }}
                    >
                      <p>
                        {index + 1}. {icd.code}: {icd.display}
                      </p>
                      <FontAwesomeIcon
                        icon={faTrash}
                        className={Styles.deleteIcon}
                        onClick={() => {
                          let arr = icdCodes.filter(
                            (data) => data.code !== icd.code,
                          )
                          setIcdCodes(arr)
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className={Styles.inputDiv}>
          <p className={Styles.inputQuestion}>Reason</p>
          <div style={{ width: '60%', margin: '0px 20px' }}>
            <input
              placeholder={'Enter Reason'}
              className={Styles.textInput}
              type="text"
              value={reasonIcd}
              onChange={(e) => {
                let text = e.target.value
                setReasonIcd(text)
              }}
            />
          </div>
        </div>
      </div>
    )
  }

  const renderInstruction = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          margin: '5% 1%',
          borderTopWidth: 1,
          borderTopColor: Constants.primaryTheme,
          borderTopStyle: 'solid',
        }}
      >
        <div className={Styles.inputDiv}>
          <p className={Styles.inputQuestion}>Instructions/Notes</p>
          <div style={{ width: '60%', margin: '0px 20px' }}>
            <input
              placeholder={'Enter Instructions'}
              className={Styles.textInput}
              type="text"
              value={notes}
              onChange={(e) => {
                let text = e.target.value
                setNotes(text)
              }}
            />
          </div>
        </div>

        {/* <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            // marginTop: '8vh',
            margin: '2%',
            justifyContent: 'flex-start',
          }}
        >
          <label className={Styles.checkContainer}>
            <p
              style={{
                color: Constants.darkGray,
                width: '100%',
                overflowWrap: 'break-word',
                display: 'block',
              }}
            >
              Include Patient Chart Information
            </p>
            <input
              type="checkbox"
              checked={includePChart}
              onClick={() => {
                setIncludePChart(!includePChart)
              }}
            />
            <span className={Styles.checkmark}></span>
          </label>
        </div> */}
      </div>
    )
  }

  const renderAllergies = () => {
    let allergies =
      patientCore && patientCore.allergies ? patientCore?.allergies : []
    let newAllergies = providerAllergies?.concat(allergies)
    let allergiesElement = newAllergies
      ? newAllergies?.map((allergy, index) => (
          <div
            className={Styles.contentRow}
            key={index}
            style={{ justifyContent: 'space-between' }}
          >
            <p key={index} style={{ textAlign: 'left', padding: '2px 10px' }}>
              {allergy?.code?.coding?.[0]?.display ||
                (allergy?.type &&
                  (allergy?.name?.length
                    ? allergy?.name?.join()
                    : allergy?.type)) ||
                allergy}
            </p>
          </div>
        ))
      : []
    return (
      <div style={{ width: '100%', margin: 10, position: 'relative' }}>
        <label class={Styles.checkContainer}>
          Allergies
          <input
            type="checkbox"
            checked={sendAllergies}
            onClick={() => {
              setSendAllergies(!sendAllergies)
            }}
          />
          <span class={Styles.checkmark}></span>
        </label>

        <div
          className={Styles.contentBackground}
          style={{ height: 240, overflowY: 'scroll' }}
        >
          {allergiesElement}
        </div>
      </div>
    )
  }

  const renderFamilyHistory = () => {
    let familyScreening = patientCore && patientCore.screening_family
    let entries = []

    function getFamilyHistoryFormatted(relations) {
      if (!relations) return null
      let toReturn = []
      relations.forEach((each) => {
        let conditions = []
        if (each.conditions)
          conditions = each.conditions.map((x, index) => (
            <span key={index.toString()} style={{ width: '80%' }}>
              {x}
              {index === each.conditions.length - 1 ? '' : ', '}
            </span>
          ))
        toReturn.push(
          <div
            key={each.relationship}
            className={Styles.contentRow}
            style={{ padding: '10px 10px', flexWrap: 'wrap' }}
          >
            <p className={Styles.contentRowKey}>{each.relationship}:</p>
            <p style={{ marginLeft: 5, overflowWrap: 'break-word' }}>
              {conditions}
            </p>
          </div>,
        )
      })

      return toReturn
    }

    if (familyScreening) {
      entries = getFamilyHistoryFormatted(familyScreening.relations)
    }
    return (
      <div style={{ width: '100%', margin: 10, position: 'relative' }}>
        <label class={Styles.checkContainer}>
          Family History
          <input
            type="checkbox"
            checked={sendFamilyHistory}
            onClick={() => {
              setSendFamilyHistory(!sendFamilyHistory)
            }}
          />
          <span class={Styles.checkmark}></span>
        </label>
        <div
          className={Styles.contentBackground}
          style={{ height: 240, overflowY: 'scroll' }}
        >
          {entries ? entries : <p>No Family History Found</p>}
        </div>
      </div>
    )
  }

  const renderMedications = () => {
    function getMedicationsOrVitaminsRow(isMedications, active) {
      let key1, key2

      if (isMedications) {
        if (active) {
          key1 = 'active_medications'
          key2 = 'active_medications_provider'
        } else {
          key1 = 'inactive_medications'
          key2 = 'inactive_medications_provider'
        }
      } else {
        if (active) {
          key1 = 'active_vitamins'
          key2 = 'active_vitamins_provider'
        } else {
          key1 = 'inactive_vitamins'
          key2 = 'inactive_vitamins_provider'
        }
      }

      let array1 =
        (patientMedicationsData && patientMedicationsData[key1]) || []
      let array2 = (providerMeds && providerMeds[key2]) || []
      let newArray = array2.concat(array1)
      let toReturn = newArray.map((x, i) => {
        let activeString = 'Active'
        if (!active)
          activeString = 'Inactive since ' + moment(x.end).format('MMM Do YYYY')
        if (newArray.length !== 0) {
          return (
            <div key={i.toString()} className={Styles.contentRow}>
              <p style={{ width: '35%', textAlign: 'center' }}>
                {x.dose?.quantity
                  ? `${x.name} (${x.dose?.quantity} ${x.dose?.unit})`
                  : `${x.name}`}
              </p>
              {/*<p style={{width: '20%', textAlign: 'center'}}>{x.dose.quantity} {x.dose.unit}</p>*/}
              <p style={{ width: '15%', textAlign: 'center' }}>
                {x.frequency?.quantity
                  ? `${x.frequency.quantity} / ${x.frequency.recurring}`
                  : '-'}
              </p>
              <p style={{ width: '20%', textAlign: 'center' }}>
                {moment(x.start).format('MMM Do YYYY')}
              </p>
              <p style={{ width: '20%', textAlign: 'center' }}>
                {activeString}
              </p>
            </div>
          )
        } else {
          return (
            <div key={i.toString()} className={Styles.contentRow}>
              <p style={{ width: '80%', textAlign: 'center' }}>
                No Medications Found
              </p>
            </div>
          )
        }
      })

      return toReturn
    }

    function renderMedicationsOrVitamins(isMedications) {
      let activeElement
      let inactiveElement

      activeElement = getMedicationsOrVitaminsRow(isMedications, true)
      inactiveElement = getMedicationsOrVitaminsRow(isMedications, false)

      return (
        <div style={{ width: '100%', margin: 10, position: 'relative' }}>
          <label class={Styles.checkContainer}>
            Medications
            <input
              type="checkbox"
              checked={sendMeds}
              onClick={() => {
                setSendMeds(!sendMeds)
              }}
            />
            <span class={Styles.checkmark}></span>
          </label>
          {/* <h5 style={{ color: Constants.primaryTheme }}>
            {isMedications ? 'Medications' : 'Vitamins'}
          </h5> */}
          {/* <div style={{ position: 'absolute', top: 0, right: 0 }}>
            <FontAwesomeIcon
              onClick={() => {
                isMedications
                  ? onManualAddClicked('medications')
                  : onManualAddClicked('vitamins')
              }}
              icon={faPlusCircle}
              style={{
                color: Constants.primaryTheme,
                fontSize: 18,
                right: 14,
                cursor: 'pointer',
              }}
            />
          </div> */}
          <div
            className={Styles.contentBackground}
            style={{ height: 340, overflowY: 'scroll' }}
          >
            <div className={Styles.contentRow} style={{ padding: '10px 10px' }}>
              <h6
                className={Styles.tableHeader}
                style={{ width: '35%', textAlign: 'center' }}
              >
                Name
              </h6>
              <h6
                className={Styles.tableHeader}
                style={{ width: '15%', textAlign: 'center' }}
              >
                Dose
              </h6>
              {/*<h6 className={Styles.tableHeader}>Frequency</h6>*/}
              <h6
                className={Styles.tableHeader}
                style={{ width: '20%', textAlign: 'center' }}
              >
                Start
              </h6>
              <h6
                className={Styles.tableHeader}
                style={{ width: '20%', textAlign: 'center' }}
              >
                Status
              </h6>
            </div>
            {activeElement}
            {inactiveElement}
          </div>
        </div>
      )
    }

    return (
      <div className={Styles.tabContentWrapper}>
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          {renderMedicationsOrVitamins(true)}
        </div>

        {/* <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            marginTop: 40,
          }}
        >
          {renderMedicationsOrVitamins(false)}
        </div> */}
      </div>
    )
  }

  const renderConditions = () => {
    function renderPatientConditions() {
      let conditions = patientConditionsData || []
      // filter out user entered data
      conditions = conditions.filter(
        (userCondn) =>
          !providerConds?.some(
            (prodCondn) =>
              prodCondn?.code?.coding?.[0]?.display === userCondn?.symptom,
          ),
      )
      let newConditions = conditions?.concat(providerConds)
      let conditionsElement =
        newConditions &&
        newConditions.map((condn, index) => (
          <div
            className={Styles.contentRow}
            key={index}
            style={{ justifyContent: 'space-between' }}
          >
            <p
              key={index.toString()}
              style={{ textAlign: 'left', padding: '2px 10px' }}
            >
              {condn.symptom ||
                condn?.code?.coding?.[0]?.display?.replace(/"/g, '')}
            </p>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {condn?.code?.coding?.[0]?.display && !condn?.userEntered && (
                <div style={{ marginRight: 10 }}>
                  <FontAwesomeIcon
                    icon={faUserMd}
                    style={{ color: Constants.primaryTheme, fontSize: 20 }}
                  />
                </div>
              )}
            </div>
          </div>
        ))
      if (newConditions.length) {
        return (
          <div style={{ width: '100%', margin: 10, position: 'relative' }}>
            <label class={Styles.checkContainer}>
              Conditions
              <input
                type="checkbox"
                checked={sendConditions}
                onClick={() => {
                  setSendConditions(!sendConditions)
                }}
              />
              <span class={Styles.checkmark}></span>
            </label>
            {/* <h5 style={{ color: Constants.primaryTheme }}>Conditions</h5> */}

            <div
              className={Styles.contentBackground}
              style={{ height: 240, overflowY: 'scroll' }}
            >
              {conditionsElement}
            </div>
          </div>
        )
      } else {
        return <p>No Conditions Found</p>
      }
    }
    return (
      <div className={Styles.tabContentWrapper}>
        <div
          style={{ display: 'flex', flexDirection: 'row', minWidth: '100%' }}
        >
          {renderPatientConditions()}
        </div>
      </div>
    )
  }
  const renderCarePlanNotes = () => {
    // let carePlanData1 = patientCarePlanData || []
    let carePlanData1 = selectedNote || []

    return (
      <>
        {carePlanData1 &&
          carePlanData1?.map((carePlanData) => {
            return (
              // latest notes from the provider
              <div
                className={Styles.contentRow}
                style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              >
                <div
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <label class={Styles.checkContainer}>
                    {/* {carePlanData.name} */}
                    <input
                      type="checkbox"
                      checked={carePlanData.isChecked}
                      onClick={() => {
                        let tempq = [...selectedNote]
                        tempq.forEach((item) => {
                          if (item._id === carePlanData._id)
                            item.isChecked = !item.isChecked
                        })
                        setSelectedNote(tempq)
                      }}
                    />
                    <span class={Styles.checkmark}></span>
                  </label>

                  {/* <Form.Check
                    type={'radio'}
                    // label={type}
                    value={carePlanData}
                    onClick={(val) => {
                      if (carePlanData === selectedNote) {
                        setSelectedNote(null)
                      } else {
                        setSelectedNote(carePlanData)
                      }
                      // this.handleRadioInput(val.target.value)
                    }}
                    style={{
                      color: 'rgb(23,61,68)',
                      fontFamily: 'Avenir',
                      marginLeft: '5%',
                    }}
                    checked={selectedNote === carePlanData}
                    classes={Styles.radioButton}
                  /> */}
                  <img
                    src={
                      carePlanData?.doctor?.image_url
                        ? carePlanData?.doctor?.image_url
                        : DocPlaceHolder
                    }
                    height={50}
                    width={50}
                    style={{ borderRadius: '50%', margin: 10 }}
                  />
                  <h6 style={{ color: Constants.primaryTheme }}>
                    {carePlanData?.doctor?.first_name}{' '}
                    {carePlanData?.doctor?.last_name} {', '}
                    {carePlanData?.appointment_date
                      ? moment(carePlanData?.appointment_date).format(
                          'MMM Do YYYY',
                        )
                      : moment(carePlanData?.created_at, 'YYYY-MM-DD').format(
                          'MMM Do YYYY',
                        )}
                  </h6>
                </div>
                <div style={{ width: '100%' }} className={Styles.carePlanRow}>
                  <div style={{ width: '80%' }}>
                    <h6 style={{ color: Constants.primaryTheme }}></h6>
                    {carePlanData?.careplan &&
                      carePlanData?.careplan.map((note_data, index) => (
                        <p style={{ marginLeft: '10%' }}>
                          <b>{note_data?.icd_10?.title?.replace(/"/g, '')}</b> -{' '}
                          {note_data.assessment}
                        </p>
                      ))}
                  </div>

                  {
                    // carePlanData?.careplan?.icd_10 &&
                    <div style={{ width: '80%' }}>
                      <p
                        style={{
                          textDecoration: 'underline',
                          textDecorationColor:
                            carePlanData?.note_status === 'completed' ||
                            !carePlanData?.note_id
                              ? Constants.primaryTheme
                              : 'red',
                          color:
                            carePlanData?.note_status === 'completed' ||
                            !carePlanData?.note_id
                              ? Constants.primaryTheme
                              : 'red',
                          // cursor: 'pointer',
                          textAlign: 'left',
                          marginLeft: '10%',
                        }}
                      >
                        {carePlanData?.note_status === 'completed' ||
                        !carePlanData?._id
                          ? carePlanData?.appointment_date
                            ? 'Complete Notes from visit'
                            : 'Notes'
                          : 'Incomplete note'}
                      </p>
                    </div>
                  }
                </div>
              </div>
            )
          })}
      </>
    )
  }

  const renderSearchList = () => {
    //SEARCH BY EMAIL
    const options = {
      includeScore: true,
      keys: [
        'facility_name',
        'email',
        'phone',
        'address',
        'city',
        'state',
        'zipcode',
      ],
    }

    let tempArray = facilityType ? metadata[facilityType?.value] : []
    fuse = new Fuse(tempArray, options)

    let uniqueList = []

    if (searchName.length !== 0) {
      const results = fuse.search(searchName)
      // const searchResults = []
      results.forEach((result) => {
        uniqueList.push(result.item)
      })
    } else {
      uniqueList = tempArray
    }

    const renderList = (facilityData, index) => {
      return (
        <div
          key={index}
          // className={Styles.contentRow}
          className={Styles.contentBackground}
          style={{
            flexDirection: 'column',
            alignItems: 'flex-start',
            margin: 10,
            backgroundColor: Constants.contentBackground,
            borderRadius: Constants.borderRadius,
            padding: '1% 2%',
          }}
        >
          <div
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {facilityType?.label === 'Imaging' ? (
              <div
                style={{
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  margin: 10,
                }}
              >
                <h6 style={{ color: Constants.primaryThemeDark }}>
                  {facilityData?.facility_name}
                </h6>
                <h6 style={{ color: Constants.primaryTheme }}>
                  {` ${facilityData?.type || ''} ${facilityData?.email || ''}`}
                  <br />
                  Phone - {facilityData?.phone || ''}
                  <br />
                  Fax - {facilityData?.fax || ''}
                </h6>
                <h6 style={{ color: Constants.primaryTheme }}>
                  {` ${facilityData?.address || ''} ${
                    facilityData?.city || ''
                  } \n ${facilityData?.state || ''} ${
                    facilityData?.zipcode || ''
                  }`}
                </h6>
                <h6 style={{ color: Constants.primaryTheme }}>
                  {` ${facilityData?.operation_hours || ''} \n ${
                    facilityData?.medications_infused || ''
                  } `}
                </h6>
                <h6 style={{ color: 'blue' }}>
                  {` ${facilityData?.referral_link || ''}`}
                </h6>
              </div>
            ) : facilityType?.label === 'Infusion' ? (
              <div
                style={{
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  margin: 10,
                }}
              >
                <h6 style={{ color: Constants.primaryThemeDark }}>
                  {facilityData?.facility_name}
                </h6>
                <h6 style={{ color: Constants.primaryTheme }}>
                  {facilityData?.type || ''}
                  <br />
                  {facilityData?.email || ''}
                  <br />
                  Phone - {facilityData?.phone || ''}
                  <br />
                  Fax - {facilityData?.fax || ''}
                </h6>
                <h6 style={{ color: Constants.primaryTheme }}>
                  {facilityData?.address || ''}
                  {facilityData?.city || ''} {facilityData?.state || ''}{' '}
                  {facilityData?.zipcode || ''}
                  <br />
                  {` ${facilityData?.operation_hours || ''}`}
                </h6>
                <h6 style={{ color: Constants.primaryTheme }}>
                  Medications Infused: {facilityData?.medications_infused || ''}
                  <br />
                  Accepted Insurances : {facilityData?.accepted_insurance || ''}
                </h6>
                <h6 style={{ color: 'blue' }}>
                  {facilityData?.referral_link || ''}
                </h6>
              </div>
            ) : facilityType?.label === 'DME' ? (
              <div
                style={{
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  margin: 10,
                }}
              >
                <h6 style={{ color: Constants.primaryThemeDark }}>
                  {facilityData?.facility_name}
                </h6>
                <h6 style={{ color: Constants.primaryTheme }}>
                  {facilityData?.email || ''}
                  <br />
                  Phone - {facilityData?.phone || ''}
                  <br />
                  Fax - {facilityData?.fax || ''}
                </h6>
                <h6 style={{ color: Constants.primaryTheme }}>
                  {facilityData?.address || ''}
                  {facilityData?.city || ''} {facilityData?.state || ''}{' '}
                  {facilityData?.zipcode || ''}
                </h6>
                <h6 style={{ color: 'blue' }}>
                  {facilityData?.referral_link || ''}
                </h6>
              </div>
            ) : (
              <div></div>
            )}

            <Button
              className={GlobalStyles.button}
              //   disabled={loading}
              onClick={() => {
                console.log(facilityData)
                setReferringTo(facilityData?.facility_name)
                setPhoneNumber(facilityData?.phone)
                setFaxNumber(facilityData?.fax)
                setShowSearchList(false)
                setSearchName('')
                setSearchList([])
              }}
              style={{
                justifyContent: 'right',
                width: '10vw',
              }}
              variant="primary"
            >
              Select
            </Button>
          </div>
        </div>
      )
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: '1% 5%',
        }}
      >
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Search Facility
        </h4>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            marginBottom: '2%',
            // padding: '1% 5%',
          }}
        >
          <div className={Styles.inputDiv}>
            <p className={Styles.inputQuestion}>Type</p>
            <div style={{ width: '50%', margin: '0px 20px' }}>
              <Select
                options={[
                  { label: 'Infusion', value: 'INFUSION_DATABASE' },
                  { label: 'Imaging', value: 'IMAGING_DATABASE' },
                  { label: 'DME', value: 'DME_DATABASE' },
                ]}
                value={facilityType}
                autoBlur={true}
                onChange={(val) => {
                  setFacilityType(val)
                  console.log(metadata[val?.value])
                }}
                theme={(theme) => selectTheme(theme)}
              />
            </div>
          </div>

          <div className={Styles.inputDiv}>
            <p className={Styles.inputQuestion}>Search</p>
            <div style={{ width: '50%', margin: '0px 20px' }}>
              <input
                placeholder="Search Name"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={searchName}
                onChange={(e) => {
                  setSearchName(e.target.value)
                }}
              />
            </div>
          </div>
        </div>

        <div style={{ height: '65vh', overflowY: 'scroll' }}>
          {uniqueList?.length !== 0 ? (
            uniqueList?.map((facility, index) => renderList(facility, index))
          ) : (
            <p style={{ textAlign: 'center', marginTop: 50 }}>
              No Searches found <br />
              Please search again
            </p>
          )}
        </div>
      </div>
    )
  }

  const getLabStatusText = (x) => {
    if (!labOrdersFromChange || !x.order_id) return 'order not placed'

    let ordersFromChange = labOrdersFromChange || {}
    let changeOrder = ordersFromChange[x.order_id]
    return ORDER_STATUSES[changeOrder?.status]
  }

  const renderLabsContent = () => {
    const a = null
    let tableHeader = (
      <div className={Styles.row}>
        <p className={Styles.headerText} style={{ width: '20%' }}>
          Name
        </p>
        <p className={Styles.headerText} style={{ width: '20%' }}>
          Location
        </p>
        <p className={Styles.headerText} style={{ width: '10%' }}>
          Payment Status
        </p>
        <p className={Styles.headerText} style={{ width: '10%' }}>
          Paid By
        </p>
        <p className={Styles.headerText} style={{ width: '15%' }}>
          Created Date
        </p>
        <p className={Styles.headerText} style={{ width: '15%' }}>
          Collection Date
        </p>
        <p className={Styles.headerText} style={{ width: '10%' }}>
          Status
        </p>
      </div>
    )

    let tableRows =
      labOrdersFromBackend &&
      labOrdersFromBackend.map((x) => {
        let tests = x.tests || []
        let testElements = tests.map((test) => <li>{test.name}</li>)

        return (
          <div className={Styles.row}>
            <ul
              className={Styles.entryText}
              style={{ width: '20%', textAlign: 'left' }}
            >
              {testElements}
            </ul>
            <p className={Styles.entryText} style={{ width: '20%' }}>
              {x.lab?.label}
            </p>
            <p
              // onClick={() => x.order_id && onLabRequistionRequested(x)}
              className={Styles.entryText}
              style={{
                width: '10%',
                color: x.order_id ? 'blue' : 'black',
                textDecoration: x.order_id ? 'underline' : 'none',
                cursor: x.order_id ? 'pointer' : 'auto',
              }}
            >
              {x.paid ? 'Paid' : 'Unpaid'}
            </p>
            <p className={Styles.entryText} style={{ width: '10%' }}>
              {x.payment_type}
            </p>
            <p className={Styles.entryText} style={{ width: '15%' }}>
              {moment(x.created_at).format('MM/DD/YYYY')}
            </p>
            <p className={Styles.entryText} style={{ width: '15%' }}>
              {moment(x.collection_date_time).format('MM/DD/YYYY h:mmA')}
            </p>
            <p
              className={Styles.entryText}
              // onClick={() => x.result && onLabReportRequested(x)}
              style={{
                width: '10%',
                cursor: x.result ? 'pointer' : 'auto',
                color: x.result ? 'blue' : 'black',
                textDecoration: x.result ? 'underline' : 'none',
              }}
            >
              {getLabStatusText(x)}
            </p>
          </div>
        )
      })

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          marginTop: 20,
        }}
      >
        {labOrdersFromBackend && labOrdersFromBackend.length !== 0 ? (
          <div
            className={Styles.contentBackground}
            style={{ height: 500, overflowY: 'scroll', width: '100%' }}
          >
            <label class={Styles.checkContainer}>
              Lab Orders
              <input
                type="checkbox"
                checked={sendLabs}
                onClick={() => {
                  setSendLabs(!sendLabs)
                }}
              />
              <span class={Styles.checkmark}></span>
            </label>
            {tableHeader}
            {labOrdersFromBackend && labOrdersFromBackend.length !== 0 && (
              <div
                // className={Styles.contentBackground}
                // style={{ height: 500, overflowY: 'scroll', width: '100%' }}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  marginTop: 20,
                }}
              >
                {tableRows}
              </div>
            )}
          </div>
        ) : (
          `No Labs Found`
        )}
      </div>
    )
  }

  {
    /* <Modal
          ariaHideApp={false}
          isOpen={showRequisitionModal}
          onRequestClose={() => setShowRequisitionModal(false)}
          style={customChangeModalStyles}
          contentLabel="Modal"
        >
          <iframe
            style={{ height: '100%', width: '100%' }}
            src={`data:application/pdf;base64,${modalRequisition}`}
          />
        </Modal>

        <Modal
          ariaHideApp={false}
          isOpen={showLabResultModal}
          onRequestClose={() => setShowLabResultModal(false)}
          style={customChangeModalStyles}
          contentLabel="Modal"
        >
          <iframe
            style={{ height: '100%', width: '100%' }}
            src={`data:application/pdf;base64,${labResultPdf}`}
          />
        </Modal> */
  }

  const renderContent = () => {
    if (!showNext) {
      return (
        <>
          <div
            className={Styles.inputDiv}
            style={{
              display: adminAccess ? 'flex' : 'none',
            }}
          >
            <p
              className={Styles.inputQuestion}
              style={{ color: Constants.darkGray }}
            >
              Task Description : {props?.task_description}
            </p>
          </div>
          <div
            className={Styles.inputDiv}
            style={{
              display: adminAccess ? 'flex' : 'none',
            }}

            //   style={{
            //     display: 'flex',
            //     flexDirection: 'row',
            //     marginTop: 50,
            //     width: '100%',
            //   }}
          >
            <p
              className={Styles.inputQuestion}

              // style={{
              //   alignSelf: 'center',
              //   color: Constants.primaryTheme,
              //   fontWeight: 'bold',
              // }}
            >
              Facility Name
            </p>
            <div style={{ width: '30%', margin: '0px 20px' }}>
              <input
                placeholder={'Referring To'}
                className={Styles.textInput}
                type="text"
                value={referringTo}
                onChange={(e) => {
                  let text = e.target.value
                  setReferringTo(text)
                }}
              />
            </div>
            <FontAwesomeIcon
              onClick={() => {
                setShowSearchList(true)
                setSearchName('')
                setSearchList([])
              }}
              icon={faSearch}
              style={{
                color: Constants.primaryTheme,
                fontSize: 20,
                marginLeft: 10,
                cursor: 'pointer',
              }}
            />
          </div>

          <div
            className={Styles.inputDiv}
            style={{ display: !adminAccess ? 'flex' : 'none' }}
            //   style={{
            //     display: 'flex',
            //     flexDirection: 'row',
            //     marginTop: '5%',
            //     width: '100%',
            //     justifyContent: 'space-evenly',
            //   }}
          >
            <p
              className={Styles.inputQuestion}
              // style={{
              //   alignSelf: 'center',
              //   color: Constants.primaryTheme,
              //   fontWeight: 'bold',
              // }}
            >
              Referral Type
            </p>
            <div style={{ width: '30%', margin: '0px 20px' }}>
              <Select
                //   style={{ width: 100 }}
                options={[
                  { label: 'To Physician/Provider', value: 'physician' },
                  { label: 'Durable Medical Equipment', value: 'medical' },
                  { label: 'Imaging/ Radiology', value: 'imaging' },
                  { label: 'Specialty Pharmacy/Infusion', value: 'speciality' },
                  { label: 'Patient Prior Auth', value: 'priorAuth' },
                  { label: 'To Physician/Provider', value: 'physician' },
                  {
                    label: 'Patient needs to be admitted to the hospital',
                    value: 'patient_hospital',
                  },
                  {
                    label: 'Patient needs to be sent to urgent care',
                    value: 'urgent_care',
                  },
                ]}
                value={referralType}
                autoBlur={true}
                onChange={(val) => setReferralType(val)}
                theme={(theme) => selectTheme(theme)}
              />
            </div>
            {/*<p style={{ alignSelf: 'center', color: Constants.primaryTheme, fontWeight: 'bold' }}>Order Type</p>*/}
            {/*<div style={{ width: 200, margin: '0px 20px' }}>*/}
            {/*  <Select*/}
            {/*    style={{width: 100}}*/}
            {/*    options={[*/}
            {/*      {label: '1st Order type', value: '1st Order type'},*/}
            {/*      {label: '2nd Order type', value: '2nd Order type'},*/}
            {/*    ]}*/}
            {/*    value={this.state.orderType}*/}
            {/*    autoBlur={true}*/}
            {/*    onChange={(val) => this.setState({orderType:  val})}*/}
            {/*    theme={(theme) => selectTheme(theme)}*/}
            {/*  />*/}
            {/*</div>*/}
            <p
              className={Styles.inputQuestion}
              style={{
                visibility:
                  referralType?.value === 'physician' ? 'visible' : 'hidden',
              }}
            >
              Specialty
            </p>
            <div
              style={{
                width: '25%',
                margin: '0px 20px',
                visibility:
                  referralType?.value === 'physician' ? 'visible' : 'hidden',
              }}
            >
              <Select
                //   style={{ width: 100 }}
                options={SPECIALITY_OPTIONS}
                value={speciality}
                autoBlur={true}
                onChange={(val) => setSpeciality(val)}
                theme={(theme) => selectTheme(theme)}
              />
            </div>

            <div
              style={{
                width: '15%',
                // margin: '0px 20px',
                visibility:
                  speciality && speciality.value === 'Others'
                    ? 'visible'
                    : 'hidden',
              }}
            >
              <input
                placeholder={'Enter Specialty'}
                className={Styles.textInput}
                type="text"
                value={extraSpecialty}
                onChange={(e) => {
                  let text = e.target.value
                  setExtraSpecialty(text)
                }}
              />
            </div>
          </div>

          {referralType && referralType?.value === 'speciality' && (
            <div className={Styles.inputDiv}>
              <p className={Styles.inputQuestion}>
                <a
                  href="https://locator.infusioncenter.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: Constants.darkGray,
                    textDecoration: 'underline',
                  }}
                >
                  Infusion Center Locator
                </a>
              </p>
            </div>
          )}

          <div
            className={Styles.inputDiv}
            style={{ display: adminAccess ? 'flex' : 'none' }}

            //   style={{
            //     display: 'flex',
            //     flexDirection: 'row',
            //     marginTop: 50,
            //     width: '100%',
            //   }}
          >
            <p
              className={Styles.inputQuestion}

              // style={{
              //   alignSelf: 'center',
              //   color: Constants.primaryTheme,
              //   fontWeight: 'bold',
              // }}
            >
              Phone Number
            </p>
            <div
              style={{
                width: '30%',
                margin: '0px 20px',
              }}
            >
              <input
                placeholder={'Phone Number'}
                className={Styles.textInput}
                type="text"
                value={phoneNumber}
                onChange={(e) => {
                  let text = e.target.value
                  setPhoneNumber(text)
                }}
              />
            </div>

            <p
              className={Styles.inputQuestion}

              // style={{
              //   alignSelf: 'center',
              //   color: Constants.primaryTheme,
              //   fontWeight: 'bold',
              // }}
            >
              Fax Number
            </p>
            <div style={{ width: '30%', margin: '0px 20px' }}>
              <input
                placeholder={'Fax Number'}
                className={Styles.textInput}
                type="text"
                value={faxNumber}
                onChange={(e) => {
                  let text = e.target.value
                  setFaxNumber(text)
                }}
              />
            </div>
          </div>

          <div
            className={Styles.inputDiv}
            style={{ display: !adminAccess ? 'flex' : 'none' }}

            //   style={{
            //     display: 'flex',
            //     flexDirection: 'row',
            //     marginTop: 50,
            //     width: '100%',
            //   }}
          >
            <p
              className={Styles.inputQuestion}

              // style={{
              //   alignSelf: 'center',
              //   color: Constants.primaryTheme,
              //   fontWeight: 'bold',
              // }}
            >
              Priority
            </p>
            <div style={{ width: '30%', margin: '0px 20px' }}>
              <Select
                style={{ width: 100 }}
                options={[
                  { label: 'Urgent', value: 'Urgent' },
                  { label: 'Routine', value: 'Routine' },
                ]}
                value={priority}
                autoBlur={true}
                onChange={(val) => setPriority(val)}
                theme={(theme) => selectTheme(theme)}
              />
            </div>
          </div>
          <div
            className={Styles.inputDiv}
            style={{ display: !adminAccess ? 'flex' : 'none' }}

            //   style={{
            //     display: 'flex',
            //     flexDirection: 'row',
            //     marginTop: 50,
            //     width: '100%',
            //   }}
          >
            <p
              className={Styles.inputQuestion}

              // style={{
              //   alignSelf: 'center',
              //   color: Constants.primaryTheme,
              //   fontWeight: 'bold',
              // }}
            >
              Referring Provider
            </p>
            <div style={{ width: '30%', margin: '0px 20px' }}>
              <Select
                style={{ width: 100 }}
                options={providerOptionList}
                value={selectedProvider}
                getOptionValue={(option) => option.label}
                autoBlur={true}
                onChange={(val) => {
                  console.log('TEST: ', val)
                  setSelectedProvider(val)
                }}
                theme={(theme) => selectTheme(theme)}
              />
            </div>
          </div>
        </>
      )
    } else if (showNext) {
      return (
        <>
          {referralType &&
            referralType.value === 'speciality' &&
            renderInfusion()}
          {referralType &&
            referralType.value === 'imaging' &&
            renderImagingFields()}
          {/* <div className={Styles.horizontalLine} /> */}
          {renderIcdCodes()}
          {/* <div className={Styles.horizontalLine} /> */}
          {renderInstruction()}

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: '5% 1%',
              borderTopWidth: 1,
              borderTopColor: Constants.primaryTheme,
              borderTopStyle: 'solid',
            }}
          >
            <div className={Styles.inputDiv}>
              <p className={Styles.inputQuestion}>Patient Conditions</p>

              {renderConditions()}

              {/* <Conditions
                patient={props?.patient}
                patientCore={props?.patient}
                conditions={patientConditionsData}
                providerConditions={providerConds}
              /> */}
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: '5% 1%',
              borderTopWidth: 1,
              borderTopColor: Constants.primaryTheme,
              borderTopStyle: 'solid',
            }}
          >
            <div className={Styles.inputDiv}>
              <p className={Styles.inputQuestion}>Patient Allergies</p>

              {renderAllergies()}

              {/* <Conditions
                patient={props?.patient}
                patientCore={props?.patient}
                conditions={patientConditionsData}
                providerConditions={providerConds}
              /> */}
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: '5% 1%',
              borderTopWidth: 1,
              borderTopColor: Constants.primaryTheme,
              borderTopStyle: 'solid',
            }}
          >
            <div className={Styles.inputDiv}>
              <p className={Styles.inputQuestion}>Patient Family History</p>

              {renderFamilyHistory()}

              {/* <Conditions
                patient={props?.patient}
                patientCore={props?.patient}
                conditions={patientConditionsData}
                providerConditions={providerConds}
              /> */}
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: '5% 1%',
              borderTopWidth: 1,
              borderTopColor: Constants.primaryTheme,
              borderTopStyle: 'solid',
            }}
          >
            <div className={Styles.inputDiv}>
              <p className={Styles.inputQuestion}>Patient Medications</p>

              {renderMedications()}

              {/* <MedicationsTab
                patient={props?.patient}
                patientCore={props?.patient}
                medications={patientMedicationsData}
                providerMedications={providerMeds}
              /> */}
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: '5% 1%',
              borderTopWidth: 1,
              borderTopColor: Constants.primaryTheme,
              borderTopStyle: 'solid',
            }}
          >
            <div className={Styles.inputDiv}>
              <p className={Styles.inputQuestion}>Patient Note</p>

              {patientCarePlanData && patientCarePlanData.length != 0 ? (
                <div
                  className={Styles.contentBackground}
                  style={{ height: 500, overflowY: 'scroll', width: '100%' }}
                >
                  {renderCarePlanNotes()}
                </div>
              ) : (
                `No Notes Found`
              )}
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: '5% 1%',
              borderTopWidth: 1,
              borderTopColor: Constants.primaryTheme,
              borderTopStyle: 'solid',
            }}
          >
            <div className={Styles.inputDiv}>
              <p className={Styles.inputQuestion}>Patient Lab Orders</p>

              {renderLabsContent()}
            </div>
          </div>
        </>
      )
    }
  }

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minWidth: '100%',
          minHeight: '100%',
        }}
      >
        <div className={GlobalStyles.loader} />
      </div>
    )
  } else {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          margin: '5% 1%',
          borderTopWidth: 1,
          borderTopColor: Constants.primaryTheme,
          borderTopStyle: 'solid',
        }}
      >
        {renderContent()}

        {showModal && (
          <Modal
            ariaHideApp={false}
            isOpen={showModal}
            onRequestClose={() => setShowModal(false)}
            style={customChangeModalStyles}
            contentLabel="Modal"
          >
            <h3>Please verify the document and proceed to Fax</h3>
            <Button
              onClick={() => {
                let postData = {
                  htmlContent: pdfData,
                }
                let url = Config.CHANGE_BACKEND_URL + `pdf/generate`
                //API REQUEST TO GET PDF BASE64 RIGHT FORMAT FROM BACKEND - PUPPETEER
                axios({
                  method: 'post',
                  headers: {
                    Authorization: 'JWT ' + props?.token,
                    x_firebase_id: props?.patientId,
                  },
                  url: url,
                  data: postData,
                })
                  .then(({ data }) => {
                    // Insert a link that allows the user to download the PDF file
                    var link = document.createElement('a')
                    link.innerHTML = 'Download PDF file'
                    link.download = `Patient Referral ${props?.patient?.firstName.charAt(
                      0,
                    )}${props?.patient?.lastName.charAt(0)} ${moment().format(
                      'MM/DD/YYYY',
                    )}.pdf`
                    link.href =
                      'data:application/octet-stream;base64,' + data.result
                    // document.body.appendChild(link);
                    link.click()
                    console.log('downloaded')
                    toast.success('pdf downloaded')
                  })
                  .catch((err) => {
                    console.log(
                      'error when getting saving provider goals data',
                      err,
                    )
                    toast.error('Could not download Notes, please try again')
                  })
              }}
              className={GlobalStyles.button}
              style={{
                width: 100,
                position: 'absolute',
                right: 120,
                top: 10,
              }}
              variant="primary"
            >
              Download
            </Button>
            <Button
              onClick={() => onSendPressed()}
              className={GlobalStyles.button}
              style={{
                width: 100,
                position: 'absolute',
                right: 5,
                top: 10,
              }}
              variant="primary"
            >
              Submit
            </Button>
            <iframe
              srcDoc={pdfData}
              title="Provider Referral"
              style={{ height: '100%', width: '100%' }}
            />
          </Modal>
        )}

        {showSearchList && (
          <Modal
            ariaHideApp={false}
            isOpen={showSearchList}
            onRequestClose={() => {
              setShowSearchList(false)
              setSearchName('')
              setSearchList([])
            }}
            style={customChangeModalStyles}
            contentLabel="Modal"
          >
            {renderSearchList()}
          </Modal>
        )}

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Button
            onClick={() => setShowNext(false)}
            className={GlobalStyles.button}
            style={{
              width: 100,
              marginTop: 60,
              alignSelf: 'flex-start',
              visibility: showNext ? 'visible' : 'hidden',
            }}
            variant="primary"
          >
            Back
          </Button>

          <Button
            onClick={() => {
              if (adminAccess) onAdminSubmitPressed()
              else if (!showNext) setShowNext(true)
              else onSubmitPressed()
            }}
            className={GlobalStyles.button}
            style={{ width: 200, marginTop: 60, alignSelf: 'flex-end' }}
            variant="primary"
          >
            {showNext ? 'Place Order' : adminAccess ? 'Place Order' : 'Next'}
          </Button>
        </div>
      </div>
    )
  }
}

export default Referrals