import React, { Component } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import ReactTooltip from 'react-tooltip'
import moment from 'moment'
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend } from 'recharts'
import Modal from 'react-modal'
import Helpers from '../../../helpers/Global.js'
import GlobalStyles from '../../styles/global.module.scss'
import Styles from './styles/Vitals.module.scss'
import {
  addPatient,
  updatePatientData,
  addPatientProgress,
  fetchPatientProgressData,
} from '../../../redux/actions'
import Config from '../../../config'
import Constants from '../../../values'
const axios = require('axios')

const GREEN_RANGE_COLOR = '#20A89266'
const YELLOW_RANGE_COLOR = '#ffae4266'
const RED_RANGE_COLOR = '#ff000066'

const windowHeight = window.innerHeight
const customProgressModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '60%',
    height: windowHeight / 1.3,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const CHART_DATA = [
  { name: 'Date A', s1: 4, s2: 8, s3: 4, s4: 8 },
  { name: 'Date B', s1: 7, s2: 9, s3: 3, s4: 6 },
  { name: 'Date c', s1: 1, s2: 10, s3: 7, s4: 6 },
  { name: 'Date d', s1: 9, s2: 6, s3: 9, s4: 9 },
  { name: 'Date e', s1: 5, s2: 6, s3: 10, s4: 10 },
  { name: 'Date f', s1: 7, s2: 4, s3: 7, s4: 8 },
]

// const VITAL_OPTIONS = ["Temperature", "Respiratory Rate", "SpO2", "Blood Pressure", "Heart Rate", "HRV"]
const VITAL_OPTIONS = [
  {
    label: 'Temperature',
    val: '98.6',
    unit: 'F',
    value: 'Temp',
    chartDomain: [85, 115],
    ranges: {
      red: [
        [0, 94.9],
        [100.4, 200],
      ],
      yellow: [
        [95, 96.89],
        [99.59, 100.39],
      ],
      green: [[96.9, 99.6]],
    },
  },
  {
    label: 'Respiratory Rate',
    val: '45',
    unit: 'breaths / min',
    value: 'Resp',
    chartDomain: [0, 50],
    ranges: {
      red: [
        [0, 11.9],
        [25, 50],
      ],
      yellow: [
        [21, 24.99],
        [21, 24.99],
      ],
      green: [[12, 20.99]],
    },
  },
  {
    label: 'SpO2',
    unit: '-',
    val: '85%',
    value: 'SpO2',
    chartDomain: [75, 100],
    ranges: 90,
  },
  {
    label: 'Blood Pressure',
    unit: 'mm Hg',
    val: '120/80',
    value: 'BP',
    chartDomain: [40, 200],
    ranges: {
      sys: [120, [120, 139], 140],
      dia: [80, [80, 89], 90],
    },
  },
  {
    label: 'Heart Rate',
    unit: 'BPM',
    val: '78',
    value: 'HR',
    chartDomain: [30, 200],
    ranges: {
      red: [
        [0, 60],
        [100, 200],
      ],
      yellow: [
        [0, 0],
        [0, 0],
      ],
      green: [[61, 99]],
    },
  },
  { label: 'HRV', unit: 'ms', val: '67', value: 'HRV', chartDomain: [0, 200] },
]

const LAB_OPTIONS = [
  {
    lab: true,
    label: 'Cholesterol',
    val: '172',
    unit: 'mg/dL',
    value: 'Cholesterol',
    chartDomain: [0, 300],
  },
  {
    lab: true,
    label: 'Glucose',
    val: '102',
    unit: 'mg/dL',
    value: 'Glucose',
    chartDomain: [40, 320],
  },
  { lab: true, label: 'HbA1C', unit: '-', val: '5.8%', value: 'A1c' },
  { lab: true, label: 'CRP', unit: 'mg/L', val: '5', value: 'CRP' },
  { lab: true, label: 'Insulin', unit: 'pmol/L', val: '10', value: 'Insulin' },
  {
    lab: true,
    label: 'Uric Acid',
    unit: 'mg/dL',
    val: '10',
    value: 'Uric Acid',
  },
  { lab: true, label: 'GGT', unit: 'units/L', val: '10', value: 'GGT' },
  { lab: true, label: 'CRP-HS', unit: 'mg/L', val: '10', value: 'CRP-HS' },
  { lab: true, label: 'Ferritin', unit: 'mcg/L', val: '10', value: 'Ferritin' },
  { lab: true, label: 'LDL-P', unit: 'mg/dL', val: '10', value: 'LDL-P' },
  {
    lab: true,
    label: 'LDL-C',
    unit: 'mg/dL',
    val: '10',
    value: 'ldlc',
    chartDomain: [0, 300],
  },
  {
    lab: true,
    label: 'HDL',
    unit: 'mg/dL',
    val: '10',
    value: 'hdl',
    chartDomain: [0, 120],
  },
  { lab: true, label: 'Lp(a)', unit: 'mg/dL', val: '10', value: 'Lp(a)' },
  {
    lab: true,
    label: 'Triglyceride',
    unit: 'mg/dL',
    val: '10',
    value: 'triglycerides',
    chartDomain: [0, 500],
  },
  {
    lab: true,
    label: 'Triglyceride Nonfasting',
    unit: 'mg/dL',
    val: '10',
    value: 'triglycerides nonfasting',
    chartDomain: [0, 500],
  },
  {
    lab: true,
    label: 'Vitamin D',
    unit: 'ng/mL',
    val: '10',
    value: 'Vitamin D',
  },
  {
    lab: true,
    label: 'Vitamin B12',
    unit: 'pg/mL',
    val: '10',
    value: 'Vitamin B12',
  },
]

const DEFAULT_LAB_OPTIONS = [
  {
    lab: true,
    label: 'Glucose',
    val: '102',
    unit: 'mg/dL',
    value: 'Glucose',
    chartDomain: [40, 320],
  },
  {
    lab: true,
    label: 'LDL-C',
    unit: 'mg/dL',
    val: '10',
    value: 'ldlc',
    chartDomain: [0, 300],
  },
  {
    lab: true,
    label: 'HDL',
    unit: 'mg/dL',
    val: '10',
    value: 'hdl',
    chartDomain: [0, 120],
  },
  {
    lab: true,
    label: 'Triglyceride',
    unit: 'mg/dL',
    val: '10',
    value: 'triglycerides',
    chartDomain: [0, 500],
  },
  {
    lab: true,
    label: 'Triglyceride Nonfasting',
    unit: 'mg/dL',
    val: '10',
    value: 'triglycerides nonfasting',
    chartDomain: [0, 500],
  },
]

const LIFESTYLE_OPTIONS = [
  { label: 'Rx Adherence', val: '75%', unit: '-', value: 'Rx Adherence' },
  {
    label: 'Average Sleep',
    val: '7',
    unit: 'hours/day',
    value: 'Sleep',
    chartDomain: [0, 16],
    ranges: {
      red: [
        [0, 5.99],
        [11, 24],
      ],
      yellow: [
        [6, 6.99],
        [9, 10.99],
      ],
      green: [[7, 8.99]],
    },
  },
  {
    label: 'Weight',
    unit: 'pounds(lbs)',
    val: '160',
    value: 'Weight',
    chartDomain: [60, 400],
    ranges: {
      red: [
        [0, 16.99],
        [30, 50],
      ],
      yellow: [
        [25, 29.99],
        [17, 18.49],
      ],
      green: [[18.5, 24.99]],
    },
  },
  {
    label: 'Average Activity',
    unit: 'mins/day',
    val: '35',
    value: 'Activity',
    chartDomain: [0, 400],
  },
  {
    label: 'Average Steps',
    unit: 'steps/day',
    val: '4023',
    value: 'Steps',
    chartDomain: [0, 20000],
  },
  {
    label: 'Calories',
    unit: 'kcal',
    val: '1024',
    value: 'Calories',
    chartDomain: [0, 5000],
  },
  {
    label: 'Body Fat',
    unit: '%',
    val: '20%',
    value: 'Body Fat',
    chartDomain: [2, 40],
    ranges: {
      red: [
        [0, 16.99],
        [30, 50],
      ],
      yellow: [
        [25, 29.99],
        [17, 18.49],
      ],
      green: [[18.5, 24.99]],
    },
  },
]

class Vitals extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalIsOpen: false,
      vitalOptions: [],
      lifestyleOptions: [],
      labOptions: [],
      timeline: { label: 'Complete History', value: 'complete' },
      patient: null,
      checked: false,
      symptomProgressData: null,
      vitalsProgressData: null,
      symptomLoading: true,
      vitalsLoading: true,
      modalOption: {},
      modalProgress: [],
      loading: true,
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.patientProgress && props.patientCore) {
      return {
        vitalsProgressData: props.patientProgress,
        vitalsLoading: false,
        loading: false,
      }
    }

    return null
  }

  componentDidMount() {
    if (!this.props.patient) {
      this.props.history.push('/patient/list')
      return
    }

    const { patient } = this.props
    const { preferences } = patient
    if (preferences && preferences.checked) {
      this.setState({
        vitalOptions: preferences.vitalOptions || [],
        labOptions: preferences.labOptions || [],
        lifestyleOptions: preferences.lifestyleOptions || [],
        timeline: preferences.timeline || {
          label: 'Monthly',
          value: 'monthly',
        },
        checked: preferences.checked,
      })
      const timeline = preferences.timeline
        ? preferences.timeline.value
        : 'complete'
      // this.getSymptomProgressData(timeline)
    } else {
      this.setState({
        vitalOptions: VITAL_OPTIONS,
        lifestyleOptions: LIFESTYLE_OPTIONS,
        labOptions: DEFAULT_LAB_OPTIONS,
        checked: false,
        timeline: { label: 'Complete History', value: 'complete' },
      })
      // this.getSymptomProgressData('complete')
    }
  }

  getVitalsProgressData() {
    // get data
    const { patient, token } = this.props
    const self = this
  }

  onSelectEntryChanged(stateOptionKey, val) {
    this.setState({ [stateOptionKey]: val })
    if (this.state.checked) {
      const { docFirebaseAuthUser, patient, token } = this.props
      const key = `preferences.${stateOptionKey}`
      this.props.updatePatientData(
        {
          doctor_id: docFirebaseAuthUser.uid,
          patient_id: patient.uid,
          [key]: val,
        },
        token,
      )
    }
  }

  onTimelineChanged(val) {
    this.setState({ timeline: val })

    if (this.state.timeline.value !== val.value) {
      // timeline changed
      const { patient, token } = this.props
      this.setState({ loading: true })
      this.props.addPatientProgress(null)
      this.props.fetchPatientProgressData(patient.uid, token, val.value)
    }
    if (this.state.checked) {
      const { docFirebaseAuthUser, patient, token } = this.props
      this.props.updatePatientData(
        {
          doctor_id: docFirebaseAuthUser.uid,
          patient_id: patient.uid,
          'preferences.timeline': val,
        },
        token,
      )
    }

    // this.getSymptomProgressData(val.value)
  }

  onChecked(e) {
    const { checked } = e.target
    this.setState({ checked })

    const { docFirebaseAuthUser, patient, token } = this.props

    if (checked) {
      this.props.updatePatientData(
        {
          doctor_id: docFirebaseAuthUser.uid,
          patient_id: patient.uid,
          preferences: {
            vitalOptions: this.state.vitalOptions,
            labOptions: this.state.labOptions,
            lifestyleOptions: this.state.lifestyleOptions,
            timeline: this.state.timeline,
            checked: true,
          },
        },
        token,
      )
    } else {
      this.props.updatePatientData(
        {
          doctor_id: docFirebaseAuthUser.uid,
          patient_id: patient.uid,
          preferences: {
            checked: false,
          },
        },
        token,
      )
    }
  }

  calculateBMIValues(height, weight, gender, age) {
    const bmi = (703 * weight) / Math.pow(height, 2)
    let bfp = null
    let bmr = null
    switch (gender) {
      case 'Male':
        bfp = 1.2 * bmi + 0.23 * age - 16.2
        bmr = 10 * weight * 0.454 + 6.25 * height * 2.54 - 5 * age + 5
        break
      case 'Female':
        bfp = 1.2 * bmi + 0.23 * age - 5.4
        bmr = 10 * weight * 0.454 + 6.25 * height * 2.54 - 5 * age - 161
    }

    return Math.round(bfp)
  }

  getTooltip(option, progress, isLab) {
    let bmiValues
    let bmiRemark
    const BMI_RANGES = [
      [0, 18.4],
      [18.5, 24.9],
      [25, 29.9],
      [30, 100],
    ]
    if (option.label === 'Weight' || option.label === 'Body Fat') {
      const { patientCore } = this.props
      bmiValues = Helpers.calculateBMIValues(
        patientCore.height,
        patientCore.weight_update || patientCore.weight,
        patientCore.gender,
        moment().diff(moment(patientCore.dob), 'years'),
      )
      if (
        bmiValues.bmi >= BMI_RANGES[0][0] &&
        bmiValues.bmi <= BMI_RANGES[0][1]
      )
        bmiRemark = 'Underweight'
      else if (
        bmiValues.bmi >= BMI_RANGES[1][0] &&
        bmiValues.bmi <= BMI_RANGES[1][1]
      )
        bmiRemark = 'Normal/Healthy'
      else if (
        bmiValues.bmi >= BMI_RANGES[2][0] &&
        bmiValues.bmi <= BMI_RANGES[2][1]
      )
        bmiRemark = 'Overweight'
      else bmiRemark = 'Obese'
    }

    const lines = []
    if (option.label === 'Blood Pressure') {
      lines.push(
        <Line
          key="Systolic"
          name="Systolic"
          type="monotone"
          dataKey="value"
          stroke="#606060"
        />,
      )
      lines.push(
        <Line
          key="Diastolic"
          name="Diastolic"
          type="monotone"
          dataKey="lower"
          stroke="#A9A9A9"
        />,
      )
    } else if (option.label === 'Calories') {
      lines.push(
        <Line
          key="resting"
          name="Resting Calories(kcal)"
          type="monotone"
          dataKey="resting"
          stroke="#606060"
        />,
      )
      lines.push(
        <Line
          key="active"
          name="Active Calories(kcal)"
          type="monotone"
          dataKey="active"
          stroke="#A9A9A9"
        />,
      )
      lines.push(
        <Line
          key="total"
          name="Total Calories(kcal)"
          type="monotone"
          dataKey="total"
          stroke={Constants.primaryTheme}
        />,
      )
    } else {
      lines.push(
        <Line
          key="value"
          name={`${option.label} (${option.unit})`}
          type="monotone"
          dataKey="value"
          stroke="gray"
        />,
      )
    }

    return (
      <ReactTooltip
        id={option.label}
        textColor="black"
        backgroundColor="white"
        effect="solid"
        className={Styles.hoverChatWrapper}
      >
        <div>
          <LineChart
            width={500}
            height={400}
            data={progress}
            margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
          >
            <Legend verticalAlign="top" />
            {lines}
            <XAxis
              dataKey="date"
              stroke="#000000cc"
              tickFormatter={(tickItem) => {
                if (option.lab) return moment(tickItem).format('D-MMM-YYYY')
                return tickItem
              }}
            />
            <YAxis domain={option.chartDomain} stroke="#000000cc" />
            <Tooltip />
          </LineChart>

          {(option.label === 'Weight' || option.label === 'Body Fat') && (
            <p
              style={{ width: '100%', textAlign: 'center', fontWeight: 'bold' }}
            >
              BMI: {`${bmiValues.bmi} (${bmiRemark})`}{' '}
            </p>
          )}
        </div>
      </ReactTooltip>
    )
  }

  getSnapshotAndProgressDataCalories() {
    const data = this.state.vitalsProgressData
    const toReturn = { progress: [] }
    data.vitals.forEach((entry) => {
      const resting = entry.resting_calories
      const active = entry.active_calories
      if (typeof resting === 'number' && typeof active === 'number') {
        if (!toReturn.snapshot) toReturn.snapshot = Math.round(resting + active)

        toReturn.progress.push({
          resting: Math.round(resting),
          active: Math.round(active),
          total: Math.round(resting + active),
          date: moment(entry.created_at).format('D-MMM'),
        })
      }
    })

    return toReturn
  }

  getSnapshotAndProgressDataLabs(option, key) {
    const labsData = this.props?.labs
    const toReturn = { progress: [] }
    labsData[key] &&
      labsData[key].forEach((entry) => {
        if (entry && entry.value !== 0) {
          if (!toReturn.snapshot) toReturn.snapshot = entry.value
          toReturn.progress.push({
            value: entry.value,
            date: entry.date,
          })
        }
      })

    return toReturn
  }

  getSnapshotAndProgressData(option, key, vitalsOrUpdates) {
    const { rpmOrders } = this.props
    let data = this.state.vitalsProgressData
    const toReturn = { progress: [] }
    const roundedValuesForKeys = [
      'heart_rate_variability',
      'resting_calories',
      'active_calories',
    ]
      if(rpmOrders.length){
        data[vitalsOrUpdates] = data[vitalsOrUpdates].filter((item) => item.rpm === true)
      }    
    data[vitalsOrUpdates].forEach((entry) => {
      const value = entry[key]
      if (value && value !== 0) {
        if (!toReturn.snapshot)
          toReturn.snapshot = roundedValuesForKeys.includes(key)
            ? Math.round(value)
            : value
        toReturn.progress.push({
          value: roundedValuesForKeys.includes(key) ? Math.round(value) : value,
          date: moment(entry.created_at).format('D-MMM'),
        })
      }
    })

    if (key === 'weight' && (!data.updates || data.updates.length === 0)) {
      const { patientCore } = this.props
      toReturn.snapshot = patientCore.weight_update || patientCore.weight
    }

    return toReturn
  }

  getSnapshotAndProgressFat(key, vitalsOrUpdates) {
    const { patientCore } = this.props
    const age =
      patientCore.dob && moment().diff(moment(patientCore.dob), 'years')
    const data = this.state.vitalsProgressData
    const toReturn = { progress: [] }
    data.updates.forEach((entry) => {
      const value = entry.weight
      if (value && value !== 0) {
        toReturn.progress.push({
          value: this.calculateBMIValues(
            patientCore.height,
            entry.weight,
            patientCore.gender,
            age,
          ),
          date: moment(entry.created_at).format('D-MMM'),
        })
      }
    })

    if (!toReturn.snapshot)
      toReturn.snapshot = this.calculateBMIValues(
        patientCore.height,
        patientCore.weight_update || patientCore.weight,
        patientCore.gender,
        age,
      )

    return toReturn
  }

  getSnapshotAndProgressDataBP() {
    const { rpmOrders } = this.props
    let data = this.state.vitalsProgressData?.vitals
    const toReturn = { progress: [] }
    if(rpmOrders.length){
      data = data.filter((item) => item.rpm === true)
    }
    data.forEach((entry) => {
      const value = entry.blood_pressure
      if (
        value &&
        value.lower &&
        value.upper &&
        value.lower !== 0 &&
        value.upper !== 0
      ) {
        if (!toReturn.snapshot)
          toReturn.snapshot = `${value.upper}/${value.lower}`
        toReturn.progress.push({
          value: value.upper,
          lower: value.lower,
          date: moment(entry.created_at).format('D-MMM'),
        })
      }
    })

    return toReturn
  }

  getSnapshotAndProgressAverage(key) {
    const data = this.state.vitalsProgressData
    const toReturn = { progress: [] }
    let total = 0
    let denom = 0
    data.vitals.forEach((entry) => {
      const value = entry[key]
      if (value && value !== 0) {
        total += Number(value)
        denom += 1
        toReturn.progress.push({
          value: Number(value).toFixed(1),
          date: moment(entry.created_at).format('D-MMM'),
        })
      }
    })

    toReturn.snapshot = denom === 0 ? '-' : (total / denom).toFixed(1)

    return toReturn
  }

  getColorRangeAddedWeight(option, height, weight) {
    if (!height || !weight) return GREEN_RANGE_COLOR

    const bmi = (703 * weight) / Math.pow(height, 2)
    const { ranges } = option
    const greenRange = ranges.green
    const yellowRange = ranges.yellow

    if (bmi >= greenRange[0][0] && bmi <= greenRange[0][1])
      return GREEN_RANGE_COLOR
    if (
      (bmi >= yellowRange[0][0] && bmi <= yellowRange[0][1]) ||
      (bmi >= yellowRange[1][0] && bmi <= yellowRange[1][1])
    )
      return YELLOW_RANGE_COLOR
    return RED_RANGE_COLOR
  }

  getColorRangeAddedSpO2(option, value) {
    if (!value) return GREEN_RANGE_COLOR
    const { ranges } = option
    if (value.value < ranges) return RED_RANGE_COLOR

    return GREEN_RANGE_COLOR
  }

  getColorRangeAddedBP(option, values) {
    const { ranges } = option
    const sysRange = ranges.sys
    const diaRange = ranges.dia

    if (!values) return GREEN_RANGE_COLOR

    const upper = values.value
    const { lower } = values
    if (upper <= sysRange[0] && lower <= diaRange[0]) return GREEN_RANGE_COLOR

    if (
      (upper >= sysRange[1][0] && upper <= sysRange[1][1]) ||
      (lower >= diaRange[1][0] && lower <= diaRange[1][1])
    )
      return YELLOW_RANGE_COLOR

    return RED_RANGE_COLOR
  }

  renderEmptyOrNullValue(option) {
    return (
      <div
        key={option.value}
        style={{
          width: 160,
          marginRight: 5,
          marginLeft: 5,
          height: 120,
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          cursor: 'pointer',
        }}
      >
        <p style={{ fontWeight: 'bold', fontSize: 20 }}>
          N/A
          {option.label === 'Body Fat' && (
            <span style={{ marginLeft: 2 }}>%</span>
          )}
        </p>
        {option.label === 'Body Fat' ? <p>-</p> : <p>{option.unit}</p>}
        <div
          style={{
            width: '90%',
            backgroundColor: GREEN_RANGE_COLOR,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '4px 2px',
            borderRadius: 8,
          }}
        >
          {option.label === 'SpO2' ? (
            <p>
              SpO<sub>2</sub>
            </p>
          ) : (
            <p>{option.label}</p>
          )}
        </div>
      </div>
    )
  }

  renderLabValue(option, key) {
    const { labs } = this.props
    const data = this.getSnapshotAndProgressDataLabs(option, key)
    return (
      <div
        onClick={() =>
          this.setState({
            modalIsOpen: true,
            modalOption: option,
            modalProgress: data.progress,
          })
        }
        data-tip
        data-for={option.label}
        key={option.value}
        style={{
          width: 160,
          marginRight: 5,
          marginLeft: 5,
          height: 120,
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          cursor: 'pointer',
        }}
      >
        {data.snapshot ? (
          <p style={{ fontWeight: 'bold', fontSize: 20 }}>{data.snapshot}</p>
        ) : (
          <p style={{ fontWeight: 'bold', fontSize: 20 }}>- </p>
        )}
        <p>{option.unit}</p>

        <div
          style={{
            width: '90%',
            backgroundColor: GREEN_RANGE_COLOR,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '4px 2px',
            borderRadius: 8,
          }}
        >
          <p style={{ textAlign: 'center' }}>{option.label}</p>
        </div>
        {this.getTooltip(option, data.progress)}
      </div>
    )
  }

  renderValue(option, key, vitalsOrUpdates) {
    const { vitalsProgressData } = this.state
    if (
      vitalsOrUpdates === 'vitals' &&
      (!vitalsProgressData ||
        !vitalsProgressData.vitals ||
        vitalsProgressData.vitals.length === 0)
    ) {
      return this.renderEmptyOrNullValue(option)
    }

    if (
      vitalsOrUpdates === 'updates' &&
      (!vitalsProgressData ||
        !vitalsProgressData.updates ||
        vitalsProgressData.updates.length === 0) &&
      key !== 'weight'
    ) {
      return this.renderEmptyOrNullValue(option)
    }

    let data
    if (
      option.label === 'Average Sleep' ||
      option.label === 'Average Activity' ||
      option.label === 'Average Steps'
    )
      data = this.getSnapshotAndProgressAverage(key)
    else if (option.label === 'Body Fat')
      data = this.getSnapshotAndProgressFat()
    else if (option.label === 'Blood Pressure')
      data = this.getSnapshotAndProgressDataBP()
    else if (option.label === 'Calories')
      data = this.getSnapshotAndProgressDataCalories()
    else data = this.getSnapshotAndProgressData(option, key, vitalsOrUpdates)

    let color = GREEN_RANGE_COLOR
    if (option.ranges) {
      if (option.label === 'Weight' || option.label === 'Body Fat') {
        // because we need to assign range based on BMI, its a different method
        const { patientCore } = this.props
        color = this.getColorRangeAddedWeight(
          option,
          patientCore.height,
          patientCore.weight_update || patientCore.weight,
        )
      } else if (option.label === 'Blood Pressure') {
        color = this.getColorRangeAddedBP(option, data.progress[0])
      } else if (option.label === 'SpO2') {
        color = this.getColorRangeAddedSpO2(option, data.progress[0])
      } else {
        const value = data.snapshot
        const { ranges } = option
        const greenRange = ranges.green
        const yellowRange = ranges.yellow

        if (value >= greenRange[0][0] && value <= greenRange[0][1])
          color = GREEN_RANGE_COLOR
        else if (
          (value >= yellowRange[0][0] && value <= yellowRange[0][1]) ||
          (value >= yellowRange[1][0] && value <= yellowRange[1][1])
        )
          color = YELLOW_RANGE_COLOR
        else color = RED_RANGE_COLOR

        if (!value) color = GREEN_RANGE_COLOR
      }
    }

    if (data.progress) data.progress.reverse()

    return (
      <div
        onClick={() =>
          this.setState({
            modalIsOpen: true,
            modalOption: option,
            modalProgress: data.progress,
          })
        }
        data-tip
        data-for={option.label}
        key={option.value}
        style={{
          width: 160,
          marginRight: 5,
          marginLeft: 5,
          height: 120,
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          cursor: 'pointer',
        }}
      >
        {data.snapshot ? (
          <p style={{ fontWeight: 'bold', fontSize: 20 }}>
            {data.snapshot}
            {(option.label === 'Body Fat' || option.label === 'SpO2') && (
              <span style={{ marginLeft: 2 }}>%</span>
            )}
          </p>
        ) : (
          <p style={{ fontWeight: 'bold', fontSize: 20 }}>- </p>
        )}

        {option.label === 'Body Fat' ? <p>-</p> : <p>{option.unit}</p>}
        <div
          style={{
            width: '90%',
            backgroundColor: color,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '4px 2px',
            borderRadius: 8,
          }}
        >
          {option.label === 'SpO2' ? (
            <p>
              SpO<sub>2</sub>
            </p>
          ) : (
            <p>{option.label}</p>
          )}
        </div>
        {this.getTooltip(option, data.progress)}
      </div>
    )
  }

  renderEmptyValue(option) {
    return (
      <div
        key={option.value}
        style={{
          width: 160,
          marginRight: 5,
          marginLeft: 5,
          height: 120,
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          cursor: 'pointer',
        }}
      >
        <p style={{ fontWeight: 'bold', fontSize: 20 }}>N/A</p>
        <p>{option.unit}</p>
        <div
          style={{
            width: '90%',
            backgroundColor: '#20A89266',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '4px 2px',
            borderRadius: 8,
          }}
        >
          {option.label === 'SpO2' ? (
            <p>
              SpO<sub>2</sub>
            </p>
          ) : (
            <p>{option.label}</p>
          )}
        </div>
      </div>
    )
  }

  renderSelectedEntries(row) {
    if (!this.state.vitalsProgressData) return null

    const toReturn = []
    let options = []
    const temp = row.toLowerCase()
    switch (temp) {
      case 'vitals':
        if (!this.state.vitalOptions) return
        options = this.state.vitalOptions
        break
      case 'labs':
        if (!this.state.labOptions) return
        options = this.state.labOptions
        break
      case 'lifestyle':
        if (!this.state.lifestyleOptions) return
        options = this.state.lifestyleOptions
        break
      default:
    }

    options.forEach((option) => {
      switch (option.label) {
        case 'Heart Rate':
          toReturn.push(this.renderValue(option, 'heart_rate', 'vitals')) // key is the key for object reutrned by API
          break
        case 'Respiratory Rate':
          toReturn.push(this.renderValue(option, 'respiration_rate', 'vitals')) // key is the key for object reutrned by API
          break
        case 'Glucose':
          toReturn.push(this.renderValue(option, 'glucose', 'vitals')) // key is the key for object reutrned by API
          break
        case 'Temperature':
          toReturn.push(this.renderValue(option, 'temperature', 'vitals')) // key is the key for object reutrned by API
          break
        case 'Weight':
          toReturn.push(this.renderValue(option, 'weight', 'updates')) // key is the key for object reutrned by API
          break
        case 'Average Sleep':
          toReturn.push(this.renderValue(option, 'sleep', 'vitals')) // key is the key for object reutrned by API
          break
        case 'Average Activity':
          toReturn.push(this.renderValue(option, 'activity_time', 'vitals')) // key is the key for object reutrned by API
          break
        case 'Body Fat':
          toReturn.push(this.renderValue(option, 'weight', 'updates')) // key is the key for object reutrned by API
          break
        case 'Blood Pressure':
          toReturn.push(this.renderValue(option, 'blood_pressure', 'vitals')) // key is the key for object reutrned by API
          break
        case 'SpO2':
          toReturn.push(this.renderValue(option, 'oxygen_saturation', 'vitals')) // key is the key for object reutrned by API
          break
        case 'HRV':
          toReturn.push(
            this.renderValue(option, 'heart_rate_variability', 'vitals'),
          ) // key is the key for object reutrned by API
          break
        case 'Average Steps':
          toReturn.push(this.renderValue(option, 'steps', 'vitals')) // key is the key for object reutrned by API
          break
        case 'Calories':
          toReturn.push(this.renderValue(option, 'calories', 'vitals')) // key is the key for object reutrned by API
          break
        case 'LDL-C':
          toReturn.push(this.renderLabValue(option, 'ldlc'))
          break
        case 'HDL':
          toReturn.push(this.renderLabValue(option, 'hdl'))
          break
        case 'Triglyceride':
          toReturn.push(this.renderLabValue(option, 'triglycerides'))
          break
        case 'Triglyceride Nonfasting':
          toReturn.push(this.renderLabValue(option, 'triglycerides nonfasting'))
          break
        default:
          toReturn.push(this.renderEmptyValue(option)) // key is the key for object reutrned by API
      }
    })

    return toReturn
  }

  renderSections(row) {
    let options = []
    let stateOptionKey = ''

    const temp = row.toLowerCase()
    switch (temp) {
      case 'vitals':
        options = VITAL_OPTIONS
        stateOptionKey = 'vitalOptions'
        break
      case 'labs':
        options = LAB_OPTIONS
        stateOptionKey = 'labOptions'
        break
      case 'lifestyle':
        options = LIFESTYLE_OPTIONS
        stateOptionKey = 'lifestyleOptions'
        break
      default:
    }

    return (
      <div className={Styles.sectionDivider}>
        <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
          <h5 style={{ color: Constants.primaryTheme }}>{row}</h5>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              marginLeft: 50,
              flexGrow: 1,
            }}
          >
            <div style={{ flexGrow: 1 }}>
              <Select
                placeholder={`Select ${temp}  ....`}
                isMulti
                style={{ color: 'red' }}
                options={options}
                value={this.state[stateOptionKey]}
                onChange={(val) =>
                  this.onSelectEntryChanged(stateOptionKey, val)
                }
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </div>
        </div>

        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            marginTop: 30,
            flexWrap: 'wrap',
          }}
        >
          {this.state.vitalsLoading ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: '100%',
              }}
            >
              <div className={GlobalStyles.loader} />
            </div>
          ) : (
            this.renderSelectedEntries(row)
          )}
        </div>
      </div>
    )
  }

  renderTrackersGraph() {
    const graphWidth = window.innerWidth / 2
    const data = this.state.symptomProgressData
    if (!data) return null

    return (
      <div
        style={{
          marginBottom: 40,
          minWidth: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          boxShadow: '5px 5px 5px 5px grey',
        }}
      >
        <h4>Lifestyle Health</h4>
        <LineChart
          width={graphWidth}
          height={350}
          data={data.progress}
          margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
        >
          <Legend verticalAlign="top" />
          <Line
            dot={false}
            name="Physical Activity"
            type="monotone"
            dataKey="physical"
            stroke={Helpers.random_hex_color_code()}
          />
          <Line
            dot={false}
            name="Healthy Eating"
            type="monotone"
            dataKey="eating"
            stroke={Helpers.random_hex_color_code()}
          />
          <Line
            dot={false}
            name="Stress"
            type="monotone"
            dataKey="stress"
            stroke={Helpers.random_hex_color_code()}
          />
          <Line
            dot={false}
            name="Mood"
            type="monotone"
            dataKey="mood"
            stroke={Helpers.random_hex_color_code()}
          />
          <Line
            dot={false}
            name="Inflammation"
            type="monotone"
            dataKey="inflammation"
            stroke={Helpers.random_hex_color_code()}
          />
          <XAxis
            dataKey="created_at"
            tickFormatter={(tickItem) => moment(tickItem).format('D-MMM')}
          />
          <YAxis />
          <Tooltip />
        </LineChart>
      </div>
    )
  }

  renderSymptomGraph() {
    const graphWidth = window.innerWidth / 2
    const data = this.state.symptomProgressData
    if (!data) return null

    const symptomsMap = {}
    data.symptoms.forEach((each) => {
      symptomsMap[each.node] = each.symptom
    })

    const chartData = []
    data.progress.forEach((each) => {
      const toPut = {}
      toPut.date = moment(each.created_at, 'MM/DD/YYYY').format('D-MMM')
      each.symptom_levels.forEach((symptom) => {
        const key = symptomsMap[symptom._id]
        toPut[key] = symptom.level
      })
      chartData.push(toPut)
    })

    const lines = data.symptoms.map((x, i) => (
      <Line
        dot={false}
        name={x.symptom}
        type="monotone"
        dataKey={x.symptom}
        stroke={Helpers.random_hex_color_code()}
      />
    ))

    return (
      <div
        style={{
          marginBottom: 100,
          marginTop: 40,
          minWidth: '100%',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          boxShadow: '5px 5px 5px 5px grey',
        }}
      >
        <h4>Symptoms</h4>
        <LineChart
          width={graphWidth}
          height={350}
          data={chartData}
          margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
        >
          <Legend verticalAlign="top" />
          {lines}
          <XAxis padding={{ top: 20 }} dataKey="date" type="category" />
          <YAxis />
          <Tooltip />
        </LineChart>
      </div>
    )
  }

  renderTrackers() {
    return (
      <div
        className={Styles.sectionDivider}
        style={{ minHeight: 400, borderBottomWidth: 0, marginBottom: 40 }}
      >
        <div
          style={{ flexDirection: 'row', display: 'flex', minWidth: '100%' }}
        >
          <h4 style={{ marginRight: 40 }}>Patient Reported Outcomes</h4>

          <div style={{ width: 300 }}>
            <Select
              options={[
                { label: 'Weekly', value: 'weekly' },
                { label: 'Monthly', value: 'monthly' },
                { label: 'Semi Annual', value: 'semiAnnual' },
                { label: 'Complete History', value: 'complete' },
              ]}
              value={this.state.timeline}
              onChange={(val) => this.onTimelineChanged(val)}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
        </div>

        {this.state.symptomLoading ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              height: 700,
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        ) : (
          <div>
            {this.renderSymptomGraph()}
            {this.renderTrackersGraph()}
          </div>
        )}
      </div>
    )
  }

  renderModal() {
    const windowHeight = window.innerHeight
    const { modalOption } = this.state
    const progressData = this.state.modalProgress
    const modalWidth = window.innerWidth * 0.6
    const lines = []
    if (modalOption.label === 'Blood Pressure') {
      lines.push(
        <Line
          key="Systolic"
          name="Systolic"
          type="monotone"
          dataKey="value"
          stroke={Constants.graphLineOne}
        />,
      )
      lines.push(
        <Line
          key="Diastolic"
          name="Diastolic"
          type="monotone"
          dataKey="lower"
          stroke={Constants.graphLineTwo}
        />,
      )
    } else if (modalOption.label === 'Calories') {
      lines.push(
        <Line
          key="Resting Calories(kcal)"
          name="Resting Calories(kcal)"
          type="monotone"
          dataKey="resting"
          stroke={Constants.graphLineOne}
        />,
      )
      lines.push(
        <Line
          key="Active Calories(kcal)"
          name="Active Calories(kcal)"
          type="monotone"
          dataKey="active"
          stroke={Constants.graphLineTwo}
        />,
      )
      lines.push(
        <Line
          key="Total Calories(kcal)"
          name="Total Calories(kcal)"
          type="monotone"
          dataKey="total"
          stroke={Constants.primaryTheme}
        />,
      )
    } else {
      lines.push(
        <Line
          key="value"
          name={`${modalOption.label} (${modalOption.unit})`}
          type="monotone"
          dataKey="value"
          stroke={Constants.graphLineOne}
        />,
      )
    }

    return (
      <div style={{ height: '100%', width: '100%' }}>
        <p
          className={Styles.closeModalBtn}
          onClick={() => this.setState({ modalIsOpen: false })}
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          {modalOption.label}
        </h4>

        <div>
          <LineChart
            width={modalWidth - 80}
            height={windowHeight / 1.6}
            data={progressData}
            margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
          >
            <Legend verticalAlign="top" />
            {lines}
            <XAxis
              dataKey="date"
              stroke="#000000cc"
              tickFormatter={(tickItem) => {
                if (modalOption.lab)
                  return moment(tickItem).format('D-MMM-YYYY')
                return tickItem
              }}
            />
            <YAxis domain={modalOption.chartDomain} stroke="#000000cc" />

            <Tooltip />
          </LineChart>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div
        className={GlobalStyles.container}
        style={{ backgroundColor: 'white' }}
      >
        {this.state.loading ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              height: '100vh',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        ) : (
          <div style={{ width: '100%', marginTop: 10 }}>
            <h4
              style={{
                color: Constants.primaryTheme,
                width: '100%',
                textAlign: 'center',
              }}
            >
              Patient Progress
            </h4>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                paddingLeft: 40,
                alignItems: 'center',
              }}
            >
              <p style={{ fontWeight: 'bold', color: 'gray', marginRight: 20 }}>
                Timeline
              </p>
              <div style={{ width: 300 }}>
                <Select
                  placeholder="Select timeline..."
                  options={[
                    { label: 'Weekly', value: 'weekly' },
                    { label: 'Monthly', value: 'monthly' },
                    { label: 'Semi Annual', value: 'semiAnnual' },
                    { label: 'Complete History', value: 'complete' },
                  ]}
                  value={this.state.timeline}
                  onChange={(val) => this.onTimelineChanged(val)}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#20A89233',
                      primary: '#20A892',
                    },
                  })}
                />
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                paddingLeft: 40,
                marginTop: 10,
              }}
            >
              <input
                type="checkbox"
                style={{ marginRight: 10 }}
                onChange={this.onChecked.bind(this)}
                checked={this.state.checked}
              />
              <p>Save this search</p>
            </div>
            <Modal
              ariaHideApp={false}
              onRequestClose={() => this.setState({ modalIsOpen: false })}
              isOpen={this.state.modalIsOpen}
              style={customProgressModalStyles}
              contentLabel="Modal"
            >
              {this.renderModal()}
            </Modal>

            {this.renderSections('Vitals')}
            {this.renderSections('Labs')}
            {this.renderSections('Lifestyle')}
          </div>
        )}

        <div id="bottom" style={{ visibility: 'hidden', marginTop: 400 }}>
          asdasd
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state /* , ownProps */) => ({
  patient: state.patientReducer.patient,
  patientCore: state.patientReducer.patientCore,
  color: state.patientReducer.color,
  token: state.authReducer.token,
  patientProgress: state.patientReducer.patientProgress,
  docFirebaseAuthUser: state.userReducer.firebaseAuthUser,
  mongoUser: state.userReducer.mongoUser,
  medications: state.patientReducer.medications,
  conditions: state.patientReducer.conditions,
  labs: state.patientReducer.labs,
  rpmOrders: state.patientReducer.rpmOrders
})

const mapDispatchToProps = {
  addPatient,
  updatePatientData,
  fetchPatientProgressData,
  addPatientProgress,
}

export default connect(mapStateToProps, mapDispatchToProps)(Vitals)
