import React, {useEffect, useState} from "react";
import Styles from "../styles/BodySystems.module.scss";
import {
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
  ReferenceLine,
  ReferenceArea,
  AreaChart,
  Label,
  CartesianGrid,
  Area
} from "recharts";
import moment from "moment";
import Constants from "../../../../values";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import LoincSearchModal from "../../../../components/LoincSearchModal";
import GlobalStyles from "../../../styles/global.module.scss";
import Button from "react-bootstrap/Button";
import QuestionnaireModal from "../../../../components/QuestionnaireModal";
import ChartModal from "../../../../components/ChartModal";
import QChartToolTip from "../../../../components/QChartToolTip";
import {getQuestionnaireResults} from "../../../../components/getQuestionnaireResults";
import {renderQuestionnaireResults} from "../../../../components/renderQuestionnaireResults";
import axios from 'axios'
import {useSelector} from "react-redux";
import Config from "../../../../config";
import GeneralChart from "../../../../components/musculoSkeletal/GeneralChart";

let GREEN_RANGE_COLOR = '#20A89266'

const Gastrointestinal = (props) => {
  let {screeningData, dateOptions, onResRadioClick} = props
  const [showSearchModal, setShowSearchModal] = useState(false)
  const [selectedLabs, setSelectedLabs] = useState([])
  const [checked, setChecked] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState([])
  const token = useSelector(state => state.authReducer.token)
  const patient = useSelector(state => state.patientReducer.patient)
  const [showQuestionnaireModal, setShowQuestionnaireModal] = useState(false)
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState([])
  const [questionnaireChecked, setQuestionnaireChecked] = useState(false)
  const [anxietyScreening, setAnxietyScreening] = useState(null)
  const [mentalHealthScreening, setMentalHealthScreening] = useState(null)
  const [crohnsScreening, setCrohnsScreening] = useState(null)
  const [colitisScreening, setColitisScreening] = useState(null)
  const [physicalScreening, setPhysicalScreening] = useState(null)
  const [burnoutScreening, setBurnoutScreening] = useState(null)
  const [aceScreening, setAceScreening] = useState(null)
  const [sleepapneaScreening, setSleepapneaScreening] = useState(null)
  const [globalhealthScreening, setGlobalhealthScreening] = useState(null)
  const [showChartModal, setShowChartModal] = useState(false)
  const [modalChartData, setModalChartData] = useState({data: [], path: '', name: ''})

  useEffect(() => {
    let patient = props.patient
    if(patient?.body_systems_preferences?.gastro?.length > 0) {
      setSelectedLabs(patient.body_systems_preferences.gastro)
      setChecked(true)
    } else {
      setChecked(false)
    }

  },[props.patient])

  const onLabsSelected = (entries) => {
    setSelectedLabs(entries || [])
    setShowSearchModal(false)
    if(checked) {
      props.updatePreferences({
        'body_systems_preferences.gastro': entries || []
      })
    }
  }

  const onChecked = (e) => {
    let data
    if(e.target.checked) {
      //save the preferences
      data = {
        'body_systems_preferences.gastro': selectedLabs || []
      }
      onQuestionnaireChecked(true)
    } else {
      data = {
        'body_systems_preferences.gastro': []
      }
      onQuestionnaireChecked(false)
    }
    setChecked(e.target.checked)
    props.updatePreferences(data)
  }

  useEffect(() => {
    let patient = props.patient
    if(patient?.questionnaire_preferences?.gastro?.length > 0) {
      setSelectedQuestionnaire(patient.questionnaire_preferences.gastro)
      setQuestionnaireChecked(true)
    } else {
      setQuestionnaireChecked(false)
    }

  },[props.patient])

  useEffect(() => {
    getAllScreeners()
  }, [selectedQuestionnaire])

  const getAllScreeners = () => {
    selectedQuestionnaire && selectedQuestionnaire.forEach((screener) => {
      getScreening(screener.path)
    })
  }

  const getScreening = (path) => {
    const url = Config.BACKEND_URL + 'screenings/' + path
    axios({
      method: 'get',
      url,
      headers: { Authorization: `JWT ${token}`, x_firebase_id: props.patient.patient_id, complete: true },
    })
      .then(({ data }) => {
        setData(path, data)
      })
      .catch((err) => {
        console.log('error when getting screening', err)
      })
  }

  const setData = (path, data) => {
    switch (path) {
      case 'anxiety':
        setAnxietyScreening(data)
        break
      case 'mental':
        setMentalHealthScreening(data)
        break
      case 'gastro':
        setCrohnsScreening(data)
        break
      case 'colitis':
        setColitisScreening(data)
        break
      case 'physical':
        setPhysicalScreening(data)
        break
      case 'burnout':
        setBurnoutScreening(data)
        break
      case 'ace':
        setAceScreening(data)
        break
      case 'sleepapnea':
        setSleepapneaScreening(data)
        break
      case 'globalhealth':
        setGlobalhealthScreening(data)
        break
      default:
    }
  }

  const getScreeningData = (path) => {
    switch (path) {
      case 'anxiety':
        return anxietyScreening
      case 'mental':
        return mentalHealthScreening
      case 'gastro':
        return crohnsScreening
      case 'colitis':
        return colitisScreening
      case 'physical':
        return physicalScreening
      case 'burnout':
        return burnoutScreening
      case 'ace':
        return aceScreening
      case 'sleepapnea':
        return sleepapneaScreening
      case 'globalhealth':
        return globalhealthScreening	
      default:
        return null
    }
  }

  const hideChartModal = () => {
    setShowChartModal(false)
  }

  const onQuestionnaireSelected = (entries) => {
    setSelectedQuestionnaire(entries || [])
    setShowQuestionnaireModal(false)
    if(checked) {
      props.updatePreferences({
        'questionnaire_preferences.gastro': entries || []
      })
    }
  }

  const onQuestionnaireChecked = (isChecked) => {
    let data
    if(isChecked) {
      //save the preferences
      data = {
        'questionnaire_preferences.gastro': selectedQuestionnaire || []
      }
    } else {
      data = {
        'questionnaire_preferences.gastro': []
      }
    }
    // setQuestionnaireChecked(e.target.checked)
    props.updatePreferences(data)
  }


  function getCrohnsReferenceAndLine() {
    let toReturn = []
    // toReturn.push(<Line dot={false} name={'Harvey Bradshaw Index'}  type="monotone" dataKey={'harvey_bradshaw_index'} stroke={Constants.graphLineOne} />)
    toReturn.push(<ReferenceArea y1={0} y2={4} fill={Constants.graphReferenceColors[0]} fillOpacity={0.1}/>)
    toReturn.push(<ReferenceArea y1={4} y2={7} fill={Constants.graphReferenceColors[1]} fillOpacity={0.1}/>)
    toReturn.push(<ReferenceArea y1={7} y2={16} fill={Constants.graphReferenceColors[2]} fillOpacity={0.1}/>)
    toReturn.push(<ReferenceArea y1={16} fill={Constants.graphReferenceColors[4]} fillOpacity={0.1}/>)
    return toReturn
  }

  function getColitisReferenceAndLine() {
    let toReturn = []
    // toReturn.push(<Line dot={false} name={'P-SCCAI'}  type="monotone" dataKey={'colitisScore'} stroke={Constants.graphLineOne} />)
    toReturn.push(<ReferenceArea y1={0} y2={5} fill={Constants.graphReferenceColors[0]} fillOpacity={0.1}/>)
    toReturn.push(<ReferenceArea y1={5} y2={10} fill={Constants.graphReferenceColors[2]} fillOpacity={0.1}/>)
    toReturn.push(<ReferenceArea y1={10} fill={Constants.graphReferenceColors[4]} fillOpacity={0.1}/>)

    return toReturn
  }

  const renderNewLabValue = (option) => {
    return (
      <div
        // onClick={() => this.setState({modalIsOpen: true, modalOption: option, modalProgress: data.progress})}
        data-tip
        data-for={option.label}
        key={option.label}
        style={{width: 180, marginRight: 10, marginLeft: 10, height: 120, alignItems: 'center', justifyContent: 'end', display: 'flex', flexDirection: 'column', cursor: 'pointer', marginBottom: 10}}>
        <div>
          {
            option.val ?
              <p style={{fontWeight: 'bold', fontSize: 16}}>{option.val}</p>
              :
              <p style={{fontWeight: 'bold', fontSize: 16}}>-</p>
          }
          <p style={{fontSize: 12}}>{option.EXAMPLE_UCUM_UNITS || option.EXAMPLE_UNITS}</p>
        </div>
        <div style={{width: '90%', backgroundColor: option.val ? GREEN_RANGE_COLOR : Constants.lightGray, display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '4px 2px', borderRadius: 8, marginTop: 15}}>
          <p style={{textAlign: 'center'}}>{option.SHORTNAME}</p>
        </div>
        {/* {this.getTooltip(option, data.progress)} */}
        <ChartTooltip option={option}/>


      </div>
    )
  }

  function renderChart(crohns) {
    let graphWidth = (window.innerWidth) / 2
    let data = props.gastroData || {}

    return (
      <LineChart width={graphWidth} height={350} data={crohns ? data.crohns : data.colitis} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
        {
          crohns ?
            getCrohnsReferenceAndLine()
            :
            getColitisReferenceAndLine()
        }

        <XAxis dataKey="created_at" tickFormatter={(tickItem) => {
          return moment(tickItem).format('D-MMM')
        }}/>
        <YAxis
          label={{ value: crohns ? 'Harvey Bradshaw Index' : 'P-SCCAI Score', angle: -90, position: 'insideLeft', offset: 10}}
          domain={crohns ? [0, 22] : [0,20]}/>
        <Tooltip content={({ active, payload, label }) => {
          let date = moment(label).format('D-MMM')
          return (
            <div className={Styles.painChartTooltip} style={{width: 240}}>
              <p>{date}</p>
              {
                crohns ?
                  <div style={{width: '100%', padding: '0px 5px'}}>
                    <p style={{fontWeight: 'bold', textAlign: 'left'}}>Harvey Bradshaw Index: <span style={{fontWeight: 'normal', marginLeft: 5}}>{payload && payload.length > 0 && payload[0].value}</span></p>
                    <p style={{fontWeight: 'bold', textAlign: 'left'}}>Status: <span style={{fontWeight: 'normal', marginLeft: 5}}>{getCrohnsStatus(payload && payload.length>0 && payload[0].payload)}</span></p>
                  </div>
                  :
                  <div style={{width: '100%', padding: '0px 5px'}}>
                    <p style={{fontWeight: 'bold'}}>P-SCCAI: <span style={{fontWeight: 'normal', marginLeft: 5}}>{payload && payload.length > 0 && payload[0].value}</span></p>
                    <p style={{fontWeight: 'bold', textAlign: 'left'}}>Status: <span style={{fontWeight: 'normal', marginLeft: 5}}>{getColitisStatus(payload && payload.length>0 && payload[0].payload)}</span></p>
                  </div>

              }
            </div>
          )
        }}/>
      </LineChart>
    )
  }

  function getCrohnsStatus(screening) {
    if(!screening)
      return 'N/A'

    if(screening.harvey_bradshaw_index < 5)
      return 'Remission'
    else if(screening.harvey_bradshaw_index >= 5 && screening.harvey_bradshaw_index <= 7)
      return 'Mild Disease'
    else if(screening.harvey_bradshaw_index >= 8 && screening.harvey_bradshaw_index <= 16)
      return 'Moderate Disease'
    else if(screening.harvey_bradshaw_index > 16 )
      return 'Severe Disease'
    else
      return 'N/A'
  }

  function getColitisStatus(screening) {
    if(!screening)
      return 'N/A'

    if(screening.colitisScore <= 5)
      return 'Remission'
    else if(screening.colitisScore >= 6 && screening.colitisScore <= 10)
      return 'Moderate'
    else if(screening.colitisScore > 10)
      return 'Active'
    else
      return 'N/A'
  }



  return (
    <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>

      <p style={{width: '100%', fontWeight: 'bold', color: Constants.primaryTheme, textAlign: 'center', marginBottom: 20, fontSize: Constants.headerSize}}>Gastrointestinal System</p>

      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 10, marginTop: 10, marginBottom: 10}}>
        <input type="checkbox" style={{marginRight: 10}} onChange={onChecked} checked={checked}/>
        <p>Save this search</p>
      </div>

      <LoincSearchModal
        selectedLabs={selectedLabs}
        onLabsSelected={onLabsSelected}
        showModal={showSearchModal}
        hideModal={() => setShowSearchModal(false)}/>

      <QuestionnaireModal
        selectedQuestionnaire={selectedQuestionnaire}
        onQuestionnaireSelected={onQuestionnaireSelected}
        showModal={showQuestionnaireModal}
        hideModal={() => setShowQuestionnaireModal(false)}/>
      <ChartModal
        data={modalChartData.data}
        path={modalChartData.path}
        name={modalChartData.name}
        getQuestionnaireResults={getQuestionnaireResults}
        showChartModal={showChartModal}
        hideChartModal={hideChartModal}
      />

      <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <p style={{fontWeight: 'bold', color: Constants.primaryTheme, width: 100}}>Biomarkers</p>
        <Button
          onClick={() => setShowSearchModal(true)}
          className={GlobalStyles.button}
          style={{width: 200, marginLeft: 20}}
          variant="primary"
        >
          Add Biomarkers
        </Button>
      </div>

      <div style={{flexDirection: 'row', display: 'flex', marginTop: 10, flexWrap: 'wrap', alignSelf: "flex-start"}}>
        {
          selectedLabs && selectedLabs.map(item => {
            return renderNewLabValue(item)
          })
        }
      </div>

      {/* <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 10, marginTop: 50}}>
        <input type="checkbox" style={{marginRight: 10}} onChange={onQuestionnaireChecked} checked={questionnaireChecked}/>
        <p>Save this search</p>
      </div> */}
      <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 30}}>
        <p style={{fontWeight: 'bold', color: Constants.primaryTheme, width: 140}}>Questionnaires</p>
        <Button
          onClick={() => setShowQuestionnaireModal(true)}
          className={GlobalStyles.button}
          style={{width: 200, marginLeft: 20}}
          variant="primary"
        >
          Add Questionnaire
        </Button>
      </div>
      <div style={{flexDirection: 'row', display: 'flex', marginTop: 10, flexWrap: 'wrap', alignSelf: "flex-start"}}>
        {
          selectedQuestionnaire && selectedQuestionnaire.map(item => {
            return renderQuestionnaireResults({option: item, getScreeningData, setModalChartData, setShowChartModal})
          })
        }
      </div>

      <p style={{fontWeight: 'bold', color: Constants.primaryTheme, marginTop: 30, marginBottom: 10}}>Patient Reported Outcomes</p>

      {/* <div className={Styles.chartClassOld} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <p style={{width: '100%', textAlign: 'center', color: Constants.primaryTheme}}>Crohn's Disease Activity</p>
        {renderChart(true)}
      </div> */}
      <GeneralChart
        token={token}
        patientId={patient?.patient_id}
        chartWidth={'74%'}
        // questionName={'physical'}
        scoreName={'harvey_bradshaw_index'}
        lineName={"Harvey Bradshaw Index"}
        yAxisName={'Harvey Bradshaw Index'}
        chartName={"Crohn's Disease Activity"}
        graphData={props?.gastroData?.crohns}
        domainRange={[0, 22]}
        referenceLine={getCrohnsReferenceAndLine}
        statusFunc={getCrohnsStatus}
      /> 
      <GeneralChart
        token={token}
        patientId={patient?.patient_id}
        chartWidth={'74%'}
        // questionName={'physical'}
        scoreName={'colitisScore'}
        lineName={"P-SCCAI"}
        yAxisName={'P-SCCAI Score'}
        chartName={"Colitis Disease Activity"}
        graphData={props?.gastroData?.colitis}
        domainRange={[0, 20]}
        referenceLine={getColitisReferenceAndLine}
        statusFunc={getColitisStatus}
      /> 

      {/* <div className={Styles.chartClassOld} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <p style={{width: '100%', textAlign: 'center', color: Constants.primaryTheme}}>Colitis Disease Activity</p>
        {renderChart(false)}
      </div> */}


    </div>
  )
}

export default Gastrointestinal

const ChartTooltip = ({option}) => {
  const [chartData, setChartData] = useState([])


  useEffect(() => {
    let temp = []
    temp.push({date: moment().toDate(), value: 3})
    temp.push({date: moment().subtract(2, 'days').toDate(), value: 5})
    temp.push({date: moment().subtract(2, 'weeks').toDate(), value: 8})
    temp.push({date: moment().subtract(3, 'weeks').toDate(), value: 4})
    setChartData(temp)
  },[option])

  return (
    <ReactTooltip id={option.label}  textColor='black' backgroundColor='white' effect='solid' className={Styles.hoverChatWrapper}>
      <div>
        <LineChart width={500} height={400} data={chartData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
          <Legend verticalAlign="top"/>
          <Line name={'Value'} type="monotone" dataKey="value" stroke={'#606060'} />
          <XAxis dataKey="date" stroke={'#000000cc'}  tickFormatter={(tickItem) => {
            return moment(tickItem).format('MMM-D')
          }}/>
          <YAxis stroke={'#000000cc'}/>
          <Tooltip />
        </LineChart>
      </div>
    </ReactTooltip>
  )
}
