import React from 'react'

const SeverityScale = ({ bgcolor, progress, height }) => {
  const Parentdiv = {
    height: height,
    width: '100%',
    backgroundColor: 'whitesmoke',
    borderRadius: 40,
    margin: '1%',
  }

  const Childdiv = {
    height: '100%',
    width: `${progress * 10}%`,
    backgroundColor: bgcolor,
    borderRadius: 40,
    textAlign: 'right',
  }

  const progresstext = {
    padding: 10,
    color: 'black',
    fontWeight: 'bold',
  }

  const labelText = {
    // padding: 10,
    color: 'black',
    // fontWeight: 900,
    margin: '0.75%',
  }

  return (
    <>
      <span style={labelText}>0</span>

      <div style={Parentdiv}>
        <div style={Childdiv}>
          <span style={progresstext}>{`${progress}`}</span>
        </div>
      </div>
      <span style={labelText}>10</span>
    </>
  )
}

export default SeverityScale
