import React, { Component } from 'react'
import 'react-chat-elements/dist/main.css'
import Header from '../../components/Header'
import GlobalStyles from '../styles/global.module.scss'
import Constants from '../../values.js'
import { connect } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import Config from '../../config'
import Styles from './styles/Faxes.module.scss'
import { Link, NavLink } from 'react-router-dom'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEye,
  faShare,
  faArchive,
  faFileArchive,
  faCheckCircle,
  faCross,
  faMinusCircle
} from '@fortawesome/free-solid-svg-icons'
import DateTimePicker from 'react-datetime-picker'
import Select from 'react-select'

import {
  getUnreadMessages,
  addPatientLP,
  addRpmPatient,
  setPatientCoreData,
  updateSideNavBar,
  stopTimer,
  resetTimer,
  startTimer,
  addVirgilE3,
  addPatient,
  userLoggedIn,
  fetchPatientCoreDate,
  fetchPatientProgressData,
  fetchPatientWellnessScreening,
  fetchPatientCalculateProgressData,
  updatePatientData,
  addPatientList,
  addMongoUser,
  updateDoctorMongoUser,
  fetchPatientConditions,
  fetchPatientMedications,
  fetchPatientLabs,
} from '../../redux/actions'
import Button from 'react-bootstrap/Button'
import Modal from 'react-modal'
import { data } from 'branch-sdk'

const axios = require('axios')

const customDocModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 10,
  },
  content: {
    zIndex: 10,
    width: '80vw',
    height: '90vh',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const sendFaxModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 10,
  },
  content: {
    zIndex: 10,
    width: '40vw',
    height: '60vh',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

class Faxes extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openDoc: false,
      modalLoading: false,
      testPDF: '',
      faxList: [],
      outboxList: [],
      showInbox: true,
      selectedTab: 'Inbox',
      sendModal: false,
      modalSchedule: new Date(),
      modalError: false,
      modalFileName: '',
      modalSenderEmail: '',
      modalRecieverFax: '',
      patientListOptions: [],
      // Initially, no file is selected
      selectedFile: null,
      forwardFax: false,
      superUser: false,
      loading: false,
    }
    this.getInbox = this.getInbox.bind(this)
    this.getOutbox = this.getOutbox.bind(this)
    this.onModalHide = this.onModalHide.bind(this)
    this.onFileChange = this.onFileChange.bind(this)
    this.onSendPressed = this.onSendPressed.bind(this)
  }

  componentDidMount() {
    if (!this.props.loggedIn) {
      this.props.history.push('/login')
      return
    }
    this.getInbox()
    this.preparePatientList()
  }

  async preparePatientList(data) {
    const { patientList } = this.props
    if (!patientList) return
    let patientListData = data || patientList
    const list = []
    Object.keys(patientListData).forEach((rpmColor) => {
      const patientsColorList = patientListData[rpmColor]
      patientsColorList.forEach((patient) => {
        list.push({
          label: `${patient.firstName} ${patient.lastName}`,
          value: patient.uid,
        })
      })
    })

    this.setState({ patientListOptions: list })
  }

  getPatientList() {
    // this.setState({loading: true})
    const { token, adminId, firebaseUser } = this.props
    const self = this
    const url =
      `${Config.BACKEND_URL}doctor/patients/list` +
      (self.state.superUser ? '?access=admin' : '')
    axios({
      method: 'get',
      url,
      headers: { x_doctor_id: adminId, Authorization: `JWT ${token}` },
    })
      .then(function (response) {
        // handle success
        self.setState({ loading: false })
        if (response.status === 200) {
          const { data } = response
          let total = []
          data &&
            Object.keys(data).forEach((color) => {
              total = total.concat(data[color])
            })
          self.props.addPatientList(data)
          self.preparePatientList(data)
        }
      })
      .catch(function (error) {
        // handle error
        console.log('error when getting patient list', error, token)
        self.setState({ loading: false })
      })
  }

  //GET ALL SRFAX INBOX FAXES
  getInbox(archive) {
    const { token, mongoUser } = this.props
    const { selectedTab } = this.state
    this.setState({ loading: true })
    let config = {
      method: 'get',
      url: Config.CHANGE_BACKEND_URL + `srfax/inbox`,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: mongoUser.firebase_id,
      },
    }
    axios(config)
      .then(({ data }) => {
        // console.log('INBOX:::', data)
        selectedTab === 'Inbox'
          ? this.setState(
              {
                faxList: data.filter(
                  (item) =>
                    !item.hasOwnProperty('archive') || item?.archive === false,
                ),
              },
              () => this.setState({ loading: false }),
            )
          : this.setState(
              {
                faxList: data.filter((item) => item.archive === true),
              },
              () => this.setState({ loading: false }),
            )

        // if (archive) {
        //   this.setState({ faxList: data }, () =>
        //     this.setState({ loading: false }),
        //   )
        // } else {
        //   this.setState(
        //     {
        //       faxList: data.filter(
        //         (item) =>
        //           !item.hasOwnProperty('archive') || item?.archive === false,
        //       ),
        //     },
        //     () => this.setState({ loading: false }),
        //   )
        // }
      })
      .catch((err) => {
        console.log('Error getting Faxes for the Patient ', err)
        this.setState({ loading: false })
        toast.error('Could not fetch Faxes')
      })
  }

  //GET ALL SRFAX OUTBOX FAXES
  getOutbox() {
    const { token, mongoUser } = this.props

    this.setState({ loading: true })
    let config = {
      method: 'get',
      url: Config.CHANGE_BACKEND_URL + `srfax/outbox`,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: mongoUser.firebase_id,
      },
    }
    axios(config)
      .then(({ data }) => {
        // console.log('OUTBOX::::', data.Result)
        this.setState({ outboxList: data.Result }, () =>
          this.setState({ loading: false }),
        )
      })
      .catch((err) => {
        console.log('Error getting Faxes for the Patient ', err)
        this.setState({ loading: false })
        toast.error('Could not fetch Faxes')
      })
  }

  renderFax = (FileName, direction) => {
    const { token, mongoUser } = this.props

    this.setState({ modalLoading: true })
    let config = {
      method: 'get',
      url:
        Config.CHANGE_BACKEND_URL +
        `srfax/retrieve-fax?sFaxFileName=${FileName}&sDirection=${direction}`,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: mongoUser.firebase_id,
      },
    }
    axios(config)
      .then(({ data }) => {
        // console.log(data.Result)
        this.setState(
          {
            testPDF: data.Result,
            selectedFile: data.Result,
            modalFileName: FileName,
          },
          () => {
            this.setState({ modalLoading: false })
          },
        )
      })
      .catch((err) => {
        console.log('Error getting Faxes content for the Patient ', err)
        toast.error('Could not fetch Faxes')
      })
  }

  onModalHide() {
    this.setState({
      openDoc: false,
      sendModal: false,
      forwardFax: false,
      modalSchedule: new Date(),
      modalError: false,
      testPDF: '',
      modalFileName: '',
      modalSenderEmail: '',
      modalRecieverFax: '',
      // Initially, no file is selected
      selectedFile: null,
    })
  }
  // On file select (from the pop up)
  onFileChange(event) {
    // Update the state
    // console.log(event.target.files)
    if (event.target.files[0]) {
      //   console.log(event.target.files[0])
      this.setState({ modalFileName: event.target.files[0].name })

      const toBase64 = (file) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = () => resolve(reader.result)
          reader.onerror = (error) => reject(error)
        })

      toBase64(event.target.files[0])
        .then((data) => {
          // let result = data.substring(data.indexOf(', ') + 1)
          let result = data.split(',')[1]
          //   console.log(result)
          this.setState({ selectedFile: result })
        })
        .catch((err) => console.log(err))
      // console.log(await toBase64(event.target.files[0]))
    } else {
      this.setState({ modalFileName: '', selectedFile: null })
    }
  }

  onSendPressed() {
    const { token, mongoUser } = this.props
    let faxNumber = this.state.modalRecieverFax.replaceAll('-', '')
    if (faxNumber?.length < 10) {
      console.log(faxNumber)
      toast.error('Please enter correct fax number')
      return
    }
    let result = {
      sSenderEmail: this.state.modalSenderEmail,
      sToFaxNumber: '1' + faxNumber,
      sFileName_1: this.state.modalFileName,
      sFileContent_1: this.state.selectedFile,
    }

    // console.log('RESULT:::', result)

    let config = {
      method: 'post',
      url: !this.state.forwardFax
        ? Config.CHANGE_BACKEND_URL + `srfax/outbox`
        : Config.CHANGE_BACKEND_URL +
          `srfax/forward?sDirection=${this.state.showInbox}`,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: mongoUser.firebase_id,
      },
      data: result,
    }
    axios(config)
      .then(({ data }) => {
        console.log('SRFAX SEND RES::::', data)
        toast.success('Fax queued successfully')
        this.getInbox()
        this.getOutbox()
        this.onModalHide()
      })
      .catch((err) => {
        console.log('Error getting Faxes for the Patient ', err)
        toast.error('Could not send Fax')
      })
  }

  sendFaxModal() {
    return (
      <div style={{ height: '100%', width: '100%' }}>
        <div
          style={{
            height: '90%',
            width: '100%',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            overflow: 'auto',
          }}
        >
          <p
            className={Styles.closeModalBtn}
            onClick={() => this.onModalHide()}
          >
            X
          </p>
          <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
            {this.state.forwardFax ? `Forward Fax` : `Send Fax`}
          </h4>

          <div className={Styles.addManualRow}>
            <p className={Styles.addManualQuestion}>Sender Email: </p>
            <input
              placeholder={'Please enter senders email address'}
              className={Styles.addManualTextInput}
              type="text"
              value={this.state.modalSenderEmail}
              onChange={(e) =>
                this.setState({ modalSenderEmail: e.target.value })
              }
            />
          </div>

          <div className={Styles.addManualRow}>
            <p className={Styles.addManualQuestion}>Recipient Fax: </p>
            <input
              placeholder={'Please enter recievers fax number'}
              className={Styles.addManualTextInput}
              type="text"
              value={this.state.modalRecieverFax}
              onChange={(e) =>
                this.setState({ modalRecieverFax: e.target.value })
              }
            />
          </div>
          {/* <div className={Styles.addManualRow}>
            <p className={Styles.addManualQuestion}>Schedule Date : </p>
            <DateTimePicker
              onChange={(val) => {
                this.setState({ modalSchedule: val, modalError: false })
              }}
              value={this.state.modalSchedule}
              className={Styles.addManualSelectInput}
            />
          </div> */}
          {!this.state.forwardFax && (
            <div className={Styles.addManualRow}>
              <p className={Styles.addManualQuestion}>Select file: </p>
              <input
                placeholder="Slect File to be Faxed"
                className={Styles.addManualSelectInput}
                type="file"
                onChange={this.onFileChange}
              />
            </div>
          )}

          <div className={Styles.addManualRow}>
            <p className={Styles.addManualQuestion}>File Selected: </p>
            <input
              disabled
              className={Styles.addManualTextInput}
              type="text"
              value={this.state.modalFileName}
            />
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={() => this.onSendPressed()}
            className={GlobalStyles.button}
            // disabled={this.state.modalLoading}
            style={{ width: 200, position: 'absolute', bottom: 20 }}
            variant="primary"
          >
            Send Fax
          </Button>
        </div>
      </div>
    )
  }

  renderDocuments(showInbox, selectedTab) {
    const { token, mongoUser } = this.props
    const faxes = showInbox ? this.state.faxList : this.state.outboxList
    // const faxes =
    //   selectedTab === 'Inbox'
    //     ? this.state.faxList.filter(
    //         (item) =>
    //           !item.hasOwnProperty('archive') || item?.archive === false,
    //       )
    //     : selectedTab === 'Outbox'
    //     ? this.state.outboxList
    //     : this.state.faxList.filter((item) => item.archive === true)

    const elements = faxes.map((fax, index) => (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          padding: '10px 0px',
        }}
      >
        <p
          style={{
            width: '5%',
            textAlign: 'center',
            fontWeight: 'bold',
            flexWrap: 'wrap',
          }}
        >
          {index + 1}
        </p>
        <p
          style={{
            width: '17.5%',
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          {this.state.showInbox
            ? moment(fax.Date).format('lll')
            : moment(fax.DateSent).format('lll')}
        </p>

        {!this.state.showInbox && (
          <p
            style={{
              width: '30%',
              textAlign: 'center',
              fontWeight: 'bold',
              flexWrap: 'wrap',
            }}
          >
            {fax.SenderEmail}
          </p>
        )}

        {this.state.showInbox ? (
          <p
            style={{
              width: '30%',
              textAlign: 'center',
              fontWeight: 'bold',
              flexWrap: 'wrap',
            }}
          >
            {fax.CallerID} / {fax.RemoteID}
          </p>
        ) : (
          <p
            style={{
              width: '30%',
              textAlign: 'center',
              fontWeight: 'bold',
              flexWrap: 'wrap',
            }}
          >
            {fax.ToFaxNumber}
          </p>
        )}

        <p
          style={{
            width: '5%',
            textAlign: 'center',
            fontWeight: 'bold',
            flexWrap: 'wrap',
          }}
        >
          {fax.Pages}
        </p>

        <p
          style={{
            width: '10%',
            textAlign: 'center',
            fontWeight: 'bold',
            flexWrap: 'wrap',
          }}
        >
          {this.state.showInbox ? fax.ReceiveStatus : fax.SentStatus}
        </p>

        <FontAwesomeIcon
          onClick={() => {
            this.setState({ openDoc: true })
            if (fax.pdf)
              this.setState({
                testPDF: fax.pdf,
                selectedFile: fax,
                modalFileName: fax.FileName,
              })
            else this.renderFax(fax.FileName, this.state.showInbox)
          }}
          icon={faEye}
          style={{
            color: Constants.primaryTheme,
            fontSize: 20,
            width: '10%',
            cursor: 'pointer',
          }}
        />
        <FontAwesomeIcon
          onClick={() => {
            this.setState({ forwardFax: true, sendModal: true })
            this.renderFax(fax.FileName, this.state.showInbox)
          }}
          icon={faShare}
          style={{
            color: Constants.primaryTheme,
            fontSize: 20,
            width: '10%',
            cursor: 'pointer',
          }}
        />
        {this.state.showInbox && (
          <div style={{ width: '17.5%' }}>
            {/* lab, prior auth, e-consult, patient request, pathology, imaging, prescriptions, referral, health history, other */}
            <Select
              placeholder="select fax type"
              inputId="faxtype"
              options={[
                { label: 'Lab', value: 'Lab' },
                { label: 'Prior auth', value: 'Prior auth' },
                { label: 'E-consult', value: 'E-consult' },
                { label: 'Patient request', value: 'Patient request' },
                { label: 'Pathology', value: 'Pathology' },
                { label: 'Imaging', value: 'Imaging' },
                { label: 'Prescriptions', value: 'Prescriptions' },
                { label: 'Referral', value: 'Referral' },
                { label: 'Health history', value: 'Health history' },
                { label: 'Other', value: 'Other' },
              ]}
              value={
                fax.faxType ? { label: fax.faxType, value: fax.faxType } : []
              }
              isSearchable
              autoBlur
              onChange={(val) => {
                let config = {
                  method: 'put',
                  url: Config.BACKEND_URL + `srfax/archive?fax_id=${fax._id}`,
                  headers: {
                    Authorization: `JWT ${token}`,
                    x_firebase_id: mongoUser.firebase_id,
                  },
                  data: {
                    faxType: val.value,
                    // fax_id: fax._id,
                  },
                }
                axios(config)
                  .then(({ data }) => {
                    let tempFaxList = [...this.state.faxList]
                    tempFaxList[index].faxType = val.value
                    this.setState({ faxList: tempFaxList }, () =>
                      this.setState({ loading: false }),
                    )
                    if(val.value === 'Referral' ){
                      window.open('https://portal.ailahealth.com/wix/aila/provider-referral', '_blank')
                    }
                  })
                  .catch((err) => {
                    console.log('Error getting Faxes for the Patient ', err)
                    toast.error('Problem while assigning the fax')
                  })
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
        )}

        {this.state.showInbox && (
          <FontAwesomeIcon
            onClick={() => {
              let config = {
                method: 'put',
                url: Config.BACKEND_URL + `srfax/archive?fax_id=${fax._id}`,
                headers: {
                  Authorization: `JWT ${token}`,
                  x_firebase_id: mongoUser.firebase_id,
                },
                data: {
                  archive: !fax.archive,
                  // fax_id: fax._id,
                },
              }
              axios(config)
                .then(({ data }) => {
                  let tempFaxList = [...this.state.faxList]
                  tempFaxList[index].archive = !tempFaxList[index]?.archive

                  selectedTab === 'Inbox'
                    ? this.setState(
                        {
                          faxList: tempFaxList.filter(
                            (item) =>
                              !item.hasOwnProperty('archive') ||
                              item?.archive === false,
                          ),
                        },
                        () => this.setState({ loading: false }),
                        toast.success('Fax moved to Archive'),
                      )
                    : this.setState(
                        {
                          faxList: tempFaxList.filter(
                            (item) => item.archive === true,
                          ),
                        },
                        () => this.setState({ loading: false }),
                        toast.success('Fax unarchived'),
                      )

                  // this.setState(
                  //   { faxList: tempFaxList },
                  //   () => this.setState({ loading: false }),
                  //   toast.success('Fax moved to Archive'),
                  // )
                })
                .catch((err) => {
                  console.log('Error getting Faxes for the Patient ', err)
                  toast.error('Problem while assigning the fax')
                })
            }}
            icon={selectedTab === 'Inbox' ? faCheckCircle : faMinusCircle}
            style={{
              color: Constants.primaryTheme,
              fontSize: 20,
              width: '10%',
              cursor: 'pointer',
            }}
          />
        )}

        {this.state.showInbox && (
          <div style={{ width: '17.5%' }}>
            <Select
              placeholder="select patient"
              inputId="name"
              options={this.state.patientListOptions}
              value={
                fax.patient_id
                  ? this.state.patientListOptions.find(
                      (patient) => patient.value === fax.patient_id,
                    )
                  : []
              }
              isSearchable
              autoBlur
              onChange={(val) => {
                let config = {
                  method: 'put',
                  url: Config.BACKEND_URL + `srfax/patient`,
                  headers: {
                    Authorization: `JWT ${token}`,
                    x_firebase_id: mongoUser.firebase_id,
                  },
                  data: {
                    patient_id: val.value,
                    fax_id: fax._id,
                  },
                }
                axios(config)
                  .then(({ data }) => {
                    let tempFaxList = [...this.state.faxList]
                    tempFaxList[index].patient_id = val.value
                    this.setState({ faxList: tempFaxList }, () =>
                      this.setState({ loading: false }),
                    )
                  })
                  .catch((err) => {
                    console.log('Error getting Faxes for the Patient ', err)
                    toast.error('Problem while assigning the fax')
                  })
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
        )}
      </div>
    ))
    return (
      <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            borderBottom: '1px solid gray',
          }}
        >
          <h5
            style={{
              width: '5%',
              textAlign: 'center',
              fontWeight: 'bold',
              color: Constants.darkGray,
            }}
          >
            Sl No.
          </h5>
          <h5
            style={{
              width: '17.5%',
              textAlign: 'center',
              fontWeight: 'bold',
              color: Constants.darkGray,
            }}
          >
            {this.state.showInbox ? `Imported Date` : `Date Sent`}
          </h5>
          {!this.state.showInbox && (
            <h5
              style={{
                width: '30%',
                textAlign: 'center',
                fontWeight: 'bold',
                color: Constants.darkGray,
              }}
            >
              Sender Email
            </h5>
          )}
          {this.state.showInbox ? (
            <h5
              style={{
                width: '30%',
                textAlign: 'center',
                fontWeight: 'bold',
                color: Constants.darkGray,
              }}
            >
              From FAX / Remote ID
            </h5>
          ) : (
            <h5
              style={{
                width: '30%',
                textAlign: 'center',
                fontWeight: 'bold',
                color: Constants.darkGray,
              }}
            >
              Recipient Fax
            </h5>
          )}
          <h5
            style={{
              width: '5%',
              textAlign: 'center',
              fontWeight: 'bold',
              color: Constants.darkGray,
            }}
          >
            Pages
          </h5>
          <h5
            style={{
              width: '10%',
              textAlign: 'center',
              fontWeight: 'bold',
              color: Constants.darkGray,
            }}
          >
            Status
          </h5>

          {/* {!this.state.showInbox && (
            <h5
              style={{
                width: '30%',
                textAlign: 'center',
                fontWeight: 'bold',
                color: Constants.darkGray,
              }}
            >
              Reciever Fax
            </h5>
          )} */}

          <h5
            style={{
              width: '10%',
              textAlign: 'center',
              fontWeight: 'bold',
              color: Constants.darkGray,
            }}
          >
            View
          </h5>
          <h5
            style={{
              width: '10%',
              textAlign: 'center',
              fontWeight: 'bold',
              color: Constants.darkGray,
            }}
          >
            Forward
          </h5>
          {this.state.showInbox && (
            <h5
              style={{
                width: '17.5%',
                textAlign: 'center',
                fontWeight: 'bold',
                color: Constants.darkGray,
              }}
            >
              Fax Type
            </h5>
          )}
          {this.state.showInbox && (
            <h5
              style={{
                width: '10%',
                textAlign: 'center',
                fontWeight: 'bold',
                color: Constants.darkGray,
              }}
            >
              {selectedTab === 'Inbox' ? 'Archive' : 'Unarchive'}
              {/* Archive */}
            </h5>
          )}
          {this.state.showInbox && (
            <h5
              style={{
                width: '17.5%',
                textAlign: 'center',
                fontWeight: 'bold',
                color: Constants.darkGray,
              }}
            >
              Assign
            </h5>
          )}
        </div>

        {elements}
      </div>
    )
  }

  render() {
    const { mongoUser, firebaseUser } = this.props
    let { superUser } = this.state
    return (
      <div
        className={GlobalStyles.container}
        style={{
          overflow: 'hidden',
          paddingBottom: '10px',
        }}
      >
        <Header header={'Faxes'} />

        <div
          style={{
            marginTop: 10,
            display: 'flex',
            flexDirection: 'row',
            // justifyContent: 'center',
            width: '100%',
          }}
        >
          <div
            style={{
              width: 300,
              height: 40,
              border: '1px solid black',
              borderRadius: 4,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: '2vw',
            }}
          >
            <div
              onClick={() =>
                this.setState({ showInbox: true, selectedTab: 'Inbox' }, () =>
                  this.getInbox(false),
                )
              }
              style={{
                borderRadius: 4,
                cursor: 'pointer',
                width: '50%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:
                  this.state.selectedTab === 'Inbox'
                    ? Constants.primaryTheme
                    : 'white',
              }}
            >
              <p
                style={{
                  color: this.state.selectedTab === 'Inbox' ? 'white' : 'black',
                }}
              >
                Inbox
              </p>
            </div>
            <div
              onClick={() =>
                this.setState({ showInbox: false, selectedTab: 'Outbox' }, () =>
                  this.getOutbox(),
                )
              }
              style={{
                borderRadius: 4,
                cursor: 'pointer',
                width: '50%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:
                  this.state.selectedTab === 'Outbox'
                    ? Constants.primaryTheme
                    : 'white',
              }}
            >
              <p
                style={{
                  color:
                    this.state.selectedTab === 'Outbox' ? 'white' : 'black',
                }}
              >
                Outbox
              </p>
            </div>
            <div
              onClick={() =>
                this.setState({ showInbox: true, selectedTab: 'Archive' }, () =>
                  this.getInbox(true),
                )
              }
              style={{
                borderRadius: 4,
                cursor: 'pointer',
                width: '50%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:
                  this.state.selectedTab === 'Archive'
                    ? Constants.primaryTheme
                    : 'white',
              }}
            >
              <p
                style={{
                  color:
                    this.state.selectedTab === 'Archive' ? 'white' : 'black',
                }}
              >
                Archive
              </p>
            </div>
          </div>
          <div style={{ display: 'flex', position: 'absolute', right: 0 }}>
            {firebaseUser?.superUser && (
              <Button
                onClick={() => {
                  this.setState({ superUser: !superUser, loading: true }, () =>
                    this.getPatientList(),
                  )
                }}
                className={GlobalStyles.button}
                disabled={this.state.loading}
                style={{
                  width: 200,
                  marginRight: '2vw',
                  right: 0,
                  position: 'relative',
                }}
                variant="primary"
              >
                {superUser ? 'Super User' : 'Provider'}
              </Button>
            )}
            <Button
              onClick={() => this.setState({ sendModal: true })}
              className={GlobalStyles.button}
              disabled={this.state.loading}
              style={{
                width: 200,
                marginRight: '2vw',
                right: 0,
                position: 'relative',
              }}
              variant="primary"
            >
              Send
            </Button>
          </div>
        </div>

        {this.state.loading ? (
          <div className={GlobalStyles.container} style={{ height: '100vh' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: '100%',
                height: '100%',
              }}
            >
              <div className={GlobalStyles.loader} />
            </div>
          </div>
        ) : (
          <div
            className={GlobalStyles.contentWrapper}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '100vh',
              overflow: 'scroll',
              // width: '100%',
            }}
          >
            {this.state.showInbox ? (
              this.state.faxList.length !== 0 ? (
                this.renderDocuments(
                  this.state.showInbox,
                  this.state.selectedTab,
                )
              ) : (
                <p
                  style={{ width: '100%', textAlign: 'center', marginTop: 20 }}
                >
                  No imported documents
                </p>
              )
            ) : this.state.outboxList.length !== 0 ? (
              this.renderDocuments(this.state.showInbox, this.state.selectedTab)
            ) : (
              <p style={{ width: '100%', textAlign: 'center', marginTop: 20 }}>
                No imported documents
              </p>
            )}
          </div>
        )}

        <Modal
          ariaHideApp={false}
          isOpen={this.state.openDoc}
          onRequestClose={() => this.onModalHide()}
          style={customDocModalStyles}
          contentLabel="Modal"
        >
          {this.state.modalLoading ? (
            <div className={GlobalStyles.container} style={{ height: '100vh' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  minWidth: '100%',
                  height: '100%',
                }}
              >
                <div className={GlobalStyles.loader} />
              </div>
            </div>
          ) : (
            <iframe
              style={{ height: '100%', width: '100%' }}
              src={`data:application/pdf;base64,${this.state.testPDF}`}
            />
          )}
        </Modal>

        <Modal
          ariaHideApp={false}
          isOpen={this.state.sendModal}
          onRequestClose={() => this.onModalHide()}
          style={sendFaxModalStyles}
          contentLabel="Modal"
        >
          {this.state.modalLoading ? (
            <div className={GlobalStyles.container} style={{ height: '100%' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  minWidth: '100%',
                  height: '100%',
                }}
              >
                <div className={GlobalStyles.loader} />
              </div>
            </div>
          ) : (
            this.sendFaxModal()
          )}
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedIn: state.userReducer.loggedIn,

    patient: state.patientReducer.patient,

    token: state.authReducer.token,

    patientList: state.patientReducer.patientList,

    mongoUser: state.userReducer.mongoUser,

    adminId: state.userReducer.adminId,

    patientCore: state.patientReducer.patientCore,

    unreadNotifications: state.userReducer.unreadNotifications,

    allNotifications: state.userReducer.allNotifications,

    firebaseUser: state.userReducer.firebaseUser,
  }
}

const mapDispatchToProps = {
  getUnreadMessages,
  addPatientLP,
  updateSideNavBar,
  stopTimer,
  resetTimer,
  startTimer,
  addVirgilE3,
  updateDoctorMongoUser,
  addMongoUser,
  addPatient,
  userLoggedIn,
  fetchPatientCoreDate,
  fetchPatientProgressData,
  fetchPatientWellnessScreening,
  fetchPatientCalculateProgressData,
  updatePatientData,
  addPatientList,
  setPatientCoreData,
  addRpmPatient,
  fetchPatientConditions,
  fetchPatientMedications,
  fetchPatientLabs,
}

export default connect(mapStateToProps, mapDispatchToProps)(Faxes)
