import React, {useEffect, useState} from "react";
import Styles from "../styles/BodySystems.module.scss";
import {
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
  ReferenceLine,
  ReferenceArea,
  AreaChart,
  Label,
  CartesianGrid,
  Area
} from "recharts";
import moment from "moment";
import Select from 'react-select';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRunning,faCheck,faCross, faChartBar } from "@fortawesome/free-solid-svg-icons";
import {} from '@fortawesome/free-regular-svg-icons'
import Constants from "../../../../values";
import ReactTooltip from "react-tooltip";
import LoincSearchModal from "../../../../components/LoincSearchModal";
import QuestionnaireModal from "../../../../components/QuestionnaireModal";
import ChartModal from "../../../../components/ChartModal";
import QChartToolTip from "../../../../components/QChartToolTip";
import {getQuestionnaireResults} from "../../../../components/getQuestionnaireResults";
import {renderQuestionnaireResults} from "../../../../components/renderQuestionnaireResults";
import Button from "react-bootstrap/Button";
import GlobalStyles from "../../../styles/global.module.scss";
import axios from 'axios'
import {useSelector} from "react-redux";
import Config from "../../../../config";

const BIOMARKER_OPTIONS = [
  {chartDomain: [0, 300], lab: true, label: "WBC", unit: "--", val: "4.0", value: "wbc"},
  {chartDomain: [0, 300], lab: true, label: "RBC", unit: "--", val: "4.5", value: "rbc"},
  {chartDomain: [0, 300], lab: true, label: "Hemoglobin", unit: "g/dL", val: null, value: "h"},
  {chartDomain: [0, 300], lab: true, label: "Hematocrit", unit: "--", val: null, value: "hm"},
  {chartDomain: [0, 300], lab: true, label: "Lactate Dehydrogenase", unit: "U/L", val: null, value: "pl"},
  {chartDomain: [0, 300], lab: true, label: "Creatine Kinase", unit: "U/L", val: null, value: "ck"},
  {chartDomain: [0, 300], lab: true, label: "Ferritin", unit: "μgI/L", val: null, value: "fr"},
  {chartDomain: [0, 300], lab: true, label: "Plasma Viscosity", unit: "--", val: null, value: "pv"}
]

let REPRODUCTIVE_GRAPH_DATA = [
    {value: 3, date: "02/06/2021", unit: "Severity"},
    {value: 6, date: "02/08/2021", unit: "Severity"},
    {value: 3, date: "02/12/2021", unit: "Severity"},
    {value: 3, date: "02/15/2021", unit: "Severity"},
    {value: 2, date: "02/18/2021", unit: "Severity"},
    {value: 1, date: "02/20/2021", unit: "Severity"},
    {value: 0, date: "02/23/2021", unit: "Severity"},
  ]

let AUTOIMMUNO_TABLE_DATA = [
  {test: 'ANA', patientRange: '1:640', normalRange: '1:640'},
  {test: 'RF', patientRange: '--', normalRange: '--'},
  {test: 'ESR', patientRange: '--', normalRange: '--'},
  {test: 'CRP', patientRange: '--', normalRange: '--'},
  {test: 'CCP', patientRange: '--', normalRange: '--'},
  {test: 'SS-A', patientRange: '--', normalRange: '--'},
  {test: 'Jo-1', patientRange: '--', normalRange: '--'},
  {test: 'IL-1β', patientRange: '--', normalRange: '--'},
  {test: 'IgM', patientRange: '--', normalRange: '--'},
  {test: 'IgG', patientRange: '--', normalRange: '--'},
  {test: 'IgA', patientRange: '--', normalRange: '--'},
  {test: 'Anti-Smith Antibody', patientRange: '--', normalRange: '--'},
  {test: 'IL-10', patientRange: '--', normalRange: '--'},
  {test: 'ACPA', patientRange: '--', normalRange: '--'},
  {test: 'CD40L', patientRange: '--', normalRange: '--'}
]

let GENETIC_TABLE_DATA = [
  {test: 'HO-1', result: '--'},
  {test: 'SOD', result: '--'},
  {test: 'TXN', result: '--'},
  {test: 'GPX', result: '--'},
  {test: 'VCAM-1', result: '--'},
  {test: 'ICAM-1', result: '--'},
  {test: 'HLA-DR4', result: '--'},
  {test: 'MMP-9', result: '--'},
  {test: 'COX-2', result: '--'},
  {test: 'CPLA2', result: '--'},
]

let GREEN_RANGE_COLOR = '#20A89266'

let selectArray = [
  { name:'Year', value: 1, id: '1'},
  { name:'Month', value: 2, id: '2' },
  { name:'Day', value: 3, id: '3' }
]
let selectValue = 'Month';
let setSelectValue = 'Year';

let Autoimmune = 'Disease';
let Genetic = 'Disease';

const ImmuneSystem = (props) => {
  let {onChangeAutoMarker, onChangeGeneticMarker, onResRadioClick, onChangeSelect} = props
  const [showSearchModal, setShowSearchModal] = useState(false)
  const [selectedLabs, setSelectedLabs] = useState([])
  const [checked, setChecked] = useState(false)
  const token = useSelector(state => state.authReducer.token)
  const [showQuestionnaireModal, setShowQuestionnaireModal] = useState(false)
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState([])
  const [questionnaireChecked, setQuestionnaireChecked] = useState(false)
  const [anxietyScreening, setAnxietyScreening] = useState(null)
  const [mentalHealthScreening, setMentalHealthScreening] = useState(null)
  const [crohnsScreening, setCrohnsScreening] = useState(null)
  const [colitisScreening, setColitisScreening] = useState(null)
  const [physicalScreening, setPhysicalScreening] = useState(null)
  const [burnoutScreening, setBurnoutScreening] = useState(null)
  const [showChartModal, setShowChartModal] = useState(false)
  const [modalChartData, setModalChartData] = useState({data: [], path: '', name: ''})
  let graphWidth = (window.innerWidth) / 2;

  let tempData = [];
  let stockName = 'Self Reported Inflammation Level';
  let subtitleName = 'TBD';
  let title = 'Self Reported Inflammation Level';

  let graphTempData = [];


    REPRODUCTIVE_GRAPH_DATA.forEach((item)=>{
      graphTempData.push({created_at: moment(item.date).format('MM/DD'), value: item.value})
      subtitleName = item.unit
      stockName = 'Self Reported Inflammation Level';
      title = 'Self Reported Inflammation Level';
    })

    function getTooltip(label) {
      return (
        <ReactTooltip
          id={label}
          data-class={Styles.immuneTooltip}
          textColor='black'
          backgroundColor={'#20A892'}
          effect='solid'
          data-offset={{top: 0, left: 0}}
          style={{position: 'absolute'}}
        >
          <p style={{color: 'white', fontSize: 18}}>{label}</p>
        </ReactTooltip>
      )
    }

  useEffect(() => {
    let patient = props.patient
    if(patient?.body_systems_preferences?.immune?.length > 0) {
      setSelectedLabs(patient.body_systems_preferences.immune)
      setChecked(true)
    } else {
      setChecked(false)
    }

  },[props.patient])

  const onLabsSelected = (entries) => {
    setSelectedLabs(entries || [])
    setShowSearchModal(false)
    if(checked) {
      props.updatePreferences({
        'body_systems_preferences.immune': entries || []
      })
    }
  }

  useEffect(() => {
    let patient = props.patient
    if(patient?.questionnaire_preferences?.immune?.length > 0) {
      setSelectedQuestionnaire(patient.questionnaire_preferences.immune)
      setQuestionnaireChecked(true)
    } else {
      setQuestionnaireChecked(false)
    }

  },[props.patient])

  useEffect(() => {
    getAllScreeners()
  }, [selectedQuestionnaire])

  const getAllScreeners = () => {
    selectedQuestionnaire && selectedQuestionnaire.forEach((screener) => {
      getScreening(screener.path)
    })
  }

  const getScreening = (path) => {
    const url = Config.BACKEND_URL + 'screenings/' + path
    axios({
      method: 'get',
      url,
      headers: { Authorization: `JWT ${token}`, x_firebase_id: props.patient.patient_id, complete: true },
    })
      .then(({ data }) => {
        setData(path, data)
      })
      .catch((err) => {
        console.log('error when getting screening', err)
      })
  }

  const setData = (path, data) => {
    switch (path) {
      case 'anxiety':
        setAnxietyScreening(data)
        break
      case 'mental':
        setMentalHealthScreening(data)
        break
      case 'gastro':
        setCrohnsScreening(data)
        break
      case 'colitis':
        setColitisScreening(data)
        break
      case 'physical':
        setPhysicalScreening(data)
        break
      case 'burnout':
        setBurnoutScreening(data)
        break
      default:
    }
  }

  const getScreeningData = (path) => {
    switch (path) {
      case 'anxiety':
        return anxietyScreening
      case 'mental':
        return mentalHealthScreening
      case 'gastro':
        return crohnsScreening
      case 'colitis':
        return colitisScreening
      case 'physical':
        return physicalScreening
      case 'burnout':
        return burnoutScreening
      default:
        return null
    }
  }

  const hideChartModal = () => {
    setShowChartModal(false)
  }

  const onQuestionnaireSelected = (entries) => {
    setSelectedQuestionnaire(entries || [])
    setShowQuestionnaireModal(false)
    if(checked) {
      props.updatePreferences({
        'questionnaire_preferences.immune': entries || []
      })
    }
  }

  const onQuestionnaireChecked = (isChecked) => {
    let data
    if(isChecked) {
      //save the preferences
      data = {
        'questionnaire_preferences.immune': selectedQuestionnaire || []
      }
    } else {
      data = {
        'questionnaire_preferences.immune': []
      }
    }
    // setQuestionnaireChecked(e.target.checked)
    props.updatePreferences(data)
  }

  const onChecked = (e) => {
    let data
    if(e.target.checked) {
      //save the preferences
      data = {
        'body_systems_preferences.immune': selectedLabs || []
      }
      onQuestionnaireChecked(true)
    } else {
      data = {
        'body_systems_preferences.immune': []
      }
      onQuestionnaireChecked(false)
    }
    setChecked(e.target.checked)
    props.updatePreferences(data)
  }

  const renderNewLabValue = (option) => {
    return (
      <div
        // onClick={() => this.setState({modalIsOpen: true, modalOption: option, modalProgress: data.progress})}
        data-tip
        data-for={option.label}
        key={option.label}
        style={{width: 180, marginRight: 10, marginLeft: 10, height: 120, alignItems: 'center', justifyContent: 'end', display: 'flex', flexDirection: 'column', cursor: 'pointer', marginBottom: 10}}>
        <div>
        {
          option.val ?
            <p style={{fontWeight: 'bold', fontSize: 16}}>{option.val}</p>
            :
            <p style={{fontWeight: 'bold', fontSize: 16}}>-</p>
        }
        <p style={{fontSize: 12}}>{option.EXAMPLE_UCUM_UNITS || option.EXAMPLE_UNITS}</p>
        </div>
        <div style={{width: '90%', backgroundColor: option.val ? GREEN_RANGE_COLOR : Constants.lightGray, display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '4px 2px', borderRadius: 8, marginTop: 15}}>
          <p style={{textAlign: 'center'}}>{option.SHORTNAME}</p>
        </div>
        {/* {this.getTooltip(option, data.progress)} */}
        <ChartTooltip option={option}/>


      </div>
    )
  }

  return (
    <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>

      <p style={{width: '100%', fontWeight: 'bold', color: Constants.primaryTheme, textAlign: 'center', marginBottom: 20, fontSize: Constants.headerSize}}>Immune System</p>

      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 10, marginTop: 10, marginBottom: 10}}>
        <input type="checkbox" style={{marginRight: 10}} onChange={onChecked} checked={checked}/>
        <p>Save this search</p>
      </div>

      <LoincSearchModal
        selectedLabs={selectedLabs}
        onLabsSelected={onLabsSelected}
        showModal={showSearchModal}
        hideModal={() => setShowSearchModal(false)}/>

      <QuestionnaireModal
        selectedQuestionnaire={selectedQuestionnaire}
        onQuestionnaireSelected={onQuestionnaireSelected}
        showModal={showQuestionnaireModal}
        hideModal={() => setShowQuestionnaireModal(false)}/>
      <ChartModal
        data={modalChartData.data}
        path={modalChartData.path}
        name={modalChartData.name}
        getQuestionnaireResults={getQuestionnaireResults}
        showChartModal={showChartModal}
        hideChartModal={hideChartModal}
      />
      <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <p style={{fontWeight: 'bold', color: Constants.primaryTheme, width: 100}}>Biomarkers</p>
        <Button
          onClick={() => setShowSearchModal(true)}
          className={GlobalStyles.button}
          style={{width: 200, marginLeft: 20}}
          variant="primary"
        >
          Add Biomarkers
        </Button>

      </div>

      <div style={{flexDirection: 'row', display: 'flex', marginTop: 10, flexWrap: 'wrap', alignSelf: "flex-start"}}>
        {
          selectedLabs && selectedLabs.map(item => {
            return renderNewLabValue(item)
          })
        }
      </div>

      {/* <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 10, marginTop: 50}}>
        <input type="checkbox" style={{marginRight: 10}} onChange={onQuestionnaireChecked} checked={questionnaireChecked}/>
        <p>Save this search</p>
      </div> */}
      <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 30}}>
        <p style={{fontWeight: 'bold', color: Constants.primaryTheme, width: 140}}>Questionnaires</p>
        <Button
          onClick={() => setShowQuestionnaireModal(true)}
          className={GlobalStyles.button}
          style={{width: 200, marginLeft: 20}}
          variant="primary"
        >
          Add Questionnaire
        </Button>
      </div>
      <div style={{flexDirection: 'row', display: 'flex', marginTop: 10, flexWrap: 'wrap', alignSelf: "flex-start"}}>
        {
          selectedQuestionnaire && selectedQuestionnaire.map(item => {
            return renderQuestionnaireResults({option: item, getScreeningData, setModalChartData, setShowChartModal})
          })
        }
      </div>

      <div className="row" style={{marginTop: 50, alignSelf: 'flex-start', width: '100%', marginLeft: 10}}>
        <div className="col-sm-7">
          <div className="row" style={{width: '80%', justifyContent: 'space-between'}}>
            <p className={Styles.autoImmunoLabel}>Autoimmune Markers</p>
            <div className={Styles.selectOuter} style={{width: 180, marginTop: -10}}>
              <Select
                isSearchable={false}
                className={Styles.selectStyle}
                classNamePrefix={Styles.selectStyleInner}
                autoBlur={true}
                placeholder={'Autoimmune'}
                options={[{label: 'Disease', value: 'Disease'},{label: 'Disease One', value: 'Disease One'}, {label: 'Disease Two', value: 'Disease Two'}]}
                value={Genetic}
                onChange={(val) => {
                  onChangeGeneticMarker()
                  Genetic = val
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </div>
          <div className={Styles.autoimmunoTableDiv}>
            <table className={Styles.firstClass}  style={{width: "100%"}}>
              <tr>
                <th style={{fontSize: 14, textAlign: 'left'}}>Test</th>
                <th style={{fontSize: 14}}>Patient Range</th>
                <th style={{fontSize: 14}}>Normal Range</th>
                <th style={{fontSize: 14, textAlign: 'right'}}>Trend</th>
              </tr>
              {
                AUTOIMMUNO_TABLE_DATA.map((item) =>{
                  return(
                    <tr>
                      <td style={{fontSize: 14, textAlign: 'left'}}>{item.test}</td>
                      <td style={{fontSize: 14}}>{item.patientRange}</td>
                      <td style={{fontSize: 14}}>{item.normalRange}</td>
                      <td
                        style={{
                          fontSize: 14,
                          textAlign: 'right',
                          cursor: 'pointer'
                        }}

                      >
                        <FontAwesomeIcon
                          data-tip
                          data-for={item.test}
                          icon={faChartBar} style={{color: '#20A892', fontSize: 25, position: 'relative'}}/>
                        {getTooltip(item.test)}
                      </td>
                    </tr>
                  )
                })
              }

            </table>
          </div>
        </div>
        <div className="col-sm-5">
          <div className="row" style={{width: '100%', justifyContent: 'space-between'}}>
            <p className={Styles.geneticLabel}>Genetic Markers</p>
            <div style={{width: 180, marginTop: -10}}>
              <Select
                isSearchable={false}
                className={Styles.selectStyle}
                classNamePrefix={Styles.selectStyleInner}
                autoBlur={true}
                placeholder={'Genetic'}
                options={[{label: 'Disease', value: 'Disease'},{label: 'Disease One', value: 'Disease One'}, {label: 'Disease Two', value: 'Disease Two'}]}
                value={Autoimmune}
                onChange={(val) => {
                  onChangeAutoMarker()
                  Autoimmune = val
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
            <div className={Styles.geneticTableDiv}>
              <table className={Styles.secondClass} style={{width: "100%"}}>
                <tr>
                  <th style={{fontSize: 14, textAlign: 'left'}}>Test</th>
                  <th style={{fontSize: 14, textAlign: 'left' }}>Result</th>
                </tr>
                {
                  GENETIC_TABLE_DATA.map((item) =>{
                    return(
                      <tr>
                        <td style={{fontSize: 14, textAlign: 'left'}}>{item.test}</td>
                        <td style={{fontSize: 14, textAlign: 'left'}}>{item.result}</td>
                      </tr>
                    )
                  })
                }

              </table>
            </div>
          </div>
        </div>
      </div>

      <div style={{width: '100%', margin: '20px auto 10px auto', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%'}}>
          <p style={{fontWeight: 'bold', color: "rgb(32, 168, 146)"}}>Patient Reported Outcomes</p>
          <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: 50, flexGrow: 1}}>
            <div style={{flexGrow: 1}}>
              <div className={Styles.customFormControl}>
                <input
                  className={Styles.formControl}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row" style={{width: '100%', alignSelf: 'flex-start', marginTop: 20}}>
        <div className="col-sm-9">
          {
            renderRespChart(graphTempData, graphWidth, stockName, subtitleName, title, onChangeSelect)
          }
        </div>
        <div className="col-sm-3">
          <div className="squareButton">
            <div className={Styles.rowDirectionBox}>
            <label className={Styles.squareConatiner}>Show Treatment Start
                <input
                  type="checkbox"
                  name="checkbox"
                />
                <span className={Styles.checkmark}></span>
              </label>
            </div>
            <div className={Styles.rowDirectionBox}>
              <label className={Styles.squareConatiner}>Show Exercise Activity
                <input
                  type="checkbox"
                  name="checkbox"
                />
                <span className={Styles.checkmark}></span>
              </label>
            </div>
            <div className={Styles.rowDirectionBox}>
              <label className={Styles.squareConatiner}>Show Healthy Eating Score
                <input
                  type="checkbox"
                  name="checkbox"
                />
                <span className={Styles.checkmark}></span>
              </label>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default ImmuneSystem


const renderLabValue = (option) => {
  return (
    <div
      // onClick={() => this.setState({modalIsOpen: true, modalOption: option, modalProgress: data.progress})}
      data-tip
      data-for={option.label}
      key={option.value}
      style={{width: 180, marginRight: 5, marginLeft: 5, height: 120, alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', cursor: 'pointer'}}>
      {
        option.val ?
          <p style={{fontWeight: 'bold', fontSize: 16}}>{option.val}</p>
          :
          <p style={{fontWeight: 'bold', fontSize: 16}}>-</p>
      }
      <p style={{fontSize: 12}}>{option.unit}</p>

      <div style={{width: '90%', backgroundColor: option.val ? GREEN_RANGE_COLOR : Constants.lightGray, display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '4px 2px', borderRadius: 8, marginTop: 15}}>
        <p style={{textAlign: 'center'}}>{option.label}</p>
      </div>
      {/* {this.getTooltip(option, data.progress)} */}

      <ChartTooltip option={option}/>


    </div>
  )
}

const renderRespChart = (data, graphWidth, stockName, subtitleName, title, onChangeSelect) =>{
  return(
    <div id="chart" className={Styles.reproductiveChartClass} style={{alignSelf: "flex-start"}}>
      <div className={Styles.yAxisLabelImmune}>
        <p className={Styles.yAxisLabelPImmune}>
          { title }
        </p>
      </div>

      <div className={Styles.chartSelectReproductive}>
        <div style={{width: '100%', height: 30}}>
          <Select
            isSearchable={false}
            className={Styles.selectStyle}
            classNamePrefix={Styles.selectStyleInnerGraph}
            autoBlur={true}
            placeholder={'Month'}
            options={[
              {label: 'Year', value: 'Year'},
              {label: 'Month', value: 'Month'},
              {label: 'Day', value: 'Day'}
            ]}
            value={selectValue}
            onChange={(val) => {
              onChangeSelect(val)
              selectValue = val
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        </div>
        {/* <div className={Styles.chartSelectReproductiveCurrent} tabindex="1">
          {
            selectArray.map((item)=>{
              return(
                <div className={Styles.chartSelectReproductiveValue}>
                  <input className={Styles.chartSelectReproductiveInput}  type="radio" id={item.id} value={item.value} name={item.name} checked={item.value == selectValue ? true : false}  />
                  <p className={Styles.chartSelectReproductiveInputText}>{item.name}</p>
                </div>
              )
            })
          }
            <img className={Styles.chartSelectReproductiveIcon}
            src="http://cdn.onlinewebfonts.com/svg/img_295694.svg" alt="Arrow Icon" aria-hidden="true"/>
        </div>
        <ul className={Styles.chartSelectReproductiveList}>
          <li onClick={()=>{ onChangeSelect('Year'); setSelectValue = 'Year'; selectValue=1}}>
            <label className={Styles.chartSelectReproductiveOption}  for="1" aria-hidden="aria-hidden">Year</label>
          </li>
          <li onClick={()=>{onChangeSelect('Month'); setSelectValue = 'Month'; selectValue=2}}>
            <label className={Styles.chartSelectReproductiveOption} for="2" aria-hidden="aria-hidden">Month</label>
          </li>
          <li onClick={()=>{onChangeSelect('Day'); setSelectValue = 'Day'; selectValue=3}}>
            <label className={Styles.chartSelectReproductiveOption} for="3" aria-hidden="aria-hidden">Day</label>
          </li>
        </ul> */}
      </div>

      <AreaChart
        width={graphWidth-70} height={280} data={data}
        margin={{ top: 10, right: 10, left: 0, bottom: 0 }}
      >
        {/* <defs>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#20A892" stopOpacity={0.5}/>
            <stop offset="95%" stopColor="#20A892" stopOpacity={0.5}/>
          </linearGradient>
        </defs> */}
        <XAxis
          // label={{ value: 'Time(Seconds)', offset: 0, position:"insideBottom"}}
          dataKey="created_at"
          padding={{ left: 0, right: 40 }}
          axisLine={true}
          tickLine={true}
        >
        </XAxis>
        <YAxis
          label={{ value: subtitleName, angle: -90, position: 'insideLeft', offset:6 }}
          domain={[0, 10]}
          axisLine={true}
          tickLine={true}
        >
        </YAxis>
        {/* <CartesianGrid vertical={false} /> */}
        <Tooltip
          content={<CustomTooltip />}
          contentStyle={{ width: 100, height: 50, borderRadius: 20 }}
          // viewBox={{x: 0, y: 0, width: 400, height: 100 }}
        />
        <Area
          type="monotone"
          dataKey='value'
          stroke={Constants.graphLineOne}
          fillOpacity={0} fill="url(#colorPv)"
          dat={true}
        />
      </AreaChart>
    </div>
  )
}

  const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    return (
      <div className={Styles.customTooltip}>
        <p className={Styles.tooleTipLabel}>{`Treatment: ${label}`}</p>
        {/* <p className="intro">{getIntroOfPage(label)}</p>
        <p className="desc">Anything you want can be displayed here.</p> */}
      </div>
    );
  }
  return null;
};

const ChartTooltip = ({option}) => {
  const [chartData, setChartData] = useState([])

  useEffect(() => {
    let temp = []
    temp.push({date: moment().toDate(), value: 3})
    temp.push({date: moment().subtract(2, 'days').toDate(), value: 5})
    temp.push({date: moment().subtract(2, 'weeks').toDate(), value: 8})
    temp.push({date: moment().subtract(3, 'weeks').toDate(), value: 4})
    setChartData(temp)
  },[option])

  return (
    <ReactTooltip id={option.label}  textColor='black' backgroundColor='white' effect='solid' className={Styles.hoverChatWrapper}>
      <div>
        <LineChart width={500} height={400} data={chartData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
          <Legend verticalAlign="top"/>
          <Line name={'Value'} type="monotone" dataKey="value" stroke={'#606060'} />
          <XAxis dataKey="date" stroke={'#000000cc'}  tickFormatter={(tickItem) => {
            return moment(tickItem).format('MMM-D')
          }}/>
          <YAxis stroke={'#000000cc'}/>
          <Tooltip />
        </LineChart>
      </div>
    </ReactTooltip>
  )
}

// const QChartTooltip = ({data, path}) => {

//   if(data.length === 0) {
//     return null
//   }

//   return (
//     <ReactTooltip id={path}  textColor='black' backgroundColor='white' effect='solid' className={Styles.hoverChatWrapper}>
//       <div>
//         <LineChart width={500} height={400} data={data} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
//           <Legend verticalAlign="top"/>
//           <Line name={'Value'} type="monotone" dataKey={getQuestionnaireResults(path)} stroke={'#606060'} />
//           <XAxis dataKey="created_at" stroke={'#000000cc'}  tickFormatter={(tickItem) => {
//             return moment(tickItem).format('MMM-D')
//           }}/>
//           <YAxis stroke={'#000000cc'}/>
//           <Tooltip />
//         </LineChart>
//       </div>
//     </ReactTooltip>
//   )
// }
