/* eslint-disable */
import React, { Component, useState, useEffect } from 'react'
import moment from 'moment'
import {
  addPatient,
  stopTimer,
  getUnreadMessages,
  fetchPatientConditions,
  fetchPatientCarePlanData,
  fetchPatientCoreDate,
  toggleSidebar,
} from '../../../../redux/actions'
import { connect } from 'react-redux'
import GlobalStyles from '../../../styles/global.module.scss'
import PatientInfoStrip from '../../../../components/PatientInfoStrip'
import Styles from '../styles/profile.module.scss'
import Constants, { borderRadius } from '../../../../values'
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify'

import APICallers from '../../../../helpers/APIs'
import CareteamChat from '../../Careplan/CareteamChat'
import AppointmentList from '../../Careplan/AppointmentList'
import CommunicationsList from '../../Careplan/CommunicationsList'
import Screeners from '../../Careplan/InnerTabs/Screeners'
import EducationProgress from '../../Careplan/InnerTabs/EducationProgress'
import NotesModal from '../../../Appointments/NotesModal'
import TaskModal from '../../Careplan/TaskModal'
import * as MetaData from '../../../../metadata'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBookMedical,
  faUser,
  faCapsules,
  faDna,
  faViruses,
  faExclamationCircle,
  faCheckCircle,
  faClinicMedical,
  faCrosshairs,
  faPlusCircle,
  faHandHoldingMedical,
  faStethoscope,
  faBookReader,
  faNotesMedical,
  faClipboardList,
  faFileInvoice,
  faEdit,
  faWindowClose,
  faUserMd,
  faFileImport,
  faTrash,
  faDownload,
  faFileUpload,
  faCloudUploadAlt,
} from '@fortawesome/free-solid-svg-icons'
import {} from '@fortawesome/free-regular-svg-icons'
import Config from '../../../../config'
import ReactTooltip from 'react-tooltip'
import Modal from 'react-modal'
import Button from 'react-bootstrap/Button'
import styles from '../../../Orders/styles/axle.module.scss'
import Orders from '../../Careplan/InnerTabs/Orders'
import Faxes from '../InnerTabs/Faxes'
import UploadDocs from '../InnerTabs/UploadDocs'
import algoliasearch from 'algoliasearch/lite'
import {
  InstantSearch,
  SearchBox,
  Hits,
  RefinementList,
  InfiniteHits,
  connectStateResults,
  connectSearchBox,
  connectCurrentRefinements,
} from 'react-instantsearch-dom'
import Slider from 'react-rangeslider'
import { useSelector } from 'react-redux'
import Helpers from '../../../../helpers/Global'
import questionMetadata from '../../../../questionMetadata'
import Axios from 'axios'
import Handlebars from 'handlebars'
import NotesPdf from '../../../../helpers/PdfTemplates/NotesPdf.txt'
import VisitNotesPdf from '../../../../helpers/PdfTemplates/VisitNotesPdf.txt'
import PatientInsurance from '../../../PatientWeb/PatientAccount/PatientInsurance'
import MessageChat from '../../Careplan/MessageChat'
import PatientAccount from '../../../PatientWeb/PatientAccount/PatientAccount'
import RpmMonitoringLog from '../../Careplan/RpmMonitoringLog'
import PatientPCP from '../../../PatientWeb/PatientAccount/PatientPCP'

const axios = require('axios')

const searchClient = algoliasearch(
  Config.ALGOLIA_APP_ID,
  Config.ALGOLIA_API_KEY,
)

const MedicationForms = MetaData.medicationForms
const GoalDesc = MetaData.goalDesc
const condnClient = {
  search(requests) {
    if (requests[0].params.query === '') {
      return []
    }
    return searchClient.search(requests)
  },
}

const eventFire = (el, etype) => {
  if (el.fireEvent) {
    el.fireEvent('on' + etype)
  } else {
    var evObj = document.createEvent('Events')
    evObj.initEvent(etype, true, false)
    el.dispatchEvent(evObj)
  }
}

const ClearQuery = ({ refine }) => {
  const onClick = (ev) => {
    refine('')
    ev.stopPropagation()
  }
  return (
    <div onClick={onClick} style={{ display: 'none' }}>
      <ConnectedClearAllRefinements />
    </div>
  )
}

const ClearAllRefinements = ({ refine, items }) => {
  const onClick = () => refine(items)
  return (
    <button id="clearQuery" onClick={onClick}>
      ClearAll
    </button>
  )
}

const ClearQueryAndRefinements = connectSearchBox(ClearQuery)
const ConnectedClearAllRefinements =
connectCurrentRefinements(ClearAllRefinements)


export const RenderAddConditionsManually = ({
    onManualAddModalHide,
    getProviderConditions,
    currentAddModalData,
    currentElementIndex,
    currentAllUserData,
    getPatientConditions,
    patientId
  }) => {
    const token = useSelector((state) => state.authReducer.token)
    const patient = (patientId ? {patient_id: patientId} : useSelector((state) => state.patientReducer.patient))
    const doctor_id = useSelector((state) => state.userReducer.adminId)
    const doctor_details = useSelector((state) => state.userReducer.mongoUser)
    const appointmentData = useSelector(
      (state) => state.appointmentReducer.appointmentData,
      )
    const showVideoModal = useSelector(
      (state) => state.genericReducer.showVideoModal,
    )
    const [noteValue, setNoteValue] = useState(
      currentAddModalData?.note?.[0] || '',
    )
    const [condition, setCondition] = useState(
      currentAddModalData?.code?.coding || currentAddModalData?.node
        ? {
            id:
              currentAddModalData?.code?.coding?.[0]?.code ||
              currentAddModalData?.node,
            name:
              currentAddModalData?.code?.coding?.[0]?.display ||
              currentAddModalData?.symptom,
          }
        : {},
    )
    const [bodysite, setBodysite] = useState(
      currentAddModalData?.bodySite?.[0]?.coding?.[0]
        ? {
            id: currentAddModalData?.bodySite?.[0]?.coding?.[0]?.code,
            name: currentAddModalData?.bodySite?.[0]?.coding?.[0]?.display,
          }
        : [],
    )
    const [conditionQuery, setConditionQuery] = useState('')
    const [bodysiteQuery, setBodysiteQuery] = useState('')
    const [severityValue, setSeverityValue] = useState(
      currentAddModalData?.severity?.coding
        ? {
            value: currentAddModalData?.severity?.coding[0]?.code,
            label: currentAddModalData?.severity?.coding[0]?.display,
          }
        : {},
    )
    const [condnStartDate, setCondnStartDate] = useState(
      currentAddModalData?.onsetDateTime || '',
    )
    const [condnAbatmentDate, setCondnAbatmentDate] = useState(
      currentAddModalData?.abatementDateTime || '',
    )
    const [clinicalStatus, setClinicalStatus] = useState(
      currentAddModalData?.clinicalStatus?.coding
        ? {
            value: currentAddModalData?.clinicalStatus?.coding[0]?.code,
            label: currentAddModalData?.clinicalStatus?.coding[0]?.display,
          }
        : {},
    )
    const [verificationStatus, setVerificationStatus] = useState(
      currentAddModalData?.verificationStatus?.coding
        ? {
            value: currentAddModalData?.verificationStatus?.coding[0]?.code,
            label: currentAddModalData?.verificationStatus?.coding[0]?.display,
          }
        : {},
    )
    const [category, setCategory] = useState(
      currentAddModalData?.category?.[0]?.coding
        ? {
            value: currentAddModalData?.category?.[0]?.coding?.[0]?.code,
            label: currentAddModalData?.category?.[0]?.coding?.[0]?.display,
          }
        : [],
    )
  
    const onClickSave = () => {
      if (!condition?.name) {
        toast.error('Please select Condition')
        return
      }
      let data = {
        patient_id: patient?.patient_id,
        doctor_id: doctor_id,
        resourceType: 'Condition',
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: condition?.id,
              display: condition?.name,
            },
          ],
        },
        clinicalStatus: {
          coding: [
            {
              system: 'http://terminology.hl7.org/CodeSystem/condition-clinical',
              code: clinicalStatus?.value,
              display: clinicalStatus?.label,
              userSelected: true,
            },
          ],
        },
        verificationStatus: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/condition-ver-status',
              code: verificationStatus?.value,
              display: verificationStatus?.label,
              userSelected: true,
            },
          ],
        },
        severity: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: severityValue?.value,
              display: severityValue?.label,
            },
          ],
        },
        category: [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/condition-category',
                code: category.value,
                display: category.label,
              },
            ],
          },
        ],
        bodySite: [
          {
            coding: [
              {
                system: 'http://snomed.info/sct',
                code: bodysite?.id,
                display: bodysite?.name,
              },
            ],
          },
        ],
        subject: {
          reference: `Patient/${patient?.patient_id}`,
          // display: `${patient?.firstName} ${patient?.lastName}`
        },
        onsetDateTime: condnStartDate,
        abatementDateTime: condnAbatmentDate,
        recorder: {
          reference: `${doctor_details.user_type}/${doctor_id}`,
          // display: `${doctor_details.first_name} ${doctor_details.last_name}`
        },
        note: [noteValue],
        recordedDate: new Date(),
        ...(appointmentData?.event_id &&
          showVideoModal && { appointment_event_id: appointmentData?.event_id }),
      }
  
      // if it is user entered data from mobile app
      if (currentAddModalData?.node) {
        // let tempData = currentAllUserData;
        // tempData[currentElementIndex] = {node: condition?.id}
  
        // let userDataConfig = {
        //   method: 'put',
        //   url: `${Config.BACKEND_URL}users`,
        //   headers: {
        //     Authorization: 'JWT ' + token,
        //     x_firebase_id: patient?.patient_id,
        //   },
        //   data: {'pre_existing.conditions': tempData.map(x => x.node)}
        // };
        data.userEntered = true
        let userDataConfig = {
          method: 'post',
          headers: { Authorization: 'JWT ' + token, x_firebase_id: doctor_id },
          url: Config.BACKEND_URL + 'fhir/Condition',
          data: data,
        }
        axios(userDataConfig)
          .then((resp) => {
            onManualAddModalHide()
            // getPatientConditions(patient?.patient_id, token)
            getProviderConditions()
            console.log('SAVED DATA: ', data)
          })
          .catch((err) => {
            console.log('error when getting saving provider goals data', err)
          })
      } else {
        let config = {
          method: currentAddModalData ? 'put' : 'post',
          headers: { Authorization: 'JWT ' + token, x_firebase_id: doctor_id },
          url:
            Config.BACKEND_URL +
            (currentAddModalData?._id
              ? `fhir/Condition/${currentAddModalData?._id}`
              : 'fhir/Condition'),
          data: data,
        }
        axios(config)
          .then((resp) => {
            onManualAddModalHide()
            getProviderConditions()
            console.log('SAVED DATA: ', data)
          })
          .catch((err) => {
            console.log('error when getting saving provider goals data', err)
          })
      }
    }
  
    const Hit = (props) => {
      let { hit } = props
      const handleClick = () => {
        eventFire(document.getElementById('clearQuery'), 'click')
        setCondition({ id: hit?.code, name: hit?.display })
        setConditionQuery('')
      }
      return (
        <div
          style={{ width: '100%', cursor: 'pointer' }}
          onClick={(e) => {
            handleClick(e)
          }}
        >
          {hit?.code} : {hit?.display}
        </div>
      )
    }
  
    const BodyHit = (props) => {
      let { hit } = props
      const handleClick = () => {
        setBodysiteQuery('')
        eventFire(document.getElementById('clearQuery'), 'click')
        setBodysite({ id: hit.Code, name: hit.Display })
      }
      return (
        <div
          style={{ width: '100%', cursor: 'pointer' }}
          onClick={(e) => {
            handleClick(e)
          }}
        >
          {hit.Code} : {hit.Display}
        </div>
      )
    }
  
    return (
      <div style={{ height: '100%', width: '100%' }}>
        <div
          style={{
            height: '90%',
            width: '100%',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            overflow: 'auto',
          }}
        >
          <p
            className={Styles.closeModalBtn}
            onClick={() => onManualAddModalHide()}
          >
            X
          </p>
          <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
            Add Conditions
          </h4>
  
          <div className={Styles.addManualRow}>
            <div className={Styles.addManualQuestion}>
              <InstantSearch
                indexName={'ICD_10_COMPLETE'}
                searchClient={condnClient}
                onSearchStateChange={({ query }) => setConditionQuery(query)}
              >
                <SearchBox
                  translations={{
                    placeholder: 'Search conditions',
                  }}
                />
                <ClearQueryAndRefinements />
                {conditionQuery.length !== 0 && (
                  <div
                    style={{
                      overflow: 'auto',
                      height: 150,
                      backgroundColor: '#F1F1F1',
                    }}
                  >
                    <RefinementList limit={5} />
                    <Hits hitComponent={(props) => Hit(props)} />
                  </div>
                )}
              </InstantSearch>
            </div>
            <p className={Styles.addManualTextInput}>
              {condition?.id ? `${condition?.id} - ${condition?.name}` : ''}
            </p>
          </div>
  
          <div className={Styles.addManualRow}>
            <div className={Styles.addManualQuestion}>
              <InstantSearch
                indexName={'BODY_SITES'}
                searchClient={condnClient}
                onSearchStateChange={({ query }) => setBodysiteQuery(query)}
              >
                <SearchBox
                  translations={{
                    placeholder: 'Search body sites',
                  }}
                />
                <ClearQueryAndRefinements />
                {bodysiteQuery.length !== 0 && (
                  <div
                    style={{
                      overflow: 'auto',
                      height: 150,
                      backgroundColor: '#F1F1F1',
                    }}
                  >
                    <RefinementList limit={5} />
                    <Hits hitComponent={(props) => BodyHit(props)} />
                  </div>
                )}
              </InstantSearch>
            </div>
            <p className={Styles.addManualTextInput}>
              {bodysite?.id ? `${bodysite?.id} - ${bodysite?.name}` : ''}
            </p>
          </div>
  
          <div className={Styles.addManualRow}>
            <p className={Styles.addManualQuestion}>Severity: </p>
            <Select
              options={[
                { label: 'Mild', value: '255604002' },
                { label: 'Moderate', value: '6736007' },
                { label: 'Severe', value: '24484000' },
              ]}
              className={Styles.addSelectInput}
              autoBlur={true}
              onChange={(val) => {
                setSeverityValue(val)
              }}
              value={severityValue}
              getOptionValue={(option) => option.label}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
  
          <div className={Styles.addManualRow}>
            <p className={Styles.addManualQuestion}>Condition start date: </p>
            <input
              placeholder={
                'Please enter estimated or actual date the condition began'
              }
              className={Styles.addManualTextInput}
              type="date"
              max={moment(new Date()).format('YYYY-MM-DD')}
              value={moment(condnStartDate).format('YYYY-MM-DD')}
              onChange={(e) => setCondnStartDate(e.target.value)}
            />
          </div>
  
          <div className={Styles.addManualRow}>
            <p className={Styles.addManualQuestion}>Condition abatement date: </p>
            <input
              placeholder={
                'Please enter estimated date that the condition resolved or went into remission'
              }
              className={Styles.addManualTextInput}
              type="date"
              max={moment(new Date()).format('YYYY-MM-DD')}
              value={moment(condnAbatmentDate).format('YYYY-MM-DD')}
              onChange={(e) => setCondnAbatmentDate(e.target.value)}
            />
          </div>
  
          <div className={Styles.addManualRow}>
            <p className={Styles.addManualQuestion}>Category: </p>
            <Select
              options={[
                { label: 'Problem List Item', value: 'problem-list-item' },
                { label: 'Encounter Diagnosis', value: 'encounter-diagnosis' },
              ]}
              className={Styles.addSelectInput}
              autoBlur={true}
              onChange={(val) => {
                setCategory(val)
              }}
              value={category}
              getOptionValue={(option) => option.label}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
  
          <div className={Styles.addManualRow}>
            <p className={Styles.addManualQuestion}>Clinical Status: </p>
            <Select
              options={[
                { label: 'Active', value: 'active' },
                { label: 'Recurrence', value: 'recurrence' },
                { label: 'Relapse', value: 'relapse' },
                { label: 'Inactive', value: 'inactive' },
                { label: 'Remission', value: 'remission' },
                { label: 'Resolved', value: 'resolved' },
              ]}
              className={Styles.addSelectInput}
              autoBlur={true}
              onChange={(val) => {
                setClinicalStatus(val)
              }}
              value={clinicalStatus}
              getOptionValue={(option) => option.label}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
  
          <div className={Styles.addManualRow}>
            <p className={Styles.addManualQuestion}>Verification Status: </p>
            <Select
              options={[
                { label: 'Unconfirmed', value: 'unconfirmed' },
                { label: 'Provisional', value: 'provisional' },
                { label: 'Differential', value: 'differential' },
                { label: 'Confirmed', value: 'confirmed' },
                { label: 'Refuted', value: 'refuted' },
                { label: 'Entered in error', value: 'entered-in-error' },
              ]}
              className={Styles.addSelectInput}
              autoBlur={true}
              onChange={(val) => {
                setVerificationStatus(val)
              }}
              value={verificationStatus}
              getOptionValue={(option) => option.label}
            />
          </div>
  
          <div className={Styles.addManualRow}>
            <p className={Styles.addManualQuestion}>Note: </p>
            <textarea
              placeholder={'Please enter notes about condition'}
              style={{
                border: '1px solid #d0d0d0',
                padding: '5px 20px',
                borderRadius: '4px',
                width: '70%',
                // height: '40px',
              }}
              rows="2"
              type="text"
              value={noteValue}
              onChange={(e) => setNoteValue(e.target.value)}
            />
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={() => onClickSave()}
            className={GlobalStyles.button}
            // disabled={this.state.modalLoading}
            style={{ width: 200, position: 'absolute', bottom: 20 }}
            variant="primary"
          >
            Save
          </Button>
        </div>
      </div>
    )
  }