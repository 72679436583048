import React, { Component } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import PatientVideoCall from './PatientVideoCall'

import 'react-datepicker/dist/react-datepicker.css'
import Styles from './styles/PatientAppointments.module.scss'
import firebase from '../../../services/firebase.js'

import Button from 'react-bootstrap/Button'
import Modal from 'react-modal'
import Select from 'react-select'
import { connect } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import {
  faTrash,
  faSpinner,
  faMinusCircle,
  faVideo,
  faBinoculars,
  faPlus,
  faCalendarAlt,
  faEdit,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  addPatientLP,
  addRpmPatient,
  stopTimer,
  updateSideNavBar,
  addAppointmentData,
  setPatientCoreData,
  resetTimer,
  startTimer,
  addVirgilE3,
  addFirebaseUser,
  addPatient,
  userLoggedIn,
  fetchPatientCoreDate,
  fetchPatientProgressData,
  fetchPatientWellnessScreening,
  fetchPatientCalculateProgressData,
  updatePatientData,
  addPatientList,
  addMongoUser,
  updateDoctorMongoUser,
  fetchPatientConditions,
  fetchPatientMedications,
  fetchPatientLabs,
  toggleSidebar,
  toggleVideoModal,
  togglePatientVideoCall,
  addTwilioToken,
} from '../../../redux/actions'
import { Link } from 'react-router-dom'
import Header from '../../../components/Header.js'
import GlobalStyles from '../../styles/global.module.scss'
import Constants from '../../../values.js'
import Config from '../../../config.js'
import GoogleLogo from '../../../assets/images/google.png'
import metadata from '../../../metadata'
import DoctorList from './DoctorList'
import MyCalendar from '../../../components/appointment/MyCalendar'
import PreVisitIntake from './PreVisitIntake'

const localizer = momentLocalizer(moment)
const axios = require('axios')
const windowHeight = window.innerHeight

const TERMS_MODAL_STYLES = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '40%',
    height: '80%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
  },
}

const APPOINTMENT_MODAL_STYLES = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: 440,
    height: 400,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
  },
}

const SCHEDULE_APPOINTMENT_MODAL_STYLES = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '75%',
    height: '90%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
  },
}

const SLOT_AVAILABLE_MODAL_STYLES = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '65%',
    height: '70%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
  },
}

const CONFIRM_MODAL_STYLES = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '40%',
    height: '40%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
  },
}

const TIME_SLOTS = {
  Mon: [{ start: 1635478200000, end: 1635492600000 }],
  Tue: [{ start: 1635478200000, end: 1635492600000 }],
  Wed: [{ start: 1635478200000, end: 1635492600000 }],
  Thu: [{ start: 1635478200000, end: 1635492600000 }],
  Fri: [{ start: 1635478200000, end: 1635492600000 }],
  Sat: [{ start: 1635478200000, end: 1635492600000 }],
  Sun: [],
}

class PatientAppointments extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      events: null,
      modalEvent: null,
      eventsFromNylas: null,
      eventsFromBackend: {},
      eventIdsFromBackend: [],
      ailaEventsSelected: true,
      //MODALS
      modalIsOpen: false,
      showScheduleModal: false,
      showSlotAvailableModal: false,
      showPaymentModal: false,
      showInsuranceModal: false,
      showCardModal: false,
      showCardLanguage: false,
      showPrevisit: false,
      showConfirmModal: false,
      selectedSchedulePatient: null,
      selectedScheduleDoctor: null,
      showTermsModal: false,
      //
      patientList: [],
      patientMap: {},
      scheduleModalLoading: false,
      patientCareTeam: [],
      reload: false,
      timeInput: 1635478200000,
      slot_available: {},
      axiosConfig: {},
      appBooked: null,
      drList: [],
      reschedule: false,
      rescheduleData: {},
      dataPassed: {},
      joinVideoCall: false,
    }

    this.allCalendars = []
    this.preparePatientList = this.preparePatientList.bind(this)
    this.saveSlotsToBackend = this.saveSlotsToBackend.bind(this)
    // this.getEventsFromBackendOnly = this.getEventsFromBackendOnly.bind(this)
    this.onModalDeleteEventPressed = this.onModalDeleteEventPressed.bind(this)
    this.onVideoCallPressed = this.onVideoCallPressed.bind(this)
    this.handleIntakeCallback = this.handleIntakeCallback.bind(this)
    this.getPatientCareTeam = this.getPatientCareTeam.bind(this)
    this.getAppointmentUrl = this.getAppointmentUrl.bind(this)

    this.fetchTwilioToken = this.fetchTwilioToken.bind(this)
    this.hidePatientVideoCall = this.hidePatientVideoCall.bind(this)
    this.cancelAppointment = this.cancelAppointment.bind(this)
    this.getPatientAppointments = this.getPatientAppointments.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.eventsFromBackend &&
      this.state.eventsFromNylas &&
      (!this.state.events || this.state.reload)
    ) {
      const { eventsFromNylas, eventsFromBackend } = this.state
      const ailaEvents = []

      eventsFromNylas.forEach((eventFromNylas) => {
        if (eventsFromBackend[eventFromNylas.id]) {
          ailaEvents.push(eventFromNylas)
          eventsFromBackend[eventFromNylas.id]['added'] = true
        }
      })

      Object.values(eventsFromBackend).forEach((each) => {
        if (each.through_api)
          ailaEvents.push({
            start: new Date(each.start), // because the time stamp is in seconds and we need milliseconds
            end: new Date(each.end),
            title: 'Intro - Aila Appointment',
            id: each.event_id,
            ailaEvent: true,
          })
      })

      // console.log('updated', ailaEvents)
      this.setState({
        events: ailaEvents,
        loading: false,
        reload: false,
        eventsFromBackend,
      })
    }
  }

  componentDidMount() {
    const { patient, patientCore } = this.props

    if (!this.props.loggedIn) {
      this.props.history.push('/login')
      return
    }
    if (patient) {
      // console.log({ ...patient, ...patientCore })
      console.log('Patient appointments')
      this.getPatientAppointments()
      this.fetchTwilioToken()
      this.getDoctorList()
      if (this.props?.location?.state?.appointment_details) {
        this.onVideoCallPressed(
          this.props?.location?.state?.appointment_details,
        )
      }
    }
  }

  refreshPage() {
    this.getPatientAppointments()
  }

  // handleCalendarCallback = (childData) => {
  //   if (childData.success) {
  //     // console.log(childData)
  //     //in case the provider booked a appointment, we want to get them all again
  //     this.setState({ loading: true, reload: true, eventsFromNylas: null })
  //     // this.getEventsFromBackendOnly()
  //     this.getPatientAppointments()

  //     this.setState({
  //       modalIsOpen: false,
  //       showScheduleModal: false,
  //       showSlotAvailableModal: false,
  //       selectedSchedulePatient: null,
  //       selectedScheduleDoctor: null,
  //     })
  //     this.props.toggleSidebar(false)
  //     toast.success('Appointment scheduled successfully')
  //   } else {
  //     toast.error('Appointment was not scheduled successfully')
  //     // console.log(childData)
  //   }
  // }

  fetchTwilioToken() {
    let { token, patient } = this.props
    let url = Config.BACKEND_URL + 'twilio/token'
    axios({
      method: 'get',
      url: url,
      headers: { Authorization: 'JWT ' + token, x_firebase_id: patient.uid },
    })
      .then((result) => {
        const { token } = result.data
        console.log('got twilio token')
        this.setState({ twilioToken: token })
        this.props.addTwilioToken(token)
      })
      .catch((err) => {
        console.log('error when getting twilio token', err)
        if (
          window.confirm(
            'Something went wrong. If the problem persists, please contact support.',
          )
        ) {
          // this.props.history.push('/appointments')
        } else {
          // this.props.history.push('/appointments')
        }
      })
  }

  getAppointmentUrl = (doctor_id, patient_id) => {
    let data = {
      doctor_id: doctor_id,
      patient_id: patient_id,
      symptoms: [],
      through_api: true,
      appointment_payment_paid: true,
      through_provider: true,
    }
    let config = {
      method: 'post',
      url: `${Config.BACKEND_URL}appointments`,
      headers: {
        Authorization: `JWT ${this.state.token}`,
        'Content-Type': 'application/json',
      },
      data: data,
    }

    this.setState({ axiosConfig: config })

    this.setState({ scheduleModalLoading: false })
  }

  async preparePatientList() {
    const { patientList } = this.props
    if (!patientList) return

    const patientMap = {}
    const list = []
    Object.keys(patientList).forEach((rpmColor) => {
      const patientsColorList = patientList[rpmColor]
      patientsColorList.forEach((patient) => {
        patientMap[patient.uid] = patient
        list.push({
          label: `${patient.firstName} ${patient.lastName}`,
          value: patient.uid,
        })
      })
    })

    this.setState({ patientMap, patientList: list })
  }

  //DELETE EVENT IN GOOGLE CALENDAR AFTER APPOINTMENT SCHEDULED
  deleteEvent(event_id, access_token, event) {
    const { patientCareTeam } = this.props
    var gapi = window.gapi
    let doctor =
      patientCareTeam?.find((x) => x.doctor_id === event.doctor_id) || {}

    const CALENDAR_ID = doctor?.google_calendar_id
    gapi.load('client:auth2', () => {
      console.log('loading client')

      gapi.client.load('calendar', 'v3', () => {
        console.log('google calendar signed in')
        try {
          gapi.auth.setToken({
            access_token: access_token?.access_token,
          })
          var request = gapi.client.calendar.events.delete({
            calendarId: CALENDAR_ID,
            eventId: event_id,
          })
          request.execute((event) => {
            console.log('EVENT DELETED:::', event)
          })
        } catch (error) {
          console.log('Event DELETION failed', error)
        }
      })
    })
  }
  async cancelAppointment() {
    const { modalEvent, eventsFromBackend } = this.state
    let data = {
      event_id: modalEvent.id,
    }
    if (!modalEvent || !modalEvent.id) return
    this.setState({ loading: true, modalIsOpen: false })
    const url = `${Config.BACKEND_URL}appointments`
    try {
      const response = await axios({
        method: 'delete',
        url,
        headers: {
          Authorization: `JWT ${this.props.token}`,
          'Content-Type': 'application/json',
        },
        data: data,
      })
      this.getPatientAppointments()

      if (response.data.success) {
        toast.success('Appointment cancelled successfully')
        this.getPatientAppointments()
        const eventToDelete = eventsFromBackend[modalEvent.id] || {}
        if (response?.data?.access_token && eventToDelete?.google_event_id) {
          this.deleteEvent(
            eventToDelete?.google_event_id,
            response?.data?.access_token,
            eventToDelete,
          )
        }
      }
 
    } catch (err) {
      console.log('error when deleting calendar event', err)
      this.setState({ loading: false })
      toast.error('Error when deleting calendar event')
    }
  }

  saveSlotsToBackend(user) {
    const self = this
    const { token } = this.props
    const url = `${Config.BACKEND_URL}providers`

    let availList = {}

    Object.keys(this.state.slot_available).forEach((avail) => {
      // avail = Mon, Tue, Wed ...
      availList[avail] = []
      if (this.state.slot_available?.[avail]?.length) {
        // In day object get the time from the array
        this.state.slot_available[avail] &&
          this.state.slot_available[avail].forEach((time) => {
            availList[avail].push({
              start: moment(time?.start)
                .year(moment().year())
                .month(moment().month())
                .date(moment().date())
                .valueOf(),
              end: moment(time?.end)
                .year(moment().year())
                .month(moment().month())
                .date(moment().date())
                .valueOf(),
            })
          })
      }
    })

    user.availability = availList

    axios({
      method: 'put',
      url,
      headers: { Authorization: `JWT ${token}` },
      data: user,
    })
      .then(({ data }) => {
        this.props.addMongoUser(user)
        this.setState({ showSlotAvailableModal: false })
      })
      .catch((err) => {
        console.log('error when adding slots data to backend', err)
      })
  }

  async getPatientAppointments() {
    const { token, patient } = this.props
    const url = `${Config.BACKEND_URL}patient/appointments`
    try {
      const response = await axios({
        method: 'get',
        url,
        headers: {
          Authorization: `JWT ${token}`,
          x_patient_id: patient.uid,
        },
      })

      const events = response.data
      const ailaEvents = []
      const eventsMap = {}
      const eventIds = []

      events.forEach((event) => {
        eventsMap[event.event_id] = event
        eventIds.push(event.event_id)

        ailaEvents.push({
          start: new Date(event.start), // because the time stamp is in seconds and we need milliseconds
          end: new Date(event.end),
          title: 'Aila Health Appointment',
          id: event.event_id,
          ailaEvent: true,
          doctor_name: event.first_name + ' ' + event.last_name,
        })
      })

      this.setState({
        events: ailaEvents,
        // events: events,
        loading: false,
        reload: false,
        eventsFromBackend: eventsMap,
        eventIdsFromBackend: eventIds,
      })
    } catch (err) {
      console.log('error when getting calendar events from backend', err)
    }
  }

  async getEventsFromBackendOnly() {
    const { token, mongoUser } = this.props
    const url = `${Config.BACKEND_URL}providers/appointments`
    try {
      const response = await axios({
        method: 'get',
        url,
        headers: {
          Authorization: `JWT ${token}`,
          x_doctor_id: mongoUser.firebase_id,
        },
      })

      const events = response.data
      const ailaEvents = []
      const eventsMap = {}
      const eventIds = []

      // console.log('Appointments: ', events)

      events.forEach((event) => {
        eventsMap[event.event_id] = event
        eventIds.push(event.event_id)

        if (
          event.appointment_payment_paid === true ||
          !event.hasOwnProperty('appointment_payment_paid')
        ) {
          ailaEvents.push({
            start: new Date(event.start), // because the time stamp is in seconds and we need milliseconds
            end: new Date(event.end),
            title: 'Patient Appointment',
            id: event.event_id,
            ailaEvent: true,
          })
        }
        // }
      })

      this.setState({
        events: ailaEvents,
        // events: events,
        loading: false,
        reload: false,
        eventsFromBackend: eventsMap,
        eventIdsFromBackend: eventIds,
      })
    } catch (err) {
      console.log('error when getting calendar events from backend', err)
    }
  }

  async getPatientCareTeam(uid) {
    const { token } = this.props

    // get the care team members
    const url = `${Config.BACKEND_URL}patient/careteam/internal`
    const doctorList = []

    axios({
      method: 'get',
      headers: {
        'Access-Control-Allow-Origin': '*',
        Accept: '*/*',
        Authorization: `JWT ${token}`,
        x_firebase_id: uid,
      },
      url,
    })
      .then(async ({ data }) => {
        if (data)
          data.forEach((x) => {
            doctorList.push({
              label: `${x.first_name} ${x.last_name}`,
              value: x.doctor_id,
              doctor: x,
            })
          })
        this.setState({
          patientCareTeam: doctorList,
          scheduleModalLoading: false,
        })
      })
      .catch((err) => {
        console.log('error when getting the care team for patient', err)
        toast.error('Something went wrong')
        this.setState({ loading: false, showScheduleModal: false })
      })
  }

  onScheduleClicked(showIns, showPlans, skipTerms) {
    const { patient, patientCore } = this.props
    const user = { ...patient, ...patientCore }

    let showCardLanguage = this.checkStripeDetails()
    if (
      showIns &&
      (!user.hasOwnProperty('medicare') || !user.hasOwnProperty('medicaid'))
    ) {
      // (!user?.insurance || !user?.insurance.length)
      console.log('no insurance found')
      this.setState({ showInsuranceModal: true })
      this.props.toggleSidebar(true)
    } else if (
      showPlans &&
      // !skipPlans &&
      (!user.coaching || !user.coaching.active)
    ) {
      console.log('Customer plan = BASIC')
      this.setState({ showPaymentModal: true })
      this.props.toggleSidebar(true)
    } else if (!skipTerms && !user.payment_terms) {
      this.setState({ showTermsModal: true })
    } else if (showCardLanguage) {
      console.log('no card details found')
      this.setState({ showCardLanguage: showCardLanguage })
    } else if (
      // !user?.insurance ||
      // !user?.change ||
      // !user?.change?.pharmacy ||
      !user?.emergencyContactPhone ||
      !user?.emergencyContactName ||
      !user?.addressComponents ||
      user?.addressComponents.length === 0 ||
      !user?.phoneNumber
    ) {
      window.alert(
        'Please fill your address, phone and emergency contact before booking your appointment.',
      )
      this.props.history.push('/patient/account')
      this.props.toggleSidebar(false)
    } else {
      this.setState({
        showScheduleModal: true,
      })
      this.props.toggleSidebar(true)
    }

    // this.props.toggleSidebar(true)
  }

  async onModalDeleteEventPressed() {
    const { modalEvent } = this.state
    let data = {
      event_id: modalEvent.id,
    }
    if (!modalEvent || !modalEvent.id) return
    this.setState({ loading: true, modalIsOpen: false })
    const url = `${Config.BACKEND_URL}appointments`
    try {
      const response = await axios({
        method: 'delete',
        url,
        headers: {
          Authorization: `JWT ${this.props.token}`,
          'Content-Type': 'application/json',
        },
        data: data,
      })
      // console.log('delete event')
      toast.success('A notification has been sent to all participants')
      // this.getEventsFromBackendOnly()
      this.getPatientAppointments()
    } catch (err) {
      console.log('error when deleting calendar event', err)
      this.setState({ loading: false })
      toast.error('Error when deleting calendar event')
    }
  }
  hidePatientVideoCall() {
    this.props.togglePatientVideoCall(false)
  }
  renderPatientVideoCall() {
    return (
      <>
        <div
          style={{
            height: 1,
            width: 1,
            position: 'fixed',
            right: 10,
            top: 0,
            visibility: 'hidden',
          }}
          //   ref={this.buttonRef}
        />

        <div
          //   {...props}
          style={{
            height: '80vh',
            // width: 450,
            color: 'white',
            borderRadius: 3,
            // ...props.style,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginTop: 20,
          }}
        >
          <PatientVideoCall endCall={this.hidePatientVideoCall} />
        </div>
      </>
    )
  }

  onVideoCallPressed(item, patient) {
    const { patientCareTeam } = this.props
    item.prev_event_id = item?.id

    let tempDoc = patientCareTeam?.find((x) => x.doctor_id === item.doctor_id)
    this.setState({
      reschedule: false,
      rescheduleData: item?.event_id,
      joinVideoCall: true,
      dataPassed: { ...item, prev_event_id: item?.event_id },
    })

    let showCardLanguage = this.checkStripeDetails()
    if (showCardLanguage) {
      console.log('no card details found')
      this.setState({ showCardLanguage: showCardLanguage })
    } else
      this.setState(
        { selectedScheduleDoctor: tempDoc, showScheduleModal: false },
        () => {
          this.setState({ showPrevisit: true })
          this.props.toggleSidebar(false)
        },
      )

    //JOIN VIDEO CALL
    // this.props.togglePatientVideoCall(true)

    // this.setState({
    //   modalIsOpen: false,
    // })
  }

  onSelectEvent(event) {
    const { eventsFromBackend } = this.state
    if (eventsFromBackend && eventsFromBackend[event.id]) {
      // this is as aila event with data in backend. Show the modal
      this.setState({ modalIsOpen: true, modalEvent: event })
    } else {
      window.alert(event.title)
    }
  }

  onModalCloseRequested() {
    //in case the patient booked a appointment, we want to get them all again
    this.getPatientAppointments()

    this.setState({
      modalIsOpen: false,
      showScheduleModal: false,
      showSlotAvailableModal: false,
      selectedSchedulePatient: null,
      selectedScheduleDoctor: null,
      showConfirmModal: false,
      appBooked: null,
      reschedule: false,
      rescheduleData: {},
      joinVideoCall: false,
    })
    this.props.toggleSidebar(false)
  }

  checkStripeDetails = () => {
    const { patient, patientCore } = this.props
    const user = { ...patient, ...patientCore }
    if (user.stripe && user.stripe.customer_id) {
      console.log('stripe customer id present')
      return false
    } else {
      console.log('error : no stripe customer id ')
      return true
    }
  }

  onReschedulePress = (item, patient) => {
    console.log(item)
    item.prev_event_id = item?.id
    let data = {
      prev_event_id: item?.id,
      doctor_id: item?.doctor_id,
      patient_id: patient.uid,
    }
    this.setState({
      reschedule: true,
      rescheduleData: item?.event_id,
      dataPassed: { ...item, prev_event_id: item?.event_id },
    })
    let tempDoc = this.state.drList.find((x) => x.doctor_id === item.doctor_id)
    console.log('DOCTOR:', tempDoc)

    this.setState(
      { selectedScheduleDoctor: tempDoc, showScheduleModal: false },
      () => {
        this.setState({ showPrevisit: true })
        this.props.toggleSidebar(false)
      },
    )
  }

  handleIntakeCallback = (childData) => {
    if (childData.success) {
      this.setState({ axiosConfig: childData.axiosConfig }, () => {
        if (childData?.joinVideoCall) {
          axios(this.state.axiosConfig)
            .then(({ data }) => {
              console.log('successfull:', childData)
              this.getPatientAppointments()

              //JOIN VIDEO CALL
              this.setState({ showPrevisit: false })
              this.props.togglePatientVideoCall(true)
              // this.props.history.push('/patient/appointments')

              this.setState({
                modalIsOpen: false,
              })
              console.log('changed  toggle video call:')
            })
            .catch((err) => {
              this.getPatientAppointments()

              console.log('Error when opening video call screen: ', err)
              toast.warning(
                'Could not save Pre Visit Intake Form, please try again.',
              )
            })
        } else {
          this.setState({ showPrevisit: false, showSlotAvailableModal: true })
          this.props.toggleSidebar(true)
        }

        // this.setState({ showScheduleModal: false })
      })
      // this.checkStripeDetails()
    } else {
      toast.warning('Could not save Pre Visit Intake Form, please try again.')
    }
  }

  handleCareTeamCallback = (childData) => {
    if (childData) {
      this.setState(
        { selectedScheduleDoctor: childData, showScheduleModal: false },
        () => {
          this.setState({ showPrevisit: true })
          this.props.toggleSidebar(false)
        },
      )
      // this.checkStripeDetails()
    } else {
      toast.warning('Could not get Care team details, please try again.')
    }
  }

  handleCalendarCallback = (childData) => {
    this.onModalCloseRequested()
    if (childData.success) {
      this.getPatientAppointments()

      if (childData.event_data.appointment_payment_paid === false) {
        this.setState(
          {
            appBooked: {
              ...childData.event_data,
              ...childData.event_doctor_data,
            },
          },
          () => {
            this.setState({ showConfirmModal: true })
          },
        )
      }

      toast.success('Appointment scheduled successfully')
    } else {
      toast.error('Appointment was not scheduled successfully')
    }
  }

  getDoctorList = () => {
    const { patient, patientCore, token } = this.props
    let self = this

    var config = {
      method: 'get',
      url: `${Config.BACKEND_URL}patient/careteam/internal`,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: patient.uid,
      },
    }

    axios(config)
      .then(function (response) {
        console.log('got patient internal team')
        if (response.status === 200 && response.data) {
          self.setState({ drList: response.data })
        }
      })
      .catch(function (error) {
        console.log('error when getting patient care team', error)
      })
  }

  renderDoctorCalendarModal() {
    const { selectedScheduleDoctor, axiosConfig } = this.state
    const { patient, patientCore, token } = this.props
    const user = { ...patient, ...patientCore }

    let data = {
      doctor_id: selectedScheduleDoctor && selectedScheduleDoctor.firebase_id,
      patient_id: user.uid,
      symptoms: [],
      through_api: true,
      // appointment_payment_paid: false,
    }

    let config = {
      method: 'post',
      url: `${Config.BACKEND_URL}appointments`,
      headers: {
        Authorization: `JWT ${this.props.token}`,
        'Content-Type': 'application/json',
      },
      data: data,
    }

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() => this.onModalCloseRequested()}
        >
          X
        </p>
        <h4
          style={{
            color: Constants.primaryTheme,
            textAlign: 'center',
            marginBottom: '5%',
          }}
        >
          Schedule Appointment
        </h4>

        <MyCalendar
          parentCallback={this.handleCalendarCallback}
          doctorDetails={selectedScheduleDoctor}
          axiosConfig={axiosConfig}
          patientId={user.uid}
          patientData={user}
          token={token}
        />
      </div>
    )
  }

  renderCardModal() {
    let { patient, patientCore } = this.props
    let user = { ...patient, ...patientCore }
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      ></div>
    )
  }

  renderConfirmModal() {
    const { patient, patientCore } = this.props
    let { appBooked } = this.state
    let user = { ...patient, ...patientCore }
    // console.log(appBooked)
    let billAmount = user?.coaching?.active
      ? appBooked &&
        (appBooked?.user_type === 'Health Coach' ||
          appBooked?.user_type === 'Wellness Coach')
        ? 49
        : 75
      : appBooked &&
        (appBooked?.user_type === 'Health Coach' ||
          appBooked?.user_type === 'Wellness Coach')
      ? 100
      : 125

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <h4
          style={{
            color: Constants.primaryThemeDark,
            textAlign: 'center',
            marginBottom: '5%',
          }}
        >
          You are booked!
        </h4>

        <p
          style={{
            fontSize: '14',
            marginTop: '4vh',
            color: Constants.primaryTheme,
            textAlign: 'center',
            fontFamily: 'Avenir',
            wordWrap: 'break-word',
            position: 'relative',
          }}
        >
          {`You will be billed $${billAmount} on ${
            moment(appBooked?.start).format('MMM Do') || ''
          } for your appointment.\n\nIf you need to reschedule or cancel your appointment, please do so 48 hours or more before your scheduled appointment.\n\nAll cancellations within 48 hours of the appointment will be billed the full amount.`}
        </p>

        <div
          style={{
            width: '50%',
            margin: '20px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={() => this.onModalCloseRequested()}
            className={GlobalStyles.button}
            // disabled={this.state.modalLoading}
            style={{ width: 200 }}
            variant="primary"
          >
            Okay
          </Button>
        </div>
      </div>
    )
  }

  renderInsuranceModal() {
    const { mongoUser, showPatientVideoCall, patient, patientCore } = this.props
    let user = { ...patient, ...patientCore }
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <h4
          style={{
            color: Constants.primaryThemeDark,
            textAlign: 'center',
            marginBottom: '5%',
          }}
        >
          Insurance Information
        </h4>

        <p
          style={{
            fontSize: '14',
            marginTop: '4vh',
            color: Constants.primaryTheme,
            textAlign: 'center',
            fontFamily: 'Avenir',
            wordWrap: 'break-word',
            position: 'relative',
          }}
        >
          {`Do you have insurance?`}
        </p>

        {/*SHOW CARD DETAILS IF USER.STRIPE.CUSTOMER_ID IS NOT PRESENT */}

        <div
          style={{
            width: '80%',
            margin: '20px',
            display: 'flex',
            justifyContent: 'space-evenly',
          }}
        >
          <Button
            onClick={() => {
              this.props.history.push('/patient/account')
              this.props.toggleSidebar(false)
              // if (
              //   window.confirm('Please fill insurance details in My Account')
              // ) {
              //   this.props.history.push('/patient/account')
              //   this.props.toggleSidebar(false)
              // }
            }}
            className={GlobalStyles.button}
            // disabled={this.state.modalLoading}
            style={{ width: '25%' }}
            variant="primary"
          >
            Yes
          </Button>

          <Button
            onClick={() => {
              this.setState({ showInsuranceModal: false })
              this.props.toggleSidebar(true)
              this.onScheduleClicked(false, true)
            }}
            className={GlobalStyles.button}
            // disabled={this.state.modalLoading}
            style={{ width: '25%' }}
            variant="primary"
          >
            No
          </Button>
        </div>
      </div>
    )
  }

  renderTermsModal() {
    const { mongoUser, showPatientVideoCall, patient, patientCore } = this.props
    let user = { ...patient, ...patientCore }
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <h4
          style={{
            color: Constants.primaryThemeDark,
            textAlign: 'center',
            marginBottom: '5%',
          }}
        >
          Payment Terms
        </h4>
        <div style={{ overflow: 'auto' }}>
          <p
            style={{
              fontSize: '14',
              marginTop: '4vh',
              color: Constants.primaryTheme,
              textAlign: 'left',
              fontFamily: 'Avenir',
              wordWrap: 'break-word',
              position: 'relative',
              // overflow: 'auto',
            }}
          >
            {`If the services are not eligible for your health insurance or you do not have health insurance, then you must pay 100% of the total amounts due; and, 
          you hereby authorize Aila Health  and/or Aila Medical to collect payment and charge your credit card.  
          To the extent you have health insurance, we participate in any insurance plans, and we accept such health insurance, you hereby authorize Aila Health and/or Aila Medical 
          to collect payment and charge your credit card for copayments, coinsurance, and amounts resulting from your insurance deductible plus any amounts not covered by your insurance.         
        `}
          </p>
          <p
            style={{
              fontSize: '14',
              marginTop: '4vh',
              color: Constants.primaryTheme,
              textAlign: 'left',
              fontFamily: 'Avenir',
              wordWrap: 'break-word',
              position: 'relative',
              // overflow: 'auto',
            }}
          >
            In any event, you hereby authorize all charges to be charged at once
            in a single charge. By agreeing to these terms, you acknowledge that
            if you are 100% self-pay:
            <br />
            1) you do not have any health insurance through a PPO, HMO, Medicaid
            or Medicare or any other insurance plan; or
            <br />
            2) you have health insurance but you do not want to use any
            insurance benefit for these services. Your insurance policy is a
            contract between you and your insurance company. It is your
            responsibility to know your benefits, and how they will apply to
            your benefit payments.
          </p>
          <p
            style={{
              fontSize: '14',
              marginTop: '4vh',
              color: Constants.primaryTheme,
              textAlign: 'left',
              fontFamily: 'Avenir',
              wordWrap: 'break-word',
              position: 'relative',
              // overflow: 'auto',
            }}
          >
            By clicking “I agree” , you are electing to purchase services that
            may or may not be covered by insurance. If the Services for purchase
            are on a self-pay basis, you have directed us to treat your purchase
            of these services as if you are an uninsured patient and you agree
            to be 100% responsible for full payment of the listed price of the
            services.
            <span style={{ color: 'red' }}>
              You agree that you will not bill any services provided by Aila
              Medical, PC to Medicaid.{' '}
            </span>
            Even if you have insurance that is applicable to the services, there
            is no guarantee your insurance company will make any payment on the
            cost of the services you have purchased.
            <br />
            <br />
            By opting in, you agree to pay all charges in full to the extent the
            services are not covered by applicable insurance.
            <br />
            <br />
            You have been given a choice of different services, along with their
            costs. You have selected the services and are willing to accept full
            financial responsibility for the payment that is due and payable.
            Our subscription model is a monthly recurring charge and the billing
            period begins from the time of registration.
          </p>
        </div>

        <div
          style={{
            width: '50%',
            margin: '20px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={() => {
              this.setState({ showTermsModal: false })
              this.onScheduleClicked(false, false, true)
              // this.setState({ showPaymentModal: false })
              // this.setState({
              //   showCardLanguage: true,
              // })
              this.props.toggleSidebar(true)
              const firebaseData = {
                payment_terms: true,
              }

              console.log('firebase:::', firebaseData)

              this.updateUserDataOnFirebase(user.uid, firebaseData)
            }}
            className={GlobalStyles.button}
            // disabled={this.state.modalLoading}
            style={{ width: 200 }}
            variant="primary"
          >
            Agree
          </Button>
        </div>
      </div>
    )
  }

  updateUserDataOnFirebase = (uid, data) => {
    console.log('success firebase inside function')
    firebase
      .firestore()
      .collection('users')
      .doc(uid)
      .update(data)
      .then((doc) => {
        console.log('success')

        firebase
          .firestore()
          .collection('users')
          .doc(uid)
          .get()
          .then((doc) => {
            if (doc && doc.data()) {
              const data = doc.data()
              console.log('user firebase saved :: ', data)
              console.log('success || firebase || savedata', data)
              this.props.addFirebaseUser(data)
              this.props.addPatient(data, 'gray')
            }
          })
        // getFirebaseUser(uid)
      })
      .catch((err) => {
        console.log('error - saving firebase ', err)
      })
  }

  renderEnterCardModal() {
    const { mongoUser, showPatientVideoCall, patient, patientCore } = this.props
    let user = { ...patient, ...patientCore }
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <h4
          style={{
            color: Constants.primaryThemeDark,
            textAlign: 'center',
            marginBottom: '5%',
          }}
        >
          Card Details
        </h4>

        <p
          style={{
            fontSize: '14',
            marginTop: '4vh',
            color: Constants.primaryTheme,
            textAlign: 'center',
            fontFamily: 'Avenir',
            wordWrap: 'break-word',
            position: 'relative',
          }}
        >
          {`Please enter your card details to continue booking your appointment`}
        </p>

        {/*SHOW CARD DETAILS IF USER.STRIPE.CUSTOMER_ID IS NOT PRESENT */}
        {this.state.loading && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              height: '80vh',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        )}

        {this.state.showCardModal && (
          <iframe
            id="payment_web"
            src={`${Config.WEB_HOST}stripe/card-details?patient_id=${user.uid}&email=${user.email}`}
            title="cardDetails"
            style={{ height: 0, width: 0 }}
          />
        )}

        <div
          style={{
            width: '50%',
            margin: '20px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={() => {
              this.setState({ showCardModal: true })
              this.props.toggleSidebar(true)
            }}
            className={GlobalStyles.button}
            // disabled={this.state.modalLoading}
            style={{ width: 200 }}
            variant="primary"
          >
            Next
          </Button>
        </div>
      </div>
    )
  }

  renderPaymentModal() {
    const { patient } = this.props
    let state_residence = null

    let addressComponents = patient.addressComponents
    if (addressComponents && addressComponents.length > 0) {
      let state = null
      addressComponents.forEach((addressComponent) => {
        let types = addressComponent.types || []
        if (types.includes('administrative_area_level_1'))
          state = addressComponent.short_name
      })
      state_residence = state
    }

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            flex: 1,
            alignSelf: 'center',
            width: '100%',
            overflow: 'hidden',
          }}
        >
          <iframe
            id="payment_web"
            src={`${Config.WEB_HOST}stripe/subscription?patient_id=${patient.uid}&email=${patient.email}&state=${state_residence}`}
            title="payment"
            style={{ height: '100%', width: '100%' }}
          />
        </div>
        <div
          style={{
            width: '50%',
            margin: '20px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={() => {
              this.onScheduleClicked(false, false)
              this.setState({ showPaymentModal: false })
              // this.setState({
              //   showCardLanguage: true,
              // })
              this.props.toggleSidebar(true)
            }}
            className={GlobalStyles.button}
            // disabled={this.state.modalLoading}
            style={{ width: 200, backgroundColor: 'gray' }}
            variant="primary"
          >
            Skip
          </Button>
        </div>
      </div>
    )
  }

  renderScheduleModal() {
    const { scheduleModalLoading } = this.state

    if (scheduleModalLoading) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '100%',
            height: '100%',
          }}
        >
          <div className={GlobalStyles.loader} />
        </div>
      )
    }

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() => this.onModalCloseRequested()}
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Care Team
        </h4>

        <DoctorList parentCallback={this.handleCareTeamCallback} />
      </div>
    )
  }

  navigatetoPatientProfile(p_id) {
    let { mongoUser, token, adminId } = this.props
    let { patientList } = this.props
    if (!patientList) return

    Object.values(patientList).forEach((colorCodedPatients, index) => {
      if (colorCodedPatients.length > 0) {
        let result = colorCodedPatients.filter(
          (patient) => patient.uid === p_id,
        )
        result.forEach((patient) => {
          let colorcode = Object.keys(patientList)[index]
          if (patient.uid === p_id) {
            this.props.updatePatientData(
              {
                doctor_id: adminId,
                patient_id: patient.uid,
                last_reviewed: new Date(),
              },
              token,
            )

            //if the doctor was already on on another patient page and just clicked on a new patient, reset time
            if (this.props.patientTimer && this.props.patientTimer !== 0)
              this.props.resetTimer(patient.uid, adminId, token)
            else this.props.startTimer(patient.uid)
            let preferences = patient.preferences
            let timeline = 'complete'
            if (preferences && preferences.timeline) {
              timeline = preferences.timeline.value
            }
            this.props.fetchPatientProgressData(
              patient.uid,
              this.props.token,
              timeline,
            )
            this.props.fetchPatientCoreDate(patient.uid, this.props.token)
            this.props.fetchPatientWellnessScreening(
              patient.uid,
              this.props.token,
            )
            this.props.fetchPatientCalculateProgressData(
              patient.uid,
              this.props.token,
            )
            this.props.fetchPatientConditions(patient.uid, this.props.token)
            this.props.fetchPatientMedications(patient.uid, this.props.token)
            this.props.fetchPatientLabs(patient.uid, this.props.token)
            this.props.addPatient(patient, colorcode)
            this.props.updateSideNavBar(!this.props.updateRender)
          }
        })
      }
    })
  }

  onPatientNavLinkClicked(lp) {
    this.props.addPatientLP(lp)
    console.log('PATIENT LANDING PAGE: ', lp)
  }

  renderModal() {
    const { patient } = this.props
    const { modalEvent, patientMap, eventsFromBackend } = this.state
    if (!modalEvent) return null
    const eventFromBackend = eventsFromBackend[modalEvent.id] || {}
    const patientData = patient || {}
    //  patientMap[eventFromBackend.patient_id] || {}

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          paddingTop: 20,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() => this.onModalCloseRequested()}
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          {modalEvent && modalEvent.title}
        </h4>

        <div className={Styles.modalRow}>
          <p className={Styles.modalKey}>Care Team</p>
          <p>:</p>
          <p className={Styles.modalValue}>
            {/* <Link
              style={{ color: '#000000', textDecoration: 'underline' }}
              onClick={() => {
                this.navigatetoPatientProfile(patientData.uid)
                this.onPatientNavLinkClicked('profile')
              }}
              to={{
                pathname: '/patient/profile',
                state: 'info',
                key: 2,
              }}
            >
              {`${patientData.firstName} ${patientData.lastName}`}
            </Link> */}
            {modalEvent.doctor_name}
          </p>
        </div>

        <div className={Styles.modalRow}>
          <p className={Styles.modalKey}>Date</p>
          <p>:</p>
          <p className={Styles.modalValue}>
            {modalEvent && moment(modalEvent.start).format('ddd MMM Do YYYY ')}
          </p>
        </div>

        <div className={Styles.modalRow}>
          <p className={Styles.modalKey}>Start Time</p>
          <p>:</p>
          <p className={Styles.modalValue}>
            {modalEvent && moment(modalEvent.start).format('hh:mm A')}
          </p>
        </div>
        <div className={Styles.modalRow}>
          <p className={Styles.modalKey}>End Time</p>
          <p>:</p>
          <p className={Styles.modalValue}>
            {modalEvent && moment(modalEvent.end).format('hh:mm A')}
          </p>
        </div>

        <div className={Styles.modalRow}>
          <p className={Styles.modalKey}>Reason for visit</p>
          <p>:</p>
          <p className={Styles.modalValue}>{eventFromBackend.reason}</p>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            position: 'absolute',
            bottom: 40,
            justifyContent: 'space-between',
            width: '80%',
          }}
        >
          <div
            style={{
              padding: 10,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: Constants.primaryTheme,
              borderRadius: '50%',
            }}
          >
            <FontAwesomeIcon
              onClick={() => {
                this.props.addAppointmentData(eventFromBackend, patientData)

                this.onVideoCallPressed(eventFromBackend, patientData)
              }}
              icon={faVideo}
              style={{ color: 'white', fontSize: 20, cursor: 'pointer' }}
            />
          </div>

          <div
            style={{
              padding: 10,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: Constants.primaryTheme,
              borderRadius: '50%',
            }}
          >
            <FontAwesomeIcon
              onClick={() => {
                this.onReschedulePress(eventFromBackend, patientData)
              }}
              icon={faEdit}
              style={{ color: 'white', fontSize: 20, cursor: 'pointer' }}
            />
          </div>

          <div
            style={{
              padding: 10,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'red',
              borderRadius: '50%',
            }}
          >
            <FontAwesomeIcon
              onClick={() => {
                if (
                  window.confirm(
                    'Are you sure you want to cancel your appointment?',
                  )
                ) {
                  this.cancelAppointment()
                }
              }}
              icon={faMinusCircle}
              style={{ color: 'white', fontSize: 20, cursor: 'pointer' }}
            />
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            position: 'absolute',
            bottom: 10,
            justifyContent: 'space-between',
            width: '80%',
          }}
        >
          <p style={{ color: 'gray' }}>Start</p>
          <p style={{ color: 'gray' }}>Reshedule</p>
          <p style={{ color: 'gray' }}>Cancel</p>
        </div>
      </div>
    )
  }

  eventStyleGetter(event) {
    const style = {
      backgroundColor: event.ailaEvent ? Constants.primaryTheme : '#38B0DE',
      color: 'white',
    }
    return {
      style,
    }
  }

  renderContent() {
    const { mongoUser, showPatientVideoCall, patient, patientCore } = this.props
    let user = { ...patient, ...patientCore }

    let eventsToShow = []
    if (this.state.ailaEventsSelected) eventsToShow = this.state.events || []
    else eventsToShow = this.state.eventsFromNylas || []
    return (
      <div className={GlobalStyles.contentWrapper}>
        <Button
          onClick={() => this.onScheduleClicked(true, true)}
          className={GlobalStyles.button}
          disabled={this.state.loading}
          style={{
            width: 250,
            marginRight: '5vw',
            right: 0,
            position: 'absolute',
            visibility: showPatientVideoCall ? `hidden` : `visible`,
          }}
          variant="primary"
        >
          Schedule Appointment
        </Button>

        {/*MODAL TO JOIN APPOINTMENT */}

        <Modal
          ariaHideApp={false}
          onRequestClose={() => this.onModalCloseRequested()}
          isOpen={this.state.modalIsOpen}
          style={APPOINTMENT_MODAL_STYLES}
          contentLabel="Modal"
        >
          {this.renderModal()}
        </Modal>

        {/*MODAL TO SCHEDULE APPOINTMENT APPOINTMENT - SHOW CARETEAM MEMBERS */}
        <Modal
          ariaHideApp={false}
          onRequestClose={() => this.onModalCloseRequested()}
          isOpen={this.state.showScheduleModal}
          style={SCHEDULE_APPOINTMENT_MODAL_STYLES}
          contentLabel="Modal"
        >
          {this.renderScheduleModal()}
        </Modal>

        {/*MODAL TO SHOW 3 PLANS - MEMBERSHIP, BASIC, PREMIUM */}

        <Modal
          ariaHideApp={false}
          onRequestClose={() => {
            this.setState({ showPaymentModal: false })
            this.setState({
              showScheduleModal: true,
            })
            this.props.toggleSidebar(true)
          }}
          isOpen={this.state.showPaymentModal}
          style={SCHEDULE_APPOINTMENT_MODAL_STYLES}
          contentLabel="Modal"
        >
          {this.renderPaymentModal()}
        </Modal>

        <Modal
          ariaHideApp={false}
          onRequestClose={() => {
            this.setState({ showInsuranceModal: false })
            this.props.toggleSidebar(false)
          }}
          isOpen={this.state.showInsuranceModal}
          style={APPOINTMENT_MODAL_STYLES}
          contentLabel="Modal"
        >
          {this.renderInsuranceModal()}
        </Modal>

        {/*MODAL TO SHOW PAYMENT TERMS */}
        <Modal
          ariaHideApp={false}
          onRequestClose={() => {
            this.setState({ showTermsModal: false })
            this.onScheduleClicked(false, false, true)
            this.props.toggleSidebar(true)
          }}
          isOpen={this.state.showTermsModal}
          style={TERMS_MODAL_STYLES}
          contentLabel="Modal"
        >
          {this.renderTermsModal()}
        </Modal>

        {/*MODAL TO TELL USER TO ENTER CARD DETAILS */}
        <Modal
          ariaHideApp={false}
          onRequestClose={() => {
            this.setState({ showCardLanguage: false })
            this.props.toggleSidebar(false)
          }}
          isOpen={this.state.showCardLanguage}
          style={APPOINTMENT_MODAL_STYLES}
          contentLabel="Modal"
        >
          {this.renderEnterCardModal()}
        </Modal>

        {/*MODAL TO APPOINTMENT CONFIRMATION - PAYMENT DETAILS 49$ 99$ */}

        <Modal
          ariaHideApp={false}
          onRequestClose={() => this.onModalCloseRequested()}
          isOpen={this.state.showConfirmModal}
          style={APPOINTMENT_MODAL_STYLES}
          contentLabel="Modal"
        >
          {this.renderConfirmModal()}
        </Modal>

        {/*MODAL TO DISPLAY SELECTED DOCTORS AVAILABILITY CALENDAR */}

        {this.state.selectedScheduleDoctor && (
          <Modal
            ariaHideApp={false}
            onRequestClose={() => this.onModalCloseRequested()}
            isOpen={this.state.showSlotAvailableModal}
            style={SCHEDULE_APPOINTMENT_MODAL_STYLES}
            contentLabel="Modal"
          >
            {this.renderDoctorCalendarModal()}
          </Modal>
        )}

        <h5 style={{ color: Constants.primaryTheme }}>
          {showPatientVideoCall ? `My Appointment` : `My Calendar`}
        </h5>

        {/*JOIN VIDEO CALL */}

        {showPatientVideoCall && this.renderPatientVideoCall()}

        {/*PATIENT CALENDAR - APPOINTMENTS  */}
        <div
          style={{
            minWidth: '100%',
            display: 'flex',
            flexDirection: 'column',
            padding: '40px 60px',
            marginTop: 20,
            visibility:
              this.state.modalIsOpen ||
              this.state.showScheduleModal ||
              this.state.showSlotAvailableModal ||
              this.state.showPaymentModal ||
              this.state.showCardModal ||
              this.state.showConfirmModal ||
              this.state.showCardLanguage ||
              this.state.showInsuranceModal ||
              this.state.showTermsModal ||
              showPatientVideoCall
                ? 'hidden'
                : 'visible',
          }}
        >
          {/*  calendar */}
          <Calendar
            defaultView="month"
            onSelectEvent={this.onSelectEvent.bind(this)}
            localizer={localizer}
            defaultDate={new Date()}
            events={eventsToShow}
            startAccessor="start"
            endAccessor="end"
            tooltip={(event) => <p>{event.title}</p>}
            eventPropGetter={this.eventStyleGetter.bind(this)}
            style={{ height: 700 }}
          />
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className={GlobalStyles.container}>
        <Header
          header={this.state.showPrevisit ? `Pre Visit Intake` : `Appointments`}
        />

        {
          this.state.loading ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: '100%',
                height: '80vh',
              }}
            >
              <div className={GlobalStyles.loader} />
            </div>
          ) : this.state.showPrevisit ? (
            <PreVisitIntake
              doctorDetails={this.state.selectedScheduleDoctor}
              parentCallback={this.handleIntakeCallback}
              reschedule={this.state.reschedule}
              joinVideoCall={this.state.joinVideoCall}
              prev_event_id={this.state.rescheduleData}
              dataPassed={this.state.dataPassed}
            />
          ) : (
            this.renderContent()
          )
          // this.renderContent()
        }
      </div>
    )
  }
}

const mapStateToProps = (state /* , ownProps */) => ({
  loggedIn: state.userReducer.loggedIn,
  patient: state.patientReducer.patient,
  token: state.authReducer.token,
  docFirebaseAuthUser: state.userReducer.firebaseAuthUser,
  mongoUser: state.userReducer.mongoUser,
  adminId: state.userReducer.adminId,
  patientList: state.patientReducer.patientList,
  showPatientVideoCall: state.genericReducer.showPatientVideoCall,
  patientCore: state.patientReducer.patientCore,
  patientCareTeam: state.patientReducer.patientCareTeam,
})

const mapDispatchToProps = {
  stopTimer,
  updateSideNavBar,
  addRpmPatient,
  addPatientLP,
  addAppointmentData,
  resetTimer,
  startTimer,
  addVirgilE3,
  updateDoctorMongoUser,
  addMongoUser,
  addPatient,
  addFirebaseUser,
  userLoggedIn,
  fetchPatientCoreDate,
  fetchPatientProgressData,
  fetchPatientWellnessScreening,
  fetchPatientCalculateProgressData,
  updatePatientData,
  addPatientList,
  setPatientCoreData,
  fetchPatientConditions,
  fetchPatientMedications,
  fetchPatientLabs,
  toggleSidebar,
  toggleVideoModal,
  togglePatientVideoCall,
  addTwilioToken,
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientAppointments)
