import React, { useState } from 'react'
import moment from 'moment'
import { ToastContainer, toast } from 'react-toastify'
import { EThree } from '@virgilsecurity/e3kit-browser'
import {
  faAppleAlt,
  faTrash,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BSModal from 'react-bootstrap/Modal'
import ModalDialog from 'react-bootstrap/ModalDialog'
import Styles from './styles/ClinicalSupport.module.scss'
import GlobalStyles from '../styles/global.module.scss'
import Header from '../../components/Header'
import TaskModal from '../Patients/Careplan/TaskModal'
import placeHolder from '../../assets/images/placeholder.png'
import Fuse from 'fuse.js'
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import Select from 'react-select'
import Modal from 'react-modal'

import {
  addRpmPatient,
  setPatientCoreData,
  updateSideNavBar,
  stopTimer,
  resetTimer,
  startTimer,
  addVirgilE3,
  addPatient,
  userLoggedIn,
  fetchPatientCoreDate,
  fetchPatientProgressData,
  fetchPatientWellnessScreening,
  fetchPatientCalculateProgressData,
  updatePatientData,
  addPatientList,
  addMongoUser,
  updateDoctorMongoUser,
  fetchPatientConditions,
  fetchPatientMedications,
  fetchPatientLabs,
  addPatientLP,
} from '../../redux/actions'
import firebase from '../../services/firebase.js'
import Config from '../../config.js'
import Constants from '../../values'
import AppointmentNotes from '../Appointments/AppointmentNotes'
import Table from '../../components/Table'
import { clinicalSupportColumns } from './Columns/clinicalSupportColumns'
const axios = require('axios')

const YELLOW = '#ffae42'

let windowHeight = window.innerHeight
const customModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '50%',
    height: windowHeight / 1.4,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const STATUS_OPTIONS = [
  { label: 'Not Started', value: 'not_started' },
  { label: 'In Progress', value: 'in_progress' },
  { label: 'Completed', value: 'completed' },
]

class ClinicalSupport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      patientListColor: 'gray',
      loading: true,
      modalPatientRows: [
        {
          name: '',
          email: '',
          error: false,
          errorString: '',
        },
      ],
      modalNew: true,
      modalLoading: false,
      modalError: false,
      modalErrorString: '',
      doctorInvites: null,
      patientListData: [],
      patientList: [],
      completePatientList: [],
      cohortsToAdd: [''],
      cohortModalError: false,
      cohortModalErrorString: '',
      shortDynamicLink: null,
      checkedIn: false,
      initializingVirgil: false,
      supportList: [],
      sortDesc: false,
      allSupportList: [],
      patientListTemp: [],
      providerOptionsData: [],
      allProviderData: [],
      superUser: false,
      searchName: '',
      tempNotes: '',
      modalTaskData: null,
      modalIsOpen: false,
      activeIndex: 1,
    }
    this.fuse = null
    this.onModalClosePressed = this.onModalClosePressed.bind(this)
    this.onPatientCheckedIn = this.onPatientCheckedIn.bind(this)
    this.getVirgilTokenFromBackend = this.getVirgilTokenFromBackend.bind(this)
    this.initializeVirgil = this.initializeVirgil.bind(this)
    this.restorePrivateKey = this.restorePrivateKey.bind(this)
    this.getPatientList = this.getPatientList.bind(this)
    this.navigateToRpmPatientProfile =
      this.navigateToRpmPatientProfile.bind(this)
    this.onPatientClicked = this.onPatientClicked.bind(this)
    this.getSupportRequest = this.getSupportRequest.bind(this)
    this.navigatetoPatientProfile = this.navigatetoPatientProfile.bind(this)
    this.onPatientNavLinkClicked = this.onPatientNavLinkClicked.bind(this)
    this.updateSupportTicket = this.updateSupportTicket.bind(this)
    this.getAllProviderData = this.getAllProviderData.bind(this)
    this.openNotesModal = this.openNotesModal.bind(this)
  }

  componentDidMount() {
    if (!this.props.loggedIn) {
      this.props.history.push('/login')
      return
    }

    const { token, adminId, mongoUser } = this.props
    this.props.stopTimer(adminId, token)
    this.getPatientList()
    this.getAllProviderData()
    this.props.setPatientCoreData(null)
  }

  getSupportRequest() {
    const { token, adminId, firebaseUser, loading, mongoUser } = this.props
    let url = Config.BACKEND_URL + 'support-request'
    // this.setState({ loading: true })
    let config = {
      method: 'get',
      url,
      headers: {
        x_firebase_id: adminId,
        Authorization: `JWT ${token}`,
        clinical_support: true,
        admin: true,
      },
    }
    // if(mongoUser?.user_type?.toLowerCase() === 'administrator')
    //   config.headers.admin = true
    axios(config)
      .then(({ data }) => {
        console.log('TESTIN: ', data)
        let openSupportList = data?.filter(
          (support) =>
            !support?.assigned_to &&
            support?.status !== 'completed' &&
            support?.status !== 'in_progress',
        )
        this.setState(
          {
            supportList: openSupportList,
            allSupportList: data,
            loading: false,
          },
          () => this.onTabSelected(this.state.activeIndex),
        )
      })
      .catch((err) => console.log('err while fetching support list: ', err))
  }

  getPatientList() {
    console.log('CALLING GET PATIENT LIST')
    const { token, adminId, firebaseUser, mongoUser } = this.props
    const self = this
    const url = `${Config.BACKEND_URL}doctor/patients/list` + '?access=admin'
    axios({
      method: 'get',
      url,
      headers: { x_doctor_id: adminId, Authorization: `JWT ${token}` },
    })
      .then(function (response) {
        // handle success
        if (response.status === 200) {
          const { data } = response
          let total = []
          data &&
            Object.keys(data).forEach((color) => {
              total = total.concat(data[color])
            })
          self.setState({
            patientListData: data || {},
            patientList: data ? total : [], // default
            completePatientList: total,
          })
          self.props.addPatientList(data)
          self.setState({ patientListTemp: data })
          if (self.props.rpmPatientId) {
            self.navigateToRpmPatientProfile(data)
          }
        }
      })
      .catch(function (error) {
        // handle error
        console.log('error when getting patient list', error, token)
      })
  }

  getAllProviderData = () => {
    axios({
      method: 'get',
      url: Config.BACKEND_URL + 'providers',
      headers: {
        x_firebase_id: this.props.adminId,
        Authorization: 'JWT ' + this.props.token,
        getallproviders: true,
      },
    })
      .then(({ data }) => {
        let providerOptionList = []
        let tempAllProviderData = data.reduce((data, doc) => {
          data[doc.speciality || 'Other'] =
            data[doc.speciality || 'Other'] || []
          data[doc.speciality || 'Other'].push({
            label:
              doc?.first_name + ' ' + doc?.last_name + `(${doc?.user_type})`,
            value: doc?.firebase_id,
          })
          return data
        }, Object.create(null))
        Object.entries(tempAllProviderData).forEach(
          ([userType, doctorArray]) => {
            providerOptionList.push({
              label: userType,
              options: doctorArray,
            })
          },
        )
        let tempData = data.map((doc) => ({
          label: doc?.first_name + ' ' + doc?.last_name,
          value: doc?.firebase_id,
        }))
        this.setState(
          {
            providerOptionsData: providerOptionList,
            allProviderData: tempData,
          },
          () => {
            this.getSupportRequest()
          },
        )
      })
      .catch((err) => {
        console.log('err while loading provider data: ', err)
      })
  }

  navigateToRpmPatientProfile(patientList) {
    const { rpmPatientId } = this.props
    let rpmPatient = null
    Object.values(patientList).forEach((colorCodedPatients) => {
      if (colorCodedPatients.length > 0) {
        colorCodedPatients.forEach((patient) => {
          if (patient.uid === rpmPatientId) rpmPatient = patient
        })
      }
    })
    this.onPatientClicked(rpmPatient, 'red')
  }

  initializeVirgil() {
    const { mongoUser, token, adminId } = this.props
    const self = this
    console.log('initializing virgil')
    EThree.initialize(this.getVirgilTokenFromBackend)
      .then((eThree) => {
        // register user, encrypt, decrypt, etc.
        console.log('successful intialization e3')
        if (!mongoUser.virgil_registered) {
          eThree
            .register()
            .then(() => {
              console.log('successfully registered user with virigl')
              self.props.updateDoctorMongoUser(
                { virgil_registered: true, firebase_id: adminId },
                mongoUser,
                token,
              )
              eThree
                .backupPrivateKey(adminId)
                .then(() => console.log('success'))
                .catch((e) => console.error('error: ', e))
            })
            .catch((err) => {
              console.error('error when registering: ', err)
              if (err.name === 'IdentityAlreadyExistsError') {
                self.props.updateDoctorMongoUser(
                  { virgil_registered: true, firebase_id: adminId },
                  mongoUser,
                  token,
                )
                self.restorePrivateKey(eThree, mongoUser, token, adminId)
              }
              // self.deleteUser(eThree, mongoUser, token)

              // eThree.rotatePrivateKey()
              //   .then(() => {
              //     console.log('successully rotated private key')
              //     self.props.updateDoctorMongoUser({virgil_registered: true, firebase_id: mongoUser.firebase_id}, mongoUser, token)
              //   })
              //   .catch(e => console.error('error: ', e));
            })
        } else {
          self.restorePrivateKey(eThree, mongoUser, token, adminId)
        }
        self.props.addVirgilE3(eThree)
      })
      .catch((err) => {
        console.log('error when intializing virgil SDK', err)
      })
  }

  async deleteUser(eThree, mongoUser, token) {
    try {
      await eThree.cleanup()
      await eThree.rotatePrivateKey()
    } catch (err) {
      console.log('error when deleting user', err)
    }
  }

  async restorePrivateKey(eThree, mongoUser, token, adminId) {
    console.log('restoring private key')
    const hasLocalPrivateKey = await eThree.hasLocalPrivateKey()

    if (!hasLocalPrivateKey) {
      console.log('no local copy')
      try {
        await eThree.restorePrivateKey(adminId)
        console.log('restored private key')
      } catch (err) {
        console.log('error when restoring private key from backup', err)
      }
    }
    console.log('restored private key')
    // this.props.updateDoctorMongoUser({virgil_registered: false, firebase_id: mongoUser.firebase_id}, mongoUser, token)
  }

  async getVirgilTokenFromBackend() {
    const { mongoUser, token } = this.props
    const url = `${Config.BACKEND_URL}virgil-jwt`
    const response = await axios({
      method: 'get',
      url,
      headers: {
        x_firebase_id: mongoUser.firebase_id,
        Authorization: `JWT ${token}`,
      },
    })

    if (response && response.data && response.data.virgilToken)
      return response.data.virgilToken
    return null
  }

  onModalClosePressed() {
    this.setState({
      modalIsOpen: false,
      modalTaskData: null,
    })
  }

  onPatientCheckedIn(patientList, index, checked) {
    const patient = patientList[index]
    firebase
      .firestore()
      .collection('users')
      .doc(patient.uid)
      .update({
        checkedIn: checked,
      })
      .then(() => {
        patient.checkedIn = checked
        patientList[index] = patient
        const color = this.state.patientListColor
        const { patientListData } = this.state
        if (color === 'red') patientListData.red = patientList
        else if (color === YELLOW) patientListData.yellow = patientList
        else if (color === 'green') patientListData.green = patientList

        this.setState({ patientListData, patientList })
        toast.success('Updated successfully')
      })
      .catch((err) => {
        console.log('error when updating doc', err)
      })
  }

  navigatetoPatientProfile(p_id) {
    let { mongoUser, token, adminId } = this.props
    let { patientList } = this.props
    let tempPatientList = patientList || this.state.patientListTemp
    console.log('testing on click: ', patientList, p_id)
    if (!tempPatientList) return

    Object.values(tempPatientList).forEach((colorCodedPatients, index) => {
      if (colorCodedPatients.length > 0) {
        let result = colorCodedPatients.filter(
          (patient) => patient.uid === p_id,
        )
        console.log('RESUKT ARRAY : ', result)
        result.forEach((patient) => {
          let colorcode = Object.keys(tempPatientList)[index]
          if (patient.uid === p_id) {
            this.props.updatePatientData(
              {
                doctor_id: adminId,
                patient_id: patient.uid,
                last_reviewed: new Date(),
              },
              token,
            )

            //if the doctor was already on on another patient page and just clicked on a new patient, reset time
            if (this.props.patientTimer && this.props.patientTimer !== 0)
              this.props.resetTimer(patient.uid, adminId, token)
            else this.props.startTimer(patient.uid)
            let preferences = patient.preferences
            let timeline = 'complete'
            if (preferences && preferences.timeline) {
              timeline = preferences.timeline.value
            }
            this.props.fetchPatientProgressData(
              patient.uid,
              this.props.token,
              timeline,
            )
            this.props.fetchPatientCoreDate(patient.uid, this.props.token)
            this.props.fetchPatientWellnessScreening(
              patient.uid,
              this.props.token,
            )
            this.props.fetchPatientCalculateProgressData(
              patient.uid,
              this.props.token,
            )
            this.props.fetchPatientConditions(patient.uid, this.props.token)
            this.props.fetchPatientMedications(patient.uid, this.props.token)
            this.props.fetchPatientLabs(patient.uid, this.props.token)
            this.props.addPatient(patient, colorcode)
            this.props.updateSideNavBar(!this.props.updateRender)
          }
        })
      }
    })
  }

  onPatientNavLinkClicked(lp) {
    this.props.addPatientLP(lp)
    console.log('PATIENT LANDING PAGE: ', lp)
  }

  onPatientClicked(patient, color) {
    const { mongoUser, token, adminId } = this.props
    this.props.updatePatientData(
      {
        doctor_id: adminId,
        patient_id: patient.uid,
        last_reviewed: new Date(),
      },
      token,
    )

    // if the doctor was already on on another patient page and just clicked on a new patient, reset time
    if (this.props.patientTimer && this.props.patientTimer !== 0)
      this.props.resetTimer(patient.uid, adminId, token)
    else this.props.startTimer(patient.uid)
    const { preferences } = patient
    let timeline = 'complete'
    if (preferences && preferences.timeline) {
      timeline = preferences.timeline.value
    }
    this.props.fetchPatientProgressData(patient.uid, this.props.token, timeline)
    this.props.fetchPatientCoreDate(patient.uid, this.props.token)
    this.props.fetchPatientWellnessScreening(patient.uid, this.props.token)
    this.props.fetchPatientCalculateProgressData(patient.uid, this.props.token)
    this.props.fetchPatientConditions(patient.uid, this.props.token)
    this.props.fetchPatientMedications(patient.uid, this.props.token)
    this.props.fetchPatientLabs(patient.uid, this.props.token)
    this.props.addPatient(patient, color)
    this.props.updateSideNavBar(!this.props.updateRender)
    const patientLp = this.props.patientLP
    if (this.props.rpmPatientId) {
      this.props.addRpmPatient(null)
      this.props.history.push('/patient/progress')
    } else {
      this.props.history.push('/patient/profile')
    }
  }

  openNotesModal(support) {
    this.setState({
      modalIsOpen: true,
      modalTaskData: support,
    })
  }
  updateSupportTicket(tempSupport) {
    let { mongoUser, token, adminId } = this.props
    let config = {
      method: 'put',
      url: Config.BACKEND_URL + 'support-request',
      headers: {
        x_firebase_id: adminId,
        Authorization: 'JWT ' + token,
      },
      data: tempSupport,
    }
    axios(config)
      .then(() => {
        toast.success('Updated successfully')
        this.getSupportRequest()
        console.log('support request edited', this.state.activeIndex)
      })
      .catch((err) => console.log('error while assigning the coach: ', err))
  }

  sortListByDueDate() {
    const { supportList, sortDesc } = this.state
    if (sortDesc) {
      supportList.sort((a, b) => new Date(b.due_date) - new Date(a.due_date))
    } else {
      supportList.sort((a, b) => new Date(a.due_date) - new Date(b.due_date))
    }

    this.setState({ supportList, sortDesc: !sortDesc })
  }

  sortListByName() {
    const { patientList } = this.state

    patientList.sort(function (a, b) {
      if (a.lastName < b.lastName) {
        return -1
      }
      if (a.lastName > b.lastName) {
        return 1
      }
      return 0
    })

    this.setState({ patientList })
  }

  renderTimeTrackingData(time) {
    if (typeof time === 'number') {
      const timeInMins = Math.round(time / 60) // cos time is tracked in seconds
      return `${timeInMins} mins`
    }

    return 'N/A'
  }

  // assigns patient color
  getPatientColor = (patientId) => {
    let color = 'gray'
    if (
      this.state.patientListData.green.some(
        (patient) => patient.uid === patientId,
      )
    )
      color = 'green'
    if (
      this.state.patientListData.yellow.some(
        (patient) => patient.uid === patientId,
      )
    )
      color = 'yellow'
    if (
      this.state.patientListData.red.some(
        (patient) => patient.uid === patientId,
      )
    )
      color = 'red'
    return color
  }

  onTabSelected(index) {
    if (!index) return
    let tempSupportList = []
    switch (index) {
      case 1:
        tempSupportList = this.state.allSupportList?.filter(
          (support) =>
            !support?.assigned_to &&
            support?.status !== 'completed' &&
            support?.status !== 'in_progress',
        )
        this.setState({ supportList: tempSupportList, activeIndex: 1 })
        break
      case 2:
        tempSupportList = this.state.allSupportList?.filter((support) =>
          support?.status
            ? support?.status !== 'completed'
            : !support?.completed_status,
        )
        this.setState({ supportList: tempSupportList, activeIndex: 2 })
        break
      case 3:
        tempSupportList = this.state.allSupportList?.filter(
          (support) =>
            support.completed_status || support?.status === 'completed',
        )
        this.setState({ supportList: tempSupportList, activeIndex: 3 })
        break
      default:
    }
  }

  tableHeader() {
    return (
      <div className={Styles.contentRow}>
        <h6 className={Styles.smtableHeader}>Checked</h6>
        <h6 className={Styles.smtableHeader}>Created At</h6>
        {/*<h6 className={Styles.tableHeader}>Frequency</h6>*/}
        <h6 className={Styles.mtableHeader}>Raised By</h6>
        <h6 className={Styles.mtableHeader}>Patient Name</h6>
        <h6 className={Styles.mtableHeader}>Provider Name</h6>
        <h6 className={Styles.smtableHeader}>Priority</h6>
        <h6 className={Styles.mtableHeader}>Subject</h6>
        <h6 className={Styles.xtableHeader}>Description</h6>
        <h6 className={Styles.xtableHeader}>Assign</h6>
        <h6 className={Styles.mtableHeader}>Status</h6>
        <h6 className={Styles.smtableHeader}>Due Date</h6>
        <h6 className={Styles.smtableHeader}>Notes</h6>
      </div>
    )
  }

  tableContent() {
    const toReturn = []
    let list
    let color
    const {
      patientList,
      supportList,
      allSupportList,
      patientListColor,
      searchName,
      providerOptionsData,
      allProviderData,
      loading,
    } = this.state

    if (!supportList) return null

    if (supportList.length === 0 && !loading) {
      return (
        <div
          style={{
            color: 'gray',
            width: '100%',
            textAlign: 'center',
            marginTop: 50,
          }}
        >
          <p>No task present</p>
        </div>
      )
    }

    supportList.forEach((support, index) => {
      let allSupportIndex = allSupportList.findIndex(
        (data) => data._id === support._id,
      )
      // console.log('DUE DATE: ', support?.due_date, moment(support?.due_date).format('MMM DD YYYY'))
      toReturn.push(
        <div key={index.toString()} className={Styles.contentRow}>
          <div className={Styles.smtableData}>
            <input
              checked={support?.checkedIn}
              key={index.toString()}
              onChange={(e) => {
                console.log('tesit', e.target.checked)
                let tempSupport = {
                  _id: support._id,
                  checkedIn: e.target.checked,
                }
                this.updateSupportTicket(tempSupport)
                let tempData = supportList
                tempData[index].checkedIn = e.target.checked
                let tempAllData = allSupportList
                tempAllData[allSupportIndex].checkedIn = e.target.checked
                this.setState({
                  supportList: tempData,
                  allSupportList: tempAllData,
                })
              }}
              type="checkbox"
            />
          </div>
          <div className={Styles.smtableData}>
            <p>{moment(support?.created_at).format('MMM DD YYYY')}</p>
          </div>
          <div className={Styles.mtableData}>
            <p>
              {support?.userType?.toLowerCase() !== 'patient'
                ? support?.name
                : 'Patient'}
            </p>
          </div>
          <div className={Styles.mtableData}>
            <Link
              onClick={() => {
                this.navigatetoPatientProfile(
                  support?.userType?.toLowerCase() === 'patient'
                    ? support?.firebase_id
                    : support?.patient_id,
                )
                this.onPatientNavLinkClicked('profile')
              }}
              to={{
                pathname: '/patient/profile',
                state: 'info',
                key: 2,
              }}
            >
              <p style={{ fontWeight: 'bold', color: '#20A892' }}>
                {support?.patient_name}
              </p>
            </Link>
          </div>

          <div className={Styles.mtableData}>
            <p>
              {allProviderData.find((obj) => obj.value === support?.doctor_id)
                ?.label || '-'}
            </p>
          </div>

          <div className={Styles.smtableData}>
            <p>{support?.priority}</p>
          </div>

          <div className={Styles.mtableData}>
            <p>{support?.subject}</p>
          </div>

          <div className={Styles.xtableData}>
            <p
              style={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              data-tip
              data-for={index + 'desc'}
            >
              {support?.description}
            </p>
          </div>

          <ReactTooltip
            id={index + 'desc'}
            multiline={true}
            place={'top'}
            effect={'solid'}
            clickable={true}
            className={Styles.tooltip}
          >
            {support?.description}
          </ReactTooltip>

          <div className={Styles.xtableData}>
            <Select
              options={providerOptionsData}
              // className={Styles.addSelectInput}
              autoBlur={true}
              isMulti={false}
              onChange={(val) => {
                let tempSupport = {
                  _id: support._id,
                  assigned_to: val.value,
                }
                this.updateSupportTicket(tempSupport)
                let tempData = supportList
                tempData[index].assigned_to = val.value
                let tempAllData = allSupportList
                tempAllData[allSupportIndex].assigned_to = val.value
                this.setState({
                  supportList: tempData,
                  allSupportList: tempAllData,
                })
              }}
              value={
                allProviderData.find(
                  (obj) => obj.value === support?.assigned_to,
                ) || null
              }
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>

          <div className={Styles.mtableData}>
            <Select
              options={STATUS_OPTIONS}
              // className={Styles.addSelectInput}
              autoBlur={true}
              isMulti={false}
              disable
              onChange={(val) => {
                let tempSupport = {
                  _id: support._id,
                  status: val.value,
                }
                this.updateSupportTicket(tempSupport)
                let tempData = supportList
                tempData[index].status = val.value
                let tempAllData = allSupportList
                tempAllData[allSupportIndex].status = val.value
                this.setState({
                  supportList: tempData,
                  allSupportList: tempAllData,
                })
              }}
              value={
                STATUS_OPTIONS.find((obj) => obj.value === support?.status) ||
                (support?.completed_status
                  ? STATUS_OPTIONS[2]
                  : STATUS_OPTIONS[0])
              }
              select
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>

          <div className={Styles.smtableData}>
            {/* {(diffLastApp > 30 || diffLastApp === undefined) && (
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className={Styles.warningIcon}
              />
            )} */}
            <p
              style={{
                color:
                  !(
                    support?.completed_status || support?.status === 'completed'
                  ) && moment(support?.due_date).isBefore(moment())
                    ? 'red'
                    : 'black',
              }}
            >
              {support?.due_date
                ? moment(support?.due_date).format('MMM DD YYYY')
                : ''}
            </p>
          </div>
          <div className={Styles.smtableData}>
            <p
              data-tip
              data-for={index + 'note'}
              style={{
                textDecoration: 'underline',
                textDecorationColor: Constants.primaryTheme,
                color: Constants.primaryTheme,
                cursor: 'pointer',
                textAlign: 'center',
              }}
              onClick={() => {
                this.setState({
                  modalIsOpen: true,
                  modalTaskData: support,
                })
              }}
            >
              Notes
            </p>
            {support?.task_note ? (
              <ReactTooltip
                id={index + 'note'}
                multiline={true}
                place={'top'}
                effect={'solid'}
                clickable={true}
                className={Styles.tooltip}
              >
                {support?.task_note}
              </ReactTooltip>
            ) : null}
          </div>
        </div>,
      )
    })

    return toReturn
  }

  render() {
    if (this.state.loading)
      return (
        <div className={GlobalStyles.container} style={{ height: '100vh' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              height: '100vh',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        </div>
      )

    return (
      <div className={GlobalStyles.container}>
        <Header header="Support" />

        <div className={GlobalStyles.contentWrapper}>
          <Tabs
            onTabSelected={this.onTabSelected.bind(this)}
            index={this.state.activeIndex}
          />
          <Table
            columns={clinicalSupportColumns({
              navigatetoPatientProfile: this.navigatetoPatientProfile,
              onPatientNavLinkClicked: this.onPatientNavLinkClicked,
              providerOptionsData: this.state.providerOptionsData,
              allProviderData: this.state.allProviderData,
              updateSupportTicket: this.updateSupportTicket,
              openNotesModal: this.openNotesModal,
            })}
            data={this.state.supportList}
          />
          <TaskModal
            supportData={this.state.modalTaskData}
            modalIsOpen={this.state.modalIsOpen}
            hideTaskModal={this.onModalClosePressed}
            getUserTask={this.getSupportRequest}
          />
          {/* <div style={{ width: '100%', margin: 10, position: 'relative', display: 'flex', justifyContent: 'center' }}>
						<div className={Styles.contentBackground}>
							{this.tableHeader()}
							{this.tableContent()}
             
						</div>
					</div> */}
        </div>
      </div>
    )
  }
}

const Tabs = (props) => {
  const [activeTab, setActiveTab] = useState(props.index)

  const onTabSelected = (index) => {
    setActiveTab(index)
    props.onTabSelected(index)
  }

  return (
    <div
      style={{
        flexDirection: 'row',
        display: 'flex',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'gray',
        width: 600,
        marginLeft: 100,
        height: 40,
        marginTop: 40,
      }}
    >
      <div
        onClick={() => onTabSelected(1)}
        className={Styles.tabItem}
        style={{ backgroundColor: activeTab === 1 ? '#DCDCDC' : '' }}
      >
        <p style={{ color: '#20A892' }}>Unassigned Task</p>
      </div>
      <div
        onClick={() => onTabSelected(2)}
        className={Styles.tabItem}
        style={{ backgroundColor: activeTab === 2 ? '#DCDCDC' : '' }}
      >
        <p style={{ color: '#20A892' }}>Open Task</p>
      </div>
      <div
        onClick={() => onTabSelected(3)}
        className={Styles.tabItem}
        style={{ backgroundColor: activeTab === 3 ? '#DCDCDC' : '' }}
      >
        <p style={{ color: '#20A892' }}>Completed Task</p>
      </div>
    </div>
  )
}

const mapStateToProps = (state /* , ownProps */) => ({
  patient: state.patientReducer.patient,
  patientLP: state.patientReducer.lp,
  loggedIn: state.userReducer.loggedIn,
  token: state.authReducer.token,
  docFirebaseAuthUser: state.userReducer.firebaseAuthUser,
  mongoUser: state.userReducer.mongoUser,
  e3: state.authReducer.e3,
  patientTimer: state.patientReducer.timer,
  rpmPatientId: state.patientReducer.rpmPatientId,
  adminId: state.userReducer.adminId,
  userType: state.userReducer.userType,
  updateRender: state.userReducer.render,
  firebaseUser: state.userReducer.firebaseUser,
})

const mapDispatchToProps = {
  updateSideNavBar,
  stopTimer,
  resetTimer,
  startTimer,
  addVirgilE3,
  updateDoctorMongoUser,
  addMongoUser,
  addPatient,
  userLoggedIn,
  fetchPatientCoreDate,
  fetchPatientProgressData,
  fetchPatientWellnessScreening,
  fetchPatientCalculateProgressData,
  updatePatientData,
  addPatientList,
  setPatientCoreData,
  addRpmPatient,
  fetchPatientConditions,
  fetchPatientMedications,
  fetchPatientLabs,
  addPatientLP,
}

export default connect(mapStateToProps, mapDispatchToProps)(ClinicalSupport)