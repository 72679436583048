import React, { useState, useEffect } from 'react'
import GlobalStyles from '../styles/global.module.scss'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import { geocodeByAddress } from 'react-google-places-autocomplete'
import values from '../../values'
import Constants from '../../values'
import Select from 'react-select'
import DatePicker from '../../components/DatePicker'

import Button from 'react-bootstrap/Button'
import FancyField from 'react-fancy-field'
import AilaLogo from '../../assets/images/aila_logo.png'
import { useHistory, useLocation } from 'react-router-dom'
import Config from '../../config'
import { firebase, analytics } from '../../services/firebase'
import moment from 'moment'
import * as metadata from '../../metadata'
import SignaturePad from 'react-signature-canvas'

import { useSelector, useDispatch } from 'react-redux'
import {
  addAuthToken,
  addFirebaseUser,
  addFirebaseAuthUser,
  addPatient,
  fetchPatientCoreDate,
} from '../../redux/actions'
import { toast } from 'react-toastify'

const axios = require('axios')

export default function RpmConsentForm(props) {
  // const token = useSelector((state) => state.authReducer.token)
  // const patient = useSelector((state) => state.patientReducer.patient)

  //Signature
  const [patientSignature, setPatientSignature] = useState(null)
  const [patientId, setPatientId] = useState(null)
  const [doctorId, setDoctorId] = useState(null)
  const [doctorName, setDoctorName] = useState('')
  const [doctorSignature, setDoctorSignature] = useState(null)
  //ADDRESS OF THE PATIENT
  const [address, setAddress] = useState('')
  const [address2, setAddress2] = useState(null)
  const [addressComponents, setAddressComponents] = useState(null)
  const [postalCode, setpostalCode] = useState('')
  const [locationSearch, setLocationSearch] = useState([null])
  const [locationState, setLocationState] = useState('')
  const [gender, setGender] = useState('')
  const [languages, setLanguages] = useState('')
  const [dob, setDob] = useState(new Date())
  const [height, setHeight] = useState('')
  const [heightinch, setHeightinch] = useState()
  const [weight, setWeight] = useState('')
  const [error, setError] = useState(false)
  const [errorString, setErrorString] = useState('')
  let sigPad = {}
  const history = useHistory()
  const dispatch = useDispatch()
  const myParam = useLocation().search

  useEffect(() => {
    const patient_id = new URLSearchParams(myParam).get('patient_id')
    const doctor_id = new URLSearchParams(myParam).get('doctor_id')
    if (!patient_id || !doctor_id) {
      history.push('/signup/patient')
    }
    setPatientId(patient_id)
    setDoctorId(doctor_id)
    getProviderData(doctor_id)
    console.log(patient_id, doctor_id)
  }, [])

  const getProviderData = (doctor_id) => {
    const url = Config.BACKEND_URL + `rpm/consent?doctor_id=${doctor_id}`
    axios({
      method: 'get',
      url,
    })
      .then(({ data }) => {
        // console.log('got doctor mongo details', data)
        setDoctorSignature(data.sign_image)
        setDoctorName(data?.first_name + ' ' + data?.last_name)
      })
      .catch((err) => {
        console.log('error when getting doctor mongo user Education', err)
      })
  }

  const savePatientSignature = async (signature) => {
    const data = {
      patient_id: patientId,
      doctor_id: doctorId,
      patient_signature: signature,
    }

    let config = {
      method: 'post',
      url: Config.BACKEND_URL + `rpm/consent`,
      data: data,
    }
    axios(config)
      .then(() => {
        console.log('saved to mongo ')
        toast.success('Saved consent')
        history.push('/login')
      })
      .catch((err) => {
        toast.warning('Something went wrong, please try again')
        console.log('error when saving data', err)
      })

    let toSaveObject = {
      rpm_consent: data,
    }

    firebase
      .firestore()
      .collection('users')
      .doc(patientId)
      .update(toSaveObject)
      .then(() => {
        console.log('updated in firebase')
      })
      .catch((err) =>
        console.log('error when updating user last signed in', err),
      )
  }

  const clear = () => {
    sigPad.clear()
  }
  const trim = () => {
    console.log(sigPad)
    let trimmedDataURL = sigPad.getTrimmedCanvas().toDataURL()
    const storageRef = firebase.storage().ref()

    const uploadTask = storageRef
      .child(`signatures/${patientId}/ReactCanvasSign`)
      .putString(trimmedDataURL.split(',')[1], 'base64', {
        contentType: 'image/png',
      })
    // const uploadTask = storageRef
    //   .child(`signatures/${mongoUser.firebase_id}/${file.name}`)
    //   .put(file)
    uploadTask.on(
      'state_changed',
      function (snapshot) {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        console.log(`${progress}%`)
      },
      function (err) {
        // Handle unsuccessful uploads
        console.log('error when uploading image', err)
        toast.warning('Something went wrong, please try again')
        // self.saveUserToBackend(userDetails)
      },
      function () {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          console.log(downloadURL)
          savePatientSignature(downloadURL)
        })
      },
    )
  }

  return (
    <div
      className={GlobalStyles.container}
      style={{
        overflow: 'hidden',
        margin: '0 auto',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',

          alignItems: 'center',
          marginTop: '2%',
        }}
      >
        {/* <img
          alt="Aila Health"
          src={AilaLogo}
          style={{
            width: '15vh',
            height: '15vh',
            alignSelf: 'flex-start',
          }}
        /> */}

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '80%',
            alignItems: 'center',
            margin: '0 auto',
          }}
        >
          <div
            style={{
              width: '100%',
              alignSelf: 'center',
              marginTop: '5vh',
            }}
          >
            <p
              style={{
                fontSize: 18,
                margin: 'auto',
                color: values.primaryThemeDark,
                textAlign: 'center',
                fontWeight: 'bold',
              }}
            >
              Aila Medical, PC <br /> 1999 Harrison Street,
              <br /> Oakland, CA 94612 <br /> Phone: 415-704-9601 <br /> Fax. #
              (720) 500-9759
            </p>
          </div>

          {/* <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              marginTop: '4vh',
              justifyContent: 'center',
            }}
          > */}
          <div
            style={{
              width: '90%',
              alignSelf: 'center',
              marginTop: 30,
              marginBottom: 30,
            }}
          >
            <p
              style={{
                fontSize: 14,
                margin: 'auto',
                // color: values.primaryThemeDark,
                fontFamily: 'Avenir-Regular',
                textAlign: 'center',
              }}
            >
              Remote Patient Monitoring (“RPM”) is the use of a digital
              technology to collect your physiological health data outside of a
              clinical setting (for example, while you are at home or at work)
              and transmit that data to your healthcare provider for evaluation.
              <b>
                IF YOU DO NOT UNDERSTAND OR AGREE TO ANY OR ALL OF THE ITEMS
                BELOW, PLEASE DO NOT CHECK OR SIGN THIS CONSENT FORM.
              </b>
              <br />
              <br />
            </p>
            <p
              style={{
                fontSize: 14,
                margin: 'auto',
                // color: values.primaryThemeDark,
                fontFamily: 'Avenir-Regular',
                textAlign: 'left',
              }}
            >
              By signing below, you acknowledge the following:
              <br />
              1. Your physician or healthcare provider has explained to you what
              RPM means, the type of health data that will be collected, and how
              it will be used in your care;
              <br />
              2. You are aware that your health data will be collected and
              transmitted digitally from an RPM technology, such as a blood
              pressure cuff, to your healthcare provider in a safe and secure
              manner to maintain the confidentiality of your healthcare
              information;
              <br />
              3. You will not transmit or allow to be transmitted the health
              data of any individual other than your own;
              <br />
              4. You will not intentionally tamper with any RPM device used in
              connection with your RPM services;
              <br />
              5. Your physician or healthcare provider is not responsible for
              inaccuracies in the health data transmitted;
              <br />
              6. You have had the opportunity to ask any questions or concerns
              and have either had those questions/concerns answered to your full
              satisfaction, or you have declined to ask any questions or
              concerns you may have and hereby accept the risk of not having
              them answered;
              <br />
              7. You have been advised of the risks, benefits, and alternatives
              of RPM;
              <br />
              8. You have the right to withdraw this consent at any time;
              <br />
              9.{' '}
              <b>
                {' '}
                You are responsible for all applicable copay and deductible
                amounts
              </b>{' '}
              (including, if you are a Medicare beneficiary, the 20% copay for
              Part B services);{' '}
              <b>
                {' '}
                RPM services are NOT emergency services and your data WILL NOT
                BE MONITORED 24/7.
              </b>{' '}
              If you think you are experiencing a medical emergency,{' '}
              <u>
                {' '}
                <b> CALL 911 IMMEDIATELY.</b>
              </u>
              <br />
              <br />
              By signing below, you acknowledge that you have read, understand,
              and agree to all of the above and you consent to receive RPM
              services from your healthcare provider.
            </p>
          </div>
          {/* </div> */}

          <div
            style={{
              display: 'flex',
              flexDirection: window?.innerWidth > 600 ? 'row' : 'column',
              width: '80%',
              marginTop: '4vh',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                width: window?.innerWidth > 600 ? '50%' : '100%',
                margin: '20px',
              }}
            >
              <p
                style={{
                  color: Constants.lightGray,
                }}
              >
                Patient Signature
              </p>
              {/* <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}> */}
              <div
                style={{
                  width: window?.innerWidth > 600 ? '50%' : '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    borderBlock: '1px solid gray',
                    borderLeft: '1px solid gray',
                    borderRight: '1px solid gray',
                  }}
                >
                  <SignaturePad
                    canvasProps={{ className: GlobalStyles.sigPad }}
                    ref={(ref) => {
                      sigPad = ref
                    }}
                  />
                </div>

                <div
                  style={{
                    width: '100%',
                    marginTop: '10%',
                    marginBottom: '10%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                  }}
                >
                  <Button
                    onClick={clear}
                    className={GlobalStyles.button}
                    // disabled={this.state.loading}
                    style={{ width: 200, marginRight: '5%' }}
                    variant="primary"
                  >
                    Clear
                  </Button>

                  <Button
                    onClick={trim}
                    className={GlobalStyles.button}
                    // disabled={this.state.loading}
                    style={{ width: 200, marginLeft: '5%' }}
                    variant="primary"
                  >
                    Sign
                  </Button>
                </div>

                {/* {signImage && (
                  <>
                    <img
                      src={signImage}
                      style={{
                        height: '100%',
                        width: '90%',
                        objectFit: 'cover',
                      }}
                      alt="No Image"
                    />
                    <p
                      style={{
                        // textDecoration: 'underline',
                        color: 'gray',
                        // cursor: 'pointer',
                      }}
                    >
                      Saved Signature
                    </p>
                  </>
                )} */}
              </div>
            </div>

            <div
              style={{
                width: window?.innerWidth > 600 ? '50%' : '100%',
                margin: '20px',
              }}
            >
              <div
                style={{
                  width: window?.innerWidth > 600 ? '50%' : '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <img
                  src={doctorSignature}
                  style={{ height: '100%', width: '90%', objectFit: 'cover' }}
                  alt="No Image"
                />
                <p
                  style={{
                    fontSize: 16,
                    marginTop: '10%',
                    // color: values.primaryThemeDark,
                    fontFamily: 'Avenir-Regular',
                    textAlign: 'center',
                  }}
                >
                  {doctorName}
                  <br />
                  <br />
                  {moment().format('Do MMMM YYYY')}
                </p>
              </div>
            </div>
          </div>

          {/* <p
            style={{
              marginTop: 20,
              fontSize: 12,
              color: 'red',
              visibility: error ? 'visible' : 'hidden',
              textAlign: 'center',
            }}
          >
            {errorString}
          </p>

          <Button
            onClick={() => {
              onSubmitPressed()
              analytics.logEvent('Web_Signup_Complete')
            }}
            className={GlobalStyles.button}
            style={{ marginTop: '8vh', width: 200, alignSelf: 'center' }}
            variant="primary"
          >
            {'Submit'}
          </Button> */}
        </div>
      </div>
    </div>
  )
}
