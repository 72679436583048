/* eslint-disable */
import React, { Component, useState, useEffect } from 'react'
import moment from 'moment'
import {
  addPatient,
  stopTimer,
  getUnreadMessages,
  fetchPatientConditions,
  fetchPatientCarePlanData,
  fetchPatientCoreDate,
  toggleSidebar,
} from '../../../../redux/actions'
import { connect } from 'react-redux'
import GlobalStyles from '../../../styles/global.module.scss'
import PatientInfoStrip from '../../../../components/PatientInfoStrip'
import Styles from '../styles/profile.module.scss'
import Constants, { borderRadius } from '../../../../values'
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify'

import APICallers from '../../../../helpers/APIs'
import CareteamChat from '../../Careplan/CareteamChat'
import AppointmentList from '../../Careplan/AppointmentList'
import CommunicationsList from '../../Careplan/CommunicationsList'
import Screeners from '../../Careplan/InnerTabs/Screeners'
import EducationProgress from '../../Careplan/InnerTabs/EducationProgress'
import NotesModal from '../../../Appointments/NotesModal'
import TaskModal from '../../Careplan/TaskModal'
import * as MetaData from '../../../../metadata'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBookMedical,
  faUser,
  faCapsules,
  faDna,
  faViruses,
  faExclamationCircle,
  faCheckCircle,
  faClinicMedical,
  faCrosshairs,
  faPlusCircle,
  faHandHoldingMedical,
  faStethoscope,
  faBookReader,
  faNotesMedical,
  faClipboardList,
  faFileInvoice,
  faEdit,
  faWindowClose,
  faUserMd,
  faFileImport,
  faTrash,
  faDownload,
  faFileUpload,
  faCloudUploadAlt,
} from '@fortawesome/free-solid-svg-icons'
import {} from '@fortawesome/free-regular-svg-icons'
import Config from '../../../../config'
import ReactTooltip from 'react-tooltip'
import Modal from 'react-modal'
import Button from 'react-bootstrap/Button'
import styles from '../../../Orders/styles/axle.module.scss'
import Orders from '../../Careplan/InnerTabs/Orders'
import Faxes from '../InnerTabs/Faxes'
import UploadDocs from '../InnerTabs/UploadDocs'
import algoliasearch from 'algoliasearch/lite'
import {
  InstantSearch,
  SearchBox,
  Hits,
  RefinementList,
  InfiniteHits,
  connectStateResults,
  connectSearchBox,
  connectCurrentRefinements,
} from 'react-instantsearch-dom'
import Slider from 'react-rangeslider'
import { useSelector } from 'react-redux'
import Helpers from '../../../../helpers/Global'
import questionMetadata from '../../../../questionMetadata'
import Axios from 'axios'
import Handlebars from 'handlebars'
import NotesPdf from '../../../../helpers/PdfTemplates/NotesPdf.txt'
import VisitNotesPdf from '../../../../helpers/PdfTemplates/VisitNotesPdf.txt'
import PatientInsurance from '../../../PatientWeb/PatientAccount/PatientInsurance'
import MessageChat from '../../Careplan/MessageChat'
import PatientAccount from '../../../PatientWeb/PatientAccount/PatientAccount'
import RpmMonitoringLog from '../../Careplan/RpmMonitoringLog'
import PatientPCP from '../../../PatientWeb/PatientAccount/PatientPCP'

const axios = require('axios')

const searchClient = algoliasearch(
  Config.ALGOLIA_APP_ID,
  Config.ALGOLIA_API_KEY,
)

const MedicationForms = MetaData.medicationForms
const GoalDesc = MetaData.goalDesc
const condnClient = {
  search(requests) {
    if (requests[0].params.query === '') {
      return []
    }
    return searchClient.search(requests)
  },
}

const eventFire = (el, etype) => {
  if (el.fireEvent) {
    el.fireEvent('on' + etype)
  } else {
    var evObj = document.createEvent('Events')
    evObj.initEvent(etype, true, false)
    el.dispatchEvent(evObj)
  }
}

const ClearQuery = ({ refine }) => {
  const onClick = (ev) => {
    refine('')
    ev.stopPropagation()
  }
  return (
    <div onClick={onClick} style={{ display: 'none' }}>
      <ConnectedClearAllRefinements />
    </div>
  )
}

const ClearAllRefinements = ({ refine, items }) => {
  const onClick = () => refine(items)
  return (
    <button id="clearQuery" onClick={onClick}>
      ClearAll
    </button>
  )
}

const ClearQueryAndRefinements = connectSearchBox(ClearQuery)
const ConnectedClearAllRefinements =
connectCurrentRefinements(ClearAllRefinements)

export const RenderAddFamilyHistoryManually = ({
    onManualAddModalHide,
    currentAddModalData,
    getFamilyHistory,
    patientId
  }) => {
    const token = useSelector((state) => state.authReducer.token)
    const patient = (patientId ? {patient_id: patientId} : useSelector((state) => state.patientReducer.patient))
    const doctor_id = useSelector((state) => state.userReducer.adminId)
    const doctor_details = useSelector((state) => state.userReducer.mongoUser)
    const appointmentData = useSelector(
      (state) => state.appointmentReducer.appointmentData,
    )
    const showVideoModal = useSelector(
      (state) => state.genericReducer.showVideoModal,
    )
  
    const [familyHistoryData, setFamilyHistoryData] = useState(
      currentAddModalData?.relations || [],
    )
    const [conditionQuery, setConditionQuery] = useState('')
    const [condition, setCondition] = useState('')
    const [selectedFamily, setSelectedFamily] = useState('')
  
    const onClickSave = () => {
      if (!familyHistoryData?.length) {
        toast.error('Please add family history')
        return
      }
  
      let data = JSON.stringify({
        firebase_id: patient?.patient_id,
        relations: familyHistoryData,
      })
  
      let config = {
        method: 'put',
        url: `${Config.BACKEND_URL}screenings/family`,
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json',
        },
        data: data,
      }
  
      axios(config)
        .then((resp) => {
          onManualAddModalHide()
          getFamilyHistory(patient?.patient_id, token)
        })
        .catch((err) => {
          console.log('error when getting saving provider goals data', err)
        })
  
      onManualAddModalHide()
    }
  
    const Hit = (props) => {
      let { hit } = props
      const handleClick = () => {
        eventFire(document.getElementById('clearQuery'), 'click')
        setCondition({ id: hit?.code, name: hit?.display })
        setConditionQuery('')
      }
      return (
        <div
          style={{ width: '100%', cursor: 'pointer' }}
          onClick={(e) => {
            handleClick(e)
          }}
        >
          {hit?.code} : {hit?.display}
        </div>
      )
    }
  
    const getFamilyHistoryFormatted = () => {
      if (!familyHistoryData) return null
      let toReturn = []
      familyHistoryData.forEach((each) => {
        let conditions = []
        if (each.conditions)
          conditions = each.conditions.map((x, index) => (
            <span key={index.toString()} style={{ width: '80%' }}>
              {x}
              {index === each.conditions.length - 1 ? '' : ', '}
            </span>
          ))
        toReturn.push(
          <div
            key={each.relationship}
            className={Styles.contentRow}
            style={{ padding: '10px 10px' }}
          >
            <p className={Styles.contentRowKey}>{each.relationship}:</p>
            <p style={{ marginLeft: 5, overflowWrap: 'break-word' }}>
              {conditions}
            </p>
          </div>,
        )
      })
  
      return toReturn
    }
  
    const renderAddedFamilyHistory = () => {
      let entries = []
      entries = getFamilyHistoryFormatted()
      return (
        <div style={{ width: '100%', margin: 10, position: 'relative' }}>
          <h5 style={{ color: Constants.primaryTheme }}>Added Family History</h5>
          <div
            className={Styles.contentBackground}
            style={{ height: 240, overflowY: 'scroll' }}
          >
            {entries}
          </div>
        </div>
      )
    }
  
    const onPlusClick = () => {
      if (!selectedFamily?.value) {
        toast.error('Please select family member')
        return
      }
      if (!condition?.name) {
        toast.error('Please select condition')
        return
      }
      let tempFamilyData = [...familyHistoryData]
      let tempFamilyIndex = familyHistoryData.findIndex(
        (data) => data.relationship === selectedFamily.value,
      )
      if (tempFamilyIndex !== -1) {
        tempFamilyData[tempFamilyIndex].conditions.push(condition.name)
        // tempFamilyData[tempFamilyIndex].conditions = removeDup(tempFamilyData[tempFamilyIndex].conditions)
        setFamilyHistoryData(tempFamilyData)
      } else {
        setFamilyHistoryData((oldFam) => [
          ...oldFam,
          { relationship: selectedFamily.value, conditions: [condition.name] },
        ])
      }
      setSelectedFamily('')
      setCondition('')
    }
  
    return (
      <div style={{ height: '100%', width: '100%' }}>
        <div
          style={{
            height: '90%',
            width: '100%',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            overflow: 'auto',
          }}
        >
          <p
            className={Styles.closeModalBtn}
            onClick={() => onManualAddModalHide()}
          >
            X
          </p>
          <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
            Add Family History
          </h4>
  
          <div className={Styles.addManualRow} style={{ marginTop: 30 }}>
            <div className={Styles.addManualQuestion} style={{ width: '60%' }}>
              <InstantSearch
                indexName={'ICD_10_COMPLETE'}
                searchClient={condnClient}
                onSearchStateChange={({ query }) => setConditionQuery(query)}
              >
                <SearchBox
                  translations={{
                    placeholder: 'Search conditions',
                  }}
                />
                <ClearQueryAndRefinements />
                {conditionQuery.length !== 0 && (
                  <div
                    style={{
                      overflow: 'auto',
                      height: 150,
                      backgroundColor: '#F1F1F1',
                    }}
                  >
                    <RefinementList limit={5} />
                    <Hits hitComponent={(props) => Hit(props)} />
                  </div>
                )}
              </InstantSearch>
              <p className={Styles.addManualQuestion} style={{ width: '50%' }}>
                {condition?.id ? `${condition?.id} - ${condition?.name}` : ''}
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                width: '40%',
              }}
            >
              <Select
                options={[
                  { label: 'Grandfather', value: 'Grandfather' },
                  { label: 'Grandmother', value: 'Grandmother' },
                  { label: 'Mother', value: 'Mother' },
                  { label: 'Father', value: 'Father' },
                  { label: 'Son', value: 'Son' },
                  { label: 'Daughter', value: 'Daughter' },
                  { label: 'Sister', value: 'Sister' },
                  { label: 'Brother', value: 'Brother' },
                  { label: 'Aunt', value: 'Aunt' },
                  { label: 'Uncle', value: 'Uncle' },
                ]}
                className={Styles.addSelectInput}
                autoBlur={true}
                onChange={(val) => {
                  setSelectedFamily(val)
                }}
                placeholder={'Select family member'}
                value={selectedFamily}
                getOptionValue={(option) => option.label}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
              <div style={{ right: 5 }}>
                <FontAwesomeIcon
                  onClick={() => onPlusClick()}
                  icon={faPlusCircle}
                  style={{
                    color: Constants.primaryTheme,
                    fontSize: 35,
                    right: 10,
                    cursor: 'pointer',
                  }}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '70%',
              marginTop: 50,
            }}
          >
            {familyHistoryData.length !== 0 && renderAddedFamilyHistory()}
          </div>
        </div>
  
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={() => onClickSave()}
            className={GlobalStyles.button}
            // disabled={this.state.modalLoading}
            style={{ width: 200, position: 'absolute', bottom: 20 }}
            variant="primary"
          >
            Save
          </Button>
        </div>
      </div>
    )
  }