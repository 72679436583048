import React, { useState, useEffect } from 'react'
import GlobalStyles from '../styles/global.module.scss'
import Styles from './styles/Referrals.module.scss'
import Constants from '../../values.js'
import { connect, useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import ReactTooltip from 'react-tooltip'
import Select from 'react-select'
import moment from 'moment'
import Modal from 'react-modal'
import DateTimePicker from 'react-datetime-picker'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import algoliasearch from 'algoliasearch/lite'
import DatePicker from 'react-date-picker'
import DocPlaceHolder from '../../assets/images/docPlaceholder.png'
import Form from 'react-bootstrap/Form'
import metadata, * as Metadata from '../../metadata'
import {
  InstantSearch,
  SearchBox,
  Hits,
  RefinementList,
  InfiniteHits,
  connectStateResults,
  connectSearchBox,
  connectCurrentRefinements,
} from 'react-instantsearch-dom'
import Config from '../../config'
import PatientInfoStrip from '../../components/PatientInfoStrip'
import Header from '../../components/Header'
import { FaUserFriends } from 'react-icons/fa'
import {
  faClipboardList,
  faCapsules,
  faFlask,
  faUserFriends,
  faStethoscope,
  faPlusCircle,
  faTrash,
  faUserMd,
} from '@fortawesome/free-solid-svg-icons'
import {
  stopTimer,
  updateSideNavBar,
  addRpmPatient,
  addPatientLP,
  getUnreadMessages,
  resetTimer,
  startTimer,
  addVirgilE3,
  updateDoctorMongoUser,
  addMongoUser,
  addPatient,
  userLoggedIn,
  fetchPatientCoreDate,
  fetchPatientProgressData,
  fetchPatientWellnessScreening,
  fetchPatientCalculateProgressData,
  updatePatientData,
  addPatientList,
  setPatientCoreData,
  fetchPatientConditions,
  fetchPatientMedications,
  fetchPatientLabs,
} from '../../redux/actions'
import { Link } from 'react-router-dom'
import ORDER_IMG from '../../assets/images/order_receipt.png'
import styles from '../Admin/styles/admin.module.scss'
import hl7parser from 'hl7parser'

import Handlebars from 'handlebars'
import htmlModule1 from '../../helpers/PdfTemplates/ProviderReferral.txt'
import htmlModule2 from '../../helpers/PdfTemplates/ProviderReferralInfusion.txt'
import htmlModule3 from '../../helpers/PdfTemplates/ProviderReferralImaging.txt'
import Axios from 'axios'
import { data } from 'branch-sdk'
const axios = require('axios')

const PLACEHOLDER_IMAGE =
  'https://firebasestorage.googleapis.com/v0/b/aila-health-b469c.appspot.com/o/images%2Fplaceholder.png?alt=media&token=89bc348e-7ccd-4e35-a291-50c6e21a3b6a'
let windowHeight = window.innerHeight
let windowWidth = window.innerWidth

const searchClient = algoliasearch(
  Config.ALGOLIA_APP_ID,
  Config.ALGOLIA_API_KEY,
)

const condnClient = {
  search(requests) {
    if (requests[0].params.query === '') {
      return []
    }
    return searchClient.search(requests)
  },
}

const eventFire = (el, etype) => {
  if (el.fireEvent) {
    el.fireEvent('on' + etype)
  } else {
    var evObj = document.createEvent('Events')
    evObj.initEvent(etype, true, false)
    el.dispatchEvent(evObj)
  }
}

const ClearQuery = ({ refine }) => {
  const onClick = (ev) => {
    refine('')
    ev.stopPropagation()
  }
  return (
    <div onClick={onClick} style={{ display: 'none' }}>
      <ConnectedClearAllRefinements />
    </div>
  )
}

const ClearAllRefinements = ({ refine, items }) => {
  const onClick = () => refine(items)
  return (
    <button id="clearQuery" onClick={onClick}>
      ClearAll
    </button>
  )
}

const ClearQueryAndRefinements = connectSearchBox(ClearQuery)
const ConnectedClearAllRefinements =
  connectCurrentRefinements(ClearAllRefinements)

const customPatientAssignModalStyle = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 10,
  },
  content: {
    zIndex: 10,
    width: '75%',
    height: '60%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const PROVIDERS = [
  {
    label: 'Alison Shmerling',
    value: '4015227895',
    clinicianId: 'iazlt523',
    clinicianPwd: 'iazlt523',
  },
]

const UNITS = [
  { label: 'Bag', value: 'Bag' },
  { label: 'Bar', value: 'Bar' },
  { label: 'Capsules', value: 'Capsules' },
  { label: 'Canister', value: 'Canister' },
  { label: 'Cartridge', value: 'Cartridge' },
  { label: 'Pills', value: 'Pills' },
  { label: 'Strip', value: 'Strip' },
  { label: 'Swab', value: 'Swab' },
  { label: 'Syringe', value: 'Syringe' },
  { label: 'Tube', value: 'Tube' },
  { label: 'Vial', value: 'Vial' },
]

const PHARMACIES = [
  {
    label: 'Bannockburn Pharmacy[6798 PYLE RD, BETHESDA (301)644-2418]',
    value: '1',
  },
  {
    label:
      'Shollenberger Pharmacy[2002 S. MCDOWELL BLVD EXT, PETALUMA (707)984-5571]',
    value: '2',
  },
]

const customDrugModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 10,
  },
  content: {
    zIndex: 10,
    width: '50%',
    height: '75%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const customModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 10,
  },
  content: {
    zIndex: 10,
    width: 800,
    height: 800,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const customChangeModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 10,
  },
  content: {
    zIndex: 10,
    width: '80%',
    height: '95%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const selectTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#20A89233',
    primary: '#20A892',
  },
})

const RpmOrders = (props) => {
  const patientCarePlanData = useSelector(
    (state) => state.patientReducer.patientCarePlanData,
  )
  const patientConditionsData = useSelector(
    (state) => state.patientReducer.patientConditionsData,
  )

  //RPM ITEMS
  const [items, setItems] = useState([])

  //RPM ORDERING VALUES
  const [shipmentProvider, setShipmentProvider] = useState('')
  const [rpmEligible, setRpmEligible] = useState(false)
  const [orderNote, setOrderNote] = useState('')
  const [selectedItems, setSelectedItems] = useState(null)
  const [selectedProgram, setSelectedProgram] = useState(null)
  const [loading, setLoading] = useState(false)
  const [referralType, setReferralType] = useState(null)
  const [speciality, setSpeciality] = useState(null)
  const [extraSpecialty, setExtraSpecialty] = useState('')
  const [labLocation, setLabLocation] = useState(null)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [faxNumber, setFaxNumber] = useState('')
  const [priority, setPriority] = useState(null)
  const [dateInfusion, setDateInfusion] = useState(null)
  const [orderType, setOrderType] = useState(null)
  const [medication, setMedication] = useState([])
  const [medicationQuery, setMedicationQuery] = useState('')
  const [dosage, setDosage] = useState('')
  const [units, setUnits] = useState('')
  const [frequency, setFrequency] = useState('')
  const [otherMeds, setOtherMeds] = useState('')
  const [accessType, setAccessType] = useState(null)
  const [reasonIcd, setReasonIcd] = useState('')
  const [notes, setNotes] = useState('')
  const [includePChart, setIncludePChart] = useState(false)
  const [patientCareTeam, setPatientCareTeam] = useState([])
  const [selectedClinician, setSelectedClinician] = useState(null)

  const [showNext, setShowNext] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [pdfData, setPdfData] = useState('')
  const [icdCodes, setIcdCodes] = useState([])
  const [selectedNote, setSelectedNote] = useState(null)
  const [providerConds, setProviderConds] = useState([])
  const [allProviderList, setAllProviderList] = useState([])
  const [monitoringProvider, setMonitoringProvider] = useState(null)

  useEffect(() => {
    // setLoading(true)
    getAllProviderData()
    props?.patientId && getPatientCareTeam()
    props?.patientId &&getProviderConditions()
  }, [props?.patientId])

  const getAllProviderData = () => {
    axios({
      method: 'get',
      url: Config.BACKEND_URL + 'providers',
      headers: {
        x_firebase_id: props.providerId,
        Authorization: 'JWT ' + props.token,
        getallproviders: true,
      },
    })
      .then(({ data }) => {
        data && setAllProviderList(data)
        let monitor =
          data.filter((provider) => provider.user_type === 'Monitor')[0] || null
        setMonitoringProvider(monitor)
        console.log(data, monitor)
      })
      .catch((err) => {
        console.log('err while loading provider data: ', err)
      })
  }

  const getPatientCareTeam = () => {
    // get the care team members
    const url = `${Config.BACKEND_URL}patient/careteam/internal`
    const doctorList = []

    axios({
      method: 'get',
      headers: {
        'Access-Control-Allow-Origin': '*',
        Accept: '*/*',
        Authorization: `JWT ${props.token}`,
        x_firebase_id: props?.patientId,
        assigned: true
      },
      url,
    })
      .then(async ({ data }) => {
        if (data) {
          let providerOptionList = []
          let allProviderData = 
          data
          .filter(doc => (doc.user_type !== 'Administrator'))
          .reduce((data, doc) => {
            data[doc.user_type || 'Other'] = data[doc.user_type || 'Other'] || []
            data[doc.user_type || 'Other'].push({
              label: doc?.first_name + ' ' + doc?.last_name + (doc?.speciality ? ` (${doc?.speciality})` : ''),
              value: doc?.firebase_id,
            })
            return data
          }, Object.create(null))
          Object.entries(allProviderData).forEach(([userType, doctorArray]) => {
            providerOptionList.push({
              label: userType,
              options: doctorArray,
            })
          })
          setPatientCareTeam(providerOptionList)
        }
      })
      .catch((err) => {
        console.log('error when getting the care team for patient', err)
      })
  }

  const getProviderConditions = async () => {
    try {
      const getCondition = async () => {
        let url =
          Config.BACKEND_URL + 'fhir/Condition?patient=' + props?.patientId
        return axios({
          method: 'get',
          url: url,
          headers: {
            x_firebase_id: props?.patientId,
            Authorization: 'JWT ' + props.token,
          },
        })
      }
      let response = await getCondition()
      if (response?.data) {
        //if there is something in data, then the particle records for the patient has been pulled at least once
        let conditions = response.data || []
        setProviderConds(conditions)
      } else {
        setProviderConds([])
      }
    } catch (err) {
      console.log('error when getting particle conditions data', err)
      // toast.error('Something went wrong when getting external conditions')
    }
  }

  // const getItems = () => {
  //   let config = {
  //     method: 'get',
  //     url: Config.BACKEND_URL + `rpm/item`,

  //     headers: {
  //       Authorization: `JWT ${props.token}`,
  //       x_firebase_id: props?.providerId,
  //     },
  //   }
  //   axios(config)
  //     .then(({ data }) => {
  //       let temp = []
  //       data &&
  //         data.items.forEach((item) => {
  //           temp.push({
  //             label: item.name,
  //             name: item.name,
  //             isChecked: false,
  //             value: item,
  //             count: 1,
  //           })
  //         })
  //       setItems(temp)
  //       console.log('IMPILO ITEMS : ', temp)

  //       setLoading(false)
  //     })
  //     .catch((err) => {
  //       console.log('Error getting Items from Impilo', err)
  //       toast.error('Could not get Items')
  //     })
  // }
  const addMedication = (hit) => {
    let temp = [{ id: hit.AILA_ID, name: hit.DrugName }]
    setMedication(temp)
  }

  const onSendPressed = () => {
    console.log('sending fax')
    setShowModal(false)

    let result = {
      patient_id: props?.patientId,
      sSenderEmail: props?.mongoUser?.email,
      sToFaxNumber: faxNumber,
      sFileName_1: 'PatientReferral.pdf',
      htmlContent: pdfData,
    }

    let config = {
      method: 'post',
      url: Config.CHANGE_BACKEND_URL + `srfax/referral-outbox`,

      headers: {
        Authorization: `JWT ${props.token}`,
        x_firebase_id: props?.providerId,
      },
      data: result,
    }
    axios(config)
      .then(({ data }) => {
        console.log('SRFAX SENT : ', data)
        if (data.Status !== 'Failed') {
          toast.success('Fax queued successfully')

          setShipmentProvider('')
          setOrderNote('')
          setSelectedItems(null)

          setReferralType(null)
          setSpeciality(null)
          setPhoneNumber('')
          setFaxNumber('')
          setDateInfusion(null)
          setMedication([])
          setMedicationQuery('')
          setDosage('')
          setFrequency('')
          setOtherMeds('')
          setAccessType(null)
          setReasonIcd('')
          setNotes('')
          setShowNext(false)
          setPdfData('')
          setIcdCodes([])
          setLoading(false)
        } else {
          toast.warning(`Could not send fax : ${data.Result}`)
        }
      })
      .catch((err) => {
        console.log('Error getting Faxes for the Patient ', err)
        toast.error('Could not send Fax')
      })
  }

  const onSubmitPressed = () => {
    let data
    let noteData
    let rpmItems = []

    // if (!shipmentProvider || !selectedItems || !props?.patientId) {
    //   toast.error('Please fill all the fields')
    //   return
    // }
    setLoading(true)

    if (selectedNote) {
      let icd_10_data = ''
      let cpt_data = ''
      noteData = {
        selected_note: 'visible',
        chief_concern: selectedNote?.careplan?.chief_concern,
        present_illness: selectedNote?.careplan?.present_illness,
        physical_exam: selectedNote?.careplan?.physical_exam,
      }
      if (selectedNote?.careplan?.icd_10.length) {
        let tempArray = selectedNote?.careplan?.icd_10 || []
        tempArray.forEach((icd, index) => {
          icd_10_data = icd_10_data.concat(
            index + 1,
            '. ',
            icd.icd10_code,
            ' : ',
            icd.title,
            ' : ',
            selectedNote?.careplan?.notes[index],
            ' : ',
            selectedNote?.careplan?.text[index],
            index !== tempArray.length && '\n',
            // '\n',
          )
        })
        noteData.icd_10 = icd_10_data
      }
      if (selectedNote?.careplan?.cpt.length) {
        let tempArray = selectedNote?.careplan?.cpt || []
        tempArray.forEach(function (cpt, index) {
          cpt_data = cpt_data.concat(
            index + 1,
            '. ',
            cpt.cpt_code,
            ' : ',
            cpt.title,
            index !== tempArray.length && '\n',
            // '\n',
          )
        })
      }
      noteData.cpt = cpt_data
    }
    selectedItems.forEach((item) => {
      rpmItems.push(
        {
          sku: item.value,
          line_name: item.label,
          quantity: Number(item.count),
        },

        //     {
        //     item_id: item?.value?.id,
        //     count: Number(item.count),
        //   }
      )
    })

    data = {
      //RPM ORDER DETAILS
      // shipment_provider: shipmentProvider,
      // note: orderNote,
      lines: rpmItems,

      // instructions: notes,
      // icd_codes: icdCodes[0]?.code,
      // reason: reasonIcd,

      //PATIENT INFORMATION
      patient_id: props.patient?.firebase_id,
      //   created_at: moment(new Date()).format('MM/DD/YYYY'),
      //   patient_name: props.patient?.label,
      //   patient_dob: moment(props.patient?.dob).format('MM/DD/YYYY'),
      //   patient_address: props.patient?.streetAddress,
      //   patient_phone: props.patient?.phoneNumber,
      //   patient_email: props.patient?.email,
      // insurance_provider: props.patient?.insurance?.insurance_provider,
      // insurance_plan: props.patient?.insurance?.plan_type,
      // insurance_member: props.patient?.insurance?.member_name,
      // insurance_subscriber: props.patient?.insurance?.subscriber_id,
      // insurance_group: props.patient?.insurance?.group_number,

      //PROVIDER INFORMATION
      provider_id: props.mongoUser?.firebase_id,
      provider_signature: props.mongoUser?.sign_image,
      monitor_id:
        selectedClinician?.firebase_id || props.mongoUser?.firebase_id,
      //   provider_name:
      //     props.mongoUser?.first_name + ' ' + props.mongoUser?.last_name,
      //   provider_specialty: props.mongoUser?.speciality,
    }

    console.log({ ...data, ...{ note_data: noteData } })

    let config = {
      method: 'post',
      url: Config.BACKEND_URL + `rpm/order`,

      headers: {
        Authorization: `JWT ${props.token}`,
        x_firebase_id: props?.providerId,
      },
      data: data,
    }
    axios(config)
      .then(({ data }) => {
        console.log('ORDER PLACED : ', data)

        toast.success('RPM Order queued successfully')

        setShipmentProvider('')
        setOrderNote('')
        setSelectedItems(null)
        setSelectedClinician(null)

        setReferralType(null)
        setSpeciality(null)
        setPhoneNumber('')
        setFaxNumber('')
        setDateInfusion(null)
        setMedication([])
        setMedicationQuery('')
        setDosage('')
        setFrequency('')
        setOtherMeds('')
        setAccessType(null)
        setReasonIcd('')
        setNotes('')
        setShowNext(false)
        setPdfData('')
        setIcdCodes([])
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)

        console.log('Error ordering RPM for the Patient ', err)
        toast.error('Could not place RPM Order, please try after sometime')
      })
  }

  const handleHitClick = (hit) => {
    if (handleChecked(hit.DrugName)) {
      let arr = medication.filter((data) => data.DrugName !== hit.DrugName)
      setMedication(arr)
    } else {
      setMedication([
        // ...medication,
        { DrugName: hit.DrugName, AILA_ID: hit.AILA_ID },
      ])
    }
  }

  // to handle the list of selected conditions from algolia
  const handleChecked = (DrugName) => {
    return medication.some((data) => data.DrugName === DrugName)
  }

  const Hit = (props) => {
    let { hit } = props
    let checked = handleChecked(hit.DrugName)
    return (
      <div
        style={{ width: '100%', cursor: 'pointer' }}
        onClick={() => {
          handleHitClick(hit)
        }}
      >
        {hit.AILA_ID} : {hit.DrugName}
      </div>
    )
  }

  // const Hit = (props, type) => {
  //   let { hit } = props
  //   // let checked = handleChecked(q, hit.symptom)
  //   const handleClick = () => addMedication(hit)

  //   return (
  //     <>
  //       <button
  //         onClick={(e) => handleClick(e)}
  //         style={{ border: 'none', outline: 'none', display: 'block' }}
  //       >
  //         {hit.code}: {hit.display}
  //       </button>
  //       <hr />
  //     </>
  //   )
  // }

  const addIcdCode = (hit) => {
    // let temp = [...icdCodes, { code: hit.code, display: hit.display }]
    let temp = [{ code: hit.code, display: hit.display }]

    setIcdCodes(temp)
  }

  const HitICD = (props, type) => {
    let { hit } = props
    // let checked = handleChecked(q, hit.symptom)
    const handleClick = () => addIcdCode(hit)

    return (
      <>
        <button
          onClick={(e) => handleClick(e)}
          style={{ border: 'none', outline: 'none', display: 'block' }}
        >
          {hit.code}: {hit.display}
        </button>
        <hr />
      </>
    )
  }

  const renderInfusion = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          margin: '5% 1%',
          borderBottomWidth: 1,
          borderBottomColor: Constants.primaryTheme,
          borderBottomStyle: 'solid',
        }}
      >
        <div className={Styles.inputDiv}>
          <p className={Styles.inputQuestion}>
            Date of Last Infusion/ Injectable
          </p>
          <div style={{ width: '30%', margin: '0px 20px' }}>
            <DatePicker
              onChange={(date) => {
                setDateInfusion(date)
              }}
              value={dateInfusion}
            />
          </div>

          <p className={Styles.inputQuestion}>Order Type</p>
          <div style={{ width: '30%', margin: '0px 20px' }}>
            <Select
              options={[
                { label: 'New', value: 'New' },
                { label: 'Renewal', value: 'Renewal' },
              ]}
              value={orderType}
              autoBlur={true}
              onChange={(val) => setOrderType(val)}
              theme={(theme) => selectTheme(theme)}
            />
          </div>
        </div>

        <div
          className={Styles.inputDiv}

          //   style={{
          //     display: 'flex',
          //     flexDirection: 'row',
          //     marginTop: 50,
          //     width: '100%',
          //   }}
        >
          <p
            className={Styles.inputQuestion}

            // style={{
            //   alignSelf: 'center',
            //   color: Constants.primaryTheme,
            //   fontWeight: 'bold',
            // }}
          >
            Medication Order
          </p>
          <div style={{ width: '30%', margin: '0px 20px' }}>
            <InstantSearch
              searchClient={condnClient}
              indexName={'MEDICATIONS'}
              onSearchStateChange={({ query }) => setMedicationQuery(query)}
            >
              <SearchBox />
              {medicationQuery.length !== 0 && (
                <div
                  style={{
                    overflow: 'auto',
                    height: 150,
                    backgroundColor: '#F1F1F1',
                  }}
                >
                  <RefinementList limit={5} />
                  <Hits hitComponent={(props) => Hit(props)} />
                </div>
              )}
            </InstantSearch>

            {medication.length !== 0 && (
              <div className={Styles.searchAdded}>
                {/* Added: */}
                {/* <br />{' '} */}
                {medication.map((data, index) => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: 10,
                      }}
                    >
                      <p>
                        {/* {index + 1}. */}
                        {data.DrugName + ' '}
                      </p>
                      <FontAwesomeIcon
                        icon={faTrash}
                        className={Styles.deleteIcon}
                        onClick={() => handleHitClick(data)}
                      />
                      <br />
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        </div>

        <div className={Styles.inputDiv}>
          <p className={Styles.inputQuestion}>Dosing</p>
          <div style={{ width: '20%', margin: '0px 20px' }}>
            <input
              placeholder={'Enter Dosage'}
              className={Styles.textInput}
              type="text"
              value={dosage}
              onChange={(e) => {
                let text = e.target.value
                setDosage(text)
              }}
            />
          </div>
          <p className={Styles.inputQuestion}>Units</p>
          <div style={{ width: '20%', margin: '0px 20px' }}>
            <input
              placeholder={'Enter Units'}
              className={Styles.textInput}
              type="text"
              value={units}
              onChange={(e) => {
                let text = e.target.value
                setUnits(text)
              }}
            />
          </div>
          <p className={Styles.inputQuestion}>Frequency</p>
          <div style={{ width: '20%', margin: '0px 20px' }}>
            <input
              placeholder={'Enter Frequency'}
              className={Styles.textInput}
              type="text"
              value={frequency}
              onChange={(e) => {
                let text = e.target.value
                setFrequency(text)
              }}
            />
          </div>
        </div>

        <div className={Styles.inputDiv}>
          <p className={Styles.inputQuestion}>If other, specify</p>
          <div style={{ width: '30%', margin: '0px 20px' }}>
            <input
              placeholder={'Enter Dosage'}
              className={Styles.textInput}
              type="text"
              value={otherMeds}
              onChange={(e) => {
                let text = e.target.value
                setOtherMeds(text)
              }}
            />
          </div>
        </div>

        <div className={Styles.inputDiv}>
          <p className={Styles.inputQuestion}>Type of Access</p>
          <div style={{ width: '30%', margin: '0px 20px' }}>
            <Select
              options={[
                { label: 'Peripheral', value: 'Peripheral' },
                { label: 'PICC', value: 'PICC' },
                { label: 'Port', value: 'Port' },
                { label: 'Midline', value: 'Midline' },
                { label: 'Subcutaneous', value: 'Subcutaneous' },
                { label: 'IM', value: 'IM' },
              ]}
              value={accessType}
              autoBlur={true}
              onChange={(val) => setAccessType(val)}
              theme={(theme) => selectTheme(theme)}
            />
          </div>
        </div>
        {/* <div className={Styles.horizontalLine} style={{ marginTop: '1vh' }} />

        {renderIcdCodes()}

        <div className={Styles.horizontalLine} /> */}
      </div>
    )
  }

  const renderIcdCodes = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          margin: '5% 1%',
          // borderBottomWidth: 1,
          // borderBottomColor: Constants.primaryTheme,
          // borderBottomStyle: 'solid',
          // borderTopWidth: 1,
          // borderTopColor: Constants.primaryTheme,
          // borderTopStyle: 'solid',
        }}
      >
        {/* <div    style={{ display: 'flex', flexDirection: 'row', padding: '30px 20px' }}   > */}

        <div className={Styles.inputDiv}>
          <p className={Styles.inputQuestion}>
            {/* <p
          style={{
            color: Constants.primaryTheme,
            fontWeight: 'bold',
            width: 140,
          }}
        > */}
            {'ICD-10 Code'}
          </p>
          <div style={{ width: '80%' }}>
            <div>
              <InstantSearch
                searchClient={searchClient}
                indexName={'ICD_10_COMPLETE'}
              >
                <div style={{ position: 'relative' }}>
                  <SearchBox />
                  <div
                    style={{
                      overflow: 'auto',
                      height: 150,
                      backgroundColor: '#F1F1F1',
                      paddingRight: 30,
                    }}
                  >
                    <RefinementList limit={5} />
                    <Hits hitComponent={(props) => HitICD(props)} />
                  </div>
                </div>
              </InstantSearch>
            </div>

            {icdCodes.length !== 0 && (
              <div>
                <p style={{ marginTop: 30, color: Constants.primaryTheme }}>
                  Selected ICD-10 Code
                </p>
                <div style={{ height: 160, overflow: 'auto', width: '50%' }}>
                  {icdCodes.map((icd, index) => (
                    <div
                      style={{
                        justifyContent: 'space-between',
                        borderTop: '1px solid #D3D3D3',
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '4px 10px',
                      }}
                    >
                      <p>
                        {index + 1}. {icd.code}: {icd.display}
                      </p>
                      <FontAwesomeIcon
                        icon={faTrash}
                        className={Styles.deleteIcon}
                        onClick={() => {
                          let arr = icdCodes.filter(
                            (data) => data.code !== icd.code,
                          )
                          setIcdCodes(arr)
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className={Styles.inputDiv}>
          <p className={Styles.inputQuestion}>Reason</p>
          <div style={{ width: '60%', margin: '0px 20px' }}>
            <input
              placeholder={'Enter Reason'}
              className={Styles.textInput}
              type="text"
              value={reasonIcd}
              onChange={(e) => {
                let text = e.target.value
                setReasonIcd(text)
              }}
            />
          </div>
        </div>
      </div>
    )
  }

  const renderInstruction = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          margin: '5% 1%',
          borderTopWidth: 1,
          borderTopColor: Constants.primaryTheme,
          borderTopStyle: 'solid',
        }}
      >
        <div className={Styles.inputDiv}>
          <p className={Styles.inputQuestion}>Instructions/Notes</p>
          <div style={{ width: '60%', margin: '0px 20px' }}>
            <input
              placeholder={'Enter Instructions'}
              className={Styles.textInput}
              type="text"
              value={notes}
              onChange={(e) => {
                let text = e.target.value
                setNotes(text)
              }}
            />
          </div>
        </div>

        {/* <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            // marginTop: '8vh',
            margin: '2%',
            justifyContent: 'flex-start',
          }}
        >
          <label className={Styles.checkContainer}>
            <p
              style={{
                color: Constants.darkGray,
                width: '100%',
                overflowWrap: 'break-word',
                display: 'block',
              }}
            >
              Include Patient Chart Information
            </p>
            <input
              type="checkbox"
              checked={includePChart}
              onClick={() => {
                setIncludePChart(!includePChart)
              }}
            />
            <span className={Styles.checkmark}></span>
          </label>
        </div> */}
      </div>
    )
  }

  const renderCarePlanNotes = () => {
    // let { careplan } = this.state;
    let carePlanData = patientCarePlanData || []
    return (
      <>
        {carePlanData?.map((carePlanData) => {
          return (
            // latest notes from the provider
            <div
              className={Styles.contentRow}
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <div
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Form.Check
                  type={'radio'}
                  // label={type}
                  value={carePlanData}
                  onClick={(val) => {
                    console.log('radio')
                    if (carePlanData === selectedNote) {
                      setSelectedNote(null)
                    } else {
                      setSelectedNote(carePlanData)
                    }
                    // this.handleRadioInput(val.target.value)
                  }}
                  style={{
                    color: 'rgb(23,61,68)',
                    fontFamily: 'Avenir',
                    marginLeft: '5%',
                  }}
                  checked={selectedNote === carePlanData}
                  classes={Styles.radioButton}
                />
                <img
                  src={
                    carePlanData?.doctor?.image_url
                      ? carePlanData?.doctor?.image_url
                      : DocPlaceHolder
                  }
                  height={50}
                  width={50}
                  style={{ borderRadius: '50%', margin: 10 }}
                />
                <h6 style={{ color: Constants.primaryTheme }}>
                  {carePlanData?.doctor?.first_name}{' '}
                  {carePlanData?.doctor?.last_name} {', '}
                  {carePlanData?.careplan?.appointment_date
                    ? moment(carePlanData?.careplan?.appointment_date).format(
                        'MMM Do YYYY',
                      )
                    : moment(
                        carePlanData?.careplan?.created_at,
                        'YYYY-MM-DD',
                      ).format('MMM Do YYYY')}
                </h6>
              </div>
              <div style={{ width: '100%' }} className={Styles.carePlanRow}>
                <div style={{ width: '80%' }}>
                  <h6 style={{ color: Constants.primaryTheme }}></h6>
                  {carePlanData?.careplan?.text &&
                    carePlanData?.careplan?.text?.map((text, index) => (
                      <p style={{ marginLeft: '10%' }}>
                        <b>
                          {carePlanData?.careplan?.icd_10?.[
                            index
                          ]?.title?.replace(/"/g, '')}
                        </b>{' '}
                        - {text}
                      </p>
                    ))}
                </div>
              </div>
            </div>
          )
        })}
      </>
    )
  }

  const renderConditions = () => {
    function renderPatientConditions() {
      let conditions = patientConditionsData || []
      // filter out user entered data
      conditions = conditions.filter(
        (userCondn) =>
          !providerConds?.some(
            (prodCondn) =>
              prodCondn?.code?.coding?.[0]?.display === userCondn?.symptom,
          ),
      )
      let newConditions = conditions?.concat(providerConds)
      let conditionsElement =
        newConditions &&
        newConditions.map((condn, index) => (
          <div
            className={Styles.contentRow}
            key={index}
            style={{ justifyContent: 'space-between' }}
          >
            <p
              key={index.toString()}
              style={{ textAlign: 'left', padding: '2px 10px' }}
            >
              {condn.symptom ||
                condn?.code?.coding?.[0]?.display?.replace(/"/g, '')}
            </p>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {condn?.code?.coding?.[0]?.display && !condn?.userEntered && (
                <div style={{ marginRight: 10 }}>
                  <FontAwesomeIcon
                    icon={faUserMd}
                    style={{ color: Constants.primaryTheme, fontSize: 20 }}
                  />
                </div>
              )}
            </div>
          </div>
        ))
      if (newConditions.length) {
        return (
          <div style={{ width: '100%', margin: 10, position: 'relative' }}>
            {/* <label class={Styles.checkContainer}>
              Conditions
              <input
                type="checkbox"
                checked={sendConditions}
                onClick={() => {
                  setSendConditions(!sendConditions)
                }}
              />
              <span class={Styles.checkmark}></span>
            </label> */}

            <div
              className={Styles.contentBackground}
              style={{ height: 240, overflowY: 'scroll' }}
            >
              {conditionsElement}
            </div>
          </div>
        )
      } else {
        return <p>No Conditions Found</p>
      }
    }
    return (
      <div className={Styles.tabContentWrapper}>
        <div
          style={{ display: 'flex', flexDirection: 'row', minWidth: '100%' }}
        >
          {renderPatientConditions()}
        </div>
      </div>
    )
  }

  const renderContent = () => {
    if (!showNext) {
      return (
        <>
          {/* <div
            className={Styles.inputDiv}          
          >
            <p
              className={Styles.inputQuestion}            
            >
              Shipment Provider
            </p>
            <div style={{ width: '30%', margin: '0px 20px' }}>
              <input
                placeholder={'Shipment Provider'}
                className={Styles.textInput}
                type="text"
                value={shipmentProvider}
                onChange={(e) => {
                  let text = e.target.value
                  setShipmentProvider(text)
                }}
              />
            </div>
          </div> */}

          {/* <div className={Styles.inputDiv}>
            <p className={Styles.inputQuestion}>Eligible for RPM?</p>
            <div
              style={{
                width: '80%',
                margin: '0px 20px',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <label class={Styles.checkContainer} style={{ width: '30%' }}>
                Yes
                <input
                  type="checkbox"
                  checked={rpmEligible}
                  onClick={() => {
                    setRpmEligible(!rpmEligible)
                  }}
                />
                <span class={Styles.checkmark}></span>
              </label>
              <label class={Styles.checkContainer} style={{ width: '30%' }}>
                No
                <input
                  type="checkbox"
                  checked={!rpmEligible}
                  onClick={() => {
                    setRpmEligible(!rpmEligible)
                  }}
                />
                <span class={Styles.checkmark}></span>
              </label>

              <div style={{ width: '30%', textAlign: 'center' }}>
                <p
                  style={{
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 2,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textDecoration: 'underline',
                    color: Constants.primaryTheme,
                    cursor: 'pointer',
                  }}
                  data-tip
                  data-for={'access_eligibility_guide'}
                >
                  Access Eligibility Guide
                </p>
              </div>

              <ReactTooltip
                id={'access_eligibility_guide'}
                multiline={true}
                place={'top'}
                effect={'solid'}
                clickable={true}
                className={Styles.tooltip}
              >
                To be eligible, a patient must:
                <br />
                <ul>
                  <li>
                    Be at least 65 years of age (because of Medicare
                    Reimbursement)
                  </li>
                  <li>Have two or more chronic conditions.</li>
                  <li>
                    Have conditions that are expected to last at least 12 months
                    or until death of the patient.
                  </li>
                  <li>
                    Have conditions that place the patient at significant risk
                    of death, acute exacerbation (i.e. worsening of condition),
                    decompensation (i.e. organ failure), or functional decline.
                  </li>
                  <li>
                    Agree to Aila Health having access to their health data
                    provided in the application
                  </li>
                  <li>
                    Agree to use the Aila Health platform as close to daily as
                    possible
                  </li>
                  <li>
                    Agree to speaking with HCP every 30 days about their usage
                    of Aila Health platform, symptoms/outcomes, etc.
                  </li>
                </ul>
              </ReactTooltip>
            </div>
          </div> */}

          <div className={Styles.inputDiv}>
            <p className={Styles.inputQuestion}>Referring Physician</p>
            <div style={{ width: '60%', margin: '0px 20px' }}>
              <input
                placeholder={'Physician'}
                className={Styles.textInput}
                type="text"
                value={
                  props.mongoUser?.first_name + ' ' + props.mongoUser?.last_name
                }
                disabled={true}
              />
            </div>
          </div>

          <div className={Styles.inputDiv}>
            <p className={Styles.inputQuestion}>Assigned Monitor</p>
            <div
              style={{
                width: '25%',
                margin: '0px 20px',
              }}
            >
              <Select
                placeholder={'Select Monitor'}
                inputId="doctor_name"
                options={patientCareTeam}
                getOptionValue={(option) => option.label}
                value={selectedClinician}
                isSearchable
                autoBlur
                onChange={(val) => {
                  console.log(val)
                  setSelectedClinician(val)
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </div>

          {/* <div className={Styles.inputDiv}>
            <p className={Styles.inputQuestion}>Assigned Monitor</p>
            <div style={{ width: '60%', margin: '0px 20px' }}>
              <input
                placeholder={'Monitor'}
                className={Styles.textInput}
                type="text"
                value={
                  monitoringProvider
                    ? monitoringProvider?.first_name +
                      ' ' +
                      monitoringProvider?.last_name
                    : 'Monitor'
                }
                disabled={true}
              />
            </div>
          </div> */}

          {/* <div className={Styles.inputDiv}>
            <p className={Styles.inputQuestion}>
              Explained the required information about RPM*
            </p>
            <div
              style={{
                width: '80%',
                margin: '0px 20px',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <label class={Styles.checkContainer} style={{ width: '30%' }}>
                Yes
                <input
                  type="checkbox"
                  checked={rpmEligible}
                  onClick={() => {
                    setRpmEligible(!rpmEligible)
                  }}
                />
                <span class={Styles.checkmark}></span>
              </label>
              <label class={Styles.checkContainer} style={{ width: '30%' }}>
                No
                <input
                  type="checkbox"
                  checked={!rpmEligible}
                  onClick={() => {
                    setRpmEligible(!rpmEligible)
                  }}
                />
                <span class={Styles.checkmark}></span>
              </label>
              <div style={{ width: '30%', textAlign: 'center' }}>
                <p
                  style={{
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 2,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textDecoration: 'underline',
                    color: Constants.primaryTheme,
                    cursor: 'pointer',
                  }}
                  data-tip
                  data-for={'access_rpm_discussion_guide'}
                >
                  Access RPM Discussion Guide
                </p>
              </div>

              <ReactTooltip
                id={'access_rpm_discussion_guide'}
                multiline={true}
                place={'top'}
                effect={'solid'}
                clickable={true}
                className={Styles.tooltip}
              >
                RPM Discussion Guide
                <br />
                The language below is intended to be a guide for conversations
                seeking verbal consent for RPM Enrollment.
                <br />
                Our clinic provides Remote Patient Monitoring Services. Your
                dedicated care team will review your records and may contact you
                if needed. They may also connect with you about how they are
                working for you and your health. This also means that the care
                team in the practice will share information about your health
                with me to make sure we can talk about everything when we meet
                again.
                <br />
                Some other benefits include:
                <br />
                <ul>
                  <li>
                    Be at least 65 years of age (because of Medicare
                    Reimbursement) This provides preventative care keeps
                    patients healthy and out of the hospital.
                  </li>
                  <li>
                    A 24/7/365 nurse line reduces the likelihood that a patient
                    would go to the emergency room for a non-urgent issue.
                  </li>
                  <li>
                    The 24/7 care management access allows patients to ask
                    questions directly to qualified health care professionals,
                    without having to go in for an office visit.
                  </li>
                  <li>
                    The care team can help assist with finding less-expensive
                    prescription options.
                  </li>
                </ul>
                <br />
                You may stop the remote monitoring services at any time
                (effective the end of calendar month)
                <br />
                <ul>
                  <li>
                    Do you have any questions about the remote monitoring
                    services?
                  </li>
                  <li>
                    Do you agree to receive the remote monitoring services?
                  </li>
                  <li>
                    Do you understand that a monthly fee could apply to the
                    remote monitoring services?
                  </li>
                  <li>
                    Only 1 practitioner can furnish and bill remote monitoring
                    services during a calendar month.
                  </li>

                  <li>
                    Are you enrolled in any other remote monitoring programs?
                  </li>
                  <li>How do you prefer to be contacted?</li>
                </ul>
                Document in patient’s medical record that you explained the
                required information and whether they accepted or declined
                services.
              </ReactTooltip>
            </div>
          </div> */}

          {/* <div className={Styles.inputDiv}>
            <p className={Styles.inputQuestion}>
              Received Patient Consent for RPM Services*
            </p>
            <div
              style={{
                width: '60%',
                margin: '0px 20px',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <label class={Styles.checkContainer} style={{ width: '30%' }}>
                Yes
                <input
                  type="checkbox"
                  checked={rpmEligible}
                  onClick={() => {
                    setRpmEligible(!rpmEligible)
                  }}
                />
                <span class={Styles.checkmark}></span>
              </label>
              <label class={Styles.checkContainer} style={{ width: '30%' }}>
                No
                <input
                  type="checkbox"
                  checked={!rpmEligible}
                  onClick={() => {
                    setRpmEligible(!rpmEligible)
                  }}
                />
                <span class={Styles.checkmark}></span>
              </label>
            </div>
          </div> */}

          <div className={Styles.inputDiv}>
            <p className={Styles.inputQuestion}>Select Program</p>
            <div
              style={{
                width: '50%',
                margin: '0px 20px',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Select
                placeholder={'Select program'}
                isMulti={true}
                isSearchable={true}
                className={'w-50'}
                options={Metadata.COHORT_OPTIONS}
                value={selectedProgram}
                onChange={(val) => {
                  console.log(val)
                  setSelectedProgram(val)
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </div>

          <div className={Styles.inputDiv}>
            <p className={Styles.inputQuestion}>Select Devices</p>
            <div style={{ width: '50%', margin: '0px 20px' }}>
              <Select
                style={{ width: 100 }}
                options={metadata.RPM_DEVICES}
                value={selectedItems}
                autoBlur={true}
                isMulti={true}
                isSearchable
                onChange={(val) => {
                  console.log(val)
                  setSelectedItems(val)
                }}
                theme={(theme) => selectTheme(theme)}
              />
            </div>
          </div>
          {selectedItems && selectedItems?.length && (
            <div
              //   key={index}
              // className={Styles.contentRow}
              className={Styles.contentBackground1}
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start',
                margin: 10,
                width: '50%',
              }}
            >
              {selectedItems.map((item) => (
                <div
                  className={Styles.inputDiv}

                  //   style={{
                  //     display: 'flex',
                  //     flexDirection: 'row',
                  //     marginTop: 50,
                  //     width: '100%',
                  //   }}
                >
                  <p
                    className={Styles.inputQuestion}
                    style={{ width: '50%', wordWrap: 'break-word' }}

                    // style={{
                    //   alignSelf: 'center',
                    //   color: Constants.primaryTheme,
                    //   fontWeight: 'bold',
                    // }}
                  >
                    {item.label}
                  </p>
                  <div style={{ width: '50%', margin: '0px 20px' }}>
                    <input
                      placeholder={'1'}
                      className={Styles.textInput}
                      type="number"
                      value={item.count}
                      onChange={(e) => {
                        let text = e.target.value
                        let temp = [...selectedItems]
                        temp.forEach((ele) => {
                          if (ele.label === item.label) ele.count = text
                        })

                        setSelectedItems(temp)
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}

          <div className={Styles.inputDiv}>
            <p className={Styles.inputQuestion}>
              Confirm Patient Shipping Address?
            </p>
            <div
              style={{
                width: '60%',
                margin: '0px 20px',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <label class={Styles.checkContainer} style={{ width: '30%' }}>
                Yes
                <input
                  type="checkbox"
                  checked={rpmEligible}
                  onClick={() => {
                    setRpmEligible(!rpmEligible)
                  }}
                />
                <span class={Styles.checkmark}></span>
              </label>
              <label class={Styles.checkContainer} style={{ width: '30%' }}>
                No
                <input
                  type="checkbox"
                  checked={!rpmEligible}
                  onClick={() => {
                    setRpmEligible(!rpmEligible)
                  }}
                />
                <span class={Styles.checkmark}></span>
              </label>
            </div>
          </div>
        </>
      )
    } else if (showNext) {
      return (
        <>
          <div className={Styles.inputDiv}>
            <p className={Styles.inputQuestion}>
              Confirm Patient Shipping Address?
            </p>
            <div
              style={{
                width: '60%',
                margin: '0px 20px',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <label class={Styles.checkContainer} style={{ width: '30%' }}>
                Yes
                <input
                  type="checkbox"
                  checked={rpmEligible}
                  onClick={() => {
                    setRpmEligible(!rpmEligible)
                  }}
                />
                <span class={Styles.checkmark}></span>
              </label>
              <label class={Styles.checkContainer} style={{ width: '30%' }}>
                No
                <input
                  type="checkbox"
                  checked={!rpmEligible}
                  onClick={() => {
                    setRpmEligible(!rpmEligible)
                  }}
                />
                <span class={Styles.checkmark}></span>
              </label>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: '5% 1%',
              borderTopWidth: 1,
              borderTopColor: Constants.primaryTheme,
              borderTopStyle: 'solid',
            }}
          >
            <div className={Styles.inputDiv}>
              <p className={Styles.inputQuestion}>
                Detailed History of Conditions
              </p>

              {renderConditions()}
            </div>
          </div>

          <div className={Styles.inputDiv}>
            <p className={Styles.inputQuestion}>Order Note</p>
            <div style={{ width: '60%', margin: '0px 20px' }}>
              <input
                placeholder={'Order Note'}
                className={Styles.textInput}
                type="text"
                value={orderNote}
                onChange={(e) => {
                  let text = e.target.value
                  setOrderNote(text)
                }}
              />
            </div>
          </div>
          {referralType &&
            referralType.value === 'speciality' &&
            renderInfusion()}
          {/* <div className={Styles.horizontalLine} /> */}
          {renderIcdCodes()}
          {/* <div className={Styles.horizontalLine} /> */}
          {renderInstruction()}

          {/* <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: '5% 1%',
              borderTopWidth: 1,
              borderTopColor: Constants.primaryTheme,
              borderTopStyle: 'solid',
            }}
          >
            <div className={Styles.inputDiv}>
              <p className={Styles.inputQuestion}>Patient Note</p>

              {patientCarePlanData && patientCarePlanData.length != 0 ? (
                <div
                  className={Styles.contentBackground}
                  style={{ height: 500, overflowY: 'scroll' }}
                >
                  {renderCarePlanNotes()}
                </div>
              ) : (
                `No Notes Found`
              )}
            </div>
          </div> */}
        </>
      )
    }
  }

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minWidth: '100%',
          minHeight: '100%',
        }}
      >
        <div className={GlobalStyles.loader} />
      </div>
    )
  } else {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          margin: '5% 1%',
          borderTopWidth: 1,
          borderTopColor: Constants.primaryTheme,
          borderTopStyle: 'solid',
        }}
      >
        {renderContent()}

        {showModal && (
          <Modal
            ariaHideApp={false}
            isOpen={showModal}
            onRequestClose={() => setShowModal(false)}
            style={customChangeModalStyles}
            contentLabel="Modal"
          >
            <h3>Please verify the document and proceed to Fax</h3>
            <Button
              onClick={() => onSendPressed()}
              className={GlobalStyles.button}
              style={{
                width: 100,
                position: 'absolute',
                right: 5,
                top: 10,
              }}
              variant="primary"
            >
              Send Fax
            </Button>
            <iframe
              srcDoc={pdfData}
              title="Provider Referral"
              style={{ height: '100%', width: '100%' }}
            />
          </Modal>
        )}

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Button
            onClick={() => setShowNext(false)}
            className={GlobalStyles.button}
            style={{
              width: 100,
              marginTop: 60,
              alignSelf: 'flex-start',
              visibility: showNext ? 'visible' : 'hidden',
            }}
            variant="primary"
          >
            Back
          </Button>

          <Button
            onClick={() => {
              // if (!showNext) setShowNext(true)
              // else onSubmitPressed()
              onSubmitPressed()
            }}
            className={GlobalStyles.button}
            style={{ width: 150, marginTop: 60, alignSelf: 'flex-end' }}
            variant="primary"
          >
            Place Order
            {/* {showNext ? 'Place Order' : 'Next'} */}
          </Button>
        </div>
      </div>
    )
  }
}

export default RpmOrders
