import React, { Component } from 'react'
import Select from 'react-select'
import ReactTooltip from 'react-tooltip'
import moment from 'moment'
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend } from 'recharts';

import Helpers from '../../helpers/Global.js'
import GlobalStyles from '../styles/global.module.scss'
import Styles from './styles/Progress.module.scss'
import {addPatient, updatePatientData} from "../../redux/actions";
import {connect} from "react-redux";
import PatientInfoStrip from "../../components/PatientInfoStrip";
import Config from "../../config";
import actionTypes from "../../redux/actionTypes";
import Modal from "react-modal";
import Constants from "../../values";
const axios = require('axios');

let GREEN_RANGE_COLOR = '#20A89266'
let YELLOW_RANGE_COLOR = '#ffae4266'
let RED_RANGE_COLOR = '#ff000066'

let windowHeight = window.innerHeight
const customProgressModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)'
  },
  content : {
    width: '60%',
    height: (windowHeight/1.3),
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};


const CHART_DATA = [
  {name: 'Date A', s1: 4, s2: 8, s3: 4, s4: 8},
  {name: 'Date B', s1: 7, s2: 9, s3: 3, s4: 6},
  {name: 'Date c', s1: 1, s2: 10, s3: 7, s4: 6},
  {name: 'Date d', s1: 9, s2: 6, s3: 9, s4: 9},
  {name: 'Date e', s1: 5, s2: 6, s3: 10, s4: 10},
  {name: 'Date f', s1: 7, s2: 4, s3: 7, s4: 8},
];


// const VITAL_OPTIONS = ["Temperature", "Respiratory Rate", "SpO2", "Blood Pressure", "Heart Rate", "HRV"]
const VITAL_OPTIONS = [
  {label: 'Temperature', val: '98.6', unit: 'F', value: "Temp", chartDomain: [85, 115],
    ranges:{
      red: [[0,94.9], [100.4,200]],
      yellow: [[95,96.89], [99.59, 100.39]],
      green: [[96.9,99.6]]
    }},
  {label: 'Respiratory Rate', val: '45', unit: 'breaths / min', value: "Resp", chartDomain: [0, 50],
    ranges:{
      red: [[0,11.9], [25, 50]],
      yellow: [[21, 24.99], [21, 24.99]],
      green: [[12,20.99]]
  }},
  {label: 'SpO2', unit: '-', val: '85%', value: "SpO2", chartDomain: [75, 100], ranges: 90},
  {label: 'Blood Pressure', unit: 'mm Hg', val: '120/80', value: "BP", chartDomain: [40, 200],
    ranges:{
      sys: [120, [120, 139], 140],
      dia: [80, [80, 89], 90]
  }},
  {label: 'Heart Rate', unit: 'BPM', val: '78', value: "HR", chartDomain: [30, 200],
    ranges:{
      red: [[0,60], [100, 200]],
      yellow: [[0, 0], [0, 0]],
      green: [[61, 99]]
  }},
  {label: 'HRV', unit: 'ms', val: '67', value: "HRV", chartDomain: [0, 200]},
]

const LAB_OPTIONS = [
  {label: 'Cholesterol', val: '172', unit: 'mg/dL', value: "Cholesterol", chartDomain: [0, 300]},
  {label: 'Glucose', val: '102', unit: 'mg/dL', value: "Glucose", chartDomain: [40, 320]},
  {label: 'HbA1C', unit: '-', val: '5.8%', value: "A1c"},
  {label: 'CRP', unit: 'mg/L', val: '5', value: "CRP"},
  {label: 'Insulin', unit: 'pmol/L', val: '10', value: "Insulin"},
  {label: 'Uric Acid', unit: 'mg/dL', val: '10', value: "Uric Acid"},
  {label: 'GGT', unit: 'units/L', val: '10', value: "GGT"},
  {label: 'CRP-HS', unit: 'mg/L', val: '10', value: "CRP-HS"},
  {label: 'Ferritin', unit: 'mcg/L', val: '10', value: "Ferritin"},
  {label: 'LDL-P', unit: 'mg/dL', val: '10', value: "LDL-P"},
  {label: 'HDL', unit: 'mg/dL', val: '10', value: "HDL"},
  {label: 'Lp(a)', unit: 'mg/dL', val: '10', value: "Lp(a)"},
  {label: 'Triglyceride', unit: 'mg/dL', val: '10', value: "Triglyceride"},
  {label: 'Vitamin D', unit: 'ng/mL', val: '10', value: "Vitamin D"},
  {label: 'Vitamin B12', unit: 'pg/mL', val: '10', value: "Vitamin B12"},

]

const LIFESTYLE_OPTIONS = [
  {label: 'Rx Adherence', val: '75%', unit: '-', value: "Rx Adherence"},
  {label: 'Average Sleep', val: '7', unit: 'hours/day', value: "Sleep", chartDomain: [0, 16],
    ranges:{
      red: [[0,5.99], [11, 24]],
      yellow: [[6, 6.99], [9, 10.99]],
      green: [[7,8.99]]
  }},
  {label: 'Weight', unit: 'pounds(lbs)', val: '160', value: "Weight", chartDomain: [60, 400],
    ranges:{
      red: [[0,16.99], [30, 50]],
      yellow: [[25, 29.99], [17, 18.49]],
      green: [[18.5,24.99]]
  }},
  {label: 'Average Activity', unit: 'mins/day', val: '35', value: "Activity", chartDomain: [0, 400]},
  {label: 'Average Steps', unit: 'steps/day', val: '4023', value: "Steps", chartDomain: [0, 20000]},
  {label: 'Resting Calories', unit: 'kcal', val: '1024', value: "Resting Calories", chartDomain: [0, 4000]},
  {label: 'Active Calories', unit: 'kcal', val: '1024', value: "Active Calories", chartDomain: [0, 5000]},
  {label: 'Body Fat', unit: '%', val: '20%', value: "Body Fat", chartDomain: [2, 40],
    ranges:{
      red: [[0,16.99], [30, 50]],
      yellow: [[25, 29.99], [17, 18.49]],
      green: [[18.5,24.99]]
    }
  }
]

class Progress extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalIsOpen: false,
      vitalOptions: [],
      lifestyleOptions: [],
      labOptions: [],
      timeline: {label: 'Complete History', value: 'complete'},
      patient: null,
      checked: false,
      symptomProgressData: null,
      vitalsProgressData: null,
      symptomLoading: true,
      vitalsLoading: true,
      modalOption: {},
      modalProgress: [],
      loading: true
    }
    this.trackScrolling = this.trackScrolling.bind(this)
    this.isBottom = this.isBottom.bind(this)
    this.getSymptomProgressData = this.getSymptomProgressData.bind(this)
  }

  static getDerivedStateFromProps(props, state) {
    if(props.patientProgress && props.patientCore) {
      return {
        vitalsProgressData: props.patientProgress, vitalsLoading: false, loading: false
      }

    }

    return null
  }

  componentDidMount() {
    if(!this.props.patient) {
      this.props.history.push('/patient/list')
      return
    }

    window.scrollTo(0, 0)
    document.addEventListener('scroll', this.trackScrolling);

    let {patient} = this.props
    let {preferences} = patient

    if(preferences && preferences.checked) {
      this.setState({
        vitalOptions: preferences.vitalOptions || [],
        labOptions: preferences.labOptions || [],
        lifestyleOptions: preferences.lifestyleOptions || [],
        timeline: preferences.timeline || {label: 'Complete History', value: 'complete'},
        checked: preferences.checked
      })
      let timeline = preferences.timeline ? preferences.timeline.value : 'complete'
      this.getSymptomProgressData(timeline)
    } else {
      this.setState({
        vitalOptions: VITAL_OPTIONS,
        lifestyleOptions: LIFESTYLE_OPTIONS,
        checked: false,
        timeline: {label: 'Complete History', value: 'complete'},
      })
      this.getSymptomProgressData('complete')
    }

  }

  getVitalsProgressData() {
    //get data
    let {patient, token} = this.props
    let self = this
  }

  getSymptomProgressData(timeline) {
    this.setState({symptomLoading: true})
    //get data
    let {patient, token} = this.props
    let self = this
    let url = Config.BACKEND_URL + 'user/progress?timeline=' + timeline
    axios({
      method: 'get',
      headers:{Authorization: 'JWT '+ token, x_firebase_id: patient.uid},
      url: url,
    }).then(({ data }) => {
      if(data && data.progress)
        data.progress.reverse()
      self.setState({symptomProgressData: data, symptomLoading: false})
    }).catch(err => {
      console.log('error when getting patient symptoms progress data', err)
    })
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.trackScrolling);
  }

  isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }

  trackScrolling() {
    const wrappedElement = document.getElementById('bottom');
    if (this.isBottom(wrappedElement)) {
      this.props.history.push('/patient/insights')
      document.removeEventListener('scroll', this.trackScrolling);
    }
  };

  onSelectEntryChanged(stateOptionKey, val) {
    this.setState({[stateOptionKey]: val})
    if(this.state.checked) {
      let {docFirebaseAuthUser, patient, token} = this.props
      let key = "preferences." + stateOptionKey
      this.props.updatePatientData({
        doctor_id: docFirebaseAuthUser.uid,
        patient_id: patient.uid,
        [key]: val
      }, token)
    }
  }

  onTimelineChanged(val) {
    this.setState({timeline: val})
    if(this.state.checked) {
      let {docFirebaseAuthUser, patient, token} = this.props
      this.props.updatePatientData({
        doctor_id: docFirebaseAuthUser.uid,
        patient_id: patient.uid,
        "preferences.timeline": val
      }, token)
    }

    this.getSymptomProgressData(val.value)

  }

  onChecked(e) {
    let checked = e.target.checked
    this.setState({checked})

    let {docFirebaseAuthUser, patient, token} = this.props

    if(checked) {
      this.props.updatePatientData({
        doctor_id: docFirebaseAuthUser.uid,
        patient_id: patient.uid,
        preferences: {
          vitalOptions: this.state.vitalOptions,
          labOptions: this.state.labOptions,
          lifestyleOptions: this.state.lifestyleOptions,
          timeline: this.state.timeline,
          checked: true
        }
      }, token)
    } else {
      this.props.updatePatientData({
        doctor_id: docFirebaseAuthUser.uid,
        patient_id: patient.uid,
        preferences: {
          checked: false
        }
      }, token)
    }
  }

  calculateBMIValues(height, weight, gender, age) {
    let bmi = (703 * weight/Math.pow(height, 2))
    let bfp = null
    let bmr = null
    switch(gender) {
      case 'Male':
        bfp = (1.2 * bmi) + (0.23 * age) - 16.2
        bmr = (10 * weight * 0.454) + (6.25 * height * 2.54) - (5 * age) + 5
        break
      case 'Female':
        bfp = (1.2 * bmi) + (0.23 * age) - 5.4
        bmr = (10 * weight * 0.454) + (6.25 * height * 2.54) - (5 * age) - 161
    }

    return Math.round(bfp)
  }


  getTooltip(option, progress) {
    return (
      <ReactTooltip id={option.label}  textColor='black' backgroundColor='white' effect='solid' className={Styles.hoverChatWrapper}>
        <div>
          <LineChart width={500} height={400} data={progress} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
            <Legend verticalAlign="top"/>
            {
              option.label === 'Blood Pressure' ?
                <Line name={'Systolic'} type="monotone" dataKey="value" stroke={Helpers.random_hex_color_code()} />
                :
                <Line name={option.label+' ('+option.unit+')'} type="monotone" dataKey="value" stroke={Helpers.random_hex_color_code()} />
            }

            {
              option.label === 'Blood Pressure' &&
                <Line name={'Diastolic'} type="monotone" dataKey="lower" stroke={Helpers.random_hex_color_code()} />
            }

            <XAxis dataKey="date" stroke={'#000000cc'}/>
            <YAxis domain={option.chartDomain} stroke={'#000000cc'}/>
            <Tooltip />
          </LineChart>
        </div>
      </ReactTooltip>
    )
  }

  getSnapshotAndProgressData(option, key, vitalsOrUpdates) {
    let data = this.state.vitalsProgressData
    let toReturn = {progress: []}
    let roundedValuesForKeys = ["heart_rate_variability", "resting_calories", "active_calories"]
    data[vitalsOrUpdates].forEach(entry => {
      let value = entry[key]
      if(value && value !== 0) {
        if(!toReturn.snapshot)
          toReturn.snapshot = roundedValuesForKeys.includes(key) ? Math.round(value) : value
        toReturn.progress.push({
          value: roundedValuesForKeys.includes(key) ? Math.round(value) : value,
          date: moment(entry.created_at).format('D-MMM')
        })
      }

    })

    if(key === 'weight' && (!data.updates || data.updates.length === 0)) {
      let {patientCore} = this.props
      toReturn.snapshot =  patientCore.weight_update || patientCore.weight
    }


    return toReturn
  }

  getSnapshotAndProgressFat(key, vitalsOrUpdates) {
    let {patientCore} = this.props
    let age = patientCore.dob && moment().diff(moment(patientCore.dob), 'years')
    let data = this.state.vitalsProgressData
    let toReturn = {progress: []}
    data['updates'].forEach(entry => {
      let value = entry['weight']
      if(value && value !== 0) {
        toReturn.progress.push({
          value: this.calculateBMIValues(patientCore.height, entry.weight, patientCore.gender, age),
          date: moment(entry.created_at).format('D-MMM')
        })
      }

    })

    if(!toReturn.snapshot)
      toReturn.snapshot = this.calculateBMIValues(patientCore.height, patientCore.weight_update || patientCore.weight, patientCore.gender, age)

    return toReturn
  }

  getSnapshotAndProgressDataBP() {
    let data = this.state.vitalsProgressData
    let toReturn = {progress: []}
    data["vitals"].forEach(entry => {
      let value = entry['blood_pressure']
      if(value && value.lower && value.upper && value.lower !== 0 && value.upper !== 0) {
        if(!toReturn.snapshot)
          toReturn.snapshot = value.upper + '/' + value.lower
        toReturn.progress.push({
          value: value.upper,
          lower: value.lower,
          date: moment(entry.created_at).format('D-MMM')
        })
      }

    })

    return toReturn
  }

  getSnapshotAndProgressAverage(key) {
    let data = this.state.vitalsProgressData
    let toReturn = {progress: []}
    let total = 0
    let denom = 0
    data['vitals'].forEach(entry => {
      let value = entry[key]
      if(value && value !== 0) {
        console.log('value', value, key)
        total += Number(value)
        denom += 1
        toReturn.progress.push({
          value: Number(value).toFixed(1),
          date: moment(entry.created_at).format('D-MMM')
        })
      }

    })

    toReturn.snapshot = denom === 0 ? '-' : (total/denom).toFixed(1)

    return toReturn
  }

  getColorRangeAddedWeight(option, height, weight) {
    let bmi = (703 * weight/Math.pow(height, 2))
    let ranges = option.ranges
    let greenRange = ranges.green
    let yellowRange = ranges.yellow

    if(bmi >= greenRange[0][0] && bmi <= greenRange[0][1])
      return GREEN_RANGE_COLOR
    else if((bmi >= yellowRange[0][0] && bmi <= yellowRange[0][1]) || (bmi >= yellowRange[1][0] && bmi <= yellowRange[1][1]))
      return YELLOW_RANGE_COLOR
    else
      return RED_RANGE_COLOR
  }

  getColorRangeAddedSpO2(option, value) {
    let ranges = option.ranges
    if(value.value < ranges)
      return RED_RANGE_COLOR

    return GREEN_RANGE_COLOR
  }

  getColorRangeAddedBP(option, values) {
    let ranges = option.ranges
    let sysRange = ranges.sys
    let diaRange = ranges.dia

    let upper = values.value
    let lower = values.lower
    if(upper <= sysRange[0] && lower <= diaRange[0])
      return GREEN_RANGE_COLOR

    if((upper >= sysRange[1][0] && upper <= sysRange[1][1])
      || (lower >= diaRange[1][0] && lower <= diaRange[1][1]))
      return YELLOW_RANGE_COLOR

    return RED_RANGE_COLOR
  }

  renderEmptyOrNullValue(option) {
    return (
      <div
        key={option.value}
        style={{width: 160, marginRight: 5, marginLeft: 5, height: 120, alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', cursor: 'pointer'}}>
        <p style={{fontWeight: 'bold', fontSize: 20}}>{'N/A'}{option.label === 'Body Fat' && <span style={{marginLeft: 2}}>%</span>}</p>
        {option.label === 'Body Fat' ?
          <p>-</p>
          :
          <p>{option.unit}</p>
        }
        <div style={{width: '90%', backgroundColor: GREEN_RANGE_COLOR, display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '4px 2px', borderRadius: 8}}>
          {option.label === 'SpO2' ? <p>SpO<sub>2</sub></p> : <p>{option.label}</p>}
        </div>
      </div>
    )
  }


  renderValue(option, key, vitalsOrUpdates) {
    let {vitalsProgressData} = this.state
    if(vitalsOrUpdates === 'vitals' && (!vitalsProgressData || !vitalsProgressData.vitals || vitalsProgressData.vitals.length === 0)) {
      return this.renderEmptyOrNullValue(option)
    }

    if(vitalsOrUpdates === 'updates' && (!vitalsProgressData || !vitalsProgressData.updates || vitalsProgressData.updates.length === 0) && key !== 'weight') {
      return this.renderEmptyOrNullValue(option)
    }

    let data
    if(option.label === 'Average Sleep' || option.label === 'Average Activity' || option.label === 'Average Steps')
      data = this.getSnapshotAndProgressAverage(key)
    else if(option.label === 'Body Fat')
      data = this.getSnapshotAndProgressFat()
    else if(option.label === 'Blood Pressure')
      data = this.getSnapshotAndProgressDataBP()
    else
      data = this.getSnapshotAndProgressData(option, key, vitalsOrUpdates)

    let color = GREEN_RANGE_COLOR
    if(option.ranges) {
      if(option.label === 'Weight' || option.label === "Body Fat") {
        //because we need to assign range based on BMI, its a different method
        let {patientCore} = this.props
        color = this.getColorRangeAddedWeight(option, patientCore.height, patientCore.weight_update || patientCore.weight)
      } else if(option.label === 'Blood Pressure') {
        color = this.getColorRangeAddedBP(option, data.progress[0])
      } else if(option.label === 'SpO2'){
        color = this.getColorRangeAddedSpO2(option, data.progress[0])
      } else {
        let value = data.snapshot
        let ranges = option.ranges
        let greenRange = ranges.green
        let yellowRange = ranges.yellow

        if(value >= greenRange[0][0] && value <= greenRange[0][1])
          color = GREEN_RANGE_COLOR
        else if((value >= yellowRange[0][0] && value <= yellowRange[0][1]) || (value >= yellowRange[1][0] && value <= yellowRange[1][1]))
          color = YELLOW_RANGE_COLOR
        else
          color = RED_RANGE_COLOR
      }
    }


    if(data.progress)
      data.progress.reverse()
    return (
      <div
        onClick={() => this.setState({modalIsOpen: true, modalOption: option, modalProgress: data.progress})}
        data-tip
        data-for={option.label}
        key={option.value}
        style={{width: 160, marginRight: 5, marginLeft: 5, height: 120, alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', cursor: 'pointer'}}>
        <p style={{fontWeight: 'bold', fontSize: 20}}>{data.snapshot}{(option.label === 'Body Fat' || option.label === 'SpO2') && <span style={{marginLeft: 2}}>%</span>}</p>
        {option.label === 'Body Fat' ?
          <p>-</p>
          :
          <p>{option.unit}</p>
        }
        <div style={{width: '90%', backgroundColor: color, display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '4px 2px', borderRadius: 8}}>
          {option.label === 'SpO2' ? <p>SpO<sub>2</sub></p> : <p>{option.label}</p>}
        </div>
        {this.getTooltip(option, data.progress)}


      </div>
    )
  }

  renderEmptyValue(option) {
    return (
      <div
        key={option.value}
        style={{width: 160, marginRight: 5, marginLeft: 5, height: 120, alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', cursor: 'pointer'}}>
        <p style={{fontWeight: 'bold', fontSize: 20}}>N/A</p>
        <p>{option.unit}</p>
        <div style={{width: '90%', backgroundColor: '#20A89266', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '4px 2px', borderRadius: 8}}>
          {option.label === 'SpO2' ? <p>SpO<sub>2</sub></p> : <p>{option.label}</p>}
        </div>
      </div>
    )
  }


  renderSelectedEntries(row) {
    if(!this.state.vitalsProgressData)
      return null

    let toReturn = []
    let options = []
    let temp = row.toLowerCase()
    switch(temp) {
      case 'vitals':
        if(!this.state.vitalOptions)
          return
        options = this.state.vitalOptions
        break
      case 'labs':
        if(!this.state.labOptions)
          return
        options = this.state.labOptions
        break
      case 'lifestyle':
        if(!this.state.lifestyleOptions)
          return
        options = this.state.lifestyleOptions
        break
      default:
    }



    options.forEach(option => {
      switch(option.label) {
        case 'Heart Rate':
          toReturn.push(this.renderValue(option, "heart_rate", "vitals")) //key is the key for object reutrned by API
          break
        case 'Respiratory Rate':
          toReturn.push(this.renderValue(option, "respiration_rate", "vitals")) //key is the key for object reutrned by API
          break
        case 'Glucose':
          toReturn.push(this.renderValue(option, "glucose", "vitals")) //key is the key for object reutrned by API
          break
        case 'Temperature':
          toReturn.push(this.renderValue(option, "temperature", "vitals")) //key is the key for object reutrned by API
          break
        case 'Weight':
          toReturn.push(this.renderValue(option, "weight", "updates")) //key is the key for object reutrned by API
          break
        case 'Average Sleep':
          toReturn.push(this.renderValue(option, "sleep", "vitals")) //key is the key for object reutrned by API
          break
        case 'Average Activity':
          toReturn.push(this.renderValue(option, "activity_time", "vitals")) //key is the key for object reutrned by API
          break
        case 'Body Fat':
          toReturn.push(this.renderValue(option, "weight", "updates")) //key is the key for object reutrned by API
          break
        case 'Blood Pressure':
          toReturn.push(this.renderValue(option, "blood_pressure", "vitals")) //key is the key for object reutrned by API
          break
        case 'SpO2':
          toReturn.push(this.renderValue(option, "oxygen_saturation", "vitals")) //key is the key for object reutrned by API
          break
        case 'HRV':
          toReturn.push(this.renderValue(option, "heart_rate_variability", "vitals")) //key is the key for object reutrned by API
          break
        case 'Average Steps':
          toReturn.push(this.renderValue(option, "steps", "vitals")) //key is the key for object reutrned by API
          break
        case 'Resting Calories':
          toReturn.push(this.renderValue(option, "resting_calories", "vitals")) //key is the key for object reutrned by API
          break
        case 'Active Calories':
          toReturn.push(this.renderValue(option, "active_calories", "vitals")) //key is the key for object reutrned by API
          break
        default:
          toReturn.push(this.renderEmptyValue(option)) //key is the key for object reutrned by API
      }
    })

    return toReturn

  }



  renderSections(row) {
    let options = []
    let stateOptionKey = ''

    let temp = row.toLowerCase()
    switch(temp) {
      case 'vitals':
        options = VITAL_OPTIONS
        stateOptionKey = 'vitalOptions'
        break
      case 'labs':
        options = LAB_OPTIONS
        stateOptionKey = 'labOptions'
        break
      case 'lifestyle':
        options = LIFESTYLE_OPTIONS
        stateOptionKey = 'lifestyleOptions'
        break
      default:
    }


    return (
      <div className={Styles.sectionDivider}>
        <div style={{display: 'flex', flexDirection: 'row', flexGrow: 1}}>
          <h4>{row}</h4>

          <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: 50, flexGrow: 1}}>
            <div style={{flexGrow: 1}}>
              <Select
                placeholder={'Select '+ temp + '  ....'}
                isMulti={true}
                style={{color: 'red'}}
                options={options}
                value={this.state[stateOptionKey]}
                onChange={(val) => this.onSelectEntryChanged(stateOptionKey, val)}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>



          </div>
        </div>

        <div style={{flexDirection: 'row', display: 'flex', marginTop: 30, flexWrap: 'wrap'}}>
          {this.state.vitalsLoading ?
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100%'}}>
              <div className={GlobalStyles.loader} />
            </div>
          :
            this.renderSelectedEntries(row)
          }
        </div>

      </div>
    )

  }

  renderTrackersGraph() {
    let graphWidth = (window.innerWidth) / 2
    let data = this.state.symptomProgressData
    if(!data)
      return null

    return (
      <div style={{marginBottom: 40, minWidth: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', boxShadow: "5px 5px 5px 5px grey"}}>
        <h4>Lifestyle Health</h4>
        <LineChart width={graphWidth} height={350} data={data.progress} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
          <Legend verticalAlign="top"/>
          <Line dot={false} name={'Physical Activity'}  type="monotone" dataKey="physical" stroke={Helpers.random_hex_color_code()} />
          <Line dot={false} name={'Healthy Eating'}  type="monotone" dataKey="eating" stroke={Helpers.random_hex_color_code()} />
          <Line dot={false} name={'Stress'}  type="monotone" dataKey="stress" stroke={Helpers.random_hex_color_code()} />
          <Line dot={false} name={'Mood'}  type="monotone" dataKey="mood" stroke={Helpers.random_hex_color_code()} />
          <Line dot={false} name={'Inflammation'}  type="monotone" dataKey="inflammation" stroke={Helpers.random_hex_color_code()} />
          <XAxis dataKey="created_at" tickFormatter={(tickItem) => {
            return moment(tickItem).format('D-MMM')
          }}/>
          <YAxis />
          <Tooltip />
        </LineChart>
      </div>
    )
  }

  renderSymptomGraph() {

    let graphWidth = (window.innerWidth) / 2
    let data = this.state.symptomProgressData
    if(!data)
      return null

    let symptomsMap = {}
    data.symptoms.forEach(each => {
      symptomsMap[each.node] = each.symptom
    })

    let chartData = []
    data.progress.forEach(each => {
      let toPut = {}
      toPut.date = moment(each.created_at, "MM/DD/YYYY").format('D-MMM')
      each.symptom_levels.forEach(symptom => {
        let key = symptomsMap[symptom._id]
        toPut[key] = symptom.level
      })
      chartData.push(toPut)
    })

    let lines = data.symptoms.map((x,i) => <Line dot={false} name={x.symptom}  type="monotone" dataKey={x.symptom} stroke={Helpers.random_hex_color_code()} />)

    return (
      <div style={{marginBottom: 100, marginTop: 40, minWidth: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column', boxShadow: "5px 5px 5px 5px grey"}}>
        <h4>Symptoms</h4>
        <LineChart width={graphWidth} height={350} data={chartData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
        <Legend verticalAlign="top"/>
          {lines}
        <XAxis padding={{ top: 20 }} dataKey="date" type={"category"}/>
        <YAxis />
        <Tooltip />
        </LineChart>
      </div>
    )
  }

  renderTrackers() {
    return (
      <div className={Styles.sectionDivider} style={{minHeight: 400, borderBottomWidth: 0, marginBottom: 40}}>
        <div style={{flexDirection: 'row', display: 'flex', minWidth: '100%'}}>
          <h4 style={{marginRight: 40}} >Patient Reported Outcomes</h4>

          <div style={{width: 300}}>
            <Select
              options={[
                {label: 'Weekly', value: 'weekly'},
                {label: 'Monthly', value: 'monthly'},
                {label: 'Semi Annual', value: 'semiAnnual'},
                {label: 'Complete History', value: 'complete'},
              ]}
              value={this.state.timeline}
              onChange={(val) => this.onTimelineChanged(val)}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>

        </div>

        {this.state.symptomLoading ?
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100%', height: 700}}>
            <div className={GlobalStyles.loader} />
          </div>
          :
          <div>
            {this.renderSymptomGraph()}
            {this.renderTrackersGraph()}
          </div>
        }


      </div>
    )
  }

  renderModal() {
    let modalOption = this.state.modalOption
    let progressData = this.state.modalProgress
    let modalWidth = (window.innerWidth) * 0.6

    return (
      <div style={{height: '100%', width: '100%'}}>
        <p className={Styles.closeModalBtn} onClick={() => this.setState({modalIsOpen: false})}>X</p>
        <h4 style={{color: Constants.primaryTheme, textAlign: 'center'}}>{modalOption.label}</h4>

        <div>
          <LineChart  width={modalWidth - 80} height={400} data={progressData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
            <Legend verticalAlign="top"/>
            {
              modalOption.label === 'Blood Pressure' ?
                <Line name={'Systolic'} type="monotone" dataKey="value" stroke={Helpers.random_hex_color_code()} />
                :
                <Line name={modalOption.label+' ('+modalOption.unit+')'} type="monotone" dataKey="value" stroke={Helpers.random_hex_color_code()} />
            }

            {
              modalOption.label === 'Blood Pressure' &&
              <Line name={'Diastolic'} type="monotone" dataKey="lower" stroke={Helpers.random_hex_color_code()} />
            }

            <XAxis dataKey="date" stroke={'#000000cc'}/>
            <YAxis domain={modalOption.chartDomain} stroke={'#000000cc'}/>

            <Tooltip />
          </LineChart>
        </div>

      </div>
    )
  }


  render() {
    return (
      <div className={GlobalStyles.container}>
        {
          this.state.loading ?
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100%', height: '100vh'}}>
              <div className={GlobalStyles.loader} />
            </div>
            :
            <div>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 40, marginTop: 20}}>
                <input type="checkbox" style={{marginRight: 10}} onChange={this.onChecked.bind(this)} checked={this.state.checked}/>
                <p>Save this search</p>
              </div>
              <Modal
                ariaHideApp={false}
                onRequestClose={() => this.setState({modalIsOpen: false})}
                isOpen={this.state.modalIsOpen}
                style={customProgressModalStyles}
                contentLabel="Modal"
              >
                {this.renderModal()}
              </Modal>

              {this.renderSections('Vitals')}
              {this.renderSections('Labs')}
              {this.renderSections('Lifestyle')}

              {this.renderTrackers('Symptoms')}
            </div>
        }

        <div id={'bottom'} style={{visibility: 'hidden', marginTop: 400}}>
          asdasd
        </div>

      </div>
    )
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    patient: state.patientReducer.patient,
    patientCore: state.patientReducer.patientCore,
    color: state.patientReducer.color,
    token: state.authReducer.token,
    patientProgress: state.patientReducer.patientProgress,
    docFirebaseAuthUser: state.userReducer.firebaseAuthUser,
    mongoUser: state.userReducer.mongoUser,
    medications: state.patientReducer.medications,
    conditions: state.patientReducer.conditions,
  }
}

const mapDispatchToProps = { addPatient, updatePatientData }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Progress)


