// file not being used anywhere check to confirm
import React, { Component, useState } from "react";
import GlobalStyles from "../../styles/global.module.scss";
import PatientInfoStrip from "../../../components/PatientInfoStrip";
import { connect } from "react-redux";
import Styles from "./styles/Careplan.module.scss";
import ReactTooltip from "react-tooltip";
import Constants from "../../../values.js";
import moment from "moment";
import DocPlaceHolder from "../../../assets/images/docPlaceholder.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCrosshairs,
  faHandHoldingMedical,
  faBookMedical,
  faStethoscope,
  faPlusCircle,
  faTrash, faNotesMedical, faClipboardList
} from "@fortawesome/free-solid-svg-icons";
import Config from "../../../config";
import Modal from "react-modal";
import Button from "react-bootstrap/Button";
import { addPatient, getUnreadMessages} from "../../../redux/actions";
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits, RefinementList, InfiniteHits, connectStateResults  } from 'react-instantsearch-dom';
import {ToastContainer, toast} from "react-toastify";
import CareteamChat from "./CareteamChat";
import Screeners from './InnerTabs/Screeners'
import Orders from './InnerTabs/Orders'

const axios = require("axios");

const HEALTH_MODULES = [
  "Diabetes Prevention",
  "Heart Diseases Prevention",
  "Nutrition",
  "Stress Management",
];

const searchClient = algoliasearch(
  Config.ALGOLIA_APP_ID,
  Config.ALGOLIA_API_KEY,
);

const NOTES_MODAL_STYLES = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)'
  },
  content : {
    width: '70%',
    height: '90%',
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    zIndex: 2
  }
};

const customModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.44)",
  },
  content: {
    width: 600,
    height: 400,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

class CarePlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      healthModules: HEALTH_MODULES,
      loading: true,
      modalLoading: false,
      patientGoals: null,
      modalText: "",
      modalError: false,
      selectedTab: "goals",
      careteam: null,
      careplan: null,
      modalNotesIsOpen: false,
      notesFromVisit: {},
      addIcd10: {},
      addNotes: '',
      addCarePlan: '',
      addCpt: [],
      notesList: [],
      modalAddNotesIsOpen: false,
    };
    this.isBottom = this.isBottom.bind(this);
    this.getPatientGoals = this.getPatientGoals.bind(this);
    this.getUserCareteam = this.getUserCareteam.bind(this);
    this.getUserCarePlan = this.getUserCarePlan.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.patient && props.patientCore && state.patientGoals)
      return { loading: false };

    return null;
  }

  componentDidMount() {
    this.props.getUnreadMessages(this.props.adminId, this.props.token, this.props.mongoUser)
    if (!this.props.patient) {
      this.props.history.push("/patient/list");
      return;
    }
    this.getPatientGoals();
    this.getUserCareteam();
    this.getUserCarePlan();
  }

  getUserCareteam() {
    let self = this;
    let { patient, token } = this.props;
    let url = Config.BACKEND_URL + "user/careteam";
    axios({
      method: "get",
      url: url,
      headers: { Authorization: "JWT " + token, x_firebase_id: patient.uid },
    })
      .then(({ data }) => {
        if (data) {
          self.setState({ careteam: data.doc });
        }
      })
      .catch((err) => {
        console.log("error when getting user care team", err);
      });
  }

  getUserCarePlan() {
    let self = this;
    let { patient, token, docFirebaseAuthUser } = this.props;
    let url = Config.BACKEND_URL + "provider/patients/careplan";
    axios({
      method: "get",
      url: url,
      headers: { Authorization: "JWT " + token, patient_id: patient.uid, x_doctor_id: docFirebaseAuthUser.uid },
    })
      .then(({ data }) => {
        if (data) {
          self.setState({ careplan: data });
        }
      })
      .catch((err) => {
        console.log("error when getting user care plan", err);
      });
  }

  getPatientGoals() {
    //get data
    let { patient, token } = this.props;
    let self = this;
    let url = Config.BACKEND_URL + "user/goals";
    axios({
      method: "get",
      headers: { Authorization: "JWT " + token, x_firebase_id: patient.uid },
      url: url,
    })
      .then(({ data }) => {
        self.setState({ patientGoals: data });
      })
      .catch((err) => {
        console.log("error when getting patient vital progress data", err);
      });
  }

  isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }

  getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  renderPatientGoalsTab() {
    let goals = this.state.patientGoals;
    if (!goals || !goals.goals) return null;

    let entries = goals.goals.map((x, index) => (
      <div className={Styles.contentRow} key={index.toString()}>
        {x.description}
      </div>
    ));
    return (
      <div style={{ width: "50%", margin: 10 }}>
        <h5 style={{ color: Constants.primaryTheme }}>Patient Goals</h5>
        <div
          className={Styles.contentBackground}
          style={{ height: 240, overflowY: "scroll" }}
        >
          {entries}
        </div>
      </div>
    );
  }

  renderProviderGoalsTab() {
    let { patient } = this.props;
    if (!patient || !patient.goals) return null;

    let entries = patient.goals.map((x, index) => (
      <div className={Styles.contentRow} key={index.toString()}>
        {x.text}
      </div>
    ));

    return (
      <div style={{ width: "50%", margin: 10 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            position: "relative",
          }}
        >
          <h5 style={{ color: Constants.primaryTheme }}>Provider Goals</h5>
          <FontAwesomeIcon
            onClick={() =>
              this.setState({ modalIsOpen: true, modalGoals: true })
            }
            icon={faPlusCircle}
            style={{
              color: Constants.primaryTheme,
              fontSize: 18,
              position: "absolute",
              right: 14,
              cursor: "pointer",
            }}
          />
        </div>
        <div
          className={Styles.contentBackground}
          style={{ height: 240, overflowY: "scroll" }}
        >
          {entries}
        </div>
      </div>
    );
  }

  renderGoalsTab() {
    return (
      <div
        style={{
          width: "100%",
          margin: 10,
          display: "flex",
          flexDirection: "row",
        }}
      >
        {this.renderPatientGoalsTab()}
        {this.renderProviderGoalsTab()}
      </div>
    );
  }

  onTabSelected(index) {
    let tab;
    switch (index) {
      case 1:
        tab = "goals";
        break;
      case 2:
        tab = "careplan";
        break;
      case 3:
        tab = "education";
        break;
      case 4:
        tab = "careteam";
        break;
      case 5:
        tab = 'screeners';
        break;
      case 6:
        tab = 'orders';
        break;
      default:
        tab = "";
    }

    this.setState({ selectedTab: tab });
  }

  onModalSaveClicked() {
    if (!this.state.modalText || this.state.modalText.length === 0) {
      this.setState({ modalError: true });
      return;
    }
    this.setState({ modalLoading: true });

    let text = this.state.modalText;
    let toSave = {};
    let { patient, token, docFirebaseAuthUser } = this.props;
    let self = this;
    let url;
    if (this.state.modalGoals) {
      toSave.goal = text;
      url = Config.BACKEND_URL + "doctor/patient/goals";
    } else {
      toSave.plan = text;
      url = Config.BACKEND_URL + "doctor/patient/careplan";
    }
    axios({
      method: "put",
      headers: {
        Authorization: "JWT " + token,
        x_patient_id: patient.uid,
        x_doctor_id: docFirebaseAuthUser.uid,
      },
      url: url,
      data: toSave,
    })
      .then(({ data }) => {
        let patient = this.props.patient;
        if (this.state.modalGoals) {
          let goals = patient.goals || [];
          goals.push({ text: text, created_at: new Date() });
          patient.goals = goals;
        } else {
          let plan = patient.careplan || [];
          plan.push({ text: text, created_at: new Date() });
          patient.careplan = plan;
        }
        self.props.addPatient(patient);
        self.setState({
          modalLoading: false,
          modalGoals: false,
          modalText: "",
          modalIsOpen: false,
        });
      })
      .catch((err) => {
        console.log("error when getting patient vital progress data", err);
      });
  }

  renderCarePlanNotes() {
    let { careplan } = this.state;
    return(
      <>
        {
          careplan.map(carePlanData => {
            return (
                // latest notes from the provider
                <div
                className={Styles.contentRow}
                style={{ flexDirection: "column", alignItems: "flex-start" }}
                >
                <div
                  style={{ flexDirection: 'row', alignItems: 'center', display: 'flex' }}
                  >
                  <img
                    src={carePlanData?.doctor?.image_url ? carePlanData?.doctor?.image_url : DocPlaceHolder}
                    height={50}
                    width={50}
                    style={{borderRadius: '50%', margin: 10}}
                  />
                  <h6 style={{ color: Constants.primaryTheme }}>
                    {carePlanData?.doctor?.first_name} {carePlanData?.doctor?.last_name} {', '}
                    {moment(carePlanData?.careplan?.created_at, "YYYY-MM-DD").format("MMM Do YYYY")}
                  </h6>
                </div>
                  <div
                    style={{ width: '100%' }}
                    className={Styles.carePlanRow}
                  >
                    <div>
                      <h6 style={{ color: Constants.primaryTheme }}>
                      </h6>
                      <p style={{ overflowWrap: "break-word", marginLeft: 20}}>
                        - {carePlanData?.careplan?.text}
                      </p>
                    </div>
                    {carePlanData?.careplan?.icd_10 && <p
                      style={{textDecoration: 'underline', textDecorationColor: Constants.primaryTheme, color: Constants.primaryTheme, marginRight: 20, cursor: 'pointer'}}
                      onClick={()=>{
                        this.setState({
                          modalNotesIsOpen: true,
                          notesFromVisit: {
                            icd: carePlanData?.careplan?.icd_10,
                            notes: carePlanData?.careplan?.notes
                          }
                        }
                      )}}
                    >
                        Notes from visit
                    </p>}
                  </div>
            </div>
            )
          })
        }
      </>
    )
  }

  renderCareplanTab() {
    let { patient } = this.props;
    if (!patient || !patient.careplan) return null;
    let careplan = [];

    let temp = {};
    patient.careplan.forEach((each) => {
      let date = moment(each.created_at).format("YYYY-MM-DD");
      let array = temp[date] || [];
      array.push(each);
      temp[date] = array;
    });

    Object.keys(temp).forEach((dateKey) => {
      let array = temp[dateKey];
      let entries = array.map((x) => (
        <p style={{ overflowWrap: "break-word", marginLeft: 20 }}>- {x.text}</p>
      ));
      careplan.push(
        <div
          className={Styles.contentRow}
          style={{ flexDirection: "column", alignItems: "flex-start" }}
        >
          <h6 style={{ color: Constants.primaryTheme }}>
            {moment(dateKey, "YYYY-MM-DD").format("MMM Do YYYY")}
          </h6>
          {entries}
        </div>
      );
    });

    return (
      <div
        style={{
          width: "100%",
          margin: 10,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div style={{ width: "50%", margin: 20 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              position: "relative",
            }}
          >
            <h5 style={{ color: Constants.primaryTheme }}>Care Plan</h5>
            <FontAwesomeIcon
              onClick={() =>
                this.setState({ modalAddNotesIsOpen: true })
              }
              icon={faPlusCircle}
              style={{
                color: Constants.primaryTheme,
                fontSize: 18,
                position: "absolute",
                right: 10,
                top: 10,
                cursor: "pointer",
              }}
            />
          </div>
          <div
            className={Styles.contentBackground}
            style={{ height: 500, overflowY: "scroll" }}
          >
            {this.renderCarePlanNotes()}
          </div>
        </div>
        <div style={{ width: "50%", margin: 20 }}>
        <h5 style={{ color: Constants.primaryTheme }}>Care Team Chat</h5>
          <CareteamChat />
        </div>
      </div>
    );
  }

  renderEducationTab() {
    let { healthModules } = this.state;
    if (!healthModules) return null;
    let content = healthModules.map((x, index) => (
      <div key={x} className={Styles.contentRow}>
        <div
          style={{
            width: 150,
            height: 20,
            border: "1px solid gray",
            marginRight: 20,
            borderRadius: 5,
          }}
        >
          <div
            style={{
              borderRadius: 5,
              backgroundColor: Constants.primaryTheme,
              width: this.getRandomInt(150),
              height: "100%",
            }}
          ></div>
        </div>
        <p>{x}</p>
      </div>
    ));
    return (
      <div style={{ width: "40%", margin: 20 }}>
        <h5 style={{ color: Constants.primaryTheme }}>
          Health Modules Progress
        </h5>
        <div
          className={Styles.contentBackground}
          style={{ height: 240, overflowY: "scroll" }}
        >
          {content}
        </div>
      </div>
    );
  }

  renderOrdersTab() {
    let {patientCore, token} = this.props
    return <Orders patient={patientCore} token={token}/>
  }

  renderScreenersTab() {
    let {patient, token} = this.props
    return (
      <Screeners patientId={patient?.uid} token={token}/>
    )
  }

  renderCareteamTab() {
    let { careteam } = this.state;
    if (!careteam) return null;

    let doctorElements = [];
    careteam.forEach((doc, index) => {
      let name = doc.doctor_name;
      if (name.charAt(name.length - 1) === ",")
        name = name.substring(0, name.length - 1);
      doctorElements.push(
        <div
          data-tip
          data-for={name}
          className={Styles.docImageWrapper}
          style={{ backgroundColor: index % 2 === 0 ? "#DEDEDE" : "#F3F3F3" }}
        >
          <img
            src={DocPlaceHolder}
            height={100}
            width={100}
            style={{ borderRadius: "50%", marginBottom: 4 }}
          />
          <h6
            style={{
              fontWeight: "bold",
              color: Constants.primaryTheme,
              textAlign: "center",
            }}
          >
            {name}
          </h6>
          <p style={{ textAlign: "center" }}>{doc.speciality}</p>

          <ReactTooltip
            id={name}
            textColor="black"
            backgroundColor="white"
            effect="solid"
            className={Styles.hoverChatWrapper}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <p style={{ fontWeight: "bold" }}>
                Address:{" "}
                <span style={{ marginLeft: 4, fontWeight: "normal" }}>
                  {doc.address}
                </span>
              </p>
              <p style={{ fontWeight: "bold" }}>
                Phone:{" "}
                <span style={{ marginLeft: 4, fontWeight: "normal" }}>
                  {doc.phone}
                </span>
              </p>
              <p style={{ fontWeight: "bold" }}>Email:</p>
            </div>
          </ReactTooltip>
        </div>
      );
    });

    return (
      <div
        style={{
          width: "100%",
          margin: 10,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h5 style={{ color: Constants.primaryTheme }}>Care Team</h5>
        <div
          style={{
            width: "80%",
            margin: 10,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {doctorElements}
        </div>
      </div>
    );
  }

  renderTabContent() {
    switch (this.state.selectedTab) {
      case "goals":
        return this.renderGoalsTab();
      case "careplan":
        return this.renderCareplanTab();
      case "education":
        return this.renderEducationTab();
      case "careteam":
        return this.renderCareteamTab();
      case 'screeners':
        return this.renderScreenersTab()
      case 'orders':
        return this.renderOrdersTab()
      default:
        return null;
    }
  }

  renderContent() {
    return (
      <div className={GlobalStyles.contentWrapper}>
        <Tabs onTabSelected={this.onTabSelected.bind(this)} />
        {this.renderTabContent()}
      </div>
    );
  }

  renderModalContent() {
    let date = moment().format("MMM Do YYYY");
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() =>
            this.setState({
              modalIsOpen: false,
              modalGoals: false,
              modalText: "",
            })
          }
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: "center" }}>
          {this.state.modalGoals ? "Add Goal" : "Add Care Plan"}
        </h4>
        {!this.state.modalGoals && (
          <h5 style={{ textAlign: "center" }}>{date}</h5>
        )}

        {this.state.modalLoading ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              minWidth: "100%",
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        ) : (
          <textarea
            placeholder={"Enter your text here....."}
            style={{
              width: "90%",
              height: 100,
              padding: "5px 20px",
              resize: "none",
              borderColor: "#aeaeae",
              borderRadius: 10,
              marginTop: 40,
            }}
            value={this.state.modalText}
            onChange={(e) =>
              this.setState({ modalText: e.target.value, modalError: false })
            }
          />
        )}

        {this.state.modalError && (
          <p style={{ fontSize: 12, marginTop: 4, color: " red" }}>
            Please enter the text in the field
          </p>
        )}

        <Button
          onClick={() => this.onModalSaveClicked()}
          className={GlobalStyles.button}
          disabled={this.state.modalLoading}
          style={{ width: 200, position: "absolute", bottom: 20 }}
          variant="primary"
        >
          Save
        </Button>
      </div>
    );
  }

  renderModalAddNotes() {
    let {token, patient, docFirebaseAuthUser, mongoUser} = this.props
    let isHealthCoach = (mongoUser?.user_type.toLowerCase() === 'health coach')
    const Hit = (props, type) => {
      let {hit} = props
      // let checked = handleChecked(q, hit.symptom)
      const handleClick = () => {
        if (type === 'cpt') {
          this.setState( prevState => ({
            addCpt: [...prevState.addCpt, {icd10_code: hit.category_code, title: hit.category_title}]
          }))
        }
        else {
          this.setState({
            addIcd10: {icd10_code: hit.category_code, title: hit.category_title}
          })
        }
      }
      return (
        <>
          <button onClick={(e) => handleClick(e)} style={{border:'none', outline: 'none', display: 'block'}}>
            {hit.category_code}: {hit.category_title}
          </button>
          <hr/>
        </>
      )
    };

    const handleNoteChange = (event, noteType) => {
      this.setState({
        [noteType]: event.target.value
      })
    }

    const noteSubmit = () => {
      let {notesList, addCpt} = this.state
      console.log('submitted: ',notesList, addCpt)
      if(!notesList || notesList.length === 0) {
        toast.error('Can\'t leave notes field')
        return
      }
      axios({
        method: 'post',
        url: Config.BACKEND_URL + "provider/patients/notes",
        headers: {Authorization: 'JWT ' + token, x_doctor_id: docFirebaseAuthUser.uid},
        data: {
          patient_id: patient.uid,
          doctor_id: docFirebaseAuthUser.uid,
          notesArray: notesList,
          cptArray: addCpt
        }
      }).then(result => {
        const data = result.data;
        console.log('done submiting')
        this.setState({modalAddNotesIsOpen: false});
        this.getUserCarePlan();
      }).catch(err => {
        console.log('error when updating notes', err)
      })
    }

    return (
      <Modal
        isOpen={this.state.modalAddNotesIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => this.setState({modalAddNotesIsOpen: false})}
        style={NOTES_MODAL_STYLES}
        contentLabel="Modal"
      >
        <div style={{height: '100%', width: '100%', paddingTop: 20, position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <p className={Styles.closeModalBtn} onClick={() => this.setState({modalAddNotesIsOpen: false})}>X</p>
          <h4 style={{color: Constants.primaryTheme, textAlign: 'center'}}>Add Assesment</h4>

            <div className={Styles.modalRow}>

              { (!isHealthCoach) &&
                // show ICD Search for everyone except health coach
                <div className={Styles.modalCol}>
                  <p className={Styles.modalKey}>ICD 10 {this.state?.addIcd10?.icd10_code}</p>
                  <div className={Styles.modalValue}>
                  <InstantSearch searchClient={searchClient} indexName={'ICD_10'}>
                      <div style={{position: 'relative', zIndex: 2}}>
                        <SearchBox />
                        <div style={{overflow: 'auto', height: 150, backgroundColor: '#F1F1F1', paddingRight: 30}}>
                          <RefinementList limit={5} />
                          <Hits hitComponent={(props) => Hit(props)} />
                        </div>
                      </div>
                  </InstantSearch>
                  </div>
                </div>
              }

              <div className={Styles.modalCol}>
                <p className={Styles.modalKey}>
                  {(!isHealthCoach) ? `Assesment about ICD-10` : `Assesment`}
                </p>
                <textarea className={Styles.modalValue} rows="7" cols="40" onChange={(e) => handleNoteChange(e, 'addNotes')} value={this.state.addNotes}></textarea>
              </div>

              <div className={Styles.modalCol}>
                <p className={Styles.modalKey}>Care plan</p>
                <textarea className={Styles.modalValue} rows="7" cols="40" onChange={(e) => handleNoteChange(e, 'addCarePlan')} value={this.state.addCarePlan}></textarea>
              </div>
              <FontAwesomeIcon
                onClick={() =>{
                  let {addIcd10, addNotes, addCarePlan} = this.state;
                  this.setState( prevState => ({
                    notesList: [...prevState.notesList, {
                      icd_10: addIcd10,
                      notes: addNotes,
                      text: addCarePlan,
                    }],
                    addIcd10: {},
                    addNotes: '',
                    addCarePlan: ''
                  }))
                }
                }
                icon={faPlusCircle}
                style={{
                  color: Constants.primaryTheme,
                  fontSize: 30,
                  position: "relative",
                  cursor: "pointer",
                  alignSelf: 'center'
                }}
              />
            </div>

            { this.state?.notesList?.length !== 0 &&
              <div className={Styles.addedNotesListContainer} style={{width: '100%'}}>
              { this.state.notesList.map(noteData => (
                  <div className={Styles.modalRow} style={{backgroundColor: '#e8e8e8', borderRadius: 20, marginTop: 10, alignItems: 'center'}}>
                    { (!isHealthCoach) &&
                      <p className={Styles.modalCol} style={{ textAlign: 'center' }}>
                        ICD 10: {noteData?.icd_10?.icd10_code}
                      </p>
                    }
                    <p className={Styles.modalCol} style={{ textAlign: 'center' }}>
                      {(!isHealthCoach) ? `ICD Notes:` : `Notes:`} {noteData?.notes}
                    </p>
                    <p className={Styles.modalCol} style={{ textAlign: 'center' }}>Careplan: {noteData?.text}</p>
                    <FontAwesomeIcon icon={faTrash}
                      className={Styles.deleteIcon}
                      onClick={() => {
                        let arr = this.state.notesList.filter(data => data?.icd_10?.icd10_code !== noteData?.icd_10?.icd10_code)
                        this.setState({notesList: arr})
                      }}
                    />
                  </div>
                ))
              }
              </div>
            }

            { (!isHealthCoach) &&
              // show CPT code for everyone except health coach
              <div className={Styles.modalRow} style={{ marginTop: 10, marginBottom: 20 }}>
                <div className={Styles.modalCol}>
                  <p className={Styles.modalKey}>CPT</p>
                  <div className={Styles.modalValue}>
                  <InstantSearch searchClient={searchClient} indexName={'ICD_10'}>
                      <div style={{position: 'relative', zIndex: 2}}>
                        <SearchBox />
                        <div style={{overflow: 'auto', height: 150, backgroundColor: '#F1F1F1', paddingRight: 30}}>
                          <RefinementList limit={5} />
                          <Hits hitComponent={(props) => Hit(props, 'cpt')} />
                        </div>
                      </div>
                  </InstantSearch>
                  </div>
                </div>
                <div className={Styles.modalCol}>
                  <p className={Styles.modalKey}>Selected CPT</p>
                  <div className={Styles.modalValue} style={{ height: 160, overflow: 'auto', width: '100%'}}>
                  {
                      this.state.addCpt.map((cpt, index) => (
                        <div className={Styles.modalRow} style={{ justifyContent: 'space-between', borderTop: '1px solid #D3D3D3' }}>
                          <p>
                            {index+1}. {cpt.icd10_code}: {cpt.title}
                          </p>
                          <FontAwesomeIcon icon={faTrash}
                            className={Styles.deleteIcon}
                            onClick={()=>{
                              let arr = this.state.addCpt.filter(data => data.icd10_code !== cpt.icd10_code)
                              this.setState({addCpt: arr})
                          }}/>
                        </div>
                      ))
                  }
                  </div>
                </div>
              </div>
            }

          <Button
              onClick={noteSubmit}
              className={GlobalStyles.button}
              style={{width: 140, position: 'absolute', bottom: 10}}
              variant="primary"
          >
            Save Assesment
          </Button>

        </div>
        </Modal>
    )
  }

  renderModalNotes() {
    let { notesFromVisit } = this.state
    let date = moment().format("MMM Do YYYY");
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() =>
            this.setState({
              modalNotesIsOpen: false,
            })
          }
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: "center" }}>
          Notes from visit
        </h4>

          <ui style={{ height: 250, overflow: 'auto', width: '80%'}}>
            <li style={{borderTop: '1px solid #ccc', listStyle: 'none', margin: 10}}>
              {notesFromVisit?.icd?.icd10_code}: {notesFromVisit?.notes}
            </li>
          </ui>


        <Button
          onClick={() => this.setState({
            modalNotesIsOpen: false,
          })}
          className={GlobalStyles.button}
          disabled={this.state.modalLoading}
          style={{ width: 200, position: "absolute", bottom: 20 }}
          variant="primary"
        >
          Close
        </Button>
      </div>
    );
  }

  render() {
    return (
      <div
        className={GlobalStyles.container}
        style={{ overflow: "hidden", margin: "0 auto", maxHeight: "500vh" }}
      >
        <PatientInfoStrip
          medications={this.props.medications}
          conditions={this.props.conditions}
          patient={this.props.patient}
          patientCore={this.props.patientCore}
          color={this.props.color}
          cohorts={this.props.mongoUser ? this.props.mongoUser.cohorts : []}
        />

        {this.state.loading ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingTop: 200,
              minWidth: "100%",
              height: 1000,
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        ) : (
          this.renderContent()
        )}

        <Modal
          ariaHideApp={false}
          isOpen={this.state.modalIsOpen}
          onRequestClose={() =>
            this.setState({
              modalIsOpen: false,
              modalGoals: false,
              modalText: "",
            })
          }
          style={customModalStyles}
          contentLabel="Modal"
        >
          {this.renderModalContent()}
        </Modal>

        <Modal
          ariaHideApp={false}
          isOpen={this.state.modalNotesIsOpen}
          onRequestClose={() =>
            this.setState({
              modalNotesIsOpen: false,
            })
          }
          style={customModalStyles}
          contentLabel="Modal"
        >
          {this.renderModalNotes()}
        </Modal>
          {this.renderModalAddNotes()}
        <div id={"bottom"} style={{ visibility: "hidden", marginTop: 400 }}>
          asdasd
        </div>
      </div>
    );
  }
}

const Tabs = (props) => {
  const [activeTab, setActiveTab] = useState(1);

  function getTooltip(label) {
    return (
      <ReactTooltip
        id={label}
        textColor="black"
        backgroundColor={Constants.primaryThemeMedium}
        effect="solid"
      >
        <p style={{ color: "white", fontSize: 18 }}>{label}</p>
      </ReactTooltip>
    );
  }

  function getTab(name, index, icon, fontAwesome) {
    return (
      <div
        data-tip
        data-for={name}
        onClick={() => {
          setActiveTab(index);
          props.onTabSelected(index);
        }}
        className={Styles.rowIcon}
        style={{
          backgroundColor:
            activeTab === index
              ? Constants.primaryTheme
              : Constants.primaryThemeDark,
        }}
      >
        {fontAwesome ? (
          <FontAwesomeIcon
            icon={icon}
            style={{ color: "white", fontSize: 30 }}
          />
        ) : (
          <img src={icon} style={{ height: "75%", width: "75%" }} />
        )}

        {getTooltip(name)}
      </div>
    );
  }

  return (
    <div
      style={{
        flexDirection: "row",
        display: "flex",
        width: "100%",
        padding: "20px 20px",
      }}
    >
      {getTab("Goals", 1, faCrosshairs, true)}
      {getTab("Care Plan", 2, faHandHoldingMedical, true)}
      {getTab("Education", 3, faBookMedical, true)}
      {getTab("Care Team", 4, faStethoscope, true)}
      {getTab("Screeners", 5, faNotesMedical, true)}
      {getTab("Orders", 6, faClipboardList, true)}
    </div>
  );
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    patient: state.patientReducer.patient,
    patientCore: state.patientReducer.patientCore,
    color: state.patientReducer.color,
    token: state.authReducer.token,
    docFirebaseAuthUser: state.userReducer.firebaseAuthUser,
    mongoUser: state.userReducer.mongoUser,
    medications: state.patientReducer.medications,
    conditions: state.patientReducer.conditions,
    adminId: state.userReducer.adminId,
    token: state.userReducer.token,
  };
};

const mapDispatchToProps = { addPatient, getUnreadMessages };

export default connect(mapStateToProps, mapDispatchToProps)(CarePlan);
